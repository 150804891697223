import React, {useState} from 'react';
import {FileGrid} from "./fileGrid";
import {FileList} from "./fileList";

export const File = (props) => {
    const {activeView, fileName, onFileItemDoubleClick} = props;

    //State
    const [inputValue, setInputValue] = useState(fileName);

    return (
        <>
            {activeView === 'grid' ? (
                <FileGrid {...props} icon={props.icon} inputValue={inputValue} setInputValue={setInputValue}
                          onFileItemDoubleClick={onFileItemDoubleClick}
                />) : <FileList {...props} inputValue={inputValue} setInputValue={setInputValue}
                                onFileItemDoubleClick={onFileItemDoubleClick}
            />
            }
        </>
    )
}
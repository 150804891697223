import React from 'react';
import PolBadge from "v4/components/ui/PolBadge/PolBadge";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import getFormattedValue from "v4/utils/getFormattedValue";

export default function Subscription({subscription}) {
    const {t} = usePolTranslation();

    const {className, badgeText, badgeStatus} = getSubscriptionStatus(subscription);

    return (
        <>
            <div className={`prospect__render__subscriptions__title ${className}`}>
                {subscription.productName} - {t('beginAt')} : {getFormattedValue('date', subscription.beginAt)} - {t('endAt')} : {getFormattedValue('date', subscription.endAt)}
                <PolBadge status={badgeStatus} text={badgeText}/>
            </div>
            {subscription.productDescription?.length > 0 && (
                <div className="prospect__render__subscriptions__desc">
                    {subscription.productDescription}
                </div>
            )}
        </>
    )
}

function getSubscriptionStatus(subscription) {
    const now = new Date();
    const beginAt = new Date(subscription.beginAt);
    const endAt = new Date(subscription.endAt);

    if (now >= beginAt && now <= endAt) {
        return {
            className: 'current',
            badgeText: 'current',
            badgeStatus: 'success',
        }
    } else if (now < beginAt) {
        return {
            className: 'upcoming',
            badgeText: 'upcoming',
            badgeStatus: 'info',
        }
    } else if (now > endAt) {
        return {
            className: 'outdated',
            badgeText: 'outdated',
            badgeStatus: 'error',
        }
    }
}

import useAccessVoter from "v4/hooks/useAccessVoter";

export default function ProductVoter() {
    const {isGranted} = useAccessVoter();

    const canProductShowList = () => {
        return isGranted('product_show_list')
    }

    const canAdd = () => {
        return isGranted('product_add_edit')
    }

    const canEdit = () => {
        return isGranted('product_add_edit')
    }

    const canCategoryAddEdit = () => {
        return isGranted('category_add_edit')
    }

    return ({canCategoryAddEdit, canProductShowList, canAdd, canEdit})
}

import React, {Component} from 'react'
import {routesBase} from "v4/data/appRoutes";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {Redirect} from 'react-router-dom'

class TableTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
        };
    }

    handleClick = (isEnabled, preSearchType, datas) => {
        if (!isEnabled) return;

        const {managedBy = ''} = datas?.[0] ?? {};

        const preSearch = {};
        if (managedBy !== '') {
            preSearch['managedBy'] = [managedBy];
        }
        switch (preSearchType) {
            case 'since_january':
                preSearch['closed'] = 'yes';
                preSearch['beginAt'] = {
                    equal: ">",
                    begin: "01-01-" + new Date().getFullYear(),
                    end: ""
                };
                break;
            case 'late':
                preSearch['closed'] = "no";
                preSearch['beginAt'] = {
                    equal: "<",
                    begin: "",
                    end: new Date().toLocaleDateString('fr-FR').replaceAll('/', '-')
                };
                break;
            case 'month1':
                preSearch['closed'] = "no";
                preSearch['beginAt'] = {
                    equal: "><",
                    begin: new Date(new Date().getFullYear(), new Date().getMonth() , 1).toLocaleDateString('fr-FR').replaceAll('/', '-'),
                    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString('fr-FR').replaceAll('/', '-')
                }
                break;
            case 'month2':
                preSearch['closed'] = "no";
                preSearch['beginAt'] = {
                    equal: "><",
                    begin: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toLocaleDateString('fr-FR').replaceAll('/', '-'),
                    end: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0).toLocaleDateString('fr-FR').replaceAll('/', '-')
                }
                break;
            case 'month3':
                preSearch['closed'] = "no";
                preSearch['beginAt'] = {
                    equal: "><",
                    begin: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 1).toLocaleDateString('fr-FR').replaceAll('/', '-'),
                    end: new Date(new Date().getFullYear(), new Date().getMonth() + 3, 0).toLocaleDateString('fr-FR').replaceAll('/', '-')
                }
                break;
            default:
                break;
        }
        this.setState({
            redirect: <Redirect
                to={{
                    pathname: routesBase.baseTask,
                    state: {preSearch: preSearch}
                }}
            />
        });
    }


    render() {
        const {t, tableTasks} = this.props
        const {redirect} = this.state;


        return (
            <div className={'table-stat tasks'}>
                {redirect}
                <p className={'col-md-2 col-sm-4 col-6 table-stat__title'}>{t('tasks_results')}</p>
                <table className={'table-stat__table'}>
                    <thead className={'table-stat__header'}>
                    <tr>
                        <th/>
                        <th>{t('completed_tasks')}</th>
                        <th>{t('tasks_late')}</th>
                        <th colSpan={3}>{t('tasks_to_do')}</th>
                    </tr>
                    <tr>
                        <th/>
                        <th>{t('since_january')}</th>
                        <th/>
                        <th>{tableTasks ? t(tableTasks['month1_label']) : ''}</th>
                        <th>{tableTasks ? t(tableTasks['month2_label']) : ''}</th>
                        <th>{tableTasks ? t(tableTasks['month3_label']) : ''}</th>
                    </tr>
                    </thead>
                    <tbody className={'table-stat__body'}>
                        {tableTasks ?
                            <>
                                {tableTasks.data.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{item.user}</td>
                                            <td className={'table-stat__link'} onClick={() => this.handleClick(item['since_january'].length > 0, "since_january", item['since_january'])}>{item['since_january'].length}</td>
                                            <td className={'table-stat__link'} onClick={() => this.handleClick(item['late'].length > 0, "late", item['late'])}>{item['late'].length}</td>
                                            <td className={'table-stat__link'} onClick={() => this.handleClick(item['month1'].length > 0, "month1", item['month1'])}>{item['month1'].length}</td>
                                            <td className={'table-stat__link'} onClick={() => this.handleClick(item['month2'].length > 0 , "month2", item['month2'])}>{item['month2'].length}</td>
                                            <td className={'table-stat__link'} onClick={() => this.handleClick(item['month3'].length > 0, "month3", item['month3'])}>{item['month3'].length}</td>
                                        </tr>
                                    )
                                })}
                            </>
                        :
                            <tr><td>{t('loading_data')}</td></tr>
                        }

                        <tr className={'table-stat__body__total'}>
                            <td>{t('total')}</td>
                            {(tableTasks && tableTasks['task_totals']) ? Object.keys(tableTasks['task_totals']).map((context) => {
                                return (
                                    <td className={'table-stat__link'} key={context} onClick={() => this.handleClick(String(tableTasks['task_totals'][context]) !== '0', context)}>{tableTasks['task_totals'][context]}</td>
                                )
                            })
                            :
                                <>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                </>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withPolTranslation(TableTasks);

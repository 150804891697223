export default function getRealFieldType(fieldData) {
    if (!fieldData) return 'TextType';

    const {type, enum_titles} = fieldData;

    switch (type) {
        case 'string':
            return enum_titles ? 'ChoiceType' : 'TextType';
        case 'integer':
        case 'number':
            return 'NumberType';
        case 'boolean':
            return 'BooleanType';
        case 'array':
            return 'ChoiceType';
        default:
            return 'TextType';
    }
}

import InputSelect from "../../Input/_partials/inputSelect";
import React, {useState} from 'react'

const SelectProspect = ({t, onChange, placeholder}) => {
    const [selectValue, setSelectValue] = useState('');

    return (
        <InputSelect
            inputData={{
                keyItem: 'autocomplete_prospect',
                attr: {
                    type : 'autocomplete_select',
                    url : '/api/prospects/search/form',
                    type_field: 'prospect',
                    placeholder: selectValue ? selectValue.label : placeholder,
                    isSingle: true,
                    value: selectValue
                },
                enum: [],
                items: [],
                type: 'array',
            }}
            onChange={(value) => {
                setSelectValue(value)
                onChange(value)
            }}
            t={t}
        />
    )
};

export default React.memo(SelectProspect)
import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import {MAILING_SEND} from "v4/data/apiRoutes";
import {routesBase} from "v4/data/appRoutes";
import {CONTACT} from "v4/data/entities";
import CampaignPopup from "v4/features/front/campaign/components/CampaignPopup/CampaignPopup";
import ListingExport from "v4/features/front/export/components/ListingExport/ListingExport";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import {generateUrl} from "v4/services/api.service";
import {ids} from "v4/utils";
import CampaignVoter from "v4/voters/campaignVoter";

export default function ContactsListingTools({checkedRows}) {
    const {t} = usePolTranslation();
    const {replace} = useHistory();
    const [{data, isLoading}, fetchMailing] = useFetch();
    const {canCreateCampaign} = CampaignVoter();
    const [showCampaignPopup, toggleShowCampaignPopup] = useToggle();

    const handleNoSelectedItems = (e) => (checkedRows?.length === 0) && e.stopPropagation();

    const handleMailing = () => {
        fetchMailing({
            url: generateUrl(MAILING_SEND),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    type: `${CONTACT}s`, /* TODO: changer contacts en contact côté back */
                    value: checkedRows
                })
            }
        })
    }

    useEffect(() => {
        if (data?.success) {
            replace(`${routesBase.baseNewsletter}/emailing`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <ListingExport selectedItems={checkedRows} entity={CONTACT}/>
            <li className="listing-header__action dropdown"
                title={t('options')} onClickCapture={handleNoSelectedItems}>
                <PolIcon icon="actions" className="action__picto"/>
                <ul className="listing-header__action__dropdown">
                    <li className={`listing-header__action__item${(checkedRows?.length === 0 || isLoading) ? ' listing-header__action__item__disabled' : ''}`}
                        onClick={handleMailing}>
                        <p>{t('send_emailing')}</p>
                        {isLoading && <PolIcon icon="circle-notch" className="spinner"/>}
                    </li>
                    {
                        canCreateCampaign() && (
                            <>
                                <li className={`listing-header__action__item${checkedRows.length > 0 ? '' : ' listing-header__action__item__disabled'}`}
                                    onClick={toggleShowCampaignPopup}>
                                    <p>{t("send_campaign")}</p>
                                </li>
                                <PolPopup isOpen={showCampaignPopup}
                                          closePopup={toggleShowCampaignPopup}
                                          title={t('create_campaign')}>
                                    <CampaignPopup selectedIds={checkedRows}
                                                   toggleShow={toggleShowCampaignPopup}/>
                                </PolPopup>
                            </>
                        )
                    }
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}>
                        <Link to={{
                            pathname: routesBase.baseTask,
                            state: {[ids(CONTACT)]: checkedRows}
                        }}>
                            <p>{t('research_associated_actions')}</p>
                        </Link>
                    </li>
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}>
                        <Link to={{
                            pathname: routesBase.baseQuote,
                            state: {[ids(CONTACT)]: checkedRows}
                        }}>
                            <p>{t('research_associated_quote')}</p>
                        </Link>
                    </li>
                </ul>
            </li>
        </>
    )
}

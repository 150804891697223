import {useContext} from "react";
import {TreeNodesContext} from "v4/components/ui/Tree/contexts/TreeNodesContext";
import {AdminContext} from "v4/contexts/AdminContext";

export default function useTreeNodes() {
    const {treeNodes, setTreeNodes, originalNodes, saveAction, events} = useContext(TreeNodesContext)
    const {asCustomer} = useContext(AdminContext)

    const addNodeAction = (node) => {
        const newTreeNodes = [...treeNodes];
        const existingNode = findNode(newTreeNodes, node ? node.id : null);
        const newId = existingNode ? existingNode.id + '_' + existingNode.children.length : 'root_' + treeNodes.length
        const newNode = {
            id: newId,
            name: '',
            parent: node ? node.id : null,
            children: [],
            customerId: asCustomer.id,
            inEditMode: true
        }
        existingNode ? existingNode.children.splice(existingNode.children.length, 0, newNode) : newTreeNodes.splice(newTreeNodes.length, 0, newNode)
        setTreeNodes(newTreeNodes);
    }

    /**
     * Find node object in given element matching search
     *
     * @param element
     * @param match
     * @returns {null|*}
     */
    const findNode = (element, match) => {
        if (element.id === match) {
            return element;
        } else {
            let result = null;

            if (element.hasOwnProperty('children')) {
                for (let i = 0; result == null && i < element.children.length; i++) {
                    result = findNode(element.children[i], match);
                }
            } else {
                for (let i = 0; result == null && i < element.length; i++) {
                    result = findNode(element[i], match);
                }
            }
            return result;
        }
    }

    // Prevent bug display base on full tree height
    const onBeforeDragStart = () => {
        const outerHeight = document.getElementById('treeNodes').offsetHeight;
        document.getElementById('treeNodes').style.minHeight = outerHeight + 'px';
    }

    const resetAction = () => {
        // When setTreeNodes is called, original nodes take same data
        // So stringify and parse back to break object memory target
        setTreeNodes(JSON.parse(JSON.stringify(originalNodes)))
    }

    const onDragEnd = (result) => {
        // If no destination, return
        // If destination is same as moved element, return
        if (!result.destination || result.draggableId === result.destination.droppableId) {
            return;
        }

        const draggable = findNode(treeNodes, result.draggableId);
        const destination = findNode(treeNodes, result.destination.droppableId);
        const source = findNode(treeNodes, result.source.droppableId);

        // Check if move is circular reference (avoid loops)
        if (destination && findNode(draggable, destination.id)) {
            return;
        }

        // Remove element from source
        if (result.source.droppableId === 'root') {
            treeNodes.splice(result.source.index, 1);
        } else {
            source.children.splice(result.source.index, 1);
        }

        // Add element to destination
        if (result.destination.droppableId === 'root') {
            treeNodes.splice(result.destination.index, 0, draggable);
        } else {
            destination.children.splice(result.destination.index, 0, draggable);
        }

        setTreeNodes([...treeNodes])
    }

    return ({treeNodes, setTreeNodes, addNodeAction, findNode, onBeforeDragStart, resetAction, onDragEnd, saveAction, events})
}

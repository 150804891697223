import React, {useState} from 'react';
import FolderList from '../../components/Ged/folderList';
import {BreadScrumb} from "../../components/Ged/breadScrumb";
import CurrentDirectory from "../../components/Ged/currentDirectory";
import HeaderGed from "../../components/Ged/headerGed";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {getApiUrl} from "../../routes";
import {PostService} from "../../api/datas/post";
import {connect} from "react-redux";
import {mergeAndSortFiles} from "../../utils/gedDocumentSorter";
import {getTree, hasSelectedElementInChild} from "../../utils/folderManager";
import {PolIcon} from "../../components/PolIcon/policon";
import {setPopup} from "../../actions/content";


export const SORT_BY_NAME = 'name';
export const SORT_BY_DATE = 'date';
export const SORT_BY_SIZE = 'size';

const Ged = ({
                 t, postService, directoryList, rootDirectoryList, isReadOnly = false, onFileItemDoubleClick,
                 expansionAllowed = [],onFileItemSimpleClick, togglePopup
             }) => {
    let [folderHistory, setFolderHistory] = useState([]);
    const [folderOpened, setFolderOpened] = useState([]);
    let [currentItem, setCurrentItem] = useState({});
    let [itemToEdit, setItemToEdit] = useState("");
    let [editedItem, setEditedItem] = useState(undefined);
    let [editItemSpot, setEditedItemSpot] = useState(null);
    const [activeView, setActiveView] = useState('grid');
    const [activeSort, setActiveSort] = useState(SORT_BY_NAME);
    const [isLoading, setLoading] = useState(false);
    const [folderListState, setFolderListState] = useState(false);

    const addOrRemoveFolderOpened = (folderId) => {
        const index = folderOpened.indexOf(folderId);
        if (folderOpened.indexOf(folderId) !== -1) {
            folderOpened.splice(index, 1)
        } else {
            folderOpened.push(folderId)
        }
        setFolderOpened([...folderOpened])
    }

    /**
     * Gere le click sur un élément côté Ged
     * @param currentElement
     * @param folderHierarchyLevel {integer}
     * @param renderByCurrentDirectory
     * @param typeClick
     * @param spot
     * @param enableEdit
     */
    const onChangeItem = (currentElement, folderHierarchyLevel, renderByCurrentDirectory, typeClick = null, spot = null, enableEdit = false) => {
        if (renderByCurrentDirectory) {
            folderHierarchyLevel++;
            currentElement.hierarchyLevel = folderHierarchyLevel;
        }

        if (currentElement.folderName && !typeClick) {
            setFolderHistory(getTree(rootDirectoryList, currentElement.id))
        }

        if (currentElement) {
            if(onFileItemSimpleClick){
                let url = null;
                if (!currentElement.folderName) {
                    url = process.env.REACT_APP_HOST_API + getApiUrl('ged', 'downloadFile').replace('{id}', currentElement.id);
                }
                onFileItemSimpleClick(url);
            }
            setCurrentItem(currentElement);
            currentItem = currentElement
        }

        itemToEdit = "";
        editedItem = undefined
        editItemSpot = spot
        setItemToEdit("");
        setEditedItem(undefined);
        setEditedItemSpot(spot);

        if (enableEdit) {
            handleClickOnEdit();
        }
    }

    /**
     * Gere la suppression des éléments courants côté Ged
     * @param isFolderDelete {boolean}
     */
    const handleDelete = (isFolderDelete) => {
        if (isFolderDelete) {
            folderHistory.pop()
            setFolderHistory(folderHistory);
        }
        setCurrentItem({});
        setItemToEdit("")
        setEditedItem(undefined)
    }

    /**
     * Gère la récupération de l'ID de l'item souhaitant être édité venant de HeaderGED et transmet cette information à CurrentDirectery et FolderList
     *
     */
    const handleClickOnEdit = () => {
        setItemToEdit("")
        if (currentItem.fileName) {
            setItemToEdit(currentItem.id)
            return
        }
        setItemToEdit(currentItem.id)
    }

    /**
     * Assigne au State l'élément venant d'être modifié afin de le transmettre à headerGed qui centralise les requêtes
     */
    const handleEditedFile = (editedElement) => {
        setItemToEdit("")
        setEditedItem(undefined)
        setEditedItem(editedElement)
    }

    const resetEditFile = () => {
        setItemToEdit("")
        setEditedItem(undefined)
        setCurrentItem({});
    }

    /**
     * Gere le click sur un élément 'Root' pour un retour à la racine de l'arborescense
     */
    const onRootReturn = () => {
        setFolderHistory([]);
        setCurrentItem({});
        setItemToEdit("")
        setEditedItem(undefined)
        const selectedItem = document.querySelector('.select');
        if (selectedItem !== null) {
            selectedItem.classList.remove('select');
        }
    }

    const onDragEnd = (result) => {
        if (!isReadOnly) {
            directoryList = mergeAndSortFiles(directoryList, activeSort)

            if (result.destination && result.source) {
                // Si on est dans un drop menu gauche
                if (result.destination.droppableId && result.destination.droppableId.search('-list') !== -1) {
                    const newFolderId = result.destination.droppableId.split('-')[1];
                    const source = directoryList[result.source.index];

                    const isDestinationChildrenOfParent = hasSelectedElementInChild(newFolderId, source.customerResources)

                    if (isDestinationChildrenOfParent || source.id === newFolderId) {
                        return;
                    }

                    if (source && newFolderId) {
                        setLoading(true)
                        source.customerFolder = "/api/customer_folders/" + newFolderId
                        postService({
                            putUrl: getApiUrl('ged', source.folderName ? 'putFolder' : 'put').replace('{id}', source.id),
                            submittedData: source,
                            type: 'uploadFile',
                            loader: 'ged',
                            refreshGed: true,
                            callback: () => {
                                setLoading(false)
                            }
                        }, t)
                    }
                } else {
                    if (result.source.index !== result.destination.index) {
                        const source = directoryList[result.source.index];
                        const destination = directoryList[result.destination.index];

                        if (source && destination && destination.folderName) {
                            setLoading(true)
                            source.customerFolder = "/api/customer_folders/" + destination.id
                            postService({
                                putUrl: getApiUrl('ged', source.folderName ? 'putFolder' : 'put').replace('{id}', source.id),
                                submittedData: source,
                                type: 'uploadFile',
                                loader: 'ged',
                                refreshGed: true,
                                callback: () => {
                                    setLoading(false)
                                }
                            }, t)
                        }
                    }
                }
            }
        }
    }

    return (
        <div className={'ged-component page page-list main-content__body__slide'}>
            <div className={'page-list__content'}>
                <div className={'ged-component__header col-12'}>
                    <HeaderGed setLoading={setLoading} activeSort={activeSort} setActiveSort={setActiveSort}
                               activeView={activeView} setActiveView={setActiveView} editedItem={editedItem}
                               handleClickOnEdit={handleClickOnEdit}
                               currentFolder={folderHistory[folderHistory.length - 1]} currentItem={currentItem}
                               handleDelete={handleDelete}
                               isReadOnly={isReadOnly}
                               onFileItemDoubleClick={onFileItemDoubleClick}
                    />
                </div>
                <div className={'ged-component__files container-fluid'}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable key={`droppable`} isCombineEnabled
                                   droppableId={`droppable-folder-list`} type="droppableItem">
                            {(provided, snapshot) => (
                                <div className={`${snapshot.isDraggingOver ? 'selected' : ''} ged-component__folder-list col-md-2`}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}>
                                    <FolderList t={t} editItemSpot={editItemSpot} resetEditFile={resetEditFile}
                                                handleEditedFile={handleEditedFile} itemToEdit={itemToEdit}
                                                onRootReturn={onRootReturn} onChangeItem={onChangeItem}
                                                currentItem={currentItem}
                                                currentFolder={folderHistory[folderHistory.length - 1]}
                                                folderOpened={folderOpened}
                                                addOrRemoveFolderOpened={addOrRemoveFolderOpened}
                                    />

                                    <span className={'hidden'}>
                                        {provided.placeholder}
                                    </span>
                                </div>
                            )}
                        </Droppable>
                        <div className={'ged-component__files-directory col-md-10 col-12'}>
                            <div>
                                <BreadScrumb t={t} onRootReturn={onRootReturn} folderHistory={folderHistory}
                                             onChangeItem={onChangeItem}/>
                            </div>
                            <div>
                                <div onClick={() => {
                                    setFolderListState(true)
                                }}>
                                    <PolIcon icon={'folder'}/>
                                </div>
                                <div className={`ged-component__files-directory--responsive ${folderListState ? 'open' : null}`}>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable key={`droppable`} isCombineEnabled
                                                   droppableId={`droppable-folder-list`} type="droppableItem">
                                            {(provided, snapshot) => (
                                                <>
                                                    <PolIcon icon={'quit'} onClick={() => {
                                                        setFolderListState(false)
                                                    }}/>
                                                    <div className={`${snapshot.isDraggingOver ? 'selected' : ''} ged-component__folder-list col-md-2`}
                                                         {...provided.droppableProps}
                                                         ref={provided.innerRef}>
                                                        <FolderList t={t} editItemSpot={editItemSpot} resetEditFile={resetEditFile}
                                                                    handleEditedFile={handleEditedFile} itemToEdit={itemToEdit}
                                                                    onRootReturn={onRootReturn} onChangeItem={onChangeItem}
                                                                    currentItem={currentItem}
                                                                    currentFolder={folderHistory[folderHistory.length - 1]}
                                                                    folderOpened={folderOpened}
                                                                    addOrRemoveFolderOpened={addOrRemoveFolderOpened}
                                                                    isMobileDisplay={true}
                                                        />
                                                        <span className={'hidden'}>
                                                                        {provided.placeholder}
                                                                    </span>
                                                    </div>
                                                </>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                                <CurrentDirectory handleClickOnEdit={handleClickOnEdit} isLoading={isLoading}
                                                  setLoading={setLoading} activeSort={activeSort}
                                                  activeView={activeView}
                                                  editItemSpot={editItemSpot} resetEditFile={resetEditFile}
                                                  handleEditedFile={handleEditedFile} itemToEdit={itemToEdit}
                                                  onChangeItem={onChangeItem} currentItem={currentItem}
                                                  currentFolder={folderHistory[folderHistory.length - 1]}
                                                  isReadOnly={isReadOnly}
                                                  onFileItemDoubleClick={onFileItemDoubleClick}
                                                  expansionAllowed={expansionAllowed}
                                                  onFileItemSimpleClick={onFileItemSimpleClick}
                                />
                            </div>
                        </div>
                    </DragDropContext>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = ({ged: {directoryList, rootDirectoryList}}) => ({
    directoryList, rootDirectoryList
});

const mapDispatchToProps = dispatch => {
    return {
        postService: (info, t) => dispatch(PostService(info, t)),
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(Ged));

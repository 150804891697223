import React, {useMemo} from 'react';
import {useHistory} from "react-router-dom";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {routesBase} from "v4/data/appRoutes";
import TABS, {
    TAB_CLOSED_TASKS,
    TAB_CONTACTS,
    TAB_OLD_MAILS_TASKS,
    TAB_OPEN_TASKS,
    TAB_QUOTES,
    TAB_RECEIVED_MAILS_TASKS,
    TAB_WON_QUOTES
} from "v4/data/tabNames";
import useTestRoute from "v4/hooks/useTestRoute";
import ProspectClosedTasksTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectClosedTasksTab/ProspectClosedTasksTab";
import ProspectContactsCustomTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsCustomTab/ProspectContactsCustomTab";
import ProspectContactsTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsTab/ProspectContactsTab";
import ProspectOldMailTasksTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOldMailTasksTab/ProspectOldMailTasksTab";
import ProspectOpenTasksTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOpenTasksTab/ProspectOpenTasksTab";
import ProspectQuotesCustomTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesCustomTab/ProspectQuotesCustomTab";
import ProspectQuotesTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/ProspectQuotesTab";
import ProspectReceivedMailsTasksTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectReceivedMailsTasksTab/ProspectReceivedMailsTasksTab";
import ProspectTabsSwitcherNavigation
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectTabsSwitcherNavigation/ProspectTabsSwitcherNavigation";
import ProspectTasksCustomTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectTasksCustomTab/ProspectTasksCustomTab";
import ProspectWonQuotesTab
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/ProspectWonQuotesTab";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/ProspectTabsSwitcher.scss';
import {getCustomTabBySlug, getCustomTabSlugs} from "v4/services/tab.service";
import QuoteTabProvider from "./contexts/QuoteTabContext";
import {PROSPECT_CONTACTS_SEARCH_FORM} from "v4/data/apiRoutes";

export default function ProspectTabsSwitcher({currentTabName}) {
    const {prospectId} = useProspectPageContext();
    const hasResumeInUrl = useTestRoute({inUrl: 'resume'});
    const hasEditInUrl = useTestRoute({inUrl: 'edit'});
    const hasAddInUrl = useTestRoute({inUrl: 'add'});
    const isCustomTab = getCustomTabSlugs(true).includes(currentTabName);
    const {replace} = useHistory();
    const shouldRedirect = (!Object.values(TABS).includes(currentTabName) && !isCustomTab);

    if (hasResumeInUrl && currentTabName !== TAB_OPEN_TASKS) replace(`${routesBase.baseProspect}/${prospectId}/${currentTabName}`);

    if (shouldRedirect) replace({pathname: routesBase.baseProspect});

    let tabComponent = useMemo(
        () => getTabComponent(currentTabName, prospectId, isCustomTab), [currentTabName, prospectId, isCustomTab]
    );

    return (
        <div className={`prospect__tab ${(hasEditInUrl || hasAddInUrl) ? 'no-overflow' : ''}`}>
            <ProspectTabsSwitcherNavigation prospectId={prospectId} currentTabName={currentTabName}/>
            <div className="prospect__tab__component">
                <SearchProvider searchRouteName={getSearchRouteName(currentTabName)} key={currentTabName + '-tab'} pageType={currentTabName + '-tab'}>
                    {tabComponent}
                </SearchProvider>
            </div>
        </div>
    );
}

function getTabComponent(tabName, prospectId, isCustomTab) {
    if (isCustomTab) {
        const tab = getCustomTabBySlug(tabName);

        switch (tab.searchType) {
            case 'contact':
                return <ProspectContactsCustomTab prospectId={prospectId} tab={tab}/>;
            case 'quote':
                return <QuoteTabProvider><ProspectQuotesCustomTab prospectId={prospectId}
                                                                  tab={tab}/></QuoteTabProvider>;
            case 'task':
                return <ProspectTasksCustomTab prospectId={prospectId} tab={tab}/>;
            default:
                return null;
        }
    }

    switch (tabName) {
        case TAB_CONTACTS:
            return <ProspectContactsTab prospectId={prospectId}/>
        case TAB_OPEN_TASKS:
            return <ProspectOpenTasksTab prospectId={prospectId}/>
        case TAB_CLOSED_TASKS:
            return <ProspectClosedTasksTab prospectId={prospectId}/>
        case TAB_OLD_MAILS_TASKS:
            return <ProspectOldMailTasksTab prospectId={prospectId}/>
        case TAB_RECEIVED_MAILS_TASKS:
            return <ProspectReceivedMailsTasksTab prospectId={prospectId}/>
        case TAB_QUOTES:
            return <QuoteTabProvider><ProspectQuotesTab prospectId={prospectId}/></QuoteTabProvider>
        case TAB_WON_QUOTES:
            return <QuoteTabProvider><ProspectWonQuotesTab prospectId={prospectId}/></QuoteTabProvider>
        default:
            return null;
    }
}

function getSearchRouteName(tabName) {
    switch (tabName) {
        case TAB_CONTACTS:
            return PROSPECT_CONTACTS_SEARCH_FORM;
        default:
            return undefined;
    }
}

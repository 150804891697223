import useAccessVoter from "v4/hooks/useAccessVoter";

export default function QuoteVoter() {
    const {isGranted} = useAccessVoter();

    const canQuoteRegeneratePrices = () => {
        return isGranted('quote_regenerate_prices')
    }

    return ({canQuoteRegeneratePrices})
}

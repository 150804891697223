import React, {Component} from 'react'
import {routesBase} from "v4/data/appRoutes";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {connect} from 'react-redux'
import {NavLink, Redirect} from 'react-router-dom'
import logo from "../../img/logo.svg";
import sfi from "../../img/logoSFI.png";
import UserService from "../../api/user/user";
import {LoginService} from "../../api/user/user";
import {resetLoader} from "../../actions/content";
import Logo from "../../img/logo";
import ImgCover from "../../img/login";
import {AuthContext} from "../../v4/contexts/AuthContext";
import {getLSItem, removeLSItem, setLSItem} from "v4/services/localStorage.service";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            forgetPwd: false,
            redirect: null,
            error: undefined,
            isLocked: false,
            lockInfos: getLSItem('loginLockInfos'),
        };
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.isLocked) {
            return;
        }
        const {username, password} = this.state;
        const {onLogin} = this.props;
        const data = {
            username: username,
            password: password,
        };

        onLogin(data, this.props.auth.setToken);
    };

    componentDidMount() {
        const {t} = this.props;
        const {lockInfos} = this.state;

        const isLocked = lockInfos && lockInfos.lockAt + lockInfos.retryAfter * 1000 > Date.now();
        const retryAfter = Math.ceil((lockInfos.lockAt + lockInfos.retryAfter * 1000 - Date.now()) / 1000);
        const errorMessage = isLocked && t(lockInfos.message, {retryAfter});

        this.setState({
            isLocked,
            error: errorMessage,
        });

        if (isLocked) {
            setTimeout(() => {
                this.setState({
                    isLocked: false,
                    error: undefined,
                });
                removeLSItem('loginLockInfos')
            }, retryAfter * 1000);
        }

        if (this.props.user > 0 || UserService.getTokens()) {
            this.setState({
                redirect: <Redirect to={routesBase.baseProspect}/>
            });
        } else {
            this.props.resetLoader();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            const {user: {error}, t} = this.props;
            if (error) {
                if ([401, 429].includes(error.status) && !error.bodyUsed) {
                    const retryAfterHeader = error.headers.get('Retry-After');
                    error.json().then(({message = 'invalid_credentials'}) => {
                        this.setState({
                            error: t(message, {retryAfter: retryAfterHeader}),
                            isLocked: message === 'ip_locked',
                        })

                        if (message === 'ip_locked') {
                            setLSItem('loginLockInfos', {
                                lockAt: Date.now(),
                                retryAfter: retryAfterHeader,
                                message: 'ip_locked'
                            });
                            setTimeout(() => {
                                this.setState({
                                    isLocked: false,
                                    error: undefined,
                                });
                                removeLSItem('loginLockInfos')
                            }, retryAfterHeader * 1000);
                        }
                    });
                }
                if (error.status === 500) {
                    this.setState({
                        error: 'Une erreur est survenue lors de la connexion, merci de réessayer ou de contacter les administrateurs du site'
                    })
                }
            }
        }
    }

    render() {
        const {redirect, forgetPwd, error, isLocked} = this.state;
        const {t, user: {load}} = this.props;

        return (
            <main className={`loginpage bg-env-${process.env.REACT_APP_ENV}`}>
                {redirect}

                <span className={'round'}/>
                <span className={'round'}/>
                <span className={'round'}/>

                <div className={'row row-fluid justify-content-between login'}>

                    <div className={'col-12 col-lg-7 col-xl-8 login__images'}>
                        <div className={'row justify-content-center align-items-center'}>
                            <div className={'col-auto'}>
                                <img src={logo} className={'login__images__logo'} alt={'POL CRM'}/>
                            </div>
                            <div className={'col-auto'}>
                                <p className={'login__images__text'}>{t('pol_slogan')}</p>
                            </div>
                        </div>

                        <div className={'row justify-content-center d-none d-md-block'}>
                            <div className={'col-auto'}>
                                <div className={'login__images__presentation'}>
                                    <ImgCover/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'col-12 col-lg-5 col-xl-4 login__form'}>
                        <div className={'row login__form__content'}>
                            <div className={'col-12 align-self-center d-none d-sm-block'}>
                                <div className={'row justify-content-end powered-by'}>
                                    <div className={'col-auto align-self-center'}>
                                        <p className={'powered-by__text'}>{t('developpe_par')}</p>
                                    </div>
                                    <div className={'col-auto'}>
                                        <img src={sfi}
                                             alt={'Logo SFI'}/>
                                    </div>
                                </div>
                            </div>

                            <div className={'col-12  align-self-center'}>
                                <form onSubmit={(e) => this.handleSubmit(e)}>

                                    <div className={'login__form__field'}>
                                        <label htmlFor="username"
                                               className={'login__form__field__label'}>{t('identifiant')} </label>
                                        <input name={'username'} type={'text'}
                                               onChange={(e) => this.handleChange(e)}
                                               className={'login__form__field__input'} id={'username'}
                                        />
                                    </div>

                                    <div className={'login__form__field'}>
                                        <label htmlFor="username"
                                               className={'login__form__field__label'}>{t('password')}</label>
                                        <input name={'password'} type={'password'}
                                               onChange={(e) => this.handleChange(e)}
                                               className={'login__form__field__input'}
                                        />
                                    </div>

                                    <button type={'submit'}
                                            className={'btn btn-primary login__form__btn'}
                                            disabled={isLocked}>
                                        {t('login')}
                                    </button>

                                    {error ? <p className={'login__form__error'}>{error}</p> : null}
                                </form>
                            </div>

                            <div className={'col-12 align-self-end'}>
                                <p className={'login__form__forget'}>
                                    {forgetPwd ? (
                                        <NavLink to={'/'}>
                                            {t('mdp_oublie')}
                                        </NavLink>
                                    ) : null}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {load ? (
                    <div className={'login__loader'}>
                        <div className={'login__loader__logo'}>
                            <Logo/>
                        </div>
                    </div>
                ) : null}
            </main>
        )
    }
}

function LoginWithAuthContext(props) {
    return (
        <AuthContext.Consumer>
            {
                (auth) => <Login {...{...props, auth}}/>
            }
        </AuthContext.Consumer>
    )
}

const mapStateToProps = ({user}) => ({
    user
});

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (info, setToken) => dispatch(LoginService(info, setToken)),
        resetLoader: () => dispatch(resetLoader()),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(LoginWithAuthContext));

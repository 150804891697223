import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_CLOSED_TASKS, TAB_OLD_MAILS_TASKS, TAB_OPEN_TASKS, TAB_RECEIVED_MAILS_TASKS} from "v4/data/tabNames";
import MoveTaskButton from "v4/features/front/moveTask/components/MoveTaskButton/MoveTaskButton";

export default function TasksListingRowActions({tab = {}, rowId, prospectId, isClosed, isMailing, isSynchroOutlook}) {
    const getSlug = () => {
        if (isMailing) {
            return TAB_OLD_MAILS_TASKS;
        }

        if (isSynchroOutlook) {
            return TAB_RECEIVED_MAILS_TASKS;
        }

        return isClosed
            ? TAB_CLOSED_TASKS
            : TAB_OPEN_TASKS
            ;
    }

    let slug = getSlug();

    if ('slug' in tab && !tab.isDisabledDisplaySublist) {
        slug = tab.slug;
    }

    return (
        <>
            <Link to={`${routesBase.baseProspect}/${prospectId}/${slug}/${rowId}`} className="table-link"/>
            <ul className="table-actions">
                <li><MoveTaskButton taskId={rowId}/></li>
            </ul>
        </>
    )
}

import React, {useEffect} from "react"
import {withPolTranslation} from "v4/hooks/usePolTranslation";


const PopupInfo = ({message, onAccept, t}) => {

    useEffect(() => {
        const handleEnter = (e) => {
            e.preventDefault()
            if (e.key === "Enter" || e.key === 'Escape') {
                onAccept();
            }
        };
        document.addEventListener('keypress', handleEnter, false);
        return () => {
            document.removeEventListener('keypress', handleEnter, false)
        };
    }, [message, onAccept]);

    return (
        <>
            <p className={'popup__content__normal-title '}>{message}</p>
            <div className={'col-12'}>
                <button className={'btn btn-third accept'}
                        onClick={() => onAccept()}>{t('ok')}</button>
            </div>
        </>
    )
}

export default withPolTranslation(PopupInfo);

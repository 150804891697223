import React, {useEffect} from 'react';
import {getApiUrl} from "../../routes";
import {gedData} from '../../api/ged/get';
import {connect} from 'react-redux';
import {Folder} from './_partials/folder/folder';
import {PolIcon} from "../PolIcon/policon";
import {sortByName} from "../../utils/gedDocumentSorter";

const FolderList = (props) => {
    //State

    //Props & Variables
    const {onRootReturn, onChangeItem, itemToEdit, handleEditedFile, t, editItemSpot, resetEditFile, gedData, isMobileDisplay = false} = props
    let {folderList} = props
    const folderListUpdate = folderList ? folderList.length : null
    const folderTree = [];
    folderList = sortByName(folderList, 'folder')

    //Fonctions

    const handleClickOnRoot = () => {
        const content = document.querySelector('.ged-component__folder-list-content');
        const header = document.querySelector('.ged-component__folder-list-header');
        content.classList.toggle("hidden");
        header.classList.toggle("isClose");
    }

    //Récupération des noms et des Ids de fichiers
    if (folderList !== undefined) {
        folderList.forEach(folder => {
            folderTree.push({
                folderName: folder.folderName,
                id: folder.id,
                subFolders: folder.customerResources
            })
        })
    }

    //componentDidUpdate

    useEffect(() => {
        gedData({
            method: 'GET',
            url: getApiUrl('ged', 'folderList'),
            type: 'folderList'
        })
    }, [folderListUpdate, gedData])

    return (
        <>
            <div className={'ged-component__folder-list-header col-12'} onClick={() => {
                onRootReturn()
                handleClickOnRoot()
            }}>
                <div>
                    <span>
                        <PolIcon icon={'folder'}/>
                        {t('documents')}
                    </span>
                    <PolIcon icon={'Polygone'}/>
                </div>
            </div>
            <div className={'ged-component__folder-list-content'}>
                {folderTree.length ? (
                    folderTree.map(folder => {
                        return (
                            <Folder {...props} folder={folder} editItemSpot={editItemSpot}
                                    resetEditFile={resetEditFile}
                                    handleEditedFile={handleEditedFile} itemToEdit={itemToEdit}
                                    hierarchyLevel={0} onChangeItem={onChangeItem} key={folder.id} isMobileDisplay={isMobileDisplay}/>
                        )
                    })
                ) : null}
            </div>
        </>
    )
}

const mapStateToProps = ({ged: {folderList}}) => ({
    folderList
});

const mapDispatchToProps = dispatch => {
    return {
        gedData: (info) => dispatch(gedData(info)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FolderList);
import React, {useEffect} from 'react';
import Sortable from "sortablejs";
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import useSetDOMInfos from "v4/pages/admin/ViewOrders/hooks/useSetDOMInfos";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ViewOrdersGrid({
                                           items,
                                           canPutInGroup = true,
                                           DOMInfos,
                                           dragType = 'default',
                                           dragHandleClassName = '',
                                           renderItem
                                       }) {
    const {t} = usePolTranslation();
    const {onDragEnd} = useViewOrdersContext();
    const gridRef = useSetDOMInfos(DOMInfos);

    useEffect(() => {
        if (!gridRef.current) return;

        const sortable = new Sortable(gridRef.current, {
            forceFallback: true, // https://github.com/SortableJS/Sortable/issues/1443#issuecomment-461508110
            scrollSensitivity: 60, // px from the edges to start scrolling
            scrollSpeed: 25,
            group: {
                name: dragType,
                put: canPutInGroup ? [dragType] : false,
            },
            ...(dragHandleClassName.length > 0 && {handle: `.${dragHandleClassName}`}),
            onEnd: e => {
                onDragEnd({
                    type: dragType,
                    item: e.item?.voInfos,
                    source: {
                        ...e.from?.voInfos,
                        index: e.oldIndex,
                    },
                    destination: {
                        ...e.to?.voInfos,
                        index: e.newIndex,
                    },
                })
            },
        });

        return () => sortable.destroy();
    }, [canPutInGroup, dragHandleClassName, dragType, gridRef, onDragEnd]);

    return (
        <div className={`new-view-orders__grid ${dragType}`}
             ref={gridRef}
             data-empty-text={t('empty')}>
            {items.map(renderItem)}
        </div>
    )
}

import React from 'react';
import GenericTableThead from "v4/components/ui/Table/components/TableThead/TableThead";
import GenericTableTbody from "v4/components/ui/Table/components/TableTbody/TableTbody";

export default function Table({tableRows, theadData, isLoading, isError, className = ""}) {
    return (
        <div className="ui-table-wrapper">
            <table className={`datatable table-v4 ${className}`}>
                <GenericTableThead theadData={theadData}/>
                <GenericTableTbody tableRows={tableRows} isLoading={isLoading} isError={isError}/>
            </table>
        </div>
    )
}

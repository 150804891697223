import React from 'react';
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {QUOTE_SEARCH} from "v4/data/apiRoutes";
import QuotesListing from "v4/pages/front/Quotes/components/QuotesListing/QuotesListing";

export default function QuotesPage({tab, isProspectPage}) {

    return (
        <SearchProvider searchRouteName={QUOTE_SEARCH} pageType="quotes" tab={tab}>
            <ToggleComponent isShowing={!isProspectPage} className="page page-list main-content__body__slide">
                <QuotesListing tab={tab}/>
            </ToggleComponent>
        </SearchProvider>
    );
}

import {removeBadges} from "v4/services/badges.service";
import {clearLSCache} from "v4/services/localStorage.service";
import UserService from "../api/user/user";

const actionAuth = (state = {error: null, load: null, lastVisits: []}, action) => {
    switch (action.type) {
        case 'SET_TOKEN':
            UserService.setTokens(action.token, action.refresh_token, action.refresh);
            return {...state, token: action.token, refresh_token: action.refresh_token};
        case 'REMOVE_TOKEN':
            UserService.removeTokens();
            return {...state, token: null, refresh_token: false};
        case 'REMOVE_USER':
            UserService.removeUser();
            return {...state, user: null};
        case 'LOG_OUT':
            UserService.removeTokens();
            UserService.removeUser()
            window.formCache = {};
            removeBadges();
            clearLSCache();
            return {...state, user: null, token: null, refresh_token: false, lastVisits: []};
        case 'SET_USER':
            UserService.setUser(action.user);
            return {...state, user: action.user};
        case 'LOAD_USER':
            return {...state, load: action.load, error: false};
        case 'BAD_CREDENTIALS':
            return {...state, error: action.error};
        case 'SET_LAST_VISITS':
            return {...state, lastVisits: action.lastVisits};
        case 'DELETE_LAST_VISITS':
            let newStateLastVisit = [];
            if(state.lastVisits.length) {
                state.lastVisits.forEach(item => {
                    if((item.prospectId !== action.prospectId) || (item.contactId !== action.contactId)) {
                        newStateLastVisit.push(item);
                    }
                });
            }

            return {
                ...state,
                lastVisits: newStateLastVisit
            };
        case 'PUSH_LAST_VISIT':
            let newLastVisit = [
                action.lastVisit
            ];
            if(state.lastVisits.length) {
                state.lastVisits.forEach(item => {
                    newLastVisit.push(item);
                });
            }
            return {
                ...state,
                lastVisits: newLastVisit
            };
        default:
            return state;
    }
};

export default actionAuth;

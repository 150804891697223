import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import Confirm from "v4/components/ui/Confirm/Confirm";
import {QUOTE_REGENERATE} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesReoffer/ProspectQuotesReoffer.scss';
import {generateUrl} from "v4/services/api.service";
import QuoteVoter from "v4/voters/quoteVoter";

export default function ProspectQuotesReoffer({isReofferPopupOpen, rowIdToReoffer, closePopup}) {
    const {t} = usePolTranslation();
    const {canQuoteRegeneratePrices} = QuoteVoter();
    const [shouldUpdatePrice, setShouldUpdatePrice] = useState(false);

    const {replace} = useHistory();
    const [{isLoading, isFinished, isError}, fetchDuplicate] = useFetch();

    const handleConfirm = () => {
        fetchDuplicate({
            url: generateUrl(QUOTE_REGENERATE, {id: rowIdToReoffer}),
            config: {
                method: 'POST',
                body: JSON.stringify({'update_price': shouldUpdatePrice}),
            }
        })
    }
    const handleCheckboxChange = () => setShouldUpdatePrice(c => !c);

    useEffect(() => {
        if (isFinished && !isError) {
            replace({state: {hasToRefresh: true, showHasToRefresh: true, dontRefreshProspectView: true}});
            closePopup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished, isError, replace])

    return (
        <Confirm title={t('refresh_quote')}
                 closeAction={closePopup}
                 isOpen={isReofferPopupOpen}
                 isLoading={isLoading}
                 confirmAction={handleConfirm}>
            <p>{t('confirm_reoffer_quote')}</p>
            {canQuoteRegeneratePrices() && (
                <div className="reoffer-checkbox">
                    <input type="checkbox" id="reoffer-checkbox" checked={shouldUpdatePrice}
                           onChange={handleCheckboxChange}/>
                    <label htmlFor="reoffer-checkbox">{t('update_price')}</label>
                </div>
            )}
        </Confirm>
    )
}

import React, {useEffect, useMemo, useState} from "react";
import SearchContextPaginationItem
    from "v4/components/utils/SearchContextPagination/components/SearchContextPaginationItem/SearchContextPaginationItem";
import "v4/components/utils/SearchContextPagination/SearchContextPagination.scss";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {debounce} from "v4/utils";

export default function SearchContextPagination({canChangeItemsPerPage = true, showWrapperEvenIfNoPages = false}) {
    const {
        currentPage,
        pages,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage
    } = useSearchContext();
    const [internalCurrentPage, setInternalCurrentPage] = useState(currentPage);

    useEffect(() => {
        if (currentPage !== internalCurrentPage) setInternalCurrentPage(currentPage);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        debounce(() => setCurrentPage(internalCurrentPage), 200); // 200 est la moyenne basse d'une latence dans un double clic
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalCurrentPage]);

    const pagesToShow = useMemo(() => {
        const isMobile = window.innerWidth <= 768;
        const totalItemsToDisplay = isMobile ? 3 : 5;
        const nbPagesAroundCurrentPage = isMobile ? 1 : 2;

        let start = Math.max(1, internalCurrentPage - nbPagesAroundCurrentPage);
        let end = Math.min(pages, internalCurrentPage + nbPagesAroundCurrentPage);

        const totalItems = end - start + 1;
        if (totalItems - totalItemsToDisplay < 0) {
            end += Math.abs(totalItems - totalItemsToDisplay);
        }

        const overPages = pages - end;
        if (overPages < 0) {
            start -= Math.abs(overPages);
        }

        start = Math.max(1, start);
        end = Math.min(pages, end);

        return generatePageRange(start, end);
    }, [internalCurrentPage, pages]);

    if (showWrapperEvenIfNoPages && pages < 1) return <div className="listing-pagination"/>;

    const handleChangeSelect = ({target: {value}}) => setItemsPerPage(value);

    return (
        pages > 0 && (
            <div className="listing-pagination">
                {canChangeItemsPerPage && (
                    <select defaultValue={itemsPerPage} onChange={handleChangeSelect}>
                        {[10, 25, 30, 50].map((item) => <option key={item} value={item}>{item}</option>)}
                    </select>
                )}
                {pages > 1 && (
                    <ul>
                        <SearchContextPaginationItem label={"<<"}
                                                     page={1}
                                                     isDisabled={1 >= internalCurrentPage}
                                                     onClick={setInternalCurrentPage}/>
                        <SearchContextPaginationItem label={"<"}
                                                     page={internalCurrentPage - 1}
                                                     isDisabled={1 >= internalCurrentPage}
                                                     onClick={setInternalCurrentPage}/>
                        {pagesToShow.map((page) => (
                            <SearchContextPaginationItem key={page}
                                                         label={page}
                                                         page={page}
                                                         isActive={page === internalCurrentPage}
                                                         onClick={setInternalCurrentPage}/>
                        ))}
                        <SearchContextPaginationItem label={">"}
                                                     page={internalCurrentPage + 1}
                                                     isDisabled={pages === internalCurrentPage}
                                                     onClick={setInternalCurrentPage}/>
                        <SearchContextPaginationItem label={" >> "}
                                                     page={pages}
                                                     isDisabled={pages === internalCurrentPage}
                                                     onClick={setInternalCurrentPage}/>
                    </ul>
                )}
            </div>
        )
    );
}

function generatePageRange(start, end) {
    return Array.from({length: end - start + 1}, (_, index) => start + index);
}

import FileSaver from 'file-saver';
import * as moment from "moment";

/**
 * Prepare data for a csv export and export
 *
 * @param columns
 * @param data
 * @param fieldKey
 * @param forceFileName
 * @param t
 */
export const generateCsv = (columns, data, fieldKey, forceFileName, t) => {
    const separator = ";";
    const fileName = forceFileName ? forceFileName + (forceFileName.search('.csv') === -1 ? '.csv' : '') : 'export-pol.csv';

    const meta = columns
        .map(column => ({
            field: fieldKey && column[fieldKey] ? column[fieldKey] : column.dataField,
            header: column.text,
            export: column.csvExport !== false
        }))
        .filter(_ => _.export);

    let content = meta.map(m => `"${m.header}"`).join(separator) + '\n';
    content += data.map(m =>
        meta.map(c => {
            let value = c.field.split('.').reduce(index, m);
            value = typeof value === 'object' && value !== null ? value.value : value;
            const isDate = value && (value.length === 10 || value.length === 25) &&
                !isNaN(Date.parse(value)) && moment(value.toString(), moment.ISO_8601).isValid()
            value = isDate ? moment(value.toString()).format('DD/MM/YYYY HH:mm') : value;
            if (typeof value ==='string') {
                const div = document.createElement("div");
                div.innerHTML = value;
                value = div.textContent || div.innerText || "";
            }

            if ('boolean' === typeof value) {
                value = value ? t('oui') : t('non');
            }

            return value !== undefined && value !== null ? `"${value}"` : '';
        }).join(separator)).join('\n');

    FileSaver.saveAs(
        new Blob(["\uFEFF" + content], {type: "text/plain;charset=utf-8"}),
        fileName,
        true
    );
}

/**
 * read array for find the smallest information
 * combine with the split and reduce 'a.b.c' => obj.a.b.c
 *
 * @param obj
 * @param i
 */
const index = (obj, i) => {
    if (obj !== undefined && obj !== null) {
        return obj[i];
    }
    return '';
}

import React from 'react'
import {withPolTranslation} from "v4/hooks/usePolTranslation";

const ImportInfo = ({dataLength, entityToImport, t, colsLength, affectedColsLength, importableObjects}) => {

    return (
        <div className={'admin__field__info'}>
            <span className={'admin-info'}/>
            <div className={'admin-info__flex-cols'}>
                {dataLength > 500 ? <p className={'admin-info-error'}> {t('import_big_data', {count: 500})} </p> : null}
                <p>{`${dataLength} ${entityToImport} ${t('found')}`}</p>
                <p>{`${affectedColsLength} / ${colsLength} ${t('affected_columns')}`}</p>
                {importableObjects > 0 ? (
                    <p>{`${importableObjects} ${entityToImport} ${t('importable_objects')}`}</p>
                ) : null}
            </div>
        </div>
    )
}



export default (withPolTranslation(ImportInfo));

import DashboardImage from 'img/illustration-homepage.svg';
import React from 'react';
import DashboardQuotesGraph from "v4/pages/front/Dashboard/components/DashboardQuotesGraph/DashboardQuotesGraph";
import DashboardQuotesListing from "v4/pages/front/Dashboard/components/DashboardQuotesListing/DashboardQuotesListing";
import DashboardQuotesSummary from "v4/pages/front/Dashboard/components/DashboardQuotesSummary/DashboardQuotesSummary";
import DashboardTasksListing from "v4/pages/front/Dashboard/components/DashboardTasksListing/DashboardTasksListing";
import DashboardTasksSummary from "v4/pages/front/Dashboard/components/DashboardTasksSummary/DashboardTasksSummary";
import 'v4/pages/front/Dashboard/DashboardPage.scss';

export default function DashboardPage() {
    return (
        <div className="dashboard-page">
            <DashboardTasksListing/>
            <DashboardQuotesListing/>
            <img src={DashboardImage} alt="DashboardImage"/>
            <DashboardQuotesGraph/>
            <DashboardTasksSummary/>
            <DashboardQuotesSummary/>
        </div>
    )
}

import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import AdminSimpleTable from "v4/components/ui/AdminSimpleTable/AdminSimpleTable";
import AdminSimpleTableHeader
    from "v4/components/ui/AdminSimpleTable/components/AdminSimpleTableHeader/AdminSimpleTableHeader";
import AdminSimpleTableBody
    from "v4/components/ui/AdminSimpleTable/components/AdminSimpleTableBody/AdminSimpleTableBody";
import {PolIcon} from "components/PolIcon/policon";

export default function AutomatedStatusListing({automatedStatus, onEdit, onDelete}) {
    const {t} = usePolTranslation();

    return (
        <AdminSimpleTable>
            <AdminSimpleTableHeader headers={['from_status', 'to_status', 'triggered_by', 'actions']}/>
            <AdminSimpleTableBody
                rows={automatedStatus}
                keysToRender={['fromQuoteStatusLabel', 'toQuoteStatusLabel', 'triggeredBy', 'actions']}
                renderNotFoundCell={(row, key) => (
                    key === 'actions'
                        ? (
                            <div className="automated-status__actions">
                                <button onClick={() => onEdit(row.id)}
                                        className="btn btn-primary"
                                        title={t('edit')}>
                                    <PolIcon icon="edit"/>
                                </button>
                                <button onClick={() => onDelete(row.id)}
                                        className="btn btn-primary"
                                        title={t('delete')}>
                                    <PolIcon icon="trash"/>
                                </button>
                            </div>
                        ) : '-'
                )}/>
        </AdminSimpleTable>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import iconMain from 'img/main_marker.svg';
import iconClient from "img/marker_client.svg";
import iconProspect from "img/marker_prospect.svg";
import L from "leaflet";
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Map, Marker, TileLayer} from "react-leaflet";
import Loader from "v4/components/ui/Loader/Loader";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import ProspectsListingMapsDraggable
    from "v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingMaps/components/ProspectsListingMapsDraggable/ProspectsListingMapsDraggable";
import 'v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingMaps/ProspectsListingMaps.scss';

const markerIcon = {
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
    shadowSize: null,
    shadowAnchor: null
};

export default function ProspectsListingMaps({entitiesList, isLoading}) {
    const {t} = usePolTranslation();
    const [showHelp, toggleHelp] = useToggle(true);
    const [selectedProspect, setSelectedProspect] = useState(null);

    const markers = useMemo(
        () => entitiesList.results?.reduce((acc, {extraFields, metadata}) => {
            const {id, isClient} = metadata;
            const {address} = extraFields;
            if (address?.lng && address?.lat) {
                acc.push(
                    {
                        lat: address.lat,
                        lng: address.lng,
                        prospectId: id,
                        isMain: false,
                        isClient: isClient ?? false,
                    }
                );
            }
            return acc;
        }, []), [entitiesList]);

    const [map, setMap] = useState({
        lat: 51.505,
        lng: -0.09,
        zoom: 8,
    });

    const mapPosition = useMemo(() => [map.lat, map.lng], [map]);
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);

    useEffect(() => {
        if (markers && markers.length) {
            const {lat, lng} = markers[0];
            setMap({lat, lng, zoom: mapRef.current?.leafletElement?.getZoom() ?? 14});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markers]);

    const onMarkerClick = useCallback((prospectId) => () => setSelectedProspect(entitiesList.results?.find(({metadata}) => metadata.id === prospectId)), [entitiesList]);

    const removeSelectedProspect = () => setSelectedProspect(null);

    useEffect(() => {
        if (selectedProspect) setSelectedProspect(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <div className="prospects-listing-maps" ref={mapContainerRef}>
            {isLoading && <Loader className="prospects-listing-maps__loader"/>}
            <div className={`prospects-listing-maps__help${showHelp ? ' show' : ''}`}>
                <PolIcon icon={showHelp ? 'close' : 'question'} onClick={toggleHelp}/>
                <div>
                    <p>{t('geolocations_text_1')}</p>
                    <p>{t('geolocations_text_2')}</p>
                </div>
            </div>

            <ToggleComponent isShowing={selectedProspect}
                             shouldRemoveFromDom={true}>
                <ProspectsListingMapsDraggable selectedProspect={selectedProspect}
                                               removeSelectedProspect={removeSelectedProspect}
                                               offsetParent={mapContainerRef.current}
                                               bounds=".prospects-listing-maps"/>
            </ToggleComponent>

            {Object.keys(entitiesList).length > 0
                ? (
                    <Map center={mapPosition}
                         zoom={map.zoom}
                         animate={true}
                         ref={mapRef}
                         whenReady={onMapReady}>
                        <TileLayer
                            attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"/>
                        {markers.map((marker, key) => {
                            const newIcon = L.icon({
                                ...markerIcon,
                                className: `mapspage__marker`,
                                iconUrl: getIcon(marker),
                            });

                            return <Marker key={key}
                                           position={[marker.lat, marker.lng]}
                                           icon={newIcon}
                                           onClick={onMarkerClick(marker.prospectId)}/>
                        })}
                    </Map>
                )
                : <Loader/>
            }
        </div>
    )
}

function onMapReady(map) {
    map.target._container.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
    });
}

function getIcon(marker) {
    if (marker.isMain) {
        return iconMain;
    }
    if (marker.isClient) {
        return iconClient;
    }

    return iconProspect;
}

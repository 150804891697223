import React, {useEffect, useMemo, useState} from 'react';
import {useHistory, useRouteMatch} from "react-router-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {TASK_ADD, TASK_FORM, TASK_ITEM, TASK_ITEM_FORM} from "v4/data/apiRoutes";
import {TAB_OPEN_TASKS} from "v4/data/tabNames";
import useTestRoute from "v4/hooks/useTestRoute";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import ShowEntityLayout from "v4/layouts/ShowEntityLayout/ShowEntityLayout";
import ProspectOpenTasksTabListing
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOpenTasksTab/components/ProspectOpenTasksTabListing/ProspectOpenTasksTabListing";
import ProspectOpenTasksTabResume from "./components/ProspectOpenTasksTabResume/ProspectOpenTasksTabResume";
import ProspectOldMailTasksTabStats
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOldMailTasksTab/components/ProspectOldMailTasksTabStats/ProspectOldMailTasksTabStats";
import MoveTaskButton from "v4/features/front/moveTask/components/MoveTaskButton/MoveTaskButton";
import ProspectTabBackButton from "v4/components/utils/ProspectTabBackButton/ProspectTabBackButton";
import ProspectTabEditButton from "v4/components/utils/ProspectTabEditButton/ProspectTabEditButton";
import ProspectTabShowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTabShowActions/ProspectTabShowActions";


export default function ProspectOpenTasksTab({prospectId}) {
    const isShow = useTestRoute({inParams: 'showId'});
    const isEdit = useTestRoute({inUrl: 'edit'});
    const isAdd = useTestRoute({inUrl: 'add'});
    const isResume = useTestRoute({inUrl: 'resume'});
    const isListing = !isShow && !isEdit && !isAdd && !isResume;
    const {params: {showId = null}} = useRouteMatch();
    const [fetchFormParameters, setFetchFormParameters] = useState(null);
    const [taskData, setTaskData] = useState(null);

    const {location: {state, search}, replace} = useHistory();
    const queryParameters = new URLSearchParams(search);
    const quoteId = queryParameters.get('quoteId') ?? null;

    useEffect(() => {
        const {relaunchId, ...newState} = state ?? {};
        if (relaunchId) {
            replace({state: newState})
            setFetchFormParameters({quoteId: relaunchId});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, setFetchFormParameters]);

    const finalFetchFormParameters = useMemo(() => ({
        id: showId,
        prospectId,
        quoteId,
        ...fetchFormParameters
    }), [quoteId, showId, prospectId, fetchFormParameters]);

    const onGetData = (data) => {
        setTaskData(data?.['hydra:member'] ?? data);
    }

    return (
        <>
            <ToggleComponent isShowing={isListing}>
                <ProspectOpenTasksTabListing prospectId={prospectId}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isShow && !isEdit}
                             shouldRemoveFromDom={true}
                             className="prospect-tab__prospect showing">
                <div className="prospect-tab__prospect__header">
                    <ProspectTabBackButton/>
                    <ProspectTabEditButton showId={showId}/>
                    <MoveTaskButton taskId={showId} className="prospect-tab__prospect__header__action"/>
                    {taskData?.metadata?.showActions && (
                        <ProspectTabShowActions showId={showId}
                                                prospectId={prospectId}
                                                showActions={taskData.metadata.showActions}/>
                    )}
                </div>
                <ShowEntityLayout entityId={showId}
                                  onGetData={onGetData}
                                  entityRouteName={TASK_ITEM_FORM}>
                    <ProspectOldMailTasksTabStats entityId={showId}/>
                </ShowEntityLayout>
            </ToggleComponent>

            <ToggleComponent isShowing={isAdd || isEdit}
                             shouldRemoveFromDom={true}
                             className="prospect-tab__prospect editing">
                <FormEntityLayout title="actions"
                                  formRouteName={isAdd ? TASK_FORM : TASK_ITEM_FORM}
                                  submitRouteName={isAdd ? TASK_ADD : TASK_ITEM}
                                  fetchFormParameters={finalFetchFormParameters}
                                  whenSubmitted={whenSubmitted}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isResume}
                             shouldRemoveFromDom={true}
                             className="resume-tab">
                <ProspectOpenTasksTabResume prospectId={prospectId}/>
            </ToggleComponent>

        </>
    )
}

function whenSubmitted(history) {
    history.replace(`${history.location.pathname.split(TAB_OPEN_TASKS)?.shift()}${TAB_OPEN_TASKS}`, {hasToRefresh: true});
}

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {postForm} from "../../../api/form/form";
import Form from '../../../components/Admin/_partials/form_field'
import AdminDatatables from "../../../components/Admin/_partials/datatables";
import {adminFetchHandler, manageError, sortFunc} from "../../../api/datas/datas";
import {arrowSort} from "../../../components/Datatables/_partials/config";
import {getLSItem} from "../../../v4/services/localStorage.service";

const initColumns = [
    {
        text: "Nom",
        dataField: 'name',
        sortCaret: (order, column) => arrowSort(order, column),
        sortFunc: sortFunc,
        sort: true
    },
    {
        text: 'entity',
        dataField: 'entity',
        sortCaret: (order, column) => arrowSort(order, column),
        sortFunc: sortFunc,
        sort: true
    },
    {
        text: "isRequired",
        dataField: 'isRequired',
        formatter: (cellContent, row) => (
            <div className="checkbox disabled">
                <label>
                    <input type="checkbox" checked={row.isRequired} disabled/>
                </label>
            </div>
        ),
        sortCaret: (order, column) => arrowSort(order, column),
        sortFunc: sortFunc,
        sort: true
    },
];

const {isSuperAdmin} = getLSItem('user');

class Listing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            view: null,
            loader: true,
            infoTable: {
                url: '/api/fields',
                deleteUrl: '/api/field/{id}',
                loader: 'datatable-field',
                queryParams: isSuperAdmin ? null : [{key: 'isSystem', value: '0'}]
            },
            columns: initColumns,
            idItem: null
        }
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        const {match: {params}} = this.props;

        let view = 'table';
        let idParam = null;

        if (params && params.id) {
            view = 'form';
            idParam = params.id;
        }

        this.setState({
            view: view,
            idItem: idParam,
        })
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    getView = () => {
        const {t} = this.props;
        const {view, infoTable, columns, data, idItem} = this.state;
        const dataForm = data ? data : {};

        switch (view) {
            case('table'):
                return (
                    <>
                        <AdminDatatables info={infoTable}
                                         columns={columns}
                                         changeView={(item) => {
                                             this.setState({
                                                 redirect: <Redirect
                                                     to={`/administration/gestion_champs/${item.id}`}/>
                                             })
                                         }}
                        />
                        <div className={'row align-items-center justify-content-end'}>
                            <div className={`col-12 col-md-auto form__submit`}>
                                <button className={'btn btn-primary form__submit__btn'} onClick={() => this.setState({
                                    view: 'form'
                                })}>
                                    {t('ajouter_champ')}
                                </button>
                            </div>
                        </div>
                    </>
                );
            case('form'):
                return (
                    <div className={'page-details'}>
                        <Form data={dataForm} idItem={idItem}/>
                    </div>
                );
            default:
                return null
        }
    };

    render() {
        const {t} = this.props;
        const {redirect} = this.state;
        const view = this.getView();

        return (
            <>
                {redirect}

                <div className="page page-admin content-wrapper">
                    <section className={'box'}>
                        <div className={'box__header'}>
                            <p className={'box__header__text'}>{t('admin_gestion_fields')}</p>
                        </div>
                        <div className={'box__body admin-field'}>
                            <div className={'admin-field__content'}>
                                {view}
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
    return {
        postForm: (info) => dispatch(postForm(info)),
        manageError: (code, error) => dispatch(manageError(code, error)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(Listing));

import {setAdmin} from "actions/router";
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {setStickyMenu} from "../../../actions/admin";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {PolIcon} from "../../PolIcon/policon";

class Header extends Component {
    render() {
        const {t, setAdmin} = this.props
        return (
            <header className={`admin__header bg-env-${process.env.REACT_APP_ENV}`}>
                <Link className={`admin__header__logo bg-env-${process.env.REACT_APP_ENV}`} to={'/'} onClick={setAdmin}>
                    Prospect On Line
                </Link>
                <nav className="row align-items-center justify-content-between admin__header__nav">
                    <div className={'admin__header__toggle'} title={t('menu_deploy')}  onClick={() => {
                        this.props.stickyMenu(!this.props.admin.isSticky)
                    }}>
                        <PolIcon icon={'bars'}/>
                    </div>
                    <ul className={'nav'}>
                        <Link to={'/'} onClick={setAdmin}>
                            <li className={'nav__item'} title={t('back_to_site')}>
                                <PolIcon icon={'home-pol'}/>
                            </li>
                        </Link>
                    </ul>
                </nav>
            </header>
        )
    }
}

const mapStateToProps = ({admin}) => ({
    admin
});

const mapDispatchToProps = dispatch => {
    return {
        stickyMenu: (sticky) => dispatch(setStickyMenu(sticky)),
        setAdmin: () => dispatch(setAdmin(false))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Header));

import {useEffect, useMemo} from 'react';
import {QUOTE_TASKS} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

export default function useManageTasks(quote) {
    const [{data, isLoading}, fetchTasks] = useFetch();

    useEffect(() => {
        if (quote) {
            fetchTasks({
                url: generateUrl(QUOTE_TASKS, {id: quote.metadata.id})
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quote]);

    const taskLines = useMemo(() => data?.data ?? [], [data]);

    return [isLoading, taskLines];
}

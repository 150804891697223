import React, {useContext} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useRouteMatch} from "react-router-dom";
import {AdminContext} from "v4/contexts/AdminContext";
import OutlookSubscriptionConfigurationsList
    from "v4/pages/admin/OutlookSubscriptionConfigurations/OutlookSubscriptionConfigurationsList/OutlookSubscriptionConfigurationsList";
import OutlookSubscriptionConfigurationsForm
    from "v4/pages/admin/OutlookSubscriptionConfigurations/OutlookSubscriptionConfigurationsForm/OutlookSubscriptionConfigurationsForm";
import OutlookSubscriptionConfigurationUsersList
    from "v4/pages/admin/OutlookSubscriptionConfigurations/OutlookSubscriptionConfigurationUsersList/OutlookSubscriptionConfigurationUsersList";
import OutlookSubscriptionConfigurationUsersForm
    from "v4/pages/admin/OutlookSubscriptionConfigurations/OutlookSubscriptionConfigurationUsersForm/OutlookSubscriptionConfigurationUsersForm";

export default function OutlookSubscriptionConfigurationsPage() {
    const {t} = usePolTranslation();
    const {path, params: {id, subscriptionConfigId}, isExact} = useRouteMatch();
    const isAddPage = path.split('/').pop() === 'add';
    const isEditPage = !!id && isExact;
    const isListPage = (!isAddPage && !isEditPage);
    const {asCustomer} = useContext(AdminContext);

    const getComponent = () => {
        if (subscriptionConfigId) {
            if (isListPage) {
                return <OutlookSubscriptionConfigurationUsersList subscriptionConfigId={subscriptionConfigId} customerId={asCustomer?.id}/>
            }

            return <OutlookSubscriptionConfigurationUsersForm id={id} subscriptionConfigId={subscriptionConfigId} customerId={asCustomer?.id}/>
        }

        if (isListPage) {
            return <OutlookSubscriptionConfigurationsList customerId={asCustomer?.id}/>
        }

        return <OutlookSubscriptionConfigurationsForm id={id} subscriptionConfigId={subscriptionConfigId} customerId={asCustomer?.id}/>
    }

    return (
        <>
            {asCustomer?.id ?
                (
                    <>
                        {getComponent()}
                    </>
                ) : (
                    <p>{t('no_customer_defined')}</p>
                )
            }
        </>
    )

}

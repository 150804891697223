import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ReactTooltip from "react-tooltip";

export default function ProspectQuotesTabActions({prospect}) {
    const {t} = usePolTranslation();
    const {isUnknown = null} = prospect?.metadata ?? {};

    return (
        (isUnknown || isUnknown === null)
            ? (
                <>
                    <button className="prospect-tab__action"
                            data-disabled="true"
                            data-tip={t('no_add_quote_unknown')}
                            data-for="not-allowed">
                        <PolIcon icon="plus-circle"/>
                        <span>{t('add_devis_offres')}</span>
                    </button>
                    <ReactTooltip effect="solid" id="not-allowed"/>
                </>
            )
            : (
                <Link to={({pathname}) => `${pathname}/add`} className="prospect-tab__action">
                    <PolIcon icon="plus-circle"/>
                    <span>{t('add_devis_offres')}</span>
                </Link>
            )
    )
}

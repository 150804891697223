import React, {createContext, useContext, useLayoutEffect, useReducer} from 'react';
import {randomId} from "v4/utils";

const defaultValues = {
    quoteLinesMethods: {},
    sectionsNames: [],
    properties: {},
    updateSectionName: () => {
    },
    addSection: () => {
    },
    removeSection: () => {
    },
}

const quotesProductsDetailsContext = createContext(defaultValues)

export const useQuotesProductsDetailsContext = () => useContext(quotesProductsDetailsContext);

const quotesProductsDetailsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_SECTIONS_NAMES':
            return {
                ...state,
                sectionsNames: action.payload.reduce((acc, sectionName) => {
                    // remove same names
                    if (acc.some(({name}) => name === sectionName.name)) {
                        return acc;
                    }
                    return [
                        ...acc,
                        sectionName,
                    ]
                }, []),
            }
        case 'SET_SECTION_NAME': {
            const {sectionIndex, sectionName, sectionId} = action.payload;
            return {
                ...state,
                sectionsNames: state.sectionsNames.map((group) => {
                    if (group.id === sectionId) {
                        return {
                            ...group,
                            name: `${sectionIndex}|${sectionName}|${sectionId}`,
                        }
                    }
                    return group;
                }),
            }
        }
        case 'ADD_SECTION':
            const id = randomId();
            return {
                ...state,
                sectionsNames: [
                    ...state.sectionsNames,
                    {
                        name: `${state.sectionsNames.length}|${action.payload ?? ''}|${id}`,
                        id,
                    }
                ],
            }
        case 'REMOVE_SECTION':
            return {
                ...state,
                sectionsNames: state.sectionsNames.filter(({id}) => id !== action.payload),
            }
        default:
            return state
    }
}

export default function QuotesProductsDetailsProvider({quoteLinesMethods, properties, children}) {
    const [state, dispatch] = useReducer(quotesProductsDetailsReducer, defaultValues);

    useLayoutEffect(() => {
        const {fields} = quoteLinesMethods;
        if (fields.length > 0) {
            const sectionNames = [...new Set(fields.map(({sectionName}) => sectionName).filter(Boolean))].sort();
            const newSectionNames = differenceBetweenArrays(sectionNames, state.sectionsNames.map(({name}) => name));
            const fieldsWithNoSection = fields.filter(({sectionName}) => !sectionName);
            let payload = [
                ...state.sectionsNames,
                ...newSectionNames.map((sectionName) => {
                    const [, , id] = sectionName.split('|');

                    return {
                        name: sectionName,
                        id: id ?? randomId(),
                    }
                })
            ];
            if (fieldsWithNoSection.length > 0 && !state.sectionsNames.some(({isDefault}) => isDefault)) {
                payload.push({
                    name: '',
                    id: randomId(),
                    isDefault: true,
                });
            } else if (fieldsWithNoSection.length === 0 && state.sectionsNames.some(({isDefault}) => isDefault)) {
                const defaultSection = payload.find(({isDefault}) => isDefault);
                const otherSections = payload.filter(({isDefault}) => !isDefault)
                if (defaultSection) {
                    payload = [
                        ...otherSections,
                        {
                            ...defaultSection,
                            isDefault: false
                        }
                    ]
                }
            }

            dispatch({type: 'SET_SECTIONS_NAMES', payload,});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteLinesMethods.fields]);

    const updateSectionName = (sectionIndex, sectionId, sectionName) => {
        dispatch({type: 'SET_SECTION_NAME', payload: {sectionIndex, sectionId, sectionName}});
    }

    const addSection = () => {
        dispatch({type: 'ADD_SECTION'});
    }

    const removeSection = (sectionId) => {
        dispatch({type: 'REMOVE_SECTION', payload: sectionId});
    }

    const quoteLinesDetailsValues = {
        ...state,
        quoteLinesMethods,
        properties,
        updateSectionName,
        addSection,
        removeSection,
    };

    return (
        <quotesProductsDetailsContext.Provider value={quoteLinesDetailsValues}>
            {children}
        </quotesProductsDetailsContext.Provider>
    )
}

function differenceBetweenArrays(array1, array2) {
    return array1.filter((value) => !array2.includes(value));
}

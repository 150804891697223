import React, {useRef} from 'react';
import Select from "react-select";

import 'v4/components/form/Field/components/InputCompare/InputCompare.scss';
import usePrevious from "v4/hooks/usePrevious";

export default function InputCompare({onChange, type, fieldValue, htmlConstraints}) {
    const prevValues = usePrevious(fieldValue);

    const parentDiv = useRef(null);

    const options = [
        {label: '=', value: '='},
        {label: '>', value: '>'},
        {label: '<', value: '<'},
        {label: '><', value: '><'}
    ];

    const handleInputChange = ({target: {name, value}}) => {
        let formattedValue = value;
        if (type === 'date') {
            formattedValue = reverseDate(value);
        }
        return onChange({...fieldValue, [name]: formattedValue});
    }

    const reverseDate = (date) => {
        return date?.split('-').reverse().join('-');
    }

    const handleSelectChange = (option) => {
        const {value} = option;
        if (value === '=' || value === '>') {
            if (['<', '><'].includes(prevValues.equal) && prevValues.end !== '') {
                return onChange({...fieldValue, equal: value, begin: prevValues.end, end: ''});
            }

            return onChange({...fieldValue, equal: value, end: ''});
        }

        if (value === '<') {
            if (prevValues.begin !== '') {
                return onChange({...fieldValue, equal: value, begin: '', end: prevValues.begin});
            }

            return onChange({...fieldValue, equal: value, begin: '', end: prevValues.end});
        }

        return onChange({...fieldValue, equal: value});
    }

    const createInput = (name) => {
        if (type === 'date') {
            const dependantAttributes = name === 'begin' ? {max: reverseDate(fieldValue.end)} : {min: reverseDate(fieldValue.begin)};
            return <input type={fieldValue[name]?.length > 0 ? "date" : "text"} name={name}
                          onChange={handleInputChange}
                          onFocus={(e) => {
                              if (fieldValue[name]?.length === 0) {
                                  e.target.type = 'date';
                              }
                              try {
                                  e.target.showPicker();
                              } catch (e) {
                              }
                              parentDiv.current.dataset.focus = "true";
                          }}
                          onBlur={(e) => {
                              if (fieldValue[name]?.length === 0) {
                                  e.target.type = 'text';
                              }
                              parentDiv.current.dataset.focus = "false";
                          }}
                          {...dependantAttributes}
                          value={reverseDate(fieldValue[name])}
                          placeholder="" {...htmlConstraints}/>
        }
        return <input type='number' name={name}
                      onChange={handleInputChange}
                      onFocus={() => parentDiv.current.dataset.focus = "true"}
                      onBlur={() => parentDiv.current.dataset.focus = "false"}
                      value={fieldValue[name]}
                      placeholder="" {...htmlConstraints}/>
    }

    return (
        <div className={`input-compare${fieldValue.equal === "><" ? ' splited' : ''}`}
             ref={parentDiv} data-focus="false" data-is-required={!!htmlConstraints?.required}>
            <Select name="equal"
                    {...htmlConstraints}
                    options={options}
                    value={options.find(option => option.value === fieldValue.equal)}
                    onChange={handleSelectChange}
                    isSearchable={false}
                    className="input-compare__container"
                    classNamePrefix="input-compare"
            />
            {['=', '>', "><"].includes(fieldValue.equal) && createInput('begin')}
            {['<', '><'].includes(fieldValue.equal) && createInput('end')}
        </div>
    );
}

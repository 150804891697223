import {Document} from "components/View/document";
import React, {useEffect, useState} from "react";
import CustomReactSelect from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import Loader from "v4/components/ui/Loader/Loader";
import {TEMPLATE_LIST} from "v4/data/apiRoutes";

import 'v4/features/front/generatePdf/components/GeneratePdf/GeneratePdf.scss'
import useTemplatePdf from "v4/features/front/generatePdf/hooks/useTemplatePdf";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";

export default function GeneratePdf({toggleGeneratePopup, PDFMode, quoteId, updateHandler}) {
    const {t} = usePolTranslation();
    const [{data: templates, isFinished, isError, isLoading}, fetchTemplates] = useFetch();
    const [template, setTemplate] = useState(null);
    const [showPrices, setShowPrices] = useState(false);

    const {
        generate,
        isLoadingGenerate,
        isFinishedGenerate,
        isDownloadReady,
        setIsDownloadReady,
    } = useTemplatePdf(updateHandler);

    useEffect(() => {
        setIsDownloadReady(false)
        fetchTemplates({
            url: generateUrl(TEMPLATE_LIST)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleCheckboxShowPricesChange = ({currentTarget: {checked}}) => setShowPrices(checked)

    const handleGeneratePdf = () => generate({quoteId, template, showPrices, mode: PDFMode});

    if (isLoading) {
        return <p>{t('chargement')} ...</p>
    }

    return (
        <>
            {!isDownloadReady && isFinished && !isError && templates?.['hydra:member'] &&
                <>
                    <div className="form-group mb-3">
                        <CustomReactSelect
                            value={template}
                            options={templates['hydra:member'].map((item) => ({
                                'value': item.id,
                                'label': item.name
                            }))}
                            selectParams={{
                                menuPosition: 'fixed',
                            }}
                            isMulti={false}
                            onChange={setTemplate} placeholder={t('template')}/>
                    </div>

                    <div className="form-group mb-3">
                        <div className="form-control form-control-checkbox">
                            <input type="checkbox" id="generate_pdf_show_prices" checked={showPrices}
                                   onChange={handleCheckboxShowPricesChange}/>
                            <label htmlFor="generate_pdf_show_prices">
                                {t('hide_prices_in_quote_lines')}
                            </label>
                        </div>
                    </div>

                    {!PDFMode && (
                        <div className="form-group mb-3">
                            <p>{t('warning_generate_pdf')}</p>
                        </div>
                    )}

                    <div className="quotes-generate-pdf__body__buttons">
                        <button className="quotes-generate-pdf__body__buttons-return"
                                onClick={toggleGeneratePopup}>
                            {t('cancel')}
                        </button>
                        <button disabled={!template}
                                className="quotes-generate-pdf__body__buttons-validate"
                                onClick={handleGeneratePdf}>
                            {t('generate')}
                        </button>
                    </div>
                </>
            }

            {isLoadingGenerate &&
                <div className="quotes-generate-pdf__loader">
                    <Loader message=""/>
                </div>
            }

            {isFinishedGenerate && isDownloadReady &&
                <>
                    <Document doc={isDownloadReady}/>
                    <div className="quotes-generate-pdf__body__buttons">
                        <button className={'quotes-generate-pdf__body__buttons-return'}
                                onClick={toggleGeneratePopup}>
                            {t('close')}
                        </button>
                    </div>
                </>
            }
        </>
    )
}

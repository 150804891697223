import {captureException} from "@sentry/react";
import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default React.memo(function EntityLoop({
                                                  inputsGroups,
                                                  loopClassName = '',
                                                  groupClassName = '',
                                                  childrenClassName = '',
                                                  forceSpaceGroupName = true,
                                                  children
                                              }) {
    const {t} = usePolTranslation();

    if (typeof children !== 'function') {
        const err = new Error('EntityLoop: children must be a function');
        captureException(err);
        throw err;
    }

    return (
        <div className={`row ${loopClassName}`}>
            {Object.entries(inputsGroups.results).map((group) => {
                const [groupKey, {properties, groupName, attr}] = group;
                return (
                    <div key={groupKey}
                         className={getGroupClassNameWidth(attr?.['data-vo-width'])}>
                        <div className={`${groupClassName}`}>
                            <h3>{groupName && t(groupName)} {forceSpaceGroupName && <>&zwj;</>}</h3> {/* &zwj; is an invisible character to prevent h3 from collapsing */}
                            <div className={`${groupClassName}__fields`}>
                                <div className="row">
                                    {properties.map(input => (
                                        input.type !== 'HiddenType'
                                            ? (
                                                <div key={input.key}
                                                     className={`${getGroupClassNameWidth(input?.attr['data-vo-width'])} ${childrenClassName}`}>
                                                    {children(input, inputsGroups?.metadata ?? {}, shouldShowLabel(input))}
                                                </div>
                                            )
                                            : children(input, inputsGroups?.metadata ?? {}, false)
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
})

function shouldShowLabel(input) {
    const {entry_type} = input;
    const {'display-fields': displayFields} = input.attr ?? {};

    if (displayFields) return false;

    return entry_type !== 'QuoteLineType';
}

function getGroupClassNameWidth(width) {
    switch (String(width)) {
        case '100':
            return 'col-md-12';
        case '75':
            return 'col-md-8';
        case '50':
            return 'col-md-6';
        case '33':
            return 'col-md-4';
        case '25':
            return 'col-md-3';
        default:
            return 'col-md-12';
    }
}

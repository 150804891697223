import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {useParams} from "react-router-dom";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import ManageTasks
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/components/ManageTasks/ManageTasks";
import "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/QuoteTasksButton.scss";
import QuoteTasksNewButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/components/QuoteTasksNewButton/QuoteTasksNewButton";

export default function QuoteTasksButton({quote}) {
    const {t} = usePolTranslation();
    const [isOpen, toggleOpen] = useToggle();
    const {prospectId} = useParams();

    return (
        <>
            <button type="button"
                    className="prospect-tab__prospect__header__action tasks"
                    onClick={toggleOpen}>
                <PolIcon icon='file-export-solid'/>
                <span>{t('manage_tasks')}</span>
            </button>
            <PolPopup isOpen={isOpen}
                      closePopup={toggleOpen}
                      title={t('manage_tasks')}
                      buttonsRender={() => (
                          <QuoteTasksNewButton quoteId={quote.metadata.id}
                                               prospectId={prospectId}
                                               closePopup={toggleOpen}/>
                      )}>
                {isOpen && <ManageTasks quote={quote}/>}
            </PolPopup>
        </>
    )
}

export const setHistory = (route) => {
    return {
        type: 'SET_HISTORY',
        route,
    }
};

export const setExpandInfo = (expanded) => {
    return {
        type: 'SET_EXPAND_INFO',
        expanded,
    }
};

export const setPopup = (popupInfo) => {
    return {
        type: 'SET_POPUP',
        popupInfo,
    }
};

export const setLoader = (loader) => {
    return {
        type: 'SET_LOADER',
        loader,
    }
};

export const resetLoader = () => {
    return {
        type: 'RESET_LOADER',
    }
};

export const resetStoreContent = () => {
    return {
        type: 'RESET_STORE',
    }
};

export const setModifyInfo = (modifyInfo) => {
    return {
        type: 'SET_MODIFY_INFO',
        modifyInfo,
    }
};

export const setAddInfo = (addInfo) => {
    return {
        type: 'SET_ADD_INFO',
        addInfo,
    }
};

export const setGeneralClass = (className) => {
    return {
        type: 'SET_GENERAL_CLASS',
        className,
    }
};

export const setRedirectToList = (redirectToList) => {
    return {
        type: 'SET_REDIRECT_LIST',
        redirectToList,
    }
};
import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {VIEW_ORDERS_CONDITIONAL_FIELD} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import ViewOrdersConfigButtonConditionalField
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/ViewOrdersConfigButton/components/ViewOrdersConfigButtonConditionalField/ViewOrdersConfigButtonConditionalField";

export default function ViewOrdersConfigButtonListItem({group, field, config, isActive}) {
    const {t} = usePolTranslation();
    const isConditionalField = config?.type === VIEW_ORDERS_CONDITIONAL_FIELD;
    if (isConditionalField && field.conditionValue?.length) isActive = true;

    return (
        <div className={`vo-action__config__item ${config.group}`}>
            {isActive ? <PolIcon icon="validation"/> : <span/>} {/* Empty span to keep the same width */}
            {t(config.label)}
            {isConditionalField && (
                <ViewOrdersConfigButtonConditionalField
                    group={group}
                    field={field}
                    config={config}/>
            )}
        </div>
    )
}

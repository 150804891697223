import React from "react";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import Select from "react-select";
import {useRecoilState, useRecoilValue} from "recoil";
import {datatableColsState, importDataState, skipRowState} from "../../../../components/Admin/atoms/importAdmin";
import {generateCsv} from "../../../../utils/exportManager";

const StepTwoHeader = ({t, formValues, setStepTwoValues, stepTwoValues}) => {
    const optionsExport = [
        {label: t('export_all_data'), value: 'export_all_data'},
        {label: t('export_correct_data'), value: 'export_correct_data'},
        {label: t('export_all_wrong_data'), value: 'export_all_wrong_data'},
    ]
    const params = `${formValues.isDeleteCustomerData === '1' ? t('delete_before_import') : t('no_delete_before_import')} | 
    ${formValues.isSendMailAfterImport === '1' ? t('send_mail_after_import') : t('no_send_mail_after_import')}
    `
    const datatableCols = useRecoilValue(datatableColsState)
    const datatableData = useRecoilValue(importDataState)
    const [skipRow, setSkipRow] = useRecoilState(skipRowState)

    const handleChange = (key, value) => {
        setStepTwoValues({
            ...stepTwoValues,
            [key]: value
        })
        if (key === 'linesToSkip') {
            setSkipRow(value)
        }
    }

    const handleExport = () => {
        generateCsv(datatableCols, getDataForExport(stepTwoValues.exportType, datatableData), 'fieldKey', stepTwoValues.fileName, t)
    }

    const getDataForExport = (exportType, data) => {
        switch (exportType) {
            case 'export_correct_data' :
                return data.filter((item) => item.import_status === true)
            case 'export_all_wrong_data' :
                return data.filter((item) => item.import_status === false)
            default:
                return data;
        }
    }

    return (
        <>
            <form className={'form form__scrollable form__admin'}>
                <div className={'row'}>
                    <div className={'col-2'}>
                    </div>
                    <div className={'col-3'}>
                        <p className={'form__label__admin form__label__admin__mt-10'}>{t('export_edited_data_new_file')}</p>
                    </div>
                    <div className={'col-2'}>
                        <input placeholder={t('filename')}
                               className={'field__input'}
                               onChange={(e) => handleChange('fileName', e.target.value)}
                        />
                    </div>
                    <div className={'col-3'}>
                        <Select
                            onChange={(value) => handleChange('exportType', value.value)}
                            options={optionsExport}
                            value={stepTwoValues.exportType !== undefined ?
                                optionsExport.find(item => item.value === stepTwoValues.exportType) : optionsExport[0]
                            }
                            isMulti={false}
                            styles={{
                                control: (base, state) => ({
                                    ...base,
                                    '&:hover': {},
                                    border: '2px solid #eef0fa',
                                    boxShadow: 'none',
                                    color: '#372e74',
                                    background: '#f3f1fc'
                                }),
                                container: (base, state) => {
                                    return ({
                                        ...base,
                                        zIndex: state.isFocused ? "999" : "1"
                                    })
                                },
                                singleValue: (provided) => ({
                                    ...provided,
                                    color: '#555299'
                                }),
                            }}
                        />
                    </div>
                    <div className={'col-2'}>
                        <span className={'btn btn-primary form__submit__btn'} onClick={handleExport}>
                            {t('export')}
                        </span>
                    </div>
                </div>
                <div className={'row form__separator'}>
                    <div className={'col-2'}>
                    </div>
                    <div className={'col-3'}>
                        <p className={'form__label__admin form__label__admin__mt-10'}>{t('lines_to_skip')}</p>
                    </div>
                    <div className={'col-7'}>
                        <div className={'row form__flex-column'}>
                            <div className={'col-2'}>
                                <input
                                       className={'field__input'}
                                       onChange={(e) => handleChange('linesToSkip', e.target.value)}
                                       value={skipRow}
                                />
                            </div>
                            <div className={'col-10'}>
                                <p className={'form__sub-label'}>{t('info_lines_to_skip')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row form__separator'}>
                    <div className={'col-2'}>
                    </div>
                    <div className={'col-3'}>
                        <p className={'form__label__admin'}>{t('settings')}</p>
                    </div>
                    <div className={'col-7'}>
                        <p>{params}</p>
                    </div>
                </div>
            </form>
        </>
    )
}

export default (withPolTranslation(React.memo(StepTwoHeader)));

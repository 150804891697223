/*Initialisation des variables utilisées ci-dessous par le AbortController()*/

let controller;

/**
 * Fetch Data for autocompleting fields
 * @param {string} url the remote url
 * @param {string} field the field to query
 * @param {string} value the value to search
 * @param {boolean} isAbsoluteUrl if third-party API
 * @param {function} manageError function to display alert if a network error appears
 * @param {function} t Translate
 * @returns {Promise<any>}
 * @constructor
 */
const AutoCompleteFetch = (url, field, value, isAbsoluteUrl = false, manageError, t) => {
    const token = localStorage.getItem('token');
    let headers = {};

    if (!token) {
        manageError(401, {});
    }
    const params = new URLSearchParams({
        [field]: value,
    });
    url = isAbsoluteUrl ? url : `${process.env.REACT_APP_HOST_API}${url}`;
    
    if (!isAbsoluteUrl) {
        headers = {
            'Content-Type': 'application/json',
            Accept: 'application/ld+json',
            "X-POL-AUTH": 'Bearer ' + token
        }
    }
    /*Si une requête est en cours alors qu'une nouvelle vient d'être soumise*/
    if (controller) {
        /*Supression de la requete en cours*/
        controller.abort();
    }

    if ("AbortController" in window) {
        /*Définition d'une nouvelle instance de la class AbortController*/
        controller = new AbortController();
    }
    return fetch(`${url}?${params.toString()}`, {
        signal: controller.signal,
        headers: headers
    })
        .then(res => res.json())
        .then(response => {
            return response['hydra:member'] ?? response;
        })
        .catch(error => {
            /*Si l'erreur est pas dû à l'abort du fetch*/
            if (error.name !== 'AbortError') {
                /*Faire disparaître le loader et apparaître une erreur lors de la requete.*/
                manageError(500, {}, t('err_loading_auto_complete'))
            }
        });
};

export default AutoCompleteFetch;

import {captureException} from "@sentry/react";
import React, {createContext, useContext, useReducer} from 'react';
import useHandleFormReady from "v4/contexts/SearchContext/hooks/useHandleFormReady";
import useSearchDispatchers from "v4/contexts/SearchContext/hooks/useSearchDispatchers";
import useSearchFormInputs from "v4/contexts/SearchContext/hooks/useSearchFormInputs";
import useSearchFormMethods from "v4/contexts/SearchContext/hooks/useSearchFormMethods";
import {getLSItem, setLSItem} from "v4/services/localStorage.service";
import {devideSavedSearch} from "v4/utils";
import useHandleRouteStateForceFilters from "./hooks/useHandleRouteStateForceFilters";

const defaultSearch = {
    formMethods: null,
    formInputs: null,
    isFormReady: false,
    searchbar: '',
    setSearchbar: () => {
    },
    currentPage: 1,
    setCurrentPage: () => {
    },
    pages: null,
    sortField: '',
    setSortField: () => {
    },
    sortOrder: 'asc',
    setSortOrder: () => {
    },
    itemsPerPage: 10,
    setItemsPerPage: () => {
    },
    initPagesWithTotalItems: () => {
    },
    tab: null,
}

export const SearchContext = createContext(defaultSearch);

export function useSearchContext() {
    return useContext(SearchContext);
}

function searchReducer(state, {type, payload}) {
    switch (type) {
        case 'SET_SEARCHBAR':
            return {...state, searchbar: payload, currentPage: 1}
        case 'SET_PAGES':
            if (payload < state.currentPage) {
                return {...state, pages: payload, currentPage: payload}
            }
            return {...state, pages: payload}
        case 'SET_SORT_FIELD':
            return {...state, sortField: payload, currentPage: 1}
        case 'SET_SORT_ORDER':
            return {...state, sortOrder: payload, currentPage: 1}
        case 'SET_SORT':
            return {...state, sortOrder: payload.sortOrder, sortField: payload.sortField, currentPage: 1}
        case 'SET_CURRENT_PAGE':
            return {...state, currentPage: payload}
        case 'SET_ITEMS_PER_PAGE': {
            setLSItem("itemsPerPageV4", {...getLSItem("itemsPerPageV4"), [state.pageType]: parseInt(payload, 10)});

            return {...state, itemsPerPage: parseInt(payload, 10), currentPage: 1}
        }
        case 'SET_FORM_READY':
            return {...state, isFormReady: payload}
        default:
            const err = new Error('Aucun type ne correspond');
            captureException(err);
            throw err;
    }
}

export function SearchProvider({formOptions = {}, searchRouteName, pageType, tab, children}) {
    const {searchbar, chainedSearch, search} = devideSavedSearch(tab?.searchContent);
    const formMethods = useSearchFormMethods(formOptions, chainedSearch, search);
    const formInputs = useSearchFormInputs(searchRouteName, tab);

    const defaultState = {
        ...defaultSearch,
        pageType,
        searchbar,
        itemsPerPage: getLSItem("itemsPerPageV4")?.[pageType] ?? defaultSearch.itemsPerPage,
        searchRouteName,
    }

    const [state, dispatch] = useReducer(searchReducer, defaultState);
    const dispatchers = useSearchDispatchers(dispatch, state);
    useHandleFormReady(formMethods, formInputs, state.isFormReady, dispatchers.setFormReady);
    useHandleRouteStateForceFilters(formMethods);

    const searchProvided = {
        ...state,
        ...dispatchers,
        formMethods,
        formInputs,
        tab,
    }

    return (
        <SearchContext.Provider value={searchProvided}>
            {children}
        </SearchContext.Provider>
    )
}

import React, {useEffect, useState} from 'react';
import {routesBase} from "v4/data/appRoutes";
import useFetch from "v4/hooks/useFetch";
import apiService from "v4/services/api.service";
import {CAMPAIGN_GENERATE} from "v4/data/apiRoutes";
import Loading from "components/Loading/Loading";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {Redirect} from "react-router-dom";

export default function MailingCampaign({selectedIds, type}) {
    const {t} = usePolTranslation();
    const [refetch, setRefetch] = useState(false);

    const [{data, isLoading, isFinished, isError}, fetchMailing] = useFetch();

    useEffect(() => {
        fetchMailing({
            url: apiService.generateUrl(CAMPAIGN_GENERATE),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    isMailing: true,
                    ["prospects" === type ? "prospectIds" : "contactIds"]: selectedIds
                }),
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch]);

    const toggleRefetch = () => setRefetch(r => !r);

    return (
        <>
            {isLoading && <Loading containerClass="campaign-popup__loader" message={t("campaign_generating")}/>}
            {(isFinished && !isError) && <Redirect to={{
                pathname: `${routesBase.baseNewsletter}/emailing`,
                state: {
                    page: type,
                    campaignId: data?.campaignId ?? null
                }
            }}/>}
            {isFinished && isError && <div>
                <p>{t("error_while_load_mailing")}</p>
                <button onClick={toggleRefetch}>{t("retry")}</button>
            </div>}
        </>
    )
}

import React, {useEffect, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, Redirect} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import {
    OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_ADD, OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_EDIT,
    OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_ITEM,
    USER_SHOW_LIST
} from "v4/data/apiRoutes";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";

export default function OutlookSubscriptionConfigurationUsersForm({customerId, subscriptionConfigId, id}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()
    const [{data: outlookSubscriptionConfigurationUserData, isLoading, isFinished}, fetchOutlookSubscriptionConfigurationUser] = useFetch()
    const [{data: userData}, fetchUser] = useFetch()
    const [{
        data: outlookSubscriptionConfigurationUserDataSave,
        isError: isErrorSave,
        isLoading: isLoadingSave,
        isFinished: isFinishedSave
    }, fetchOutlookSubscriptionConfigurationUserSave] = useFetch()
    const [outlookSubscriptionConfigurationUser, setOutlookSubscriptionConfigurationUser] = useState({
        subscriptionId: null,
        outlookFolderId: null,
        user: null,
        outlookSubscriptionConfiguration: subscriptionConfigId
    })
    const isEdit = (!!id)
    const [redirect, setRedirect] = useState(null)

    useEffect(() => {
        if (isEdit) {
            fetchOutlookSubscriptionConfigurationUser({
                url: apiService.generateUrl(OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_ITEM, {id: id}),
            })
        }

        fetchUser({url: apiService.generateUrl(USER_SHOW_LIST, {customerId: customerId})});
    }, [isEdit, customerId, id, fetchOutlookSubscriptionConfigurationUser, fetchUser])

    useEffect(() => {
        if (outlookSubscriptionConfigurationUserData && isFinished) {
            outlookSubscriptionConfigurationUserData.user = outlookSubscriptionConfigurationUserData.user?.id;
            setOutlookSubscriptionConfigurationUser(outlookSubscriptionConfigurationUserData)
            document.getElementById('outlook_subscription_configuration_user_user').value = outlookSubscriptionConfigurationUserData.user;
        }
    }, [outlookSubscriptionConfigurationUserData, isFinished])

    const handleChange = (input) => {
        setOutlookSubscriptionConfigurationUser({...outlookSubscriptionConfigurationUser, [input.target.name]: input.target.value})
    }

    const handleSave = (e) => {
        e.preventDefault()
        fetchOutlookSubscriptionConfigurationUserSave({
            url: isEdit
            ? apiService.generateUrl(OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_EDIT, {
                    subscriptionConfigId: subscriptionConfigId,
                    id: id
                })
            : apiService.generateUrl(OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_ADD, {
                    subscriptionConfigId: subscriptionConfigId
                }),
            config: {
                method: 'POST',
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({...outlookSubscriptionConfigurationUser, outlookSubscriptionConfiguration: subscriptionConfigId})
            }
        })
    }

    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('outlook_subscription_configuration_save_error'))
        }

        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('outlook_subscription_configuration_save_done'))
            setRedirect(<Redirect to={`${routesBase.adminMicrosoftGraph}/${subscriptionConfigId}`}/>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outlookSubscriptionConfigurationUserDataSave, isErrorSave, isFinishedSave])

    const CardHeaderTools = () => (
        <>
            <Link to={`${routesBase.adminMicrosoftGraph}/${subscriptionConfigId}`} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )

    if (!userData?.['hydra:member']) {
        return null
    }

    return (
        <AdminCard title={t('manage_outlook_subscription_configurations')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoading || isLoadingSave) ? <Loader message="Chargement..."/> : null
            }
            <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                <div className="row row-fluid mt-4">
                    <div className={'col-6 '}>
                        <div className={'col-12 field ' + (outlookSubscriptionConfigurationUser?.subscriptionId ? 'field-written' : null)}>
                            <input type="text" name="subscriptionId" id="outlook_subscription_configuration_user_subscriptionId" className="field__input"
                                   defaultValue={outlookSubscriptionConfigurationUser?.subscriptionId} onChange={(input) => handleChange(input)}/>
                            <label className="field__label" htmlFor="outlook_subscription_configuration_user_subscriptionId">{t('outlook_subscription_configuration_user_subscriptionId')} *</label>
                        </div>
                        <div className={'col-12 field ' + (outlookSubscriptionConfigurationUser?.outlookFolderId ? 'field-written' : null)}>
                            <input type="text" name="outlookFolderId" id="outlook_subscription_configuration_user_outlook_folder_id" className="field__input"
                                   defaultValue={outlookSubscriptionConfigurationUser?.outlookFolderId} onChange={(input) => handleChange(input)}/>
                            <label className="field__label" htmlFor="outlook_subscription_configuration_user_outlook_folder_id">{t('outlook_subscription_configuration_user_outlook_folder_id')}</label>
                        </div>
                    </div>
                    <div className={'col-6 '}>
                        <div className={'col-12 field ' + (outlookSubscriptionConfigurationUser?.user ? 'field-written' : null)}>
                            <select name="user" id="outlook_subscription_configuration_user_user" className="field__input" required={true}
                                    defaultValue={outlookSubscriptionConfigurationUser?.user} onChange={(input) => handleChange(input)}>
                                <option value={null} label={t('empty')}></option>
                                {userData['hydra:member'].map((user, userKey) => {
                                    return (
                                        <option key={userKey} value={user.id} label={user.fullname}></option>
                                    )
                                })}
                            </select>
                            <label className="field__label" htmlFor="outlook_subscription_configuration_user_user">{t('outlook_subscription_configuration_user_user')}</label>
                        </div>
                    </div>
                </div>
            </form>
            {redirect}
        </AdminCard>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import moment from "moment";
import React, {useCallback} from 'react';
import {Link} from "react-router-dom";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {getFieldsValuesByKey} from "v4/utils";
import getDatetimeFormat from "v4/utils/getDatetimeFormat";
import {TAB_CLOSED_TASKS, TAB_OLD_MAILS_TASKS, TAB_OPEN_TASKS, TAB_RECEIVED_MAILS_TASKS} from "v4/data/tabNames";
import TextareaRenderer from "v4/components/form/ShowField/components/TextareaRenderer/TextareaRenderer";


export default function TimelineItem({task}) {
    const {t} = usePolTranslation();

    const classSuffixPosition = useCallback((statusComparison) => [statusComparison].flat().includes(getFieldsValuesByKey('status', task))
            ? 'right'
            : 'left'
        , [task]);

    return (
        <div className="summaryTask__timeline">
            <div
                className={`summaryTask__timeline__state summaryTask__timeline__state--${getFieldsValuesByKey('status', task)}`}>
                <PolIcon icon="clock"/>
            </div>
            <div
                className={`summaryTask__timeline__content summaryTask__timeline__content--${classSuffixPosition('late')}`}>
                <div className="summaryTask__timeline__content__title">
                    <Link to={({pathname}) => getItemURL(pathname, task.metadata)}>
                        {t(getFieldsValuesByKey('taskType', task))}
                        <PolIcon className="ml-2" icon="eye"/>
                    </Link>
                </div>
                <div className="summaryTask__timeline__content__info">
                    <div className="summaryTask__timeline__content__info--title">
                        <div>
                            <span>{getFieldsValuesByKey('name', task)}</span>
                            {getFieldsValuesByKey('contact', task) && t('tasks_summary_contacts_to')}
                            <span>{getFieldsValuesByKey('contact', task)}</span>
                        </div>
                        <div>{getFieldsValuesByKey('managedBy', task)}</div>
                    </div>
                    <div className="summaryTask__timeline__content__info--text">
                        {getFieldsValuesByKey('report', task)
                            ? (
                                <div className="summaryTask__timeline__content__info--report resizable-v">
                                    <div>{t('compte_rendu')}</div>
                                    <TextareaRenderer value={getFieldsValuesByKey('report', task)} allInputs={task.fields}/>
                                </div>
                            )
                            : null
                        }
                        {getFieldsValuesByKey('memo', task)
                            ? (
                                <div className="summaryTask__timeline__content__info--memo resizable-v">
                                    <div>{t('memo')}</div>
                                    <TextareaRenderer value={getFieldsValuesByKey('memo', task)} allInputs={task.fields}/>
                                </div>
                            )
                            : null
                        }
                        {(!getFieldsValuesByKey('report', task) && !getFieldsValuesByKey('memo', task))
                            ? (
                                <div className="summaryTask__timeline__content__info--none">
                                    <div>{t('no_additional_information')}</div>
                                </div>
                            )
                            : null
                        }
                    </div>
                </div>
            </div>
            <div
                className={`summaryTask__timeline__content--date summaryTask__timeline__content--date--${classSuffixPosition(['ongoing', 'closed'])}`}>
                <div>
                    {getFieldsValuesByKey('beginAt', task) ? moment(getFieldsValuesByKey('beginAt', task)).format(getDatetimeFormat()) : ''}
                </div>
            </div>
        </div>
    )
}

function getItemURL(pathname, metadata) {
    const {id, isMailing, isSynchroOutlook, isClosed} = metadata;
    let finalUrl = pathname.split(TAB_OPEN_TASKS)[0];

    if (isMailing) finalUrl += TAB_OLD_MAILS_TASKS
    else if (isSynchroOutlook) finalUrl += TAB_RECEIVED_MAILS_TASKS
    else if (isClosed) finalUrl += TAB_CLOSED_TASKS
    else finalUrl += TAB_OPEN_TASKS;

    return `${finalUrl}/${id}`;
}

import React, {createContext, useContext, useMemo, useReducer} from 'react';
import useNotification from "v4/hooks/useNotification";
import {generateUrl} from "v4/services/api.service";
import {SAVE_VIEW_ORDER} from "v4/data/apiRoutes";
import getNewViewOrderState from "v4/pages/admin/ViewOrders/contexts/utils/getNewViewOrderState";
import getCleanViewOrders from "v4/pages/admin/ViewOrders/contexts/utils/getCleanViewOrders";
import createViewOrderPayload from "v4/pages/admin/ViewOrders/contexts/utils/createViewOrderPayload";
import getViewOrdersUrl from "v4/pages/admin/ViewOrders/contexts/utils/getViewOrdersUrl";
import populateViewOrder from "v4/pages/admin/ViewOrders/contexts/utils/populateViewOrder";
import UserService from "api/user/user";
import {randomId} from "v4/utils";
import getAvailableWidths from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/utils/getAvailableWidths";
import {QUOTE, TASK} from "v4/data/entities";
import i18n from "i18next";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export const VIEW_ORDERS_IS_EMAIL = 'isEmail';
export const VIEW_ORDERS_IS_EXTENDED = 'isExtended';
export const VIEW_ORDERS_IS_MONETARY = 'isMonetary';
export const VIEW_ORDERS_IS_PARENT_FIELD = 'isParentField';
export const VIEW_ORDERS_IS_PERCENTAGE = 'isPercentage';
export const VIEW_ORDERS_IS_PHONE = 'isPhone';
export const VIEW_ORDERS_IS_READ_ONLY = 'isReadOnly';
export const VIEW_ORDERS_IS_REQUIRED = 'isRequired';
export const VIEW_ORDERS_IS_RICH_TEXT = 'isRichText';
export const VIEW_ORDERS_IS_UNIQUE = 'isUnique';
export const VIEW_ORDERS_IS_URL = 'isUrl';
export const VIEW_ORDERS_CONDITIONAL_FIELD = 'conditionalField';

export const VIEW_ORDERS_CONFIGS = [
    {
        label: 'isRequired',
        type: VIEW_ORDERS_IS_REQUIRED,
    },
    {
        label: 'unique',
        type: VIEW_ORDERS_IS_UNIQUE,
    },
    {
        label: 'readOnly',
        type: VIEW_ORDERS_IS_READ_ONLY,
    },
    {
        label: 'email',
        type: VIEW_ORDERS_IS_EMAIL,
        group: 'oneTypeOnly',
        allowedTypes: ['TextType'],
    },
    {
        label: 'phone',
        type: VIEW_ORDERS_IS_PHONE,
        group: 'oneTypeOnly',
        allowedTypes: ['TextType'],
    },
    {
        label: 'money',
        type: VIEW_ORDERS_IS_MONETARY,
        group: 'oneTypeOnly',
        allowedTypes: ['NumberType'],
    },
    {
        label: 'percent',
        type: VIEW_ORDERS_IS_PERCENTAGE,
        group: 'oneTypeOnly',
        allowedTypes: ['NumberType'],
    },
    {
        label: 'html',
        type: VIEW_ORDERS_IS_RICH_TEXT,
        group: 'oneTypeOnly',
        allowedTypes: ['TextType'],
    },
    {
        label: 'website',
        type: VIEW_ORDERS_IS_URL,
        group: 'oneTypeOnly',
        allowedTypes: ['TextType'],
    },
    {
        label: 'extendedField',
        type: VIEW_ORDERS_IS_EXTENDED,
        allowedTypes: ['ChoiceType'],
        allowedVOTypes: ['search'],
    },
    {
        label: 'isParentField',
        type: VIEW_ORDERS_IS_PARENT_FIELD,
        allowedEntities: [QUOTE],
    },
    {
        label: 'show_condition_field',
        type: VIEW_ORDERS_CONDITIONAL_FIELD,
        allowedEntities: [TASK],
        removeIfFieldIsRequired: true,
    },
]

/**
 *
 * @typedef {{
 *  type: string,
 *  item: {
 *      id: string,
 *      label: string,
 *      width: number,
 *  },
 *  source: {
 *      index: number,
 *      [key: string]: any,
 *  },
 *  destination: {
 *      index: number,
 *      [key: string]: any,
 *  },
 * }} DropResult
 */

/**
 *
 * @type {{
 *     currentViewOrder?: {
 *      label: string,
 *      id: string,
 *      entity: string,
 *      viewOrderType: string,
 *      formFields: {},
 *      data: {},
 *      state: {},
 *     },
 *     changeCurrentViewOrder: (viewOrderKey: string) => void,
 *     onDragEnd: (result: DropResult) => void,
 *     currentKeyForRefresh: string,
 *     viewOrders?: {},
 *     isViewOrdersLoading: boolean,
 *     isViewOrdersFormLoading: boolean,
 *     onSubmit: (event: SyntheticMouseEvent) => void,
 *     isSubmitDisabled: boolean,
 *     setGroupLabel: (idGroup: string, label: string) => void,
 *     setWidth: (idGroup: string, idField: string|undefined, width: number) => void,
 *     removeElement: (idGroup: string, idField: string) => void,
 *     addGroup: (idGroup: string, isAfter: boolean, width: number) => void,
 *     setFieldConfig: (idGroup: string, idField: string, config: {}) => void,
 * }}
 */
const defaultState = {
    currentViewOrder: null,
    changeCurrentViewOrder: (viewOrderKey) => {
    },
    onDragEnd: (result) => {
    },
    currentKeyForRefresh: randomId(),
    viewOrders: {},
    isViewOrdersLoading: false,
    isViewOrdersFormLoading: false,
    onSubmit: (event) => {
    },
    isSubmitDisabled: false,
    setGroupLabel: (idGroup, label) => {
    },
    setWidth: (idGroup, idField, width) => {
    },
    removeElement: (idGroup, idField) => {
    },
    addGroup: (idGroup, isAfter, width) => {
    },
    setFieldConfig: (idGroup, idField, config) => {
    }
}

const viewOrdersContext = createContext(defaultState);

export function useViewOrdersContext() {
    return useContext(viewOrdersContext);
}

function viewOrdersReducer(state, {type, payload}) {
    switch (type) {
        case 'CHANGE_CURRENT_VIEW_ORDER': {
            const {viewOrderName, fields} = payload;
            const [viewOrderKey, viewOrderType, viewOrderSubKey] = viewOrderName?.split('.');

            let viewOrder = state.viewOrders?.[viewOrderKey]?.[viewOrderType]?.[viewOrderSubKey];
            const viewOrderId = state.viewOrders?.[viewOrderKey]?.id;
            if (!viewOrder) return {
                ...state,
                isViewOrdersFormLoading: false,
            }

            // Rename quote_lines to quoteLines (specific case because of the API)
            Object.values(fields).forEach(field => {
                if (field.title === 'quote_lines') {
                    field.title = 'quoteLines';
                }
            })
            viewOrder = populateViewOrder(viewOrder, fields);

            let label = i18n.t(viewOrderType);
            const tabs = UserService.getFlatTabs();
            const tab = tabs.find(tab => tab.id === viewOrderSubKey);
            if (tab) {
                label = `${label} ${tab.name}`;
            } else if (viewOrderSubKey === 'individual') {
                label = `${label} ${i18n.t('prospect_individual')}`;
            } else {
                label = `${label} ${i18n.t(viewOrderSubKey)}`;
            }

            return {
                ...state,
                isViewOrdersFormLoading: false,
                currentViewOrder: viewOrder
                    ? (
                        {
                            label,
                            data: viewOrder,
                            id: viewOrderId,
                            entity: viewOrderKey.toLowerCase(),
                            viewOrderKey,
                            viewOrderType,
                            viewOrderSubKey,
                            formFields: fields,
                            state: window.structuredClone(viewOrder), // The state maintains the order of the groups and fields which corresponds to what the user sees but, because of sortablejs, which is not a React library, we need to separate the state from the view (DOM)
                        }
                    )
                    : null,
            }
        }
        case 'VIEW_ORDERS_FORM_LOADING':
            return {
                ...state,
                isViewOrdersFormLoading: true,
            }
        case 'VIEW_ORDERS_FORM_LOADED':
            return {
                ...state,
                isViewOrdersFormLoading: false,
            }
        case 'ON_DRAG_END': {
            const {currentViewOrder} = state;

            return {
                ...state,
                currentKeyForRefresh: randomId(),
                currentViewOrder: {
                    ...currentViewOrder,
                    state: getNewViewOrderState(currentViewOrder, payload),
                }
            }
        }
        case 'ON_SUBMIT':
            return {
                ...state,
                isSubmitDisabled: true,
            }
        case 'RESET_VIEW_ORDERS':
            UserService.setOrders(payload);

            return {
                ...state,
                viewOrders: getCleanViewOrders(),
            }
        case 'FORM_SUBMITTED':
            return {
                ...state,
                isSubmitDisabled: false,
            }
        case 'SET_GROUP_LABEL': {
            const {idGroup, label} = payload;
            const {currentViewOrder} = state;

            const newGroups = currentViewOrder.state.map(group =>
                group.id !== idGroup
                    ? group
                    : {
                        ...group,
                        ...(group.isRequired && group.label !== label) && {id: randomId(), isRequired: false},
                        label,
                    }
            );

            const hasRequiredGroup = newGroups.some(group => group.isRequired);
            if (!hasRequiredGroup) {
                newGroups.push({
                    id: 'isRequired',
                    label: i18n.t('unmapped_fields'),
                    isRequired: true,
                    width: 100,
                    fields: [],
                });
            }

            return {
                ...state,
                currentViewOrder: {
                    ...currentViewOrder,
                    state: newGroups,
                }
            }
        }
        case 'SET_WIDTH': {
            const {idGroup, idField, width} = payload;
            const {currentViewOrder: currentViewOrderWidth} = state;
            const newGroupsWidth = currentViewOrderWidth.state.map(group =>
                group.id !== idGroup
                    ? group // If the group id is not the one we want to change, we keep it as it is
                    : {
                        ...group,
                        ...(!idField && {width}), // If there is no fieldId, it means we want to change the group width
                        fields: group.fields.map(field => ({
                            ...field,
                            width: field.id === idField
                                ? width // If the field id is the one we want to change, we change it
                                : getAvailableWidths(group.width, false).includes(field.width)
                                    ? field.width // If the field width is available for the group, we keep it as it is
                                    : getAvailableWidths(group.width, false).at(-1) // If the field width is not available for the group, we set it to the last available width which is the biggest one --> 100%
                        }))
                    }
            );

            return {
                ...state,
                currentViewOrder: {
                    ...currentViewOrderWidth,
                    state: newGroupsWidth,
                }
            }
        }
        case 'REMOVE_GROUP': {
            const {idGroup} = payload;
            const {currentViewOrder} = state;
            const removedGroup = currentViewOrder.state.find(group => group.id === idGroup);
            if (!removedGroup) return state;

            const newCurrentViewOrderState = currentViewOrder.state
                .filter(group => group.id !== idGroup)
                .map((group, index) => {
                    const isRequiredGroup = group.isRequired ?? false;
                    const isDefaultGroup = group.isDefault ?? false;

                    const removedFields = removedGroup.fields.filter(field => (isRequiredGroup && field.isRequired) || (isDefaultGroup && !field.isRequired));

                    return {
                        ...group,
                        index,
                        fields: [...group.fields, ...removedFields].map(field => ({
                            ...field,
                            ...(isDefaultGroup && {
                                width: getAvailableWidths(group.width, false).at(-1), // If isDefaultGroup, we need to put the field in the last available width which is the biggest one --> 100%
                            }),
                        })),
                    };
                });

            return {
                ...state,
                currentViewOrder: {
                    ...currentViewOrder,
                    state: newCurrentViewOrderState,
                }
            }
        }
        case 'REMOVE_FIELD': {
            const {idGroup, idField} = payload;
            const {currentViewOrder} = state;
            const removedField = currentViewOrder.state.find(group => group.id === idGroup)?.fields.find(field => field.id === idField);
            if (!removedField) return state;

            const newCurrentViewOrderState = currentViewOrder.state.map(group => {
                if (removedField && ((removedField.isRequired && group.isRequired) || (!removedField.isRequired && group.isDefault))) {
                    return {
                        ...group,
                        fields: [
                            ...group.fields,
                            {
                                ...removedField,
                                width: getAvailableWidths(group.width, false).at(-1), // If the field is removed, we need to put it in the last available width which is the biggest one --> 100%
                            }
                        ],
                    };
                }

                if (group.id === idGroup) {
                    const newFields = group.fields.filter(field => field.id !== idField);
                    return {...group, fields: newFields};
                }

                return group;
            });

            return {
                ...state,
                currentViewOrder: {
                    ...currentViewOrder,
                    state: newCurrentViewOrderState,
                }
            }
        }
        case 'ADD_GROUP': {
            const {idGroup, isAfter, width} = payload;
            const {currentViewOrder} = state;
            const index = currentViewOrder.state.findIndex(group => group.id === idGroup);
            const newGroup = {
                id: randomId(),
                label: '',
                width: width ?? 100,
                fields: [],
            };

            const newCurrentViewOrderState = [
                ...currentViewOrder.state.slice(0, index + (isAfter ? 1 : 0)),
                newGroup,
                ...currentViewOrder.state.slice(index + (isAfter ? 1 : 0)),
            ].map((group, index) => ({
                ...group,
                index,
            }));

            return {
                ...state,
                currentViewOrder: {
                    ...currentViewOrder,
                    state: newCurrentViewOrderState.map((group, index) => ({
                        ...group,
                        index,
                    })),
                }
            }
        }
        case 'SET_FIELD_CONFIG': {
            const {idGroup, idField, config} = payload;
            if (!idGroup || !idField || !config) return state;

            const {type, value, group: payloadGroup, conditionField, conditionValue} = config;
            const {currentViewOrder} = state;
            const newCurrentViewOrderState = currentViewOrder.state.map(group => {
                if (group.id === idGroup) {
                    const newFields = group.fields.map(field => {
                        if (field.id === idField) {
                            return {
                                ...field,
                                ...(payloadGroup && {
                                    ...VIEW_ORDERS_CONFIGS
                                        .filter(({group: configGroup}) => configGroup === payloadGroup) // If the config has a group, we need to disable the other configs of the same group
                                        .map(({type}) => ({[type]: false})) // We disable the other configs of the same group
                                        .reduce((acc, curr) => ({...acc, ...curr}), {}) // We merge the objects
                                }),
                                ...(type !== VIEW_ORDERS_CONDITIONAL_FIELD && {[type]: value}),
                                conditionField: conditionField ?? null,
                                conditionValue: conditionValue ?? null,
                            }
                        }

                        return field;
                    });

                    return {
                        ...group,
                        fields: newFields,
                    }
                }

                return group;
            });

            return {
                ...state,
                currentViewOrder: {
                    ...currentViewOrder,
                    state: newCurrentViewOrderState,
                }
            }
        }
        default:
            return state;
    }
}

export function ViewOrdersContextProvider({children}) {
    const {t} = usePolTranslation();
    const [state, dispatch] = useReducer(viewOrdersReducer, defaultState, () => ({
        ...defaultState,
        viewOrders: getCleanViewOrders(),
    }));
    const {addSuccessNotification, addErrorNotification} = useNotification();

    const viewOrdersValue = useMemo(() => ({
        ...state,
        changeCurrentViewOrder: async (viewOrderName) => {
            const [viewOrderKey, viewOrderType, viewOrderSubKey] = viewOrderName?.split('.');
            if (!viewOrderKey || !viewOrderType || !viewOrderSubKey) return addErrorNotification('error');

            dispatch({type: 'VIEW_ORDERS_FORM_LOADING'});
            const response = await fetch(getViewOrdersUrl(viewOrderKey, viewOrderType, viewOrderSubKey));
            const responseJson = await response.json();
            const fields = responseJson?.schema?.['hydra:member'][2];

            if (!fields) {
                dispatch({type: 'VIEW_ORDERS_FORM_LOADED'});
            } else {
                dispatch({type: 'CHANGE_CURRENT_VIEW_ORDER', payload: {viewOrderName, fields}});
            }
        },
        /** @param {DropResult} result */
        onDragEnd: (result) => {
            // Move the item in the groups
            if (!result.item || !result.source || !result.destination) return;

            dispatch({type: 'ON_DRAG_END', payload: result});
        },
        onSubmit: async (event) => {
            event.preventDefault();
            if (state.currentViewOrder === null) return;

            dispatch({type: 'ON_SUBMIT'});
            const request = await fetch(generateUrl(SAVE_VIEW_ORDER, {delcache: true}), {
                method: 'PUT',
                body: JSON.stringify(createViewOrderPayload(state.currentViewOrder)),
            });

            try {
                const response = await request.json();
                addSuccessNotification(t('success'));
                dispatch({type: 'RESET_VIEW_ORDERS', payload: response.data});
            } catch (error) {
                addErrorNotification('error');
            }

            dispatch({type: 'FORM_SUBMITTED'});
        },
        setGroupLabel: (idGroup, label) => {
            dispatch({type: 'SET_GROUP_LABEL', payload: {idGroup, label}});
        },
        setWidth: (idGroup, idField, width) => {
            dispatch({type: 'SET_WIDTH', payload: {idGroup, idField, width}});
        },
        removeElement: (idGroup, idField) => {
            if (idField) {
                dispatch({type: 'REMOVE_FIELD', payload: {idGroup, idField}});
                return;
            }

            dispatch({type: 'REMOVE_GROUP', payload: {idGroup}});
        },
        addGroup: (idGroup, isAfter, width) => {
            dispatch({type: 'ADD_GROUP', payload: {idGroup, isAfter, width}});
        },
        setFieldConfig: (idGroup, idField, config) => {
            dispatch({type: 'SET_FIELD_CONFIG', payload: {idGroup, idField, config}});
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [state]);

    return (
        <viewOrdersContext.Provider value={viewOrdersValue}>
            {children}
        </viewOrdersContext.Provider>
    )
}

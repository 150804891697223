import React from 'react';
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {CONTACT_SEARCH} from "v4/data/apiRoutes";
import ContactsListing from "v4/pages/front/Contacts/components/ContactsListing/ContactsListing";

export default function ContactsPage({tab, isProspectPage}) {

    return (
        <SearchProvider searchRouteName={CONTACT_SEARCH} pageType="contacts" tab={tab}>
            <ToggleComponent isShowing={!isProspectPage} className="page page-list main-content__body__slide">
                <ContactsListing tab={tab}/>
            </ToggleComponent>
        </SearchProvider>
    );
}

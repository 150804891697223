export default function getCleanViewOrdersFields(fields) {
    if (!fields) return [];

    return fields.map(({
                           id,
                           key,
                           label,
                           order,
                           width,
                           isEmail = false,
                           isExtended = false,
                           isHidden = false,
                           isParentField = false,
                           isReadOnly = false,
                           isRequired = false,
                           isSpecificField = false,
                           isUnique = false,
                           valueType,
                           specificFieldType: type,
                           conditionField,
                           conditionValue,
                       }) => {
        return {
            id,
            key,
            order,
            label,
            width: parseInt(width, 10),
            ...(type && {type}),
            isUnique,
            isRequired,
            isReadOnly,
            isSpecificField,
            isHidden,

            /* Only for number fields */
            isMonetary: valueType === 'money',
            isPercentage: valueType === 'percent',

            /* Only for text fields */
            isPhone: valueType === 'TelType',
            isUrl: valueType === 'website',
            isRichText: valueType === 'html',
            isEmail,

            /* Only in Task fields */
            conditionField,
            conditionValue,

            /* Only for Quote fields */
            isParentField, // Used to make the field value from the parent (when quote is split into multiple quotes)

            /* Only for form view order fields */
            isExtended, // Transform the field into tabs like mail inboxes for Plenetude
        }
    });
}

import React from 'react';
import {PRODUCT_ADD, PRODUCT_FORM} from "v4/data/apiRoutes";
import {routesBase} from "v4/data/appRoutes";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import FullPageFormLayout from "v4/layouts/FullPageFormLayout/FullPageFormLayout";

export default function ProductsAdd() {
    return (
        <FullPageFormLayout title="add_product">
            <FormEntityLayout formRouteName={PRODUCT_FORM}
                              submitRouteName={PRODUCT_ADD}
                              whenSubmitted={whenSubmitted}/>
        </FullPageFormLayout>
    )
}

function whenSubmitted(history) {
    history.replace(`${history.location.pathname.split(routesBase.baseArticle).shift()}${routesBase.baseArticle}`, {hasToRefresh: true});
}

import React, {Component} from 'react';
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {setExpandInfo, setPopup} from "../../../actions/content";
import {substitutionDataView} from "../../../datas/Content";
import {
    DataService,
    dataFilter,
    isCompany
} from "../../../api/datas/datas";
import {baseProspect, baseProspectContent} from "../../../routes";
import {setLastVisit} from "../../../api/lastVisits/lastVisits";
import {deleteLastVisit, pushLastVisit} from "../../../actions/user";
import Form from "../../Form/form";
import View from "../../View";
import {Redirect} from "react-router-dom";
import {DeleteService} from "../../../api/datas/delete";
import {PolIcon} from "../../PolIcon/policon"
import UserService from "../../../api/user/user";
import Loading from "../../Loading/Loading";
import {removeError, resetStoreDatas, setError} from "../../../actions/datas";
import ReactTooltip from "react-tooltip";
import PopupInfoConfirm from "../../Popup/_popup_info_confirm";
import {setFilterSearch} from "../../../actions/form";

const defaultInfoUrl = {
    info: {
        name: 'prospects',
        id: '',
        type: 'data',
        url: '/api/prospect/',
        loader: 'single',
        columnId: 'prospects',
        order_type: 'view',
        order_entity: 'Prospect'
    },
    infoModify: {
        formUrl: '/api/prospect/individual/form',
        name: 'prospects_update',
        group: 'individual',
        order_entity: 'Prospect',
        putUrl: '/api/prospects',
        setDataInfo: {
            name: 'prospects',
            type: 'data'
        }
    }
};

class InfosProspect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            errorInfos: false,
            expandEdit: false,
            rawData: {},
            data: substitutionDataView,
            title: {},
            isLoading: false,
            isEditMode: false,
            isSetLastVisit: false,
            isGeolocated: false,
            ...defaultInfoUrl
        }
    }

    manageDatas = (values) => {
        const {setLastVisit, pushLastVisit, toSetLastVisit, t} = this.props;
        const {info, isSetLastVisit} = this.state;
        const processedData = values;

        let {infoModify} = this.state;
        let isGeolocated = false;
        let group = 'individual';
        let type = 'Particulier';
        let picto = 'client';

        if (values.mainAddress &&
            values.mainAddress.lat &&
            values.mainAddress.lng
        ) {
            isGeolocated = true
        }

        if (isCompany(values)) {
            type = 'Entreprise';
            picto = 'company';
            group = 'business';
        }

        infoModify = {
            ...infoModify,
            group: group,
            itemId: info.id,
            formParam: info.id,
            setDataInfo: {
                ...infoModify.setDataInfo,
                id: info.id
            }
        };
        let filterDatas = dataFilter('Prospect', 'view', processedData, false, group);
        const fullname = values.fullname;

        if (toSetLastVisit && !isSetLastVisit) {
            this.setState({
                isSetLastVisit: true
            }, () => {
                const infoRoute = {
                    url: '/api/last_visit',
                    loader: 'last-visit-post'
                };

                const data = {
                    url: `${baseProspect}/${info.id}/${baseProspectContent}`,
                    name: fullname && fullname.length ? fullname : t('lastvisit_empty'),
                    type: type,
                    prospectId: info.id
                };

                setLastVisit(infoRoute, data);
                pushLastVisit(data);
            })
        }

        this.setState({
            title: {
                text: fullname,
                icon: picto
            },
            infoModify: infoModify,
            data: filterDatas,
            rawData: values,
            isGeolocated: isGeolocated
        });
    };

    updateView = (groupKey, itemKey, value) => {
        const {data} = this.state;
        const groupData = data[groupKey].viewOrderInfos;
        if (groupData) {
            groupData[itemKey].value = value
        }
        this.setState({
            data: data,
        });
    }

    generateUrlInfo = (idProspect) => {
        let {info, infoModify} = defaultInfoUrl;
        const {isUpdateProspect, isCompany = false, onExpand} = this.props;

        info = {
            ...info,
            id: idProspect,
            url: info.url + idProspect
        };

        if (isUpdateProspect) {
            onExpand(true);

            this.setState({
                isEditMode: isUpdateProspect,
                infoModify: {
                    ...infoModify,
                    itemId: info.id,
                    formParam: info.id,
                    group: isCompany ? 'business' : 'individual'
                }
            });
        }

        return info;
    };

    viewMaps = (id) => {
        const {rawData, isGeolocated} = this.state;
        if (isGeolocated) {
            this.setState({
                redirect: null
            }, () => {
                this.setState({
                    redirect: <Redirect to={{
                        pathname: `${baseProspect}/${id}/maps`,
                        state: {
                            prospect: rawData
                        }
                    }}/>
                })
            });
        }
    };

    removeProspect = () => {
        let {t, setPopup, resetStoreData, deleteLastVisitProspectId, setFilterSearch} = this.props;
        const {info} = this.state;
        const user = UserService.getUser();
        const token = user.token ? user.token : localStorage.getItem('token');

        setPopup({
            isOpen: true,
            target: 'delete_item',
            content: (
                <PopupInfoConfirm
                    message={t('popup_validation_suppression_prospect')}
                    onAccept={() => {
                        this.setState({isLoading: true});
                        fetch(process.env.REACT_APP_HOST_API + info.url, {
                            method: 'DELETE',
                            headers: {
                                Accept: 'application/ld+json',
                                'Content-Type': 'application/json',
                                "X-POL-AUTH": 'Bearer ' + token
                            },
                        }).then(
                            () => {
                                resetStoreData();
                                deleteLastVisitProspectId(info.id);
                                setFilterSearch({submittedData: []});
                                this.setState({
                                    redirect: <Redirect to={{
                                        pathname: `${baseProspect}`
                                    }}/>
                                });
                            }
                        );

                        setPopup({});
                    }}
                    onDecline={() => {
                        setPopup({})
                    }}
                />
            )
        });
    }

    componentDidMount() {
        const {datas, idProspect, getDatas} = this.props;
        const info = this.generateUrlInfo(idProspect);

        if (datas[info.name] &&
            datas[info.name][info.id] &&
            datas[info.name][info.id][info.type]
        ) {
            this.setState({
                info: info
            }, () => {
                this.manageDatas(datas[info.name][info.id][info.type]);
            });
        } else {
            this.setState({
                info: info
            });
            if (info.id !== 'all') {
                getDatas(info);
            }
        }
    }

    componentWillUnmount() {
        const {onExpand} = this.props;
        onExpand(false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {loader, datas, idProspect, getDatas} = this.props;
        let {info} = this.state;

        if (prevProps !== this.props) {
            const prevLoader = prevProps.loader;

            if (prevLoader && prevLoader['single'] && loader['single'] && datas.error) {
                this.setState({
                    errorInfos: true
                })
            } else if (prevLoader &&
                prevLoader['single'] &&
                datas[info.name] &&
                datas[info.name][info.id] &&
                datas[info.name][info.id][info.type]
            ) {
                this.manageDatas(datas[info.name][info.id][info.type])
            } else if (prevProps.idProspect && prevProps.idProspect !== idProspect) {
                const info = this.generateUrlInfo(idProspect);
                this.setState({
                    info: info
                });
                getDatas(info);
            }
        }

        if (prevState.isEditMode && !this.state.isEditMode && datas[info.name]) {
            this.manageDatas(datas[info.name][info.id][info.type]);
        }
    }

    render() {
        const {t, expanded, onExpand, loader, deleteService, setPopup, setError, removeError, setCanChangeWindow} = this.props;
        const {info: {id}, isGeolocated, redirect, errorInfos, data, isEditMode, infoModify, title: {icon = 'add-company', text = ''}, isLoading} = this.state;

        let classLoader = '';
        let notDisplay = true;
        let classGeolocated = isGeolocated ? '' : 'disable';

        if (loader['single'] || errorInfos) {
            classLoader = 'infos-loader';
            notDisplay = false;
        }

        return (
            <>
                {redirect}

                <div className={'page-details__prospect'}>
                    <div className={`row row-fluid page-details__prospect__title`}>
                        {notDisplay ? (
                            <>
                                <div className={'col-auto col-fluid page-details__prospect__name'}>
                                    <span className={'page-details__prospect__title__picto'}>
                                        <PolIcon icon={icon}/>
                                    </span>
                                    <p title={text}>{text}</p>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className={'page-details__edit'}>
                        {expanded && !isEditMode ? (
                            <>
                                <div className={'page-details__edit__dropDown__content--right'}>
                                    <li className={'page-details__edit__dropDown__content--right__item'}
                                        data-text={'Modifier'}
                                        data-for={'expanded'}
                                        onClick={() => {
                                            onExpand(true);
                                            this.setState({
                                                isEditMode: true
                                            })
                                        }}
                                        data-tip={t('edit')}>
                                                <span
                                                    className={'page-details__edit__dropDown__content--right__item__icon'}>
                                                    <PolIcon icon={'edit'}/>
                                                </span>
                                    </li>
                                    <li className={`page-details__edit__dropDown__content--right__item ${classGeolocated}`}
                                        data-text={'Géolocaliser'}
                                        onClick={() => this.viewMaps(id)}
                                        data-for={'expanded'}
                                        data-tip={classGeolocated ? t('geoloc_disabled') : t('location')}>
                                                 <span
                                                     className={'page-details__edit__dropDown__content--right__item__icon'}>
                                                     <PolIcon icon={'map'}/>
                                                 </span>
                                    </li>
                                    <li className={'page-details__edit__dropDown__content--right__item'}
                                        data-text={'Supprimer'}
                                        data-for={'expanded'}
                                        onClick={() => {
                                            this.removeProspect();
                                        }}
                                        data-tip={t('delete')}>
                                                <span className={'page-details__edit__dropDown__content__item__icon'}
                                                      data-action={'delete'}>
                                                    <PolIcon icon={'bin'}/>
                                                </span>
                                    </li>
                                    <div className={'page-details__prospect__close--less'} onClick={() => {
                                        if (expanded) {
                                            this.setState({
                                                isEditMode: false
                                            })
                                        }
                                        onExpand(!expanded)
                                    }}>
                                        <span className={'page-details__prospect__close__icon'}>
                                            <PolIcon icon="arrow-left" className={'d-none d-lg-block'}/>
                                        </span>
                                        <span>
                                            {t('show_less')}
                                        </span>
                                    </div>
                                    <ReactTooltip effect="solid" id={'expanded'}/>
                                </div>
                            </>
                        ) : (
                            <>
                                {!isEditMode ? (
                                    <>
                                        <li className={'page-details__edit__dropDown'}>
                                            <PolIcon icon="actions"/>
                                            <ul className={'page-details__edit__dropDown__content'}>
                                                <li className={'page-details__edit__dropDown__content__item'}
                                                    data-text={'Modifier'}
                                                    data-for={'not-expanded'}
                                                    onClick={() => {
                                                        onExpand(true);
                                                        this.setState({
                                                            isEditMode: true
                                                        })
                                                    }}
                                                    data-tip={t('edit')}>
                                            <span className={'page-details__edit__dropDown__content__item__icon'}>
                                                <PolIcon icon={'edit'}/>
                                            </span>
                                                </li>
                                                <li className={`page-details__edit__dropDown__content__item ${classGeolocated}`}
                                                    data-text={'Géolocaliser'}
                                                    onClick={() => this.viewMaps(id)}
                                                    data-for={'not-expanded'}
                                                    data-tip={classGeolocated ? t('geoloc_disabled') : t('location')}>
                                            <span className={'page-details__edit__dropDown__content__item__icon'}>
                                                <PolIcon icon={'map'}/>
                                            </span>
                                                </li>
                                                <li className={'page-details__edit__dropDown__content__item'}
                                                    data-text={'Supprimer'}
                                                    onClick={() => {
                                                        this.removeProspect();
                                                    }}
                                                    data-for={'not-expanded'}
                                                    data-tip={t('delete')}>
                                                <span className={'page-details__edit__dropDown__content__item__icon'}
                                                      data-action={'delete'}>
                                                    <PolIcon icon={'bin'}/>
                                                </span>
                                                </li>
                                            </ul>
                                        </li>
                                        <div className={'page-details__prospect__close'} onClick={() => {
                                            if (expanded) {
                                                this.setState({
                                                    isEditMode: false
                                                })
                                            }
                                            onExpand(!expanded)
                                        }}>
                                    <span>
                                        {t('show_more')}
                                    </span>
                                            <span className={'page-details__prospect__close__icon'}>
                                        <PolIcon icon="arrow-right" className={'d-none d-lg-block'}/>
                                    </span>
                                        </div>
                                    </>) : null}
                            </>
                        )}
                        <ReactTooltip effect="solid" id={'not-expanded'}/>

                    </div>

                    {!isEditMode ? (
                        <div className={'page-details__prospect__content data'}>
                            <View
                                data={data}
                                classLoader={classLoader}
                                updateView={this.updateView.bind(this)}
                                deleteService={deleteService}
                                setPopup={setPopup}
                                isExpanded={expanded}
                                setError={setError}
                                removeError={removeError}
                                order_entity='Prospect'
                            />
                        </div>
                    ) : (
                        <Form info={infoModify}
                              isCurrentEditionByView={true}
                              onBack={() => this.setState({
                                  isEditMode: false
                              })}
                              setCanChangeWindow={setCanChangeWindow}
                        />
                    )}
                </div>
                {isLoading &&
                <Loading message={t("loader_message_delete")} specificClass={"form-loader"}/>
                }
            </>
        )
    }
}

const mapStateToProps = ({content: {loader, expanded}, datas}) => ({
    loader,
    expanded,
    datas
});

const mapDispatchToProps = dispatch => {
    return {
        getDatas: (info) => dispatch(DataService(info)),
        setLastVisit: (info, data) => dispatch(setLastVisit(info, data)),
        pushLastVisit: (lastVisit) => dispatch(pushLastVisit(lastVisit)),
        deleteService: (info) => dispatch(DeleteService(info)),
        setPopup: (popupInfo) => dispatch(setPopup(popupInfo)),
        deleteLastVisitProspectId: (prospectId) => dispatch(deleteLastVisit(prospectId)),
        onExpand: (expanded) => dispatch(setExpandInfo(expanded)),
        resetStoreData: () => dispatch(resetStoreDatas(true)),
        setError: (error) => dispatch(setError(error)),
        removeError: () => dispatch(removeError()),
        setFilterSearch: (data) => dispatch(setFilterSearch(data)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(InfosProspect));

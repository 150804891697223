import useAccessVoter from "v4/hooks/useAccessVoter";

export default function CampaignVoter() {
    const {isGranted} = useAccessVoter();

    const canCreateCampaign = () => {
        return isGranted('campaign_create')
    }

    return ({canCreateCampaign})
}

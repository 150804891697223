import {setLoader} from "../../actions/content";
import {fetchHandler, manageError} from "../datas/datas";
import {setDataForSelect} from "../../actions/prospects";

export function getProspectsSelectData(info, prospectId, entityId) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');

        dispatch(setLoader({
            [info.loader]: true
        }));

        return fetch(process.env.REACT_APP_HOST_API + '/api/prospects', {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
        }).then(
            fetchHandler,
            error => {
                dispatch(manageError(true, info))
            }
        ).then(
            data => {
                if (data) {
                    data = data.data ? data.data : data['hydra:member'];
                    dispatch(setDataForSelect(data, prospectId, entityId));

                    dispatch(setLoader({
                        [info.loader]: false
                    }));
                } else {
                    dispatch(manageError(500, info))
                }
            },
            error => {
                dispatch(manageError(error.status, info))
            }
        );
    };
}
import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useHistory} from "react-router-dom";

export default function ProspectTabBackButton() {
    const {t} = usePolTranslation();
    const {goBack} = useHistory();

    return (
        <div className="prospect-tab__prospect__header__back" onClick={goBack}>
            <PolIcon icon='return'/>
            <span>{t('retour')}</span>
        </div>
    )
}

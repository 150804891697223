import Loading from "components/Loading/Loading";
import React, {useEffect, useState} from 'react';
import {CAMPAIGN_HAS_MAIL} from "v4/data/apiRoutes";

import "v4/features/front/campaign/components/CampaignPopup/CampaignPopup.scss";
import MailingCampaign
    from "v4/features/front/campaign/components/CampaignPopup/components/MailingCampaign/MailingCampaign";
import StandardCampaign
    from "v4/features/front/campaign/components/CampaignPopup/components/StandardCampaign/StandardCampaign";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import apiService from "v4/services/api.service";

export default React.memo(function CampaignPopup({selectedIds, type, toggleShow}) {
    const {t} = usePolTranslation();
    const [{data, isLoading, isFinished, isError}, fetchHasMail] = useFetch();

    useEffect(() => {
        fetchHasMail({
            url: apiService.generateUrl(CAMPAIGN_HAS_MAIL),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    ["prospects" === type ? "prospectIds" : "contactIds"]: selectedIds
                })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isMailing, setIsMailing] = useState(null);

    const goStart = () => setIsMailing(null);
    const goMailing = () => setIsMailing(true);
    const goStandard = () => setIsMailing(false);

    return (
        <div className="campaign-popup__body">
            {isLoading && <Loading message="Chargement..." containerClass="campaign-popup__loader"/>}
            {(isFinished && !isError) && (
                <>
                    {(data.hasEmail && null === isMailing) && (
                        <div className="campaign-popup__choice">
                            <button onClick={goStandard}>{t("standard_campaign")}</button>
                            <button onClick={goMailing}>{t("mailing_campaign")}</button>
                        </div>
                    )}
                    {((!data.hasEmail && null === isMailing) || (false === isMailing)) &&
                        <StandardCampaign selectedIds={selectedIds} type={type}
                                          goStart={null === isMailing ? toggleShow : goStart}/>
                    }
                    {(data.hasEmail && isMailing) && <MailingCampaign selectedIds={selectedIds} type={type}/>}
                </>
            )}
        </div>
    )
})

export const fillForm = (inputs, activeFilters) => {
    if (!activeFilters) {
        return inputs;
    }
    const keys = Object.keys(activeFilters);

    if (keys.length) {
        keys.forEach(key => {
            const params = findByKey(inputs, key);
            if (Object.keys(params).length === 0 || !inputs[params.group].fields[params.item].input) {
                return;
            }

            const items = inputs[params.group].fields[params.item].input.items
            const enums = inputs[params.group].fields[params.item].input.enum
            const enum_titles = inputs[params.group].fields[params.item].input.enum_titles

            // Recherche sauvegardé
            if (activeFilters[key] && typeof activeFilters[key] === 'string' && activeFilters[key].length) {
                inputs[params.group].fields[params.item].input.value = activeFilters[key]
                return;
            }
            if (typeof activeFilters[key] === 'boolean') {
                inputs[params.group].fields[params.item].input.value = activeFilters[key]
            }
            if (activeFilters[key] && typeof activeFilters[key] === 'object' && !Array.isArray(activeFilters[key]) && activeFilters[key] !== null) {
                // Si prefill d'un input date/number compare
                if (activeFilters[key].equal) {
                    inputs[params.group].fields[params.item].input = {
                        ...inputs[params.group].fields[params.item].input,
                        equal: activeFilters[key].equal,
                        begin: activeFilters[key].begin,
                        end: activeFilters[key].end,
                        value: {
                            equal: activeFilters[key].equal,
                            begin: activeFilters[key].begin,
                            end: activeFilters[key].end,
                        }
                    }
                } else {
                    // Si select simple
                    if (enums && enum_titles) {
                        // On cherche l'index de la valeur grâce au libellé
                        inputs[params.group].fields[params.item].input.value = enums[enum_titles.indexOf(activeFilters[key].value)]
                        if (!inputs[params.group].fields[params.item].input.value) {
                            // Sinon on cherche directement dans les valeurs
                            inputs[params.group].fields[params.item].input.value = enums[enums.indexOf(activeFilters[key].value)]
                        }
                        if (!inputs[params.group].fields[params.item].input.value) {
                            // Sinon on fait une recherche en splittant la valeur
                            const firstName = activeFilters[key].value.split(' ').pop()
                            const index = enum_titles.findIndex(item => item.search(firstName) !== -1)
                            inputs[params.group].fields[params.item].input.value = enums[index]
                        }
                        return;
                    }
                    // Si input default
                    inputs[params.group].fields[params.item].input.value = activeFilters[key].value;
                }
                // Select multiple
            } else if (Array.isArray(activeFilters[key])) {
                if (inputs[params.group].fields[params.item].input && inputs[params.group].fields[params.item].input.items) {
                    inputs[params.group].fields[params.item].input.value = activeFilters[key].map(item => {
                        if(item === undefined){
                            return '';
                        }
                        let index = items.enum.findIndex((elt) => {
                            if (!elt || !item) return false;
                            return elt.toUpperCase() === item.toUpperCase()
                        })
                        if (index === -1) {
                            index = items.enum_titles.findIndex((elt) => {
                                if (!elt || !item) return false;
                                return elt.toUpperCase() === item.toUpperCase()
                            })
                            if (index === -1) {
                                const firstName = item.split(' ').pop()
                                index = items.enum_titles.findIndex(elt => elt.toUpperCase().search(firstName.toUpperCase()) !== -1)
                            }
                        }

                        return {
                            label: items.enum_titles[index],
                            value: items.enum[index]
                        }
                    });
                }
            }
        })
    }

    return inputs;
}

export const findByKey = (inputs, key) => {
    let eltTargetedBy = {};
    inputs.some((input, index) => {
        if (input.fields) {
            input.fields.forEach((item, itemKey) => {
                if (item.key === key) {
                    eltTargetedBy = {
                        group: index,
                        item: itemKey
                    };
                }
            });
        }
        return eltTargetedBy && eltTargetedBy.group;
    });

    return eltTargetedBy;
};

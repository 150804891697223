import React, {useEffect} from 'react';
import {routesBase} from "v4/data/appRoutes";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useFetch from "v4/hooks/useFetch";
import {handleFormToJson} from "v4/utils";
import {generateUrl} from "v4/services/api.service";
import {CAMPAIGN_GENERATE} from "v4/data/apiRoutes";
import {PolIcon} from "components/PolIcon/policon";
import {useHistory} from "react-router-dom";
import useNotification from "v4/hooks/useNotification";

export default function StandardCampaign({selectedIds, type, goStart}) {
    const {t} = usePolTranslation();
    const [{isLoading, isFinished, isError}, fetchGenerate] = useFetch();
    const {addSuccessNotification, addErrorNotification} = useNotification();

    const onGenerate = (e) => {
        e.preventDefault();
        const bodyData = handleFormToJson(e.currentTarget);
        if (Object.values(bodyData).every((value) => String(value).trim() === '')) return;


        fetchGenerate({
            url: generateUrl(CAMPAIGN_GENERATE),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    ...bodyData,
                    ["prospects" === type ? "prospectIds" : "contactIds"]: selectedIds
                })
            }
        })
    };

    const {push} = useHistory();
    useEffect(() => {
        if (isFinished && !isError) {
            addSuccessNotification(t('campaign_generated'));
            push(routesBase.baseTask);
        }
        if (isFinished && isError) addErrorNotification(t('error_campaign_generation'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished, isError]);

    return (
        <>
            <form onSubmit={onGenerate}>
                <div className="input-field-wrapper">
                    <input type="text" name="title" className="input-field"
                           placeholder={t("campaign_name")} required/>
                </div>
                <div className="campaign-popup__buttons">
                    <button type="button" onClick={goStart}>
                        {t("return")}
                    </button>
                    <button>
                        {t("generate")}
                        {isLoading && <PolIcon icon="circle-notch"/>}
                    </button>
                </div>
            </form>
        </>
    )
}

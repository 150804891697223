import React, {useEffect, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, Redirect} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";
import {
    PRODUCT_ATTRIBUTE_ADD,
    PRODUCT_ATTRIBUTE_EDIT,
    PRODUCT_ATTRIBUTE_FORM,
    PRODUCT_ATTRIBUTE_ITEM
} from "v4/data/apiRoutes";
export default function ProductAttributesForm({customerId, id}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification();
    const [{data: productAttributeFormData, isLoading: isLoadingForm, isFinished: isFinishedForm}, fetchProductAttributeForm] = useFetch();
    const [{data: productAttributeDataSave, isError: isErrorSave, isLoading: isLoadingSave, isFinished: isFinishedSave}, fetchProductAttributeSave] = useFetch();
    const [productAttributeForm, setProductAttributeForm] = useState(null);
    const [productAttribute, setProductAttribute] = useState({
        label: null,
        type: 'integer',
        groupCode: null,
        position: 1,
        defaultValueField: null,
        customerId: customerId
    });
    const isEdit = (!!id);
    const [redirect, setRedirect] = useState(null);
    useEffect(() => {
        fetchProductAttributeForm({
            url: id
                ? apiService.generateUrl(PRODUCT_ATTRIBUTE_EDIT, {id: id, customerId: customerId})
                : apiService.generateUrl(PRODUCT_ATTRIBUTE_FORM, {customerId: customerId}),
        })
    }, [fetchProductAttributeForm, id, customerId, isEdit])
    useEffect(() => {
        if (productAttributeFormData && isFinishedForm) {
            const form = productAttributeFormData?.schema?.['hydra:member']?.[2];
            setProductAttributeForm(form);
            if (form) {
                setProductAttribute({
                    label: form?.label?.value,
                    type: form?.type?.value,
                    groupCode: form?.groupCode?.value,
                    position: form?.position?.value ?? 1,
                    defaultValueField: form?.defaultValueField?.value,
                    customerId: form?.customerId?.value,
                });
            }
        }
    }, [isEdit, setProductAttributeForm, productAttributeFormData, isFinishedForm])
    const handleChange = (input) => {
        setProductAttribute({...productAttribute, [input.target.name]: input.target.value})
    }
    const handleSave = (e) => {
        e.preventDefault()
        fetchProductAttributeSave({
            url: isEdit
                ? apiService.generateUrl(PRODUCT_ATTRIBUTE_ITEM, {id: id})
                : apiService.generateUrl(PRODUCT_ATTRIBUTE_ADD),
            config: {
                method: 'POST',
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(productAttribute)
            }
        })
    }
    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('product_attribute_save_error'))
        }
        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('product_attribute_save_done'))
            setRedirect(<Redirect to={routesBase.adminProductAttributes}/>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productAttributeDataSave, isErrorSave, isFinishedSave])
    const CardHeaderTools = () => (
        <>
            <Link to={routesBase.adminProductAttributes} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )
    return (
        <AdminCard title={t('manage_product_attributes')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoadingForm || isLoadingSave || !productAttributeForm)
                    ? <Loader message="Chargement..."/>
                    : <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                        <div className="row row-fluid mt-4">
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (productAttribute?.label ? 'field-written' : null)}>
                                    <input type="text" name="label" id="product_attribute_label" className="field__input"
                                           defaultValue={productAttribute?.label} onChange={(input) => handleChange(input)}/>
                                    <label className="field__label" htmlFor="product_attribute_label">{t('label')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (productAttribute?.type ? 'field-written' : null)}>
                                    <select name="type" id="product_attribute_type" className="field__input" required={true}
                                            defaultValue={productAttributeForm?.type?.value}
                                            onChange={(input) => handleChange(input)}>
                                        {productAttributeForm?.type?.enum?.map((type, typeKey) => {
                                            return (
                                                <option key={typeKey} value={type} label={type}></option>
                                            )
                                        })}
                                    </select>
                                    <label className="field__label" htmlFor="product_attribute_type">{t('type')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (productAttribute?.groupCode ? 'field-written' : null)}>
                                    <input type="text" name="groupCode" id="product_attribute_group_code" className="field__input"
                                           defaultValue={productAttribute?.groupCode} onChange={(input) => handleChange(input)}/>
                                    <label className="field__label" htmlFor="product_attribute_group_code">{t('groupCode')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (productAttribute?.position ? 'field-written' : null)}>
                                    <input type="number" name="position" id="product_attribute_position" className="field__input"
                                           defaultValue={productAttribute?.position} onChange={(input) => handleChange(input)}/>
                                    <label className="field__label" htmlFor="product_attribute_position">{t('position')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (productAttribute?.defaultValueField ? 'field-written' : null)}>
                                    <input type="text" name="defaultValueField" id="product_attribute_group_code" className="field__input"
                                           defaultValue={productAttribute?.defaultValueField} onChange={(input) => handleChange(input)}/>
                                    <label className="field__label" htmlFor="product_attribute_group_code">{`${t('default_value_field')} - Ex: stockQty, priceExcludingVat, sellingPriceExcludingVat`}</label>
                                </div>
                            </div>
                        </div>
                    </form>
            }
            {redirect}
        </AdminCard>
    )
}
import React from 'react';
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ViewOrdersLegend from "v4/pages/admin/ViewOrders/components/ViewOrdersLegend/ViewOrdersLegend";
import {PolIcon} from "components/PolIcon/policon";

export default function ViewOrdersHeader() {
    const {t} = usePolTranslation();
    const {currentViewOrder, onSubmit, isSubmitDisabled} = useViewOrdersContext();

    return (
        <div className="new-view-orders__header">
            <div className="new-view-orders__header__title">
                <h1>{t('admin_gestion_orders')}</h1>
                <p className="box__header__text">
                    {t('editing')} : <b>{currentViewOrder?.label ?? t('pick_form_to_edit')}</b>
                </p>
            </div>
            <div className="new-view-orders__header__actions">
                <ViewOrdersLegend/>
                <button className="new-view-orders__header__submit btn btn-primary"
                        onClick={onSubmit}
                        disabled={isSubmitDisabled}>
                    {t('enregistrer')}
                    {isSubmitDisabled && <PolIcon icon="circle-notch" className="spinner"/>}
                </button>
            </div>
        </div>
)
}

import React from 'react';
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import ProspectTasksTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTasksTabPartial/ProspectTasksTabPartial";
import ProspectOpenTasksTabActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOpenTasksTab/components/ProspectOpenTasksTabActions/ProspectOpenTasksTabActions";
import ProspectOpenTasksTabRowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOpenTasksTab/components/ProspectOpenTasksTabRowActions/ProspectOpenTasksTabRowActions";

const fetchParams = {
    isClosed: false,
};

export default function ProspectOpenTasksTabListing({prospectId}) {

    return (
        <div className="prospect-tab__listing__wrapper">
            <ProspectHeadingTabPartial title="current_tasks" actions={(props) => <ProspectOpenTasksTabActions {...props}/>}/>
            <ProspectTasksTabPartial fetchParams={fetchParams}
                                     prospectId={prospectId}
                                     rowActions={ProspectOpenTasksTabRowActions}/>
        </div>
    )
}

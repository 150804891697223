import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import useToggle from "v4/hooks/useToggle";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ReactTooltip from "react-tooltip";
import MoveTaskForm from "v4/features/front/moveTask/components/MoveTaskForm/MoveTaskForm";

export default function MoveTaskButton({taskId, className}) {
    const {t} = usePolTranslation();
    const [isOpen, toggleOpen] = useToggle();

    return (
        <>
            <div
                className={"move-task__button " + className}
                onClick={toggleOpen}
                data-for="move-task"
                data-tip={t('move_task')}>
                <PolIcon icon="arrow-right-bis"/>
            </div>
            <ReactTooltip id="move-task" effect="solid"/>
            <PolPopup
                title={t('move_task')}
                isOpen={isOpen}
                closePopup={toggleOpen}
                classNames={{popup: 'move-task__popup'}}
                buttonsRender={({isError, inputs, submit, isLoading}) => {
                    if (isError || !inputs) return null;

                    return (
                        <button className="btn move-task__submit" onClick={submit} disabled={isLoading}>
                            <span>{t('validate')}</span>
                            {isLoading && <PolIcon icon="circle-notch" className="spinner"/>}
                        </button>
                    )
                }}>
                {({setInternalState}) => (
                    <MoveTaskForm
                        taskId={taskId}
                        setInternalState={setInternalState}
                        toggleOpen={toggleOpen}/>
                )}
            </PolPopup>
        </>
    )
}

import React, {useState} from 'react'
import {withPolTranslation} from "v4/hooks/usePolTranslation";

const TaskName = ({onChange, t, item}) => {
    const [checkedQuoteReminder, setCheckedQuoteReminder] = useState(item && item.isQuoteReminder);
    return (
        <>
            <input className={'field__input'} type={'checkbox'} value={checkedQuoteReminder}
                   checked={checkedQuoteReminder} id="is_quote_reminder" onChange={(e) => {
                onChange('isQuoteReminder', e.target.value)
                setCheckedQuoteReminder(!checkedQuoteReminder)
            }}/>
            <label htmlFor="is_quote_reminder">{t('is_quote_reminder')}</label>
        </>
    )
}

export default withPolTranslation(TaskName)

import React, {Fragment, useEffect, useState} from 'react'
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {Prompt, Redirect} from "react-router-dom";
import {PolIcon} from "../../PolIcon/policon";
import {adminFetchHandler} from "../../../api/datas/datas";
import {Input} from "../../Input/input";
import {PostService} from "../../../api/datas/post";
import {getForm} from "../../../api/admin/get";
import {handleFormData} from "../../../reducers/form";
import {setLoader} from "../../../actions/content";
import {updateCustomerList} from "../../../actions/admin";

const FormCustomer = ({t, adminFetchHandler, form, postService, itemId, setLoader, updateCustomerList, additionalInput}) => {
    const [inputs, setInputs] = useState({});
    const [redirect, setRedirect] = useState(null);
    const formUrl = `/api/customers/form${itemId ? `/${itemId}` : ''}`
    const [isEdited, setIsEdited] = useState(false);

    if (form && form.errors) {
        const errors = form.errors;

        Object.keys(inputs).forEach(key => {
                const input = inputs[key]
                const errorField = Object.keys(errors).find((error) => error === input.keyItem);
                if (errorField) {
                    input.error = errors[errorField];
                }
            }
        );

        setInputs({
            ...inputs
        })

        delete form.errors;
    }

    const handleLeaving = () => {
        setIsEdited(false);
        return t("customer_confirm_leave_form_alert");
    }

    useEffect(() => {
        if (!Object.keys(inputs).length) {
            setLoader({
                'form-customer': true
            })
            getForm(formUrl).then(data => {
                setInputs(handleFormData(data));
                setLoader({
                    'form-customer': false
                })
            }).catch(error => {
                adminFetchHandler(error, {})
                setLoader({
                    'form-customer': false
                })
            })
        }
    }, [adminFetchHandler, formUrl, inputs, setLoader]);

    const handleChange = (key, value) => {
        setIsEdited(true);
        inputs[key].value = inputs[key].type === 'boolean' ? !inputs[key].value : value
        inputs[key].originalValue = ''
        inputs[key].error = null;

        setInputs({
            ...inputs
        })

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let dataFetch = {};
        Object.keys(inputs).forEach(key => {
            dataFetch[key] = inputs[key].value;
            if (Array.isArray(inputs[key].value)) {
                dataFetch[key] = inputs[key].value.map(item => {
                    if (item instanceof File) {
                        return item;
                    }
                    return item.value
                });
            }
        });
        setIsEdited(false);

        let infoSubmit = {
            url: `/api/customers`,
            submittedData: dataFetch,
            loader: 'form-customer',
            callback: () => {
                setRedirect(<Redirect to={`/administration/customer_list/`}/>)
                updateCustomerList();
            }
        };

        if (itemId) {
            dataFetch.id = itemId;
            infoSubmit = {
                ...infoSubmit,
                putUrl: `/api/customers`,
            }
        }

        postService(infoSubmit, t)

        return false;
    };

    const handleFilesMaxSizeExceeded = () => {
        if (inputs.logo) {
            inputs.logo.error = t('maximum_size_exceeded_label')
            setInputs({
                ...inputs
            })

        }
    }

    return (
        <>
            {redirect}

            <form className={`form form__scrollable form__admin`} onSubmit={(event) => handleSubmit(event)}>
                <Prompt message={handleLeaving} when={isEdited}/>
                <div className={'row row-fluid'}>
                    {Object.keys(inputs).map((key, index) => {
                        const input = inputs[key]
                        let fieldClass = input && input.value ? 'field-written' : '';
                        fieldClass += input && input.error ? ' field-error' : '';

                        if(input && input.attr && input.attr.type && input.attr.type === 'hidden'){
                            return null;
                        }

                        return (
                            <Fragment key={index}>
                                {input.error ? (
                                    <div className={'form__error'}
                                         onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                         onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                                    >
                                        <PolIcon icon={'exclamation-circle'}
                                                         className={'form__error__icon'}/>
                                        <p className={'form__error__content'}>{input.error}</p>
                                    </div>
                                ) : null}
                                <div className={`col-12 col-md-3 field ${fieldClass}`}>
                                    <Input t={t}
                                           inputData={input}
                                           onChange={(newValue) => handleChange(key, newValue)}
                                           typeForm={null}
                                           handleFilesSizeExceeded={handleFilesMaxSizeExceeded}
                                    />
                                </div>
                            </Fragment>)
                    })}
                    <div className={`col-12 col-md-9 field`}>
                        {additionalInput}
                    </div>
                </div>

                <div className={'row align-items-center justify-content-end'}>
                    <div className={'col-12 col-md-auto form__back'} onClick={() =>
                        setRedirect(<Redirect to={`/administration/customer_list/`}/>)
                    }>
                        <PolIcon icon={'chevron-circle-left'} className={'form__back__icon'}/>
                        {t('retour')}
                    </div>
                    <div className={`col-12 col-md-auto form__submit`}>
                        <button className={'btn btn-primary form__submit__btn'} onClick={() => handleSubmit}>
                            {t('enregistrer')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = ({form}) => ({
    form
});
const mapDispatchToProps = dispatch => {
    return {
        postService: (info, t) => dispatch(PostService(info, t)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setLoader: (value) => dispatch(setLoader(value)),
        updateCustomerList: () => dispatch(updateCustomerList()),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(FormCustomer));

import React from 'react';
import ProspectPageProvider from "v4/contexts/ProspectPageContext";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {PROSPECT_SEARCH} from "v4/data/apiRoutes";
import useToggle from "v4/hooks/useToggle";
import ProspectMap from "v4/pages/front/Prospect/components/ProspectMap/ProspectMap";
import ProspectTabsSwitcher from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/ProspectTabsSwitcher";
import ProspectView from "v4/pages/front/Prospect/components/ProspectView/ProspectView";
import 'v4/pages/front/Prospect/ProspectPage.scss';

export default function ProspectPage({prospectId, currentTabName, isMapPage}) {
    const [isExpanded, toggleExpanded] = useToggle();
    return (
        <ProspectPageProvider key={prospectId} prospectId={prospectId} currentTabName={currentTabName}>
            <div className={`prospect__page${isExpanded ? ' fullpage' : ''}`}>
                <ProspectView isExpanded={isExpanded} toggleExpanded={toggleExpanded}/>

                {!isMapPage && <ProspectTabsSwitcher currentTabName={currentTabName}/>}
            </div>
            {isMapPage && (
                <SearchProvider searchRouteName={PROSPECT_SEARCH} pageType="prospect-map">
                    <ProspectMap/>
                </SearchProvider>
            )}
        </ProspectPageProvider>
    )
}

import getValueType from "v4/pages/admin/ViewOrders/contexts/utils/getValueType";

export default function createViewOrderPayload(currentViewOrder) {
    if (!currentViewOrder) return null;

    const {id, state, viewOrderKey, viewOrderType, viewOrderSubKey} = currentViewOrder;

    const groups = state.map(({id, label, width, fields, isRequired, isDefault}, index) => {
        if (isRequired || isDefault) return null; // These groups are not saved (iso prod), it means they are not shown in the front part

        return {
            id,
            label,
            width: +width,
            order: index,
            isGroup: true,
            type: viewOrderSubKey,
            fields: fields.map((field, index) => {
                const {
                    id,
                    key,
                    label,
                    width,
                    isRequired,
                    isReadOnly,
                    isExtended,
                    isEmail,
                    isSpecificField,
                    isUnique,
                    isHidden,
                    conditionField,
                    conditionValue,
                    isParentField,
                } = field;

                const valueType = getValueType(field);

                return {
                    id,
                    key,
                    viewKey: key,
                    label,
                    width: width.toString(),
                    order: index.toString(),
                    isRequired,
                    isReadOnly,
                    isExtended,
                    isEmail,
                    isSpecificField,
                    isUnique,
                    isHidden,
                    ...(valueType && {valueType}),
                    conditionField,
                    conditionValue,
                    isParentField,
                    isGroup: false,
                    viewOrderType,
                }
            }),
        }
    }).filter(Boolean);

    return {
        [viewOrderKey]: {
            [viewOrderType]: groups,
            id,
        }
    }
}

import React, {useCallback} from 'react';
import {Link, useHistory} from "react-router-dom";
import {useFrontContext} from "v4/contexts/FrontContext";
import {getRouteByBasePath, getRouteByEntity, getRoutesByFilters, getRoutesParams} from "v4/data/appRoutes";
import BadgeMercure
    from "v4/features/front/routing/components/NavigationMenuItems/components/BadgeMercure/BadgeMercure";
import useFrontRoute from "v4/hooks/useFrontRoute";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function NavigationMenuItems() {
    const {push, location: {pathname}} = useHistory();
    const {t} = usePolTranslation();
    const {refreshRoute} = useFrontRoute();
    const {currentListingEntity, tab, isProspectTab} = useFrontContext();

    const getActive = useCallback((route) => {
        let currentRoute = getRouteByBasePath(pathname, !isProspectTab);

        if (isProspectTab) {
            currentRoute = tab ? getRoutesByFilters({id: tab.id})[0] : getRouteByEntity(currentListingEntity);
        }

        return currentRoute?.basePath.startsWith(route?.basePath) ? 'active' : '';

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentListingEntity, pathname]);

    return (
        getRoutesParams().map((route) => {
            return (
                route?.testIsGranted?.() &&
                route.isProtected && route.menuTitle && !route.isAdmin &&
                <li key={route.id} className="nav__list__item">
                    {
                        route.subMenu?.length > 0
                            ? (
                                <>
                                    <span
                                        className={`nav__list__item__link ${route.className} ${getActive(route)}`}>
                                        {t(route.menuTitle)}
                                    </span>
                                    <ul className={'sub-menu'}>
                                        {route.subMenu.map(subMenu => {
                                            return subMenu.menuTitle && (
                                                <li key={subMenu.id} className="sub-menu__item">
                                                    <Link to={subMenu.basePath}
                                                          className={`sub-menu__item__link ${route.className}${pathname.startsWith(subMenu.basePath) ? ' active' : ''}`}
                                                          onClick={(e) => {
                                                              e.preventDefault();
                                                              if (pathname !== subMenu.basePath) {
                                                                  push(subMenu.basePath);
                                                              } else {
                                                                  refreshRoute()
                                                              }
                                                          }}>
                                                        {t(subMenu.menuTitle)}
                                                        {subMenu?.badgeId && <BadgeMercure badgeId={subMenu.badgeId}/>}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </>
                            )
                            : (
                                <Link to={route.basePath}
                                      className={`nav__list__item__link ${route.className} ${getActive(route)}`}
                                      onClick={(e) => {
                                          e.preventDefault();
                                          if (pathname !== route.basePath) {
                                              push(route.basePath);
                                          } else {
                                              refreshRoute()
                                          }
                                      }}>
                                    {t(route.menuTitle)}
                                    {route?.badgeId && <BadgeMercure badgeId={route.badgeId}/>}
                                </Link>
                            )
                    }
                </li>
            )
        })
    )
}

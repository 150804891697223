import React from "react";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const dynamicChildFactory = classNames => child =>
    React.cloneElement(child, {
        classNames
    });

function TransitionRoute({children, location, isTransition, setTransition}) {
    const {key} = location;
    const classNames = location.state && location.state.transition ? 'page' : '';

    return (
        <TransitionGroup component={null} childFactory={dynamicChildFactory(classNames)}>
            <CSSTransition
                timeout={0}
                key={key}
                onEnter={() => setTransition(false)}
                onExited={() => setTransition(true)}
            >
                {children}
            </CSSTransition>
        </TransitionGroup>
    )
}

export default TransitionRoute;
import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {Redirect, Prompt} from "react-router-dom";
import {PolIcon} from "../../PolIcon/policon";
import {adminFetchHandler} from "../../../api/datas/datas";
import {Input} from "../../Input/input";
import {PostService} from "../../../api/datas/post";
import {getForm} from "../../../api/admin/get";
import {handleFormData} from "../../../reducers/form";
import {setLoader, setPopup} from "../../../actions/content";
import {useRecoilValue} from "recoil";
import {customerIdSelector} from "../atoms/customer";
import {resetStoreDatas} from "../../../actions/datas";

const FormUser = ({
                      t, adminFetchHandler, form, postService, itemId, setLoader, setPopup, disableCustomerIdFilter
                      , setCalendarUrl, calendarUrl, resetStoreDatas
                  }) => {
    const [inputs, setInputs] = useState({});
    const [redirect, setRedirect] = useState(null);
    const [isEdited, setIsEdited] = useState(false);
    const customerId = useRecoilValue(customerIdSelector);
    const formUrl = `/api/user-info/form${itemId ? `/${itemId}` : ''}${!disableCustomerIdFilter ? `?customerId=${customerId}` : ``}`

    const handleLeaving = () => {
        setIsEdited(false);
        return t("customer_confirm_leave_form_alert");
    }

    if (form && form.errors) {
        const errors = form.errors;

        Object.keys(inputs).forEach(key => {
                const input = inputs[key]
                const errorField = Object.keys(errors).find((error) => error === input.keyItem);
                if (errorField) {
                    input.error = errors[errorField];
                }
                if (errors['plainPassword_first'] || errors['plainPassword_second']) {
                    inputs.plainPassword.properties.first.error = errors['plainPassword_first'] ?? errors['plainPassword_second']
                    inputs.plainPassword.properties.second.error = errors['plainPassword_first'] ?? errors['plainPassword_second']
                }
            }
        );

        setInputs({
            ...inputs
        })

        delete form.errors;
    }

    useEffect(() => {
        if (!Object.keys(inputs).length) {
            setLoader({
                'form-user': true
            })
            getForm(formUrl).then(data => {
                setInputs(handleFormData(data, false, undefined, undefined, false));
                setLoader({
                    'form-user': false
                })
            }).catch(error => {
                adminFetchHandler(error, {})
                setLoader({
                    'form-user': false
                })
            })
        }
    }, [setLoader, setInputs, adminFetchHandler, inputs, formUrl]);

    const handleChange = (key, value, isSecond = false) => {
        setIsEdited(true);
        if (key === 'plainPassword') {
            if (!isSecond) {
                inputs[key].properties.first.value = value
            } else {
                inputs[key].properties.second.value = value
            }
            inputs[key].value = {
                first: inputs[key].properties.first.value,
                second: inputs[key].properties.second.value
            }
            inputs[key].properties.first.error = null;
            inputs[key].properties.second.error = null;
        } else {
            inputs[key].value = inputs[key].type === 'boolean' ? !inputs[key].value : value
            inputs[key].originalValue = ''
        }


        inputs[key].error = null;

        setInputs({
            ...inputs
        })

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let dataFetch = {};

        Object.keys(inputs).forEach(key => {
            dataFetch[key] = inputs[key].value;
            if (Array.isArray(inputs[key].value)) {
                dataFetch[key] = inputs[key].value.map(item => item.value);
            }
        });

        let infoSubmit = {
            url: `/api/user-info${!disableCustomerIdFilter ? `?customerId=${customerId}` : ``}`,
            submittedData: dataFetch,
            loader: 'form-user',
            callback: () => {
                // Reset les lists
                resetStoreDatas();
                setRedirect(<Redirect to={`/administration/user_list/`}/>)
            }
        };
        setIsEdited(false);

        if (itemId) {
            dataFetch.id = itemId;
            infoSubmit = {
                ...infoSubmit,
                putUrl: `/api/user-info${!disableCustomerIdFilter ? `?customerId=${customerId}` : ``}`,
            }
        }

        postService(infoSubmit, t)

        return false;
    };


    return (
        <>
            {redirect}

            <form className={`form form__scrollable form__admin`} onSubmit={(event) => handleSubmit(event)}>
                <Prompt message={handleLeaving} when={isEdited}/>
                <div className={'row row-fluid'}>
                    {Object.keys(inputs).map((key, index) => {
                        let input = inputs[key]
                        let fieldClass = input && input.value ? 'field-written' : '';
                        fieldClass += (input && input.error)
                        || (input.properties && input.properties.first && input.properties.first.error)
                        || (input.properties && input.properties.second && input.properties.second.error)
                            ? ' field-error' : '';

                        if (input && input.attr && input.attr.type && input.attr.type === 'hidden') {
                            return <></>
                        }

                        if (input && input.attr && input.attr.calendar_params && !calendarUrl) {
                            setCalendarUrl(input.attr.calendar_params);
                        }

                        const toRender = [];

                        if (input.properties && input.properties.first && input.properties.second) {
                            input = input.properties.first;
                            input.keyItem = inputs[key].keyItem + '1';

                        }

                        toRender.push(<React.Fragment key={`to-${input.keyItem}`}>
                            <div
                                className={`col-12 col-md-${input.keyItem === 'signature' ? 12 : 3} field ${fieldClass}`}>
                                <Input t={t}
                                       inputData={input}
                                       onChange={(newValue) => handleChange(key, newValue)}
                                       typeForm={null}
                                       setPopup={setPopup}
                                />
                            </div>
                        </React.Fragment>)

                        if (inputs[key].properties && inputs[key].properties.first && inputs[key].properties.second) {
                            input = inputs[key].properties.second;
                            input.keyItem = inputs[key].keyItem + '2';
                            toRender.push(<React.Fragment key={`trr-${input.keyItem}`}>
                                <div className={`col-12 col-md-3 field ${fieldClass}`}>
                                    <Input t={t}
                                           inputData={input}
                                           onChange={(newValue) => handleChange(key, newValue, true)}
                                           typeForm={null}
                                    />
                                </div>
                            </React.Fragment>)
                        }


                        return (
                            <React.Fragment key={`fragment-${input.keyItem}`}>
                                {toRender}
                            </React.Fragment>
                        )
                    })}
                </div>

                <div className={'row align-items-center justify-content-end'}>
                    <div className={'col-12 col-md-auto form__back'} onClick={() =>
                        setRedirect(<Redirect to={`/administration/user_list/`}/>)
                    }>
                        <PolIcon icon={'chevron-circle-left'} className={'form__back__icon'}/>
                        {t('retour')}
                    </div>
                    <div className={`col-12 col-md-auto form__submit`}>
                        <button className={'btn btn-primary form__submit__btn'} onClick={() => handleSubmit}>
                            {t('enregistrer')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = ({form}) => ({
    form
});
const mapDispatchToProps = dispatch => {
    return {
        postService: (info, t) => dispatch(PostService(info, t)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setLoader: (value) => dispatch(setLoader(value)),
        setPopup: (info) => dispatch(setPopup(info)),
        resetStoreDatas: () => dispatch(resetStoreDatas())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(FormUser));

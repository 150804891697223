import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ProspectOpenTasksTabActions() {
    const {t} = usePolTranslation();

    return (
        <>
            <Link to={({pathname}) => `${pathname}/resume`} className="prospect-tab__action">
                <span>{t('tasks_summary')}</span>
            </Link>
            <Link to={({pathname}) => `${pathname}/add`} className="prospect-tab__action">
                <PolIcon icon="plus-circle"/>
                <span>{t('add_action')}</span>
            </Link>
        </>
    )
}

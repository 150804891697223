import UserService from "api/user/user";
import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect} from 'react';
import PolTableTdCustomerFiles
    from "v4/components/ui/PolTable/components/PolTableTd/components/PolTableTdCustomerFiles/PolTableTdCustomerFiles";
import {IMAGE, LIVEO_CALLS} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";
import {getFormattedValue} from "v4/utils";
import PolTableTdRowActions from "./components/PolTableTdRowActions/PolTableTdRowActions";

export default function PolTableTd({cellKey, value, type, fullname, rowActions, dataLabel, rowMetadata, cellMetadata}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification();
    const isLiveo = UserService.isLiveo();

    const [{isError}, fetchLiveo] = useFetch();

    const handleLiveoCall =
        (isLiveo && fullname && value)
            ? () => {
                fetchLiveo({
                    url: generateUrl(LIVEO_CALLS),
                    config: {
                        method: 'POST',
                        body: JSON.stringify({
                            contactName: fullname,
                            number: value,
                        })
                    }
                })
                addSuccessNotification(t('calling'));
            }
            : null;

    useEffect(() => {
        if (isError) {
            addErrorNotification(t('error_liveo'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError])

    switch (type) {
        case 'image':
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    {value
                        ? (
                            /* eslint-disable-next-line jsx-a11y/img-redundant-alt */
                            <img src={generateUrl(IMAGE, {id: value})}
                                 alt="Image non trouvée"
                                 width="100"
                                 height="100"/>
                        )
                        : (
                            <div>-</div>
                        )
                    }
                </td>
            )
        case 'TelType':
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    {
                        handleLiveoCall
                            ? (
                                <span className="liveo-format" onClick={handleLiveoCall}>
                                    <PolIcon icon="phone"/>
                                    {value}
                                </span>
                            )
                            : (
                                value
                                    ? <a href={`tel:${value}`} className="liveo-format">{value}</a>
                                    : '-'
                            )
                    }
                </td>
            )
        case 'money':
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    {value
                        ? Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'})?.format(value) /* TODO: n'afficher qu'en euro ou peut être évolutif ? */
                        : '-'
                    }
                </td>
            )
        case 'percent':
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    {
                        value
                            ? isNaN(value) ? value : `${value}%`
                            : '-'
                    }
                </td>
            )
        case 'email':
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    {
                        value
                            ? <a href={`mailto:${value}`}>{value}</a>
                            : '-'
                    }
                </td>
            )
        case 'date':
        case 'datetime':
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    {getFormattedValue(type, value, {keepTime: true})}
                </td>
            )
        case 'files':
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    <PolTableTdCustomerFiles files={value}/>
                </td>
            )
        case 'longtext':
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    <div>
                        {
                            value?.length > 0
                                ? value?.length > 100 ? value.substring(0, 100).trim() + '...' : value
                                : '-'
                        }
                    </div>
                </td>
            )
        case 'rowActions':
            return (
                <td data-type={type}>
                    <PolTableTdRowActions rowActions={rowActions} rowMetadata={rowMetadata}/>
                </td>
            )
        case 'icon': {
            const icon = cellMetadata?.icon ?? 'circle-notch';
            const iconColor = cellMetadata?.iconColor ?? 'grey';
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    <PolIcon icon={icon} color={iconColor}/>
                </td>
            )
        }
        case 'html':
            return (
                <td data-label={dataLabel}
                    data-type={type}>
                    <span dangerouslySetInnerHTML={{__html: value}}/>
                </td>
            )
        default:
            return <td data-label={dataLabel}
                       data-type={type}>
                {autoFormatValue(value)}
                {cellMetadata?.isUnknown && <span className="pol-table__badge">{t('unknown')}</span>}
            </td>
    }
}

function autoFormatValue(value) {
    if (Array.isArray(value)) {
        return value.join(', ');
    }

    return value ?? '-';
}

import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function AddConfigButton({onClickAdd}) {
    const {t} = usePolTranslation();

    return (
        <button className="btn btn-primary" onClick={onClickAdd}>
            {t('add')}
        </button>
    )
}

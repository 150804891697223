import React, {useEffect, useState} from 'react'
import {useRecoilState, useRecoilValue} from "recoil";
import {adminFetchHandler} from "../../../api/datas/datas";
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "../../PolIcon/policon";
import {customerIdSelector} from "../atoms/customer";
import {Input} from "../../Input/input";
import {datatableColsState} from "../atoms/importAdmin";
import {setPopup} from "../../../actions/content";
import {getAdmin} from "../../../api/admin/get";
import PopupInfo from "../../Popup/_popup_info";

const FormImport = ({t, setFieldsAvailable, formValues, setFormValues, setLoader, setStep, setPopup}) => {
    const customerId = useRecoilValue(customerIdSelector);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [inputs, setInputs] = useState([]);
    const [file, setFile] = useState({});
    let [, setDatatableCols] = useRecoilState(datatableColsState);

    useEffect(() => {
        if (customerId) {
            setLoader(true)
            getAdmin(`/api/data_import_model/form?customerId=${customerId}`, (error) => {
                adminFetchHandler(error, {})
                setLoader(false)
            }, (data) => {
                if (data && data.schema && data.schema['hydra:member'] && data.schema['hydra:member'].length) {
                    data.schema['hydra:member'][2].entityToImport.value = data.schema['hydra:member'][2].entityToImport.enum[0]
                    setInputs(data.schema['hydra:member'][2])
                    setFieldsAvailable(data.schema['hydra:member'][2].entityToImport.attr['data-available-fields'])
                    setFormValues({
                        ...formValues,
                        entityToImport: data.schema['hydra:member'][2].entityToImport.enum[0],
                        isDeleteCustomerData: "Non",
                        isSendMailAfterImport: "Non",
                    })
                }
                setLoader(false)
            })
        }
        // eslint-disable-next-line
    }, [customerId]);


    const handleChange = (key, value) => {
        if (key === 'customerFiles') {
            setFile(Array.isArray(value) ? value[0] : null);
            return;
        }

        inputs[key].value = value;

        if (key === 'entityToImport') {
            if (value === 'Contacts') {
                inputs['isDeleteCustomerData'] = {
                    ...inputs['isDeleteCustomerData'],
                    value: "0",
                    disabled: true
                };
            } else {
                inputs['isDeleteCustomerData'] = {
                    ...inputs['isDeleteCustomerData'],
                    disabled: false
                };
            }
        }

        setFormValues({
            ...formValues,
            [key]: value
        })

        setInputs({...inputs})
    }

    const handleSubmit = () => {
        if (file instanceof File) {
            setFormValues({
                ...formValues,
                csvFile: file
            })
            setDatatableCols([])
            setStep(2);
        } else {
            setPopup({
                isOpen: true,
                content: (
                    <PopupInfo onAccept={() => {
                        setPopup({})
                    }} message={t('must_pick_fill')}/>
                )
            })
        }
    }

    return (
        <form className={`form form__scrollable form__admin`} onSubmit={(event) => handleSubmit(event)}>
            <div className={'row row-fluid'}>
                {Object.keys(inputs).map(key => {
                    const input = inputs[key]
                    let fieldClass = input && input.value ? 'field-written' : '';
                    fieldClass += input && input.error ? ' field-error' : '';
                    return (
                        <React.Fragment key={input.fieldKey + key}>
                            {input.error ? (
                                <div className={'form__error'}
                                     onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                     onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                                >
                                    <PolIcon icon={'exclamation-circle'}
                                             className={'form__error__icon'}/>
                                    <p className={'form__error__content'}>{input.error}</p>
                                </div>
                            ) : null}
                            <>
                                <div className={`col-md-5 form__content__admin`}>
                                    <p className={'form__label__admin'}>{t(input.title, {entity: formValues.entityToImport})}</p>
                                    {key === 'customerFiles' ?
                                        <p className={'form__label__admin form__label__admin__info'}>{t('csv_format')}</p>
                                        : null}
                                </div>
                                <div
                                    className={`${input.title === 'import_form_delimeter-multiple' ? 'col-md-1' : 'col-md-7'} field ${fieldClass}`}>
                                    <Input inputData={input}
                                           onChange={(newValue) => {
                                               handleChange(key, newValue)
                                           }}
                                           typeForm={null}
                                           t={t}
                                           onFileTypeWrong={(val) => setAllowSubmit(val)}
                                    />
                                </div>
                                {input.title === 'import_form_delimeter-multiple' && <div className={'col-md-6'}/>}
                            </>
                        </React.Fragment>)
                })}
            </div>
            <div className={'row align-items-center justify-content-end'}>
                <div className={`col-12 col-md-auto form__submit`}>
                    <button className={`btn btn-primary form__submit__btn ${!allowSubmit ? 'form__submit__btn__disabled' : ''}`}
                            onClick={() => handleSubmit()}>
                        {t('import')}
                    </button>
                </div>
            </div>
        </form>

    )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
    return {
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setPopup: (info) => dispatch(setPopup(info))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(React.memo(FormImport)));

import React from 'react';
import {useFormContext} from "react-hook-form";
import Field from "v4/components/form/Field/Field";
import ShowField from "v4/components/form/ShowField/ShowField";
import useAttributesPopupRenderFields
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuotesProductsTableAttributesPopup/components/AttributesPopupRenderFields/hooks/useAttributesPopupRenderFields";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function AttributesPopupRenderFields({inputs, isShow}) {
    const {t} = usePolTranslation();

    const [inputsWithoutGroupCode, inputsWithGroupCode] = useAttributesPopupRenderFields(inputs);
    const {watch} = useFormContext();

    if (!inputs) {
        return <p className="quotes-products-table-attributes-popup__loading">{t('loading')}</p>
    }

    if (inputsWithoutGroupCode.length === 0 && inputsWithGroupCode.length === 0) {
        return <p className="quotes-products-table-attributes-popup__no-attribute">{t('no_attributes')}</p>
    }

    return (
        <>
            {
                inputsWithoutGroupCode.map(({id, name, label, type, attr}) => {
                    return (
                        <div className="quotes-products-table-attributes-popup__attribute" key={id}>

                            {isShow
                                ? <ShowField id={id} type={type} name={name} value={watch(name)} attr={attr}
                                             label={label.split('|')[0]}/>
                                : (
                                    <>
                                        <label
                                            className="quotes-products-table-attributes-popup__attribute-label"
                                            htmlFor={id}>{label.split('|')[0]}</label>
                                        <Field id={id} type={type} name={name} attr={attr}/>
                                    </>
                                )
                            }
                        </div>
                    )
                })
            }
            {
                inputsWithGroupCode.map(({groupCode, inputs: groupInputs}) => {
                    return (
                        <div key={groupCode} className="quotes-products-table-attributes-popup__group">
                            {!isHidden(groupCode) && (
                                <h3 className="quotes-products-table-attributes-popup__group-title">{groupCode.split('|')[0]}</h3>
                            )}
                            <div className="quotes-products-table-attributes-popup__group-attributes">
                                {
                                    groupInputs.map(({id, name, label, type, attr}) => {
                                        if (isHidden(label)) return null;

                                        return (
                                            <div key={id}
                                                 className="quotes-products-table-attributes-popup__group-attributes__field">
                                                {isShow
                                                    ? <ShowField id={id}
                                                                 type={type}
                                                                 name={name}
                                                                 attr={attr}
                                                                 value={watch(name)}
                                                                 label={label.split('|')[0]}/>
                                                    : (
                                                        <>
                                                            <Field id={id}
                                                                   type={type}
                                                                   name={name}
                                                                   attr={attr}
                                                                   placeholder={label.split('|')[0]}/>
                                                            {type === 'checkbox' && (
                                                                <label htmlFor={name}>{label.split('|')[0]}</label>
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

function isHidden(label) {
    const splittedLabel = label?.split('|') ?? [];
    if (splittedLabel.length) return splittedLabel.some(item => item.toLowerCase() === 'hidden');

    return false;
}

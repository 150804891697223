import React from 'react';
import {connect} from 'react-redux'
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {setPopup} from "../../actions/content";

const Popup = ({popup, togglePopup}) => {
    return (
        <>
            {popup.isOpen ? (
                <>
                    <div className={`popup__overlay`}>
                    </div>
                    {popup.content ? (
                        <div className={`popup__content ${popup.className ? popup.className : ''}`}>
                            {popup.content}
                        </div>
                    ) : null}
                </>
            ) : null}
        </>
    )
};


const mapStateToProps = ({content: {popup}}) => ({
    popup
});

const mapDispatchToProps = dispatch => {
    return {
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(Popup))

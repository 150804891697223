import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function NoCustomerIdModules() {
    const {t} = usePolTranslation();

    return (
        <section className="box">
            <div className={'box__header d-flex justify-content-between'}>
                <p className="box__header__text align-self-center">{t("manage_modules")}</p>
            </div>
            <div className="box__body">
                <p>{t('module_new_customer')}</p>
            </div>
        </section>
    )
}

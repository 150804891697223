export const setToken = (token, refresh_token, refresh) => {
    return {
        type: 'SET_TOKEN',
        token,
        refresh_token,
        refresh
    }
};

export const removeToken = () => {
    return {
        type: 'REMOVE_TOKEN'
    }
};

export const setUser = (user) => {
    return {
        type: 'SET_USER',
        user
    }
};

export const removeUser = () => {
    return {
        type: 'REMOVE_USER',
    }
};

export const logOut = () => {
    return {
        type: 'LOG_OUT',
    }
};

export const loadUser = (load) => {
    return {
        type: 'LOAD_USER',
        load: load
    }
};

export const badCredentials = (error) => {
    return {
        type: 'BAD_CREDENTIALS',
        error: error
    }
};

export const setLastVisits = (lastVisits) => {
    return {
        type: 'SET_LAST_VISITS',
        lastVisits: lastVisits
    }
};

export const deleteLastVisit = (prospectId, contactId) => {
    return {
        type: 'DELETE_LAST_VISITS',
        prospectId,
        contactId
    }
};

export const pushLastVisit = (lastVisit) => {
    return {
        type: 'PUSH_LAST_VISIT',
        lastVisit: lastVisit
    }
};
import React from 'react';
import {PolIcon} from "../../../PolIcon/policon";
import {onDoubleClickHandler, onSingleClickHandler} from "../../../../utils/clicHandler";

export const FolderGrid = ({
                               itemToEdit, folder: {id, folderName}, onChangeItem,
                               folderHierarchyLevel, renderByCurrentDirectory, isEditFolderName, editItemSpot,
                               resetEditFile, handleEditedFile, inputValue,
                               setFolderName, setInputValue, selfFolderName, handleClickOnEdit
                           }) => {
    return (
        <>
            <PolIcon
                onClick={(e) => {
                    const target = e.currentTarget;
                    onSingleClickHandler(() => {
                        const selectedItem = document.querySelector('.select');
                        if (selectedItem !== null) {
                            selectedItem.classList.remove('select');
                        }
                        target.classList.toggle('select');
                    })}
                }
                onDoubleClick={(e) => onDoubleClickHandler(() => {
                    if (renderByCurrentDirectory) {
                        folderHierarchyLevel++;
                    }
                    if (itemToEdit !== id) {
                        onChangeItem({
                            folderName: folderName,
                            id: id,
                            hierarchyLevel: folderHierarchyLevel
                        }, folderHierarchyLevel);
                    }
                })} icon={'folder'}/>
            {isEditFolderName && editItemSpot === 'container' ? (
                <>
                    <input onBlur={() => {
                        if (resetEditFile) {
                            resetEditFile()
                        }
                        handleEditedFile({
                            folderName: inputValue,
                            id: id
                        })
                        setFolderName(inputValue);

                    }} className={'ged-component__input-rename'} onKeyPress={(e) => {
                        if (e.charCode === 13) {
                            handleEditedFile({
                                folderName: inputValue,
                                id: id
                            })
                            setFolderName(inputValue);
                        }
                    }} onChange={(e) => {
                        setInputValue(e.target.value)
                    }} type={"text"} defaultValue={selfFolderName}/>
                </>
            ) : <p onDoubleClick={(e) => onDoubleClickHandler(() => {
                if (itemToEdit !== id) {
                    onChangeItem({
                        folderName: folderName,
                        id: id,
                        hierarchyLevel: folderHierarchyLevel
                    }, folderHierarchyLevel, null, 'single', 'container', true);
                } else {
                    handleClickOnEdit();
                }
            })} className={'ged-component__document-name'}>{selfFolderName}</p>}
        </>
    )
}
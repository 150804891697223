import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {financial} from "v4/utils";
import useObjectToGETUrl from "v4/hooks/useObjectToGETUrl";

export default function DashboardQuotesSummaryCell({value, preSearch, isFinancial = false, ...props}) {
    const {pathname, search} = useObjectToGETUrl(routesBase.baseQuote, preSearch);

    if (!value || parseFloat(value) <= 0) {
        return <td {...props}>-</td>
    }

    if (preSearch) {
        return (
            <td {...props}>
                <Link to={{pathname, search}}>
                    {isFinancial ? financial(value) : value}
                </Link>
            </td>
        )
    }

    return <td {...props}>{isFinancial ? financial(value) : value}</td>
}

import React from "react";
import FormImport from "../../../../components/Admin/_partials/form_import";
import ImportFieldsAvailable from "../../../../components/Admin/_partials/import_fields_available";
import {withPolTranslation} from "v4/hooks/usePolTranslation";

const ImportStepOne = ({t, fieldsAvailable, setFieldsAvailable, formValues, setFormValues, setLoader, setStep}) => {

    return (
        <>
            <div className={'col-8'}>
                <section className={'box'}>
                    <div className={'box__header'}>
                        <p className={'box__header__text'}>{t('manage_import')}</p>
                    </div>
                    <div className={'box__body'}>
                        <FormImport setLoader={setLoader} formValues={formValues} setFormValues={setFormValues}
                                    setFieldsAvailable={setFieldsAvailable} setStep={setStep}/>
                    </div>
                </section>
            </div>
            <div className={'col-4'}>
                <section className={'box'}>
                    <div className={'box__header'}>
                        <p className={'box__header__text'}>{t('import_fields_available')}</p>
                    </div>
                    <div className={'box__body'}>
                        <ImportFieldsAvailable fieldsAvailable={fieldsAvailable} formValues={formValues}/>
                    </div>
                </section>
                {/* TODO BLOC EXEMPLE
                <section className={'box'}>
                    <div className={'box__header'}>
                        <p className={'box__header__text'}>{t('import_example_csv')}</p>
                    </div>
                    <div className={'box__body'}>
                        <ImportCsvExample/>
                    </div>
                </section>*/}
            </div>
        </>
    )
}

export default (withPolTranslation(React.memo(ImportStepOne)));

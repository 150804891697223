import React from 'react';
import ConfiguredActions from "v4/features/front/configuredActions/components/ConfiguredActions/ConfiguredActions";
import './ProspectTabShowActions.scss';

export default function ProspectTabShowActions({showId, showActions, prospectId, popupTitle}) {
    return (
        <div className="show-actions">
            <ConfiguredActions actions={showActions}
                               classNamePrefix="show-actions"
                               btnClassName="prospect-tab__prospect__header__action"
                               entityId={showId}
                               popupTitle={popupTitle}
                               prospectId={prospectId}/>
        </div>
    )
}

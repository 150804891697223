import {setFilterSearch} from "actions/form";
import React, {useEffect} from 'react';
import {connect, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {usePolTranslation, withPolTranslation} from "v4/hooks/usePolTranslation";
import Datatables from "../Datatables/datatables";
import ErrorBoundary from '../Error/errorBoundary'

/**
 * List of object => datatable
 * @param info
 * @param {string} [className]
 * @param {string} [title]
 * @param {Object} [customTabSearch={}]
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const List = ({info = {}, className = '', title = '', customTabSearch = {}}) => {
    const {t} = usePolTranslation();
    const {location: {pathname, state}, replace} = useHistory();
    const dispatch = useDispatch()

    useEffect(() => {
        if (state?.hasToRefresh) {
            const {hasToRefresh, ...restState} = state;
            dispatch(setFilterSearch({})); // refresh listing (redux)
            replace({pathname, state: restState});
        }
    }, [state, pathname, replace, dispatch]);

    return (
        <div className={'page page-list main-content__body__slide'}>
            <div className={'page-list__content'}>
                <ErrorBoundary>
                    <Datatables
                        title={t(title)}
                        className={className}
                        info={info}
                        customTabSearch={customTabSearch}
                    />
                </ErrorBoundary>
            </div>
        </div>
    )
};

const mapStateToProps = ({content}) => ({
    content
});

export default connect(
    mapStateToProps,
    null
)(withPolTranslation(React.memo(List)));

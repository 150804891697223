import React, {useEffect, useState} from 'react';
import getDatetimeFormat from "v4/utils/getDatetimeFormat";
import {adminFetchHandler, manageError} from '../../api/datas/datas';
import {connect} from 'react-redux';
import {setLoader, setPopup} from '../../actions/content';
import {DeleteService} from '../../api/datas/delete';
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import moment from 'moment';
import {Redirect} from 'react-router-dom';
import {baseProspect} from '../../routes';
import {getAdmin} from '../../api/admin/get';
import BgAll from '../../img/bg_all.svg';
import BgInLate from '../../img/bg_in_late.svg';
import BgOk from '../../img/bg_ok.svg';
import {PolIcon} from '../../components/PolIcon/policon';
import ReactTooltip from "react-tooltip";

export const SummaryTasks = (props) => {
    const t = props.t;

    const [showOnGoing, setOngoing] = useState(true);
    const [showLate, setLate] = useState(true);
    const [showClosed, setClosed] = useState(true);

    const [tasks, setTasks] = useState([]);
    const [task, setTask] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token && props && props.location && props.location.state && props.location.state.prospectId) {
            getAdmin(props.info.listAllTasks.replace('{id}', props.location.state.prospectId),
            (error) => {
                adminFetchHandler(error, {})
                setLoader(false)
            }, (data) => {
                if (data) {
                    const now = moment(new Date());

                    data = data['hydra:member'].filter((task) => {
                        const isTaskClosed = task.isClosed;
                        const isTaskLate = moment(task.beginAt) < now

                        if (showLate && !isTaskClosed && isTaskLate) {
                            // Ajout de la propriété "inLate" pour l'affichage
                            task.inLate = true;
                            return true;
                        }

                        if (showClosed && isTaskClosed) {
                            return true;
                        }

                        if (showOnGoing && !isTaskClosed && !isTaskLate) {
                            return true;
                        }

                        return false;
                    })

                    data.sort((a, b) => {
                        return moment(b.beginAt) - moment(a.beginAt)
                    })

                    setTasks(data);
                }
            })
        }
    }, [
        showLate,
        showOnGoing,
        showClosed,
        props,
    ]);

    return (
        <>
            <div className={'summaryTask__header d-flex row flex-row justify-content-between align-items-center'}>
                <div className={'col-6'}>
                    <h2>{t('tasks_summary')}</h2>
                </div>
                <div className={'col-6 d-flex justify-content-end'}>
                    <button
                        className={`summaryTask__header__button summaryTask__header__button--onGoing ${showOnGoing && 'summaryTask__header__button--active'}`}
                        onClick={() => setOngoing(!showOnGoing)}
                        data-tip={t('tasks_summary_toggle_current')}
                    >
                        <PolIcon icon={'clock'}/>
                    </button>
                    <button className={`summaryTask__header__button summaryTask__header__button--inLate ${showLate &&'summaryTask__header__button--active'}`}
                            onClick={() => setLate(!showLate)}
                            data-tip={t('tasks_summary_toggle_late')}
                    >
                        <PolIcon icon={'clock'}/>
                    </button>
                    <button
                        className={`summaryTask__header__button summaryTask__header__button--isClosed ${showClosed && 'summaryTask__header__button--active'}`}
                        onClick={() => setClosed(!showClosed)}
                        data-tip={t('tasks_summary_toggle_done')}
                    >
                        <PolIcon icon={'clock'}/>
                    </button>
                </div>
            </div>
            <div className={`summaryTask__container ${tasks.length < 1 && 'summaryTask__container--empty'} position-relative`}>
                <div>
                    {task !== null && <Redirect to={{pathname: `${baseProspect}/${task.prospect.id}/actions/${task.id}`}} />}
                    {tasks.length > 0 ?
                        <>
                            <div className={'summaryTask__timeline--first'}>
                                <div />
                            </div>
                                {tasks.map((task, key) => {
                                    let lastIndexTasks = false;

                                    if (tasks.length === key + 1) {
                                        lastIndexTasks = true;
                                    }

                                    return (
                                        <div className={`summaryTask__timeline`} key={task.id}>
                                            <div className={`summaryTask__timeline__state ${task.isClosed ? 'summaryTask__timeline__state--finish' : task.inLate ? 'summaryTask__timeline__state--late' : 'summaryTask__timeline__state--pending'}`}>
                                                <PolIcon icon={'clock'}/>
                                            </div>
                                            <div className={`summaryTask__timeline__line ${lastIndexTasks ? 'summaryTask__timeline__line--last' : ''}`}/>
                                            <div className={`summaryTask__timeline__content ${task.isClosed ? 'summaryTask__timeline__content--left' : task.inLate ? 'summaryTask__timeline__content--right' : 'summaryTask__timeline__content--left'}`}>
                                                <div className={'summaryTask__timeline__content__title'} onClick={() => setTask(task)}>
                                                    {(task.taskType && task.taskType.name) ? task.taskType.name : ''}
                                                    <PolIcon className={'ml-2'} icon={'eye'} data-tip={t('see_action')} data-delay-show='1500'/>
                                                    <ReactTooltip effect="solid"/>
                                                </div>
                                                <div className={'summaryTask__timeline__content__info'}>
                                                    <div className={'summaryTask__timeline__content__info--title'}>
                                                        <div>
                                                            <span>{task.name ? task.name + ' ' : ''}</span>{task.contactsFormatted && <>{t('tasks_summary_contacts_to')} <span>{task.contactsFormatted}</span></>}
                                                        </div>
                                                        <div>{task.managedByRealName ? task.managedByRealName : ''}</div>
                                                    </div>
                                                    <div className={'summaryTask__timeline__content__info--text'}>
                                                        {task.report ?
                                                            <>
                                                                <div className={'summaryTask__timeline__content__info--report'}>
                                                                    <div>{t('compte_rendu')}</div>
                                                                    <div dangerouslySetInnerHTML={{__html: task.report}}/>
                                                                </div>
                                                            </>
                                                            : null
                                                        }
                                                        {task.memo ?
                                                            <>
                                                                <div className={'summaryTask__timeline__content__info--memo'}>
                                                                    <div>{t('memo')}</div>
                                                                    <div dangerouslySetInnerHTML={{__html: task.memo}}/>
                                                                </div>
                                                            </>
                                                            : null
                                                        }
                                                        {!task.report && !task.memo ?
                                                            <>
                                                                <div className={'summaryTask__timeline__content__info--none'}>
                                                                    <div>{t('no_additional_information')}</div>
                                                                </div>
                                                            </>
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`summaryTask__timeline__content--date ${task.isClosed ? 'summaryTask__timeline__content--date--right' : task.inLate ? 'summaryTask__timeline__content--date--left' : 'summaryTask__timeline__content--date--right'}`}>
                                                <div>
                                                    {task.beginAt ? moment(task.beginAt).format(getDatetimeFormat()) : ''}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </>
                        :
                        <div>
                            {!showOnGoing && !showClosed && !showLate ? t('select_action_type') : t('summary_no_actions')}
                        </div>
                    }
                </div>
                {
                    (!showOnGoing && !showClosed && showLate && <img className={'summaryTask__img summaryTask__img'} alt={t('tasks_summary_late')} src={BgInLate}/>)
                    || ((showOnGoing || showClosed) && !showLate && <img className={'summaryTask__img summaryTask__img--finish'} alt={t('tasks_summary_done')} src={BgOk}/>)
                    || <img className={'summaryTask__img summaryTask__img--all'} alt={t('tasks_summary')} src={BgAll}/>
                }
            </div>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        manageError: (code, error) => dispatch(manageError(code, error)),
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
        deleteService: (info) => dispatch(DeleteService(info)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setLoader: (value) => dispatch(setLoader(value)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(React.memo(SummaryTasks)));

import UserService from "api/user/user";

export default function useAccessVoter() {

    const isGranted = (accessKey) => {
        const accessRight = UserService.getAccessRight()
        // If accessKey take first _ character, it seems the rule is not available for user, return Granted false
        return (accessRight.includes('_'+accessKey)) ? false : (accessRight.includes(accessKey))
    }

    const isRestricted = (accessKey) => {
        return !isGranted(accessKey)
    }

    return ({isGranted, isRestricted})
}

import React, {useMemo} from 'react';
import {MAIL_TYPE_ITEM, MAIL_TYPE_ITEM_FORM} from "v4/data/apiRoutes";
import {routesBase} from "v4/data/appRoutes";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import FullPageFormLayout from "v4/layouts/FullPageFormLayout/FullPageFormLayout";

export default function MailsTypesEdit({mailTypeId}) {
    const fetchFormParameters = useMemo(() => ({
        id: mailTypeId
    }), [mailTypeId]);

    return (
        <FullPageFormLayout title="edit_mailing_type">
            <FormEntityLayout formRouteName={MAIL_TYPE_ITEM_FORM}
                              submitRouteName={MAIL_TYPE_ITEM}
                              fetchFormParameters={fetchFormParameters}
                              whenSubmitted={whenSubmitted}/>
        </FullPageFormLayout>
    )
}

function whenSubmitted(history) {
    history.replace(`${history.location.pathname.split(routesBase.baseMailsTypes).shift()}${routesBase.baseMailsTypes}`, {hasToRefresh: true});
}

import React, {useEffect, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, Redirect} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import {
    SAVED_REQUEST, SAVED_REQUEST_EDIT_FORM, SAVED_REQUEST_FORM,
    SAVED_REQUEST_ITEM
} from "v4/data/apiRoutes";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";

export default function SavedRequestsForm({customerId, id}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification();

    const [{data: savedRequestFormData, isLoading: isLoadingForm, isFinished: isFinishedForm}, fetchSavedRequestForm] = useFetch();
    const [{data: savedRequestDataSave, isError: isErrorSave, isLoading: isLoadingSave, isFinished: isFinishedSave}, fetchSavedRequestSave] = useFetch();

    const [savedRequestForm, setSavedRequestForm] = useState(null);
    const [savedRequest, setSavedRequest] = useState({
        entity: null,
        type: null,
        name: null,
        content: null,
        customerId: customerId,
        tabId: null,
    });
    const isEdit = (!!id);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        fetchSavedRequestForm({
            url: id
            ? apiService.generateUrl(SAVED_REQUEST_EDIT_FORM, {id: id, customerId: customerId})
            : apiService.generateUrl(SAVED_REQUEST_FORM, {customerId: customerId}),
        })
    }, [fetchSavedRequestForm, id, customerId, isEdit])

    useEffect(() => {
        if (savedRequestFormData && isFinishedForm) {
            const form = savedRequestFormData?.schema?.['hydra:member']?.[2];
            setSavedRequestForm(form);

            if (form) {
                setSavedRequest({
                    name: form?.name?.value,
                    content: form?.content?.value,
                    type: form?.type?.value,
                    entity: form?.entity?.value,
                    customerId: form?.customerId?.value,
                    tabId: form?.tabId.value
                });
            }
        }
    }, [isEdit, setSavedRequestForm, savedRequestFormData, isFinishedForm])

    const handleChange = (input) => {
        setSavedRequest({...savedRequest, [input.target.name]: input.target.value})
    }

    const handleSave = (e) => {
        e.preventDefault()
        fetchSavedRequestSave({
            url: isEdit
            ? apiService.generateUrl(SAVED_REQUEST_ITEM, {id: id})
            : apiService.generateUrl(SAVED_REQUEST),
            config: {
                method: 'POST',
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(savedRequest)
            }
        })
    }

    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('saved_request_save_error'))
        }

        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('saved_request_save_done'))
            setRedirect(<Redirect to={routesBase.adminSavedRequest}/>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedRequestDataSave, isErrorSave, isFinishedSave])

    const CardHeaderTools = () => (
        <>
            <Link to={routesBase.adminSavedRequest} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )

    return (
        <AdminCard title={t('manage_saved_requests')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoadingForm || isLoadingSave || !savedRequestForm)
                    ? <Loader message="Chargement..."/>
                    : <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                    <div className="row row-fluid mt-4">
                        <div className={'col-6 '}>
                            <div className={'col-12 field ' + (savedRequest?.name ? 'field-written' : null)}>
                                <input type="text" name="name" id="saved_request_name" className="field__input"
                                       defaultValue={savedRequest?.name} onChange={(input) => handleChange(input)}/>
                                <label className="field__label" htmlFor="saved_request_name">{t('saved_request_name')}</label>
                            </div>
                        </div>
                        <div className={'col-6 '}>
                            <div className={'col-12 field ' + (savedRequest?.content ? 'field-written' : null)}>
                        <textarea name="content" id="saved_request_content" className="field__textarea" rows={16}
                                  defaultValue={savedRequest?.content} onChange={(input) => handleChange(input)}></textarea>
                                <label className="field__label" htmlFor="saved_request_content">{t('saved_request_content')}</label>
                            </div>
                        </div>

                        <div className={'col-6 '}>
                            <div className={'col-12 field ' + (savedRequest?.entity ? 'field-written' : null)}>
                                <select name="entity" id="saved_request_entity" className="field__input" required={true}
                                        defaultValue={savedRequestForm?.entity?.value}
                                        onChange={(input) => handleChange(input)}>
                                    <option key={'entity_option'} value={null} label={t('empty')}></option>
                                    {savedRequestForm?.entity?.enum?.map((entity, entityKey) => {
                                        return (
                                            <option key={entityKey} value={entity} label={entity}></option>
                                        )
                                    })}
                                </select>
                                <label className="field__label" htmlFor="saved_request_entity">{t('saved_request_entity')}</label>
                            </div>
                        </div>

                        <div className={'col-6 '}>
                            <div className={'col-12 field ' + (savedRequest?.type ? 'field-written' : null)}>
                                <select name="type" id="saved_request_type" className="field__input" required={true}
                                        defaultValue={savedRequestForm?.type?.value}
                                        onChange={(input) => handleChange(input)}>
                                    <option key={'type_option'} value={null} label={t('empty')}></option>
                                    {savedRequestForm?.type?.enum?.map((type, typeKey) => {
                                        return (
                                            <option key={typeKey} value={type} label={type}></option>
                                        )
                                    })}
                                </select>
                                <label className="field__label" htmlFor="saved_request_type">{t('saved_request_type')}</label>
                            </div>
                        </div>

                        <div className={'col-6 '}>
                            <div className={'col-12 field ' + (savedRequest?.tabId ? 'field-written' : null)}>
                                <select name="tabId" id="saved_request_tab_id" className="field__input" required={true}
                                        defaultValue={savedRequestForm?.tabId?.value}
                                        onChange={(input) => handleChange(input)}>
                                    <option key={'entity_option'} value={null} label={t('empty')}></option>
                                    {savedRequestForm?.tabId?.enum?.map((tabId, tabIdKey) => {
                                        return (
                                            <option key={tabIdKey} value={tabId} label={savedRequestForm?.tabId?.enum_titles?.[tabIdKey]}></option>
                                        )
                                    })}
                                </select>
                                <label className="field__label" htmlFor="saved_request_tab_id">{t('saved_request_tab_id')}</label>
                            </div>
                        </div>
                    </div>
                </form>
            }
            {redirect}
        </AdminCard>
    )
}

import React from "react";
import Logo from "../../img/logo";

const ProgressBar = (props) => {
    const { bgcolor, completed, timeRemaining, infoText} = props;

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',
        transition: 'width 1s ease-in-out',
        textAlign: 'right'
    }

    return (
        <>
            <span className={'loader admin-loader'}>
                <Logo/>
                <p className={'loader-message'}>{infoText}</p>
                <div className={'progress-bar__container-style'}>
                    <div style={fillerStyles}>
                        <span className={'progress-bar__label'}>{`${completed}%`}</span>
                    </div>
                </div>
                <p className={'loader-message'}>Temps restant : {timeRemaining}</p>
            </span>
        </>
    );
};

export default ProgressBar;

import React, {useState} from 'react'
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {Prompt, Redirect} from "react-router-dom";
import {PolIcon} from "../../PolIcon/policon";
import {adminFetchHandler, fetchHandler, manageError} from "../../../api/datas/datas";
import {Input} from "../../Input/input";
import {PostService} from "../../../api/datas/post";
import {setLoader} from "../../../actions/content";
import {useRecoilValue} from "recoil";
import {customerIdSelector} from "../atoms/customer";

const dependField = {
    multiple: {
        field: 'fieldType',
        values: ['checkbox_type', 'choice_type']
    },
    expanded: {
        field: 'fieldType',
        values: ['checkbox_type'],
        hidden: ['multiple'],
        required: ['listing']
    },
    listing: {
        field: 'fieldType',
        values: ['checkbox_type', 'choice_type']
    }
};

const FormField = ({idItem = null, t, postService, setLoader, form}) => {
    const [inputs, setInputs] = useState({});
    let [fieldErrors, setFieldErrors] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const [mounted, setMounted] = useState(false);
    const customerId = useRecoilValue(customerIdSelector)
    const [isEdited, setIsEdited] = useState(false);

    const handleLeaving = () => {
        setIsEdited(false);
        return t("customer_confirm_leave_form_alert");
    }

    const handleChange = (key, value, oldValue) => {
        setIsEdited(true);

        if (inputs[key].type === 'boolean') {
            inputs[key].value = !inputs[key].value;
        } else if (value !== oldValue) {
            inputs[key].value = value && typeof value === 'object' ? value.value : value
        }
        if (inputs['expanded'].value) {
            inputs['multiple'].value = false;
            inputs['multiple'].isHidden = true;
        } else {
            inputs['multiple'].isHidden = false;
        }
        inputs[key].error = null;
        setInputs({
            ...inputs
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let dataFetch = {};

        Object.keys(inputs).forEach(key => {
            dataFetch[key] = inputs[key].value;
        });

        fieldErrors = [];

        Object.keys(dependField).forEach(key => {
            const field = dependField[key]
            if (Array.isArray(field.required) && dataFetch[key]) {
                field.required.forEach((item) => {
                    if (!dataFetch[item] || !dataFetch[item].length) {
                        fieldErrors.push(item)
                        alert(t('field_listing_radio_must_be_fill', {item: item}))
                    }
                })
            }
        })

        if (fieldErrors.length) {
            return;
        }
        setIsEdited(false);
        setFieldErrors([...fieldErrors])

        let infoSubmit = {
            url: `/api/field`,
            submittedData: dataFetch,
            loader: 'form-field',
            callback: () => {
                setRedirect(<Redirect to={`/administration/gestion_champs/`}/>)
            }
        };

        if (idItem) {
            dataFetch.id = idItem;

            infoSubmit = {
                ...infoSubmit,
                putUrl: `/api/field`,
            }
        } else {
            dataFetch.customerId = customerId
        }

        postService(infoSubmit, t)

        return false;
    };

    const getForm = () => {
        const prefixUrl = idItem ? `/${idItem}` : '';
        setLoader({
            'form-field': true
        })
        fetch(process.env.REACT_APP_HOST_API + `/api/field/form${prefixUrl}`, {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + localStorage.getItem('token')
            },
        }).then(
            fetchHandler,
            error => {
                setLoader({
                    'form-field': false
                })
                adminFetchHandler(error, {})
            }
        ).then(
            data => {
                if (data && data.schema['hydra:member'] && data.schema['hydra:member'].length) {
                    setInputs(data.schema['hydra:member'][2])
                } else {
                    manageError(500, null)
                }
                setLoader({
                    'form-field': false
                })
            },
            error => {
                setLoader({
                    'form-field': false
                })
                adminFetchHandler(error, {})
            }
        );
    };

    if (form && form.errors) {
        const errors = form.errors;
        Object.keys(inputs).forEach(key => {
                const errorField = Object.keys(errors).find((error) => error === inputs[key].keyItem);
                if (errorField) {
                    inputs[key].error = errors[errorField];
                }
            }
        );

        delete form.errors

        setInputs(inputs)
    }

    if (!inputs.length && !mounted) {
        getForm();
        setMounted(true)
    }

    return (
        <>
            {redirect}

            <form className={`form form__scrollable form__admin`} onSubmit={(event) => handleSubmit(event)}>
                <Prompt message={handleLeaving} when={isEdited}/>
                <div className={'row row-fluid'}>
                    {Object.keys(inputs).map((inputKey, key) => {
                        let input = inputs[inputKey];
                        const value = input.value ? input.value : '';
                        const isDependant = dependField[inputKey];
                        let fieldClass = input && input.value ? 'field-written' : '';
                        fieldClass += input && input.error ? ' field-error' : '';
                        input.keyItem = inputKey;
                        let isShown = !input.isHidden;

                        if (isDependant && isDependant.hidden && Array.isArray(isDependant.hidden)) {
                            isDependant.hidden.forEach((item) => {
                                if (inputs[item].value) {
                                    isShown = false;
                                }
                            })
                        }

                        if (
                            (isDependant
                                && !isDependant.values.includes(inputs[isDependant.field].value)) || !isShown
                        ) {
                            return null;
                        }

                        return (
                            <React.Fragment key={key}>
                                {input.error ? (
                                    <div className={'form__error'}
                                         onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                         onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                                    >
                                        <PolIcon icon={'exclamation-circle'}
                                                 className={'form__error__icon'}/>
                                        <p className={'form__error__content'}>{input.error}</p>
                                    </div>
                                ) : null}
                                <div className={`col-12 col-md-3 field ${fieldClass}`} key={key}>
                                    <Input inputData={input}
                                           t={t}
                                           onChange={(newValue) => handleChange(inputKey, newValue, value)}
                                           typeForm={null}/>
                                </div>
                            </React.Fragment>)
                    })}
                </div>

                <div className={'row align-items-center justify-content-end'}>
                    <div className={'col-12 col-md-auto form__back'}
                         onClick={() => setRedirect(<Redirect to={`/administration/gestion_champs/`}/>)}>
                        <PolIcon icon={'chevron-circle-left'} className={'form__back__icon'}/>
                        {t('retour')}
                    </div>
                    <div className={`col-12 col-md-auto form__submit`}>
                        <button className={'btn btn-primary form__submit__btn'} onClick={() => handleSubmit}>
                            {t('enregistrer')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = ({form}) => ({
    form
});
const mapDispatchToProps = dispatch => {
    return {
        postService: (info, t) => dispatch(PostService(info, t)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setLoader: (value) => dispatch(setLoader(value)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(React.memo(FormField)));

export const setDataForSelect = (data, prospectId, entityId) => {
    return {
        type: 'SET_DATA_FOR_SELECT',
        data,
        prospectId,
        entityId
    }
};

export const resetDatatableAfterDuplicate = () => {
    return {
        type: 'RESET_AFTER_DUPLICATE',
    }
};
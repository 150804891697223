import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {randomId} from "v4/utils";
import {useHistory} from "react-router-dom";
import {
    applyEffects
} from "v4/features/front/configuredActions/components/ConfiguredActionButton/hooks/useConfiguredActionButton";

export default function useQuoteStateForm(props, entityId, componentInfos) {
    const {quoteStateId, actionUrl, toggleOpen} = props;
    const {
        api_fetch_route_identifier: apiFetchRouteIdentifier,
        api_send_route_identifier: apiSendRouteIdentifier,
    } = componentInfos;
    const [{
        data: actionData,
        isLoading: isFetchActionLoading,
        isFinished: isFetchActionFinished
    }, fetchAction] = useFetch();
    const [{data, isLoading: isFetchLoading, isError: isFetchError}, fetchForm] = useFetch();
    const [{isError, isFinished, isLoading: isSubmitLoading}, fetchSubmitForm] = useFetch();
    const [inputsGroups, setInputsGroups] = useState({});
    const formMethods = useForm({mode: 'onChange'});

    useEffect(() => {
        fetchForm({
            url: generateUrl(apiFetchRouteIdentifier, {id: entityId, quoteStateId}),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, fetchForm, quoteStateId]);

    useEffect(() => {
        const hydraMember = data?.['hydra:member'];
        if (hydraMember) {
            const {results} = hydraMember ?? {};
            const inputs = Object.entries(results).map(([key, value]) => {
                const input = {key, ...value};
                if (key === 'reason') {
                    input.specificParams = {
                        menuPosition: 'fixed',
                    }
                }

                return input;
            });
            const inputsGroups = {
                results: {
                    [randomId()]: {
                        properties: inputs
                    }
                }
            };
            setInputsGroups(inputsGroups);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const handleSubmit = formMethods.handleSubmit((data) => {
        fetchSubmitForm({
            url: generateUrl(apiSendRouteIdentifier, {id: entityId}),
            config: {
                method: 'POST',
                body: JSON.stringify({...data, status: quoteStateId}),
            }
        });
    });

    const {replace, push} = useHistory();

    useEffect(() => {
        if (isFinished && !isError) {
            fetchAction({
                url: actionUrl,
                config: {
                    method: 'POST',
                    body: JSON.stringify({id: entityId})
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished, isError, actionUrl, entityId]);

    useEffect(() => {
        if (isFetchActionFinished && actionData?.effects) {
            toggleOpen();
            applyEffects(actionData.effects, {redirectFunc: push});
            if (actionData.effects.some(({redirectTo, needsRefreshList}) => redirectTo || needsRefreshList)) return;

            replace({state: {hasToRefresh: true}});
        }
    }, [replace, toggleOpen, isFetchActionFinished, actionData, push]);

    const isLoading = isFetchLoading || Object.keys(inputsGroups).length === 0;
    const submitDisabled = !formMethods.formState.isValid;

    return {
        formMethods,
        state: {
            isLoading,
            isSubmitLoading: (isFetchActionLoading || isSubmitLoading),
            submitDisabled,
            isFetchError
        },
        inputsGroups,
        handleSubmit
    }
}

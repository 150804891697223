import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function InputCheckboxes({onChange, fieldName, fieldValue, choices, htmlConstraints, prefix}) {
    const {t} = usePolTranslation();

    const handleInputChange = ({target: {value: targetValue, checked: isChecked}}) => {
        if (isChecked) {
            onChange([...fieldValue, targetValue]);
        } else {
            onChange(fieldValue.filter(v => v !== targetValue));
        }
    };

    return (
        <div className="input-field-checkboxes-container" data-is-required={!!htmlConstraints?.required}>
            {choices.map(({label, value}, index) => {
                const id = prefix + fieldName + index;

                return (
                    <div key={id} className="input-field-checkbox">
                        <input type="checkbox"
                               id={id}
                               name={fieldName}
                               value={value}
                               onChange={handleInputChange}
                               checked={fieldValue.includes(value)}
                               {...htmlConstraints}
                               required={false}/>
                        <label htmlFor={id}>{t(label)}</label>
                    </div>
                )
            })}
        </div>
    )
}

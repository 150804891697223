import {useCallback, useEffect} from 'react';
import {INTRANET_EXPORT} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

export default function useIntranetExport(quoteId, isDry, finishedCallback) {
    const [{data, isLoading, isFinished, isError}, fetchBusinessNumber] = useFetch();

    const handleExport = useCallback(() => {
        fetchBusinessNumber({
            url: generateUrl(INTRANET_EXPORT, {id: quoteId}),
            config: {
                method: 'PUT',
                body: JSON.stringify(isDry ? {dry: true} : {}),
            }
        });
    }, [fetchBusinessNumber, quoteId, isDry]);

    useEffect(() => {
        if (isDry) {
            handleExport();
        }
    }, [handleExport, isDry]);

    useEffect(() => {
        if (isFinished && !isError) {
            finishedCallback(isDry ? true : data);

            if (isDry) {
                return () => finishedCallback(false);
            }
        }
    }, [isFinished, isError, finishedCallback, data, isDry]);

    if (isDry) {
        return [isLoading, data?.contractNumber];
    }

    return [isLoading, handleExport];
}

const parsedLocalStorageCache = {};

export function setLSItem(key, data) {
    updateCache(key, data);

    if (typeof data === 'string') {
        localStorage.setItem(key, data);
        return;
    }

    return localStorage.setItem(key, JSON.stringify(data));
}

/**
 *
 * @param {string} key - key of the item in localStorage
 * @param {('string'|'json')} type - type of the desired output
 * @returns {string|Object} - the item in localStorage
 */
export function getLSItem(key, type = 'json') {
    if (parsedLocalStorageCache[key] && type === 'json') {
        return window.structuredClone(parsedLocalStorageCache[key]);
    }

    const data = localStorage.getItem(key);

    if (data) {
        switch (type) {
            case 'json':
                try {
                    const parsedData = JSON.parse(data);
                    parsedLocalStorageCache[key] = parsedData;
                    return parsedData;
                } catch (e) {
                    console.warn(`Error parsing localStorage item ${key}, did you want a string instead ?`, e);
                    return data;
                }
            case 'string':
            default:
                return data;
        }
    }

    return type === 'json' ? {} : null;
}

export function removeLSItem(key) {
    removeKeyCache(key);
    localStorage.removeItem(key);
}

export function clearLSCache() {
    Object.keys(parsedLocalStorageCache).forEach((key) => {
        delete parsedLocalStorageCache[key];
    });
}

function updateCache(key, data) {
    if (!parsedLocalStorageCache[key]) return;

    parsedLocalStorageCache[key] = data;
}

function removeKeyCache(key) {
    delete parsedLocalStorageCache[key];
}

import {useEffect, useRef} from "react";

// The purpose of this hook is to set infos on a DOM element. Don't forget to use the returned ref in the component
export default function useSetDOMInfos(infos, key = 'voInfos') {
    const nodeRef = useRef(null);
    useEffect(() => {
        if (!nodeRef.current || !infos) return;

        nodeRef.current[key] = infos;
    }, [infos, key]);

    return nodeRef;
}

import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import QuoteState from "v4/features/admin/adminForms/components/QuoteState/QuoteState";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import GetCustomerId from "views/Administration/pages/GetCustomerId";
import {adminFetchHandler, fetchHandler, manageError, sortFunc} from "../../../api/datas/datas";
import {postForm} from "../../../api/form/form";
import UserService from "../../../api/user/user";
import AdminDatatables from "../../../components/Admin/_partials/datatables";
import Form from '../../../components/Admin/_partials/form_listing'
import FormListingAdmin from "../../../components/Admin/_partials/form_listing_admin";
import {arrowSort} from "../../../components/Datatables/_partials/config";
import {PolIcon} from "../../../components/PolIcon/policon";
import AdminCard from "../../../v4/components/ui/AdminCard/AdminCard";
import CategoriesPage from "../../../v4/pages/admin/Categories/CategoriesPage";

const initInputs = [
    {
        id: 'name',
        type: 'text',
        label: 'list_name',
        value: ''
    },
    {
        id: 'listingItems',
        type: 'array',
        label: 'items',
        value: []
    }
];

class Listing extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            view: null,
            loader: true,
            infoTable: {
                url: '/api/get_params_requests',
                deleteUrl: '/api/listing/{id}',
                loader: 'datatable-listing'
            },
            infoForm: {
                name: "admin_field",
                type: "form",
                url: "/api/field/form",
                formUrl: "/api/listing/form",
                group: 'admin_listing'
            },
            columns: [
                {
                    text: "Nom",
                    dataField: 'name',
                    sortCaret: (order, column) => arrowSort(order, column),
                    sortFunc: sortFunc,
                    sort: true
                },
                {
                    text: 'Code',
                    dataField: 'code',
                    sortCaret: (order, column) => arrowSort(order, column),
                    sortFunc: sortFunc,
                    sort: true
                },
            ],
            inputs: [],
            data: {},
            idItem: null
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const {match: {params}, location} = this.props;
        const item = location ? location.state ? location.state.item : null : null;

        let {infoForm} = this.state;
        let view = 'table';
        let idParam = null;

        if (params && params.id) {
            view = 'form';
            idParam = params.id;

            this.getData(idParam);
        }

        if (params && params.type && item && item.params) {
            view = 'form-list-admin';
        }

        this.setState({
            view: view,
            type: params?.type,
            idItem: idParam,
            infoForm: infoForm,
            data: {}
        })
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    getData = (id) => {
        const user = UserService.getUser();
        const token = user.token ? user.token : localStorage.getItem('token');

        fetch(process.env.REACT_APP_HOST_API + `/api/listing/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
        }).then(
            fetchHandler,
            error => {
                adminFetchHandler(error, {})
            }
        ).then(
            data => {
                if (data) {
                    if (this._isMounted) {
                        this.setState({
                            data: data
                        });
                    }
                } else {
                    manageError(500, null)
                }
            },
            error => {
                adminFetchHandler(error, {})
            }
        );
    };

    getView = () => {
        const {view, infoTable, columns, data} = this.state;
        const dataForm = data ? data : {};
        const {t, location} = this.props;
        const item = location ? location.state ? location.state.item : null : null;

        switch (view) {
            case('table'):
                return (
                    <>
                        <AdminDatatables info={infoTable}
                                         columns={columns}
                                         changeView={(item) => {
                                             let redirectTo = `/administration/gestion_listes${item && item.params ? '_admin' : ''}/${item['id'] ? item['id'] : item['code']}`;
                                             this.setState({
                                                 redirect: <Redirect
                                                     to={{
                                                         pathname: redirectTo,
                                                         state: {
                                                             item: item
                                                         }
                                                     }}/>
                                             })
                                         }}
                        />
                        <div className={'row align-items-center justify-content-end'}>
                            <div className={`col-12 col-md-auto form__submit`}>
                                <button className={'btn btn-primary form__submit__btn'} onClick={() => this.setState({
                                    view: 'form'
                                })}>
                                    {t('ajouter_liste')}
                                </button>
                            </div>
                        </div>
                    </>
                );
            case('form'):
                return (
                    <div className={'page-details'}>
                        <Form data={dataForm} initInputs={initInputs}/>
                    </div>
                );
            case('form-list-admin'):
                return (
                    <div className={'page-details'}>
                        {item?.code === "Category"
                            ? <CategoriesPage/>
                            : (
                                item.code === "QuoteState"
                                    ? <QuoteState rawValuesFromAdmin={item.params}/>
                                    : (
                                        <GetCustomerId>
                                            {(customerId) => <FormListingAdmin data={item.params}
                                                                               type={item['@type']}
                                                                               initInputs={initInputs}
                                                                               customerId={customerId}/>}
                                        </GetCustomerId>
                                    )
                            )
                        }
                    </div>
                );
            default:
                return null
        }
    };

    render() {
        const {t} = this.props;
        const {redirect} = this.state;
        const view = this.getView();

        const CardHeaderTools = () => (
            (this.state.type) === "Category" ?
                <Link className={'btn btn-primary'} to={'/administration/gestion_listes/'}>
                    <PolIcon icon={'chevron-circle-left'} className={'form__back__icon'}/>
                    {t('retour')}
                </Link>
                :
                <></>
        )

        return (
            <>
                {redirect}
                <AdminCard title={t('admin_gestion_listes')} CardHeaderTools={CardHeaderTools}>
                    {view}
                </AdminCard>
            </>
        )
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
    return {
        postForm: (info) => dispatch(postForm(info)),
        manageError: (code, error) => dispatch(manageError(code, error)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(Listing));

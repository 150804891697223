import {getLSItem, removeLSItem, setLSItem} from "v4/services/localStorage.service";

export const BADGES = 'badges';

export function setBadges(badges) {
    setLSItem(BADGES, badges);
}

export function getBadges(type = 'json') {
    return getLSItem(BADGES, type);
}

export function removeBadges() {
    removeLSItem(BADGES);
}

export function getBadge(badgeId) {
    const badges = getBadges();

    return badges[badgeId];
}

export function setBadge(badgeId, badge) {
    const badges = getBadges();

    badges[badgeId] = badge;

    setBadges(badges);
}

export function removeBadge(badgeId) {
    const badges = getBadges();

    delete badges[badgeId];

    setBadges(badges);
}

import React, {useCallback, useEffect} from 'react';
import Select from "react-select";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import usePrevious from "v4/hooks/usePrevious";

export default function CustomReactSelect({
                                              name,
                                              value,
                                              options,
                                              isMulti = false,
                                              placeholder,
                                              onChange,
                                              htmlConstraints,
                                              selectParams = {}
                                          }) {
    const {t} = usePolTranslation();

    const handleChange = (option) => {
        if (option) {
            onChange(isMulti ? option.map((item) => item.value) : option.value);
        } else {
            onChange(isMulti ? [] : '');
        }
    }

    const getValue = useCallback((val) => {
        if (options && val) {
            return isMulti
                ? options.filter(option => val.indexOf(String(option?.value)) >= 0)
                : options.find(option => option?.value === val);
        }

        return isMulti ? [] : "";
    }, [isMulti, options]);

    const previousOptions = usePrevious(options);
    useEffect(() => {
        if (previousOptions === undefined || JSON.stringify(previousOptions) === JSON.stringify(options)) return;
        handleChange(getValue(value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    return (
        <Select name={name}
                {...htmlConstraints}
                value={getValue(value)}
                onChange={handleChange}
                options={options}
                isMulti={isMulti}
                placeholder={placeholder ?? t('select')}
                noOptionsMessage={() => t('no_results')}
                closeMenuOnSelect={!isMulti}
                isClearable={true}
                menuPlacement="auto"
                className={`react-select ${htmlConstraints?.required ? "select-is-required" : ""}`}
                classNamePrefix="react-select"
                onFocus={({target}) => target.closest('.react-select').dataset.focus = "true"}
                onBlur={({target}) => target.closest('.react-select').dataset.focus = "false"}
                styles={selectStyle}
                {...selectParams}
        />
    )
}

export const selectStyle = {
    control: (base) => ({
        ...base,
        boxShadow: 'none',
        border: '1px solid transparent',
        backgroundColor: '#f3f1fc',
        fontFamily: 'inherit',
        outline: 'none',
        cursor: 'pointer',
        width: '100%',
        ':hover': {
            border: '1px solid transparent',
        },
    }),
    container: (base) => ({
        ...base,
        width: '100%',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        ':hover': {color: '#7565c0'},
        color: '#7565c0',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#a49bd6',
    }),
    valueContainer: (base) => ({
        ...base,
        textOverflow: "ellipsis",
        maxWidth: "80%",
        whiteSpace: "nowrap",
        overflow: "hidden"
    }),
    singleValue: (base) => ({
        ...base,
        color: '#7565c0',
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? state.isSelected ? '#a59bd6' : '#a59bd6' : 'transparent',
        cursor: 'pointer',
        color: state.isFocused ? 'white' : 'black',
    })
}

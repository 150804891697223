import React, {useMemo, useEffect, useState, useCallback} from 'react';
import Table from "v4/components/ui/Table/Table";
import {Link} from "react-router-dom";
import {routesBase} from 'v4/data/appRoutes'
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import apiService from "v4/services/api.service";
import {TEMPLATE_LIST} from "v4/data/apiRoutes";
import {PolIcon} from "components/PolIcon/policon";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import TemplatesListDelete from "v4/pages/admin/Templates/components/TemplatesList/components/TemplatesListDelete/TemplatesListDelete";
import useNotification from "v4/hooks/useNotification";

export default function TemplatesList({customerId}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()

    // Listing
    const [{
        data: loadedTemplates,
        isLoading: loadedTemplatesLoading,
        isError: loadedTemplatesError
    }, fetchTemplates] = useFetch();

    const [refetch, setRefetch] = useState(0)

    useEffect(() => {
        fetchTemplates({
            url: apiService.generateUrl(TEMPLATE_LIST, {
                customerId: customerId,
                sortField: "name",
                sortOrder: "asc"
            }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const onDeleteSuccess = useCallback(() => {
        addSuccessNotification(t('delete_template_done'))
        setRefetch(c => c + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const onDeleteError = useCallback(() => {
        addErrorNotification(t('delete_template_error'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hydrateTemplateRows = useCallback(() => {

        if (!loadedTemplates?.['hydra:member']) {
            return null
        }

        const rows = JSON.parse(JSON.stringify(loadedTemplates['hydra:member']))

        return (
            rows.map((item, index) => {
                return {
                    name: item.name,
                    actions: (
                        <ul className="datatable__actions">
                            <Link key={index} to={`${routesBase.adminManageTemplates}/edit/${item.id}`}>
                                <li className="datatable__actions__item">
                                    <span className="datatable__actions__item__icon">
                                        <PolIcon icon='edit'/>
                                    </span>
                                </li>
                            </Link>
                            <TemplatesListDelete item={item} onSuccessCallback={onDeleteSuccess}
                                                 onErrorCallBack={onDeleteError}/>
                        </ul>
                    )
                };
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedTemplates])

    const tableList = useMemo(() => {
        return <Table tableRows={hydrateTemplateRows()} isLoading={loadedTemplatesLoading}
                   isError={loadedTemplatesError}
                   theadData={Object.keys({name: 'name', actions: 'actions'})}/>
    }, [hydrateTemplateRows, loadedTemplatesLoading, loadedTemplatesError])

    const CardHeaderTools = () => (
        <Link to={routesBase.adminManageTemplates + '/add'}
              className="btn btn-primary">{t('add_new_template')}</Link>
    )

    return (
        <AdminCard title={t('manage_invoices_templates')} CardHeaderTools={CardHeaderTools}>
            {tableList}
        </AdminCard>
    )
}

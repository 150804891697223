import {getLSItem} from "v4/services/localStorage.service";
import getCleanViewOrdersGroups from "v4/pages/admin/ViewOrders/contexts/utils/getCleanViewOrdersGroups";
import {CONTACT, PRODUCT, PROSPECT, QUOTE, TASK} from "v4/data/entities";
import {toCapitalize} from "v4/utils";

export default function getCleanViewOrders() {
    const user = getLSItem('user');
    const viewOrdersFromLocalStorage = user.orders?.['hydra:member'][0];
    if (!viewOrdersFromLocalStorage) return {};

    const viewOrders = Object.entries(viewOrdersFromLocalStorage).reduce((acc, [entity, viewOrder]) => {
        const {idViewOrderCustomer, form, search} = viewOrder;
        acc[entity] = {
            id: idViewOrderCustomer,
            ...(form && {form: getCleanViewOrdersGroups(entity, form)}),
            ...(search && {search: getCleanViewOrdersGroups(entity, search, true)}),
        };
        return acc;
    }, {});

    // Return sorted view orders by entity name and remove unhandled entities
    const sortedViewOrders = Object.keys(viewOrders).sort().reduce((acc, entityName) => {
        if ([PROSPECT, CONTACT, TASK, QUOTE, PRODUCT].includes(entityName.toLowerCase())) {
            acc[entityName] = viewOrders[entityName];
        }
        return acc;
    }, {});

    const tabsWithoutViewOrder = user.tabs?.['hydra:member'].filter(tab => {
        if (!tab.id) return;

        return !Object.keys(sortedViewOrders).some(entityName => {
            const {form, search} = sortedViewOrders[entityName];
            const allViewOrdersIds = [...Object.keys(form), ...Object.keys(search)];

            // Check if allViewOrdersIds contains the tab.id twice (form and search)
            // It might be cases where the view order of form or search is missing
            return allViewOrdersIds.filter(id => id === tab.id).length === 2;
        });
    });

    return createEmptyViewOrdersForTabs(sortedViewOrders, tabsWithoutViewOrder);
}

function createEmptyViewOrdersForTabs(viewOrders, tabsWithoutViewOrders) {
    return tabsWithoutViewOrders.reduce((acc, tab) => {
        const entityName = toCapitalize(tab.searchType);
        if (!acc[entityName].form[tab.id]) {
            acc[entityName].form[tab.id] = [];
        }
        if (!acc[entityName].search[tab.id]) {
            acc[entityName].search[tab.id] = [];
        }

        return acc;
    }, viewOrders);
}

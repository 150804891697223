import * as moment from "moment";
import {SORT_BY_DATE, SORT_BY_NAME, SORT_BY_SIZE} from "../views/Ged/Ged";

export const sortByName = (documents, type)=> {
    if(documents && documents.length){
        if(type === 'folder'){
            return documents.sort((a, b) => {
                if(a.folderName && b.folderName) {
                    if (a.folderName.toLowerCase() < b.folderName.toLowerCase()) {
                        return -1;
                    }
                    if (a.folderName.toLowerCase() > b.folderName.toLowerCase()) {
                        return 1;
                    }
                }
                return 0;
            })
        }
        return documents.sort((a, b) => {
            if(a.fileName && b.fileName) {
                if (a.fileName.toLowerCase() < b.fileName.toLowerCase()) {
                    return -1;
                }
                if (a.fileName.toLowerCase() > b.fileName.toLowerCase()) {
                    return 1;
                }
            }
            return 0;
        })
    }
}

export const sortBySize = (documents, type)=> {
    return documents.sort((a, b) => {
        if(a.size < b.size) { return -1; }
        if(a.size > b.size) { return 1; }
        return 0;
    })
}

export const sortByDate = (documents, type)=> {
    return documents.sort((a, b) => {
        return moment(a.createdAt) - moment(b.createdAt)
    })
}

export const mergeAndSortFiles = (directoryList, activeSort) => {
    let currentDirectoryList = [];
    let directories = [];
    let files = [];

    directoryList.forEach(item => {
        if (item.fileName) {
            files.push(item)
            return
        }
        directories.push(item)
    })

    if (activeSort === SORT_BY_NAME) {
        directories = sortByName(directories, 'folder');
        files = sortByName(files);
    }

    if (activeSort === SORT_BY_DATE) {
        directories = sortByDate(directories, 'folder');
        files = sortByDate(files);
    }

    if (activeSort === SORT_BY_SIZE) {
        directories = sortBySize(directories, 'folder');
        files = sortBySize(files);
    }

    if (Array.isArray(directories) && Array.isArray(files)) {
        currentDirectoryList = [...directories, ...files]
    } else {
        if (Array.isArray(directories)) {
            currentDirectoryList = [...directories]
        }
        if (Array.isArray(files)) {
            currentDirectoryList = [...files]
        }
    }

    return currentDirectoryList;
}
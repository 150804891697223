import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";

export default function ProspectHeadingTabPartial({title, actions = null}) {
    const {t} = usePolTranslation();
    const {prospect} = useProspectPageContext();

    return (
        <div className="prospect-tab__listing__header">
            <h2>{t(title)}</h2>
            {actions && (
                <div className="prospect-tab__actions">
                    {actions({prospect})}
                </div>
            )}
        </div>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TASK} from "v4/data/entities";
import ListingExport from "v4/features/front/export/components/ListingExport/ListingExport";
import useMailingStatistics from "v4/features/front/mailingStatistics/hooks/useMailingStatistics";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import 'v4/pages/front/Tasks/components/TasksListing/components/TasksListingTools/TasksListingTools.scss';
import {ids} from "v4/utils";

const svg = <svg aria-hidden="true" focusable="false" className="svg-inline--fa fa-sync fa-w-16" role="img"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="currentColor"
          d="M440.65 12.57l4 82.77A247.16 247.16 0 0 0 255.83 8C134.73 8 33.91 94.92 12.29 209.82A12 12 0 0 0 24.09 224h49.05a12 12 0 0 0 11.67-9.26 175.91 175.91 0 0 1 317-56.94l-101.46-4.86a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12H500a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12h-47.37a12 12 0 0 0-11.98 12.57zM255.83 432a175.61 175.61 0 0 1-146-77.8l101.8 4.87a12 12 0 0 0 12.57-12v-47.4a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12V500a12 12 0 0 0 12 12h47.35a12 12 0 0 0 12-12.6l-4.15-82.57A247.17 247.17 0 0 0 255.83 504c121.11 0 221.93-86.92 243.55-201.82a12 12 0 0 0-11.8-14.18h-49.05a12 12 0 0 0-11.67 9.26A175.86 175.86 0 0 1 255.83 432z"></path>
</svg>;

export default function TasksListingTools({checkedRows}) {
    const {t} = usePolTranslation();
    const [isLoading, fetchMailingStatistics] = useMailingStatistics();
    const handleNoSelectedItems = (e) => (checkedRows?.length === 0) && e.stopPropagation();

    return (
        <>
            <ListingExport selectedItems={checkedRows} entity={TASK}/>
            <li className="listing-header__action dropdown">
                <PolIcon icon="actions" className="action__picto"/>
                <ul className="listing-header__action__dropdown">
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}
                        onClickCapture={handleNoSelectedItems}>
                        <Link to={{
                            pathname: routesBase.baseContact,
                            state: {[ids(TASK)]: checkedRows}
                        }}>
                            <p>{t('research_associated_contacts')}</p>
                        </Link>
                    </li>
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}
                        onClickCapture={handleNoSelectedItems}>
                        <Link to={{
                            pathname: routesBase.baseProspect,
                            state: {[ids(TASK)]: checkedRows}
                        }}>
                            <p>{t('research_associated_prospects')}</p>
                        </Link>
                    </li>
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}
                        onClickCapture={handleNoSelectedItems}>
                        <Link to={{
                            pathname: routesBase.baseContact,
                            state: {[ids(TASK)]: checkedRows, isReverse: true}
                        }}>
                            <p>{t('reverse_research_associated_contacts')}</p>
                        </Link>
                    </li>
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}
                        onClickCapture={handleNoSelectedItems}>
                        <Link to={{
                            pathname: routesBase.baseProspect,
                            state: {[ids(TASK)]: checkedRows, isReverse: true}
                        }}>
                            <p>{t('reverse_research_associated_prospects')}</p>
                        </Link>
                    </li>
                    <li className={`listing-header__action__item stats ${isLoading ? 'listing-header__action__item__disabled' : ''}`}
                        onClick={fetchMailingStatistics}>
                        <div>
                            <span className={`svg-arrows ${isLoading ? 'spin' : ''}`}>
                                {svg}
                            </span>
                            <p>
                                {t('stats_mailing')}
                            </p>
                        </div>
                    </li>
                </ul>
            </li>
        </>
    )
}

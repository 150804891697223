import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import DownloadLink from "v4/components/utils/DownloadLink/DownloadLink";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuotesTabRowActions/QuotePdfVersionningButton/QuotePdfVersionningButton.scss';

export default function QuotePdfVersionningButton({quoteInvoices}) {
    const [isDropdownOpen, toggleDropdownOpen] = useToggle(false);
    const {t} = usePolTranslation();

    const handleClick = e => {
        if (e.target.tagName === 'A') return;

        toggleDropdownOpen();
    }

    const closeDropdown = () => toggleDropdownOpen(false);

    return (
        <div className="quote-pdf-versionning-button">
            {quoteInvoices.current ? (
                    <DownloadLink href={quoteInvoices.current.downloadLink} fileName={quoteInvoices.current?.fileLabel}>
                        <PolIcon icon="file_present"/>
                    </DownloadLink>
                )
                : <PolIcon icon="file_present"/>
            }

            {quoteInvoices.archives.length > 0 && (
                <div className="quote-pdf-versionning-button__dropdown"
                     data-is-open={isDropdownOpen.toString()}
                     onClick={handleClick}
                     onMouseLeave={closeDropdown}>
                    <PolIcon icon="Polygone"/>
                    <ul>
                        {quoteInvoices.archives.map((archive, index) => (
                            <li key={archive.id} title={t(archive.fileLabel ?? archive.realFileName)}>
                                <DownloadLink href={archive.downloadLink} fileName={archive?.fileLabel}>
                                    <PolIcon icon="download"/>
                                    <span>{`V${archive.versionNumber ?? (index + 1)}.pdf`}</span>
                                </DownloadLink>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

import React, {useContext} from 'react'
import {useRouteMatch} from "react-router-dom";
import {AdminContext} from "v4/contexts/AdminContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import CustomerEventServicesForm
    from "v4/pages/admin/CustomerEventServices/components/CustomerEventServicesForm/CustomerEventServicesForm";
import CustomerEventServicesList
    from "v4/pages/admin/CustomerEventServices/components/CustomerEventServicesList/CustomerEventServicesList";

export default function CustomerEventServicesPage() {
    const {t} = usePolTranslation();
    const {path, params: {id}, isExact} = useRouteMatch();
    const isAddPage = path.split('/').pop() === 'add';
    const isEditPage = !!id && isExact;
    const isListPage = (!isAddPage && !isEditPage);
    const {asCustomer} = useContext(AdminContext);

    if (!asCustomer?.id) {
        return <p>{t('no_customer_defined')}</p>
    }


    return (
        isListPage
            ? <CustomerEventServicesList customerId={asCustomer?.id}/>
            : <CustomerEventServicesForm id={id} customerId={asCustomer?.id}/>
    )
}

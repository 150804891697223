import React, {cloneElement} from 'react';

export default function usePropToComponent(component, newProps = {}) {
    if (!component) return [null, null];

    const newComponent = prepareComponentWithProps(component, newProps);

    const newComponentWithProps = (newProps) => prepareComponentWithProps(newComponent, newProps);

    return [newComponent, newComponentWithProps];
}

function prepareComponentWithProps(component, newProps) {
    let newComponent = null;

    if (typeof component === 'function') {
        const Component = component;

        newComponent = <Component {...component.props} {...newProps}/>;
    } else if (component) {
        newComponent = cloneElement(component, newProps);
    }

    return newComponent;
}

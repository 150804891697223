import React from 'react';
import {useFormContext} from "react-hook-form";
import QuotesProductsAdvancedSearch
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsAdvancedSearch/QuotesProductsAdvancedSearch";
// import apiService from "v4/services/api.service";
// import {IMAGE} from "v4/data/apiRoutes";
import 'v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsPopup/QuotesProductsPopup.scss';
import QuotesProductsStandardSearch
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsStandardSearch/QuotesProductsStandardSearch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";

export default function QuotesProductsPopup({togglePopup, addLine}) {
    const {t} = usePolTranslation();
    const {getValues, reset} = useFormContext();
    const [isAdvancedMode, toggleAdvancedMode] = useToggle();

    const handleAddProduct = () => {
        const values = getValues();

        if (values.quantity <= 0) {
            return;
        }

        if (String(values.search).length === 0) {
            document.querySelector('.quotes-products__popup__body__add-form .react-select').style.outline = '1px solid red';
        } else {
            document.querySelector('.quotes-products__popup__body__add-form .react-select').style.outline = '';
        }

        if (String(values.quantity).length === 0) {
            document.querySelector('.quotes-products__popup__body__add-form .input-field').style.outline = '1px solid red';
        } else {
            document.querySelector('.quotes-products__popup__body__add-form .input-field').style.outline = '';
        }

        if (String(values.search).length > 0 && String(values.quantity).length > 0) {
            addLine(values);
            reset();
            togglePopup();
        }
    }

    const handleAddProducts = (values) => {
        values.forEach(value => {
            if (value.quantity <= 0) {
                return;
            }

            // const imgUrl = value.productInfo?.imageId ? apiService.generateUrl(IMAGE, {id: value.productInfo.imageId}) : null;
            // let formattedImgUrl = null;
            // if (imgUrl) {
            //     formattedImgUrl = imgUrl.slice(imgUrl.indexOf('api'));
            // }

            addLine({
                name: value.productInfo.name,
                comment: value.productInfo?.shortDescription ?? '',
                description: value.productInfo?.description ?? '',
                unitPriceExclVat: value.price,
                quantity: value.quantity,
                sectionName: value.sectionName,
                quoteLineInfo: {
                    product: {...value.productInfo}
                },
            }, true);
        })
    }

    return (
        <div className="quotes-products__popup">
            <div className="quotes-products__popup__header">
                <p>{t('add_product')}</p>
            </div>
            <div className="quotes-products__popup__body">
                <div className="quotes-products__popup__body__advanced">
                    <div onClick={toggleAdvancedMode}>
                        {isAdvancedMode
                            ? <button>{t('standard_fields')}</button>
                            : <button>{t('advanced_fields')}</button>
                        }
                    </div>
                </div>
                {isAdvancedMode
                    ? <QuotesProductsAdvancedSearch togglePopup={togglePopup} handleAddProducts={handleAddProducts}/>
                    : <QuotesProductsStandardSearch togglePopup={togglePopup} handleAddProduct={handleAddProduct}/>
                }
            </div>
        </div>
    )
}

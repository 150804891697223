import {useEffect, useState} from 'react';
import {MODULE_MICROSOFT_GRAPH_POST_URL} from "v4/data/apiRoutes";
import {
    MODULE_MICROSOFT_GRAPH_ID
} from "v4/features/admin/modules/components/ErrorBoundaryModules/components/ManageModules/ManageModules";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";

export default function useMicrosoftGraphResponse(fetchData) {
    const {t} = usePolTranslation();
    const [{data, isLoading, errorData, isFinished}, fetchHook] = useFetch();
    const [message, setMessage] = useState(t('loading'));

    useEffect(() => {
        fetchHook({
            url: generateUrl(MODULE_MICROSOFT_GRAPH_POST_URL, {id: MODULE_MICROSOFT_GRAPH_ID}),
            config: {
                method: 'POST',
                body: JSON.stringify(fetchData),
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);

    useEffect(() => {
        if (data && !isLoading) {
            setMessage(data.message);
        } else if (errorData && !isLoading) {
            setMessage(errorData.message);
        } else if (isFinished && !isLoading && !data && !errorData) {
            setMessage('microsoft_graph_unknown_error');
        }
    }, [data, isLoading, errorData, isFinished]);

    return [t(message), isLoading];
}

import {useEffect} from 'react';
import {CONTACT_MOVE} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

export default function useMoveContact(contactId) {
    const [{data, isLoading}, fetchForm] = useFetch();

    useEffect(() => {
        fetchForm({
            url: generateUrl(CONTACT_MOVE, {id: contactId}),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return [formatDataForFormInputs(data), isLoading];
}

function formatDataForFormInputs(data) {
    const inputsInData = data?.schema?.['hydra:member']?.[2];
    if (!inputsInData) return null;

    return Object.entries(inputsInData)?.reduce((acc, [key, properties]) => {
        const type = getType(properties);

        const input = {
            key: key,
            label: properties.title,
            type: type,
            constraints: {required: properties.required},
            value: properties.value,
            attr: properties.attr,
            placeholder: properties.placeholder,
        }

        if (type === 'select') {
            input.choices = getOptions(properties);
        }

        acc.push(input);

        return acc;
    }, []);
}

function getType(properties) {
    if (properties.type === 'boolean') {
        return 'checkbox';
    }

    if (properties.type === 'string' && properties.enum) {
        return 'select';
    }

    return 'text';
}

function getOptions(properties) {
    return properties.enum?.reduce((acc, option, index) => {
        acc[properties.enum_titles[index]] = option;

        return acc;
    }, {})
}

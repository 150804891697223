import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_OPEN_TASKS} from "v4/data/tabNames";

export default function ProspectsListingRowActions({rowId}) {
    return (
        <Link to={`${routesBase.baseProspect}/${rowId}/${TAB_OPEN_TASKS}`} className="table-link"/>
    )
}

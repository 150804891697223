import React, {useRef} from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function DisplayFields({label, choices, attr}) {
    const {t} = usePolTranslation();

    const {'display-fields-for': displayFieldsFor} = attr ?? {};
    const displayFields = choices.map(({label}) => label);

    const textareaRef = useRef(null);

    const onFieldChange = ({currentTarget}) => {
        if (!textareaRef.current) {
            textareaRef.current = document.querySelector(`[name="${displayFieldsFor}"]`);
        }
        if (textareaRef.current.TinyMCE) {
            const editor = textareaRef.current.TinyMCE.editor;
            // add currentTarget.innerHTML to tinymce editor at the current position
            editor.selection.setContent(currentTarget.innerHTML);
            // focus on tinymce editor
            editor.focus();
        }
    }

    return (
        <div className="input-display-fields">
            <p>{t(label)}</p>
            {displayFields.map((title) => (
                <button type="button" key={title} onClick={onFieldChange}>{title}</button>
            ))}
        </div>
    )
}

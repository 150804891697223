import React, {useCallback, useEffect, useMemo} from 'react';
import {useHistory} from "react-router-dom";
import PolTable from "v4/components/ui/PolTable/PolTable";
import SearchContextPagination from "v4/components/utils/SearchContextPagination/SearchContextPagination";
import {PROSPECT_CONTACTS} from "v4/data/apiRoutes";
import {CONTACT} from "v4/data/entities";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import useToggle from "v4/hooks/useToggle";
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import ProspectContactsCustomTabRowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsCustomTab/components/ProspectContactsCustomTabRowActions/ProspectContactsCustomTabRowActions";
import ProspectContactsCustomTabActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsCustomTab/components/ProspectContactsCustomTabActions/ProspectContactsCustomTabActions";
import {generateUrl} from "v4/services/api.service";

export default function ProspectContactsCustomTabListing({prospectId, tab}) {
    const {t} = usePolTranslation();
    const {
        initPagesWithTotalItems,
        currentPage,
        pages,
        itemsPerPage,
        sortOrder,
        sortField,
        setSortField,
        setSortOrder
    } = useSearchContext();

    const [{data, isLoading}, fetchItems] = useFetch();

    const [refetch, toggleRefetch] = useToggle();

    const {location: {state}, replace} = useHistory();
    useEffect(() => {
        if (state?.hasToRefresh) {
            const {hasToRefresh, ...newState} = state;
            replace({state: newState})
            toggleRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.hasToRefresh]);


    const contacts = useMemo(() => data?.['hydra:member'] || [], [data]);
    const status = useMemo(() => {
        return {
            isLoading: isLoading,
        }
    }, [isLoading]);

    useEffect(() => {
        fetchItems({
            url: generateUrl(PROSPECT_CONTACTS, {
                prospectId,
                page: currentPage,
                sortField,
                sortOrder,
                itemsPerPage,
                tabId: tab.id,
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prospectId, sortOrder, sortField, currentPage, itemsPerPage, refetch, tab]);

    useEffect(() => {
        if (data && !pages) {
            initPagesWithTotalItems(data?.['hydra:totalItems']);
        }

        return () => initPagesWithTotalItems(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pages]);

    const onHeaderClick = useCallback(({currentTarget}) => {
        const {key, sort} = currentTarget.dataset;
        let currentSortOrder = sortOrder;
        if (sortField !== key) {
            setSortField(key);
            currentSortOrder = 'asc';
            [...currentTarget.closest('tr').querySelectorAll('[data-sort]')].forEach(child => {
                if (child !== currentTarget) {
                    delete child.dataset.sort;
                    child.classList.remove('active');
                }
            })
        }
        if (!sort) {
            setSortOrder(currentSortOrder);
            currentTarget.dataset.sort = currentSortOrder;
            currentTarget.classList.add('active');
        } else {
            const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
            setSortOrder(newSortOrder);
            currentTarget.dataset.sort = newSortOrder;
            currentTarget.classList.add('active');
        }
    }, [sortField, sortOrder, setSortField, setSortOrder]);


    return (
        <div className="prospect-tab__listing__wrapper">
            <ProspectHeadingTabPartial title={tab.name} actions={(props) => <ProspectContactsCustomTabActions {...props}/>}/>
            <div className="prospect-tab__listing">
                <p className="prospect-tab__listing__results">
                    &zwj;
                    {data
                        && t('result_header_datatable_homepage', {
                            start: data?.['hydra:totalItems'] > 0 ? (currentPage - 1) * itemsPerPage + 1 : 0,
                            end: (currentPage - 1) * itemsPerPage + contacts?.results.length,
                            count: data?.['hydra:totalItems'],
                            many: data?.['hydra:totalItems'] > 1 ? 's' : '',
                            all: data?.['hydra:totalItems'] > 1 ? 's' : '',
                        })
                    }
                </p>
                <PolTable data={contacts}
                          status={status}
                          onHeaderClick={onHeaderClick}
                          rowActions={ProspectContactsCustomTabRowActions}
                          toggleRefetch={toggleRefetch}
                          entity={CONTACT}/>
                <SearchContextPagination/>
            </div>
        </div>
    )
}

import React, {useState} from 'react'
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import Loading from "../../../components/Loading/Loading";
import ImportStepOne from "./_partial/importStepOne"
import ImportStepTwo from "./_partial/importStepTwo"
import {useRecoilValue} from "recoil";
import {importProgression} from "../../../components/Admin/atoms/importAdmin";
import moment from 'moment';
import ProgressBar from "../../../components/Loading/progressBar";


const Import = ({t}) => {
    const [fieldsAvailable, setFieldsAvailable] = useState('');
    const [formValues, setFormValues] = useState({});
    const [loader, setLoader] = useState(true);
    const [progressBar, setProgressBar] = useState(false);
    const [step, setStep] = useState(1);
    const progress = useRecoilValue(importProgression)
    const message = t('loading');

    return (
        <>
            {(loader) || (progressBar && Object.keys(progress).length === 0) ? (
                <Loading message={message} specificClass={"admin-loader"}/>) : null
            }
            {progressBar && Object.keys(progress).length &&
            <ProgressBar bgcolor={'#6a1b9a'}
                         completed={progress.isFinished ? 100 : ((progress.nbEntitiesProcessed / progress.totalNbEntities) * 100).toFixed(0)}
                         timeRemaining={moment().startOf('day').seconds(progress.timeRemaining).format('mm:ss')}
                         infoText={progress.step === 'validate' ? t('validate_info') : t('import_info')}
            />
            }
            <div className="page page-admin content-wrapper">
                <div className={'row'}>
                    {step === 1 ? (
                        <ImportStepOne setLoader={setLoader}
                                       formValues={formValues}
                                       setFormValues={setFormValues}
                                       fieldsAvailable={fieldsAvailable}
                                       setFieldsAvailable={setFieldsAvailable}
                                       setStep={setStep}
                        />) : null}
                    {step === 2 ? (
                        <ImportStepTwo setProgressBar={setProgressBar} formValues={formValues} setLoader={setLoader}
                                       setStep={setStep} fieldsAvailable={fieldsAvailable}/>
                    ) : null}
                </div>
            </div>
        </>
    )
}

export default (withPolTranslation(Import));

import React, {Component} from 'react';
import {Map, TileLayer, Marker} from 'react-leaflet';
import L from 'leaflet';
import {Help} from "./_partials/help";
import {Informations} from "./_partials/informations";
import Search from "./_partials/search";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import Draggable from 'react-draggable';

const iconMain = require('../../img/main_marker.svg');
const iconDefault = require('../../img/marker_client.svg');
//const iconProspect = require('../../img/marker_prospect.svg');

let markerIcon = {
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
    shadowSize: null,
    shadowAnchor: null
};

class Maps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayInfo: true,
            activeDrags: 0,
        }
    }

    displayInfo = (display = true) => {
        this.setState({
            displayInfo: display
        })
    };

    onStart = () => {
        this.setState({activeDrags: this.state.activeDrags + 1});
    };

    onStop = () => {
        this.setState({activeDrags: this.state.activeDrags + 1});
    };

    render() {
        const {t, map, markers, prospect, info, clickProspect, setLoading, isLoading, setSearchDistance, currentLocation} = this.props;
        const {displayInfo} = this.state;

        const mapPosition = [map.lat, map.lng];
        if (!map.lat === undefined || map.lng === undefined) {
            return (
                <div className={'map-error'}>
                    {t('error_map')}
                </div>
            )
        }

        return (

            <div className={'maps'}>
                <Map center={mapPosition}
                     zoom={map.zoom}
                     animate={true}
                >
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                    />

                    {markers.map((marker, key) => {
                        const newIcon = L.icon({
                            ...markerIcon,
                            className: `mapspage__marker`,
                            iconUrl: marker.isMain ? iconMain : iconDefault
                        });

                        return (
                            <Marker
                                key={key}
                                position={[marker.lat, marker.lng]}
                                icon={newIcon}
                                onClick={() => {
                                    clickProspect(key);
                                    this.displayInfo(true)
                                }}
                            >
                            </Marker>
                        )
                    })}

                    <Help/>
                </Map>

                {prospect && Object.keys(prospect).length && displayInfo ? (
                    <Draggable
                        onStar={() => this.onStart()}
                        onStop={() => this.onStop()}
                        defaultClassName={`mapspage__popin info`}
                    >
                        <div>
                            <Informations prospect={prospect} setDisplay={() => this.displayInfo(!displayInfo)}/>
                        </div>
                    </Draggable>
                ) : null}

                <Search info={info} lat={map.lat} lng={map.lng} setLoading={setLoading} isLoading={isLoading} setSearchDistance={setSearchDistance} isDistanceDisplay={Object.keys(prospect).length>0 && !currentLocation.match(/\/all\/maps/g)}/>
            </div>
        )
    }
}

export default withPolTranslation(Maps);

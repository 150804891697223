import React from 'react';
import {Link} from "react-router-dom";
import {TAB_CLOSED_TASKS, TAB_OPEN_TASKS} from "v4/data/tabNames";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ProspectClosedTasksTabActions() {
    const {t} = usePolTranslation();

    return (
        <>
            <Link to={({pathname}) => `${pathname.replace(TAB_CLOSED_TASKS, TAB_OPEN_TASKS)}/resume`} className="prospect-tab__action">
                <span>{t('tasks_summary')}</span>
            </Link>
        </>
    )
}

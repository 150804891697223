import React from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useRouteMatch} from "react-router-dom";
import ModuleConfigurationsList
    from "v4/pages/admin/ModuleConfigurations/ModuleConfigurationsList/ModuleConfigurationsList";
import ModuleConfigurationsForm
    from "v4/pages/admin/ModuleConfigurations/ModuleConfigurationsForm/ModuleConfigurationsForm";
export default function ModuleConfigurationsPage() {
    const {t} = usePolTranslation();
    const {path, params: {id, moduleId, customerId}, isExact} = useRouteMatch();
    const isAddPage = path.split('/').pop() === 'add';
    const isEditPage = !!id && isExact;
    const isListPage = (!isAddPage && !isEditPage);

    return (
        <>
            {customerId ?
                (
                    <>
                        {
                            isListPage
                                ? <ModuleConfigurationsList customerId={customerId} moduleId={moduleId}/>
                                : <ModuleConfigurationsForm id={id} customerId={customerId} moduleId={moduleId}/>
                        }
                    </>
                ) : (
                    <p>{t('no_customer_defined')}</p>
                )
            }
        </>
    )

}

import useToggle from "v4/hooks/useToggle";
import {useLayoutEffect, useRef} from "react";

export default function useViewOrdersButtonListHover(list) {
    const [isActionOpen, toggleIsActionOpen] = useToggle();
    const [shouldOpenTop, toggleShouldOpenTop] = useToggle();
    const containerRef = useRef();

    useLayoutEffect(() => {
        if (isActionOpen && containerRef.current) {
            const {bottom, height} = containerRef.current.getBoundingClientRect();
            const {bottom: scrollableBottom} = containerRef.current.closest('.new-view-orders__scrollable').getBoundingClientRect();

            toggleShouldOpenTop((bottom + height * list.length) > scrollableBottom);
        }
    }, [list.length, isActionOpen, toggleShouldOpenTop]);

    return {
        isActionOpen,
        toggleIsActionOpen,
        shouldOpenTop,
        containerRef,
    }
}

export default function financial(x, hasDecimals = true) {
    const euroFormat = Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: hasDecimals ? 2 : 0,
    });
    if (isNaN(x) || x === '') {
        return '0 €';
    }
    return euroFormat.format(Number.parseFloat(x).toFixed(2));
};

import {PolIcon} from "components/PolIcon/policon";
import React, {useRef} from 'react';
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {PROSPECT} from "v4/data/entities";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import AsideFilters
    from "v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/AsideFilters";

const additionnalParamsForSearch = {isGeolocationSearch: true};

export default function MapFilter() {
    const {t} = usePolTranslation();
    const {formMethods} = useSearchContext();
    const distanceInput = useRef(null);

    const [isFiltersShowing, toggleFiltersShowing] = useToggle();

    const handleOk = () => {
        const distanceFromForm = formMethods.getValues('distance');

        if (distanceFromForm === distanceInput.current.valueAsNumber || distanceInput.current.valueAsNumber === 0) return;
        if (!distanceFromForm) {
            formMethods.register('distance');
        }

        formMethods.setValue('distance', distanceInput.current.valueAsNumber);
    }

    const handleEnter = (e) => {
        if (
            e.key === 'Enter'
            && distanceInput.current.valueAsNumber !== formMethods.getValues('distance')
            && distanceInput.current.valueAsNumber >= distanceInput.current.min
        ) {
            handleOk();
        }
    }

    return (
        <div className="map-filter">
            <div className="map-filter__distance">
                <span>{t('recherche_distance')}</span>
                <input type="number"
                       name="distance"
                       defaultValue={0}
                       onKeyPress={handleEnter}
                       min={0}
                       ref={distanceInput}/>
                <span>{t('km')}</span>
                <button onClick={handleOk}>{t('ok')}</button>
            </div>
            <div className="map-filter__filters">
                <div className="map-filter__filters__icon" onClick={toggleFiltersShowing}>
                    <PolIcon icon="search"/>
                </div>
                <div className="map-filter__filters__wrapper">
                    <AsideFilters entity={PROSPECT}
                                  isSavedSearchVisible={false}
                                  additionnalParamsForSearch={additionnalParamsForSearch}
                                  isFiltersShowing={isFiltersShowing}
                                  toggleFiltersShowing={toggleFiltersShowing}/>
                </div>
            </div>
        </div>
    )
}

import React, {useState} from 'react';
import {adminFetchHandler} from '../../../api/datas/datas';
import {getAdmin} from '../../../api/admin/get';
import {connect} from 'react-redux';
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {humanFileSize} from '../../../utils/unitConverter';

const GedSizeButton = ({customerId, adminFetchHandler, t}) => {
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [size, setSize] = useState(null);

    /**
     * OnClick EventListener
     *
     * Fetches the current GED utilization
     */
    const onClick = () => {
        setLoading(true);
        setError(false);
        setSize(null);

        getAdmin(`/api/customer_resources_statistics?customerId=${customerId}`, (error) => {
            setLoading(false);
            setError(true);

            adminFetchHandler(error, {});
        }, (response) => {
            setLoading(false);

            if (response.data.hasOwnProperty('totalSize')) {
                setSize(response.data.totalSize);
            }
        });
    };

    return <>
        <button className="btn btn-primary form__submit__btn col-6" onClick={onClick} disabled={isLoading}>
            {
                (isError && t('ged_get_size_error'))
                || (isLoading && t('loading'))
                || (size && `${t('ged_weight')} ${humanFileSize(size)}`)
                || t('ged_get_size')
            }
        </button>
    </>;
};

const mapDispatchToProps = dispatch => {
    return {
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info))
    };
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(React.memo(GedSizeButton)));

import React, {createContext, Fragment, useCallback, useContext, useMemo, useRef, useState} from "react";
import {useHistory, useRouteMatch} from "react-router-dom";
import {LAST_VISIT} from "v4/data/apiRoutes";
import {getRouteByBasePath, getRouteByEntity, getRouteById, routesBase} from "v4/data/appRoutes";
import {PROSPECT} from "v4/data/entities";
import useFetch from "v4/hooks/useFetch";
import useToggle from "v4/hooks/useToggle";
import {generateUrl} from "v4/services/api.service";

const defaultFront = {
    routeKey: undefined,
    setRouteKey: () => {
    },
    lastVisits: [],
    setLastVisits: () => {
    },
    addLastVisit: () => {
    },
    currentListingEntity: null,
    goBackToListing: () => {
    },
    refetchLastsVisits: false,
    toggleRefetchLastsVisits: () => {
    },
    tab: null,
    isProspectTab: false,
    saveClickedId: () => {
    },
    clearClickedId: () => {
    },
}

export const FrontContext = createContext(defaultFront)

export function useFrontContext() {
    return useContext(FrontContext)
}

export function FrontProvider({body, header, footer}) {
    const [routeKey, setRouteKey] = useState(defaultFront.routeKey);
    const [lastVisits, setLastVisits] = useState(defaultFront.lastVisits);
    const [refetchLastsVisits, toggleRefetchLastsVisits] = useToggle(defaultFront.refetchLastsVisits);
    const [, fetchLastVisits] = useFetch();

    const addLastVisit = useCallback((item) => {
        const hasSameUrl = visit => visit.url === item.url;
        const removeDuplicates = visit => !hasSameUrl(visit);

        fetchLastVisits({
            url: generateUrl(LAST_VISIT),
            config: {
                method: 'POST',
                body: JSON.stringify(item)
            }
        });

        setLastVisits(prev => {
            const alreadyExistsAtIndex = prev.findIndex(hasSameUrl);
            if (alreadyExistsAtIndex === 0) return prev;

            return [item, ...prev.filter(removeDuplicates)].slice(0, 10);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastVisits]);

    // Set current listing entity on route change if we are on a listing page
    const {push, location: {pathname}} = useHistory();
    const {params: {prospectId, tabName} = {}} = useRouteMatch(`${routesBase.baseProspect}/:prospectId/:tabName`) ?? {};
    const isProspectTab = useMemo(() => !(!tabName || ['maps', 'add'].includes(prospectId)), [prospectId, tabName]);
    let previousCurrentListingEntity = useRef(defaultFront.currentListingEntity);
    let previousTab = useRef(defaultFront.tab);
    const [currentListingEntity, tab] = useMemo(() => {
        const route = getRouteByBasePath(pathname, !isProspectTab);
        if (route?.entity) {
            previousCurrentListingEntity.current = route.entity;
            previousTab.current = route.isTab ? route.tab : undefined;

            return [route.entity, route.tab];
        }

        return [previousCurrentListingEntity.current ?? PROSPECT, previousTab.current];
    }, [pathname, isProspectTab]);

    const clickedId = useRef(null);
    const saveClickedId = useCallback(({currentTarget}) => {
        const {id} = currentTarget ?? {};
        if (!id) return;

        clickedId.current = id;
    }, []);
    const clearClickedId = useCallback(() => {
        clickedId.current = null;
    }, []);

    const goBackToListing = useCallback(() => {
        const listingRoute = tab ? getRouteById(tab.id) : getRouteByEntity(currentListingEntity);
        if (!listingRoute) return;
        push(listingRoute.basePath + (clickedId.current ? `#${clickedId.current}` : ''));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentListingEntity, tab, clickedId.current]);

    const valueToRender = useMemo(() => ({
        routeKey,
        setRouteKey,
        lastVisits,
        setLastVisits,
        addLastVisit,
        currentListingEntity,
        goBackToListing,
        refetchLastsVisits,
        toggleRefetchLastsVisits,
        tab,
        isProspectTab,
        saveClickedId,
        clearClickedId,
    }), [routeKey, setRouteKey, lastVisits, setLastVisits, addLastVisit, currentListingEntity, goBackToListing, refetchLastsVisits, toggleRefetchLastsVisits, tab, isProspectTab, saveClickedId, clearClickedId]);

    return (
        <FrontContext.Provider value={valueToRender}>
            {header?.()}
            <Fragment key={routeKey}>{body}</Fragment>
            {footer?.()}
        </FrontContext.Provider>
    )
}

import ErrorBoundary from "components/Error/errorBoundary";
import React from 'react';

export default function ToggleComponent({isShowing, shouldRemoveFromDom = false, className = "", children}) {

    return (
        <ErrorBoundary>
            <div className={className} style={{display: !isShowing ? 'none' : ''}}>
                {isShowing || !shouldRemoveFromDom ? children : null}
            </div>
        </ErrorBoundary>
    )
}

import React from 'react'
import {DragDropContext} from "react-beautiful-dnd";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import TreeNodes from "v4/components/ui/Tree/components/TreeNodes/TreeNodes";
import useTreeNodes from "v4/components/ui/Tree/hooks/useTreeNodes";

export default function Tree({parentId, level}) {
    const {t} = usePolTranslation();
    const {treeNodes, addNodeAction, onBeforeDragStart, resetAction, onDragEnd, saveAction} = useTreeNodes()

    return (
        <>
            <div className="row justify-between mb-3">
                <div className="col">
                    <button type={"button"}
                            className="btn btn-primary"
                            onClick={() => addNodeAction(null)}>{t('add_root_category')}</button>
                    <button type={"button"}
                            className="btn btn-primary"
                            onClick={() => resetAction()}>{t('reset')}</button>
                    <button type={"button"}
                            className="btn btn-primary"
                            onClick={() => saveAction([...treeNodes])}>{t('save_categories')}</button>
                </div>
            </div>

            <div className={'treeNodes'} id={'treeNodes'}>
                <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
                    {treeNodes &&
                        <TreeNodes childNodes={treeNodes} parentId={parentId} level={level}/>
                    }
                </DragDropContext>
            </div>

            <div className="row justify-between">
                <div className="col">
                    <button type={"button"}
                            className="btn btn-primary"
                            onClick={() => addNodeAction(null)}>{t('add_root_category')}</button>
                    <button type={"button"}
                            className="btn btn-primary"
                            onClick={() => resetAction()}>{t('reset')}</button>
                    <button type={"button"}
                            className="btn btn-primary"
                            onClick={() => saveAction([...treeNodes])}>{t('save_categories')}</button>
                </div>
            </div>
        </>
    )
}

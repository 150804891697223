import {setPopup} from "actions/content";
import {resetStoreDatas} from "actions/datas";
import {setFilterSearch} from "actions/form";
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import NavigationMenuItems from "v4/features/front/routing/components/NavigationMenuItems/NavigationMenuItems";
import logo from "../../../img/logo.svg";
import {GlobalSearch} from "./globalSearch";
import MenuUser from "./menuUser";

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandSearch: false,
        };
    }

    /**
     * @returns {JSX.Element}
     */
    render() {
        const {t, content: {popup}} = this.props;
        let classMenu = '';

        if (popup.target === 'menu' && popup.isOpen) {
            classMenu += ' nav-open'
        }

        return (
            <nav className={'nav' + classMenu}>
                <p className={'d-md-none nav__title'}>{t('menu_principal')}</p>
                <ul className={'nav__list'}>
                    <NavigationMenuItems/>
                    <GlobalSearch t={t}/>
                </ul>
                <div className={'d-md-none'}>
                    <div className={'nav__user'}>
                        <MenuUser/>
                    </div>

                    <img src={logo} className={'nav__logo'} alt={'POL CRM'}/>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = ({login, content}) => ({
    login, content
});

const mapDispatchToProps = dispatch => {
    return {
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
        setFilterSearch: (data) => dispatch(setFilterSearch(data)),
        resetStoreDatas: (isDisableCache) => dispatch(resetStoreDatas(isDisableCache))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Navigation));

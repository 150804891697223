import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Confirm from "v4/components/ui/Confirm/Confirm";
import {TASK_DELETE} from "v4/data/apiRoutes";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import useDeleteEntity from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/hooks/useDeleteEntity";
import MoveTaskButton from "v4/features/front/moveTask/components/MoveTaskButton/MoveTaskButton";

export default function ProspectOldMailTasksTabRowActions({rowId}) {
    const {t} = usePolTranslation();
    const [isConfirmOpen, toggleConfirmOpen] = useToggle();
    const deleteContact = useDeleteEntity(TASK_DELETE, rowId, toggleConfirmOpen);

    return (
        <>
            <Link to={({pathname}) => `${pathname}/${rowId}`} className="table-link"/>
            <ul className="table-actions">
                <li><MoveTaskButton taskId={rowId}/></li>
                <li data-for="row-action" data-tip={t('edit')}>
                    <Link to={({pathname}) => `${pathname}/edit/${rowId}`}>
                        <PolIcon icon="edit"/>
                    </Link>
                </li>
                <li data-for="row-action" data-tip={t('delete')}>
                    <PolIcon icon="trash" onClick={toggleConfirmOpen}/>
                </li>
            </ul>
            <ReactTooltip id="row-action" effect="solid"/>
            <Confirm title={t('delete')}
                     isOpen={isConfirmOpen}
                     cancelAction={toggleConfirmOpen}
                     confirmAction={deleteContact}>
                <p>{t('popup_validation_suppression')}</p>
            </Confirm>
        </>
    )
}

import ErrorBoundary from "components/Error/errorBoundary";
import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {FormProvider} from "react-hook-form";
import Field from "v4/components/form/Field/Field";
import ShowField from "v4/components/form/ShowField/ShowField";
import Loader from "v4/components/ui/Loader/Loader";
import useQuoteStateForm
    from "v4/features/front/configuredActions/components/ConfiguredActionButton/components/QuoteStateForm/hooks/useQuoteStateForm";
import 'v4/features/front/configuredActions/components/ConfiguredActionButton/components/QuoteStateForm/QuoteStateForm.scss'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import EntityLoop from "v4/layouts/partials/EntityLoop/EntityLoop";
import {getActiveClassName} from "v4/layouts/FormEntityLayout/components/FormEntityLayoutForm/FormEntityLayoutForm";

export default function QuoteStateForm({componentInfos, entityId, ...props}) {
    const {t} = usePolTranslation();
    const {formMethods, state, inputsGroups, handleSubmit} = useQuoteStateForm(props, entityId, componentInfos);

    if (state.isFetchError) return <p>{t('fetch_get_error')}</p>

    if (state.isLoading) return <Loader/>

    return (
        <ErrorBoundary>
            <FormProvider {...formMethods}>
                <form onSubmit={handleSubmit} className="quote-state-form">
                    <div>
                        <EntityLoop inputsGroups={inputsGroups}
                                    groupClassName="quote-state-form__group"
                                    childrenClassName="quote-state-form__field"
                                    forceSpaceGroupName={false}>
                            {({attr, ...input}, showLabel) => {
                                const {
                                    'show-field': showField,
                                    'show-field-value': showFieldValue,
                                    ...restAttr
                                } = attr ?? {};
                                if (showField) {
                                    return <ShowField key={input.key}
                                                      attr={restAttr}
                                                      {...input}
                                                      value={showFieldValue}/>
                                } else {
                                    return (
                                        <div className="input-field-wrapper">
                                            <Field key={input.key}
                                                   name={input.key}
                                                   attr={restAttr}
                                                   specificParams={{menuPosition: 'fixed'}}
                                                   {...input}/>
                                            {showLabel && (
                                                <label htmlFor={input.key}
                                                       className={getActiveClassName(formMethods.watch(input.key) ?? input.value)}>
                                                    {t(input.label)}
                                                </label>
                                            )}
                                        </div>
                                    )
                                }
                            }}
                        </EntityLoop>
                    </div>
                    <button type="submit" disabled={state.submitDisabled}>
                        {t('enregistrer')}
                        {state.isSubmitLoading && <PolIcon icon="circle-notch" className="spinner"/>}
                    </button>
                </form>
            </FormProvider>
        </ErrorBoundary>
    )
}

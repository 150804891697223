import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_QUOTES, TAB_WON_QUOTES} from "v4/data/tabNames";

export default function DashboardQuotesListingTableRowAction({isWon, prospectId, rowId}) {
    const slug = isWon ? TAB_WON_QUOTES : TAB_QUOTES;

    return (
        <>
            <Link to={`${routesBase.baseProspect}/${prospectId}/${slug}/${rowId}`} className="table-link"/>
        </>
    )
}

import React, {useEffect, useState} from "react"
import {withPolTranslation} from "v4/hooks/usePolTranslation";


const PopupInfoConfirm = ({message, onAccept, t, onDecline, children, acceptText}) => {
    let [userChoice, setUserChoice] = useState('accept');

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.key === "Escape") {
                onDecline();
                e.preventDefault()
            }
            if (e.key === "Enter") {
                if(userChoice === 'accept') {
                    onAccept();
                } else {
                    onDecline();
                }
                e.preventDefault()
            }
            if(e.key === 'Tab'){  // eslint-disable-next-line
                userChoice = userChoice === 'refuse' ? 'accept' : 'refuse';
                setUserChoice(userChoice)
                e.preventDefault()
            }
        };
        document.addEventListener('keydown', handleEnter, false);
        return () => {
            document.removeEventListener('keydown', handleEnter, false)
        };
    }, [message]);

    return (
        <>
            <p className={'popup__content__normal-title '}>{message}</p>
            {children}
            <div className={'row popup__buttons-m'}>
                <div className={'col-6'}>
                    <button className={`btn btn-third ${userChoice === 'refuse' ? 'accept' : 'decline'}`}
                            onClick={() => onDecline()}>{t('non')}</button>
                </div>
                <div className={'col-6'}>
                    <button className={`btn btn-third ${userChoice === 'accept' ? 'accept' : 'decline'}`}
                            onClick={() => onAccept()}>{!acceptText ? t('oui') : acceptText}</button>
                </div>
            </div>
        </>
    )
}

export default withPolTranslation(PopupInfoConfirm);

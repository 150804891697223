import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect, useRef, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, Redirect} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import {
    CUSTOMER_EVENT_SERVICE_ADD, CUSTOMER_EVENT_SERVICE_EDIT, CUSTOMER_EVENT_SERVICE_FORM,
    CUSTOMER_EVENT_SERVICE_ITEM
} from "v4/data/apiRoutes";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";
import 'v4/pages/admin/CustomerEventServices/components/CustomerEventServicesForm/CustomerEventServicesForm.scss'
import provider from "react-redux/lib/components/Provider";

export default function CustomerEventServicesForm({customerId, id}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification();
    const [{data: customerEventServiceFormData, isLoading: isLoadingForm, isFinished: isFinishedForm}, fetchCustomerEventServiceForm] = useFetch();
    const [{data: customerEventServiceDataSave, isError: isErrorSave, isLoading: isLoadingSave, isFinished: isFinishedSave}, fetchCustomerEventServiceSave] = useFetch();
    const [customerEventServiceForm, setCustomerEventServiceForm] = useState(null);
    const [customerEventService, setCustomerEventService] = useState({
        eventName: null,
        servicePath: null,
        provider: provider,
        customerId: customerId
    });
    const isEdit = (!!id);
    const [redirect, setRedirect] = useState(null);
    useEffect(() => {
        fetchCustomerEventServiceForm({
            url: id
                ? apiService.generateUrl(CUSTOMER_EVENT_SERVICE_EDIT, {id: id, customerId: customerId})
                : apiService.generateUrl(CUSTOMER_EVENT_SERVICE_FORM, {customerId: customerId}),
        })
    }, [fetchCustomerEventServiceForm, id, customerId, isEdit])
    useEffect(() => {
        if (customerEventServiceFormData && isFinishedForm) {
            const form = customerEventServiceFormData?.schema?.['hydra:member']?.[2];
            setCustomerEventServiceForm(form);
            if (form) {
                setCustomerEventService({
                    eventName: form?.eventName?.value,
                    servicePath: form?.servicePath?.value,
                    provider: form?.provider?.value,
                    customerId: form?.customerId?.value,
                });
            }
        }
    }, [isEdit, setCustomerEventServiceForm, customerEventServiceFormData, isFinishedForm])
    const handleChange = (input) => {
        setCustomerEventService({...customerEventService, [input.target.name]: input.target.value})
    }
    const handleSave = (e) => {
        e.preventDefault()
        fetchCustomerEventServiceSave({
            url: isEdit
                ? apiService.generateUrl(CUSTOMER_EVENT_SERVICE_ITEM, {id: id})
                : apiService.generateUrl(CUSTOMER_EVENT_SERVICE_ADD),
            config: {
                method: 'POST',
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerEventService)
            }
        })
    }
    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('customer_event_service_save_error'))
        }
        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('customer_event_service_save_done'))
            setRedirect(<Redirect to={routesBase.adminCustomerEventServices}/>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerEventServiceDataSave, isErrorSave, isFinishedSave])
    const CardHeaderTools = () => (
        <>
            <Link to={routesBase.adminCustomerEventServices} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )

    const eventNameRef = useRef(null);
    const [eventNames, setEventNames] = useState(() => customerEventService?.eventName?.split(',') ?? []);
    const handleAdd = () => {
        const newEventName = eventNameRef.current.value;
        if (newEventName && !eventNames.includes(newEventName)) {
            setEventNames(prev => [...prev, newEventName]);
            setCustomerEventService(prev => ({...prev, eventName: prev.eventName ? prev.eventName + ',' + newEventName : newEventName}));
            eventNameRef.current.value = '';
        }
    }

    const handleRemove = (eventName) => () => {
        setEventNames(prev => prev.filter(e => e !== eventName));
        setCustomerEventService(prev => ({...prev, eventName: prev.eventName.split(',').filter(e => e !== eventName).join(',')}));
    }

    useEffect(() => {
        if (customerEventService.eventName) {
            setEventNames(customerEventService.eventName.split(','));
        }
    }, [customerEventService.eventName])

    return (
        <AdminCard title={t('manage_customer_event_services')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoadingForm || isLoadingSave || !customerEventServiceForm)
                    ? <Loader message="Chargement..."/>
                    : <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                        <div className="row row-fluid mt-4">
                            <div className={'col-4 '}>
                                <div className={'col-12 field ' + (customerEventService?.eventName ? 'field-written' : null)}>
                                    <input type="text" name="eventName" id="customer_event_service_event_name" className="field__input" ref={eventNameRef}/>
                                    <label className="field__label" htmlFor="customer_event_service_event_name">{t('eventName')}</label>
                                </div>
                            </div>
                            <div className={'col-2 '}>
                                <button type="button" className="btn btn-primary" onClick={handleAdd}>{t('add')}</button>
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (customerEventService?.servicePath ? 'field-written' : null)}>
                                    <input type="text" name="servicePath" id="customer_event_service_service_path" className="field__input"
                                           defaultValue={customerEventService?.servicePath} onChange={handleChange}/>
                                    <label className="field__label" htmlFor="customer_event_service_service_path">{t('servicePath')}</label>
                                </div>
                            </div>
                            <div className="col-5 eventnames-list">
                                <p>{t('eventNames')}</p>
                                <ul>
                                    {
                                        eventNames.length > 0
                                        ? eventNames.map((eventName, index) => (
                                            <li key={index}>{eventName} <span onClick={handleRemove(eventName)}><PolIcon icon="times-circle"/></span></li>
                                            ))
                                        : <li>{t('no_event')}</li>
                                    }
                                </ul>
                            </div>
                            <div className={'col-6 field ' + (customerEventService?.provider ? 'field-written' : null)}>
                                <select name="provider" id="customer_event_service_provider" className="field__input" required={true}
                                        defaultValue={customerEventService?.provider?.value}
                                        onChange={(input) => handleChange(input)}>
                                    {customerEventServiceForm?.provider?.enum?.map((provider, providerKey) => {
                                        return (
                                            <option key={providerKey} value={provider} label={customerEventServiceForm?.provider?.enum_titles[providerKey]}></option>
                                        )
                                    })}
                                </select>
                                <label className="field__label" htmlFor="customer_event_service_provider">{t('provider')}</label>
                            </div>
                        </div>
                    </form>
            }
            {redirect}
        </AdminCard>
    )
}

import ErrorBoundary from "components/Error/errorBoundary";
import React from 'react';
import ReactDOM from 'react-dom';
import {FormProvider} from "react-hook-form";
import Field from "v4/components/form/Field/Field";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import AsideExtendedFiltersHeader
    from "v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/components/AsideExtendedFiltersHeader/AsideExtendedFiltersHeader";
import 'v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/components/AsideFiltersForm/AsideFiltersForm.scss';
import AdminSavedRequest
    from "v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/components/AsideFiltersForm/components/AdminSavedSearch/AdminSavedRequest";
import SavedSearch
    from "v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/components/AsideFiltersForm/components/SavedSearch/SavedSearch";
import ListingHeaderFilters
    from "v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/components/AsideFiltersHeader/AsideFiltersHeader";
import {getLSItem} from "v4/services/localStorage.service";
import {devideSavedSearch, randomId} from "v4/utils";

export default function AsideFiltersForm({
                                             entity,
                                             inputsGroups,
                                             isFiltersExtended,
                                             isSavedSearchVisible = true,
                                             additionnalParamsForSearch,
                                         }) {
    const {t} = usePolTranslation();
    const {
        formMethods,
        tab,
    } = useSearchContext();

    let fieldExtended = null;
    const headerFiltersPortalEl = document.querySelector('.header__filters');

    const handleActiveClassName = (name) => {
        if (
            formMethods.watch(name)?.length > 0
            || formMethods.watch(name)?.begin?.length > 0
            || formMethods.watch(name)?.end?.length > 0
        ) {
            return 'active';
        }
        return '';
    }

    const setFieldExtended = (field) => {
        fieldExtended = field;
    }

    const getDefaultField = () => {
        if (fieldExtended?.key in savedSearch.search && fieldExtended.choices) {
            return Object.keys(fieldExtended.choices).find(key => fieldExtended.choices[key] === savedSearch.search[fieldExtended.key]) ?? '';
        }

        return '';
    }

    const formPrefix = randomId();
    const savedSearch = devideSavedSearch(tab?.searchContent, true);

    const {isSuperAdmin} = getLSItem('user');

    return (
        <>
            <div className="savedSearch">
                <h2>{t('filters_button_text')}</h2>
                {isSavedSearchVisible && (
                    <ErrorBoundary>
                        <SavedSearch entity={entity}/>
                    </ErrorBoundary>
                )}
            </div>
            {isSuperAdmin && (
                <ErrorBoundary>
                    <AdminSavedRequest entity={entity}/>
                </ErrorBoundary>
            )}
            <p className="content-search__title">{t('recherche_criteres')} :</p>
            <FormProvider {...formMethods}>
                <form className="form form__scrollable form-search">
                    <div className="form__scrollable__container">
                        <div className="input-field-group-wrapper">
                            <div className="row align-items-md-stretch">
                                {
                                    Object.entries(inputsGroups?.results ?? []).map((group) => {
                                        const [groupKey, {properties, groupName, attr}] = group;
                                        return (
                                            <div key={groupKey}
                                                 className={isFiltersExtended ? handleGroupClassNameWidth(attr?.['data-vo-width']) : 'col-md-12'}>
                                                <div className="input-field-group">
                                                    <div className="row">
                                                        {groupName && <h3 className="col-md-12">{t(groupName)}</h3>}
                                                        {
                                                            properties.map((input) => {
                                                                if (input.attr?.['data-is-extended']) {
                                                                    setFieldExtended(input);
                                                                    return null;
                                                                }
                                                                if (input.key in savedSearch || input.key in savedSearch.search) return null;

                                                                return (
                                                                    <div key={input.key}
                                                                         className={`input-field-wrapper ${isFiltersExtended ? handleGroupClassNameWidth(input.attr?.['data-vo-width']) : 'col-md-12'}`}>
                                                                        <Field name={input.key}
                                                                               prefix={formPrefix} {...input}/>
                                                                        <label htmlFor={formPrefix + input.key}
                                                                               className={handleActiveClassName(input.key)}>
                                                                            {t(input.label)}
                                                                        </label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </form>
                {fieldExtended &&
                    ReactDOM.createPortal(
                        <AsideExtendedFiltersHeader field={fieldExtended} defaultField={getDefaultField()}/>,
                        document.querySelector('#extended-filters-listing')
                    )
                }
            </FormProvider>
            {headerFiltersPortalEl && ReactDOM.createPortal(<ListingHeaderFilters/>, headerFiltersPortalEl)}
        </>
    )
}

function handleGroupClassNameWidth(width) {
    switch (String(width)) {
        case '100':
            return 'col-md-12';
        case '75':
            return 'col-md-9';
        case '50':
            return 'col-md-6';
        case '33':
            return 'col-md-4';
        case '25':
            return 'col-md-3';
        default:
            return 'col-md-12';
    }
}

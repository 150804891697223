import {PolIcon} from "components/PolIcon/policon";
import exportIllustration from "img/export.svg";
import React, {useMemo} from 'react';
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useIntranetExport
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/components/ProspectWonQuoteTabShow/components/ProspectWonQuoteIntranetButton/hooks/useIntranetExport";
import {getFormattedValue} from "v4/utils";

const howToShowFields = [
    // Nom du compte
    {
        label: 'account_name',
        value: 'prospect.metadata.fullname',
        type: 'string',
    },
    // Titre de l'offre
    {
        label: 'quote_title',
        value: 'quote.name',
        type: 'string',
    },
    // Date de signature
    {
        label: 'expectedSignedAt',
        value: 'quote.expectedSignedAt',
        type: 'date',
    },
    // Numéro bon de commande
    {
        label: 'order_number',
        value: 'quote.sf_field_337',
        type: 'string',
    },
    // Montant
    {
        label: 'excl_taxes_amount',
        value: 'quote.totalExcludingVat',
        type: 'money',
    },
    // Adresse de facturation
    {
        label: 'main_address',
        value: 'prospect.metadata.address.address',
        type: 'string',
    },
    // Code postal
    {
        label: 'postal_code',
        value: 'prospect.metadata.address.postalCode',
        type: 'string',
    },
    // Ville
    {
        label: 'city',
        value: 'prospect.metadata.address.city',
        type: 'string',
    },
    // Adresse mail
    {
        label: 'email',
        value: 'prospect.contact_email',
        type: 'string',
    },
]

export default function QuoteIntranetPopupContent({allowExport}) {
    const {t} = usePolTranslation();
    const {quote, prospect} = useProspectPageContext();
    const [isLoading, contractNumber] = useIntranetExport(quote.metadata.id, true, allowExport);

    const fields = useMemo(() => getFieldsToShow(howToShowFields, {quote, prospect}), [quote, prospect]);

    return (
        <div className="intranet-export-popup">
            <div className="intranet-export-popup__illustration">
                <img width="295" height="345" src={exportIllustration} alt="export illustration"/>
            </div>
            <div className="intranet-export-popup__fields">
                {fields.map(({label, value}) => (
                    <div key={label} className="intranet-export-popup__field">
                        <span className="intranet-export-popup__field-label">{t(label)} :</span>
                        <span className="intranet-export-popup__field-value">{value}</span>
                    </div>
                ))}
                <div className="intranet-export-popup__field">
                    <span className="intranet-export-popup__field-label">{t('contract_number_potentially')} :</span>
                    <span className="intranet-export-popup__field-value">
                        {isLoading
                            ? <PolIcon icon="circle-notch" className="spinner"/>
                            : contractNumber
                        }
                    </span>
                </div>
            </div>
        </div>
    )
}

function getValue(value, allFields) {
    const splittedKeys = value.split('.');

    return splittedKeys.reduce((acc, key) => {
        return acc[key];
    }, allFields)
}

function getFieldsToShow(howToShowFields, allFields) {
    return howToShowFields.map(({label, value, type}) => {
        return {
            label,
            value: getFormattedValue(type, getValue(value, allFields)),
        }
    })
}

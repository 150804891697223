import {getLSItem, setLSItem} from "v4/services/localStorage.service";
import {formatForView} from "./viewTypeFormatter";
import UserService from "../api/user/user";

/**
 * Return viewOrders from localstorage
 * @param {string} entity Prospect/Contact ...
 * @param {string} type form/search/list
 * @param {string|null} group individual/business/null
 * @returns {null|*}
 */
export const getViewOrdersOfEntityAndType = (entity, type, group = null) => {
    const user = getLSItem('user');

    if (!user || !user.orders || !user.orders['hydra:member'] || !user.orders['hydra:member'][0]) {
        return null;
    }

    if (!entity || !type) {
        return null;
    }

    const orders = user.orders['hydra:member'][0];

    if (!orders[entity] || !orders[entity][type]) {
        return null;
    }

    let results = orders[entity][type];

    // Cas 1 : Nous rechrchons uniquement les "groupes" d'items de ViewOrders qui ont le type :
    // - correspondant à l'identifiant de l'onglet
    // - correspondant au nom de l'entité (task, quote, ...)
    // - correspondant au type réel de l'entité (prospect => business ou individual)
    if (group && ['form', 'list', 'search'].includes(type)) {
        results = results.filter((result) => result && result.type === group)
    } else {
        // Probablement inutile maintenant, mais sait-on jamais
        if (['list'].includes(type)) {
            // Attention ne fonctionne que sur Quote && Tasks
            results = results.filter((result) => result && result.type === entity.toLowerCase())
        }

        // Cas 2:
        // On recherche les "groupes" d'items de ViewOrders qui sont "search" ou null
        if (['search'].includes(type)) {
            // Attention ne fonctionne que sur Quote && Tasks
            results = results.filter((result) => result?.type === 'search' || null === result?.type)
        }
    }

    // Si toujours pas ok, un ID comporte potentiellement plus de 15 caractères
    // du coup on va filtrer par le type inférieur à moins de caractères
    if (results.length === 0) {
        if (['list'].includes(type)) {
            results = orders[entity][type];
            results = results.filter((result) => result && result?.type?.length < 20)
        }
    }

    if ('list' === type && 0 === results.length) {
        return orders[entity][type].slice(0, 1); // Force a n'en retourner qu'un seul
    }

    return results;
}

/**
 * Persist ViewOrders in localStorage and in the Database
 * @param {string} entity Prospect/Contact ...
 * @param {string} type form/search/list
 * @param {object} viewOrders to save
 * @param {function} postService dispatcher
 * @param {string} prospectType individual/business/null
 * @param callback callback function
 * @param {string|null} subType business/individual/task/contact/quote/customeTab_id
 * @returns {null}
 */
export const persistViewOrdersInfo = (entity, type, viewOrders, postService, prospectType = null, callback = null, subType = null) => {
    const user = getLSItem('user');

    if (!user
        || !user.orders
        || !user.orders['hydra:member']
        || !user.orders['hydra:member'][0]
        || !user.orders['hydra:member'][0][entity]
    ) {
        return null;
    }

    if (subType && 'list' === type && !['individual', 'business', 'prospect', 'unit_mail', 'mail_type', 'contact', 'task', 'quote'].includes(subType)) {
        if (subType !== viewOrders[0].type) {
            viewOrders[0].id = null;
            viewOrders[0].type = subType;
        }
        viewOrders[0].fields.map((field) => {
            field.id = null;

            return field;
        })
    }

    viewOrders.forEach(obj => {
        if (!user.orders['hydra:member'][0][entity][type].find(obj2 => obj2.id === obj.id)) {
            user.orders['hydra:member'][0][entity][type].push(obj);
        }
    });
    viewOrders = user.orders['hydra:member'][0][entity][type].map(obj => viewOrders.find(o => o.id === obj.id) || obj);
    user.orders['hydra:member'][0][entity][type] = viewOrders;

    const request = {
        [entity]: {
            [type]: viewOrders,
            id: type === 'list' ?
                user.orders['hydra:member'][0][entity].id
                : user.orders['hydra:member'][0][entity].idViewOrderCustomer,
        }
    }

    try {
        setLSItem('user', user);
        UserService.removeCache();
    } catch (e) {
        return;
    }

    postService({
        method: 'PUT',
        loader: 'viewOrderUpdate',
        url: '/api/view_orders_info',
        data: request,
        type: 'viewOrder',
        callback: (isError) => callback ? callback(isError) : null,
        noReset: true
    })
}

/**
 * Set Listing ViewOrders from a toggles array
 * @param {string} entity
 * @param {Object} columnsToggles
 * @param {string|null} subType
 * @returns {array}
 */
export const toggleViewOrdersListing = (entity, columnsToggles, subType = null) => {
    const viewOrderInfosListing = getViewOrdersOfEntityAndType(entity, 'list', subType);

    // Ajoute les nouveaux toggles
    Object
        .keys(columnsToggles.toggles)
        .filter((keyToggle) => columnsToggles.toggles[keyToggle])
        .forEach((key) => {
            const params = searchFieldKeyInViewOrders(viewOrderInfosListing, key);
            let column = columnsToggles.columns.find((column) => column.dataField === key)

            if (column) {
                if (!params && key !== 'actions') {
                    viewOrderInfosListing[0].fields.push({
                        label: column.text,
                        width: 100,
                        order: column.order,
                        key: key
                    })
                } else if (params) {
                    // On met à jour les positions
                    viewOrderInfosListing[0].fields[params.index] = {
                        ...viewOrderInfosListing[0].fields[params.index],
                        order: column.order,
                    }

                }
            }
        })

    // Remove les fields qui ne sont pas toggle
    Object
        .keys(columnsToggles.toggles)
        .filter((keyToggle) => !columnsToggles.toggles[keyToggle])
        .forEach((key) => {
            const params = searchFieldKeyInViewOrders(viewOrderInfosListing, key);
            if (params && key !== 'actions') {
                viewOrderInfosListing[0].fields.splice(params.index, 1);
            }
        })
    return viewOrderInfosListing;
}

/**
 * cherche dans les view order s il trouve un label
 * @param {string|null} entity
 * @param {string} key
 * @returns {string|null}
 */
export const getLabelOfFieldInViewOrder = (entity, key) => {

    if (entity === '' || entity === null) {
        return null;
    }

    const viewOrderInfos = getViewOrdersOfEntityAndType(entity, 'form');
    let label = null;

    if (viewOrderInfos) {
        viewOrderInfos.forEach(function (group) {
            if (group.fields) {
                group.fields.forEach(function (item) {
                    if (item.id && item.id === key && item.label) {
                        label = item.label;
                        return;
                    }
                    if (item.id && item.key === key && item.label) {
                        label = item.label;
                        return;
                    }
                });
            }
            if (label !== null) {
                return;
            }
        });
    }
    return label;
}

/**
 * Retourne les indexes (groupKey, key) pour modifier facilement les viewOrders
 * @param {array} viewOrdersInfo
 * @param {string} key
 * @returns {*}
 */
export const searchFieldKeyInViewOrders = (viewOrdersInfo, key) => {
    let params = {};
    viewOrdersInfo.forEach((group, groupKey) => {
        if (group.fields) {
            const index = group.fields.findIndex((item) => item?.key === key);
            if (index !== -1) {
                params = {
                    groupKey,
                    index
                };
            }
        }
    })

    return Object.keys(params).length ? params : null;
}

export const updateDatatableOrders = (info, columnsToggles, newColumns, newDatas) => {
    const newViewOrders = toggleViewOrdersListing(info.order_entity, columnsToggles, info.subType)
    const formViewOrders = getViewOrdersOfEntityAndType(info.order_entity, 'form');
    const colsChanged = [];

    newViewOrders.forEach((viewOrder, index) => {
        viewOrder.fields.forEach((field, fieldIndex) => {
            let params = searchFieldKeyInViewOrders(formViewOrders, field.key);
            if (!params) {
                params = searchFieldKeyInViewOrders(formViewOrders, field.key.replace('.', '_'));
            }
            if (!params) {
                params = searchFieldKeyInViewOrders(formViewOrders, field.key.replace('_', '.'));
            }
            if (!params) {
                params = searchFieldKeyInViewOrders(formViewOrders, field.key.replace('specificFields.', ''));
            }
            if (params && formViewOrders[params.groupKey].fields[params.index].valueType) {
                newViewOrders[index].fields[fieldIndex].valueType = formViewOrders[params.groupKey].fields[params.index].valueType;
                const columnIndex = newColumns.findIndex(item => item.dataField === field.key)
                if (columnIndex !== -1) {
                    newColumns[columnIndex] = {
                        ...newColumns[columnIndex],
                        valueType: formViewOrders[params.groupKey].fields[params.index].valueType
                    }
                    colsChanged.push(newColumns[columnIndex]);
                }
            }
        })
    })

    newDatas.forEach((item, index) => {
        colsChanged.forEach((col) => {
            if (col.dataField && col.valueType) {
                const keys = col.dataField.split('.');
                let value = keys.length ? undefined : newDatas[index][col.dataField];
                let lastArray = [];

                keys.forEach((key) => {
                    if (null !== value && typeof value === 'object') {
                        lastArray = value
                        value = value[key]
                        return;
                    }
                    value = newDatas[index][key]
                    lastArray = newDatas[index][key]
                })
                if (keys.length > 1 && lastArray) {
                    lastArray[keys[keys.length - 1]] = formatForView(value, col.valueType)
                } else {
                    newDatas[index][col.dataField] = formatForView(value, col.valueType);
                }
            }
        })
    });
    return newViewOrders;
}

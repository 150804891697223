import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import Draggable from "react-draggable";
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_OPEN_TASKS} from "v4/data/tabNames";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ProspectsListingMapsDraggable({selectedProspect, removeSelectedProspect, offsetParent, bounds}) {
    const {t} = usePolTranslation();

    return (
        <Draggable handle=".prospects-listing-maps__prospect__header"
                   offsetParent={offsetParent}
                   bounds={bounds}>
            <div className="prospects-listing-maps__prospect">
                <div className="prospects-listing-maps__prospect__header">
                    <PolIcon
                        icon={getProspectValue(selectedProspect, 'type') === 'business' ? "business" : 'store-alt'}/>
                    <span>{getProspectValue(selectedProspect, 'fullname')}</span>
                    <PolIcon className="prospects-listing-maps__prospect__header__close" icon="close"
                             onClick={removeSelectedProspect}/>
                </div>
                <div className="prospects-listing-maps__prospect__infos">
                    <p>
                        {t('type')} : <span>{t(getProspectValue(selectedProspect, 'type'))}</span>
                    </p>
                    <p>
                        {t('email')} : <span><a
                        href={`mailto:${getProspectValue(selectedProspect, 'contact_email')}`}>
                                        {getProspectValue(selectedProspect, 'contact_email') ?? t('undefined')}
                                    </a></span>
                    </p>
                </div>
                {getProspectValue(selectedProspect, 'address') && (
                    <div className="prospects-listing-maps__prospect__address">
                        <p>
                                    <span>
                                        <b>
                                            {getProspectValue(selectedProspect, 'address')?.address}
                                            <br/>
                                            {getProspectValue(selectedProspect, 'address')?.postalCode} {getProspectValue(selectedProspect, 'address')?.city}
                                        </b>
                                    </span>
                        </p>
                    </div>
                )}
                <div className="prospects-listing-maps__prospect__phone">
                    {getProspectValue(selectedProspect, 'phone') && (
                        <>
                            <PolIcon icon="phone"/>
                            <p>
                                <span>{getProspectValue(selectedProspect, 'phone')}</span>
                            </p>
                        </>
                    )}
                    <Link to={`${routesBase.baseProspect}/${selectedProspect?.metadata?.id}/${TAB_OPEN_TASKS}`}
                          className="prospects-listing-maps__prospect__go" title={t('see-prospect')}>
                        <PolIcon icon="arrow-right"/>
                    </Link>
                </div>
            </div>
        </Draggable>
    )
}

function getProspectValue(prospect, desiredKey) {
    if (!prospect) return null;

    const {extraFields, metadata, fields} = prospect;

    let value = extraFields?.[desiredKey];

    if (!value) {
        value = metadata?.[desiredKey] ?? null;
    }

    if (!value) {
        value = fields?.find(({key}) => key === desiredKey)?.value ?? null;
    }

    return value;
}

import React, {Component} from 'react'
import {connect} from "react-redux";
import {Prompt} from "react-router";
import {Redirect} from "react-router-dom";
import {substitutionInputs} from "../../datas/Form";
import {PostService, PostSearchService} from "../../api/datas/post";
import {dataFilter, fetchHandler, manageError} from "../../api/datas/datas";
import ViewForm from "./index";
import {setExpandInfo, setLoader, setPopup} from "../../actions/content";
import UserService from "../../api/user/user";
import {handleFormData} from '../../reducers/form'
import ErrorBoundary from "../Error/errorBoundary";
import {setFilterSearch, setFormEndRedirect, setPreFillingObject} from "../../actions/form";
import AutoCompleteFetch from "../../api/autocomplete/autocomplete";
import Loading from "../Loading/Loading";
import {PolIcon} from "../PolIcon/policon";
import FillSelectFetch from "../../api/fillSelect/fillSelect";
import {getApiUrl} from "../../routes";
import * as moment from 'moment';
import {fillForm} from "../../utils/formUtils";
import PopupInfo from "../Popup/_popup_info";
import {debounce} from "../../utils/debounce";
import {withPolTranslation} from "../../v4/hooks/usePolTranslation";

window.beforeUnloadHandler = null;
window.formCache = {};

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: substitutionInputs,
            info: {},
            loader: true,
            redirect: null,
            isSubmitted: false,
            searchRequestNumber: 0,
            doIsSubmitAndCreate: false,
            doFormIsModified: false,
            filesSizeExceeded: false,
            initialDataForm: {},
            quoteLinesValues: null,
        }
    }


    findByKey = (key) => {
        let eltTargetedBy = {};
        const {inputs} = this.state;
        inputs.some((input, index) => {
            if (input.fields) {
                input.fields.forEach((item, itemKey) => {
                    if (item.key === key) {
                        eltTargetedBy = {
                            group: index,
                            item: itemKey
                        };
                    }
                });
            }
            return eltTargetedBy && eltTargetedBy.group;
        });

        return eltTargetedBy;
    };

    updateInput = (groupKey, itemKey, attributes) => {
        const {inputs} = this.state;
        const groupData = inputs[groupKey].fields;

        groupData[itemKey]['input'] = {
            ...groupData[itemKey]['input'],
            fileNames: attributes.fileNames,
            downloadUrls: attributes.downloadUrls,
            deleteUrls: attributes.deleteUrls
        }

        this.setState({
            inputs: inputs,
        });
    }

    setSelectIsFocused = (keyItem, value) => {
        const eltTargetedBy = this.findByKey(keyItem);
        let {inputs} = this.state;

        if (eltTargetedBy && 'group' in eltTargetedBy && 'item' in eltTargetedBy && inputs[eltTargetedBy.group]
            && inputs[eltTargetedBy.group].fields && inputs[eltTargetedBy.group].fields[eltTargetedBy.item]
            && inputs[eltTargetedBy.group].fields[eltTargetedBy.item].input) {
            inputs[eltTargetedBy.group].fields[eltTargetedBy.item].input.isOpen = value;
        }

        this.setState({inputs: inputs});
    }

    handleChange = (groupKey, itemKey, value, oldValue, directValidation = false) => {
        const {type, removeSearch, activeFilters, changeKey, setCanChangeWindow, reInitAllIds, t, customTabSearch} = this.props;

        let {inputs} = this.state;
        const groupData = inputs[groupKey].fields;

        if (groupData[itemKey].isReadOnly) {

            groupData[itemKey].input.error = t('field_is_read_only');

            this.setState({
                inputs: inputs
            });
            return;
        }

        let element = groupData[itemKey]['input'];
        if (groupData[itemKey].input
            && groupData[itemKey].input.keyItem
            && customTabSearch
            && Object.keys(customTabSearch).includes(groupData[itemKey].input.keyItem)) {
            return;
        }

        if (setCanChangeWindow) {
            setCanChangeWindow(false)
        }
        //Changement de statut du formulaire suite à une entrée sur un des champs
        this.setState({
            doFormIsModified: true,
        });

        if (element.attr && (groupData[itemKey].input.attr.type === 'date_compare' || groupData[itemKey].input.attr.type === 'number_compare')) {
            element.value = value;
            if (changeKey) {
                changeKey(element.keyItem, value)
            }
            // Si valeur vide il faut supprimer le filtre
            if (removeSearch && value === null) {
                removeSearch(groupData[itemKey].key);
                element.value = undefined;
            }
            if ((removeSearch && groupData[itemKey] && groupData[itemKey].key && (value && value.begin === '' && value.end === '')) || (removeSearch && !value)) {
                removeSearch(groupData[itemKey].key);
                element.value = undefined;
            }
        } else if (element.type === 'boolean') {
            const newValue = !element.value;
            element.value = newValue;

            if (activeFilters && activeFilters[groupData[itemKey].key]) {
                activeFilters[groupData[itemKey].key] = {
                    ...activeFilters[groupData[itemKey].key],
                    value: newValue
                };
            }

            if (changeKey) {
                changeKey(element.keyItem, newValue)
            }

        } else if (value !== oldValue) {
            element.error = null;

            if (element.attr && element.attr['input-type'] && element.type === 'array') {
                if (!Array.isArray(element.value)) {
                    element.value = [];
                }
                const index = element.value.indexOf(value);

                if (index !== -1) {
                    element.value.splice(index, 1);
                } else {
                    if (Array.isArray(value) && element.attr && element.attr['data-type'] === 'specific-fields') {
                        element.value = value;
                    } else {
                        element.value = [...element.value, value];
                    }
                }
            } else {
                element.value = value;
                if (element && element.attr
                    && element.attr.type
                    && element.attr.type === "autocomplete_select"
                    && Array.isArray(element.value)) {
                    if (element.value[0] && element.value[0].label) {
                        element.options = element.value;
                    } else {
                        element.options = element.options.filter(val => {
                            return element.value.indexOf(val.value) !== -1;
                        })
                    }
                }
            }
            if (changeKey) {
                changeKey(element.keyItem, element.value)
            }

            if (activeFilters && activeFilters[groupData[itemKey].key]) {
                activeFilters[groupData[itemKey].key] = {
                    ...activeFilters[groupData[itemKey].key],
                    value: value
                };
            }
            if ((removeSearch && groupData[itemKey] && groupData[itemKey].key && value === '') || (removeSearch && !value)) {
                removeSearch(groupData[itemKey].key);
            }

            if (element.attr && element.attr['data-autocomplete-type'] && typeof value === 'object') {
                const autoCompleteType = element.attr['data-autocomplete-type'];
                if (autoCompleteType === 'zip') {
                    element.value = value.code
                    const eltTargetedBy = this.findByKey(element.parentElement + '_' + element.attr['data-target']);

                    inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'].value = value.city;
                }
                if (autoCompleteType === 'city') {
                    element.value = value.city
                    const eltTargetedBy = this.findByKey(element.parentElement + '_' + element.attr['data-target']);

                    inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'].value = value.code;
                }
            }

            if (element.attr &&
                element.attr.type === 'autocomplete'
            ) {
                if (value.length > 2 || ('spam-request-security' in element.attr && element.attr['spam-request-security'] === false)) {
                    element = this.getAutoCompleteData(element, value, groupKey, itemKey)
                } else {
                    element.dataAutocomplete = [];
                }
            }
            if (element.attr &&
                element.attr.type === 'fill-select'
            ) {
                if (value) {
                    inputs = this.handleFillSelect(groupKey, itemKey)
                }
            }
            if (element.attr &&
                element.attr.type === 'fill-other-field'
            ) {
                if (value) {
                    element.onBlur = () => this.handleFillOtherField(groupKey, itemKey)
                }
            }

            // Si input doit cacher d'autres field en fonction de sa valeur
            inputs = this.autoHideFields(groupKey, itemKey, false)
        }

        if (element.attr && element.attr['data-same-for'] && type !== 'form_search') {
            const eltTargetedBy = this.findByKey(element.attr['data-same-for']);
            if (eltTargetedBy && eltTargetedBy.item && eltTargetedBy.group && inputs[eltTargetedBy.group] &&
                inputs[eltTargetedBy.group].fields && inputs[eltTargetedBy.group].fields[eltTargetedBy.item] &&
                inputs[eltTargetedBy.group].fields[eltTargetedBy.item].input && inputs[eltTargetedBy.group].fields[eltTargetedBy.item].input.value === null) {
                inputs[eltTargetedBy.group].fields[eltTargetedBy.item].input.value = element.value;
            }
        }

        this.setState({
            inputs: inputs,
            isSubmitted: false
        });
        if (type === 'form_search') {

            let searchRequestNumber = this.state.searchRequestNumber;
            searchRequestNumber++;
            this.setState({
                searchRequestNumber: searchRequestNumber
            });
            debounce(() => {
                if (searchRequestNumber === this.state.searchRequestNumber) {
                    this.handleSubmit(null, type, directValidation);
                    if (reInitAllIds) {
                        reInitAllIds();
                    }
                }
            }, 500);
        }
    };

    setQuoteLinesValues = (values) => {
        this.setState({quoteLinesValues: values});
    }

    handleFillOtherField = (groupKey, itemKey) => {
        let {inputs} = this.state;
        const groupData = inputs[groupKey].fields;

        if (groupData[itemKey]['input'].attr && groupData[itemKey]['input'].attr['data-target']) {
            const dataTarget = groupData[itemKey]['input'].attr['data-target'];
            const value = groupData[itemKey]['input'].value;
            const eltTargetedBy = this.findByKey(dataTarget);

            if (!inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'].value) {
                inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'].value = value
            }

            this.setState({
                inputs: inputs,
            })
        }
    }

    handleFillSelect = (groupKey, itemKey) => {
        let {manageError, t} = this.props;
        let {inputs} = this.state;
        const groupData = inputs[groupKey].fields;

        if (groupData[itemKey]['input'].attr && groupData[itemKey]['input'].attr['data-collection-url']
            && groupData[itemKey]['input'].attr['data-targets']) {
            const collectionUrl = groupData[itemKey]['input'].attr['data-collection-url'];
            const dataTargets = groupData[itemKey]['input'].attr['data-targets'].split(';');
            const value = groupData[itemKey]['input'].value;

            this.setState({
                loader: true
            })

            FillSelectFetch(getApiUrl(collectionUrl, 'id').replace('{id}', value),
                dataTargets, false, manageError, t).then((response) => {
                Object.keys(response).forEach((key) => {
                    const eltTargetedBy = this.findByKey(key);
                    if (key === 'customerFiles') {
                        const downloadUrls = response[key]['hydra:member'].map((item) => {
                            return item.downloadLink;
                        }).join(';');
                        const deleteUrls = response[key]['hydra:member'].map((item) => {
                            return item.deleteLink;
                        }).join(';');
                        const fileNames = response[key]['hydra:member'].map((item) => {
                            return item.fileLabel;
                        }).join(';');
                        inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'] = {
                            ...inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'],
                            downloadUrls: downloadUrls,
                            deleteUrls: deleteUrls,
                            fileNames: fileNames,
                            doNotDeleteFiles: true
                        };
                    } else {
                        inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'].value = response[key];

                    }
                })
                this.setState({
                    inputs: inputs,
                }, () => {
                    this.setState({
                        loader: false
                    })
                });
            })

        }
    }

    autoHideFields = (groupKey, itemKey, setState = true) => {
        let {inputs} = this.state;
        const groupData = inputs[groupKey].fields;

        if (groupData[itemKey]['input'].attr && groupData[itemKey]['input'].attr['data-hide-fields']) {
            const dataChoicesAttr = groupData[itemKey]['input'].attr['data-choices-attr'].split(';')
            const dataTargets = groupData[itemKey]['input'].attr['data-targets'].split(';')
            if (Array.isArray(groupData[itemKey]['input'].enum)) {
                const index = groupData[itemKey]['input'].enum.indexOf(groupData[itemKey]['input'].value
                && typeof groupData[itemKey]['input'].value === 'object' ? groupData[itemKey]['input'].value.value : groupData[itemKey]['input'].value);
                dataTargets.forEach((key) => {
                    const eltTargetedBy = this.findByKey(key);
                    inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'].hidden = (index !== -1 && dataChoicesAttr[index] === '0') || (index === -1);
                })
            }
        }

        if (setState) {
            this.setState({
                inputs: inputs,
            });
        }

        return inputs;
    }

    getAutoCompleteData = (input, value, groupKey, itemKey) => {
        let {inputs} = this.state;
        const {manageError, t} = this.props;
        const url = input.attr ? input.attr['data-get-url'] : null;
        const field = input.attr ? input.attr['data-field'] : null;
        const autoCompleteType = input.attr ? input.attr['data-autocomplete-type'] : null;
        const isAbsoluteUrl = input.attr ? input.attr['data-absolute-url'] : null;
        const autoCompleteCityKeys = ['zip', 'city'];

        // Si Pays != France on autocomplète pas les clé zip et city
        if (autoCompleteCityKeys.indexOf(autoCompleteType) !== -1) {
            const eltTargetedBy = this.findByKey(
                `${inputs[groupKey].fields[itemKey]['input'].parentElement}_country`);
            if (inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'].value !== 'France'
                && inputs[eltTargetedBy.group].fields[eltTargetedBy.item]['input'].value !== '') {
                return input;
            }
        }

        AutoCompleteFetch(url, field, value, isAbsoluteUrl, manageError, t).then(
            response => {
                if (response) {
                    inputs[groupKey].fields[itemKey]['input'].dataAutocomplete = autoCompleteCityKeys.indexOf(autoCompleteType) !== -1 ? response.cities : response;
                    inputs[groupKey].fields[itemKey]['input'].autoCompleteType = autoCompleteType;

                    this.setState({
                        inputs: inputs
                    })
                }
            }
        );

        return input;
    };

    handleRequiredValidation = (inputs) => {
        const {t} = this.props;
        let hasEmptyField = false;

        inputs.forEach((group, groupIndex) => {
            if (Array.isArray(group.fields)) {
                group.fields.forEach((input, key) => {
                    if (input.input && (input.isRequired === true || input.input.required === true)) {
                        if (input.input.value === null || input.input.value === undefined || !input.input.value.length) {
                            hasEmptyField = true
                            inputs[groupIndex].fields[key].input.error = t('field_must_be_filled');
                        }
                    }
                })
            }
        })
        if (hasEmptyField) {
            this.setState({
                inputs: inputs,
                doFormIsModified: true
            })
        }
        return hasEmptyField;
    }

    handleSubmit = (event, type, directValidation = false, delay = 2000) => {
        if (event) {
            event.preventDefault();
        }

        const {inputs, doIsSubmitAndCreate} = this.state;
        const {
            supGroup,
            postService,
            postSearchService,
            info: {
                prospectIds,
                taskIds,
                quoteIds,
                contactIds,
                productIds,
                isReverse = false,
                additionalSearch,
                loaderDatatable,
                page,
                searchText,
                sortField,
                sortOrder,
                itemsPerPage
            },
            t,
            setPopup,
            setPreFillingObject,
            isAllIds,
            setIsAllIds,
            setSelectedRows
        } = this.props;
        const {
            info: {
                order_entity,
                order_type,
                submitUrl,
                name,
                itemId,
                putUrl,
                setDataInfo,
                submitLoader = '',
                prospectId = null
            }
        } = this.state;
        let outputs = {};
        const keyGroupBy = 'parentElement';

        if (type !== 'form_search' && this.handleRequiredValidation(inputs)) {
            setPopup({
                isOpen: true,
                content: (
                    <PopupInfo onAccept={() => {
                        setPopup({})
                    }} message={t('form_has_field_required_empty')}/>
                )
            })
            return;
        }

        if (!this.controlQuoteLineValues()) {
            return;
        }

        this.setState({
            isSubmitted: true
        });

        const map = new Map(Array.from(Object.values(inputs), obj => [obj[keyGroupBy], []]));

        Object.values(inputs).forEach(value => {
            map.get(value[keyGroupBy]).push(value);
        });

        const reducer = (items, item) => {
            const value = item.value ? item.value : item;
            if (value !== undefined && value !== null && value !== '') {
                items.push(item.value ? item.value : item)
            }

            return items;
        };

        const formatField = (input) => {
            if (input.type === 'integer') {
                return parseInt(input.value) ? parseInt(input.value) : undefined
            }
            if (input.type === 'boolean') {
                return !!input.value;
            }

            if (input.value) {
                if (Array.isArray(input.value)) {
                    return input.value.reduce((items, item) => reducer(items, item), []);
                }
                if (typeof input.value === 'object' && input.value.value) {
                    return input.value.value
                }
                return input.value;
            }

            return "";
        };

        map.forEach((group, index) => {
            if (!index) {
                group.forEach((item) => {
                    if (item.fields) {
                        item.fields.forEach((order, keyOrder) => {
                            const {input} = order;

                            if (input) {
                                if (input.attr && input.attr['data-if-empty'] && input.attr['data-if-empty'] === 'date' && input.value === null) {
                                    const now = new Date();
                                    input.value = moment(now).format('DD-MM-YYYY HH:mm');
                                }

                                if (input.attr && input.attr['data-same-by'] && input.value === null) {
                                    let target = this.findByKey(input.attr['data-same-by']);
                                    if (target) {
                                        let element = inputs[target.group].fields[target.item].input;
                                        if (element.value === null && element.attr && element.attr['data-if-empty'] && element.attr['data-if-empty'] === 'date') {
                                            const now = new Date();
                                            element.value = moment(now).format('DD-MM-YYYY HH:mm');
                                        }
                                        input.value = element.value;
                                    }
                                }

                                if (input.attr && (input.attr.type === "date_compare" || input.attr.type === "number_compare")) {
                                    outputs = {
                                        ...outputs,
                                        [input.keyItem]: input.value
                                    };
                                    if (typeof input.value === 'string') {
                                        delete outputs[input.keyItem];
                                    }
                                } else if (input.parentElement && input.parentElement.length) {
                                    outputs = {
                                        ...outputs,
                                        [input.parentElement]: {
                                            ...outputs[input.parentElement],
                                            [input.keyItem]: formatField(input)
                                        }
                                    };
                                } else {
                                    if (input.doNotDeleteFiles) {
                                        outputs = {
                                            ...outputs,
                                            attachments: input.downloadUrls,
                                            attachmentsLabels: input.fileNames
                                        };
                                    }
                                    outputs = {
                                        ...outputs,
                                        [input.keyItem]: formatField(input)
                                    };
                                }
                            }
                        });

                        if (itemId) {
                            outputs = {
                                ...outputs,
                                id: itemId,
                            }
                        }
                    }
                });
            } else {
                group.forEach((item) => {
                    outputs = {
                        ...outputs,
                        [index]: {
                            ...outputs[index],
                            [item.keyItem]: formatField(item)
                        }
                    };

                    if (item.parentId) {
                        outputs = {
                            ...outputs,
                            [index]: {
                                ...outputs[index],
                                id: item.parentId,
                                '@id': '/api/' + (item.searchItem ? item.searchItem : item.parentElement) + '/' + item.parentId,
                            }
                        }
                    }
                });
            }
        });

        if (type === 'form_search') {
            let searchRequestNumber = this.state.searchRequestNumber;
            searchRequestNumber++;
            this.setState({
                searchRequestNumber: searchRequestNumber
            });

            debounce(() => {
                if (searchRequestNumber === this.state.searchRequestNumber || directValidation) {
                    Object.keys(outputs).forEach(key => {
                        if (outputs[key] || outputs[key] !== '') {
                            let value = outputs[key];
                            const infoFilter = this.findByKey(key);
                            if (inputs[infoFilter.group]) {
                                const input = inputs[infoFilter.group].fields[infoFilter.item]['input'];

                                if (input.enum_titles) {
                                    if (input.type === 'array') {
                                        value = input.value;
                                    } else {
                                        const index = input.enum.findIndex(item => item === value);
                                        value = input.enum_titles[index];
                                    }
                                }
                            }
                        }
                    });

                    if ((prospectIds && prospectIds.length)
                        || (contactIds && contactIds.length)
                        || (quoteIds && quoteIds.length)
                        || (taskIds && taskIds.length)
                        || (productIds && productIds.length)) {
                        outputs = {
                            ...outputs,
                            prospectIds: prospectIds,
                            contactIds: contactIds,
                            quoteIds: quoteIds,
                            taskIds: taskIds,
                            productIds: productIds,
                            isReverse
                        }
                    }

                    if (additionalSearch && Object.keys(additionalSearch).length) {
                        outputs = {
                            ...outputs,
                            ...additionalSearch
                        }
                    }

                    outputs = {
                        ...outputs
                    }

                    // Ajout des values de la map (distance, lat, lng)
                    if (supGroup && supGroup.fields && supGroup.fields.length) {
                        supGroup.fields.forEach((item) => {
                            if (item.input) {
                                outputs = {
                                    ...outputs,
                                    [item.fieldKey]: item.input.value
                                }
                            }
                        })
                    }

                    postSearchService({
                        loader: submitLoader,
                        url: submitUrl,
                        submittedData: outputs,
                        itemId: itemId,
                        putUrl: putUrl,
                        name: name,
                        inputs: inputs,
                        loaderDatatable: loaderDatatable,
                        order_entity: order_entity,
                        order_type: order_type,
                        isAllIds: isAllIds,
                        setIsAllIds: setIsAllIds,
                        setSelectedRows: setSelectedRows,
                        searchInformations: {
                            page,
                            searchText,
                            sortField,
                            sortOrder,
                            itemsPerPage,
                        }
                    })
                }
            }, delay)
        } else {
            if (prospectId) {
                outputs = {
                    ...outputs,
                    prospect: prospectId
                }
            }

            if (Object.keys(outputs).some(key => key === 'quoteLines')) {
                if (this.state?.quoteLinesValues) {
                    let quoteLinesValues = JSON.parse(JSON.stringify(this.state.quoteLinesValues.quoteLines));

                    quoteLinesValues.forEach(quoteLine => {

                        quoteLine.quoteLineInfo = {
                            product: quoteLine?.product_id ?? null
                        }

                        /* We have to delete these non asked fields by middleware, otherwise it throws an error */
                        delete quoteLine.vat;
                        delete quoteLine.product_id;
                        delete quoteLine.product_img;
                        delete quoteLine.product_reference;
                        delete quoteLine.product_category;
                    })

                    outputs.quoteLines = quoteLinesValues;
                } else {
                    delete outputs.quoteLines;
                }
            }

            postService({
                loader: 'form',
                url: submitUrl,
                submittedData: outputs,
                itemId: itemId,
                prospectId: prospectId ? prospectId : itemId,
                putUrl: putUrl,
                name: name,
                setDataInfo: setDataInfo,
                doIsSubmitAndCreate: doIsSubmitAndCreate,
                isAllIds: isAllIds,
                setIsAllIds: setIsAllIds,
                setSelectedRows: setSelectedRows,
            }, t);
            //Remise du statut du formulaire à l'initial
            this.setState({
                doIsSubmitAndCreate: false
            });
        }
        setPreFillingObject(null);
    };

    /**
     * control all quoteLines before submitting
     * @returns {boolean}
     */
    controlQuoteLineValues = () => {
        const {quoteLinesValues} = this.state;
        let isOk = true;

        if (quoteLinesValues && quoteLinesValues.quoteLines) {
            quoteLinesValues.quoteLines.forEach(quoteLine => {
                if (quoteLine.name?.length === 0
                    || parseFloat(quoteLine.unitPriceExclVat) < 0
                    || parseInt(quoteLine.quantity) <= 0
                    || parseFloat(quoteLine.discountValue) < 0
                    || ((quoteLine.discountType === 'amount' && quoteLine.discountValue > (quoteLine.unitPriceExclVat * quoteLine.quantity))
                    || (quoteLine.discountType === 'percentage' && quoteLine.discountValue > 100))) {
                    isOk = false;
                }
            });
        }

        return isOk;
    }

    /**
     * set form with initial input
     * @param data
     * @param context
     * @param callback
     * @param reset
     * @returns {{inputs: [], orders: *}|*}
     */
    setInitialInput = (data, context, callback, reset = false) => {
        let {
            setLoader,
            info: {additionalSearch, prospectIds, contactIds, taskIds, quoteIds, productIds},
            admin,
            activeFilters,
            form: {prefillInputs},
            customTabSearch
        } = this.props;
        let {info, info: {group = '', type, order_entity = 'Prospect'}} = this.state;
        const dataFilterType = type === 'search' ? type : 'form';

        const formatData = handleFormData(data[2]);
        if (admin) {
            this.props.getData(formatData);
        }

        let inputs = dataFilter(order_entity, dataFilterType, formatData, false, group);

        if (!inputs) {
            setLoader({
                [info.loader]: false
            });
            return;
        }

        let processedInput = inputs

        if (!reset) {
            // Préremplissage avec activeFilters
            if (activeFilters && Object.keys(activeFilters).length) {
                processedInput = fillForm(processedInput, activeFilters)
            }
            // Préremplissage avec preFillInputs
            if (prefillInputs && Object.keys(prefillInputs).length) {
                processedInput = fillForm(processedInput, prefillInputs)
            }
            // Préremplissage avec customTabSearch
            if (customTabSearch && Object.keys(customTabSearch).length) {
                processedInput = fillForm(processedInput, customTabSearch)
            }
        }

        this.setState({
            initalInputs: formatData,
            inputs: inputs,
            loader: false
        }, () => {
            // Si input doit cacher d'autres field en fonction de sa valeur
            inputs.forEach((value, groupKey) => {
                if (value.fields) {
                    value.fields.forEach((childValue, itemKey) => {
                        if (childValue['input']
                            && childValue['input'].attr
                            && childValue['input'].attr['data-hide-fields']
                        ) {
                            processedInput = this.autoHideFields(groupKey, itemKey, false);
                        }
                    })
                }
            })
            this.setState({
                inputs: processedInput
            }, () => {
                if (callback) {
                    callback();
                }
            })
        });

        setLoader({
            [info.loader]: false
        });

        if (type === 'search' && ((additionalSearch && Object.keys(additionalSearch).length) || (prospectIds && prospectIds.length) || (taskIds && taskIds.length) || (contactIds && contactIds.length) || (quoteIds && quoteIds.length) || (productIds && productIds.length)) && context !== 'search') {
            this.handleSubmit(null, 'form_search', true)
        }

        return processedInput;
    }

    getForm = () => {
        let {
            manageError,
            admin = false,
            setLoader,
            info: {fetchWithData = false, contactIds},
            t,
            setFormEndRedirect
        } = this.props;
        let {info, info: {type}} = this.state;

        if (info.loadForm === false) {
            return;
        }

        const user = UserService.getUser();
        let token = user.token ? user.token : localStorage.getItem('token');

        setLoader({
            [info.loader]: true,
        });
        setFormEndRedirect();
        info = (info.itemId ? {
            ...info,
            formUrl: info.formUrl + '/' + info.itemId + '/?prospectId=' + info.prospectId
        } : info);
        let fetchOptions = {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
        }

        if (info.prospectId && info.addFormWithSubResourceUrl) {
            info.addFormWithSubResourceUrl = info.addFormWithSubResourceUrl + '/?prospectId=' + info.prospectId;
        }

        if (fetchWithData && contactIds && contactIds.length) {
            info.formUrl = info.formUrl.replace('{contactIds}', contactIds.join(';'))
            contactIds = undefined
        }
        const formUrlFinal = process.env.REACT_APP_HOST_API + (info.addFormWithSubResourceUrl ? info.addFormWithSubResourceUrl : info.formUrl);

        const dataHandler = (data) => {
            if (data && data.schema && data.schema['hydra:member']) {
                this.setState({
                    initialDataForm: data.schema['hydra:member']
                })

                let resetFilter = false;
                const {contactIds, taskIds, quoteIds, prospectIds, productIds} = this.props?.info;
                if (contactIds?.length > 0 || taskIds?.length > 0 || quoteIds?.length > 0 || prospectIds?.length > 0 || productIds?.length > 0) {
                    resetFilter = true;
                }

                this.setInitialInput(data.schema['hydra:member'], null, () => {
                    if (type === 'search' && UserService.submitFormSearchOnLoading(info.order_entity)) {
                        this.handleSubmit(null, 'form_search', true);
                    }
                }, resetFilter) /* Si un problème à cause de "true", voir PV-929 : https://sfimultimedia.atlassian.net/browse/PV-929 */
            } else {
                manageError(500, info, type === 'search' ? t('loading_form_search') : t('loading_form_error'))
            }
            setLoader({
                [info.loader]: false,
            });
        }

        fetch(formUrlFinal,
            fetchOptions).then(
            fetchHandler,
            error => {
                manageError(true, info)
            }
        ).then(
            data => {
                if (!info.itemId && !admin) {
                    window.formCache[formUrlFinal] = data;
                }
                dataHandler(data);
            },
            error => {
                manageError(error.status, info, type === 'search' ? t('loading_form_search') : t('loading_form_error'));
                setLoader({
                    [info.loader]: false,
                });
            }
        );
    };

    deleteFilter = (key, oldValueFilter) => {
        let {inputs} = this.state;
        const {activeFilters} = this.props
        const infoFilter = this.findByKey(key);
        let value = '';

        if (!key) {
            return;
        }

        const input = inputs[infoFilter.group].fields[infoFilter.item]['input'];
        if (infoFilter.group >= 0 && infoFilter.item >= 0) {
            if (infoFilter.group >= 0 && input.attr && input.attr.type === 'date_compare') {
                value = null;
                input.value = value;
            } else if (input.items && (input.items.enum_titles || input.enum_titles)) {
                if (input.type !== 'array') {
                    value = null;
                } else {
                    value = activeFilters && activeFilters[key] ? activeFilters[key] : []
                }
            }
        }
        if (inputs[infoFilter.group]) {
            this.handleChange(
                infoFilter.group,
                infoFilter.item,
                typeof value === 'object' && value !== null ? value.value : value,
                input.value,
                true
            );
        }
    };

    /**
     * Méthode affichant une confirmation lorsque l'utilisateur souhaite quitter une page alors que des données n'ont pas étaient enregistrées.
     * @param {string} context "prompt | refresh | backButton"
     * @param {Event} event "Objet event utilisé dans le cas du refresh"
     */
    onLeavePage = (context, event = null) => {
        const {type, t, setPreFillingObject} = this.props;
        const {doFormIsModified} = this.state;

        //Si le formulaire n'est pas un formulaire de recherche et que des données ne sont pas encore enregistrées
        if (type !== 'form_search' && doFormIsModified) {
            const message = (t("customer_confirm_leave_form_alert"));
            switch (context) {
                //Si le Prompt a détecté une tentative de quitter la page
                case 'prompt' :
                    //Retour du message de confirmation
                    this.setState({
                        redirect: null
                    });
                    setPreFillingObject(null);
                    return message;
                //Si l'utilisateur essaye de râfraichir la page (Lié à l'événement beforeunload)
                case 'refresh' :
                    //Retour du message de confirmation
                    event.returnValue = message;
                    return;
                //Si l'utilisateur clique sur le bouton 'Retour' du formulaire (Lié à la méthode HandleClickReturnButton
                case 'confirm' :
                    //Si l'utilisateur annule
                    if (!window.confirm(message)) {
                        //Retourne false à la méthode HandleClickReturnButton
                        return false;
                    }

                    //Si l'utilisateur confirme, mise du statut du formulaire en 'Non modifié' afin de ne pas déclenché le Prompt (Sécurité contre les doubles confirmations)
                    this.setState({
                        doFormIsModified: false
                    });
                    setPreFillingObject(null);
                    break;
                default:

                    break;
            }
        }
    }

    /**
     * Vérification de la taille des fichiers, si les fichiers dépassent la valeur de REACT_APP_MAX_FILES_SIZE_MB_UPLOAD (.env), mise en "disable" du bouton enregistrement
     * @param {Boolean} filesSizeExceeded
     * @returns {Boolean}
     */
    handleFilesSizeExceeded = (filesSizeExceeded) => {
        this.setState({
            filesSizeExceeded: filesSizeExceeded
        })
    }

    componentDidMount() {
        const {info, form: {prefillInputs}, type, isMapSearch} = this.props;

        if (prefillInputs) {
            this.setState({doFormIsModified: true});
        }

        this.setState({
            info: info
        }, () => {
            this.getForm()
            if (type !== 'form_search') {
                if (window.beforeUnloadHandler) {
                    window.removeEventListener('beforeunload', window.beforeUnloadHandler, false);
                }

                window.beforeUnloadHandler = this.handleBeforeUnload.bind(this);
                //Ajout d'un événement détectant les tentatives de râfraichissement (F5, CTRL + F5, Browser Refresh)
                window.addEventListener('beforeunload', window.beforeUnloadHandler, false);
            } else if (isMapSearch) {
                setTimeout(() => {
                    this.handleSubmit(null, 'form_search', true);
                }, 2000);
            }
        });
    }

    handleBeforeUnload(e) {
        //Appel de la méthode onLeavePage pour la gestion de l'affichage des messages de confirmation.
        this.onLeavePage("refresh", e);
    }

    /**
     * @param prevProps
     * @param prevState
     * @param snapshot
     *
     * @returns {boolean|(*&{inputs: *})}
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            form,
            onBack,
            isForceSubmitForm,
            setForceSubmit,
            admin,
            resetForm,
            info: {removeFilter = null, oldValueFilter = false, valueDeleted},
            setResetForm,
            searchContent,
            setFormEndRedirect,
            isAllIds
        } = this.props;
        const {isSubmitted, info: {backUrl = null}, initialDataForm} = this.state;

        if (prevProps === this.props) {
            return;
        }

        if (prevProps.isAllIds !== isAllIds && isAllIds === true) {
            this.handleSubmit(null, 'form_search', true, 0, isAllIds);
            setForceSubmit(false);
        }

        if (prevProps.isForceSubmitForm !== isForceSubmitForm && isForceSubmitForm) {
            this.handleSubmit(null, 'form_search', true, 0);
            setForceSubmit(false)
        }
        // Recherche sauvegardé
        if (searchContent && (!prevProps.searchContent || searchContent !== prevProps.searchContent)) {
            if (resetForm && prevProps.resetForm !== resetForm) {
                let newInputs = this.setInitialInput(initialDataForm, 'search', () => {
                }, true)
                setResetForm(false)
                newInputs = fillForm(newInputs, searchContent)
                this.setState({
                    inputs: newInputs
                }, () => {
                    this.handleSubmit(null, 'form_search', true, 0);
                })
            }

            return;
        }

        if (prevProps.distanceIsChanged !== this.props.distanceIsChanged) {
            this.handleSubmit(null, 'form_search', true);
        }

        if (
            admin &&
            this.props.info !== this.state.info
        ) {
            this.setState({
                info: this.props.info
            }, () => {
                this.getForm()
            });
        }

        if ((prevProps.info.removeFilter !== removeFilter || prevProps.info.oldValueFilter !== oldValueFilter || prevProps.info.valueDeleted !== valueDeleted)) {
            this.deleteFilter(removeFilter, oldValueFilter);
            const {info} = this.props
            delete info.removeFilter;
            delete info.oldValueFilter;
            delete info.valueDeleted;
        }

        if (this.props.info.order_entity === "unitMail" && this.state.info?.quotePdfFile) {
            this.state.inputs.forEach(({fields}) => {
                fields.forEach((field) => {
                    if (field.key === "customerFiles" && field.valueType === "files") {
                        field.input.existingFiles = {
                            source: this.state.info.quotePdfFile.downloadLink,
                            name: this.state.info.quotePdfFile.realFileName ?? this.state.info.quotePdfFile.fileLabel,
                            type: 'application/pdf'
                        }

                        field.input.addProductsQuoteFiles = this.state.info.quoteId
                    }
                })
            })
        }

        if (isSubmitted) {
            if (form && form.errors && ((Array.isArray(form.errors) && form.errors.length) || Object.keys(form.errors).length)) {
                let inputs = prevState.inputs;
                const errors = form.errors;

                if (prevProps.form !== form && errors.empty !== undefined && isSubmitted) {
                    this.setState({
                        isSubmitted: false
                    })
                }

                inputs.forEach(group => {
                    if (group.fields) {
                        group.fields.forEach(order => {
                            let errorField = Object.keys(errors).find((error) => error === order.key);

                            if (errorField) {
                                order.input.error = errors[errorField];
                                this.setState({
                                    isSubmitted: false,
                                    doFormIsModified: true
                                })
                            }
                        });
                    }
                });

                return {
                    ...prevState,
                    inputs: inputs
                }
            }

            if (form.redirect) {
                setFormEndRedirect();
                delete form.redirect;
                if (resetForm) {
                    resetForm();
                }
                if (typeof onBack === 'function') {
                    onBack();
                    return false;
                } else if (backUrl) {
                    this.setState({
                        isSubmitted: false,
                        redirect: <Redirect to={{
                            pathname: backUrl,
                            state: {hasToRefresh: true}
                        }}/>
                    })
                }
            }
        }
    }

    /**
     * Méthode permettant l'édition des states afin d'indiquer que le formulaire a été enregistré à l'aide du bouton "Enregistré et créer
     * le contact associé" et que les données ont étaient sauvegardées (Sécurité afin de ne pas déclencher le prompt).
     */
    handleClickOnSubmitCreate() {
        this.setState({
            doIsSubmitAndCreate: true,
            doFormIsModified: false
        })
    }

    /**
     * Méthode appelant la méthode onLeavePage pour gérer l'affichage des messages de confirmation et gérant la redirection en fonction de la réponse de l'utilisateur.
     * @returns {boolean}
     */
    handleClickReturnButton() {
        const {onBack, info, setPreFillingObject} = this.props;
        //Appel de la méthode onLeavePage afin d'afficher le message de confirmation et test de la réponse de l'utilisateur
        if (this.onLeavePage('confirm') !== false) {
            //Si l'utilisateur confirme, il est redirigé vers la page précédente.
            setPreFillingObject(null);
            if (info.backUrl) {
                this.setState({
                    redirect: <Redirect to={info.backUrl}/>
                });
                return false;
            }
            onBack();
        }
    }

    /**
     * Méthode mettant le statut du formulaire à non modifié (Sécurité pour éviter les messages de confirmation sur l'enregistrement des données)
     */
    handleClickOnSubmit() {
        this.setState({
            doFormIsModified: false,
        })
    }

    componentWillUnmount() {
        this.setState({
            doFormIsModified: false,
        });

        // Suppression de l'event ajouté
        window.removeEventListener('beforeunload', window.beforeUnloadHandler, false);
    }

    render() {
        const {
            t,
            type,
            isCurrentEditionByView,
            admin = false,
            info,
            getSearchContent,
            setPopupIsOpen,
            extendedSearchForm = false,
            doIsSearchForm = false,
            viewSelected = null
        } = this.props;
        const {inputs, redirect, loader, isSubmitted, filesSizeExceeded} = this.state;
        let classBtnForm = (type !== 'form_search') ? '' : 'form__submit-search';
        let classForm = loader ? 'form-loader' : '';
        classForm += (type !== 'form_search') ? '' : ' form-search';
        const message = (t("add_prospect_compagny_and_create_contact"));
        if (admin) {
            const {t, ...rest} = this.props;
            return (
                <ViewForm
                    {...rest}
                    adminOrder={true}
                />
            );
        }

        return (
            <ErrorBoundary>
                {redirect}
                <form
                    className={`form form__scrollable ${isCurrentEditionByView ? "form__edit--view" : ""} ${classForm}`}
                    onSubmit={(event) => {
                        this.handleSubmit(event);
                    }}>
                    <div
                        className={`row row-fluid align-items-center justify-content-end form__button-container${isCurrentEditionByView ? "-edit__view" : ""} ${info.name ? `template__${info.name}` : ""}`}>
                        {type !== 'form_search' ? (
                            <>
                                {/*Prompt détect les changements de routes*/}
                                <Prompt message={() => {
                                    return this.onLeavePage("prompt");
                                }}/>
                                <div className={'col-12 col-md-auto form__back'}
                                     onClick={this.handleClickReturnButton.bind(this)}>
                                    <span className={'form__back__icon'}>
                                        <PolIcon icon="return"/>
                                    </span>
                                    {t('retour')}
                                </div>
                                <div className={`col-12 col-md-auto form__submit ${classBtnForm}`}>
                                    <button className={'btn btn-primary form__submit__btn'}
                                            disabled={isSubmitted || filesSizeExceeded}
                                            onClick={this.handleClickOnSubmit.bind(this)}>
                                        <span className={'form__submit__btn__icon'}>
                                            <PolIcon
                                                icon={info.order_entity !== 'unitMail' ? "validation" : "mails-send"}/>
                                        </span>
                                        {type !== 'form_search' ? info.order_entity !== 'unitMail' ? t('enregistrer') : t('send_mail') : t('recherche_criteres')}
                                    </button>
                                </div>
                                {info.name === 'propsect_business' ? (
                                    <>
                                        <div className={`col-12 col-md-auto form__submit ${classBtnForm}`}>
                                            <button className={'btn btn-primary form__submit__btn'}
                                                    disabled={isSubmitted || filesSizeExceeded}
                                                    onClick={this.handleClickOnSubmitCreate.bind(this)}>
                                                {message}
                                            </button>
                                        </div>
                                    </>
                                ) : null}
                            </>
                        ) : null}
                    </div>


                    {((isSubmitted && type !== 'form_search') || loader) &&
                        <Loading message={t("loading")} specificClass={"form-loader"}/>
                    }

                    <ViewForm inputs={inputs}
                              getSearchContent={getSearchContent}
                              handleFilesSizeExceeded={this.handleFilesSizeExceeded.bind(this)}
                              handleChange={(groupKey, itemKey, value, oldValue) => this.handleChange(groupKey, itemKey, value, oldValue)}
                              itemId={info.itemId}
                              setQuoteLinesValues={this.setQuoteLinesValues}
                              setSelectIsFocused={(keyItem, value) => this.setSelectIsFocused(keyItem, value)}
                              adminOrder={false}
                              typeForm={type}
                              updateInput={(groupKey, itemKey, attributes) => this.updateInput(groupKey, itemKey, attributes)}
                              disableViewOrder={info && info.getVisibleInfos}
                              setPopupIsOpen={setPopupIsOpen}
                              extendedSearchForm={extendedSearchForm}
                              doIsSearchForm={doIsSearchForm}
                              viewSelected={viewSelected}
                    />
                </form>
            </ErrorBoundary>
        )
    }
}

const mapStateToProps = ({form, content: {expanded}}) => ({
    form,
    expanded
});

const mapDispatchToProps = dispatch => {
    return {
        postService: (info, t) => dispatch(PostService(info, t)),
        setFilterSearch: (data) => dispatch(setFilterSearch(data)),
        postSearchService: (info) => dispatch(PostSearchService(info)),
        setLoader: (value) => dispatch(setLoader(value)),
        setPopup: (info) => dispatch(setPopup(info)),
        setPreFillingObject: (data) => dispatch(setPreFillingObject(data)),
        manageError: (code, error, errorMessage) => dispatch(manageError(code, error, errorMessage)),
        setFormEndRedirect: () => dispatch(setFormEndRedirect()),
        onExpand: (expanded) => dispatch(setExpandInfo(expanded))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(React.memo(Form)));

import React, {useState} from "react";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import Form from "../../Form/form";
import {PolIcon} from "../../PolIcon/policon";
import Filter from "./filter";
import UserService from "../../../api/user/user";
import {fetchHandler, manageError} from "../../../api/datas/datas";
import SavedSearch from "../../Form/savedSearch";
import ReactTooltip from "react-tooltip";
import {createPortal} from "react-dom";

export const MAX_COUNT_FILTERS = 3;


/**
 * Pagination render
 *
 * @param pages
 * @param onPageChange
 * @returns {*}
 */
export const pageListRenderer = ({pages, onPageChange, isImport}) => {
    const pageWithoutIndication = isImport ? pages : pages.filter(p => typeof p.page !== 'string');

    return (
        <>
            {(pages.length > 1) ? (
                <div className={'col-12 col-md-6 col-fluid'}>
                    <ul className={'pagination'}>
                        {pageWithoutIndication.map((item, key) => {
                            let classItem = item.active ? 'pagination__item-active' : null;
                            return (
                                <li className={`pagination__item ${classItem}`} key={key}>
                                    <button className="pagination__item__btn" key={key}
                                            onClick={() => {
                                                onPageChange(item.page)
                                            }}
                                    >
                                        {item.page}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            ) : null}
        </>
    );
};

export const importPaginationRenderer = (props) => {
    return pageListRenderer({...props, isImport: true});
}

/**
 * Custom sort icons
 *
 * @param order
 * @param column
 * @returns {null|*}
 */
export const arrowSort = (order, column) => {
    if (!order) return (<PolIcon icon="sort" className={'datatable__sort'}/>);
    else if (order === 'asc') return (<PolIcon icon="sort-up" className={'datatable__sort'}/>);
    else if (order === 'desc') return (<PolIcon icon="sort-down" className={'datatable__sort'}/>);
    return null;
};

/**
 * Custom toggle list
 *
 * @param columns
 * @param onColumnToggle
 * @param toggles
 * @param onClick
 * @param onDragStart
 * @param onDragOver
 * @param setOrders
 * @returns {*}
 * @constructor
 */
export const CustomToggleList = ({columns, onColumnToggle, toggles, onClick, onDragStart, onDragOver, setOrders}) => {
    const {t} = usePolTranslation();
    if (!toggles)
        return null;

    return (
        <>
         <span className={'table-column__close'} data-tip={t('close_column')}
               onClick={() => onClick()}
         >
            <PolIcon icon={'times-circle'}/>
        </span>

            <div className={'table-column__title'}>
                <p className={'table-column__title__text'}>{t('gestion_colonnes')}</p>
            </div>

            <ul className={'list'}>
                {
                    columns.map(column => ({
                        ...column,
                        toggle: toggles[column.dataField]
                    })).map((column, idx) => (
                        <li className={`list__item ${column.toggle ? 'active' : ''}`}
                            key={column.dataField}
                            onClick={() => {
                                onColumnToggle(column.dataField);
                                setOrders();
                            }}
                            onDragOver={() => onDragOver(idx)}
                        >
                            <div
                                className="list__item__drag"
                                draggable
                                onDragStart={e => onDragStart(e, idx)}
                                onDragEnd={() => setOrders()}
                            >
                                {idx + 1} - {column.text}

                                <span className="list__item__check"> </span>
                            </div>

                        </li>
                    ))
                }
            </ul>
            {/* portal to body for the tooltip to prevent the overflow hidden */}
            {createPortal(<ReactTooltip effect="solid"/>, document.querySelector('body'))}
        </>
    );
};

/**
 * Search tab
 *
 * @param setVisibleFilters
 * @param itemType
 * @param info
 * @returns {*}
 * @constructor
 */
export const DatabaseSearchContent = ({setVisibleFilters, info, removeSearch, setPopupIsOpen, activeFilters, setExtendedMenu, isForceSubmitForm, setForceSubmit, isAllIds, setIsAllIds, reInitAllIds, setSelectedRows, customTabSearch}) => {
    //States
    const [searchContent, setSearchContent] = useState({});
    const [resetForm, setResetForm] = useState(false);
    const [extendedSearchForm, setExtendedSearchForm] = useState(false);

    //Props & variables
    const {t} = usePolTranslation();

    //Variables

    const changeKey = (key, value) => {
        if (Array.isArray(value)) {
            value = value.map((item) => item && item.value ? item.value : item)
        }

        setSearchContent({
            ...searchContent,
            [key]: value
        })
    }

    const resetSavedSearch = () => {
        setSearchContent({})
        setResetForm(true)
    }

    return (
        <>
            <PolIcon className={`content-search__expend ${extendedSearchForm ? 'open' : ''}`} icon={'arrow-right-bis'}
                     onClick={() => {
                         setExtendedSearchForm(!extendedSearchForm);
                         setExtendedMenu();
                     }}/>
            <PolIcon className={'content-search__close'} icon={'times-circle'}
                     onClick={() => setVisibleFilters()} data-tip={t('quit')}/>
            {(!customTabSearch || Object.keys(customTabSearch).length === 0) && <SavedSearch searchContent={searchContent}
                          resetSavedSearch={resetSavedSearch} setSavedSearch={setSearchContent} info={info}/>}
            <p className={'content-search__title'}>
                {t('recherche_criteres')} :
            </p>

            <Form resetForm={resetForm} setResetForm={setResetForm} changeKey={changeKey} info={info}
                  type={'form_search'} searchContent={searchContent} removeSearch={removeSearch}
                  setPopupIsOpen={setPopupIsOpen}
                  activeFilters={activeFilters}
                  extendedSearchForm={extendedSearchForm}
                  doIsSearchForm={true}
                  isForceSubmitForm={isForceSubmitForm}
                  setForceSubmit={setForceSubmit}
                  setSelectedRows={setSelectedRows}
                  isAllIds={isAllIds}
                  setIsAllIds={setIsAllIds}
                  reInitAllIds={reInitAllIds}
                  customTabSearch={customTabSearch}
            />
            <ReactTooltip effect="solid"/>
        </>
    );
};

/**
 * Active filters
 *
 * @param activeFilters
 * @param removeSearch
 * @returns {*}
 * @constructor
 */
export const ActiveFilters = ({filters, removeSearch, resetChainedSearch, resetMapFilter, customTabSearch}) => {
    const {t} = usePolTranslation();
    let processedFilters = [];
    let index = 0;

    if (!filters) {
        return <></>;
    }
    delete filters['_selectedFields'];
    Object.keys(filters).forEach(filter => {
        //Traitement des Booléans à "true", des filtres différents de null, des tableaux avec un length
        if (filters[filter] && filters[filter] !== 'null') {
            //CAS 1 : Recherche enchainée
            if (filter === 'prospectIds' || filter === 'contactIds' || filter === 'taskIds' || filter === 'quoteIds' || filter === 'productIds') {
                if (typeof filters[filter] === 'object' && filters[filter] !== null && (filters[filter].length || (filters[filter].value
                    && filters[filter].value.length))) {

                    let filterValue;
                    switch (filter) {
                        case "prospectIds":
                            filterValue = 'Prospect';
                            break;
                        case "contactIds":
                            filterValue = 'contact';
                            break;
                        case "taskIds":
                            filterValue = 'task';
                            break;
                        case "quoteIds":
                            filterValue = 'quote';
                            break;
                        case "productIds":
                            filterValue = 'product';
                            break;
                        default:
                            break;
                    }
                    index++;
                    processedFilters.push({
                        filterLabel: t('chained_search'),
                        filterValue: t(filterValue),
                        onClick: (e) => {
                            e.currentTarget.classList.add('disable');
                            resetChainedSearch()
                        },
                        index: index
                    });
                    return;
                }
            }
            const value = typeof filters[filter] === 'boolean' ? 'Oui' : filters[filter];
            //CAS 2 : Recherche avec un tableau (Départments)
            if (value && Array.isArray(value)) {
                value.forEach((item, itemKey) => {
                    index++;
                    if (filter !== "prospectIds" && filter !== "contactIds" && filter !== "quoteIds" && filter !== "taskIds") {
                        processedFilters.push({
                            filterLabel: (t(filter)),
                            filterValue: item.label ? item.label : item,
                            onClick: customTabSearch && Object.keys(customTabSearch).includes(filter) ? null : (e) => {
                                e.currentTarget.classList.add('disable');
                                removeSearch(filter, itemKey)
                            },
                            index: index
                        })
                    }
                })
            } else {
                //CAS 3 : Recherche avec un array|object
                if (typeof value === 'object' && value !== null && Array.isArray(value.value)) {
                    const toBrowse = value.filterValue ?? value.value
                    toBrowse.forEach((item, itemKey) => {
                            index++;
                            processedFilters.push({
                                filterLabel: value.name ?? filter,
                                filterValue: item && item.label ? item.label : item,
                                onClick: customTabSearch && Object.keys(customTabSearch).includes(filter) ? null : (e) => {
                                    e.currentTarget.classList.add('disable');
                                    removeSearch(filter, itemKey)
                                },
                                index: index
                            })
                        }
                    )
                } else if (value && typeof value.value === 'object' && typeof value.filterValue === 'object') {
                    processedFilters.push({
                        filterLabel: value.name ?? filter,
                        filterValue: value.filterValue,
                        onClick: customTabSearch && Object.keys(customTabSearch).includes(filter) ? null : (e) => {
                            resetMapFilter()
                        },
                        index: index
                    })
                } else { // string
                    index++;
                    processedFilters.push({
                        filterLabel: t(filter),
                        filterValue: value,
                        onClick: customTabSearch && Object.keys(customTabSearch).includes(filter) ? null : (e) => {
                            e.currentTarget.classList.add('disable');
                            if (['distance', 'lat', 'lng'].indexOf(filter) === -1) {
                                removeSearch(filter)
                            } else {
                                resetMapFilter()
                            }
                        },
                        index: index
                    })
                }
            }
        }
    });
    let selectFilters = [];
    if (processedFilters.length > MAX_COUNT_FILTERS) {
        selectFilters = processedFilters.splice(MAX_COUNT_FILTERS - 1);
    }

    /**
     * Ajout d'une class CSS pour afficher les filtres contenus dans le select lors d'un click
     */
    const handleDropDownClick = () => {
        const ul = document.querySelector('.table-active-filter__dropDown-content');
        ul.classList.add("isOpen");
    }

    return (
        <div className={'table-active-filter'}>
            {/*Affichage du select lorsque celui-ci contient au minimum deux filtres*/}
            {selectFilters.length ? (
                <li className={'table-active-filter__dropDown-Btn'} onClick={handleDropDownClick}>
                    {/*Affichage du nombre de filtres dans le select*/}
                    {`Filtres (${selectFilters.length})`}
                    <PolIcon icon={'chevron-down'}
                             className={'table-active-filter__item__icon-dropDown'}
                    />
                    <ul className={'table-active-filter__dropDown-content'} onMouseLeave={(e) => {
                        e.currentTarget.classList.remove("isOpen");
                    }}>
                        {selectFilters.map((filter, key) => {
                            return (
                                <Filter
                                    key={key}
                                    filterLabel={filter.filterLabel}
                                    filterValue={filter.filterValue}
                                    index={filter.index}
                                    onClick={filter.onClick}
                                    t={t}
                                />
                            )
                        })}
                    </ul>
                </li>
            ) : null}
            <ul className={'table-active-filter__visibleFilter-content'}>
                {processedFilters.map((filter, key) => {
                    return (
                        <Filter
                            key={key}
                            filterLabel={filter.filterLabel}
                            filterValue={filter.filterValue}
                            index={filter.index}
                            onClick={filter.onClick}
                            isVisibleFilter={true}
                            t={t}
                        />
                    )
                })}
            </ul>
        </div>
    )
};

/**
 * Empty table
 *
 * @param t
 * @param isLoading
 * @returns {*}
 */
export const noData = (t, isLoading = false) => {
    return (
        <div className={'datatable__no-result'}>
            <PolIcon icon={isLoading ? 'circle-notch' : 'search'}
                     className={isLoading ? 'fas fa-circle-notch fa-spin' : 'datatable__no-result__picto'}/>
            <p className={'datatable__no-result__text'}>{t(isLoading ? 'loading_data' : 'aucun_resultat_trouve')}</p>
        </div>
    );
};

export const reOfferTask = (row, prospectId, callback) => {
    const user = UserService.getUser();
    const token = user.token ? user.token : localStorage.getItem('token');

    fetch(process.env.REACT_APP_HOST_API + '/api/task_names', {
        method: 'GET',
        headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/json',
            "X-POL-AUTH": 'Bearer ' + token
        },
    }).then(
        fetchHandler,
        error => {
            manageError(true, error)
        }
    ).then(
        data => {
            let field = "";
            if (data && data['hydra:member']) {

                let order = 0;
                data['hydra:member'].forEach((value) => {
                    if ((order === 0 && value['quoteReminder'] === true) ||
                        (order > 0 && value['quoteReminder'] === true && value['order'] < order)) {
                        field = value['name'];
                        order = value['order'];
                    }
                });
            }
            callback(row, prospectId, field);
        }
    );
}

export const setData = (title, dataType, datas) => {
    return {
        type: 'SET_DATA',
        title,
        dataType,
        datas
    }
};

export const setError = (error) => {
    return {
        type: 'ERROR',
        error
    }
};

export const setAppError = (errorMessage) => {
    return {
        type: 'APP_ERROR',
        errorMessage
    }
};

export const removeError = () => {
    return {
        type: 'REMOVE_ERROR'
    }
};

export const resetListDatas = (entity, idProspect, id) => {
    return {
        type: 'UPDATE_LIST',
        entity,
        idProspect,
        id
    }
};

export const resetStoreDatas = (disableCache = false) => {
    return {
        type: 'RESET_STORE_DATAS',
        disableCache : disableCache
    }
};

export const refreshBigList = (name) => {
    return {
        type: 'REFRESH_BIG_LIST',
        name
    }
};

export const resetSubResourceStore = () => {
    return {
        type: 'RESET_SUBRESOURCE_STORE_DATAS'
    }
};

export const setContractNumber = (id, typeP, contractNumber) => {
    return {
        type: 'SET_CONTRACT_NUMBER',
        id,
        typeP,
        contractNumber
    }
};

export const setDataId = (id, isSubmitAndCreate) => {
    return {
        type: 'SET_DATA_ID',
        id,
        isSubmitAndCreate
    }
}



import {captureException} from "@sentry/react";
import UserService from "api/user/user";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "v4/contexts/AuthContext";
import {MAINTENANCE} from "v4/data/apiRoutes";
import {generateUrl} from "v4/services/api.service";

export default function useMaintenanceChecker() {
    const {isMaintenance} = useContext(AuthContext);
    const [isMaintenanceMode, setIsMaintenanceMode] = useState(isMaintenance);
    const [blockRender, setBlockRender] = useState(isMaintenance === null)

    useEffect(() => {
        if (isMaintenance === null) {
            fetch(generateUrl(MAINTENANCE))
                .then(res => res.json())
                .then(({isMaintenance}) => {
                    setIsMaintenanceMode(isMaintenance);
                    setBlockRender(false);
                })
                .catch(err => {
                    setBlockRender(false);
                    captureException(err);
                });
        } else if (Boolean(isMaintenance) !== isMaintenanceMode) {
            setIsMaintenanceMode(isMaintenance);
            setBlockRender(false);
        }

        if (isMaintenance && UserService.isLogged()) {
            UserService.logout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMaintenance]);

    return [isMaintenanceMode, blockRender];
}

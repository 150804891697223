import React, {useContext, useEffect, useState} from 'react';
import {setStickyMenu} from "../../../actions/admin";
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {menuGroupAdmin, menuGroupSuperAdmin} from "../../../routes";
import {getRoutesParams, menuGroupAdminV4} from "v4/data/appRoutes";
import {routes} from "../../../routes";
import {NavLink} from "react-router-dom";
import UserService, {ROLE_SUPER_ADMIN} from "../../../api/user/user";
import {PolIcon} from "../../PolIcon/policon";
import {adminData} from "../../../api/admin/get";
import {getApiUrl} from "../../../routes";
import Select from "react-select";
import {useRecoilState} from "recoil";
import {customerState} from "../atoms/customer";
import {useHistory} from 'react-router-dom'
import {AdminContext} from "../../../v4/contexts/AdminContext";

export const SideBar = ({adminData, admin, t}) => {
    let [options, setOptions] = useState([]);
    const [customer, setCustomer] = useRecoilState(customerState);
    const [mounted, setMounted] = useState(false);
    let user = localStorage.getItem('user');
    user = JSON.parse(user)
    const currentPath = window.location.pathname
    const history = useHistory();
    const {setAsCustomer} = useContext(AdminContext)

    const setBothCustomer = (customer) => {
        setAsCustomer(customer)
        setCustomer(customer)
    }

    useEffect(() => {
        if (
            ((UserService.controlAccess([ROLE_SUPER_ADMIN]) || UserService.controlAccess(['ROLE_ADMIN']))
                && !mounted)
            || (admin && admin.reloadCustomerList)
        ) {
            adminData({
                url: getApiUrl('admin', 'customersList')
            })
            setBothCustomer(user.customer);
            setMounted(true);
            if (admin && admin.reloadCustomerList) {
                delete admin.reloadCustomerList
            }
            setOptions([]);
        } // eslint-disable-next-line
    }, [mounted, adminData, setCustomer, user.customer]);


    if (!options.length && admin && admin.customersList && admin.customersList.length) {
        setOptions(admin.customersList
            .sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            })
            .map(customer => {
                return {value: customer.id, label: customer.name, customer: customer}
            }))

        if (customer) {
            setCustomer(admin.customersList.find(item => item.id === customer.id))
        }
    }

    return (
        <aside className="admin__sidebar">
            {customer && 'logoUrl' in customer ? (
                <div className={"admin__sidebar-logo"}>
                    <img src={customer.logoUrl} alt={customer.name}/>
                </div>
            ) : null}
            <div className="user">
                <div className="user__image">
                    <div data-initials={`${user.firstname[0]}${user.lastname[0]}`}/>
                </div>
                <div className="user__info">
                    <p>{`${user.firstname} ${user.lastname}`}</p>
                    <div className={'user__info__etat'}>{t('admin_connecte')}</div>
                </div>
            </div>
            {UserService.controlAccess([ROLE_SUPER_ADMIN]) ? (
                <>
                    <div className={'title'}>
                        {t('admin_navigation_principale')}
                    </div>
                    {/*-------------------MENU SUPERADMIN-------------------*/}
                    <ul className="menu">
                        {
                            menuGroupSuperAdmin.map((item, key) => {
                                const submenu = routes.filter(route => (route.isAdmin && route.group === item.groupName));
                                const groupAccessGranted = UserService.controlAccess(item.roles);

                                return (
                                    groupAccessGranted ? (
                                        <NavLink to={item.path} className={`menu__item`} key={`admin_menu_${key}`}
                                                 onClick={(e) => {
                                                     const currentElement = e.currentTarget.querySelector('.menu__item__link')
                                                     const oldElements = document.querySelectorAll('.submenu__active');
                                                     if (oldElements.length) {
                                                         oldElements.forEach(item => item.classList.remove('submenu__active'))
                                                     }
                                                     currentElement.classList.add('submenu__active')
                                                 }}
                                        >
                                            <div
                                                className={`${currentPath.search(item.path) !== -1 ? 'submenu__active' : ''} menu__item__link`}>
                                                <PolIcon icon={item.icon}/>
                                                <span className={'menu__item__text'}>{t(item.name)}</span>
                                            </div>

                                            <ul className="submenu">
                                                {
                                                    submenu.map((submenuItem, submenuKey) => {
                                                        const menuAccessGranted = UserService.controlAccess(submenuItem.roles);
                                                        return (
                                                            menuAccessGranted && (
                                                                <li className={'submenu__item'}
                                                                    key={`admin_submenu_${key}_${submenuKey}`}>
                                                                    <NavLink to={submenuItem.path}>
                                                                        <PolIcon icon={'circle-notch'}
                                                                                 className={'submenu__item__picto'}/>
                                                                        {t(submenuItem.text)}
                                                                    </NavLink>
                                                                </li>
                                                            )
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </NavLink>
                                    ) : null
                                )
                            })
                        }
                    </ul>
                </>
            ) : null}

            {UserService.controlAccess([ROLE_SUPER_ADMIN]) || UserService.controlAccess(['ROLE_ADMIN']) ? (
                <>
                    <div className={'title'}>
                        {t('admin_customers')}
                    </div>
                    {/*-------------------MENU ADMIN-------------------*/}
                    <ul className="menu">
                        {
                            menuGroupAdmin.map((item, key) => {
                                const submenu = routes.filter(route => (route.isAdmin && route.group === item.groupName));
                                const submenuV4 = getRoutesParams().filter(route => (route.isAdmin && route.group === item.groupName));
                                const groupAccessGranted = UserService.controlAccess(item.roles);
                                let disabled;

                                if (!customer) {
                                    disabled = 'disabled';
                                }

                                return (
                                    groupAccessGranted && admin.customersList ? (
                                        <div key={key}>
                                            {item.name !== 'clients_accounts' ?
                                                <li className={"collapsed menu__item " + disabled}
                                                    key={`user_menu_${key}`}
                                                >
                                                    <div className={'menu__item__link'}>
                                                        <PolIcon icon={item.icon}/>
                                                        <span className={'menu__item__text'}>{t(item.name)}</span>
                                                    </div>
                                                    <ul className="submenu">
                                                        {submenu.map((submenuItem, submenuKey) => {
                                                            const menuAccessGranted = UserService.controlAccess(submenuItem.roles);

                                                            return (
                                                                menuAccessGranted ? (
                                                                    <NavLink
                                                                        key={`user_nav_submenu_${key}_${submenuKey}`}
                                                                        to={`${submenuItem.path}`}>
                                                                        <li className={`${currentPath.search(submenuItem.path) !== -1 ? 'submenu__active' : ''} submenu__item`}
                                                                            onClick={(e) => {
                                                                                const currentElement = e.currentTarget
                                                                                const oldElements = document.querySelectorAll('.submenu__active');
                                                                                if (oldElements.length) {
                                                                                    oldElements.forEach(item => item.classList.remove('submenu__active'));
                                                                                }
                                                                                currentElement.classList.add('submenu__active')
                                                                            }}>
                                                                            <PolIcon icon={'circle-notch'}
                                                                                     className={'submenu__item__picto'}/>{t(submenuItem.text)}
                                                                        </li>
                                                                    </NavLink>

                                                                ) : null
                                                            )
                                                        })}
                                                        {submenuV4.map((submenuItem, submenuKey) => (
                                                            submenuItem?.testIsGranted?.() && (
                                                                <NavLink
                                                                    key={`user_nav_submenu_${key}_${submenuKey}`}
                                                                    to={`${submenuItem.basePath}`}>
                                                                    <li className={`${currentPath.search(submenuItem.basePath) !== -1 ? 'submenu__active' : ''} submenu__item`}
                                                                        onClick={(e) => {
                                                                            const currentElement = e.currentTarget
                                                                            const oldElements = document.querySelectorAll('.submenu__active');
                                                                            if (oldElements.length) {
                                                                                oldElements.forEach(item => item.classList.remove('submenu__active'));
                                                                            }
                                                                            currentElement.classList.add('submenu__active')
                                                                        }}>
                                                                        <PolIcon icon={'circle-notch'}
                                                                                 className={'submenu__item__picto'}/>
                                                                        {t(submenuItem.menuTitle)}
                                                                    </li>
                                                                </NavLink>
                                                            )
                                                        ))}
                                                    </ul>
                                                </li>
                                                :
                                                <>
                                                    {
                                                        admin.customersList && admin.customersList.length > 0 ? (
                                                                <Select options={options}
                                                                        classNamePrefix={'react-select'}
                                                                        placeholder={t('select_customer')}
                                                                        isMulti={false}
                                                                        value={options.find((item) => customer && item.value === customer.id)}
                                                                        onChange={(value) => {
                                                                            setBothCustomer(value.customer)
                                                                            history.push('/administration')
                                                                        }}
                                                                        styles={selectStyle}
                                                                        isDisabled={!UserService.controlAccess([ROLE_SUPER_ADMIN])}
                                                                />
                                                            ) :
                                                            null
                                                    }
                                                </>
                                            }
                                        </div>
                                    ) : null
                                )
                            })}

                        {
                            menuGroupAdminV4.map((item, key) => {
                                const submenu = getRoutesParams().filter(route => (route.isAdmin && route.group === item.groupName));
                                const groupAccessGranted = UserService.controlAccess(item.roles);
                                let disabled;

                                if (!customer) {
                                    disabled = 'disabled';
                                }

                                return (
                                    groupAccessGranted && item?.testIsGranted?.() && admin.customersList ? (
                                        <div key={key}>
                                            {item.name !== 'clients_accounts' ?
                                                <li className={"collapsed menu__item " + disabled}
                                                    key={`user_menu_${key}`}
                                                >
                                                    <div className={'menu__item__link'}>
                                                        <PolIcon icon={item.icon}/>
                                                        <span className={'menu__item__text'}>{t(item.name)}</span>
                                                    </div>

                                                    <ul className="submenu">
                                                        {submenu.map((submenuItem, submenuKey) => {
                                                            const menuAccessGranted = UserService.controlAccess(submenuItem.roles);

                                                            return (
                                                                menuAccessGranted && submenuItem?.testIsGranted?.() ? (
                                                                    <NavLink
                                                                        key={`user_nav_submenu_${key}_${submenuKey}`}
                                                                        to={`${submenuItem.basePath}`}>
                                                                        <li className={`${currentPath.search(submenuItem.basePath) !== -1 ? 'submenu__active' : ''} submenu__item`}
                                                                            onClick={(e) => {
                                                                                const currentElement = e.currentTarget
                                                                                const oldElements = document.querySelectorAll('.submenu__active');
                                                                                if (oldElements.length) {
                                                                                    oldElements.forEach(item => item.classList.remove('submenu__active'));
                                                                                }
                                                                                currentElement.classList.add('submenu__active')
                                                                            }}>
                                                                            <PolIcon icon={'circle-notch'}
                                                                                     className={'submenu__item__picto'}/>{t(submenuItem.menuTitle)}
                                                                        </li>
                                                                    </NavLink>
                                                                ) : null
                                                            )
                                                        })}
                                                    </ul>
                                                </li>
                                                :
                                                null
                                            }
                                        </div>
                                    ) : null
                                )
                            })
                        }
                    </ul>
                </>
            ) : null}
        </aside>
    )
}

const mapStateToProps = ({admin}) => ({
    admin
});

const mapDispatchToProps = dispatch => {
    return {
        stickyMenu: (sticky) => dispatch(setStickyMenu(sticky)),
        adminData: (info) => dispatch(adminData(info))
    }
};

const selectStyle = {
    control: (base) => ({
        ...base,
        '&hover': {border: '1px solid #2C3B42'},
        border: 'none',
        boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.16)',
        backgroundColor: '#2C3B42',
        fontFamily: 'inherit',
        outline: 'none',
        height: '40px',
        cursor: 'pointer',
    }),
    container: (base) => ({
        ...base,
        width: '100%',
        zIndex: '999'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'white'
    }),
    dropdownIndicator: (base) => ({
        ...base,
        '&:hover': {color: '#c3c3c3'},
        color: '#fffff',
        display: !UserService.controlAccess([ROLE_SUPER_ADMIN]) ? 'none' : ''
    }),
    placeholder: (base) => ({
        ...base,
        color: '#2C3B42',
    }),
    valueContainer: (base) => ({
        ...base,
        textOverflow: "ellipsis",
        maxWidth: "80%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: '#ffffff',
        backgroundColor: '#2C3B42'
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? state.isSelected ? '#2C3B42' : '#2C3B42' : '#222D32',
        cursor: 'pointer',
        color: state.isFocused ? 'white' : 'white',
    }),
    input: (base, state) => ({
        ...base,
        color: 'white',
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#2C3B42'
    }),
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(React.memo(SideBar)));

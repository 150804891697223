import React from 'react'

export default function AdminCard({title, children, CardHeaderTools}) {
    return (
        <div className="page page-admin content-wrapper">
            <section className={'box admin__card'}>
                <div className='box__header admin__card__header'>
                    <p className={'box__header__title admin__card__title'}>{title}</p>

                    <div className="box__header__tools admin__card__header_tools">
                        {CardHeaderTools &&
                            <CardHeaderTools/>
                        }
                    </div>
                </div>
                <div className={'box__body admin__card__content'}>
                    {children}
                </div>
            </section>
        </div>
    )
}
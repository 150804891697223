export default function handleFormToJson(formElement) {
    const jsonFormData = {};
    for (let formDataValues of new FormData(formElement).entries()) {
        if (jsonFormData[formDataValues[0]]) {
            if (typeof jsonFormData[formDataValues[0]] === "string") {
                jsonFormData[formDataValues[0]] = [jsonFormData[formDataValues[0]], formDataValues[1]];
            } else {
                jsonFormData[formDataValues[0]] = [...jsonFormData[formDataValues[0]], formDataValues[1]];
            }
        } else {
            jsonFormData[formDataValues[0]] = formDataValues[1];
            if (formDataValues[1] === 'on'){
                jsonFormData[formDataValues[0]] = true;
            }
        }
    }

    return jsonFormData;
}

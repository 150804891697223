import ErrorBoundary from "components/Error/errorBoundary"
import React from "react";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import QuotesProductsDetails from "v4/features/front/products/components/QuotesProductsDetails/QuotesProductsDetails";

export default function QuoteLines({properties, onChange}) {
    const {quote} = useProspectPageContext();

    return (
        <ErrorBoundary>
            <QuotesProductsDetails isShow={quote?.metadata?.splitType === 'parent'} setQuoteLinesValues={onChange} properties={properties}/>
        </ErrorBoundary>
    )
}

import {useEffect, useMemo, useState} from 'react';
import {useFormContext} from "react-hook-form";
import {PARTIAL_FORM, PROSPECT_NAMES} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

export default function useQuotesProductsTableAttributesPopup({index, productId}) {
    const [{data}, fetchPartialForm] = useFetch();
    const {register, getValues, setValue} = useFormContext();
    const [fields, setFields] = useState(null);

    const productAttributes = useMemo(() => getValues(`quoteLines.${index}.quoteLineInfo.quoteLineInfoProductAttributes`), [getValues, index]);

    useEffect(() => {
        if (productId && !productAttributes?.length) {
            fetchPartialForm({
                url: generateUrl(PARTIAL_FORM, {formType: 'QuoteLine', productId: productId}),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productId, productAttributes]);

    useEffect(() => {
        let attributes;
        if (productAttributes?.length > 0) {
            attributes = productAttributes;
        } else {
            attributes = data?.['hydra:member']?.results?.quoteLineInfo?.quoteLineInfoProductAttributes?.value;
            if (attributes) {
                attributes.forEach(attribute => {
                    const type = getRealType(attribute.productAttribute.type);
                    if (type === 'checkbox') {
                        attribute.value = attribute.value === 'true';
                    } else {
                        attribute.value = attribute.value ?? '';
                    }
                });
                productAttributes
                    ? setValue(`quoteLines.${index}.quoteLineInfo.quoteLineInfoProductAttributes`, attributes)
                    : register(`quoteLines.${index}.quoteLineInfo.quoteLineInfoProductAttributes`, {
                        value: attributes,
                    });
            }
        }

        if (!attributes) {
            return;
        }

        const fields = attributes.map((attribute, attributeIndex) => ({
            ...attribute,
            ...attribute.productAttribute,
            attr: getAttr(attribute.productAttribute.type),
            type: getRealType(attribute.productAttribute.type),
            name: `quoteLines.${index}.quoteLineInfo.quoteLineInfoProductAttributes.${attributeIndex}.value`,
        }));

        setFields(fields);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return [fields];
}

function getRealType(type) {
    switch (type) {
        case 'integer':
            return 'number';
        case 'boolean':
            return 'checkbox';
        case 'prospect':
        default:
            return 'text';
    }
}

function getAttr(type) {
    switch (type) {
        case 'prospect':
            return {
                'data-autocomplete': generateUrl(PROSPECT_NAMES, {}, false),
                'data-field': 'fullname',
                'data-prevent-unknown': true,
            };
        default:
            return {};
    }
}

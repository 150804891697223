import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {FormProvider} from "react-hook-form";
import Field from "v4/components/form/Field/Field";
import useMoveTask from "v4/features/front/moveTask/hooks/useMoveTask";
import Loader from "v4/components/ui/Loader/Loader";
import "./MoveTaskForm.scss";

export default function MoveTaskForm({taskId, toggleOpen, setInternalState}) {
    const {t} = usePolTranslation();
    const {
        methods,
        inputs,
        isError,
    } = useMoveTask(taskId, toggleOpen, setInternalState);
    const hasProspectValue = !!methods.watch('prospect');

    if (isError) {
        return <p>{t('error')}</p>
    }

    if (!inputs && !isError) {
        return <Loader/>
    }

    return (
        <FormProvider {...methods}>
            <div className="move-task__form">
                <label>
                    {t(inputs.prospect?.label ?? 'prospect')}
                    <div className='input-field-wrapper'>
                        <Field {...inputs.prospect}
                               name="prospect"
                               specificParams={{menuPosition: 'fixed'}}/>
                    </div>
                </label>
                <label>
                    {t(inputs.contacts?.label ?? 'contacts')}
                    <small>{t('empty_no_associate')}</small>
                    <div className='input-field-wrapper'>
                        <Field {...inputs.contacts}
                               name="contacts"
                               specificParams={{menuPosition: 'fixed', isDisabled: !hasProspectValue}}/>
                    </div>
                </label>
                <label>
                    {t(inputs.quote?.label ?? 'quote')}
                    <small>{t('empty_no_associate')}</small>
                    <div className='input-field-wrapper'>
                        <Field {...inputs.quote}
                               name="quote"
                               specificParams={{menuPosition: 'fixed', isDisabled: !hasProspectValue}}/>
                    </div>
                </label>
            </div>
        </FormProvider>
    )
}

import React, {useState} from 'react'
import {connect} from 'react-redux'
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import AdminDatatables from '../../../components/Admin/_partials/datatables';
import {Redirect} from "react-router-dom";
import FormGroups from "../../../components/Admin/_partials/form_groups";
import {arrowSort} from "../../../components/Datatables/_partials/config";
import {sortFunc} from "../../../api/datas/datas";

const Groups = ({t, match}) => {
    const [redirect, setRedirect] = useState(undefined);
    const itemId = match && match.params ? match.params.id : null;
    const [isAddForm, setAddForm] = useState(false);

    const initColumns = [
        {
            text: t('name'),
            dataField: 'name',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('roles'),
            dataField: 'roles',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('customerId'),
            dataField: 'customer.name',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        }
    ];

    const infoTable = {
            url: '/api/security_groups',
            deleteUrl: '/api/security_groups/{id}',
            loader: 'datatable-groups'
        };

    return (
        <>
            {redirect}
            <div className="page page-admin content-wrapper">
                <section className={'box'}>
                    <div className={'box__header'}>
                        <p className={'box__header__text'}>{t('administration_groups')}</p>
                    </div>
                    <div className={'box__body'}>
                        {!itemId && !isAddForm ? (
                            <>
                            <AdminDatatables info={infoTable}
                                         columns={initColumns}
                                         changeView={(item) => {
                                             setRedirect(<Redirect
                                                     to={`/administration/gestion_groupes/${item.id}`}/>
                                             )
                                         }}/>
                            <div className={'row align-items-center justify-content-end'}>
                                <div className={`col-12 col-md-auto form__submit`}>
                                    <button className={'btn btn-primary form__submit__btn'} onClick={() => setAddForm(true)}>
                                        {t('add_group')}
                                    </button>
                                </div>
                            </div>

                        </>) : (
                            <FormGroups itemId={itemId}/>
                            )}
                    </div>
                </section>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    state
});

export default connect(
    mapStateToProps,
    null
)(withPolTranslation(Groups));

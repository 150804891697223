import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default React.memo(function TableThead({theadData}) {
    const {t} = usePolTranslation();

    return (
        <thead>
        <tr>
            {
                theadData?.length > 0
                    ? <>
                        {theadData.map(label => (
                            label === 'actions'
                                ? <th key={label}>{t('Actions')}</th>
                                : <th key={label} data-label={label} data-order='asc'>
                                    {t(label)}
                                </th>
                        ))}
                    </>
                    : <th></th>
            }
        </tr>
        </thead>
    )
})

import React, {useCallback, useEffect} from 'react';
import AsyncSelect from "react-select/async";
import {PROSPECT_SEARCH} from "v4/data/apiRoutes";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";
import {asyncDebounce} from "v4/utils/debounce";

export default function AutocompleteProspectSelect({
                                                       name,
                                                       value,
                                                       autocompleteAdditionnalSearchFilter,
                                                       options,
                                                       isMulti = false,
                                                       placeholder,
                                                       handleChange,
                                                       addOptions,
                                                       htmlConstraints,
                                                       selectParams = {},
                                                       selectStyle,
                                                       impossibleValues
                                                   }) {
    const {t} = usePolTranslation();

    const removeImpossibleValues = (option) => !impossibleValues.includes(option.value);

    const getValue = useCallback((val) => {
        if (options && val) {
            return isMulti
                ? options.filter(option => val.indexOf(String(option.value)) >= 0)
                : options.find(option => option.value === val);
        }

        return isMulti ? [] : "";
    }, [isMulti, options]);

    useEffect(() => {
        const optionsHasAllValues = [value ?? []].flat().every((id) => options.some((option) => option.value === id));

        if (!optionsHasAllValues && value?.length > 0) {
            const additionalParams = {};
            if (autocompleteAdditionnalSearchFilter.filter) {
                additionalParams[autocompleteAdditionnalSearchFilter.filter] = autocompleteAdditionnalSearchFilter.value;
            }
            fetch(generateUrl(PROSPECT_SEARCH, additionalParams), {
                method: 'POST',
                body: JSON.stringify({
                    id: [value].flat()
                })
            })
                .then(response => response.json())
                .then((data) => {
                    const newValue = data['hydra:member'].results.map((item) => {
                        return {
                            value: item.metadata.id,
                            label: item.metadata.name,
                        }
                    });
                    newValue.sort((a, b) => value.indexOf(a.value) - value.indexOf(b.value));
                    addOptions(newValue);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <AsyncSelect defaultOptions={options.filter(removeImpossibleValues)}
                     loadOptions={getAutoCompleteOptions(removeImpossibleValues, addOptions, autocompleteAdditionnalSearchFilter)}
                     name={name}
                     {...htmlConstraints}
                     value={getValue(value)}
                     onChange={handleChange}
                     isMulti={isMulti}
                     placeholder={placeholder ?? t('select')}
                     noOptionsMessage={({inputValue}) => inputValue.length < 3 ? t('need_more_letter', {nb_letters: 3}) : t('no_results')}
                     loadingMessage={() => t('loading')}
                     closeMenuOnSelect={!isMulti}
                     isClearable={true}
                     menuPlacement="auto"
                     className="react-select"
                     classNamePrefix="react-select"
                     onFocus={({target}) => target.closest('.react-select').dataset.focus = "true"}
                     onBlur={({target}) => target.closest('.react-select').dataset.focus = "false"}
                     styles={selectStyle}
                     {...selectParams}
        />
    )
};

function getAutoCompleteOptions(removeImpossibleValues, addOptions, autocompleteAdditionnalSearchFilter) {
    return async (inputValue) => {
        if (!inputValue || inputValue.length < 3) return [];

        return await asyncDebounce(async () => {
            const additionalParams = {};
            if (autocompleteAdditionnalSearchFilter.filter) {
                additionalParams[autocompleteAdditionnalSearchFilter.filter] = autocompleteAdditionnalSearchFilter.value;
            }
            const request = await fetch(generateUrl(PROSPECT_SEARCH, {
                page: 1,
                itemsPerPage: 15,
                searchText: inputValue,
                ...additionalParams
            }), {method: 'POST'});
            const response = await request.json();
            if (response['hydra:member']?.results) {
                const options = response['hydra:member'].results.map((item) => {
                    const name = item.fields.find((field) => field.key === 'fullname')?.value ?? '?';
                    return {
                        value: item.metadata.id,
                        label: name
                    }
                }).filter(removeImpossibleValues);

                addOptions(options);

                return options;
            }

            return [];
        });
    }
}

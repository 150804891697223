import React from 'react';
import useDownloadLink from "v4/components/utils/DownloadLink/hooks/useDownloadLink";

export default function DownloadLink({fileName, href, className = "", children}) {
    const {handleClick} = useDownloadLink(fileName);

    return (
        <a href={href} className={className} onClick={handleClick}>
            {children}
        </a>
    )
}

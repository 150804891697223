import {useEffect, useMemo, useState} from 'react';
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

export default function useSearchFormInputs(searchRouteName, tab) {
    const [{data, isLoading, isFinished}, fetchSearchForm] = useFetch();
    const [autoCompleteChoices, setAutoCompleteChoices] = useState({});

    useEffect(() => {
        if (!searchRouteName) return;

        const fetchParams = {};
        if ('id' in (tab ?? {})) {
            fetchParams.tabId = tab.id;
        }
        fetchSearchForm({url: generateUrl(searchRouteName, fetchParams)});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchRouteName])

    const inputs = useMemo(() => data?.['hydra:member'] ?? {}, [data]);

    const flatInputs = useMemo(() => Object.values(inputs?.results ?? {}).map(({properties}) => properties).flat(), [inputs]);

    return {
        inputs,
        flatInputs,
        state: {
            isLoading,
            isFinished,
        },
        autoCompleteChoices,
        setAutoCompleteChoices,
    };
}

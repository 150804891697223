import React, {useState, useEffect} from 'react';
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import statsMailingSVG from '../../img/svg-stat-mailing.svg'
import {HorizontalBar} from "react-chartjs-2";
import * as moment from "moment";
import {postAdmin} from "../../api/datas/post";
import {adminFetchHandler} from "../../api/datas/datas";
import {connect} from "react-redux";
import {setError} from "../../actions/datas";

const TaskStats = ({t, task, adminFetchHandler, nbReading, setNbReading, setError}) => {
    const [refresh, setRefresh] = useState(false);
    const [date, setDate] = useState(null);
    const [hour, setHour] = useState(null);
    const [nbClic, setNbClic] = useState(0);
    const [hasDetailledStats, setHasDetailledStats] = useState(false);
    const [dataset, setDataset] = useState({
        labels: [],
        datasets: [
            {
                type: 'horizontalBar',
                label: t('taskMailingStats_nbClic'),
                fill: false,
                backgroundColor: 'rgba(161,209,245,0.6)',
                borderColor: 'rgba(161,209,245,1)',
                data: []
            }
        ]
    });

    useEffect(() => {
        if (refresh) {
            postAdmin('/api/task_mailing_stats', (error) => {
                adminFetchHandler(error, {});
                setRefresh(false)
            }, data => {
                const newLabels = [];
                const newData = [];
                const urlsJson = data.details && data.details.urls ? data.details.urls : {};
                const urls = Object.keys(urlsJson);
                const newDataSet = [...dataset.datasets];
                urls.forEach(url => {
                    newLabels.push(url);
                    newData.push(urlsJson[url].count);
                });
                newDataSet[0].data = newData;
                setDataset(
                    {
                        ...dataset,
                        labels: newLabels,
                        datasets: newDataSet
                    }
                );
                setDate(moment(data.date ? data.date : task.createdAt).format('DD/MM/YYYY'));
                setHour(moment(data.date ? data.date : task.createdAt).format('HH:mm'));
                if (data.nbReading >= 0 && data.nbClic >= 0) {
                    setNbReading(data.nbReading);
                    setNbClic(data.nbClic);
                    setError({msg: t('stats_updated'), errorCode: 200})
                } else {
                    setError({msg: t('stats_parsing_error'), errorCode: 500})
                }
                setRefresh(false);

                setHasDetailledStats(newData.length > 0)
            }, {
                'taskId': task.id
            })
        } // eslint-disable-next-line
    }, [refresh]);

    useEffect(() => {
        if (task && task.taskType && task.taskType.isMailing) {
            try {
                const stats = task && task.taskMailingStats && task.taskMailingStats.stats
                && task.taskMailingStats.stats.length > 5
                    ? JSON.parse(task.taskMailingStats.stats) : false;
                setDate(moment(stats.date ? stats.date : task.createdAt).format('DD/MM/YYYY'));
                setHour(moment(stats.date ? stats.date : task.createdAt).format('HH:mm'));
                setNbReading(task && task.taskMailingStats && task.taskMailingStats.nbReading ? task.taskMailingStats.nbReading
                    : task && task.taskMailingStats ? 0 : null);
                setNbClic(task && task.taskMailingStats && task.taskMailingStats.nbClic ? task.taskMailingStats.nbClic : 0);
                const newLabels = [];
                const newData = [];
                const urlsJson = stats && stats.urls ? stats.urls : {};
                const urls = Object.keys(urlsJson);
                const newDataSet = [...dataset.datasets];
                urls.forEach(url => {
                    newLabels.push(url);
                    newData.push(urlsJson[url].count);
                });
                newDataSet[0].data = newData;
                setDataset(
                    {
                        ...dataset,
                        labels: newLabels,
                        datasets: newDataSet
                    }
                );
                setHasDetailledStats(newData.length > 0)
            } catch (e) {
                adminFetchHandler(t('stats_parsing_error'))
            }
        } // eslint-disable-next-line
    }, [task]);

    if (!task || !task.taskType || !task.taskType.isMailing) {
        return null;
    }


    const handleRefreshButton = () => {
        setRefresh(true);
    };

    const canRefreshStats = (task) => {
        return typeof task?.taskMailingStats?.mailingJournalId !== 'undefined'
            && task?.contacts['hydra:member']?.length > 0
        ;
    }

    return (
        <>
            <div
                className={`col-6 data__group`}>
                <div className={'data__stats-header'}>
                    <p className={'data__title--left'}>{t('stats_task')} <span
                        className={'data__info__label--time'}>{t('stats_timestamp', {
                        date: date,
                        time: hour
                    })}</span></p>
                    {canRefreshStats(task) ? (
                        <button className={'btn btn-primary data__title__edit__btn data__title__edit__btn__large'}
                                disabled={refresh}
                                onClick={() => handleRefreshButton()}
                        >
                            <span
                                className={'data__title__edit__btn__icon data__title__edit__btn__icon__large'}>
                                <FontAwesomeIcon icon={faSync} className={refresh ? 'spin' : ''}/>
                            </span>
                            {t('refresh')}
                        </button>) : null}
                </div>
                {task.taskMailingStats ? (
                    <div>

                        <div className={'row data__prospect__row data__prospect__container'}>
                            <div
                                className={`col-12 data__info`}
                            >
                                <div className={`data__info__text even`}>
                            <span className={'data__info__label'}>
                                {t('nb_readings')}
                                                        </span>
                                    <span className={'data__info__value'}>
                                                       {nbReading}
                        </span>
                                </div>
                                <div className={`data__info__text`}>
                            <span className={'data__info__label'}>
                                {t('nb_clic')}
                                                        </span>
                                    <span className={'data__info__value'}>
                                                       {nbClic}
                        </span>
                                </div>
                            </div>
                        </div>
                        <div className={'data__stats-header'}>
                            <p className={'data__title--left'}>{t('stats_details_task')} </p>
                        </div>
                        {hasDetailledStats ? (
                            <HorizontalBar
                                data={
                                    dataset
                                }
                                width={100}
                                height={50}
                                options={{
                                    scales: {
                                        yAxes: [
                                            {
                                                ticks: {
                                                    mirror: true,
                                                    fontColor: "#372E74",
                                                    beginAtZero: true
                                                }
                                            }
                                        ],
                                        xAxes: [
                                            {
                                                ticks: {
                                                    stepSize: 1,
                                                    beginAtZero: true
                                                }
                                            }
                                        ]
                                    }
                                }}
                            />
                        ) : t('aucun_resultat_trouve')}
                    </div>
                ) : t('no_mailing')}
            </div>

            <div
                className={`col-6 data__group`}>
                <div className={'data__stats-svg'}>
                    <img className={'data__stats-img'} src={statsMailingSVG} alt={t('stats_details_task')}/>
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setError: (error) => dispatch(setError(error)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(React.memo(TaskStats)));



export default function downloadBlob(blob, fileName) {
    const downloadLink = document.createElement("a");
    const urlObject = URL.createObjectURL(blob);

    downloadLink.style.display = "none";
    downloadLink.href = urlObject;
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.dispatchEvent(new MouseEvent("click"));
    URL.revokeObjectURL(urlObject)
}

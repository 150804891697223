import {PolIcon} from "components/PolIcon/policon";
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import {useFrontContext} from "v4/contexts/FrontContext";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {PROSPECT_ITEM, PROSPECT_ITEM_FORM} from "v4/data/apiRoutes";
import {routesBase} from "v4/data/appRoutes";
import {TAB_CONTACTS, TAB_OPEN_TASKS} from "v4/data/tabNames";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import ShowEntityLayout from "v4/layouts/ShowEntityLayout/ShowEntityLayout";
import ProspectViewActions
    from "v4/pages/front/Prospect/components/ProspectView/components/ProspectViewActions/ProspectViewActions";
import ProspectViewSubscriptions
    from "v4/pages/front/Prospect/components/ProspectView/components/ProspectViewSubscriptions/ProspectViewSubscriptions";
import 'v4/pages/front/Prospect/components/ProspectView/ProspectView.scss';
import useFetch from "../../../../../hooks/useFetch";
import {generateUrl} from "../../../../../services/api.service";

export default function ProspectView({isExpanded, toggleExpanded}) {
    const {t} = usePolTranslation();
    const [isEditMode, toggleEditMode] = useToggle();
    const [prospectHeaderInfos, setProspectHeaderInfos] = useState(null);
    const {addLastVisit} = useFrontContext();
    const {prospectId, currentTabName, setMapProspect, setProspect} = useProspectPageContext();

    useEffect(() => {
        if (isEditMode && !isExpanded) toggleExpanded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode])

    const onGetData = useCallback((data) => {
        const hydraMember = data?.['hydra:member'];
        if (hydraMember) {
            if (currentTabName !== TAB_CONTACTS) {
                addLastVisit({
                    name: hydraMember.metadata.fullname ?? t('Prospect'),
                    prospectId: prospectId,
                    type: hydraMember.metadata.isBusiness ? 'Entreprise' : 'Particulier',
                    url: `${routesBase.baseProspect}/${prospectId}/${TAB_OPEN_TASKS}`,
                });
            }
            const [prospect, prospectForm] = Object.values(hydraMember.results).reduce((acc, {properties}) => {
                properties.forEach(input => {
                    acc[0][input.key] = input.value;
                    acc[1][input.key] = input;
                })
                return acc;
            }, [{}, {}]);
            prospect.metadata = {prospectForm, ...hydraMember.metadata};
            setProspect(prospect);
            setMapProspect(hydraMember);
            setProspectHeaderInfos(hydraMember.metadata);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isExpanded && isEditMode) toggleEditMode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded]);

    const {replace, location: {state}} = useHistory();
    const whenSubmitted = useCallback(() => {
        toggleEditMode();
        replace({state: {hasToRefresh: true}})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [{data, isError}, fetchProspectForSubscriptions] = useFetch();
    useEffect(() => {
        const {verifyProspectSubscriptions, ...restState} = state ?? {};
        if (verifyProspectSubscriptions) {
            fetchProspectForSubscriptions({
                url: generateUrl(PROSPECT_ITEM_FORM, {id: prospectId}),
            });
            replace({state: restState});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (data?.['hydra:member'] && !isError) {
            setProspectHeaderInfos(data?.['hydra:member'].metadata);
        }
    }, [data, isError]);

    const fetchFormParameters = useMemo(() => ({
        id: prospectId
    }), [prospectId]);

    return (
        <div className="prospect__view">
            <div className="prospect__view__header">
                <div className="prospect__view__header__title">
                    {prospectHeaderInfos
                        ? (
                            <>
                                <PolIcon icon={prospectHeaderInfos?.isBusiness ? 'company' : 'client'}/>
                                <span>{prospectHeaderInfos.fullname}</span>
                            </>
                        )
                        : <span>{t('loading')}</span>}
                </div>
                <span className="prospect__view__header__close" onClick={toggleExpanded}>
                <PolIcon icon="close"/>
            </span>
            </div>
            {!isEditMode && (
                <div className="prospect__view__actions">
                    <ProspectViewActions isEditMode={isEditMode}
                                         toggleEditMode={toggleEditMode}
                                         hasGeoloc={prospectHeaderInfos?.lat}
                                         isUnknown={prospectHeaderInfos?.isUnknown}
                                         prospectId={prospectId}/>
                    <div className="prospect__view__actions__expand" onClick={toggleExpanded}>
                        {isExpanded
                            ? (
                                <>
                                    <PolIcon icon="arrow-left"/>
                                    <span>{t('show_less')}</span>
                                </>
                            )
                            : (
                                <>
                                    <span>{t('show_more')}</span>
                                    <PolIcon icon="arrow-right"/>
                                </>
                            )
                        }
                    </div>
                </div>
            )}
            <div className={`prospect__render${isEditMode ? ' editing' : ''}`}>
                {isEditMode
                    ? <FormEntityLayout formRouteName={PROSPECT_ITEM_FORM}
                                        submitRouteName={PROSPECT_ITEM}
                                        fetchFormParameters={fetchFormParameters}
                                        onGetData={onGetData}
                                        onBack={toggleEditMode}
                                        whenSubmitted={whenSubmitted}/>
                    : <ShowEntityLayout entityRouteName={PROSPECT_ITEM_FORM}
                                        onGetData={onGetData}
                                        entityId={prospectId}/>
                }
                {
                    prospectHeaderInfos?.prospectSubscription?.length > 0 && !isEditMode && (
                        <ProspectViewSubscriptions prospectSubscriptions={prospectHeaderInfos.prospectSubscription} isExpanded={isExpanded}/>
                    )
                }
            </div>
        </div>
    )
}

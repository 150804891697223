import {useEffect, useMemo} from "react";
import {useFormContext, useWatch} from "react-hook-form";

export default function useDependsOnField(currentFieldName, dependsOnField, choices) {
    const {fieldKey, searchFilter, choices: dependentChoices} = dependsOnField ?? {};

    const dependsOnFieldValue = useWatch({
        name: fieldKey,
        disabled: !fieldKey
    });

    const {resetField} = useFormContext();
    useEffect(() => {
        if (!fieldKey) return;

        if (dependsOnFieldValue?.length > 0) return;

        resetField(currentFieldName);
    }, [dependsOnFieldValue, fieldKey, resetField, currentFieldName]);

    const dependsOnFieldChoices =  useMemo(() => {
        return dependentChoices?.[dependsOnFieldValue] ?? choices ?? [];
    }, [choices, dependentChoices, dependsOnFieldValue]);

    const autocompleteAdditionnalSearchFilter = useMemo(() => ({
        filter: searchFilter,
        value: Object.keys(dependsOnFieldValue).length ? dependsOnFieldValue : undefined
    }), [searchFilter, dependsOnFieldValue]);

    return {
        dependsOnFieldChoices,
        autocompleteAdditionnalSearchFilter,
    };
}

export default function useObjectToGETUrl(route, search = {}) {
    const searchParams = new URLSearchParams();

    Object.entries(search).forEach(([key, value]) => {
        if (typeof value === 'object') {
            return searchParams.append(key, JSON.stringify(value));
        }

        if (!value) return;

        searchParams.append(key, value.toString());
    });

    return {
        pathname: `${route}`,
        search: searchParams.toString(),
        fullUrl: `${route}?${searchParams.toString()}`,
    }
}

import {getLSItem} from "v4/services/localStorage.service";

export default function getDatetimeFormat(includeTime = false) {
    const locale = getLSItem('i18nextLng', 'string');

    if (locale?.startsWith('en')) {
        return includeTime ? 'MM/DD/YYYY hh:mm' : 'MM/DD/YYYY';
    }

    return includeTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
}

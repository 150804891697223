import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Confirm from "v4/components/ui/Confirm/Confirm";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {QUOTE_SPLIT} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import {generateUrl} from "v4/services/api.service";

export default function ProspectQuotesSplitButton() {
    const {t} = usePolTranslation();
    const [{isError, isFinished, isLoading}, fetchSplit] = useFetch();
    const {quote} = useProspectPageContext();

    const onSplit = () => {
        fetchSplit({
            url: generateUrl(QUOTE_SPLIT, {id: quote.metadata.id}),
            config: {
                method: 'POST',
            }
        })
    }

    const {addErrorNotification, addSuccessNotification} = useNotification();
    const {replace} = useHistory();
    useEffect(() => {
        if (isFinished && !isError) {
            addSuccessNotification(t('split_success'));
            replace({state: {hasToRefresh: true, showHasToRefresh: true}})
            toggleOpen();
        }
        if (isFinished && isError) {
            addErrorNotification(t('quote_form_edit_error'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished, isError]);

    const [isOpen, toggleOpen] = useToggle();

    const hasQuoteSections = quote?.quoteLines?.some(quoteLine => quoteLine.sectionName);
    const {parent, children} = quote?.metadata ?? {};
    if (!hasQuoteSections || !quote || (parent || (children && children.length > 0))) return null;

    return (
        <>
            <button className="prospect-tab__prospect__header__action"
                    data-tip={t('split_tip')}
                    data-for="split-button"
                    onClick={toggleOpen}>
                <PolIcon icon="flow"/>
            </button>
            <ReactTooltip id="split-button" effect="solid"/>
            <Confirm isOpen={isOpen}
                     cancelAction={toggleOpen}
                     confirmAction={onSplit}
                     title={t('confirm-action')}
                     isLoading={isLoading}>
                <p>{t('split_confirm')}</p>
            </Confirm>
        </>
    )
}

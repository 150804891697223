import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLengthClassname, getSpecifiClassname} from "../../api/datas/datas";
import {DocumentList} from "./documentList";
import {formatForView} from "../../utils/viewTypeFormatter";
import {searchFieldKeyInViewOrders, getLabelOfFieldInViewOrder} from "../../utils/viewOrdersManager";
import UserService from "../../api/user/user";
import {postAdmin} from "../../api/datas/post";
import {withHttp} from "../../utils/clicHandler";
import {PolIcon} from "../PolIcon/policon";
import TaskStats from "./TaskStats";
import QuotesProductsDetails from "../../v4/features/front/products/components/QuotesProductsDetails/QuotesProductsDetails";
import {CONDITION_VALUE, TASK_TYPE} from "../Form/dropdownOrders";
import {withPolTranslation} from "../../v4/hooks/usePolTranslation";

export const handleLiveoCall = (phone, name, setError, t, removeError) => {
    postAdmin('/api/liveo_calls', () => {
        setError({msg: t('error_liveo'), errorCode: 500})
        setTimeout(() => {
            removeError()
        }, 1500)
    }, () => {
        setError({msg: t('calling'), errorCode: 200})
        setTimeout(() => {
            removeError()
        }, 1500)
    }, {
        contactName: name,
        number: phone
    })
}

class View extends Component {
    /**
     * Renvoie true si les conditions d'affichage de l'input sont respectées (fonctionne uniquement pour les taskTypes)
     * @param {Object} input
     * @returns {boolean}
     */
    controlConditionOfInput = (input) => {
        const {order_entity, datas} = this.props;
        if ('Task' !== order_entity || !input[CONDITION_VALUE]) {
            return true;
        }

        let taskTypeId = null;
        if(datas && datas.prospects) {
            Object.keys(datas.prospects).forEach((prospect) => {
                Object.keys(datas.prospects[prospect]).forEach((field) => {
                    if (datas.prospects[prospect][field] && datas.prospects[prospect][field][TASK_TYPE]) {
                        taskTypeId = datas.prospects[prospect][field][TASK_TYPE].id;
                    }
                });
            })
        }

        return input[CONDITION_VALUE] === taskTypeId;
    }

    render() {
        const {
            t, data, classLoader = '', updateView, deleteService, setPopup, isExpanded, setError,
            removeError, order_entity, task, nbReading, setNbReading, removePdfFile
        } = this.props;
        let isEvenChild = true;
        const createdAtParams = searchFieldKeyInViewOrders(data, 'createdAt');
        const updatedAtParams = searchFieldKeyInViewOrders(data, 'updatedAt');
        const createdByParams = searchFieldKeyInViewOrders(data, 'createdByRealName');
        const updatedByParams = searchFieldKeyInViewOrders(data, 'updatedByRealName');
        const hasContactField = searchFieldKeyInViewOrders(data, 'firstname');
        const firstname = hasContactField ? hasContactField : searchFieldKeyInViewOrders(data, 'contact_firstname');
        const lastname = hasContactField ? searchFieldKeyInViewOrders(data, 'lastname') : searchFieldKeyInViewOrders(data, 'contact_lastname');
        const name = searchFieldKeyInViewOrders(data, 'company_name');

        const contactName = firstname && lastname ? data[lastname.groupKey].fields[lastname.index].value
            + ' ' + data[firstname.groupKey].fields[firstname.index].value : name ? data[name.groupKey].fields[name.index].value : '';

        return (
            <div className={'row row-fluid'}>
                {data.map((item, index) => {
                    const subData = item.fields;
                    let columnsWidth = 0;

                    return (
                        <div
                            className={`col-12 data__group ${getLengthClassname(item.width)} ${getSpecifiClassname(item.label)}`}
                            key={index}>

                            {item.label !== 'unmapped_fields' ? (
                                <p className={'data__title--left'}>{t(item.label)}</p>
                            ) : null}

                            <div className={'row data__prospect__row data__prospect__container'}>
                                {
                                    subData.map((item, key) => {
                                        if (!window.labels) {
                                            window.labels = {}
                                        }
                                        if (item.label && item.label.length) {
                                            window.labels[item.key] = item.label;
                                        }
                                        let {width, value, label} = item;
                                        let className = getSpecifiClassname(label);
                                        let classNameLength = getLengthClassname(width);
                                        if (item.id && ((item.id).endsWith('_lat') || (item.id).endsWith('_lng') || item.type === 'unmapped')) {
                                            return null;
                                        }
                                        if (item && item.specificFieldType
                                            && item.specificFieldType === 'CheckboxType'
                                            && (typeof item.value === 'boolean' || item.value === 'Oui'
                                                || item.value === 'Non'
                                                || item.value === "1" || item.value === '0'
                                            )
                                        ) {
                                            item.valueType = 'boolean'
                                        }
                                        if (item.isHidden) {
                                            return null;
                                        }
                                        columnsWidth += parseInt(width);
                                        if (columnsWidth > 100) {
                                            columnsWidth = width;
                                            isEvenChild = isEvenChild !== true;
                                        }

                                        className += isEvenChild ? ' even' : ' odd';
                                        const enableLiveo = UserService.isLiveo();

                                        return (
                                            <div
                                                className={`col-12 ${classNameLength} data__info ${item.key === 'quoteLines' ? '' : className}`}
                                                key={key}>
                                                <div
                                                    className={`data__info__text${item.key === 'quoteLines' ? ' quote-lines' : ''}${(item.specificFieldType && item.specificFieldType === "CheckboxType") ? ' data__info__text--details' : ''} ${classLoader}${this.controlConditionOfInput(item) ? '' : ' d-none'}`}>
                                                    {
                                                        item.key === 'quoteLines'
                                                            ? <QuotesProductsDetails isShow={true}/>
                                                            : <>
                                                                {label || (window.labels && window.labels[item.key]) || getLabelOfFieldInViewOrder(order_entity, item.key) ? (
                                                                    <span className={'data__info__label'}>
                                                            {t(label ? label : window.labels && window.labels[item.key] ? window.labels[item.key] : getLabelOfFieldInViewOrder(order_entity, item.key))} :
                                                        </span>
                                                                ) : null}
                                                                <span className={`data__info__value`}
                                                                      title={typeof value === 'string' && value.length > 15 ? value : ''}>
                                                                {typeof value !== 'object' &&
                                                                ((!item.valueType || ['required', 'unique', 'readOnly', 'email'].indexOf(item.valueType) !== -1)) ?
                                                                    formatForView(value, item.valueType) : ''}
                                                                    {item.valueType && item.valueType === 'files' ?
                                                                        <DocumentList
                                                                            files={value}
                                                                            updateView={updateView}
                                                                            groupKey={index}
                                                                            fieldKey={key}
                                                                            deleteService={deleteService}
                                                                            setPopup={setPopup}
                                                                            removePdfFile={removePdfFile}
                                                                        />
                                                                        : null}
                                                                    {item.valueType && item.valueType === 'website' && value ?
                                                                        <a href={withHttp(value)} rel="noopener noreferrer"
                                                                           target="_blank">{value}</a>
                                                                        : null}
                                                                    {item.valueType && item.valueType === 'boolean' ?
                                                                        value === "1" ? t('yes') : value === "0" ? t('no') : value
                                                                        : null}
                                                                    {item.valueType && item.valueType === 'html' && value ?
                                                                        <span
                                                                            className={'data__info__text__area'}>{value}</span>
                                                                        : null}
                                                                    {(item.valueType && (item.valueType === 'money' || item.valueType === 'TelType' || item.valueType === 'percent')) ?
                                                                        <>
                                                                            {enableLiveo && contactName && value && item.valueType === 'TelType' ? (
                                                                                <>
                                                                                    <PolIcon icon={'phone'}/>
                                                                                    <span
                                                                                        className={'data__info__phone'}
                                                                                        onClick={() => handleLiveoCall(value, contactName, setError, t, removeError)}>
                                                                                    {' ' + formatForView(value, item.valueType)}
                                                                                </span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {formatForView(value, item.valueType)}
                                                                                </>)}
                                                                        </> : null}
                                                                    {item.valueType && item.valueType === 'texte' && value ?
                                                                        <span className={'data__info__text'}>{value}</span>
                                                                        : null}
                                                            </span>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    )
                })}
                {order_entity === 'Task' ? (
                    <TaskStats task={task} nbReading={nbReading} setNbReading={setNbReading}/>
                ) : null}
                {isExpanded === true || isExpanded === undefined ? (
                    <div className={'row row-fluid data__container'}>
                        <div className={"col-12"}>
                            <div className={"row flex-col-end"}>
                                {createdAtParams ? (
                                    <div
                                        className={`col-12 data__info data__info__timestamp ${isExpanded === true ? 'data__info__transparent' : null}`}>
                                        <div className={`data__info__sub-text`}>
                                            <span className={'data__info__label-info'}>{t('createdAt_info')}</span>
                                            <span
                                                className={'data__info__value'}>{data[createdAtParams.groupKey].fields[createdAtParams.index].value}</span>
                                            {createdByParams ? (
                                                <>
                                                    <span className={'data__info__label-info'}> {t('by')}</span>
                                                    <span
                                                        className={'data__info__value'}>{data[createdByParams.groupKey].fields[createdByParams.index].value}</span>

                                                </>) : null}
                                        </div>
                                    </div>) : null}
                                {updatedAtParams ? (
                                    <div
                                        className={`col-12 data__info data__info__timestamp ${isExpanded === true ? 'data__info__transparent' : null}`}>
                                        <div className={`data__info__sub-text`}>
                                            <span className={'data__info__label-info'}>{t('updatedAt_info')}</span>
                                            <span
                                                className={'data__info__value'}>{data[updatedAtParams.groupKey].fields[updatedAtParams.index].value}</span>
                                            {updatedByParams ? (
                                                <>
                                                    <span className={'data__info__label-info'}> {t('by')}</span>
                                                    <span
                                                        className={'data__info__value'}>{data[updatedByParams.groupKey].fields[updatedByParams.index].value}</span>
                                                </>) : null}
                                        </div>
                                    </div>) : null}
                            </div>
                        </div>
                    </div>) : null}
            </div>
        )
    }
}

export default connect(
    null,
    null
)(withPolTranslation(View));

import React, {useState} from 'react';
import {useFormContext} from "react-hook-form";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import 'v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/components/AsideExtendedFiltersHeader/AsideExtendedFiltersHeader.scss';

export default React.memo(function AsideExtendedFiltersHeader({field, defaultField}) {
    const {t} = usePolTranslation();
    const [currentFilter, setCurrentFilter] = useState(defaultField.length ? defaultField : field.choices[0]?.value);
    const filters = Array.isArray(field?.choices) ? field.choices : [];
    const {setValue} = useFormContext();

    const createExtendedFilter = (filter, key) => {
        return (
            <li key={key} className={`extended-filters__tab ${filter.value === currentFilter ? 'active' : ''}`}
                onClick={() => setValueOfExtendedFilter(filter.value)}>
                {t(filter.label)}
            </li>
        );
    }

    const setValueOfExtendedFilter = (value) => {
        const finalValue = field.multiple ? [value] : value;
        setValue(field.key, finalValue);
        setCurrentFilter(value);
    }

    return (
        <ul className="extended-filters__wrapper">
            {filters?.map(createExtendedFilter)}
        </ul>
    )
})

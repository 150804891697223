export const setStickyMenu = (isSticky) => {
    return {
        type: 'SET_STICKY_MENU',
        isSticky
    }
};

export const setCustomersList = (customersList) => {
    return {
        type: 'SET_CUSTOMERS_LIST',
        customersList: customersList
    }
}

export const updateCustomerList = () => {
    return {
        type: 'UPDATE_CUSTOMER_LIST'
    }
}

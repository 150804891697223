import {
    atom,
    selector
} from 'recoil';

export const customerState = atom({
    key: 'customer-state',
    default: null,
});

export const customerIdSelector = selector({
    key: 'customer-id-selector', // unique ID (with respect to other atoms/selectors)
    get: ({get}) => {
        const customer = get(customerState);
        return customer ? customer.id : null;
    },
});
import React from 'react';
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import ProspectTasksTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTasksTabPartial/ProspectTasksTabPartial";
import ProspectTasksCustomTabActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectTasksCustomTab/components/ProspectTasksCustomTabActions/ProspectTasksCustomTabActions";
import ProspectTasksCustomTabRowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectTasksCustomTab/components/ProspectTasksCustomTabRowActions/ProspectTasksCustomTabRowActions";

export default function ProspectTasksCustomTabListing({prospectId, tab}) {

    return (
        <div className="prospect-tab__listing__wrapper">
            <ProspectHeadingTabPartial title={tab.name} actions={(props) => <ProspectTasksCustomTabActions {...props}/>}/>
            <ProspectTasksTabPartial fetchParams={{tabId: tab.id}}
                                     prospectId={prospectId}
                                     rowActions={ProspectTasksCustomTabRowActions}/>
        </div>
    )
}

import {captureException} from "@sentry/react";
import React, {Component} from 'react';
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "../PolIcon/policon";
import Maps from "../Maps/maps";
import {Redirect} from "react-router-dom";
import {baseProspect, baseProspectContent} from "../../routes";
import {setGeneralClass, setExpandInfo, setRedirectToList} from "../../actions/content";
import {setData} from '../../actions/datas';
import Loading from "../Loading/Loading";

const infoMaps = {
    formUrl: "/api/prospects/search/form",
    submitUrl: "/api/prospects/search/form?pagination=false",
    name: "maps",
    loader: "datatable_search",
    submitLoader: "form-search",
    type: "search",
};

class MapsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            init: false,
            redirect: null,
            loader: false,
            info: {},
            prospect: {},
            information: {},
            data: [],
            defaultRedirect: '',
            fullname: {
                value: ''
            },
            hasProspect: true,
            map: {
                lat: 51.505,
                lng: -0.09,
                zoom: 14,
            },
            markers: [],
            isLoading: false
        }
        this.setLoading = this.setLoading.bind(this);
        this.setSearchDistance = this.setSearchDistance.bind(this);
    }

    componentDidMount() {
        const {setGeneralClass, location: {state = {}}, match: {params}, maps} = this.props;
        let {map, markers} = this.state;
        const defaultRedirect = `${baseProspect}/${params.id}/${baseProspectContent}`;

        setGeneralClass('is-map');

        if (!state.prospect || !Object.keys(state.prospect).length) {
            // Si affichage de la map sans prospect ciblé (Liste prospect -> Map)
            if (!maps || !maps.tableSearch || !maps.tableSearch.datas) {
                // Si pas de data
                const err = new Error("Pas de liste prospect pour l'affichage de la carte")
                captureException(err);
                throw err
            }

            const data = maps.tableSearch.datas;
            const newDatas = [];
            let firstLat, firstLng = 0;

            data.forEach((item, index) => {
                if (item.mainAddress &&
                    item.mainAddress.lat &&
                    item.mainAddress.lng
                ) {
                    if (firstLat === 0 || firstLng === 0) {
                        firstLat = item.mainAddress.lat
                        firstLng = item.mainAddress.lng
                    }
                    markers.push({
                        lat: item.mainAddress.lat,
                        lng: item.mainAddress.lng,
                        isMain: false
                    });
                    newDatas.push(item)
                }
            });

            this.setState({
                fullname: 'Recherche prospect',
                init: true,
                markers: markers,
                map: {
                    ...map,
                    lat: firstLat,
                    lng: firstLng
                },
                hasProspect: false,
                data: newDatas
            })

            return false;
        }

        // Si recherche autour d'un prospect
        if (state && state.prospect && Object.keys(state.prospect).length) {
            const data = state.prospect;
            const lat = data.mainAddress.lat;
            const lng = data.mainAddress.lng;

            markers.push({
                lat: lat,
                lng: lng,
                isMain: true
            });

            this.setState({
                information: state.prospect,
                prospect: state.prospect,
                fullname: data.fullname,
                init: true,
                map: {
                    ...map,
                    lat: lat,
                    lng: lng
                },
                markers: markers,
                data: [
                    state.prospect
                ],
                hasProspect: true
            })
        }

        this.setState({
            defaultRedirect: defaultRedirect
        })
    }

    componentWillUnmount() {
        this.props.setGeneralClass('');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            const {maps} = this.props;
            const {markers, prospect} = this.state;
            let mainMarker = markers[0];
            let newMarkers = [];
            let newDatas = [];
            if (Object.keys(prospect).length > 0) {
                newMarkers.push(mainMarker);
            }
            newDatas.push(prospect);

            if (maps &&
                maps.tableSearch &&
                maps.tableSearch.datas &&
                prevProps.maps !== maps
            ) {
                const data = maps.tableSearch.datas;
                data.forEach(item => {
                    if (item.mainAddress &&
                        item.mainAddress.lat &&
                        item.mainAddress.lng && (
                            item.mainAddress.lat !== mainMarker.lat ||
                            item.mainAddress.lng !== mainMarker.lng
                        )) {
                        newMarkers.push({
                            lat: item.mainAddress.lat,
                            lng: item.mainAddress.lng,
                            isMain: false
                        });

                        newDatas.push(item);
                    }
                });

                this.setState({
                    data: newDatas,
                    markers: newMarkers,
                    isLoading: false
                })
            }
        }
    }

    clickProspect = (index) => {
        const {data} = this.state;

        this.setState({
            information: data[index]
        });
    };

    switchToList = () => {
        const {maps} = this.props;
        const {map: {distance, lat, lng}} = this.state;
        if (maps) {
            this.setState({
                redirect: distance ? <Redirect to={{
                        pathname: baseProspect,
                        state: {
                            from: this.props.location.pathname,
                            additionalSearch: {
                                lat,
                                lng,
                                distance: distance
                            }
                        }
                    }}/> :
                    <Redirect to={baseProspect}/>
            });
        }
    }

    setLoading(isLoading) {
        this.setState({isLoading: isLoading});
    }

    setSearchDistance(searchDistance) {
        this.setState({
            map: {
                ...this.state.map,
                distance: searchDistance
            }
        })
    }

    render() {
        const {
            redirect,
            init,
            defaultRedirect,
            map,
            markers,
            information,
            fullname,
            hasProspect,
            isLoading
        } = this.state;
        const {t, maps} = this.props
        let shouldToListDisplay = maps?.tableSearch?.datas.length === markers.length ||  maps?.tableSearch?.datas.length+1 === markers.length;

        return (
            <div className={'mapspage'} id={'mapspage'}>
                {redirect}

                {init ? (
                    <>
                        <div className={'row mapspage__header'}>
                            <div className={'col-12 col-md-5 company'}>
                                <div>
                                    <PolIcon icon={'building'} className={'company__icon'}/>
                                    <p className={'company__name close__icon'} onClick={() => {
                                        if (hasProspect) {
                                            this.setState({
                                                redirect: <Redirect to={defaultRedirect}/>
                                            })
                                        } else {
                                            this.switchToList();
                                        }
                                    }}>{fullname}</p>
                                </div>
                                <PolIcon icon={'sign-out'} className={'company__icon-close d-md-none'}
                                         onClick={() => {
                                             if (hasProspect) {
                                                 this.setState({
                                                     redirect: <Redirect to={defaultRedirect}/>
                                                 })
                                             } else {
                                                 this.switchToList();
                                             }
                                         }}/>
                            </div>
                            <div className={'col-12 col-md-2 d-none d-md-block map-icon'}>
                                <PolIcon icon={'map'} className={'map-icon__icon'}/>
                            </div>
                            {shouldToListDisplay ? (
                                <div className={'col-12 col-md-2 d-none d-md-block'} onClick={this.switchToList}>
                                    {t('swtich_to_list')}
                                </div>) : null}
                            <div className={`col-12 col-md-${maps ? 2 : 3} d-none d-md-block close`}>
                                <PolIcon icon={'sign-out'} className={'close__icon'}
                                         onClick={() => {
                                             if (hasProspect) {
                                                 this.setState({
                                                     redirect: <Redirect to={defaultRedirect}/>
                                                 })
                                             } else {
                                                 this.switchToList();
                                             }
                                         }}/>
                            </div>
                        </div>
                        <Maps map={map} info={infoMaps} clickProspect={(index) => this.clickProspect(index)}
                              prospect={information}
                              markers={markers}
                              setLoading={this.setLoading}
                              setSearchDistance={this.setSearchDistance}
                              isLoading={isLoading}
                              currentLocation={this.props.location.pathname}/>

                        {isLoading &&
                            <Loading message={t("loader_message_search")} specificClass={"form-loader"}/>
                        }
                    </>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = ({datas: {maps}}) => ({
    maps
});

const mapDispatchToProps = dispatch => {
    return {
        setGeneralClass: (className) => dispatch(setGeneralClass(className)),
        setDataListProspect: (title, dataType, datas) => dispatch(setData(title, dataType, datas)),
        setExpandInfo: (expand) => dispatch(setExpandInfo(expand)),
        setRedirectToList: (redirectToList) => dispatch(setRedirectToList(redirectToList))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(MapsPage));

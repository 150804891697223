import React, {createContext, useContext, useMemo, useReducer} from 'react';

const debugBarContextDefaultValue = {
    tokens: {},
    addToken: (token, timestamp) => {},
}

const debugBarContext = createContext(debugBarContextDefaultValue);

export function useDebugBarContext() {
    return useContext(debugBarContext);
}

function debugBarContextReducer(state, action) {
    switch (action.type) {
        case 'ADD_TOKEN':
            return {
                ...state,
                tokens: {...state.tokens, ...action.payload}
            }
        default:
            return state;
    }
}

export default function DebugBarContext({children}) {
    const [state, dispatch] = useReducer(debugBarContextReducer, debugBarContextDefaultValue);

    const valueToProvide = useMemo(() => ({
        ...state,
        addToken: (token, timestamp) => dispatch({type: 'ADD_TOKEN', payload: {[token]: timestamp}})
    }), [state]);

    return (
        <debugBarContext.Provider value={valueToProvide}>
            {children}
        </debugBarContext.Provider>
    )
}

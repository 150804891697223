import React from 'react';
import {Bar} from "react-chartjs-2";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useDashboardQuotesGraph
    from "v4/pages/front/Dashboard/components/DashboardQuotesGraph/hooks/useDashboardQuotesGraph";
import {financial} from "v4/utils";

export default function DashboardQuotesGraph() {
    const {t} = usePolTranslation();
    const data = useDashboardQuotesGraph();

    return (
        <div className="dashboard-quotes-graph">
            <p className="dashboard-quotes-graph__title">{t('the_sales')}</p>
            <div className="dashboard-quotes-graph__graph">
                <Bar
                    data={data}
                    options={{
                        maintainAspectRatio: false,
                        legend: {position: 'bottom'},
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    callback: financial,
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: ({yLabel}) => financial(yLabel)
                            }
                        },
                    }}
                />
            </div>
        </div>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {useFormContext} from "react-hook-form";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ProspectsAddAdditionalButtons() {
    const {t} = usePolTranslation();
    const {register, formState: {isValid}} = useFormContext();

    const handleClick = () => {
        if (isValid) {
            register('redirectToContact', {value: {value: true, shouldBeSubmitted: false}});
        }
    }

    return (
        <div>
            <button type="submit" className="btn btn-primary submit-and-add-prospect" onClick={handleClick}>
                <PolIcon icon="user-link"/>
                <span>
                {t('add_prospect_compagny_and_create_contact')}
                </span>
            </button>
        </div>
    )
}

import React, {Component} from 'react'
import {connect} from 'react-redux';
import {getApiUrl, routes} from "../../routes";
import UserService from "../../api/user/user";
import MailsTypesPage from "v4/pages/front/MailsTypes/MailsTypesPage";

class Mail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iframePage: '',
            user: {sfiMailingHosts: ''},
            info: {
                name: 'mails',
                id: 'table',
                type: 'datas',
                url: getApiUrl('mails', 'list'),
                loader: 'table-mails',
                route: 'mails'
            },
            contactIds: props.location && props.location.state && props.location.state.page && props.location.state.page === 'contacts'
                && props.location.state.contactIds ? props.location.state.selectedRows : [],
            prospectIds: props.location && props.location.state && props.location.state.page && props.location.state.page === 'prospects'
                && props.location.state.prospectIds ? props.location.state.prospectIds : [],
            campaignId: props.location?.state?.campaignId ?? null,
            component: ''
        }
    }

    componentDidMount() {
        const {location: {pathname}} = this.props;
        const mainRoute = routes.find(item => (item.component === 'mails'));
        const user = UserService.getUser();
        const subMenu = mainRoute.subMenu;

        if (subMenu && subMenu.length) {
            const route = subMenu.find(item => item.path === pathname);

            this.setState({
                iframePage: route.iframePage,
                user: user,
                component: route.component
            });
        }
    }

    getCampaignSuffix = () => {
        const {campaignId} = this.state;

        return null !== campaignId ? '&idCampagnePol=' + campaignId : ''
    }

    render() {
        const {iframePage, user, component} = this.state;
        if (user.sfiMailingHosts !== '' && typeof user.sfiMailingHosts !== 'object') {
            this.setState({
                user: {
                    ...user,
                    sfiMailingHosts: JSON.parse(user.sfiMailingHosts)
                }
            });
        }

        return (
            <>
                {component === 'mails-type' ? (
                    <MailsTypesPage/>
                ) : (
                    <div className={'page page-list main-content__body__slide'}>
                        <div className={'page-list__content'}>
                            <iframe
                                src={`${user.sfiMailingHosts ? `${user.sfiMailingHosts[iframePage]}&env=${process.env.REACT_APP_ENV}` + this.getCampaignSuffix() : ''}`}
                                height="700"
                                width="100%"
                                title={'Mailing'}
                            />
                        </div>
                    </div>
                )}
            </>
        )
    };
}

const mapStateToProps = (state) => ({
    state
});

export default connect(
    mapStateToProps,
    null
)(Mail);

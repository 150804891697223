import {captureException} from "@sentry/react";

export default function toCapitalize(string) {
    try {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (error) {
        captureException(error, {
            extra: {
                string
            }
        })
        return string;
    }
}

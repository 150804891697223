import React, {Component} from 'react'
import {connect} from 'react-redux'
import {setExpandInfo} from "../../actions/content";
import List from "../../components/Page/List";
import {setData} from "../../actions/datas";
import {getApiUrl} from "../../routes";
import {GROUPS} from "../Administration/pages/orders";

class Actions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            info: {
                name: 'actions',
                id: 'table',
                type: 'datas',
                group: 'task',
                url: getApiUrl('tasks', 'list'),
                urlId: getApiUrl('tasks', 'id'),
                loader: 'table-actions',
                route: 'actions',
                routeHistorique: 'historique',
                order_type: 'list',
                order_entity: 'Task',
                subType: props.subType ?? GROUPS['task'],
                prospectIds: props.location && props.location.state ? props.location.state.prospectIds : [],
                contactIds: props.location && props.location.state ? props.location.state.contactIds : [],
                preSearch: props.location && props.location.state && props.location.state.preSearch ? props.location.state.preSearch : null,
                additionalSearch: props.customTabSearch
            }
        }
    }

    componentDidMount() {
        const {info} = this.state;
        const {setExpandInfo, setDatas} = this.props;
        setDatas(info.name, info.id);

        setExpandInfo(null);
    }

    /**
     * @returns {JSX.Element}
     */
    render() {
        const {info} = this.state;
        const {title, customTabSearch} = this.props;
        return (
            <List
                info={info}
                className={'actions'}
                title={title ?? 'liste_actions'}
                customTabSearch={customTabSearch}
            />
        )
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
    return {
        setExpandInfo: (expandinfo) => dispatch(setExpandInfo(expandinfo)),
        setDatas: (name, type, data) => dispatch(setData(name, type, data))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(Actions));
import React, {useMemo, useEffect, useState, useCallback} from 'react';
import Table from "v4/components/ui/Table/Table";
import {Link} from "react-router-dom";
import {routesBase} from 'v4/data/appRoutes'
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import apiService from "v4/services/api.service";
import {OUTLOOK_SUBSCRIPTION_CONFIGURATION_SHOW_LIST} from "v4/data/apiRoutes";
import {PolIcon} from "components/PolIcon/policon";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import useNotification from "v4/hooks/useNotification";
import OutlookSubscriptionConfigurationsListDelete
    from "v4/pages/admin/OutlookSubscriptionConfigurations/OutlookSubscriptionConfigurationsList/components/OutlookSubscriptionConfigurationsListDelete/OutlookSubscriptionConfigurationsListDelete";

export default function OutlookSubscriptionConfigurationsList({customerId}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()

    // Listing
    const [{
        data: loadedOutlookSubscriptionConfigurations,
        isLoading: loadedOutlookSubscriptionConfigurationsLoading,
        isError: loadedOutlookSubscriptionConfigurationsError
    }, fetchOutlookSubscriptionConfigurations] = useFetch();

    const [refetch, setRefetch] = useState(0)

    useEffect(() => {
        fetchOutlookSubscriptionConfigurations({
            url: apiService.generateUrl(OUTLOOK_SUBSCRIPTION_CONFIGURATION_SHOW_LIST, {
                customerId: customerId,
                sortField: "name",
                sortOrder: "asc"
            }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const onDeleteSuccess = useCallback(() => {
        addSuccessNotification(t('delete_outlook_subscription_configuration_done'))
        setRefetch(c => c + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const onDeleteError = useCallback(() => {
        addErrorNotification(t('delete_outlook_subscription_configuration_error'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hydrateOutlookSubscriptionConfigurationRows = useCallback(() => {

        if (!loadedOutlookSubscriptionConfigurations?.['hydra:member']) {
            return null
        }

        const rows = JSON.parse(JSON.stringify(loadedOutlookSubscriptionConfigurations['hydra:member']))

        return (
            rows.map((item, index) => {
                return {
                    label: item.label,
                    folder: item.folder,
                    actions: (
                        <ul className="datatable__actions">
                            <Link key={index} to={`${routesBase.adminMicrosoftGraph}/edit/${item.id}`}>
                                <li className="datatable__actions__item">
                                    <span className="datatable__actions__item__icon">
                                        <PolIcon icon='edit'/>
                                    </span>
                                </li>
                            </Link>
                            <Link key={index} to={`${routesBase.adminMicrosoftGraph}/${item.id}`}>
                                <li className="datatable__actions__item">
                                    <span className="datatable__actions__item__icon">
                                        <PolIcon icon='add'/>
                                    </span>
                                </li>
                            </Link>
                            <OutlookSubscriptionConfigurationsListDelete item={item} onSuccessCallback={onDeleteSuccess}
                                                 onErrorCallBack={onDeleteError}/>
                        </ul>
                    )
                };
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedOutlookSubscriptionConfigurations])

    const tableList = useMemo(() => {
        return <Table tableRows={hydrateOutlookSubscriptionConfigurationRows()} isLoading={loadedOutlookSubscriptionConfigurationsLoading}
                   isError={loadedOutlookSubscriptionConfigurationsError}
                   theadData={Object.keys({label: 'label', folder: 'folder', actions: 'actions'})}/>
    }, [hydrateOutlookSubscriptionConfigurationRows, loadedOutlookSubscriptionConfigurationsLoading, loadedOutlookSubscriptionConfigurationsError])

    const CardHeaderTools = () => (
        <Link to={routesBase.adminMicrosoftGraph + '/add'}
              className="btn btn-primary">{t('add_new_outlook_subscription_configuration')}</Link>
    )

    return (
        <AdminCard title={t('manage_outlook_subscription_configurations')} CardHeaderTools={CardHeaderTools}>
            {tableList}
        </AdminCard>
    )
}

import React, {Component} from 'react';
import Datetime from 'react-datetime';
import {PolIcon} from "../../PolIcon/policon";
import Select from "react-select";

export class InputDateCompare extends Component {
    constructor(props) {
        super(props);
        const {inputData} = this.props;

        this.state = {
            values: {
                equal: inputData.equal ? inputData.equal : '<',
                begin: inputData.begin ? inputData.begin : '',
                end: inputData.end ? inputData.end : '',
            },
            final: inputData.equal ? this.getFinal(inputData.equal, inputData.begin, inputData.end) : '',
        };
    }

    componentDidUpdate(prevProps) {
        const {inputData} = this.props;

        if (prevProps.inputData !== inputData && prevProps.inputData.equal !== inputData.equal) {
            this.setState({
                    values: {
                        equal: inputData.equal,
                        begin: inputData.begin,
                        end: inputData.end,
                        final: this.getFinal(inputData.equal, inputData.begin, inputData.end)
                    }
                }
            )
        }
        if (prevProps.inputData.value !== inputData.value && inputData.value === undefined) {
            this.setState({
                values: {
                    equal: '<',
                    begin: '',
                    end: '',
                },
                final: ''
            })
        }
    }

    clickPopupCompare = (e = null) => {
        if (e) {
            e.preventDefault();
        }
        this.setState({equal: '<'});

        const {equal, begin, end} = this.state.values;
        const {inputData, setPopup, t, setPopupIsOpen} = this.props;
        if (setPopupIsOpen) {
            setPopupIsOpen(true);
        }
        const options = [{
            value: '<', label: t(inputData.properties.end.title)
        },
            {value: '>', label: t(inputData.properties.begin.title)},
            {value: '><', label: t('Entre')},
        ]

        setPopup({
            isOpen: true,
            className: 'popup__date-compare',
            content: (
                <>
                    <p className={'popup__content__title'}>{t(inputData.title)}</p>
                    <div className={'form'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <label>{t(inputData.properties.equal.title)}</label>
                                <Select
                                    onChange={(value) => this.manageData('signe', value.value)}
                                    options={options}
                                    value={options.find((item) => item.value === equal)}
                                    isMulti={false}
                                    placeholder={t(inputData.properties.equal.title)}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            '&:hover': {},
                                            border: '2px solid #eef0fa',
                                            boxShadow: 'none',
                                            color: '#372e74',
                                            background: '#f3f1fc'
                                        }),
                                        container: (base, state) => {
                                            return ({
                                                ...base,
                                                zIndex: state.isFocused ? "999" : "1"
                                            })
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {(equal === ">" || equal === "><") &&
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <label>{t(inputData.properties.begin.title)}</label>
                                <Datetime className={'field__input field__input-date'}
                                          locale="fr"
                                          dateFormat="DD-MM-YYYY"
                                          timeFormat={false}
                                          closeOnSelect={true}
                                          onChange={(date) => {
                                              if (typeof date.format !== 'undefined') {
                                                  this.manageData('begin', date.format('DD-MM-YYYY'));
                                              }
                                          }}
                                          value={begin}
                                />
                            </div>
                        </div>
                        }
                        {equal !== ">" &&
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <label>{t(inputData.properties.end.title)}</label>
                                <Datetime className={'field__input field__input-date'}
                                          locale="fr"
                                          dateFormat="DD-MM-YYYY"
                                          timeFormat={false}
                                          closeOnSelect={true}
                                          onChange={(date) => {
                                              if (typeof date.format !== 'undefined') {
                                                  this.manageData('end', date.format('DD-MM-YYYY'));
                                              }
                                          }}
                                          value={end}
                                />
                            </div>
                        </div>
                        }
                        <div className={'row'}>
                            <div className={'col-12 popup__separator'}>
                                <div className={'row'}>
                                    <div className={'col-6'}>
                                        <button className={'btn btn-third accept'}
                                                onClick={() => this.manageData('', '', true)}>{t('validate')}</button>
                                    </div>
                                    <div className={'col-6'}>
                                        <button className={'btn btn-third decline'}
                                                onClick={() => setPopup({})}>{t('cancel')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        })
        ;
    }

    getFinal(equal, begin, end) {
        let final = equal + ' ';
        final += begin !== '' ? begin : (end !== '' ? end : '');
        if (begin !== '' && end !== '') {
            final += ' | ' + end;
        }
        return final;
    }

    manageData = (context, value, change = false) => {
        let {equal, begin, end} = this.state.values;
        let {final} = this.state;
        const {onChange, setPopup, setPopupIsOpen} = this.props;
        switch (context) {
            case 'signe':
                equal = value;
                break;
            case 'begin':
                begin = value;
                break;
            case 'end':
                end = value;
                break;
            case 'final':
                final = '';
                equal = '='
                begin = value;
                end = '';
                break;
            default:
                break;
        }

        end = equal === '>' ? '' : end;
        begin = equal === '<' ? '' : begin;

        if (context !== 'final' && context !== '') {
            final = this.getFinal(equal, begin, end);
        }

        const values = {
            equal: equal,
            begin: begin,
            end: end,
        }

        if (change) {
            this.setState({values: values, final: final}, () => {
                onChange(this.state.values);
                setPopup({});
            });
            if (setPopupIsOpen) {
                setPopupIsOpen(false);
            }
            return;
        }
        this.setState({values: values, final: final}, () => this.clickPopupCompare());
    }

    render() {
        const {inputData, t} = this.props;
        const {final} = this.state;
        let {begin, end} = this.state.values;

        if ((inputData.value === null || inputData.value === '') &&
            (begin !== ''
                || end !== '')
        ) {
            const values = {
                equal: '<',
                begin: '',
                end: '',
            };
            this.setState({values: values, final: ''});
        }

        return (
            <>
                <div className={`${inputData && inputData.hidden ? 'd-none' : 'input-compare'}`}>
                    {final === '' ? (
                        <>
                            <Datetime className={'field__input field__input-date'}
                                      locale="fr"
                                      dateFormat="DD-MM-YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(date) => {
                                          if (typeof date.format !== 'undefined') {
                                              this.manageData('final', date.format('DD-MM-YYYY'), true);
                                          }
                                      }}
                                      value={begin}
                            />
                            <>
                                <label title={t(inputData.title)}
                                       className={'field__label'}
                                       htmlFor={inputData.keyItem}
                                >{t(inputData.title)}</label>
                            </>
                        </>

                    ) : (
                        <input className={'field__input field__input-date'}
                               onChange={() => {
                                   this.manageData('final', '', true);
                               }}
                               value={final}
                        />
                    )}
                    <PolIcon className={'input-compare-icon'} onClick={(e) => this.clickPopupCompare(e)}
                             icon={'cogs'}/>
                </div>

            </>
        );
    }
}

import React, {useCallback, useEffect, useState} from 'react';
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {connect} from 'react-redux';
import {adminFetchHandler} from '../../../api/datas/datas';
import {setPopup} from '../../../actions/content';
import PopupInfoConfirm from '../../Popup/_popup_info_confirm';
import {getAdmin} from '../../../api/admin/get';
import PopupInfo from '../../Popup/_popup_info';
import {postAdmin} from '../../../api/datas/post';
import Loading from '../../Loading/Loading';
import {humanFileSize} from '../../../utils/unitConverter';

const GedCleanup = ({customerId, adminFetchHandler, t, togglePopup}) => {
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [data, setData] = useState(null);

    const onClick = useCallback(() => {
        setLoading(true);
        setError(false);

        getAdmin(`/api/unknown_customer_resources?customerId=${customerId}`, error => {
            setLoading(false);
            setError(true);

            adminFetchHandler(error, {});
        }, response => {
            setLoading(false);
            setData(response.data);
        });
    }, [customerId, adminFetchHandler]);

    const closePopup = useCallback(() => {togglePopup({})}, [togglePopup]);
    const onAcceptCleanup = useCallback(() => {
        togglePopup({});
        setLoading(true);

        postAdmin(`/api/unknown_customer_resources?customerId=${customerId}`, error => {
            setLoading(false);
            setError(true);

            adminFetchHandler(error, {});
        }, response => {
            setLoading(false);

            togglePopup({
                isOpen: true,
                content: <>
                    <PopupInfo
                        message={t('ged_cleanup_unknown_resources_deleted', {
                            size: humanFileSize(response.data.totalSize),
                            nbFiles: response.data.nbFiles
                        })}
                        onAccept={closePopup}
                    />
                </>
            });
        }, null, 'DELETE');
    }, [adminFetchHandler, closePopup, customerId, t, togglePopup]);

    useEffect(() => {
        if (!data) {
            return;
        }

        togglePopup({
            isOpen: true,
            content: data.nbFiles === 0
                ? <>
                    <PopupInfo
                        message={t('ged_cleanup_unknown_resources_no_files')}
                        onAccept={closePopup}
                    />
                </>
                : <>
                    <PopupInfoConfirm
                        message={t('ged_cleanup_unknown_resources_confirm', {
                            size: humanFileSize(data.totalSize),
                            nbFiles: data.nbFiles
                        })}
                        onAccept={onAcceptCleanup}
                        onDecline={closePopup}
                    />
                </>
        });
    }, [data, closePopup, onAcceptCleanup, t, togglePopup]);

    return <>
        <button className="btn btn-primary form__submit__btn mx-3 col-3" onClick={onClick} disabled={isLoading}>
            {
                (isError && t('ged_cleanup_unknown_resources_error'))
                || (isLoading && <Loading message={t("loading")} specificClass={"admin-loader"}/>)
                || t('ged_cleanup_unknown_resources')
            }
        </button>
    </>;
};

const mapDispatchToProps = dispatch => {
    return {
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info))
    };
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(GedCleanup));

import {PolIcon} from "components/PolIcon/policon";
import React, {useCallback, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Confirm from "v4/components/ui/Confirm/Confirm";
import {useFrontContext} from "v4/contexts/FrontContext";
import {PROSPECT_ITEM} from "v4/data/apiRoutes";
import {routesBase} from "v4/data/appRoutes";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import {generateUrl} from "v4/services/api.service";

export default function ProspectViewActions({isEditMode, toggleEditMode, hasGeoloc, prospectId, isUnknown}) {
    const [{isError, isFinished}, deleteProspectFetch] = useFetch();
    const {replace} = useHistory();
    const {t} = usePolTranslation();
    const [isConfirmOpen, toggleConfirmOpen] = useToggle();
    const {toggleRefetchLastsVisits} = useFrontContext();

    const handleDelete = useCallback(() => {
        deleteProspectFetch({
            url: generateUrl(PROSPECT_ITEM, {id: prospectId}),
            config: {
                method: 'DELETE',
            }
        });
        toggleConfirmOpen();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prospectId]);

    useEffect(() => {
        if (!isError && isFinished) {
            toggleRefetchLastsVisits();
            replace({pathname: routesBase.baseProspect, state: {hasToRefresh: true}});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished]);

    return (
        <div className="prospect__view__actions__relative">
            <ul className="prospect__view__actions__list">
                <li>
                    <PolIcon icon="actions"/>
                </li>
                <li data-for="prospect-actions"
                    data-tip={t(isUnknown ? 'no_edit_unknown_prospect' : 'edit')}
                    onClick={!isUnknown ? toggleEditMode : undefined}
                    className={isUnknown ? 'disabled' : ''}>
                    <PolIcon icon={isEditMode ? 'eye' : 'edit'}/>
                </li>
                <li data-for="prospect-actions"
                    data-tip={t(hasGeoloc ? 'geolocaliser' : 'geoloc_disabled')}
                    className={!hasGeoloc ? 'disabled' : ''}>
                    {hasGeoloc
                        ? (
                            <Link to={`${routesBase.baseProspect}/${prospectId}/maps`}>
                                <PolIcon icon="map"/>
                            </Link>
                        )
                        : <PolIcon icon="map"/>
                    }
                </li>
                <li data-for="prospect-actions"
                    data-tip={t(isUnknown ? 'no_delete_unknown_prospect' : 'delete')}
                    onClick={!isUnknown ? toggleConfirmOpen : undefined}
                    className={isUnknown ? 'disabled' : ''}>
                    <PolIcon icon="trash"/>
                </li>
            </ul>
            <Confirm title={t('delete')}
                     isOpen={isConfirmOpen}
                     cancelAction={toggleConfirmOpen}
                     confirmAction={handleDelete}>
                <p>{t('popup_validation_suppression_prospect')}</p>
            </Confirm>
            <ReactTooltip id="prospect-actions" effect="solid"/>
        </div>
    )
}

import React from 'react';
import {MAIL_TYPE} from "v4/data/entities";
import ListingExport from "v4/features/front/export/components/ListingExport/ListingExport";

export default function MailsTypesListingTools({checkedRows}) {

    return (
        <>
            <ListingExport selectedItems={checkedRows} entity={MAIL_TYPE}/>
        </>
    )
}

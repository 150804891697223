import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useManageTasksActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/components/ManageTasks/components/ManageTasksActions/hooks/useManageTasksActions";

export default function ManageTasksActions({taskId, changeDate, canRenew, defaultCanClose}) {
    const {t} = usePolTranslation();

    const {
        state,
        canClose,
        isRenew,
        toggleRenew,
        isClose,
        toggleClose,
        renewDate,
        handleRenewDateChange,
        handleRenewValidate,
        handleCloseValidate
    } = useManageTasksActions(taskId, changeDate, defaultCanClose);

    return (
        <div className="manage-task__row__actions">
            <button className={`manage-task__row__actions__action close${isClose ? ' active' : ''}`}
                    onClick={toggleClose} disabled={!canClose}>
                {t("close_this_task")}
            </button>
            {(!isRenew && !isClose) && (
                <button className="manage-task__row__actions__action renew"
                        onClick={toggleRenew} disabled={!canRenew}>
                    {t("renew_this_task")}
                </button>
            )}
            {(isRenew || isClose) && (
                <>
                    {isRenew && (
                        <div className="manage-task__row__actions__action renew-date">
                            <input type="datetime-local" value={renewDate} onChange={handleRenewDateChange}/>
                        </div>
                    )}
                    <button className="manage-task__row__actions__action renew-cancel"
                            onClick={isRenew ? toggleRenew : toggleClose}>
                        {t('cancel')}
                    </button>
                    <button className="manage-task__row__actions__action renew-validate"
                            onClick={isRenew ? handleRenewValidate : handleCloseValidate}>
                        {t(isRenew ? 'validate' : 'confirm')}
                        {state.isLoading && <PolIcon icon="circle-notch" className="spinner"/>}
                    </button>
                </>
            )}
        </div>
    )
}

import React, {useState, useEffect} from 'react';
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {postAdmin} from "../../../api/datas/post";
import {adminFetchHandler} from "../../../api/datas/datas";
import {resetStoreDatas, resetSubResourceStore, setError} from "../../../actions/datas";
import {setLoader} from "../../../actions/content";

const RefreshStatsButton = ({
                                t, adminFetchHandler, setError, prospectId, setListStateInfo, listStateInfo,
                                resetSubResourceStore, resetStoreDatas, isNotBtn
                            }) => {
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refresh) {
            postAdmin('/api/task_mailing_stats', (error) => {
                adminFetchHandler(error, {});
                setRefresh(false)
            }, data => {
                setRefresh(false);
                // Recharge liste subResource
                if (prospectId && prospectId.length) {
                    resetSubResourceStore();
                    setListStateInfo({
                        ...listStateInfo,
                        reloadList: true
                    });
                    // Reset listing général
                } else {
                    resetStoreDatas();
                }
                setError({msg: t('stats_updated'), errorCode: 200});

            }, {
                'prospectId': prospectId ? prospectId : null
            })
        } // eslint-disable-next-line
    }, [refresh]);

    const handleRefreshButton = () => {
        setRefresh(true);
    };

    return (
        <>
            {!isNotBtn ? (
                <button className={'btn btn-primary data__title__btn'}
                        disabled={refresh}
                        onClick={() => handleRefreshButton()}
                >
                    <span
                        className={'data__title__btn__icon'}>
                        <FontAwesomeIcon icon={faSync} className={refresh ? 'spin' : ''}/>
                    </span>
                    {t('stats_mailing')}
                </button>) : (
                <li className={`action__dropdown__item ${refresh ? 'action__dropdown__item__disabled' : ''}`}
                    onClick={() => {
                        handleRefreshButton()
                    }}>
                    <div className={'icon-li'}>
                        <FontAwesomeIcon icon={faSync} className={refresh ? 'spin' : ''}/>
                        <p>{t('stats_mailing')}</p>
                    </div>
                </li>
            )}
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setError: (error) => dispatch(setError(error)),
        resetSubResourceStore: () => dispatch(resetSubResourceStore()),
        resetStoreDatas: () => dispatch(resetStoreDatas(true)),
        setLoader: (value) => dispatch(setLoader(value)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(React.memo(RefreshStatsButton)));



import React, {useMemo} from 'react';
import Field from "v4/components/form/Field/Field";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsTab/components/ProspectContactsTabMail/components/ProspectContactsTabMailForm/components/MailField/MailField.scss';
import {
    CHOICE_TYPE,
    COLLECTION_TYPE,
    CUSTOMER_FILE_TYPE,
    DISPLAY_FIELDS_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE
} from "v4/data/fieldTypes";

export default React.forwardRef(function MailField({input, name, onFieldChange, specificParams}, ref) {
    const {t} = usePolTranslation();

    const {
        type,
        constraints,
        choices,
        attr,
        multiple,
        entry_type,
        properties,
    } = useMemo(() => prepareInfosForField(input), [input]);

    return (
        <div className="input-field-wrapper">
            {
                type === DISPLAY_FIELDS_TYPE
                    ? (
                        <div className="input-display-wrapper">
                            <b>{t(name)}</b>
                            {input.enum_titles.map((title) => (
                                <span key={title} onClick={onFieldChange}>{title}</span>
                            ))}
                        </div>
                    )
                    : (
                        <>
                            <Field type={type}
                                   id={name}
                                   name={name}
                                   choices={choices}
                                   constraints={constraints}
                                   properties={properties}
                                   multiple={multiple}
                                   onFieldChange={onFieldChange}
                                   attr={attr}
                                   entry_type={entry_type}
                                   specificParams={specificParams}
                                   ref={ref}/>
                            <label htmlFor={name} className='active'>
                                {t(input.title)}
                            </label>
                        </>
                    )
            }
        </div>
    )
})

function prepareInfosForField(input) {
    let type = TEXT_TYPE;
    let constraints = {required: !!input.required};
    let choices = null;
    let attr = input.attr ?? {};
    let multiple = false;
    let entry_type = null;
    const properties = {};

    const getChoices = (enum_values, enum_titles) => {
        return enum_values.map((value, index) => ({value, label: enum_titles[index]}));
    }

    if (input.type === 'string' && input.enum) {
        type = CHOICE_TYPE;
        choices = getChoices(input.enum, input.enum_titles);
    }

    if (input.type === 'array') {
        if (input.attr.type === 'file') {
            type = COLLECTION_TYPE;
            entry_type = CUSTOMER_FILE_TYPE;
            properties.customerFile = {
                constraints: {
                    File: {
                        maxSize: 14 /*MB*/ * 1024 /*kB*/ * 1024 /*B*/,
                        mimeTypes: [
                            "application/pdf",
                            "image/gif",
                            "image/jpeg",
                            "image/png",
                            "image/svg+xml",
                            "image/tiff",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "application/vnd.ms-excel",
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            "application/vnd.ms-powerpoint",
                            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                            "application/vnd.oasis.opendocument.text",
                            "application/vnd.oasis.opendocument.spreadsheet",
                            "application/vnd.oasis.opendocument.presentation",
                            "application/vnd.oasis.opendocument.graphics",
                            "application/vnd.oasis.opendocument.formula",
                            "application/vnd.oasis.opendocument.chart",
                            "application/vnd.oasis.opendocument.image",
                            "application/vnd.oasis.opendocument.text-master",
                            "application/vnd.oasis.opendocument.text-template",
                            "application/vnd.oasis.opendocument.spreadsheet-template",
                            "application/vnd.oasis.opendocument.presentation-template",
                            "application/vnd.oasis.opendocument.graphics-template",
                            "application/vnd.oasis.opendocument.formula-template",
                            "application/vnd.oasis.opendocument.chart-template",
                            "application/vnd.oasis.opendocument.image-template",
                            "application/vnd.oasis.opendocument.text-web",
                            "application/zip",
                            "application/x-7z-compressed",
                            "application/x-rar-compressed",
                            "application/x-tar",
                            "application/gzip",
                            "application/x-bzip2",
                            "application/x-zip-compressed"
                        ],
                    }
                }
            }
        } else {
            type = CHOICE_TYPE;
            choices = getChoices(input.items.enum, input.items.enum_titles);
            multiple = true;
        }
    }

    if (input.type === 'string' && input.widget === 'textarea') {
        type = TEXTAREA_TYPE;
    }

    if (input.type === 'string' && input.attr?.['display-fields']) {
        type = DISPLAY_FIELDS_TYPE;
    }

    return {
        type,
        constraints,
        properties,
        choices,
        attr,
        multiple,
        entry_type,
    }
}

import React from 'react';
import Logo from "img/logo";

export default function Loader({message = null, className= ''}) {
    return (
        <div className={`is-loading ${className}`}>
            <span className="loader">
                <Logo/>
                <p className="loader-message">{message}</p>
            </span>
        </div>
    )
}

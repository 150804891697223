import React, {useState} from 'react'
import {withPolTranslation} from "v4/hooks/usePolTranslation";

export const TaskType = ({onChange, t, item}) => {
    const [checkedTimeStamped, setCheckedTimeStamped] = useState(item && item.isTimestamped);
    const [checkedIsMailing, setCheckedIsMailing] = useState(item && item.isMailing);
    const [checkedIsSynchroOutlook, setCheckedIsSynchroOutlook] = useState(item && item.isSynchroOutlook);
    const [checkedIsReminder, setCheckedIsReminder] = useState(item && item.isReminder);

    return (
        <>
            <input className={'field__span'} type={'checkbox'} checked={checkedTimeStamped} value={checkedTimeStamped}
                   id="is_time_stamped" onChange={(e) => {
                onChange('isTimestamped', e.target.value)
                setCheckedTimeStamped(!checkedTimeStamped);
            }}/>
            <label htmlFor="is_time_stamped">{t('is_time_stamped')}</label>
            <input className={'field__span'} type={'checkbox'} checked={checkedIsMailing} value={checkedIsMailing}
                   id="is_mailing" onChange={(e) => {
                onChange('isMailing', e.target.value)
                setCheckedIsMailing(!checkedIsMailing)
            }}/>
            <label htmlFor="is_mailing">{t('is_mailing')}</label>
            <input className={'field__span'} type={'checkbox'} checked={checkedIsSynchroOutlook} value={checkedIsSynchroOutlook}
                   id="is_synchro_outlook" onChange={(e) => {
                onChange('isSynchroOutlook', e.target.value)
                setCheckedIsSynchroOutlook(!checkedIsSynchroOutlook)
            }}/>
            <label htmlFor="is_synchro_outlook">{t('is_synchro_outlook')}</label>
            <input className={'field__span'} type={'checkbox'} checked={checkedIsReminder} value={checkedIsReminder}
                   id="is_reminder" onChange={(e) => {
                onChange('isReminder', e.target.value)
                setCheckedIsReminder(!checkedIsReminder)
            }}/>
            <label htmlFor="is_reminder">{t('is_reminder')}</label>
        </>
    )
}

export default withPolTranslation(TaskType)

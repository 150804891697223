import {PolIcon} from "components/PolIcon/policon";
import DownloadLink from "v4/components/utils/DownloadLink/DownloadLink";
import React from 'react';

export default function CustomerFileButton({file}) {
    const {downloadLink = '#', fileLabel, realFileName} = file;

    return (
        <DownloadLink href={downloadLink} fileName={fileLabel} className="customer-file-btn">
            <PolIcon icon={'download'}/>
            <span>{fileLabel ?? realFileName}</span>
        </DownloadLink>
    )
}

import React from 'react'
import {PolIcon} from "../PolIcon/policon";
import {usePolTranslation} from "../../v4/hooks/usePolTranslation";

const TASK_FORM = 'task_formTask';
export const TASK_TYPE = 'taskType';
export const SHOW_CONDITION_FIELD = 'show_condition_field';
export const TYPE_EXTENDED = 'isExtended';
export const TYPE_PARENT_FIELD = 'isParentField';
export const CONDITION_VALUE = 'conditionValue';
export const CONDITION_FIELD = 'conditionField';

export default function DropdownOrders({input, setValueType, viewSelected, taskTypeInputs}) {
    const {t} = usePolTranslation();
    let taskTypesTitle = [`(${t('empty')})`];
    let taskTypesId = [null];
    const isSearch = viewSelected.includes('formSearch');
    const TYPES = isSearch
        ? []
        :['money', 'percent', 'TelType', 'website', 'html', 'files', 'texte', 'unique', 'required', 'readOnly', 'email', TYPE_PARENT_FIELD]
    ;
    if (TASK_FORM === viewSelected
        && taskTypeInputs?.enum
        && taskTypeInputs?.enum_titles
        && !input.input.required
        && !input.isRequired
    ) {
        TYPES.push(SHOW_CONDITION_FIELD);

        taskTypeInputs.enum.forEach((taskTypeEnum, index) => {
            taskTypesTitle.push(taskTypeInputs.enum_titles[index]);
            taskTypesId.push(taskTypeEnum);
        })
    }

    if (isSearch && (input.input?.items?.enum || input.input?.enum)) {
        TYPES.push(TYPE_EXTENDED);
    }
    const toggle = () => {
        const ul = document.getElementById(`${input.input.parentElement ? input.input.parentElement : 'root'}_${input.input.keyItem}`);
        ul.classList.toggle('admin-orders__hidden')
    }

    const handleMouseLeave = () => {
        const inputElt = document.getElementById(`${input.input.parentElement ? input.input.parentElement : 'root'}_${input.input.keyItem}`)
        inputElt.classList.toggle('admin-orders__hidden')
    }

    return (
        <div onMouseLeave={handleMouseLeave}>
            <ul id={`${input.input.parentElement ? input.input.parentElement : 'root'}_${input.input.keyItem}`}

                className='admin-orders__dropdown__content admin-orders__hidden'>
                {TYPES.map((item, index) => (
                    //Si l'input est un champ obligatoire (déclaré par l'API), ajout de la class '--disable'
                    <li key={index} className={`admin-orders__dropdown__item ${item === "required" && input.input.required ? 'admin-orders__dropdown__item--disable' : null}`} onClick={() => {
                        //Empêcher le click si l'input est un champ obligatoire (déclaré par l'API)
                        if (!input.input.required || item !== "required") {
                            setValueType(item, toggle)
                        }
                    }}>
                        <p>{t(item)}</p>
                        {((input.valueType === item)
                            || (input.isRequired && item === 'required')
                            || (input.isUnique && item === 'unique')
                            || (input.isEmail && item === 'email')
                            || (input.isReadOnly && item === 'readOnly')
                            || (input.isExtended && item === TYPE_EXTENDED)
                            || (input.isParentField && item === TYPE_PARENT_FIELD)
                            || (input[SHOW_CONDITION_FIELD] && item === SHOW_CONDITION_FIELD)) && (
                            <PolIcon icon='check-circle'
                                className='admin-orders__dropdown__icon'/>
                        )}
                    </li>
                ))}

            </ul>

            {taskTypesTitle &&
                <ul id={`${input.input.parentElement ? input.input.parentElement : 'sub_root'}_${input.input.keyItem}`}
                    onMouseLeave={() => {
                        setValueType(SHOW_CONDITION_FIELD, toggle);
                    }}
                    className={'admin-orders__dropdown__subcontent '
                        + ((!input[SHOW_CONDITION_FIELD] && input.valueType !== SHOW_CONDITION_FIELD) ? 'admin-orders__hidden' : '')}>
                    {taskTypesTitle.map((taskType, index) => (
                        <li className={`admin-orders__dropdown__item`} onClick={() => {
                            setValueType(CONDITION_FIELD, null, TASK_TYPE);
                            setValueType(CONDITION_VALUE, null, taskTypesId[index]);
                        }} key={index}>
                            <p>{t(taskType)}</p>
                            {(input[CONDITION_VALUE] === taskTypesId[index]) && (
                                <PolIcon icon={'check-circle'}
                                    className={'admin-orders__dropdown__icon'}/>
                            )}
                        </li>
                    ))}

                </ul>
            }
        </div>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import {PRODUCT} from "v4/data/entities";
import GenericVoter from "v4/voters/genericVoter";

export default function ProductsListingActions() {
    return (
        (GenericVoter(PRODUCT, 'canAdd'))
        && <li className="action">
            <Link to={({pathname}) => `${pathname}/add`}>
                <PolIcon icon="plus" className="action__picto"/>
            </Link>
        </li>
    )
}

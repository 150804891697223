import React from 'react';

export default function InputSimpleCheckbox({
                                                fieldName,
                                                fieldValue,
                                                prefix,
                                                error,
                                                attr,
                                                inputConstraints,
                                                onChange,
                                            }) {
    const handleChange = ({currentTarget: {checked}}) => {
        onChange(checked);
    }

    return (
        <div className="input-field-checkbox-container" data-checked={fieldValue}>
            <input className={error ? 'has-error' : ''}
                   name={fieldName}
                   type="checkbox"
                   checked={toBoolean(fieldValue)}
                   {...attr}
                   data-is-required={!!inputConstraints?.required}
                   onChange={handleChange}
                   id={prefix + fieldName}/>
        </div>
    )
}

function toBoolean(value) {
    if (typeof value === 'boolean') return value;

    if (typeof value === 'string') return value === 'true';

    return Boolean(value);
}

import React, {useEffect, useMemo} from 'react';
import {useHistory} from "react-router-dom";
import Loader from "v4/components/ui/Loader/Loader";
import {UNIT_MAIL} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import ProspectContactsTabMailForm
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsTab/components/ProspectContactsTabMail/components/ProspectContactsTabMailForm/ProspectContactsTabMailForm";
import {generateUrl} from "v4/services/api.service";

export default function ProspectContactsTabMail({prospectId}) {
    const {replace, location: {search, state = {}}} = useHistory();
    const [{data}, fetchForm] = useFetch();

    const {selectedContacts, mailContactIds, ...restState} = state;
    const contactIdsFromUrl = new URLSearchParams(search).get('contactIds')?.split(',');
    const mailTypeId = new URLSearchParams(search).get('mailTypeId');

    const contacts = useMemo(() => {
        if (contactIdsFromUrl?.length > 0) {
            return contactIdsFromUrl.map(id => ({metadata: {id}}));
        }

        if (selectedContacts) {
            replace({state: restState});
            return selectedContacts;
        }

        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const params = {id: prospectId};
        if (contactIdsFromUrl?.length > 0) {
            params['contactIds[]'] = contactIdsFromUrl;
        } else {
            params['contactIds[]'] = contacts?.map(({metadata}) => metadata.id) ?? mailContactIds
        }
        if (Array.isArray(params['contactIds[]'])) {
            params['contactIds[]'] = params['contactIds[]'].join('&contactIds[]=');
        }

        fetchForm({url: generateUrl(UNIT_MAIL, params)});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts]);

    const inputGroups = useMemo(() => {
        const inputGroups = data?.schema?.['hydra:member'][2] ?? {};
        if (mailTypeId && inputGroups.mailType) {
            inputGroups.mailType.value = mailTypeId;
        }

        return inputGroups;
    }, [data, mailTypeId])

    return (
        Object.keys(inputGroups).length > 0
            ? <ProspectContactsTabMailForm inputGroups={inputGroups}/>
            : <Loader className="show-entity-loader"/>
    );
}

import {captureException} from "@sentry/react";
import React, {useEffect} from 'react';
import {useHistory, useRouteMatch} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {getLSItem} from "v4/services/localStorage.service";

export default function NewsletterPage() {
    const {params: {tabName}} = useRouteMatch(`${routesBase.baseNewsletter}/:tabName`) ?? {};
    const {replace, location: {state}} = useHistory();

    useEffect(() => {
        return () => {
            const {campaignId, ...rest} = state ?? {};

            if (campaignId) replace({state: rest});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replace]);

    return (
        <div className="page page-list main-content__body__slide">
            <div className="page-list__content">
                <iframe title="Mailing"
                        src={getIframeUrl(tabName, state)}
                        height="700"
                        width="100%"/>
            </div>
        </div>
    )
}

function getIframeUrl(tabName, state) {
    let {sfiMailingHosts} = getLSItem('user');
    const {campaignId} = state ?? {};

    try {
        sfiMailingHosts = JSON.parse(sfiMailingHosts);
    } catch (e) {
        captureException(e);
    }

    let iframeSrc;

    switch (tabName) {
        case 'add':
            iframeSrc = sfiMailingHosts?.['add_newslettersnew'];
            break;
        case 'gestions':
            iframeSrc = sfiMailingHosts?.['list_news'];
            break;
        case 'journal':
            iframeSrc = sfiMailingHosts?.['journal'];
            break;
        case 'statistiques':
            iframeSrc = sfiMailingHosts?.['statsnew'];
            break;
        case 'emailing':
            iframeSrc = sfiMailingHosts?.['mail_envoi1&source=pol'];
            break;
        case 'import-destinataires':
            iframeSrc = sfiMailingHosts?.['import&pol=1'];
            break;
        default:
            iframeSrc = '';
    }

    if (iframeSrc?.length > 0) {
        iframeSrc = new URL(iframeSrc);
        iframeSrc.protocol = 'https:';
        iframeSrc.searchParams.append('env', process.env.REACT_APP_ENV);

        if (campaignId) {
            iframeSrc.searchParams.append('idCampagnePol', campaignId);
        }

        iframeSrc = iframeSrc.toString();
    }

    return iframeSrc;
}

import React, {useEffect, useMemo, useState} from 'react';
import Draggable from "react-draggable";
import {PolIcon} from "components/PolIcon/policon";
import useToggle from "v4/hooks/useToggle";
import useExportPopup from "v4/features/front/export/components/ExportPopup/hooks/useExportPopup";
import ExportPopupLine
    from "v4/features/front/export/components/ExportPopup/components/ExportPopupLine/ExportPopupLine";
import 'v4/features/front/export/components/ExportPopup/ExportPopup.scss';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ReactTooltip from "react-tooltip";

export default function ExportPopup() {
    const {t} = usePolTranslation();
    const {loadingExports, readyExports, problemExports, exportDownload} = useExportPopup();
    const [isClosed, toggleIsClosed] = useToggle();
    const [isMinimized, toggleIsMinimized] = useToggle();
    const [isDragging, setIsDragging] = useState(false);

    const eventControl = (e, info) => {
        if (e.type === 'mousemove' || e.type === 'touchmove') {
            setIsDragging(true)
        }

        if (e.type === 'mouseup' || e.type === 'touchend') {
            setTimeout(() => {
                setIsDragging(false);
            }, 100);
        }
    }
    const readyExportsKeysLength = useMemo(() => Object.keys(readyExports).length, [readyExports]);
    const loadingExportsKeysLength = useMemo(() => Object.keys(loadingExports).length, [loadingExports]);

    useEffect(() => {
        if (readyExportsKeysLength > 0 || loadingExportsKeysLength > 0) {
            toggleIsClosed(false);
            toggleIsMinimized(false);
        }
    }, [loadingExportsKeysLength, readyExportsKeysLength, toggleIsClosed, toggleIsMinimized]);

    if (isClosed || (Object.keys(loadingExports).length === 0 && Object.keys(readyExports).length === 0)) return null;

    return (
        <>
            <Draggable bounds="html"
                       defaultClassName="export-popup"
                       handle=".export-popup__header"
                       onDrag={eventControl}
                       onStop={eventControl}>
                <div>
                    <div className="export-popup__header"
                         onClick={!isDragging ? toggleIsMinimized : undefined}>
                        <p>{t('exports')} {Object.keys(readyExports).length > 0 ? `(${Object.keys(readyExports).length})` : ''}</p>
                        <div className="export-popup__header__icons">
                            <PolIcon icon="chevron-down"
                                     className={`export-popup__header__icon ${isMinimized ? 'minimized' : ''}`}/>
                            <PolIcon icon="close"
                                     className="export-popup__header__icon"
                                     onClick={toggleIsClosed}
                                     data-tip={t('export_popup_close')}
                                     data-for="close-explanation"/>
                        </div>
                    </div>
                    <div className={`export-popup__wrapper ${isMinimized ? 'minimized' : ''}`}>
                        <ul className="export-popup__list">
                            {Object.entries(loadingExports).map(([id, line]) => (
                                <ExportPopupLine key={id} line={line} problemExports={problemExports}/>
                            ))}
                            {Object.entries(readyExports).map(([id, line]) => (
                                <ExportPopupLine key={id} line={line} exportDownload={exportDownload}/>
                            ))}
                        </ul>
                    </div>
                </div>
            </Draggable>
            <ReactTooltip effect="solid"
                          id="close-explanation"
                          place="left"
                          multiline={true}
                          className="export-popup__header__tooltip"/>
        </>
    )
}

import {useHistory} from "react-router-dom";

export default function useGETUrlToObject() {
    const {location} = useHistory();

    const searchParams = new URLSearchParams(location.search);
    const search = Object.fromEntries(searchParams.entries());
    Object.entries(search).forEach(([key, value]) => {
        try {
            search[key] = JSON.parse(value);
        } catch (e) {
            // do nothing
        }
    });

    return search;
}

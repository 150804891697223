import ErrorBoundary from "components/Error/errorBoundary";
import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import 'v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/AsideFilters.scss';
import AsideFiltersForm
    from "v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/components/AsideFiltersForm/AsideFiltersForm";

export default function AsideFilters({
                                         entity,
                                         isFiltersShowing,
                                         toggleFiltersShowing,
                                         isSavedSearchVisible,
                                         additionnalParamsForSearch
                                     }) {
    const {t} = usePolTranslation();
    const [isFiltersExtended, toggleFiltersExtended] = useToggle();

    const {
        formInputs: {inputs, state: {isLoading, isFinished}}
    } = useSearchContext();


    return (
        <div className={`content-search
                ${isFiltersShowing ? 'active-search' : ''}
                ${isFiltersExtended ? 'extended' : ''}`}>
            <PolIcon className={`content-search__expend ${isFiltersExtended ? 'open' : ''}`}
                     icon="arrow-right-bis"
                     onClick={toggleFiltersExtended}/>
            <PolIcon className="content-search__close"
                     icon="times-circle"
                     onClick={toggleFiltersShowing}
                     data-tip={t('quit')}/>
            {isLoading && <p>Chargement...</p>}

            <ErrorBoundary>
                {isFinished && <AsideFiltersForm entity={entity}
                                                 isSavedSearchVisible={isSavedSearchVisible}
                                                 additionnalParamsForSearch={additionnalParamsForSearch}
                                                 inputsGroups={inputs}
                                                 isFiltersExtended={isFiltersExtended}/>}
            </ErrorBoundary>
        </div>
    )
}

import React, {useEffect, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, Redirect} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import {
    CUSTOM_ACTION_ADD, CUSTOM_ACTION_EDIT_FORM, CUSTOM_ACTION_FORM,
    CUSTOM_ACTION_ITEM
} from "v4/data/apiRoutes";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";
export default function CustomActionsForm({customerId, id}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification();
    const [{data: customActionFormData, isLoading: isLoadingForm, isFinished: isFinishedForm}, fetchCustomActionForm] = useFetch();
    const [{data: customActionDataSave, isError: isErrorSave, isLoading: isLoadingSave, isFinished: isFinishedSave}, fetchCustomActionSave] = useFetch();
    const [customActionForm, setCustomActionForm] = useState(null);
    const [customAction, setCustomAction] = useState({
        entity: null,
        name: null,
        config: null,
        position: 0,
        customerId: customerId
    });
    const isEdit = (!!id);
    const [redirect, setRedirect] = useState(null);
    useEffect(() => {
        fetchCustomActionForm({
            url: id
                ? apiService.generateUrl(CUSTOM_ACTION_EDIT_FORM, {id: id, customerId: customerId})
                : apiService.generateUrl(CUSTOM_ACTION_FORM, {customerId: customerId}),
        })
    }, [fetchCustomActionForm, id, customerId, isEdit])
    useEffect(() => {
        if (customActionFormData && isFinishedForm) {
            const form = customActionFormData?.schema?.['hydra:member']?.[2];
            setCustomActionForm(form);
            if (form) {
                setCustomAction({
                    name: form?.name?.value,
                    config: form?.config?.value,
                    entity: form?.entity?.value,
                    position: form?.position?.value ?? 0,
                    customerId: form?.customerId?.value,
                });
            }
        }
    }, [isEdit, setCustomActionForm, customActionFormData, isFinishedForm])
    const handleChange = (input) => {
        setCustomAction({...customAction, [input.target.name]: input.target.value})
    }
    const handleSave = (e) => {
        e.preventDefault()
        fetchCustomActionSave({
            url: isEdit
                ? apiService.generateUrl(CUSTOM_ACTION_ITEM, {id: id})
                : apiService.generateUrl(CUSTOM_ACTION_ADD),
            config: {
                method: 'POST',
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customAction)
            }
        })
    }
    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('custom_action_save_error'))
        }
        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('custom_action_save_done'))
            setRedirect(<Redirect to={routesBase.adminCustomActions}/>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customActionDataSave, isErrorSave, isFinishedSave])
    const CardHeaderTools = () => (
        <>
            <Link to={routesBase.adminCustomActions} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )
    return (
        <AdminCard title={t('manage_custom_actions')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoadingForm || isLoadingSave || !customActionForm)
                    ? <Loader message="Chargement..."/>
                    : <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                        <div className="row row-fluid mt-4">
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (customAction?.name ? 'field-written' : null)}>
                                    <input type="text" name="name" id="custom_action_name" className="field__input"
                                           defaultValue={customAction?.name} onChange={(input) => handleChange(input)}/>
                                    <label className="field__label" htmlFor="custom_action_name">{t('custom_action_name')}</label>
                                </div>
                            </div>
                            <div className={'col-3 '}>
                                <div className={'col-12 field ' + (customAction?.entity ? 'field-written' : null)}>
                                    <select name="entity" id="custom_action_entity" className="field__input" required={true}
                                            defaultValue={customActionForm?.entity?.value}
                                            onChange={(input) => handleChange(input)}>
                                        <option key={'entity_option'} value={null} label={t('empty')}></option>
                                        {customActionForm?.entity?.enum?.map((entity, entityKey) => {
                                            return (
                                                <option key={entityKey} value={entity} label={entity}></option>
                                            )
                                        })}
                                    </select>
                                    <label className="field__label" htmlFor="custom_action_entity">{t('custom_action_entity')}</label>
                                </div>
                            </div>
                            <div className={'col-3 '}>
                                <div className={'col-12 field ' + (customAction?.position ? 'field-written' : null)}>
                                    <input type="text" name="position" id="custom_action_position" className="field__input"
                                           defaultValue={customAction?.position} onChange={(input) => handleChange(input)}/>
                                    <label className="field__label" htmlFor="custom_action_position">{t('position')}</label>
                                </div>
                            </div>
                            <div className={'col-12 '}>
                                <div className={'col-12 field ' + (customAction?.config ? 'field-written' : null)}>
                        <textarea name="config" id="custom_action_config" className="field__textarea" rows={16}
                                  defaultValue={customAction?.config} onChange={(input) => handleChange(input)}></textarea>
                                    <label className="field__label" htmlFor="custom_action_config">{t('custom_action_config')}</label>
                                </div>
                            </div>

                        </div>
                    </form>
            }
            {redirect}
        </AdminCard>
    )
}
import ErrorBoundary from "components/Error/errorBoundary";
import React, {useState} from "react";
import {PRODUCT} from "v4/data/entities";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ListingEntityLayout from "v4/layouts/ListingEntityLayout/ListingEntityLayout";
import ListingHeaderEntityLayout from "v4/layouts/ListingHeaderEntityLayout/ListingHeaderEntityLayout";
import ProductsListingActions
    from "v4/pages/front/Products/components/ProductsListing/components/ProductsListingActions/ProductsListingActions";
import ProductsListingRowActions
    from "v4/pages/front/Products/components/ProductsListing/components/ProductsListingRowActions/ProductsListingRowActions";
import ProductsListingTools
    from "v4/pages/front/Products/components/ProductsListing/components/ProductsListingTools/ProductsListingTools";

export default function ProductsListing() {
    const {t} = usePolTranslation();
    const [totalItems, setTotalItems] = useState(null);

    return (
        <>
            <div className="page-list__content">
                <div className="table-container">
                    <ErrorBoundary>
                        <ListingHeaderEntityLayout totalItems={totalItems}
                                                   title='articles'
                                                   pageType='articles'
                                                   resultsText={(t(`resultats_elements_product${(totalItems > 1) ? 's' : ''}`, {count: totalItems}))}
                                                   actions={ProductsListingActions}/>
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <ListingEntityLayout entity={PRODUCT}
                                             tools={ProductsListingTools}
                                             rowActions={ProductsListingRowActions}
                                             tableClassName="articles"
                                             setTotalItems={setTotalItems}/>
                    </ErrorBoundary>
                </div>
            </div>
        </>
    )
}

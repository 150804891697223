import React, {useEffect, useState} from "react";
import {FilePond, registerPlugin} from "react-filepond";
import {PolIcon} from "../../PolIcon/policon";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import 'filepond/dist/filepond.min.css';
import {DocumentList} from "../../View/documentList";
import InputFileImportProductsDocs
    from "../../../v4/features/front/products/components/InputFileImportProductsDocs/InputFileImportProductsDocs";
import useFetch from "../../../v4/hooks/useFetch";
import {usePolTranslation} from "../../../v4/hooks/usePolTranslation";

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

export default function InputFile(
    {
        inputData,
        onChange,
        setPopup,
        deleteService,
        updateInput,
        handleFilesSizeExceeded,
        onFileTypeWrong
    }
) {
    const {t} = usePolTranslation();
    const [files, setFiles] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onChange(files.map(({file}) => file)), [files]);

    // Legacy
    const handleFilesMaxSizeExceeded = (filesSizeExceeded) => {
        if (handleFilesSizeExceeded) {
            handleFilesSizeExceeded(filesSizeExceeded);
        }
    }

    const downloadUrls = inputData.downloadUrls ? inputData.downloadUrls.split(';') : [];
    const deleteUrls = inputData.deleteUrls ? inputData.deleteUrls.split(';') : [];
    const fileNames = inputData.fileNames ? inputData.fileNames.split(';') : [];
    const holdedFiles = [];
    fileNames.forEach((value, index) =>
        holdedFiles.push({
            fileLabel: value,
            downloadLink: downloadUrls ? downloadUrls[index] : null,
            deleteLink: deleteUrls[index]
        })
    )

    let fileValidateTypeLabelExpectedTypesMap = {};
    const mimesAllowed = inputData && inputData.attr && inputData.attr['mimes-allowed'] ?
        inputData.attr['mimes-allowed'].split(';') : [];
    // Gestion de la validation de l'extension
    if (mimesAllowed && mimesAllowed.length) {
        const labels = inputData && inputData.attr && inputData.attr['labels-ext'] ?
            inputData.attr['labels-ext'].split(';') : [];

        mimesAllowed.forEach((item, index) => {
            fileValidateTypeLabelExpectedTypesMap[item] = labels[index]
        });
    }

    const [{data: filesData, isFinished: filesReady}, fetchFile] = useFetch('blob');

    const onInit = () => {
        if (inputData?.existingFiles) {
            fetchFile({
                url: inputData.existingFiles.source
            })
        }
    }

    useEffect(() => {
        if (filesData && filesReady) {
            setFiles([
                new File(
                    [filesData],
                    inputData.existingFiles.name,
                    {type: inputData.existingFiles.type},
                )
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesData]);

    const onUpdateFiles = (updatedFiles) => {
        let filesSize = 0;
        let hasFileTypeError = false;

        updatedFiles.forEach(updatedFile => {
            filesSize += updatedFile.fileSize;
            if (onFileTypeWrong && mimesAllowed.length && mimesAllowed.indexOf(updatedFile.fileType) === -1) {
                hasFileTypeError = true;
            }
        })

        if (onFileTypeWrong) {
            if (updatedFiles.length) {
                onFileTypeWrong(!hasFileTypeError)
            } else {
                onFileTypeWrong(false)
            }
        }

        filesSize > process.env.REACT_APP_MAX_FILES_SIZE_MB_UPLOAD * 1024 * 1024 ? handleFilesMaxSizeExceeded(true) : setFiles(updatedFiles)
    }

    const onRemoveFile = (error, file) => {
        if (!error) {
            setFiles(currentFiles => {
                return currentFiles.filter(currentFile => currentFile.id !== file.id);
            })
        }
    }

    return (
        <>
            {inputData.error ? (
                <div className="form__error"
                     onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                     onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                >
                    <PolIcon icon="exclamation-circle" className="form__error__icon"/>
                    <p className="form__error__content">{t(inputData.error)}</p>
                </div>
            ) : null}

            <div className="row">
                <div className="col-md-4">
                    <label className="field__label field__label-radio">{inputData.title}</label>
                </div>
                {inputData.addProductsQuoteFiles &&
                    <div className="col-md-8 justify-content-end" style={{textAlign: 'right'}}>
                        <InputFileImportProductsDocs files={files} setFiles={setFiles}
                                                     quoteId={inputData.addProductsQuoteFiles}/>
                    </div>
                }
            </div>

            <div className="row">
                {inputData.attr?.['data-current-logo'] ? (
                    <div className="col-12">
                        <img className="list__item__logo" src={inputData.attr['data-current-logo']} alt="logo"/>
                    </div>
                ) : null}
                <div className="col-12">
                    <FilePond
                        className="filepond"
                        files={files}
                        oninit={onInit}
                        onupdatefiles={onUpdateFiles}
                        onremovefile={onRemoveFile}
                        allowMultiple={inputData.attr && inputData.attr.multiple} labelIdle={t('add_files')}
                        maxTotalFileSize={`${process.env.REACT_APP_MAX_FILES_SIZE_MB_UPLOAD}MB`}
                        labelMaxTotalFileSizeExceeded={t('maximum_size_exceeded_label')}
                        labelMaxTotalFileSize={t('maximum_size_label')}
                        acceptedFileTypes={mimesAllowed}
                        fileValidateTypeLabelExpectedTypesMap={fileValidateTypeLabelExpectedTypesMap}
                        labelFileTypeNotAllowed={t('filepond_wrong_filetype')}
                        fileValidateTypeLabelExpectedTypes={t('filepond_expect_filetype')}
                    />
                </div>
            </div>

            <DocumentList
                files={holdedFiles}
                updateInput={updateInput}
                deleteService={deleteService}
                setPopup={setPopup}
                t={t}
                doNotDeleteFiles={inputData.doNotDeleteFiles}
            />
        </>
    )
}

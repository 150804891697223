import React from 'react';
import {ViewOrdersContextProvider} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import ViewOrdersHeader from "v4/pages/admin/ViewOrders/components/ViewOrdersHeader/ViewOrdersHeader";
import './ViewOrdersPage.scss';
import ViewOrdersContent
    from "v4/pages/admin/ViewOrders/components/ViewOrdersContent/ViewOrdersContent";

export default function ViewOrdersPage() {

    return (
        <ViewOrdersContextProvider>
            <main className="box">
                <div className="box__header">
                    <ViewOrdersHeader/>
                </div>
                <div className="box__body new-view-orders">
                    <ViewOrdersContent/>
                </div>
            </main>
        </ViewOrdersContextProvider>
    )
}

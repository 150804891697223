import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import useToggle from "v4/hooks/useToggle";

export default function ConfiguredActionsGroup({actions, renderAction, classNamePrefix, btnClassName}) {
    const [isActive, toggleActive] = useToggle();
    const handleGroupClick = (e) => {
        if (e.target.closest('.no-group-click')) return;

        toggleActive();
    }
    return (
        <div className={`${btnClassName} ${classNamePrefix}__group ${isActive ? 'active' : ''}`}
             onClick={handleGroupClick}
             onMouseLeave={isActive ? toggleActive : undefined}>
            <PolIcon icon="actions"/>
            <ul className={`${classNamePrefix}__group__list`}>
                {actions.map(renderAction)}
            </ul>
        </div>
    )
}

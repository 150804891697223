import {captureException} from "@sentry/react";
import {useFrontContext} from "v4/contexts/FrontContext";
import {getLSItem} from "v4/services/localStorage.service";
import {toCapitalize} from "v4/utils";

export default function useHandleDefaultSearchFilters() {
    const {tab, currentListingEntity, isProspectTab} = useFrontContext();
    const entityOrTabId = tab ? tab.id : toCapitalize(currentListingEntity);
    const {defaultSearchFilters} = getLSItem('user');
    let defaultFilters = {};

    try {
        defaultFilters = JSON.parse(defaultSearchFilters);
    } catch (e) {
        captureException(e, {
            extra: {
                defaultSearchFilters,
            }
        });
    }

    return {
        ...defaultFilters[isProspectTab ? undefined : entityOrTabId],
    }
}

import React from 'react';

export default function InputNumber({
                                        fieldName,
                                        fieldValue,
                                        prefix,
                                        placeholder,
                                        error,
                                        attr,
                                        inputConstraints,
                                        onChange
                                    }) {

    const handleChange = ({currentTarget: {value}}) => {
        onChange(value);
    }

    return (
        <input className={`input-field${error ? ' has-error' : ''}`}
               type="number"
               id={prefix + fieldName}
               value={fieldValue}
               placeholder={placeholder}
               data-is-required={!!inputConstraints?.required}
               {...attr}
               onChange={handleChange}/>
    )
}

export default function getAvailableWidths(groupWidth, isGroup = true) {
    const availableWidths = [25, 33, 50, 66, 75, 100];
    const filterAvailableFieldWidths = width => {
        switch (+groupWidth) {
            case 25:
            case 33:
                return width === 100;
            case 50:
            case 66:
                return width === 50 || width === 100;
            case 75:
                return width === 33 || width === 50 || width === 66 || width === 100;
            case 100:
                return width === 25 || width === 33 || width === 50 || width === 66 || width === 75 || width === 100;
            default:
                return false;
        }
    }

    return isGroup ? availableWidths : availableWidths.filter(filterAvailableFieldWidths);
}

import {useCallback, useEffect, useMemo, useRef} from "react";
import useToggle from "v4/hooks/useToggle";
import {CHOICE_TYPE} from "v4/data/fieldTypes";
import {randomId} from "v4/utils";

export default function useFieldWrapperLabel(field) {
    const fieldWrapperRef = useRef();
    const [isLabelActive, toggleLabelActive] = useToggle();
    const fieldPrefix = useMemo(() => `id-${randomId()}_`, []);
    const getFieldElement = useCallback(() => {
        if (!fieldWrapperRef.current) return;

        if (field.type === CHOICE_TYPE) {
            return fieldWrapperRef.current?.querySelector('.react-select__value-container');
        }

        return fieldWrapperRef.current?.querySelector(`[id^="${fieldPrefix}"]`);
    }, [field, fieldPrefix]);

    useEffect(() => {
        const fieldElement = getFieldElement();
        if (!fieldElement) return;

        const hasValue = () => (
            fieldElement.value?.length > 0 ||
            fieldElement.classList.contains('react-select__value-container--has-value')
        );

        const onFocus = () => toggleLabelActive(true);

        const onBlur = () => {
            if (hasValue()) return;

            toggleLabelActive(false);
        }

        fieldElement.addEventListener('focusin', onFocus);
        fieldElement.addEventListener('focusout', onBlur);

        if (hasValue()) toggleLabelActive(true);

        return () => {
            fieldElement.removeEventListener('focusin', onFocus);
            fieldElement.removeEventListener('focusout', onBlur);
        }
    }, [fieldPrefix, getFieldElement, toggleLabelActive]);

    return {
        isLabelActive,
        fieldPrefix,
        fieldLabel: field?.label,
        fieldWrapperRef,
    }
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link, useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Confirm from "v4/components/ui/Confirm/Confirm";
import {QUOTE_DELETE} from "v4/data/apiRoutes";
import {TAB_OPEN_TASKS, TAB_QUOTES} from "v4/data/tabNames";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import useDeleteEntity from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/hooks/useDeleteEntity";
import QuotePdfVersionningButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuotesTabRowActions/QuotePdfVersionningButton/QuotePdfVersionningButton";
import QuoteLinesVoter from "v4/voters/quoteLinesVoter";

export default function ProspectQuotesTabRowActions({rowId, onDuplicate, onReoffer, quote_invoices, isSplit}) {
    const {t} = usePolTranslation();
    const [isConfirmOpen, toggleConfirmOpen] = useToggle();
    const deleteContact = useDeleteEntity(QUOTE_DELETE, rowId, toggleConfirmOpen);
    const {push, location: {pathname}} = useHistory();
    const handleRelaunch = () => push({
        pathname: `${pathname.replace(TAB_QUOTES, TAB_OPEN_TASKS)}/add`,
        state: {relaunchId: rowId}
    });

    const quoteInvoices = JSON.parse(quote_invoices);

    const {canAccessQuoteLines} = QuoteLinesVoter();

    return (
        <>
            <Link to={({pathname}) => `${pathname}/${rowId}`} className="table-link"/>
            <ul className="table-actions">
                {canAccessQuoteLines() && quoteInvoices && (
                    <li className={quoteInvoices.archives.length > 0 ? "has-dropdown" : undefined} data-for="row-action"
                        data-tip={t('download_pdf_quote')} data-disabled={quoteInvoices.current ? 'false' : 'true'}>
                        <QuotePdfVersionningButton quoteInvoices={quoteInvoices}/>
                    </li>
                )
                }
                {canAccessQuoteLines() && (
                    <li onClick={onReoffer(rowId)} data-for="row-action" data-tip={t('refresh_quote')}>
                        <PolIcon icon="refresh"/>
                    </li>
                )}
                <li data-for="row-action" data-tip={t('edit')}>
                    <Link to={({pathname}) => `${pathname}/edit/${rowId}`}>
                        <PolIcon icon="edit"/>
                    </Link>
                </li>
                <li onClick={!isSplit ? onDuplicate(rowId) : undefined}
                    data-for="row-action"
                    data-tip={t(isSplit ? 'no_action_splitted_quote' : 'duplicate_action_quote')}
                    data-disabled={isSplit}>
                    <PolIcon icon="copy"/>
                </li>
                {canAccessQuoteLines() && (
                    <li onClick={!isSplit ? handleRelaunch : undefined}
                        data-for="row-action"
                        data-tip={t(isSplit ? 'no_action_splitted_quote' : 'recovery_action_quote')}
                        data-disabled={isSplit}>
                        <PolIcon icon="file-export-solid"/>
                    </li>
                )}
                <li data-for="row-action" data-tip={t('delete')}>
                    <PolIcon icon="trash" onClick={toggleConfirmOpen}/>
                </li>
            </ul>
            <ReactTooltip id="row-action" effect="solid"/>
            <Confirm title={t('delete')}
                     isOpen={isConfirmOpen}
                     cancelAction={toggleConfirmOpen}
                     confirmAction={deleteContact}>
                <p>{t('popup_validation_suppression')}</p>
            </Confirm>
        </>
    )
}

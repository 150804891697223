import React, {useCallback, useState} from 'react';
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import ProspectQuotesDuplicate
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesDuplicate/ProspectQuotesDuplicate";
import ProspectQuotesReoffer
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesReoffer/ProspectQuotesReoffer";
import ProspectQuotesTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesTabPartial/ProspectQuotesTabPartial";
import ProspectQuotesTabActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuotesTabActions/ProspectQuotesTabActions";
import ProspectQuotesTabRowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuotesTabRowActions/ProspectQuotesTabRowActions";

const fetchParams = {
    'status.isWon': false,
}

export default function ProspectQuotesTabListing({prospectId}) {
    const [rowIdToDuplicate, setRowIdToDuplicate] = useState(null);
    const [rowIdToReoffer, setRowIdToReoffer] = useState(null);

    const handleDuplicate = (rowId) => () => setRowIdToDuplicate(rowId);
    const handleReoffer = (rowId) => () => setRowIdToReoffer(rowId);

    const closeDuplicatePopup = useCallback(() => setRowIdToDuplicate(null), [setRowIdToDuplicate]);
    const closeReofferPopup = useCallback(() => setRowIdToReoffer(null), [setRowIdToReoffer]);

    return (
        <>
            <div className="prospect-tab__listing__wrapper">
                <ProspectHeadingTabPartial title="devis_offres" actions={(props) => <ProspectQuotesTabActions {...props}/>}/>
                <ProspectQuotesTabPartial fetchParams={fetchParams}
                                          prospectId={prospectId}
                                          rowActions={<ProspectQuotesTabRowActions onDuplicate={handleDuplicate} onReoffer={handleReoffer}/>}/>
            </div>

            <ToggleComponent isShowing={rowIdToDuplicate} shouldRemoveFromDom={true} className="duplicate-popup">
                <ProspectQuotesDuplicate rowIdToDuplicate={rowIdToDuplicate} closePopup={closeDuplicatePopup}/>
            </ToggleComponent>

            <ToggleComponent isShowing={rowIdToReoffer} shouldRemoveFromDom={true} className="reoffer-popup">
                <ProspectQuotesReoffer rowIdToReoffer={rowIdToReoffer} closePopup={closeReofferPopup}/>
            </ToggleComponent>
        </>
    )
}

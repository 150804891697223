import React, {useMemo, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {TASK_ITEM, TASK_ITEM_FORM} from "v4/data/apiRoutes";
import {TAB_OLD_MAILS_TASKS} from "v4/data/tabNames";
import useTestRoute from "v4/hooks/useTestRoute";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import ShowEntityLayout from "v4/layouts/ShowEntityLayout/ShowEntityLayout";
import ProspectOldMailTasksTabListing
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOldMailTasksTab/components/ProspectOldMailTasksTabListing/ProspectOldMailTasksTabListing";
import ProspectOldMailTasksTabStats
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOldMailTasksTab/components/ProspectOldMailTasksTabStats/ProspectOldMailTasksTabStats";
import MoveTaskButton from "v4/features/front/moveTask/components/MoveTaskButton/MoveTaskButton";
import ProspectTabBackButton from "v4/components/utils/ProspectTabBackButton/ProspectTabBackButton";
import ProspectTabEditButton from "v4/components/utils/ProspectTabEditButton/ProspectTabEditButton";
import ProspectTabShowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTabShowActions/ProspectTabShowActions";

export default function ProspectOldMailTasksTab({prospectId}) {
    const isShow = useTestRoute({inParams: 'showId'});
    const isEdit = useTestRoute({inUrl: 'edit'});
    const isListing = !isShow && !isEdit;
    const {params: {showId = null}} = useRouteMatch();
    const [taskData, setTaskData] = useState(null);

    const fetchFormParameters = useMemo(() => ({
        id: showId,
        prospectId
    }), [showId, prospectId]);

    const onGetData = (data) => {
        setTaskData(data?.['hydra:member'] ?? data);
    }

    return (
        <>
            <ToggleComponent isShowing={isListing}>
                <ProspectOldMailTasksTabListing prospectId={prospectId}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isShow && !isEdit} shouldRemoveFromDom={true}
                             className="prospect-tab__prospect showing">
                <div className="prospect-tab__prospect__header">
                    <ProspectTabBackButton/>
                    <ProspectTabEditButton showId={showId}/>
                    <MoveTaskButton taskId={showId} className="prospect-tab__prospect__header__action"/>
                    {taskData?.metadata?.showActions && (
                        <ProspectTabShowActions showId={showId}
                                                prospectId={prospectId}
                                                showActions={taskData.metadata.showActions}/>
                    )}
                </div>
                <ShowEntityLayout entityId={showId}
                                  onGetData={onGetData}
                                  entityRouteName={TASK_ITEM_FORM}>
                    <ProspectOldMailTasksTabStats entityId={showId}/>
                </ShowEntityLayout>
            </ToggleComponent>

            <ToggleComponent isShowing={isEdit} shouldRemoveFromDom={true} className="prospect-tab__prospect editing">
                <FormEntityLayout title="historique_mails"
                                  action="edit"
                                  formRouteName={TASK_ITEM_FORM}
                                  submitRouteName={TASK_ITEM}
                                  fetchFormParameters={fetchFormParameters}
                                  whenSubmitted={whenSubmitted}/>
            </ToggleComponent>
        </>
    )
}

function whenSubmitted(history) {
    history.replace(`${history.location.pathname.split(TAB_OLD_MAILS_TASKS)?.shift()}${TAB_OLD_MAILS_TASKS}`, {hasToRefresh: true});
}

import {useForm} from "react-hook-form";
import useHandleChainedSearch from "v4/contexts/SearchContext/hooks/useHandleChainedSearch";
import useHandleDefaultSearchFilters from "v4/contexts/SearchContext/hooks/useHandleDefaultSearchFilters";
import useGETUrlToObject from "v4/hooks/useGETUrlToObject";

export default function useSearchFormMethods(formOptions, chainedSearch = {}, search = {}) {
    const chainedSearchFromRouter = useHandleChainedSearch();
    const searchFromGETUrl = useGETUrlToObject();
    const defaultSearchFilters = useHandleDefaultSearchFilters();

    formOptions.defaultValues = {
        ...formOptions.defaultValues,
        ...chainedSearch,
        ...search,
        ...chainedSearchFromRouter,
        ...searchFromGETUrl,
        ...defaultSearchFilters,
    }

    formOptions.mode = formOptions.mode ?? 'onTouched';

    return useForm(formOptions);
}

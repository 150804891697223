import {PolIcon} from "components/PolIcon/policon";
import React, {useCallback, useEffect, useMemo} from 'react';
import {useWatch} from "react-hook-form";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {ENTITIES} from "v4/data/entities";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import 'v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/components/AsideFiltersHeader/AsideFiltersHeader.scss';
import {clearUnusedFieldsOfSearch, devideSavedSearch, getFormattedValue, ids, randomId} from "v4/utils";

// alreadyShownDefaultFilters is used to know if a default filter has already been shown
const alreadyShownDefaultFilters = new Set();

export default function AsideFiltersHeader() {
    const {t} = usePolTranslation();
    const {
        formMethods,
        formInputs: {flatInputs, autoCompleteChoices},
        tab,
    } = useSearchContext();

    // getClassName is used to add a class to the filters with default values like myTasks, myQuotes...
    const getClassName = useCallback((key, value) => {
        const {value: defaultValue} = flatInputs.find(input => input.key === key) ?? {};
        if (defaultValue !== value || alreadyShownDefaultFilters.has(key)) return;

        alreadyShownDefaultFilters.add(key);
        return 'is-default';
    }, [flatInputs]);

    // reset alreadyShownDefaultFilters when unmount
    useEffect(() => () => alreadyShownDefaultFilters.clear(), []);

    const getRemoveFilter = useCallback((key, value, isValueInArray = false) => () => {

        if (key === 'isReverse') {
            return formMethods.setValue(key, false);
        }

        if (ids(ENTITIES).includes(key)) {
            if (formMethods.getValues('isReverse')) {
                formMethods.setValue('isReverse', false);
            }

            return formMethods.setValue(key, []);
        }

        if (key === 'distance') {
            formMethods.setValue('lat', '');
            formMethods.setValue('lng', '');

            return formMethods.setValue(key, '');
        }

        if (isValueInArray) {
            return formMethods.setValue(key, formMethods.getValues(key).filter(v => v !== value));
        }

        if (typeof value === "string" || typeof value === "number") {
            return formMethods.setValue(key, '');
        }

        if (Array.isArray(value)) {
            return formMethods.setValue(key, []);
        }

        if (typeof value === "boolean") {
            return formMethods.setValue(key, false);
        }

        if (value.equal) {
            return formMethods.setValue(key, {
                equal: '=',
                begin: '',
                end: '',
            });
        }
    }, [formMethods]);

    const createFilter = useCallback(([key, value]) => {
        const {label, type, choices, attr} = flatInputs.find(input => input.key === key) ?? {};

        if (
            (value.length !== undefined && value.length === 0) ||
            (typeof value === "boolean" && value === false) ||
            (type?.toLowerCase().includes('compare') && value.begin === '' && value.end === '')
        ) return null;

        let removeFilter = getRemoveFilter(key, value);

        if (key === 'isReverse') {
            return (
                <li className={getClassName(key, value)} key={key} onClick={removeFilter}>
                    <PolIcon icon="times-circle"/>
                    {t('isReverse')}
                </li>
            )
        }

        if (ids(ENTITIES).includes(key)) {
            return (
                <li className={getClassName(key, value)} key={key} onClick={removeFilter}>
                    <PolIcon icon="times-circle"/>
                    {t('chainedSearch')} : {t(key)}
                </li>
            )
        }

        if (key === 'lat' || key === 'lng') {
            return null;
        }

        if (key === 'distance') {
            return (
                <li className={getClassName(key, value)} key={key} onClick={removeFilter}>
                    <PolIcon icon="times-circle"/>
                    {t('geolocalisation')}
                </li>
            )
        }

        if (choices) {
            const isAutocomplete = attr?.type === "autocomplete";

            return [value].flat().map(v => {
                let choiceLabel = choices.find(({value}) => v === value)?.label;
                if (!choiceLabel && autoCompleteChoices[key]) {
                    choiceLabel = autoCompleteChoices[key].find(({value: choiceValue}) => v === choiceValue)?.label;
                }
                if (!choiceLabel) {
                    choiceLabel = isAutocomplete ? null : v;
                }

                removeFilter = getRemoveFilter(key, v, Array.isArray(value));
                return (
                    <li className={getClassName(key, value)} key={v} onClick={removeFilter}>
                        <PolIcon icon="times-circle"/>
                        {t(label)} : {choiceLabel ? t(choiceLabel) : <PolIcon icon="circle-notch" className="spinner"/>}
                    </li>
                )
            });
        }

        if (value.equal) {
            const {equal, begin, end} = value;

            const formattedBegin = begin ? getFormattedValue(type, begin) : '';
            const formattedEnd = end ? getFormattedValue(type, end) : '';

            if (equal === '=') {
                return (
                    <li className={getClassName(key, value)} key={label} onClick={removeFilter}>
                        <PolIcon icon="times-circle"/>
                        {t(label)} : {t('equal-to')} {formattedBegin}
                    </li>
                )
            }

            if (equal === '>') {
                return (
                    <li className={getClassName(key, value)} key={label} onClick={removeFilter}>
                        <PolIcon icon="times-circle"/>
                        {t(label)} : min {formattedBegin}
                    </li>
                )
            }

            if (equal === '<') {
                return (
                    <li className={getClassName(key, value)} key={label} onClick={removeFilter}>
                        <PolIcon icon="times-circle"/>
                        {t(label)} : max {formattedEnd}
                    </li>
                )
            }

            if (equal === '><') {
                return (
                    <li className={getClassName(key, value)} key={label} onClick={removeFilter}>
                        <PolIcon icon="times-circle"/>
                        {t(label)} : min {formattedBegin} max {formattedEnd}
                    </li>
                )
            }
        }

        if (typeof value === "boolean") {
            return (
                <li className={getClassName(key, value)} key={label} onClick={removeFilter}>
                    <PolIcon icon="times-circle"/>
                    {t(label)} : {t(value ? 'yes' : 'no')}
                </li>
            )
        }

        return (
            <li className={getClassName(key, value)} key={label ?? value ?? randomId()} onClick={removeFilter}>
                <PolIcon icon="times-circle"/>
                {t(label)} : {getFormattedValue(type, value, {keepTime: true}) ?? value}
            </li>
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoCompleteChoices]);

    const notInTab = useCallback(([key]) => {
        if (!tab) return true;
        const {search, chainedSearch} = devideSavedSearch(tab.searchContent, true);

        return !search?.[key] && !chainedSearch?.[key];
    }, [tab]);

    const inputs = useWatch({control: formMethods.control});
    const filters = useMemo(() =>
            Object.entries(clearUnusedFieldsOfSearch(inputs))
                .filter(notInTab)
                .map(filter => createFilter(filter))
                .filter(Boolean)
                .flat()
        , [inputs, createFilter, notInTab]);

    if (!filters?.length > 0) return null;

    if (window.innerWidth < 768) {
        return (
            <ul className="header__filters__list">
                <li className="header__filters__dropdown">
                    {t('filters_button_text')}
                    <PolIcon icon="chevron-down"/>
                    <ul>
                        {filters}
                    </ul>
                </li>
            </ul>
        )
    }

    return (
        <ul className="header__filters__list">
            {
                filters.length <= 4
                    ? filters
                    : <>
                        <li className="header__filters__dropdown">
                            {t('filters_button_text')}
                            <PolIcon icon="chevron-down"/>
                            <ul>
                                {
                                    filters?.slice(3)
                                }
                            </ul>
                        </li>
                        {
                            filters?.slice(0, 3)
                        }
                    </>
            }
        </ul>
    )
}

import React, {Fragment} from 'react';
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import ViewOrdersGroup from "v4/pages/admin/ViewOrders/components/ViewOrdersGroup/ViewOrdersGroup";
import ViewOrdersField from "v4/pages/admin/ViewOrders/components/ViewOrdersField/ViewOrdersField";
import ViewOrdersGrid from "v4/pages/admin/ViewOrders/components/ViewOrdersGrid/ViewOrdersGrid";
import Loader from "v4/components/ui/Loader/Loader";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ViewOrdersViewer() {
    const {t} = usePolTranslation();
    const {currentViewOrder, isViewOrdersFormLoading, currentKeyForRefresh} = useViewOrdersContext();

    const notSpecialGroups = currentViewOrder?.state.filter(({isDefault, isRequired}) => !isDefault && !isRequired);
    const requiredGroup = currentViewOrder?.state.find(({isRequired}) => isRequired);

    if (isViewOrdersFormLoading) return <Loader message={t('loading')} className="new-view-orders__loader"/>;

    if (!notSpecialGroups && !requiredGroup) return null;

    return (
        <div className="new-view-orders__scrollable">
            <Fragment key={currentKeyForRefresh}> {/* key is used to force re-render when an element has been dragged. It's on a Fragment to keep the scroll position of the container. Do not move it on the parent div */}
                {notSpecialGroups.length > 0 && (
                    <ViewOrdersGrid
                        items={notSpecialGroups}
                        dragHandleClassName="new-view-orders__group__icon"
                        dragType="group"
                        renderItem={(group) => (
                            <ViewOrdersGroup key={group.id}
                                             group={group}>
                                <ViewOrdersGrid
                                    items={group.fields}
                                    dragHandleClassName="new-view-orders__field__icon"
                                    dragType="field"
                                    DOMInfos={group}
                                    renderItem={(field) => (
                                        <ViewOrdersField key={field.id}
                                                         group={group}
                                                         field={field}/>
                                    )}/>
                            </ViewOrdersGroup>
                        )}/>
                )}

                {requiredGroup?.fields.length > 0 && (
                    <ViewOrdersGroup group={requiredGroup} className="required">
                        <ViewOrdersGrid
                            items={requiredGroup.fields}
                            dragHandleClassName="new-view-orders__field__icon"
                            dragType="field"
                            DOMInfos={requiredGroup}
                            renderItem={(field) => (
                                <ViewOrdersField key={field.id}
                                                 group={requiredGroup}
                                                 field={field}/>
                            )}/>
                    </ViewOrdersGroup>
                )}
            </Fragment>
        </div>
    )
}

import * as React from 'react';
import {withTranslation} from "react-i18next";
import forgotPasswordUser from '../../../img/forgot_password.svg'
import {Input} from "../../Input/input";
import {useEffect} from "react";
import {getForm} from "../../../api/admin/get";
import {handleFormData} from "../../../reducers/form";
import {getApiUrl} from "../../../routes";
import {useState} from "react";
import {adminFetchHandler} from "../../../api/datas/datas";
import {connect} from "react-redux";
import Loading from "../../Loading/Loading";
import {setLoader} from "../../../actions/content";
import {PostService} from "../../../api/datas/post";
import {setError} from "../../../actions/datas";

const ChangePassword = ({t, adminFetchHandler, togglePopup, setLoader, postService, form, setError}) => {
    const [inputs, setInputs] = useState({});
    const [isFormLoaded, setFormLoaded] = useState(false);

    const setLoaded = (value) => {
        setLoader({
            'forgot-password': !value
        });
        if(Object.keys(inputs).length) {
            setFormLoaded(value);
        }
    };

    // On mount on get le form
    useEffect(() => {
        if (!Object.keys(inputs).length) {
            setLoaded(false);
            getForm(getApiUrl('user', 'resetPassword')).then(data => {
                const inputs = {};
                const formattedInputs = handleFormData(data);
                Object.keys(formattedInputs).forEach(item => {
                    if (!formattedInputs[item].properties) {
                        inputs[item] = formattedInputs[item]
                    } else {
                        inputs[`${item}_first`] = formattedInputs[item].properties.first
                        inputs[`${item}_second`] = formattedInputs[item].properties.second
                    }
                });

                setInputs(inputs);
                setLoaded(true);
                setFormLoaded(true);
            }).catch(error => {
                adminFetchHandler(error, {})

            })
        }
    });

    // Si retour formulaire avec des erreurs
    useEffect(() => {
        if (form && form.errors) {
            const errors = form.errors;
            Object.keys(errors).forEach(key => {
                const input = inputs[key];
                if(input) {
                    input.error = errors[key];
                }
            });

            setInputs({
                ...inputs
            });

            setLoaded(true);

            delete form.errors;
        } // eslint-disable-next-line
    }, [form]);

    useEffect(() => {
        const handleEnter = (e) => {
            if (e.key === "Escape") {
                togglePopup({});
                e.preventDefault()
            }
            if (e.key === "Enter") {
                handleSubmit();
                e.preventDefault()
            }
        };
        document.addEventListener('keydown', handleEnter, false);
        return () => {
            document.removeEventListener('keydown', handleEnter, false)
        };// eslint-disable-next-line
    }, [inputs]);

    const handleChange = (key, value) => {
        inputs[key].value = value;
        inputs[key].originalValue = '';
        inputs[key].error = null;

        setInputs({
            ...inputs
        })
    };

    const handleSubmit = () => {
        let dataFetch = {};
        let hasError = false;

        // On formatte les données pour l'api, newPassword doit être un objet avec first et second car c'est un input répété
        Object.keys(inputs).forEach(key => {
            if (inputs[key].value === null || !inputs[key].value.length) {
                inputs[key].error = t('field_must_be_filled');
                hasError = true
            }
            if (key !== 'newPassword_first' && key !== 'newPassword_second') {
                dataFetch[key] = inputs[key].value;
            } else {
                if (!dataFetch['newPassword']) {
                    dataFetch['newPassword'] = {}
                }
                dataFetch['newPassword'] = {
                    ...dataFetch['newPassword'],
                    [key === 'newPassword_first' ? 'first' : 'second']: inputs[key].value
                }
            }
        });

        // Si erreur détecté côté react on submit pas
        if (hasError) {
            setInputs({
                ...inputs
            });
            return false;
        }

        setLoaded(false);
        let infoSubmit = {
            url: `/api/reset_password_models`,
            submittedData: dataFetch,
            callback: () => {
                togglePopup({})
                setLoaded(true);
                // Alerte verte
                setError({msg: t('reset_password_ok'), errorCode: 200})
            }
        };

        postService(infoSubmit, t);

        return false;
    };

    return (
        <>
            <div className={'popup__header'}>
                <p className={'popup__header__title'}>{t('changePassword')}</p>
            </div>
            <div className={'popup__forgot-content'}>
                <img className={'popup__forgot-img'} src={forgotPasswordUser} alt={t('changePassword')}/>
                <div className={'popup__forgot-form'}>
                    {!isFormLoaded ? (<Loading message={t('loading')} specificClass={'popup__loader'}/>) : (
                        <>
                            <form className={`form form__scrollable form__admin`} onSubmit={(event) => {
                            }}>
                                {Object.keys(inputs).map((key, index) => {
                                    const input = inputs[key]
                                    let fieldClass = input && input.value ? 'field-written' : '';
                                    fieldClass += input && input.error ? ' field-error' : '';

                                    return (
                                        <React.Fragment key={key}>
                                            <div className={`col-12 field ${fieldClass}`} key={index}>
                                                <Input t={t}
                                                       inputData={input}
                                                       onChange={(newValue) => handleChange(key, newValue)}
                                                       typeForm={null}
                                                />
                                            </div>
                                        </React.Fragment>)
                                })}
                            </form>

                            <div className={'row popup__buttons'}>
                                <div className={'col-6'}>
                                    <button className={`btn btn-third decline`}
                                            onClick={() => {
                                                togglePopup({})
                                            }}>{t('cancel')}</button>
                                </div>
                                <div className={'col-6'}>
                                    <button className={`btn btn-third accept`}
                                            onClick={() => {
                                                handleSubmit()
                                            }}>{t('validate')}</button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({form}) => ({
    form
});

const mapDispatchToProps = dispatch => {
    return {
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setLoader: (value) => dispatch(setLoader(value)),
        postService: (info, t) => dispatch(PostService(info, t)),
        setError: (info) => dispatch(setError(info)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(React.memo(ChangePassword)));


import React, {useContext} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useRouteMatch} from "react-router-dom";
import {AdminContext} from "v4/contexts/AdminContext";
import CustomEventTriggersForm from "v4/pages/admin/CustomEventTriggers/components/CustomEventTriggersForm/CustomEventTriggersForm";
import CustomEventTriggersList from "v4/pages/admin/CustomEventTriggers/components/CustomEventTriggersList/CustomEventTriggersList";
export default function CustomEventTriggersPage() {
    const {t} = usePolTranslation();
    const {path, params: {id}, isExact} = useRouteMatch();
    const isAddPage = path.split('/').pop() === 'add';
    const isEditPage = !!id && isExact;
    const isListPage = (!isAddPage && !isEditPage);
    const {asCustomer} = useContext(AdminContext);
    const getComponent = () => {
        if (isListPage) {
            return <CustomEventTriggersList customerId={asCustomer?.id}/>
        }
        return <CustomEventTriggersForm id={id} customerId={asCustomer?.id}/>
    }
    return (
        <>
            {asCustomer?.id ?
                (
                    <>
                        {getComponent()}
                    </>
                ) : (
                    <p>{t('no_customer_defined')}</p>
                )
            }
        </>
    )
}
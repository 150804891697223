import React from 'react';
import {useParams} from "react-router-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {MAIL_TYPE_SEARCH} from "v4/data/apiRoutes";
import useTestRoute from "v4/hooks/useTestRoute";
import MailsTypesAdd from "v4/pages/front/MailsTypes/components/MailsTypesAdd/MailsTypesAdd";
import MailsTypesEdit from "v4/pages/front/MailsTypes/components/MailsTypesEdit/MailsTypesEdit";
import MailsTypesListing from "v4/pages/front/MailsTypes/components/MailsTypesListing/MailsTypesListing";
import useHashPulse from "v4/hooks/useHashPulse";

/* TODO: Setup done but finish when there will be the routes */
export default function MailsTypesPage() {
    const isAddPage = useTestRoute({inUrl: ['add']});
    const isEditPage = useTestRoute({inParams: ['mailTypeId'], inUrl: ['edit']}, true);
    const isListingShowing = !(isAddPage || isEditPage);
    const {mailTypeId} = useParams();
    useHashPulse(isListingShowing);

    return (
        <>
            <SearchProvider searchRouteName={MAIL_TYPE_SEARCH} pageType="mailTypes">
                <ToggleComponent isShowing={isListingShowing} className="page page-list main-content__body__slide">
                    <MailsTypesListing/>
                </ToggleComponent>
            </SearchProvider>

            {isAddPage && <MailsTypesAdd/>}
            {isEditPage && <MailsTypesEdit mailTypeId={mailTypeId}/>}
        </>
    );
}

import ErrorBoundary from "components/Error/errorBoundary";
import {PolIcon} from "components/PolIcon/policon";
import React, {useState} from 'react'
import {createPortal} from "react-dom";
import 'v4/components/ui/PolPopup/PolPopup.scss';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

const defaultClassNames = {
    overlay: '',
    popup: '',
    header: '',
    content: '',
    btns: '',
}

/**
 * @param isOpen
 * @param closePopup
 * @param title
 * @param parentSelector
 * @param {{overlay?: string, popup?: string, header?: string, content?: string, btns?: string}} classNames
 * @param buttonsRender
 * @param sticked - {header: false, btns: false}
 * @param children
 */

export default function PolPopup({
                                     isOpen,
                                     closePopup,
                                     title,
                                     secondTitle,
                                     titleNoTranslation = false,
                                     parentSelector = '#root',
                                     classNames = {},
                                     buttonsRender,
                                     sticked = {},
                                     children
                                 }) {
    const finalClassNames = {...defaultClassNames, ...classNames};
    const {t} = usePolTranslation();
    const modalRoot = document.querySelector(parentSelector);
    /* Creating an internal state to allow communication between children components and buttonsRender */
    const [internalState, setInternalState] = useState({});

    if (!modalRoot || !isOpen) return null;

    return (
        createPortal(
            <div className={"pol-popup__overlay " + finalClassNames.overlay}>
                <div className={"pol-popup " + finalClassNames.popup}>
                    <div className={`pol-popup__header ${finalClassNames.header} ${sticked.header ? 'is-sticky' : ''}`}>
                        <p>{titleNoTranslation ? title : t(title)}</p>
                        {secondTitle && <p className="pol-popup__second-title">
                            {titleNoTranslation ? secondTitle : t(secondTitle)}
                        </p>}
                        <PolIcon icon="close" onClick={closePopup}/>
                    </div>
                    <ErrorBoundary>
                        <> {/* Fragment to avoid crash when children is not defined */}
                            {
                                children &&
                                <div className={"pol-popup__content " + finalClassNames.content}>
                                    {/* Allows to pass some props to the child if needed */}
                                    {typeof children === 'function'
                                        ? children({internalState, setInternalState})
                                        : children
                                    }
                                </div>
                            }
                        </>
                    </ErrorBoundary>
                    {
                        buttonsRender &&
                        <div className={`pol-popup__btns ${finalClassNames.btns} ${sticked.btns ? 'is-sticky' : ''}`}>
                            {buttonsRender(internalState)}
                        </div>
                    }
                </div>
            </div>
            , modalRoot)
    )
}

import React, {useEffect, useState} from 'react';
import "v4/components/utils/SearchContextSearchbar/SearchContextSearchbar.scss";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {debounce} from "v4/utils";

export default function SearchContextSearchbar() {
    const {t} = usePolTranslation();
    const {tab, searchbar, setSearchbar} = useSearchContext();
    const [searchbarInput, setSearchbarInput] = useState('');

    useEffect(() => {
        debounce(() => setSearchbar(searchbarInput));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchbarInput]);

    useEffect(() => {
        if (searchbar !== searchbarInput) {
            setSearchbarInput(searchbar);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchbar]);

    const onChange = ({currentTarget: {value}}) => {
        if (tab) {
            // try catch to avoid error when currentSavedSearch is not a valid JSON
            try {
                const {searchbar: savedSearchbar} = JSON.parse(tab.searchContent);
                if (savedSearchbar === value) return;
            } catch (e) {
                console.error(e);
            }
        }

        setSearchbarInput(value);
    }

    return (
        <div className="listing-searchbar">
            <input type="text"
                   className="datatable__search__input"
                   placeholder={t('votre_recherche')}
                   value={searchbarInput}
                   onChange={onChange}/>
        </div>
    )
}

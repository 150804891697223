import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Draggable, Droppable} from "react-beautiful-dnd";
import {useFormContext} from "react-hook-form";
import ReactTooltip from "react-tooltip";
import Field from "v4/components/form/Field/Field";
import QuoteLineDescription
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuoteLineDescription/QuoteLineDescription";
import {
    useQuotesProductsDetailsContext
} from "v4/features/front/products/components/QuotesProductsDetails/contexts/QuotesProductsDetailsContext/QuotesProductsDetailsContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrlFromRelativeUrl} from "v4/services/api.service";
import {getLSItem} from "v4/services/localStorage.service";
import {financial} from "v4/utils";

export default function QuotesProductsTableSection({
                                                       isShow,
                                                       name,
                                                       id,
                                                       sectionIndex,
                                                       isDefault,
                                                       editAttributesFor,
                                                   }) {
    const {t} = usePolTranslation();
    const {
        quoteLinesMethods: {fields, remove, update},
        sectionsNames,
        updateSectionName,
        removeSection,
        properties,
    } = useQuotesProductsDetailsContext();

    const {getValues, watch} = useFormContext();
    const getDiscountValueConstraints = (names, constraints) => ({
        constraints: {
            ...constraints,
            LessThanOrEqual: {
                message: 'This value should be greater than {{ compared_value }}.',
                value: getMaxDiscountPossible(watch([
                    names.unitPriceExclVat,
                    names.quantity,
                    names.discountType
                ])),
            },
        }
    })

    const updateQuoteLineSection = (index, sectionName) => {
        update(index, {
            ...getValues(`quoteLines.${index}`),
            sectionName,
        });
    }

    const onlyCurrentSection = ({sectionName}) => {
        return sectionName === name || (!sectionName && isDefault);
    }

    const editSectionName = e => {
        const {value} = e.target;
        updateSectionName(sectionIndex, id, value);
        fields.filter(onlyCurrentSection).forEach((field) => {
            const fieldIndex = fields.indexOf(field);
            updateQuoteLineSection(fieldIndex, `${sectionIndex}|${value}|${id}`);
        });
    }

    const removeCurrentSection = () => {
        removeSection(id);
        fields.filter(onlyCurrentSection).forEach((field) => {
            const fieldIndex = fields.indexOf(field);
            update(fieldIndex, {
                ...field,
                sectionName: '',
            })
        });
    }

    const defaultQuoteSections = getLSItem('user')?.defaultQuoteSections?.['hydra:member'] ?? [];
    const autocompleteOptions = defaultQuoteSections.reduce((prev, {label}) => {
        if (!sectionsNames.some(({name}) => name.split('|')[1] === label)) {
            return [...prev, label];
        }

        return prev;
    }, []);

    return (
        <div className="quotes-products__tbody__section">
            {
                isShow
                    ? (
                        <h3>
                            <span>{isDefault ? t('without_section') : name.split('|')[1]}</span>
                        </h3>
                    )
                    : (
                        <div className="quotes-products__tbody__section__heading">
                            <input type="text"
                                   value={name.split('|')[1] ?? ''}
                                   data-debug-name={name}
                                   list="quoteSections"
                                   onChange={editSectionName}
                                   placeholder={isDefault ? t('without_section') : t('new_section')}/>
                            {autocompleteOptions.length > 0 && (
                                <datalist id="quoteSections">
                                    {autocompleteOptions.map((option, index) => (
                                        <option key={index} value={option}/>
                                    ))}
                                </datalist>
                            )}
                            {!isDefault && (
                                <>
                                    <button type="button"
                                            data-tip={t('delete') + ' ' + name.split('|')[1]}
                                            data-for="delete"
                                            onClick={removeCurrentSection}>
                                        <PolIcon icon="minus"/>
                                    </button>
                                    <ReactTooltip id="delete" effect="solid"/>
                                </>
                            )}
                        </div>
                    )
            }
            <Droppable key={id} droppableId={id}>
                {(provided) => {
                    return (
                        <div className="quotes-products__tbody__droppable"
                             ref={provided.innerRef} {...provided.droppableProps}>
                            {
                                fields.filter(onlyCurrentSection).map((field, index) => {
                                    const realIndex = fields.findIndex(f => f.id === field.id);
                                    const names = Object.keys(field).reduce((prev, current) => (
                                        {
                                            ...prev,
                                            [current]: `quoteLines.${realIndex}.${current}`
                                        }
                                    ), {
                                        vat: `quoteLines.${realIndex}.vatRate`,
                                        comment: `quoteLines.${realIndex}.comment`,
                                        description: `quoteLines.${realIndex}.description`,
                                    });
                                    const product = field?.quoteLineInfo?.product;

                                    return <Draggable key={field.id} draggableId={field.id}
                                                      index={index}
                                                      isDragDisabled={isShow}>
                                        {(provided) => (
                                            <div className="quotes-products__item"
                                                 ref={provided.innerRef}
                                                 {...provided.draggableProps}
                                                 {...provided.dragHandleProps}>
                                                <div className="quotes-products__item__img">
                                                    {
                                                        product?.imageUrl &&
                                                        <img
                                                            src={generateUrlFromRelativeUrl(product.imageUrl)}
                                                            alt={field.name}/>
                                                    }
                                                </div>
                                                <div
                                                    className="quotes-products__item__optional">
                                                    {
                                                        isShow
                                                            ?
                                                            <p className="quotes-products__item__label__is_optional">{t(field.isOptional ? 'yes' : 'no')}</p>
                                                            :
                                                            <Field name={names.isOptional} {...properties.isOptional}/>
                                                    }
                                                </div>
                                                <div
                                                    className="quotes-products__item__label">
                                                    <div>
                                                        {
                                                            product?.id || isShow
                                                                ? (
                                                                    <div
                                                                        className="quotes-products__item__product">
                                                                        <p>{field.name}</p>
                                                                        {product?.reference &&
                                                                            <p>{t('ref')} : {product.reference}</p>}
                                                                        {product?.categories &&
                                                                            <p>
                                                                                {t(product.categories.length > 1 ? 'productFamilies' : 'productFamily')} : {product.categories.map(({name}) => name).join(', ')}
                                                                            </p>}
                                                                    </div>
                                                                )
                                                                : <div
                                                                    className='input-field-wrapper'>
                                                                    <Field name={names.name} {...properties.name}/>
                                                                </div>
                                                        }
                                                        <QuoteLineDescription isShow={isShow} name={names.description}
                                                                              field={field}/>
                                                        {
                                                            isShow
                                                                ?
                                                                <p className="quotes-products__item__label__commentary">{field.comment}</p>
                                                                : <Field name={names.comment}
                                                                         placeholder={t('comment')}
                                                                         {...properties.comment}/>
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    className="quotes-products__item__price">
                                                    {
                                                        isShow
                                                            ?
                                                            <p>{financial(field.unitPriceExclVat)}</p>
                                                            : <div
                                                                className='input-field-wrapper'>
                                                                <Field name={names.unitPriceExclVat}
                                                                       {...properties.unitPriceExclVat}/>
                                                            </div>
                                                    }
                                                </div>
                                                <div
                                                    className="quotes-products__item__quantity">
                                                    {
                                                        isShow
                                                            ? <p>{field.quantity}</p>
                                                            : <div
                                                                className='input-field-wrapper'>
                                                                <Field name={names.quantity} {...properties.quantity}/>
                                                            </div>
                                                    }
                                                </div>
                                                <div
                                                    className="quotes-products__item__discount">
                                                    <div>
                                                        {
                                                            isShow
                                                                ? (
                                                                    <p>
                                                                        {
                                                                            field.discountValue > 0
                                                                                ? (
                                                                                    field.discountType === 'amount'
                                                                                        ? financial(field.discountValue)
                                                                                        : `${field.discountValue} %`
                                                                                )
                                                                                : '-'
                                                                        }
                                                                    </p>
                                                                )
                                                                : (
                                                                    <>
                                                                        <div
                                                                            className='input-field-wrapper'>
                                                                            <Field name={names.discountValue}
                                                                                   {...properties.discountValue}
                                                                                   {...getDiscountValueConstraints(names, properties.discountValue?.constraints)}/>
                                                                        </div>
                                                                        <Field name={names.discountType}
                                                                               specificParams={{isClearable: false}}
                                                                               {...properties.discountType}/>
                                                                    </>
                                                                )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="quotes-products__item__vat">
                                                    {isShow
                                                        ? (
                                                            <p>{(properties.vatRate?.choices ?? []).find(choice => choice?.value === field.vatRate)?.label}</p>
                                                        ) : (
                                                            <Field name={names.vat}
                                                                   specificParams={{isClearable: false}}
                                                                   {...properties.vatRate}/>
                                                        )
                                                    }
                                                </div>
                                                <div
                                                    className="quotes-products__item__total">
                                                    <div>
                                                        {getTotal(watch([
                                                            names.unitPriceExclVat,
                                                            names.quantity,
                                                            names.discountValue,
                                                            names.discountType
                                                        ]))}
                                                    </div>
                                                </div>
                                                <div
                                                    className="quotes-products__item__actions">
                                                    <div>
                                                        <div
                                                            className="quotes-products__item__actions__btns">
                                                            {!isShow && (
                                                                <button type="button"
                                                                        onClick={() => remove(realIndex)}>
                                                                    <PolIcon icon="trash"/>
                                                                </button>
                                                            )}
                                                            {!!product?.productConfiguration && (
                                                                <button type="button"
                                                                        onClick={() => editAttributesFor(realIndex)}>
                                                                    <PolIcon icon="cog"/>
                                                                </button>
                                                            )}
                                                        </div>
                                                        {!isShow && <PolIcon icon="draggable"/>}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                })
                            }
                            {provided.placeholder}
                        </div>
                    )
                }}
            </Droppable>
            {
                sectionsNames.length > 1 && (
                    <div className="quotes-products__tbody__section__total">
                        <span>{t('subtotal_with_discount')} {name.split('|')[1]} :</span>
                        <span>{financial(getSubTotal(watch('quoteLines'), name))}</span>
                    </div>
                )
            }
        </div>
    )
}

function getMaxDiscountPossible([unit_price = 0, quantity = 0, discount_type = 'amount']) {
    return discount_type === 'amount' ? (unit_price * quantity) : 100;
}

function getTotal([unit_price = 0, quantity = 0, discount = 0, discount_type = 'amount']) {
    const discountByUnitIfPercentage = discount_type === 'percentage' ? parseFloat((unit_price * (discount / 100)).toFixed(2)) : 0;
    return <>
        {
            discount > 0 && (
                <p className="discount">
                    {
                        financial(unit_price * quantity)
                    }
                </p>
            )
        }
        {discount_type === 'amount' && <p>{financial((unit_price * quantity) - discount)}</p>}
        {
            discount_type === 'percentage' && (
                <p>
                    {
                        financial((unit_price - discountByUnitIfPercentage) * quantity)
                    }
                </p>
            )
        }
    </>
}

function getSubTotal(values, sectionNameFilter) {
    return values
        .filter(({isOptional, sectionName}) => !isOptional && sectionName === sectionNameFilter)
        .reduce((acc, {unitPriceExclVat, quantity, discountValue, discountType}) => {
            const discountByUnitIfPercentage = discountType === 'percentage' ? parseFloat((unitPriceExclVat * (discountValue / 100)).toFixed(2)) : 0;

            if (discountType === 'amount') {
                acc += (unitPriceExclVat * quantity) - discountValue;
            } else {
                acc += ((unitPriceExclVat - discountByUnitIfPercentage) * quantity);
            }

            return acc;
        }, 0)
}

import React from 'react';
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {CONTACT} from "v4/data/entities";
import Field from "v4/components/form/Field/Field";
import {FormProvider} from "react-hook-form";
export default function ProspectTabSearchListing({entity}) {
    const {formInputs, formMethods} = useSearchContext();

    const inputSearch = getInputSearch(formInputs, entity);

    if (!inputSearch) return null;

    return (
        <div className="prospect-tab__search">
            <FormProvider {...formMethods}>
                <Field name={inputSearch.key} {...inputSearch}/>
            </FormProvider>
        </div>
    )
}

function getInputSearch(formInputs, entity) {
    let searchInputKey = 'search';
    switch (entity) {
        case CONTACT:
            searchInputKey = 'fullname';
            break;
        default:
            break;
    }

    return formInputs?.flatInputs?.find(({key}) => key === searchInputKey) ?? null
}

export const setForm = (name, inputs) => {
    return {
        type: 'SET_FORM',
        name,
        inputs
    }
};

export const resetForm = () => {
    return {
        type: 'RESET_FORM',
    }
};

export const deleteForm = (name) => {
    return {
        type: 'DELETE_FORM',
        name
    }
};

export const setFormError = (errors) => {
    return {
        type: 'SET_FORM_ERROR',
        errors
    }
};

export const setFormSuccess = () => {
    return {
        type: 'SET_FORM_SUCCESS'
    }
};

export const setFormEndRedirect = () => {
    return {
        type: 'SET_REDIRECT_FALSE'
    }
};

export const setFilterSearch = (info) => {
    return {
        type: 'SET_FILTER_SEARCH',
        info
    }
};

export const setPreFillingObject = (prefillInputs) => {
    return {
        type: 'SET_PREFILLING_OBJECT',
        prefillInputs
    }
};


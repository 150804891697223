import {useState} from "react";
import {useFormContext} from "react-hook-form";
import {
    MAX_LENGTH_DESCRIPTION
} from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuoteLineDescription/components/QuoteLineDescriptionEditor/QuoteLineDescriptionEditor";

export default function useQuoteLineDescription(name, togglePopup) {
    const {getValues, setValue} = useFormContext();
    const [isErrorMaxLength, setIsErrorMaxLength] = useState(false);

    const handleLengthError = (value) => {
        const length = value?.length ?? 0;
        setIsErrorMaxLength(length > MAX_LENGTH_DESCRIPTION);
    }

    const [description, setDescription] = useState(() => {
        const value = getValues(name) ?? '';
        handleLengthError(value);

        return value;
    });

    const handleUpdate = (value) => {
        handleLengthError(value);

        setDescription(value);
    };

    const checkSizeLimit = (evt, editor) => {
        const length = editor.getContent().length;
        if (length > MAX_LENGTH_DESCRIPTION) {
            evt.preventDefault();
        }
    };

    const handleSave = () => {
        setValue(name, description, {shouldDirty: true, shouldTouch: true});

        togglePopup();
    }

    return {
        description,
        handleUpdate,
        checkSizeLimit,
        handleSave,
        isErrorMaxLength,
    }
}

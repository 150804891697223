import React, {useMemo, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {CONTACT_ADD, CONTACT_FORM, CONTACT_ITEM, CONTACT_ITEM_FORM,} from "v4/data/apiRoutes";
import useTestRoute from "v4/hooks/useTestRoute";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import ShowEntityLayout from "v4/layouts/ShowEntityLayout/ShowEntityLayout";
import ProspectContactsCustomTabListing
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsCustomTab/components/ProspectContactsCustomTabListing/ProspectContactsCustomTabListing";
import ProspectTabBackButton from "v4/components/utils/ProspectTabBackButton/ProspectTabBackButton";
import ProspectTabEditButton from "v4/components/utils/ProspectTabEditButton/ProspectTabEditButton";
import ProspectTabShowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTabShowActions/ProspectTabShowActions";

export default function ProspectContactsCustomTab({prospectId, tab}) {
    const isShow = useTestRoute({inParams: 'showId'});
    const isEdit = useTestRoute({inUrl: 'edit'});
    const isAdd = useTestRoute({inUrl: 'add'});
    const isListing = !isShow && !isEdit && !isAdd;
    const {params: {showId = null}} = useRouteMatch();
    const [contactData, setContactData] = useState(null);

    const fetchFormParameters = useMemo(() => ({
        id: showId,
        prospectId,
        tabId: tab.id
    }), [showId, prospectId, tab]);

    const onGetData = (data) => {
        setContactData(data?.['hydra:member'] ?? data);
    }

    return (
        <>
            <ToggleComponent isShowing={isListing}>
                <ProspectContactsCustomTabListing prospectId={prospectId} tab={tab}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isShow && !isEdit}
                             shouldRemoveFromDom={true}
                             className="prospect-tab__prospect showing">
                <div className="prospect-tab__prospect__header">
                    <ProspectTabBackButton/>
                    <ProspectTabEditButton showId={showId}/>
                    {contactData?.metadata?.showActions && (
                        <ProspectTabShowActions showId={showId}
                                                prospectId={prospectId}
                                                showActions={contactData.metadata.showActions}/>
                    )}
                </div>
                <ShowEntityLayout entityId={showId}
                                  onGetData={onGetData}
                                  entityRouteName={CONTACT_ITEM_FORM}
                                  fetchFormParameters={{tabId: tab.id}}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isAdd || isEdit}
                             shouldRemoveFromDom={true}
                             className="prospect-tab__prospect editing">
                <FormEntityLayout title="contact"
                                  formRouteName={isAdd ? CONTACT_FORM : CONTACT_ITEM_FORM}
                                  submitRouteName={isAdd ? CONTACT_ADD : CONTACT_ITEM}
                                  fetchFormParameters={fetchFormParameters}
                                  whenSubmitted={whenSubmitted(tab)}/>
            </ToggleComponent>
        </>
    )
}

function whenSubmitted(tab) {
    return (history) => history.replace(`${history.location.pathname.split(tab.slug)?.shift()}${tab.slug}`, {hasToRefresh: true});
}

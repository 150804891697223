import React from 'react';
import UserService from "../../api/user/user";
import Prospects from "../Prospects/prospects";
import Contact from "../Contact/contact";
import Actions from "../Actions/actions";
import Devis from "../Devis/devis";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function CustomTabs({match}) {
    const {t} = usePolTranslation();
    const slug = (match.params && match.params.slug) ?? '';
    const tabs = UserService.getTabs();
    let tab = null;
    for(let i = 0; i < tabs.length; i++)
    {
        if (tabs[i].slug === slug) {
            tab = tabs[i];
            break;
        }
    }

    if(null === tab) {
        return <>{t('tab_item_not_found_msg')}</>;
    }

    return (
        <>
            {'prospect' === tab.searchType &&
                <Prospects
                    title={tab.title}
                    subType={tab.id}
                    customTabSearch={tab.searchContent
                        ? JSON.parse(tab.searchContent)
                        : {}}
                    isCustomTab={true}
                />
            }
            {'contact' === tab.searchType &&
                <Contact
                    title={tab.title}
                    subType={tab.id}
                    customTabSearch={tab.searchContent
                        ? JSON.parse(tab.searchContent)
                        : {}}
                />
            }
            {'task' === tab.searchType &&
                <Actions
                    title={tab.title}
                    subType={tab.id}
                    customTabSearch={tab.searchContent
                        ? JSON.parse(tab.searchContent)
                        : {}}
                />
            }
            {'quote' === tab.searchType &&
                <Devis
                    title={tab.title}
                    subType={tab.id}
                    customTabSearch={tab.searchContent
                        ? JSON.parse(tab.searchContent)
                        : {}}
                />
            }
        </>
    )
}

import React, {createContext} from 'react'

export const MercureContext = createContext({
    hubUrl: '',
    withCredentials: false
})

export default function MercureProvider({hubUrl, prefix, withCredentials = false, children}) {

    return (
        <MercureContext.Provider value={{hubUrl, prefix, withCredentials}}>
            {children}
        </MercureContext.Provider>
    )
}

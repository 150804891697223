import React, {useEffect} from 'react';
import {Prompt} from "react-router-dom";

export default function PreventRedirect({when, message}) {
    useEffect(() => {
        window.onbeforeunload = when && (() => window.confirm());
        return () => window.onbeforeunload = null;
    }, [when]);

    return <Prompt when={when} message={message}/>;
}

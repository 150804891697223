import UserService from "api/user/user";
import {useEffect, useMemo, useState} from 'react';
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {SAVED_REQUEST, SAVED_REQUEST_ITEM} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {getLSItem} from "v4/services/localStorage.service";
import {clearUnusedFieldsOfSearch, devideSavedSearch, toCapitalize} from "v4/utils";

export default function useAdminSavedRequest(entity) {
    const [{data}, fetchSaveSavedRequest] = useFetch();
    const [, fetchDeleteSavedRequest] = useFetch();
    const [{data: savedRequestsData}, fetchGetSavedRequest] = useFetch();
    const [savedRequests, setSavedRequests] = useState([]);
    const [selectedSavedRequest, setSelectedSavedRequest] = useState('');
    const [name, setName] = useState("");
    const [isFormDirty, setIsFormDirty] = useState(false);
    const {
        tab,
        searchbar,
        setSearchbar,
        formMethods,
    } = useSearchContext();

    useEffect(() => {
        if (searchbar !== '') {
            setIsFormDirty(true);
        } else {
            setIsFormDirty(formMethods.formState.isDirty);
        }
    }, [formMethods.formState.isDirty, searchbar]);

    useEffect(() => {
        const options = {
            entity: entity,
            type: 'section_filter',
        }
        if (tab?.id) {
            options.tabId = tab.id;
        }
        fetchGetSavedRequest({
            url: generateUrl(SAVED_REQUEST, options),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (savedRequestsData?.['hydra:member']) {
            setSavedRequests(savedRequestsData['hydra:member']);
        }
    }, [savedRequestsData]);

    const options = useMemo(() => savedRequests.map(({content, name}) => ({
        value: content,
        label: name
    })), [savedRequests]);

    const onSelectChange = (value) => {
        setSelectedSavedRequest(value);
        if (value && value !== '') {
            const {searchbar, chainedSearch, search} = devideSavedSearch(value, true);
            setSearchbar(searchbar);
            Object.entries({...search, ...chainedSearch}).forEach(([key, value]) => {
                if (key in formMethods.getValues()) {
                    formMethods.setValue(key, value);
                } else {
                    formMethods.register(key, {value, shouldUnregister: true});
                }
            });
        }
    }

    const onAddSavedRequest = () => {
        const nameValue = name;
        const {customer: {id: customerId}} = getLSItem('user');
        setName("");
        const searchContent = clearUnusedFieldsOfSearch(formMethods.getValues());
        const optionalsBody = {};
        if (tab?.id) {
            optionalsBody.tabId = tab.id;
        }
        if (searchbar !== '') {
            searchContent.searchbar = searchbar;
        }

        fetchSaveSavedRequest({
            url: generateUrl(SAVED_REQUEST),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    customerId: customerId,
                    type: "section_filter",
                    name: nameValue,
                    entity: toCapitalize(entity),
                    content: JSON.stringify(searchContent),
                    ...optionalsBody
                })
            }
        });
    }

    useEffect(() => {
        if (data?.data) {
            const {id, name, entity, content} = data.data;
            setSavedRequests((prev) => [...prev, {id, name, entity, content}]);
        }
    }, [data]);

    const onDeleteSavedRequest = () => {
        const savedRequestId = savedRequests.find(s => s.content === selectedSavedRequest)?.id;
        fetchDeleteSavedRequest({
            url: generateUrl(SAVED_REQUEST_ITEM, {id: savedRequestId}),
            config: {
                method: 'DELETE'
            }
        });
        UserService.removeSavedSearch(savedRequestId);
        setSavedRequests((prevSavedRequests) => prevSavedRequests.filter(s => s.content !== selectedSavedRequest));
        setSelectedSavedRequest('');
    }

    const handleNameChange = ({currentTarget: {value}}) => setName(value);

    return {
        options,
        onSelectChange,
        onAddSavedRequest,
        onDeleteSavedRequest,
        selectedSavedRequest,
        isFormDirty,
        name,
        handleNameChange,
    }
}

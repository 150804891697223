import React, {useMemo, useEffect, useState, useCallback} from 'react';
import Table from "v4/components/ui/Table/Table";
import {Link} from "react-router-dom";
import {routesBase} from 'v4/data/appRoutes'
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import apiService from "v4/services/api.service";
import {
    SAVED_REQUEST_SHOW_LIST
} from "v4/data/apiRoutes";
import {PolIcon} from "components/PolIcon/policon";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import useNotification from "v4/hooks/useNotification";
import SavedRequestsListDelete
    from "v4/pages/admin/SavedRequests/SavedRequestsList/components/SavedRequestsListDelete/SavedRequestsListDelete";
export default function SavedRequestsList({customerId}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()

    // Listing
    const [{
        data: loadedSavedRequests,
        isLoading: loadedSavedRequestsLoading,
        isError: loadedSavedRequestsError
    }, fetchSavedRequests] = useFetch();

    const [refetch, setRefetch] = useState(0)

    useEffect(() => {
        fetchSavedRequests({
            url: apiService.generateUrl(SAVED_REQUEST_SHOW_LIST, {
                sortField: "name",
                sortOrder: "asc",
                customerId: customerId
            }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const onDeleteSuccess = useCallback(() => {
        addSuccessNotification(t('delete_saved_request_done'))
        setRefetch(c => c + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const onDeleteError = useCallback(() => {
        addErrorNotification(t('delete_saved_request_error'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hydrateSavedRequestRows = useCallback(() => {

        if (!loadedSavedRequests?.['hydra:member']) {
            return null
        }

        const rows = JSON.parse(JSON.stringify(loadedSavedRequests['hydra:member']))

        return (
            rows.map((item, index) => {
                return {
                    entity: item.entity,
                    type: item.type,
                    name: item.name,
                    actions: (
                        <ul className="datatable__actions">
                            <Link key={index} to={`${routesBase.adminSavedRequest}/edit/${item.id}`}>
                                <li className="datatable__actions__item">
                                    <span className="datatable__actions__item__icon">
                                        <PolIcon icon='edit'/>
                                    </span>
                                </li>
                            </Link>
                            <SavedRequestsListDelete item={item} onSuccessCallback={onDeleteSuccess}
                                                 onErrorCallBack={onDeleteError}/>
                        </ul>
                    )
                };
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedSavedRequests])

    const tableList = useMemo(() => {
        return <Table tableRows={hydrateSavedRequestRows()} isLoading={loadedSavedRequestsLoading}
                   isError={loadedSavedRequestsError}
                   theadData={Object.keys({entity: 'entity', type: 'type', name: 'name', actions: 'actions'})}/>
    }, [hydrateSavedRequestRows, loadedSavedRequestsLoading, loadedSavedRequestsError])

    const CardHeaderTools = () => (
        <Link to={`${routesBase.adminSavedRequest}/add`}
              className="btn btn-primary">{t('add_new_saved_request')}</Link>
    )

    return (
        <AdminCard title={t('manage_saved_requests')} CardHeaderTools={CardHeaderTools}>
            {tableList}
        </AdminCard>
    )
}

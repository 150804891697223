import React from 'react';

export const EnvHeader = () => {
    const env = process.env.REACT_APP_ENV;

    return (
        <>
            {env && env !== 'production' ? (
                <div>
                    <div className={'env-header'}>
                        {env}
                    </div>
                </div>
            ) : null}
        </>
    );
};


import React from 'react';
import QuotePdfVersionningButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuotesTabRowActions/QuotePdfVersionningButton/QuotePdfVersionningButton";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ReactTooltip from "react-tooltip";

export default function ProspectTabPdfversionningButton({quote}) {
    const {t} = usePolTranslation();
    const quoteInvoices = quote?.metadata?.quote_invoices ? JSON.parse(quote.metadata.quote_invoices) : {current: null, archives: []};

    const isDisabled = quoteInvoices.current === null;
    const hasDropdown = quoteInvoices.archives.length > 0;

    return (
        <>
            <div data-for="download_pdf_quote"
                 data-tip={t('download_pdf_quote')}
                 className={`prospect-tab__prospect__header__action quote-pdf-versionning ${isDisabled ? 'disabled' : ''} ${hasDropdown ? 'has-dropdown' : ''}`}>
                <QuotePdfVersionningButton quoteInvoices={quoteInvoices}/>
            </div>
            <ReactTooltip id="download_pdf_quote" effect="solid"/>
        </>
    )
}

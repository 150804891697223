import React from 'react';
import InputSelect from "./_partials/inputSelect";
import InputDefault from "./_partials/inputDefault";
import {InputRichTextEditor} from "./_partials/inputRichTextEditor";
import {InputDateCompare} from "./_partials/inputDateCompare";
import {InputNumberCompare} from "./_partials/InputNumberCompare";
import InputDisplayInfo from "./_partials/inputDisplayInfo";
import InputFile from "./_partials/inputFile";
import {usePolTranslation} from "../../v4/hooks/usePolTranslation";

/**
 * Composant input des formulaires
 * @param inputData
 * @param inputType
 * @param onChange
 * @param typeForm
 * @param setPopup
 * @param deleteService
 * @param updateInput
 * @param handleFilesSizeExceeded
 * @param setPopupIsOpen
 * @param setSelectIsFocused
 * @param onFileTypeWrong
 * @returns {*}
 * @constructor
 */
export const Input = ({
                          inputData,
                          inputType,
                          onChange,
                          typeForm,
                          setPopup,
                          deleteService,
                          updateInput,
                          handleFilesSizeExceeded,
                          setPopupIsOpen,
                          setSelectIsFocused,
                          onFileTypeWrong
                      }) => {
    const {t} = usePolTranslation();

    let inputToDisplay;

    if (inputData && inputData.attr && inputData.attr.type) {
        inputType = inputData.attr.type;
    }

    if (((inputData && inputData.enum && inputData.type === 'string') || (inputData.type === 'array'))) {

        switch (inputType) {
            case 'file' :
                inputToDisplay = <InputFile inputData={inputData} onChange={(value) => onChange(value)}
                                            handleFilesSizeExceeded={handleFilesSizeExceeded}
                                            updateInput={(attributes) => updateInput(attributes)} setPopup={setPopup}
                                            deleteService={deleteService}
                                            onFileTypeWrong={onFileTypeWrong}
                />;
                break;
            case 'display-fields' :
                inputToDisplay = <InputDisplayInfo inputData={inputData} onChange={(value) => onChange(value)}
                                                   updateInput={(attributes) => updateInput(attributes)}
                                                   setPopup={setPopup} deleteService={deleteService}/>;
                break;
            default:
                inputToDisplay =
                    <InputSelect inputData={inputData} type={inputData.type} onChange={(value) => onChange(value)}
                                 typeForm={typeForm} setSelectIsFocused={setSelectIsFocused}/>;
        }
    } else {
        switch (inputType) {
            case 'html':
                inputToDisplay = <InputRichTextEditor inputData={inputData} onChange={(value) => onChange(value)}
                                                      setPopup={setPopup}/>;
                break;
            case 'date_compare' :
                inputToDisplay = <InputDateCompare inputData={inputData} onChange={(value) => onChange(value)}
                                                   setPopup={setPopup} t={t} setPopupIsOpen={setPopupIsOpen}/>;
                break;
            case 'number_compare' :
                inputToDisplay = <InputNumberCompare inputData={inputData} onChange={(value) => onChange(value)}
                                                     setPopup={setPopup} t={t} setPopupIsOpen={setPopupIsOpen}/>;
                break;
            default :
                inputToDisplay = <InputDefault inputData={inputData} type={inputData.type}
                                               onChange={(value) => onChange(value)}
                />;
                break;
        }
    }

    return (
        <>
            {inputToDisplay}
        </>
    )
};

import React, {useEffect, useMemo} from 'react';
import Loader from "v4/components/ui/Loader/Loader";
import useFetch from "v4/hooks/useFetch";
import FormEntityLayoutForm from "v4/layouts/FormEntityLayout/components/FormEntityLayoutForm/FormEntityLayoutForm";
import {generateUrl} from "v4/services/api.service";

export default function FormEntityLayout({
                                             formRouteName,
                                             submitRouteName,
                                             onGetData,
                                             additionalButtons,
                                             whenSubmitted,
                                             onBack,
                                             fetchFormParameters,
                                             shouldWarnOnChangePage
                                         }) {
    const [{data, isLoading, isError}, fetchForm] = useFetch();
    const parameters = useMemo(() => Object.entries(fetchFormParameters ?? {}).reduce((acc, [key, value]) => Boolean(value) ? {...acc, [key]: value} : acc, {}), [fetchFormParameters]);

    useEffect(() => {
        fetchForm({
            url: Object.keys(parameters).length > 0
                ? generateUrl(formRouteName, parameters)
                : generateUrl(formRouteName),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRouteName, parameters]);

    const inputs = useMemo(() => {
        return data?.['hydra:member'] ?? null;
    }, [data])

    useEffect(() => {
        if (onGetData) {
            onGetData(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    return (
        <>
            {isLoading && <Loader className="form-entity-loader"/>}
            {isError && <div className="form__error">Une erreur est survenue</div>}
            {inputs && <FormEntityLayoutForm inputsGroups={inputs}
                                             fetchFormParameters={parameters}
                                             submitRouteName={submitRouteName}
                                             additionalButtons={additionalButtons}
                                             whenSubmitted={whenSubmitted}
                                             onBack={onBack}
                                             shouldWarnOnChangePage={shouldWarnOnChangePage}/>}
        </>
    )
}

import {captureException} from "@sentry/react";

export default function fetchReducer(state, action) {
    switch (action.type) {
        case 'FETCH_INIT': {
            return {
                ...state,
                isLoading: true,
                isError: false,
                isFinished: false,
                errorData: null,
                token: null,
            };
        }

        case 'FETCH_SUCCESS': {
            return {
                ...state,
                isLoading: false,
                isError: false,
                isFinished: true,
                data: action?.payload ?? null,
                errorData: null,
                ...(action.payload?.token && {token: action.payload.token}),
            };
        }

        case 'FETCH_FAILURE': {
            return {
                ...state,
                isLoading: false,
                isError: true,
                isFinished: true,
                data: null,
                ...(action.payload && {errorData: action.payload}),
            };
        }

        default:
            const err = new Error(`No action type ${action.type} found in fetchReducer`);
            captureException(err);
            throw err;
    }
};

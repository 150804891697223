import React, {useState} from 'react';
import AutocompleteContactSelect
    from "v4/components/form/Field/components/AutocompleteSelect/AutocompleteContactSelect";
import AutocompleteProductSelect
    from "v4/components/form/Field/components/AutocompleteSelect/AutocompleteProductSelect";
import AutocompleteProspectSelect
    from "v4/components/form/Field/components/AutocompleteSelect/AutocompleteProspectSelect";
import {selectStyle} from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {CONTACT, PRODUCT, PROSPECT, QUOTE} from "v4/data/entities";
import AutocompleteQuoteSelect from "v4/components/form/Field/components/AutocompleteSelect/AutocompleteQuoteSelect";

export default function AutocompleteSelect({
                                               name,
                                               value,
                                               entity,
                                               autocompleteAdditionnalSearchFilter,
                                               options,
                                               isMulti = false,
                                               placeholder,
                                               onChange,
                                               htmlConstraints,
                                               selectParams = {},
                                               impossibleValues,
                                           }) {
    const [allOptions, setAllOptions] = useState(Array.isArray(options) ? options : []);
    const {formInputs: {setAutoCompleteChoices} = {}} = useSearchContext() ?? {};

    const addOptions = (newOptions) => {
        if (!newOptions) return;

        setAllOptions(previousOptions => {
            if (isMulti) {
                const newOptionsValues = newOptions.map((item) => item.value);

                const options = previousOptions.filter((option) => !newOptionsValues.includes(option.value)).concat(newOptions);
                setAutoCompleteChoices && setAutoCompleteChoices(prev => ({...prev, [name]: options}));

                return options;
            } else {
                const options = previousOptions.filter((option) => option.value !== newOptions.value).concat(newOptions);
                setAutoCompleteChoices && setAutoCompleteChoices(prev => ({...prev, [name]: options}));

                return options;
            }
        });
    }

    const handleChange = (option) => {
        if (option) {
            onChange(isMulti ? option.map((item) => item.value) : option.value);
        } else {
            onChange(isMulti ? [] : '');
        }
    }

    switch (entity) {
        case PROSPECT:
            return (
                <AutocompleteProspectSelect name={name}
                                            value={value}
                                            autocompleteAdditionnalSearchFilter={autocompleteAdditionnalSearchFilter}
                                            handleChange={handleChange}
                                            addOptions={addOptions}
                                            htmlConstraints={htmlConstraints}
                                            options={allOptions}
                                            isMulti={isMulti}
                                            placeholder={placeholder}
                                            selectParams={selectParams}
                                            selectStyle={selectStyle}
                                            impossibleValues={impossibleValues}/>
            );
        case CONTACT:
            return (
                <AutocompleteContactSelect name={name}
                                           value={value}
                                           autocompleteAdditionnalSearchFilter={autocompleteAdditionnalSearchFilter}
                                           handleChange={handleChange}
                                           addOptions={addOptions}
                                           htmlConstraints={htmlConstraints}
                                           options={allOptions}
                                           isMulti={isMulti}
                                           placeholder={placeholder}
                                           selectParams={selectParams}
                                           selectStyle={selectStyle}
                                           impossibleValues={impossibleValues}/>
            );
        case QUOTE:
            return (
                <AutocompleteQuoteSelect name={name}
                                         value={value}
                                         autocompleteAdditionnalSearchFilter={autocompleteAdditionnalSearchFilter}
                                         handleChange={handleChange}
                                         addOptions={addOptions}
                                         htmlConstraints={htmlConstraints}
                                         options={allOptions}
                                         isMulti={isMulti}
                                         placeholder={placeholder}
                                         selectParams={selectParams}
                                         selectStyle={selectStyle}
                                         impossibleValues={impossibleValues}/>
            );
        case PRODUCT:
            return (
                <AutocompleteProductSelect name={name}
                                           value={value}
                                           autocompleteAdditionnalSearchFilter={autocompleteAdditionnalSearchFilter}
                                           handleChange={handleChange}
                                           addOptions={addOptions}
                                           htmlConstraints={htmlConstraints}
                                           options={allOptions}
                                           isMulti={isMulti}
                                           placeholder={placeholder}
                                           selectParams={selectParams}
                                           selectStyle={selectStyle}
                                           impossibleValues={impossibleValues}/>
            );
        default:
            return null;
    }
}

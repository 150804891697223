import React, {useEffect, useState} from 'react';
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import ViewOrdersField from "v4/pages/admin/ViewOrders/components/ViewOrdersField/ViewOrdersField";
import ViewOrdersGrid from "v4/pages/admin/ViewOrders/components/ViewOrdersGrid/ViewOrdersGrid";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ViewOrdersAvailableFields() {
    const {t} = usePolTranslation();
    const {currentViewOrder, isViewOrdersFormLoading, currentKeyForRefresh} = useViewOrdersContext();
    const [search, setSearch] = useState('');

    const defaultGroup = currentViewOrder?.state.find(({isDefault}) => isDefault);

    const handleSearch = ({currentTarget}) => {
        const {value} = currentTarget;
        setSearch(value);
    };

    useEffect(() => {
        if (!isViewOrdersFormLoading) return;

        setSearch('');
    }, [isViewOrdersFormLoading])

    if (!defaultGroup) return null;

    return (
        <div className="new-view-orders__available-fields">
            <h3>{t('import_fields_available')} :</h3>
            <div className="new-view-orders__available-fields__search">
                <input type="search" placeholder={t('search_field')} value={search} onChange={handleSearch}/>
            </div>

            <div className="new-view-orders__scrollable">
                {isViewOrdersFormLoading
                    ? <div>{t('loading')}</div>
                    : <ViewOrdersGrid key={currentKeyForRefresh}
                                      items={defaultGroup?.fields}
                                      dragType="field"
                                      canPutInGroup={false}
                                      DOMInfos={defaultGroup}
                                      renderItem={(field) => {
                                          if (search.length > 0 && !field.label.toLowerCase().includes(search.toLowerCase())) return null;

                                          return (
                                              <ViewOrdersField key={field.id}
                                                               group={defaultGroup}
                                                               field={field}
                                                               hasActions={false}/>
                                          )
                                      }}/>
                }
            </div>
        </div>
    )
}

import React from 'react';
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import AddConfigButton from "v4/pages/admin/AutomatedStatus/components/AddConfigButton/AddConfigButton";
import Loader from "v4/components/ui/Loader/Loader";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import AutomatedStatusListing
    from "v4/pages/admin/AutomatedStatus/components/AutomatedStatusListing/AutomatedStatusListing";
import Confirm from "v4/components/ui/Confirm/Confirm";
import useAutomatedStatus from "v4/pages/admin/AutomatedStatus/hooks/useAutomatedStatus";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import AutomatedStatusForm from "v4/pages/admin/AutomatedStatus/components/AutomatedStatusForm/AutomatedStatusForm";
import "./AutomatedStatusPage.scss";

export default function AutomatedStatusPage() {
    const {t} = usePolTranslation();
    const {
        automatedStatus,
        isLoading,
        isConfirmOpen,
        isPopupOpen,
        idToEdit,
        onClickAdd,
        onSubmit,
        onEdit,
        onDelete,
        handleDelete,
        onConfirmCancel,
        onPopupClose,
        submitErrors
    } = useAutomatedStatus();

    return (
        <AdminCard title={t('automated_status')}
                   CardHeaderTools={() => <AddConfigButton onClickAdd={onClickAdd}/>}>
            {isLoading && <Loader/>}
            {!isLoading && automatedStatus && (
                automatedStatus.length > 0
                    ? <AutomatedStatusListing
                        automatedStatus={automatedStatus}
                        onEdit={onEdit}
                        onDelete={onDelete}/>
                    : <p>{t('no_results')}</p>
            )}

            <PolPopup isOpen={isPopupOpen}
                      closePopup={onPopupClose}
                      title={t(idToEdit ? 'edit_configuration' : 'add_configuration')}>
                <AutomatedStatusForm id={idToEdit} onSubmit={onSubmit} submitErrors={submitErrors}/>
            </PolPopup>

            <Confirm isOpen={isConfirmOpen}
                     cancelAction={onConfirmCancel}
                     confirmAction={handleDelete}
                     title={t('confirm_delete')}/>
        </AdminCard>
    )
}

import useFetch from "v4/hooks/useFetch";
import {useEffect, useMemo, useState} from "react";
import {generateUrl} from "v4/services/api.service";
import {EXPORT_DOWNLOAD, EXPORT_LIST} from "v4/data/apiRoutes";
import {downloadBlob} from "v4/utils";
import useExportMercure from "v4/features/front/export/components/ExportPopup/hooks/useExportMercure";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function useExportPopup() {
    const [loadingExports, readyExportsMercure, problemExports] = useExportMercure();
    const [{data, errorData, isError}, fetchExports] = useFetch();
    const [readyExports, setReadyExports] = useState({});
    const [{data: downloadedExport, errorData: downloadErrorData, isError: isDownloadError}, fetchDownloadExport] = useFetch('blob');
    const {addErrorNotification} = useNotification();
    const {t} = usePolTranslation();

    useEffect(() => {
        fetchExports({url: generateUrl(EXPORT_LIST)});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isError) return addErrorNotification(t(errorData?.message ?? 'error'));

        if (Object.keys(data?.files ?? {}).length > 0) {
            const _readyExports = {};
            Object.entries(data.files).forEach(([entity, exports]) => {
                exports.forEach(({id, requestedAt}) => {
                    _readyExports[id] = {
                        id,
                        entity,
                        requestedAt,
                        finished: true,
                    }
                });
            });
            setReadyExports(prevState => ({...prevState, ..._readyExports}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, errorData, isError]);

    useEffect(() => {
        if (readyExportsMercure) {
            setReadyExports(prevState => ({...readyExportsMercure, ...prevState}));
        }
    }, [readyExportsMercure]);

    const exportDownload = (id) => () => {
        const entity = readyExports[id].entity;
        fetchDownloadExport({
            url: generateUrl(EXPORT_DOWNLOAD, {entity, id}),
            config: {
                method: 'POST',
            }
        });
    }

    useEffect(() => {
        if (isDownloadError) return addErrorNotification(t(downloadErrorData?.message ?? 'error'));

        if (downloadedExport) {
            downloadBlob(downloadedExport, 'pol-export.csv');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadedExport, downloadErrorData, isDownloadError]);

    const sortedLoadingExports = useMemo(() => Object.entries(loadingExports).sort(([idA, exportA], [idB, exportB]) => {
        const requestedAtA = new Date(exportA.requestedAt);
        const requestedAtB = new Date(exportB.requestedAt);
        return requestedAtB - requestedAtA;
    }).reduce((acc, [id, exportData]) => {
        acc[id] = exportData;
        return acc;
    }, {}), [loadingExports]);

    const sortedReadyExports = useMemo(() => Object.entries(readyExports).sort(([idA, exportA], [idB, exportB]) => {
        const requestedAtA = new Date(exportA.requestedAt);
        const requestedAtB = new Date(exportB.requestedAt);
        return requestedAtB - requestedAtA;
    }).reduce((acc, [id, exportData]) => {
        acc[id] = exportData;
        return acc;
    }, {}), [readyExports]);

    return {
        loadingExports: sortedLoadingExports,
        readyExports: sortedReadyExports,
        problemExports,
        exportDownload
    }
}


const stateDefault = {
};

const datatable = (state = stateDefault, action) => {
    switch (action.type) {
        case 'DISPATCH_SELECTED_ENTITIES_IDS' :
            switch(action.entity){
                case "Prospect":
                    return {
                        ...state,
                        prospectIds: action.entityIds
                    };
                case "Contact":
                    return {
                        ...state,
                        contactIds: action.entityIds
                    };
                case "Quote":
                    return {
                        ...state,
                        quoteIds: action.entityIds
                    };
                case "Task":
                    return {
                        ...state,
                        taskIds: action.entityIds
                    };
                default:
                    return state;
            }
        case 'RESET_CHAINED_SEARCH':
            return stateDefault;
        default:
            return state;
    }
};

export default datatable;

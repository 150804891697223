import React from 'react';
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import ProspectTasksTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTasksTabPartial/ProspectTasksTabPartial";
import ProspectOldMailTasksTabActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOldMailTasksTab/components/ProspectOldMailTasksTabActions/ProspectOldMailTasksTabActions";
import ProspectOldMailTasksTabRowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOldMailTasksTab/components/ProspectOldMailTasksTabRowActions/ProspectOldMailTasksTabRowActions";

const fetchParams = {
    isMailingTask: true,
}

export default function ProspectOldMailTasksTabListing({prospectId}) {
    return (
        <div className="prospect-tab__listing__wrapper">
            <ProspectHeadingTabPartial title="historique_mails" actions={(props) => <ProspectOldMailTasksTabActions prospectId={prospectId} {...props}/>}/>
            <ProspectTasksTabPartial fetchParams={fetchParams}
                                     prospectId={prospectId}
                                     rowActions={ProspectOldMailTasksTabRowActions}/>
        </div>
    )
}

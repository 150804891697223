import {PolIcon} from "../../PolIcon/policon";
import React, {useState} from "react";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export const Help = () => {
    const {t} = usePolTranslation();
    const [isDisplay, setDisplay] = useState(true);
    const classDesc = isDisplay ? 'open' : 'close';
    const iconDesc = isDisplay ? 'close' : 'question';

    return (
        <div className={`mapspage__popin help ${classDesc}`}>
            <div className={'close'} onClick={() => setDisplay(!isDisplay)}>
                <PolIcon icon={iconDesc} className={'close__icon'}/>
            </div>
            <ul className={'popin-toggle list'}>
                <li className={'list__item'}>
                    {t('geolocations_text_1')}
                </li>
                <li className={'list__item'}>
                    {t('geolocations_text_2')}
                </li>
            </ul>
        </div>
    )
};

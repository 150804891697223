import {PolIcon} from "components/PolIcon/policon";
import Logo from 'img/logo.svg';
import React from 'react';
import 'v4/pages/front/Maintenance/MaintenancePage.scss';
import {useTranslation} from "react-i18next";

export default function MaintenancePage() {
    const {t} = useTranslation();

    return (
        <div className="maintenance-page">
            <img className="background" src={Logo} alt="background"/>
            <div>
                <img className="logo" src={Logo} alt="logo"/>
                <h1>{t('maintenance')}</h1>
                <h2>{t('maintenance_planned')}</h2>
                <hr/>
                <p>{t('maintenance_message')}</p>
            </div>
            <div className="cogs">
                <PolIcon icon="cog" className="cog1"/>
                <PolIcon icon="cog" className="cog2"/>
                <PolIcon icon="cog" className="cog3"/>
            </div>
        </div>
    )
}

import populateField from "v4/pages/admin/ViewOrders/contexts/utils/populateField";
import appendNotExistingGroups from "v4/pages/admin/ViewOrders/contexts/utils/appendNotExistingGroups";

export default function populateViewOrder(viewOrder, fields) {
    if (!viewOrder || !fields) return null;
    const fieldsDatas = flattenFields(fields);

    const populatedViewOrder = viewOrder.map(group => {
        const {fields: groupFields} = group;

        group.fields = groupFields
            .sort((a, b) => a.order - b.order) // Sort the fields by order
            .map(field => {
                // Return the field data if it exists
                const fieldDataIndex = fieldsDatas.findIndex(({key, fixedKey}) => [key, fixedKey].includes(field.key));

                const fieldData = fieldsDatas[fieldDataIndex] ?? null;

                return populateField(field, fieldData);
            }).filter(Boolean);

        return group;
    });

    populatedViewOrder.push(...appendNotExistingGroups(populatedViewOrder, fieldsDatas));

    return populatedViewOrder;
}

function flattenFields(fields, previousKey, depth = 0) {
    return Object.entries(fields).reduce((acc, [fieldKey, field]) => {
        const keyPrefix = previousKey ? `${previousKey}_` : ''; // Keep track of the recursive key
        const key = `${keyPrefix}${fieldKey}`;
        let fixedKey = key;

        // If the depth is greater than 1 (more than 2 keys), we keep only the last two parts of the key
        if (depth > 1) {
            fixedKey = key.split('_').slice(-2).join('_');
        }

        // If the field has properties, we call the function recursively
        if (!field.attr?.type?.includes('compare') && field.properties) {
            return [...acc, ...flattenFields(field.properties, key, depth + 1)];
        }

        return [...acc, {...field, key, fixedKey}]; // Add the key to the field
    }, []);
}

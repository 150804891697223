import React, {useEffect, useState} from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "../../../../components/PolIcon/policon";
import useFetch from "../../../../v4/hooks/useFetch";
import InputDefault from "../../../../components/Input/_partials/inputDefault";
import InputSelect from "../../../../components/Input/_partials/inputSelect";
import {Redirect} from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";

/**
 * Sections form
 * @param {string} path
 * @param goBack
 * @param {string} pathSubmit
 * @param submitMethod
 * @param {string} sectionId
 * @param {string} customerId
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export default function SectionsForm({path, goBack, pathSubmit, submitMethod, sectionId, customerId}) {
    const [redirect, setRedirect] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const {t} = usePolTranslation();
    const [{data: dataGet, isLoading: isLoadingGet}, fetchHookGet] = useFetch();
    const [{data: dataSubmit, isError, isLoading: isLoadingSubmit}, fetchHookSubmit] = useFetch();
    const [inputs, setInputs] = useState(null);

    useEffect(() => {
        fetchHookGet({
            url: path,
            config: {
                method: 'GET',
            }
        })
    }, [fetchHookGet, path]);

    useEffect(() => {
        if(dataGet?.schema['hydra:member'] && dataGet.schema['hydra:member'][2]) {
            setInputs(dataGet.schema['hydra:member'][2]);
        }
    }, [dataGet]);

    useEffect(() => {
        if (dataSubmit && dataSubmit.data && !isError) {
            if (200 === dataSubmit.code) {
                setRedirect(
                    <Redirect to={`/administration/sections`}/>
                );
            } else if (500 === dataSubmit.code && dataSubmit.data && dataSubmit.data.children) {
                let newInputs = inputs;
                Object.keys(dataSubmit.data.children).map((field) => {
                    newInputs[field].error = dataSubmit.data.children[field].errors ? dataSubmit.data.children[field].errors[0] : null;
                    return field;
                })
                setInputs(newInputs);
                setRefresh(!refresh);
            }
        }
        // les dépendances manquantes bloquent le formulaire en cas d'erreur
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSubmit]);

    useEffect(() => {
    }, [refresh]);

    /**
     * handle form input change
     * @param {(number|string)} key
     * @param {string|null} value
     */
    const handleChange = (key, value) => {
        if(key) {
            setInputs({
                ...inputs,
                [key]: {
                    ...inputs[key],
                    value: value
                }
            })
        }
    }

    /**
     * Submit form
     */
    const submit = () => {
        let data = {};
        Object.keys(inputs).map((input, key) => {
            data[input] = inputs[input].value;

            return input;
        })

        data.customerId = customerId;
        if('0' !== sectionId) {
            data.id = sectionId;
        }

        data.leaders = data.leaders?.filter(a => a.value)?.map(a => a.value);
        data.members = data.members?.filter(a => a.value)?.map(a => a.value);
        data.sectionSavedRequests = data.sectionSavedRequests?.filter(a => a.value)?.map(a => a.value);

        fetchHookSubmit({
            url: pathSubmit,
            config: {
                method: submitMethod,
                body: JSON.stringify(data)
            }
        });
    }

    /**
     * get appropriate input element
     * @param {Object} input
     * @param {string} input.type
     * @param {string} input.value
     * @param {array} [input.enum]
     * @param {(number|string)} key
     * @returns {JSX.Element}
     */
    const getInputFor = (input, key) => {
        if(input.enum) {
            return <InputSelect inputData={input} type={input.type} onChange={(value) => handleChange(key, value)}
                                setSelectIsFocused={handleChange}
            />;
        }

        if (input.type === 'boolean') {
            return (
                <div>
                    <input type="checkbox"
                           className={`field__input field__input--checkbox ${inputs[key].value ? 'field__input--checkbox--checked' : ''}`}
                           name={key}
                           id={key}
                           checked={inputs[key].value}
                           onClick={({currentTarget: {checked}}) => handleChange(key, checked)}/>
                    <label htmlFor={key} className={`field__label--checkbox ${inputs[key].value ? 'field__label--checkbox--checked' : ''}`}>
                        {t(input.title)}
                    </label>
                </div>
            );
        }

        if (input.type === 'array') {
            return (
                <div>
                    <InputSelect inputData={input} type={input.type} onChange={(values) => handleChange(key, values)} useNewCheckValue={true} />
                </div>
            );
        }

        return <InputDefault t={t} inputData={input} type={input.type}
                             onChange={(value) => handleChange(key, value)}
        />;
    }

    return (
        <>
            {redirect}
            {(isLoadingGet || isLoadingSubmit) && <Loading message={t("loading")} specificClass={"form-loader"}/>}
            <div>
                {inputs &&
                    <form className={`form form__scrollable form__edit--view prospect`}>
                        <fieldset className={`group__content`}>
                            <div className={'row row-fluid'}>
                                <div className={`group__container group__row row`}>
                                    {
                                        Object.keys(inputs).map((input, key) => {
                                            let fieldClass = inputs[input] && inputs[input].value ? 'field-written' : '';
                                            fieldClass += inputs[input] && inputs[input].error ? ' field-error' : '';
                                            return (
                                                <div key={key}
                                                     className={`col-12 col-md-3 field ${fieldClass}`}>
                                                    {getInputFor(inputs[input], input)}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </fieldset>
                    </form>
                }
                <div className={`col-12 col-md-auto`}>
                    <button className={'col-12 col-md-auto form__back'} onClick={goBack}>
                        <PolIcon icon={'chevron-circle-left'} className={'form__back__icon'}/>
                        {t('retour')}
                    </button>
                </div>
                <div className={`col-12 col-md-auto form__submit`}>
                    <button className={'btn btn-primary form__submit__btn'} onClick={submit}>
                        {t('enregistrer')}
                    </button>
                </div>
            </div>
        </>
    )
}

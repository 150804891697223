import React from 'react';
import {Document} from "./document";
import PopupInfoConfirm from "../Popup/_popup_info_confirm";
import {usePolTranslation} from "../../v4/hooks/usePolTranslation";

export const DocumentList = ({files, updateView = false, updateInput = false, groupKey, fieldKey,
                                 deleteService, setPopup, doNotDeleteFiles, removePdfFile}) => {
    const {t} = usePolTranslation();

    const removeFile = (key, deleteLink) => {
        const filesUpdatedName = [];
        const filesUpdatedDownloadLink = [];
        const filesUpdatedDeleteLink = [];
        setPopup({
            isOpen: true,
            content: (
                <PopupInfoConfirm
                    message={t('confirm_delete')}
                    onAccept={() => {
                        setPopup({});
                        files.splice(key, 1);
                        if(!doNotDeleteFiles) {
                            deleteService({absoluteDeleteUrl: deleteLink})
                        }
                        //Suppression des filtres dans le formulaire d'édition
                        if (typeof updateInput === 'function') {
                            files.forEach((filesIndex) => {
                                filesUpdatedName.push(filesIndex.fileLabel)
                                filesUpdatedDownloadLink.push(filesIndex.downloadLink)
                                filesUpdatedDeleteLink.push(filesIndex.deleteLink)
                            })
                            updateInput({
                                fileNames: filesUpdatedName.join(';'),
                                downloadUrls: filesUpdatedDownloadLink.join(';'),
                                deleteUrls: filesUpdatedDeleteLink.join(';')
                            })
                        }
                        //Suppression des filtres dans la view
                        else {
                            updateView(groupKey, fieldKey, files)
                        }
                        if (typeof removePdfFile === 'function') {
                            removePdfFile();
                        }
                    }}
                    onDecline={() => {
                        setPopup({})
                    }}
                />
            )
        })
    }

    return (
        <>
            <div className={'row filepond__container'}>
                {files && files.length > 0 ? (
                    files.map((item, key) =>
                        <div className={'col-md-4 col-6'} key={key}>
                            <Document doc={item} removeFile={removeFile} index={key}/>
                        </div>
                    )
                ) : null}
            </div>
        </>
    )
}

import React, {Component} from 'react'
import {connect} from 'react-redux'
import List from "../../components/Page/List";
import {setExpandInfo} from "../../actions/content";
import {getApiUrl} from "../../routes";
import {GROUPS} from "../Administration/pages/orders";

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            info: {
                name: 'contacts',
                id: 'table',
                type: 'datas',
                url: getApiUrl('contacts', 'list'),
                urlId: getApiUrl('contacts', 'id'),
                loader: 'table-contact',
                route: 'contacts',
                order_type: 'list',
                order_entity: 'Contact',
                subType: props.subType ?? GROUPS['contact'],
                prospectIds: props.location && props.location.state ? props.location.state.prospectIds : [],
                taskIds: props.location && props.location.state ? props.location.state.taskIds : [],
                quoteIds: props.location && props.location.state ? props.location.state.quoteIds : [],
                isReverse: props.location && props.location.state ? props.location.state.isReverse : false,
                additionalSearch: props.customTabSearch
            }
        }
    }

    componentDidMount() {
        const {setExpandInfo} = this.props;
        setExpandInfo(null);
    }

    render() {
        const {info} = this.state;
        const {title, customTabSearch} = this.props;

        return (
            <List
                info={info} className={'contacts'}
                title={title ?? 'liste_contacts'}
                customTabSearch={customTabSearch}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    state
});

const mapDispatchToProps = dispatch => {
    return {
        setExpandInfo: (expandinfo) => dispatch(setExpandInfo(expandinfo)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(Contact));

import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import React, {useEffect, useRef, useState} from 'react';
import {FilePond, registerPlugin} from "react-filepond";
import DocumentsHolder from "v4/components/form/Field/components/InputFile/components/DocumentsHolder/DocumentsHolder";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

export default React.forwardRef(function InputFile({
                                                       onChange,
                                                       fieldName,
                                                       fieldValue,
                                                       multiple,
                                                       properties,
                                                       htmlConstraints,
                                                       register
                                                   }, ref) {
    const {t} = usePolTranslation();
    const [files, setFiles] = useState([]);
    const [filesFromServer, setFilesFromServer] = useState({});
    const [revalidate, setRevalidate] = useState(false);
    const wrapperRef = useRef(null);

    const FileConstraints = properties?.customerFile?.constraints?.File;

    const constraints = {
        ...htmlConstraints,
        maxFileSize: FileConstraints?.maxSize ? FileConstraints.maxSize + 'B' : process.env.REACT_APP_MAX_FILES_SIZE_MB_UPLOAD + 'MB',
        maxTotalFileSize: FileConstraints?.maxSize ? FileConstraints.maxSize + 'B' : process.env.REACT_APP_MAX_FILES_SIZE_MB_UPLOAD + 'MB',
        allowMultiple: multiple,
        acceptedFileTypes: FileConstraints?.mimeTypes ?? ['application/pdf'],
    }

    useEffect(() => {
        if (fieldValue?.realFileName || fieldValue?.[0]?.realFileName) {
            setFilesFromServer(fieldValue);
        }
    }, [fieldValue])

    useEffect(() => {
        onChange(
            multiple
                ? {files: files?.map(file => {
                    file.file.error = file.getMetadata('error');
                    return file.file
                }) ?? []}
                : {file: files?.[0]?.file ?? null}
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    return (
        <>
            {/* Adding wrapper to force height when Filepond is removed from DOM */}
            <div ref={wrapperRef}>
                {revalidate
                    /* To force revalidation Filepond is removed from DOM */
                    ? setTimeout(() => setRevalidate(false), 0) && null
                    : (
                        <FilePond className="filepond-v4"
                                  ref={ref}
                                  files={files}
                                  onupdatefiles={setFiles}
                                  onaddfile={(error, file) => {
                                      if (!error || !file) return;
                                      file.setMetadata('error', error.main);
                                  }}
                                  beforeRemoveFile={() => {
                                      // Force keeping height of the wrapper without transition
                                      wrapperRef.current.style.transition = '';
                                      wrapperRef.current.style.minHeight = wrapperRef.current.offsetHeight + 'px';
                                  }}
                                  onremovefile={(error) => {
                                      setRevalidate(true)
                                  }}
                                  oninit={() => {
                                      // Remove height of the wrapper with transition
                                      wrapperRef.current.style.transition = 'min-height 0.2s ease-in-out';
                                      wrapperRef.current.style.minHeight = '0';
                                  }}
                                  allowFileSizeValidation={true}
                                  {...constraints}
                                  labelMaxTotalFileSizeExceeded={t('maximum_size_exceeded_label')}
                                  labelMaxTotalFileSize={t('maximum_size_label')}
                                  labelMaxFileSizeExceeded={t('maximum_size_exceeded_label')}
                                  labelMaxFileSize={t('maximum_size_label')}
                                  labelIdle={t('add_files')}
                                  labelFileTypeNotAllowed={t('filepond_wrong_filetype')}
                                  fileValidateTypeLabelExpectedTypes={t('filepond_expect_filetype')}
                        />
                    )}
            </div>
            {
                Object.keys(filesFromServer).length !== 0 && (
                    <DocumentsHolder multiple={multiple} filesFromServer={filesFromServer} fieldName={fieldName}
                                     shouldDeleteType={properties.shouldDelete.type} register={register}/>
                )}
        </>
    )
})

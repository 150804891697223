import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import ShowField from "v4/components/form/ShowField/ShowField";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import QuoteLineDescriptionEditor
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuoteLineDescription/components/QuoteLineDescriptionEditor/QuoteLineDescriptionEditor";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import './QuoteLineDescription.scss';

export default function QuoteLineDescription({isShow, name, field}) {
    const {t} = usePolTranslation();
    const [showPopup, togglePopup] = useToggle();

    return (
        <>
            <button type="button"
                    className="quote-line-description-btn btn btn-primary d-flex align-items-center justify-content-center align-self-start"
                    onClick={togglePopup}>
                <PolIcon className="mr-2" icon={isShow ? 'eye' : 'edit'}/>
                <span>{t(isShow ? 'product_description' : 'edit_description')}</span>
            </button>
            <PolPopup isOpen={showPopup}
                      closePopup={togglePopup}
                      sticked={{header: true}}
                      title={t(isShow ? 'product_description' : 'edit_description')}>
                {isShow
                    ? <ShowField type="text-rich-editor"
                                 value={field.description ?? ''}/>
                    : <QuoteLineDescriptionEditor name={name} togglePopup={togglePopup}/>
                }
            </PolPopup>
        </>
    )
}

import React from 'react';
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "components/PolIcon/policon";

export default function ViewOrdersConfigButtonConditionalField({group, field, config}) {
    const {t} = usePolTranslation();
    const {
        currentViewOrder: {formFields: {[field.conditionField ?? 'taskType']: formField}},
        setFieldConfig
    } = useViewOrdersContext();
    // For now, we specifically use the taskType from the formFields but in the future, we will have a more generic way to get the list
    const onClick = (taskTypeEnumIndex) => (e) => {
        e.stopPropagation();
        const isRemove = field.conditionValue === formField.enum?.[taskTypeEnumIndex];

        setFieldConfig(group.id, field.id, {
            ...config,
            conditionField: isRemove ? null : (field.conditionField ?? 'taskType'),
            conditionValue: isRemove ? null : formField.enum?.[taskTypeEnumIndex]
        });
    }

    return (
        <ul className="vo-action__config__item__submenu">
            {formField?.enum_titles?.map((title, index) => {
                const isActive = field.conditionValue === formField.enum?.[index];
                return (
                    <li className="vo-action__config__item__submenu__item"
                        key={index}
                        onClick={onClick(index)}>
                        {isActive ? <PolIcon icon="validation"/> : <span/>} {/* Empty span to keep the same width */}
                        {t(title)}
                    </li>
                )
            })}
        </ul>
    )
}

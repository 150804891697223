import React from 'react';
import useAutocomplete from "v4/components/form/Field/components/InputText/hooks/useAutocomplete";

export default function InputText({
                                      fieldName,
                                      fieldValue,
                                      prefix,
                                      placeholder,
                                      error,
                                      attr,
                                      inputConstraints,
                                      onChange
                                  }) {

    const {
        'data-autocomplete': autocompleteUrl,
        'data-field': dataField,
        'data-prevent-unknown': shouldPreventUnknown,
        'data-autocomplete-values': autocompleteValues,
        'data-min-length': minLength = 0,
        'data-target': target,
        'data-target-field': targetField,
        ...restAttr
    } = attr ?? {};

    const autocompleteList = useAutocomplete(autocompleteUrl, dataField, fieldValue, minLength, autocompleteValues, target, targetField);

    const onBlur = shouldPreventUnknown
        ? ({currentTarget: {value}}) => {
            if (!autocompleteList.includes(value)) {
                onChange('');
            }
        }
        : undefined;

    const handleChange = ({currentTarget: {value}}) => {
        onChange(value);
    }

    return (
        <>
            {autocompleteList?.length > 0 && (
                <datalist id={prefix + fieldName}>
                    {autocompleteList.map((item, index) => (
                        <option key={index} value={item}/>
                    ))}
                </datalist>
            )}
            <input className={`input-field${error ? ' has-error' : ''}`}
                   type="text"
                   id={prefix + fieldName}
                   value={fieldValue}
                   placeholder={placeholder}
                   list={prefix + fieldName}
                   data-is-required={!!inputConstraints?.required}
                   {...restAttr}
                   onChange={handleChange}
                   onBlur={onBlur}/>
        </>
    )
}

import React from 'react';
import Loader from "v4/components/ui/Loader/Loader";

import 'v4/components/ui/Table/components/TableTbody/TableTbody.scss'
import {createPortal} from "react-dom";

export default React.memo(function TableTbody({tableRows, isLoading, isError}) {
    return (
        <tbody className={(tableRows?.length === 0 || tableRows === null) ? 'empty' : null}>
        {isLoading && (
            createPortal(
                <div className="listing-table-tbody__loader">
                    <Loader message="Chargement..."/>
                </div>
                , document.querySelector('.ui-table-wrapper'))
        )}
        {
            tableRows?.length > 0
                ? !isError && tableRows.map((row, index) => (
                <tr key={row?.id ?? index}>
                    {
                        Object.keys(tableRows[0]).map(key => (
                            <td key={key}>
                                {row[key]}
                            </td>
                        ))
                    }
                </tr>
            ))
                : !isLoading && !isError && (
                <tr>
                    <td colSpan="9999">Aucune donnée à afficher</td>
                </tr>
            )
        }
        {isError &&
            <tr>
                <td id="table-error" colSpan="9999">Une erreur est survenue</td>
            </tr>
        }
        </tbody>
    )
})

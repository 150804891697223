import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect, useState} from 'react';

export default function Notice({type, message, show, delay}) {

    const [showNotice, setShowNotice] = useState(show)
    const [hideDelay, setHideDelay] = useState(delay)

    // Init default delay if not specified
    if (!hideDelay) {
        setHideDelay(3)
    }

    useEffect(() => {
        setShowNotice(show);
    }, [show])

    useEffect(() => {
        setHideDelay(delay);
    }, [delay])

    useEffect(() => {
        if (showNotice) {
            if (hideDelay > 0) {
                setTimeout(function () {
                    setShowNotice(false)
                }, hideDelay * 1000)
            }
        }
    }, [showNotice, hideDelay])

    return (
        <>
            {showNotice &&
                <div className={'alertNotice alertNotice--' + type}>
                    <PolIcon icon={'close'} className="alertNotice__close" onClick={() => setShowNotice(false)}/>
                    {typeof message === 'string'
                        ? <p>{message}</p>
                        : message
                    }
                </div>
            }
        </>
    )

}

const stateDefault = {error: null};

const datas = (state = stateDefault, action) => {
    switch (action.type) {
        case 'REMOVE_ERROR':
            return {...state, error: false};
        case 'ERROR' :
            return {...state, error: action.error};
        case 'APP_ERROR' :
            return {...state, error: action.errorMessage};
        case 'SET_DATA':
            const currentData = state[action.title];
            if (currentData && currentData[action.dataType]) {
                return {
                    ...state,
                    [action.title]: {
                        ...state[action.title],
                        [action.dataType]: {
                            ...currentData[action.dataType],
                            ...action.datas
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    [action.title]: {
                        ...state[action.title],
                        [action.dataType]: {
                            ...action.datas
                        }
                    }
                };
            }
        case 'UPDATE_LIST':
            const {entity, idProspect, id} = action;
            if (state[entity]) {
                delete state[entity]['table'];
                if(id !== 'datas') {
                    delete state[entity][idProspect][id];
                }
            }
            return state;
        case 'RESET_STORE_DATAS' :
            const {disableCache} = action
            return {...stateDefault, disableCache};
        case 'SET_CONTRACT_NUMBER' :

            const stateUpdated = {
                ...state
            };
            stateUpdated.prospects[action.id][action.typeP].contractNumber = action.contractNumber

            return stateUpdated;
        case 'REFRESH_BIG_LIST' :
            const newBigListState = {
                ...state
            };

            if(action && action.name && newBigListState[action.name]){
                delete newBigListState[action.name].table
                delete newBigListState[action.name].tableSearch
                newBigListState[action.name] = {
                    ...newBigListState[action.name],
                    reload: true
                }
            }

            return newBigListState;
        case 'RESET_SUBRESOURCE_STORE_DATAS' :
            const newState = {
                ...state
            };

            if(state.prospects){
                const keys = Object.keys(state.prospects);
                const keysToKeep = ['field', 'filters', 'order', 'page', 'search'];
                keys.forEach(key => {
                    if(keysToKeep.indexOf(key) === -1) {
                        newState.prospects[key] = {
                            datas: state.prospects[key].datas
                        }
                    } else {
                        newState.prospects[key] = state.prospects[key]
                    }
                })
            }

            return newState;
        case 'SET_DATA_ID':
            return {...state, newProspectId: action.id, isSubmitAndCreate: action.isSubmitAndCreate}
        default:
            return state;
    }
};

export default datas;

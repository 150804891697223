import {useHistory} from "react-router-dom";
import {useFrontContext} from "v4/contexts/FrontContext";

export default function useFrontRoute() {
    const {setRouteKey} = useFrontContext();
    const {replace, location: {pathname}} = useHistory();

    const refreshRoute = () => {
        setRouteKey(Math.random())
        replace(pathname)
    }

    return ({refreshRoute})
}

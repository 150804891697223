import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import ProspectQuotesReoffer
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesReoffer/ProspectQuotesReoffer";
import useToggle from "v4/hooks/useToggle";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ReactTooltip from "react-tooltip";

export default function ProspectTabReOfferButton({showId}) {
    const {t} = usePolTranslation();
    const [isReofferPopupOpen, toggleIsReofferPopupOpen] = useToggle();

    return (
        <>
            <div className="prospect-tab__prospect__header__action"
                 data-for="refresh_quote"
                 data-tip={t('refresh_quote')}
                 onClick={toggleIsReofferPopupOpen}>
                <PolIcon icon='refresh'/>
            </div>
            <ProspectQuotesReoffer rowIdToReoffer={showId}
                                   isReofferPopupOpen={isReofferPopupOpen}
                                   closePopup={toggleIsReofferPopupOpen}/>
            <ReactTooltip id="refresh_quote" effect="solid"/>
        </>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import CountUp from "react-countup";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import DashboardTasksListingTable
    from "v4/pages/front/Dashboard/components/DashboardTasksListing/components/DashboardTasksListingTable/DashboardTasksListingTable";
import useDashboardCounters from "v4/pages/front/Dashboard/hooks/useDashboardCounters";
import useTabToggle from "v4/pages/front/Dashboard/hooks/useTabToggle";
import {getVarsCounter} from "v4/utils";

const DAILY_TASKS_TAB_KEY = 'daily-tasks';
const OPENED_TASKS_TAB_KEY = 'opened-tasks';

const tabs = [
    DAILY_TASKS_TAB_KEY,
    OPENED_TASKS_TAB_KEY,
];

const tabIcons = {
    [DAILY_TASKS_TAB_KEY]: 'tasks',
    [OPENED_TASKS_TAB_KEY]: 'tasks',
}

export default function DashboardTasksListing() {
    const {t} = usePolTranslation();
    const [activeTabKey, setActiveTabKey] = useTabToggle(DAILY_TASKS_TAB_KEY);
    const [counters, setCounter] = useDashboardCounters(tabs);

    return (
        <div className="dashboard-listing tasks">
            <div className="dashboard-listing__counters">
                {tabs.map((tabKey, nth) => (
                    <div key={tabKey}
                         className="dashboard-listing__counter"
                         style={getVarsCounter(nth)}>
                        <CountUp className="dashboard-listing__counter__number"
                                 start={0}
                                 end={counters?.[tabKey]?.totalItems}
                                 duration={1}/>

                        <div className="dashboard-listing__counter__name">
                            {t(tabKey)}
                        </div>
                        <PolIcon icon={tabIcons?.[tabKey] ?? 'circle-notch'} className="dashboard-listing__counter__icon"/>
                    </div>
                ))}
            </div>
            <div className="dashboard-listing__tabs">
                {tabs.map((tabKey, nth) => (
                    <div key={tabKey}
                         className={`dashboard-listing__tab ${activeTabKey === tabKey ? 'active' : ''}`}
                         onClick={setActiveTabKey(tabKey)}
                         style={getVarsCounter(nth)}>
                        <span>{t(tabKey)}</span>
                    </div>
                ))}
            </div>
            <div className="dashboard-listing__listing">
                {tabs.map((tabKey, nth) => (
                    <SearchProvider key={tabKey} pageType={tabKey}>
                        <DashboardTasksListingTable tabKey={tabKey}
                                                    activeTabKey={activeTabKey}
                                                    setCounter={setCounter}
                                                    styleVars={getVarsCounter(nth)}/>
                    </SearchProvider>
                ))}
            </div>
        </div>
    )
}

import {setLoader} from "../../actions/content";
import {errorAjaxHandler, fetchHandler, manageError} from "../datas/datas";
import {resetStoreDatas} from "../../actions/datas";
import {resetDatatableAfterDuplicate} from "../../actions/prospects";

export function postForm(info) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');

        dispatch(setLoader({
            [info.loader]: true
        }));

        return fetch(process.env.REACT_APP_HOST_API + info.url, {
            method: info.method,
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
            body: JSON.stringify(info.data)
        }).then(
            fetchHandler,
            error => {
                dispatch(manageError(true, info))
            }
        ).then(
            data => {
                if (data && data.code === 200 && !info.noReset) {
                    dispatch(resetStoreDatas());
                    dispatch(setLoader({
                        [info.loader]: false
                    }));
                    dispatch(resetDatatableAfterDuplicate());
                    if (typeof info.callback === 'function' && data.data && data.data.id) {
                        info.callback(data.data.id);
                    }
                } else if (data) {
                    dispatch(setLoader({
                        [info.loader]: false
                    }));
                } else {
                    dispatch(manageError(dispatch, 500, info));
                }
                if (info.callback) {
                    info.callback();
                }
            },
            error => {
                errorAjaxHandler(dispatch, info, error);
                if (info.callback) {
                    info.callback(true);
                }
            }
        );
    };
}


import React, {useEffect, useMemo} from 'react';
import {useHistory} from "react-router-dom";
import QuoteStateForm
    from "v4/features/front/configuredActions/components/ConfiguredActionButton/components/QuoteStateForm/QuoteStateForm";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import useToggle from "v4/hooks/useToggle";
import ManageTasks
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/components/ManageTasks/ManageTasks";
import QuoteTasksNewButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/components/QuoteTasksNewButton/QuoteTasksNewButton";

export default function useConfiguredActionButton(action, entityId, prospectId) {
    const {action: actionUrl, component} = action;

    const [isOpen, toggleOpen] = useToggle();
    const [{data, isFinished}, fetchAction] = useFetch();

    const handleAction = () => {
        if (actionUrl.length > 0) {
            fetchAction({
                url: actionUrl,
                config: {
                    method: 'POST',
                    body: JSON.stringify({id: entityId})
                }
            });
        }
        toggleOpen();
    }

    const {addNotification} = useNotification();
    const {replace} = useHistory();

    const handleReturn = (data) => {
        if (data?.messages) {
            data.messages.forEach((message) => {
                addNotification({type: message.type, message: message.message});
            });
        }
        if (data?.effects) {
            applyEffects(data.effects, {redirectFunc: replace});
        }
    }

    useEffect(() => {
        if (isFinished) {
            handleReturn(data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isFinished])

    const components = useMemo(() => getComponents(component, {
        entityId,
        prospectId,
        toggleOpen,
        actionUrl,
    }), [component, actionUrl, entityId, prospectId, toggleOpen]);

    return [
        isOpen,
        toggleOpen,
        handleAction,
        components
    ];
}

function getComponents(componentInfos, additionalProps = {}) {
    if (!componentInfos) return null;

    const {name: componentName, params: props} = componentInfos;
    switch (componentName) {
        case 'QuoteStateForm':
            return {
                children: <QuoteStateForm componentInfos={componentInfos} {...props} {...additionalProps}/>
            }
        case 'ManageRecalls': // [PV-1095] All recalls are now reminders, it's just in case configurations are not updated
        case 'ManageReminders': // [PV-1162] All reminders are now for all tasks, it's just in case
        case 'ManageTasks': {
            const {entityId, prospectId} = additionalProps;

            return {
                children: <ManageTasks quoteId={entityId}/>,
                buttons: <QuoteTasksNewButton quoteId={entityId}
                                              prospectId={prospectId}
                                              closePopup={additionalProps.toggleOpen}/>
            }
        }
        default:
            return null;
    }
}


/**
 * @param effects
 * @param {{
 *     redirectFunc?: useHistory['replace'|'push']
 * }} [dependencies]
 */
export function applyEffects(effects, dependencies = {}) {
    const {redirectFunc} = dependencies;

    effects.forEach((effect) => {
        if (effect.needsRefreshList) {
            redirectFunc && redirectFunc(({pathname}) => pathname, {hasToRefresh: true});
        }
        if (effect.redirectTo) {
            redirectFunc && redirectFunc(effect.redirectTo);
        }
    });
}

import {getLSItem} from "v4/services/localStorage.service";
import useMercure from "v4/hooks/useMercure";
import {useEffect, useState} from "react";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

const STUCK_TIMEOUT = 30000;
const ERROR_TIMEOUT = 60000;

export default function useExportMercure() {
    const {t} = usePolTranslation();
    const {addSuccessNotification} = useNotification();
    const [loadingExports, setLoadingExports] = useState({});
    const [readyExports, setReadyExports] = useState({});
    const [problemExports, setProblemExports] = useState({stucked: [], error: []});
    const [timeouts, setTimeouts] = useState({});
    const user = getLSItem('user');
    const mercureData = useMercure({
        topic: `${user.customer.id}_${user?.id}_export`,
    })

    useEffect(() => {
        if (!mercureData) return;

        setLoadingExports(previousLoadingExports => {
            const newLoadingExports = {
                ...previousLoadingExports,
                [mercureData.id]: {...mercureData}
            };

            if (timeouts[mercureData.id]) {
                clearTimeout(timeouts[mercureData.id].stuckTimeout);
                clearTimeout(timeouts[mercureData.id].errorTimeout);
            }

            if (problemExports.stucked.includes(mercureData.id) || problemExports.error.includes(mercureData.id)) {
                setProblemExports(prev => ({
                    stucked: prev.stucked.filter(id => id !== mercureData.id),
                    error: prev.error.filter(id => id !== mercureData.id),
                }));
            }

            const stuckTimeout = setTimeout(() => {
                setProblemExports(prev => ({
                    ...prev,
                    stucked: [...prev.stucked, mercureData.id]
                }));
            }, STUCK_TIMEOUT);

            const errorTimeout = setTimeout(() => {
                setProblemExports(prev => ({
                    stucked: prev.stucked.filter(id => id !== mercureData.id),
                    error: [...prev.error, mercureData.id]
                }));
            }, ERROR_TIMEOUT);

            setTimeouts(timeouts => ({
                ...timeouts,
                [mercureData.id]: {
                    stuckTimeout,
                    errorTimeout
                }
            }));

            return newLoadingExports;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mercureData]);

    useEffect(() => {
        const newReadyExports = Object.keys(loadingExports).reduce((acc, id) => {
            if (loadingExports[id].finished) {
                acc[id] = loadingExports[id];
            }
            return acc;
        }, {});

        if (Object.keys(newReadyExports).length === 0) return;
        addSuccessNotification(t('export_success'));
        setLoadingExports(prev => {
            const newLoadingExports = {...prev};
            Object.keys(newReadyExports).forEach(id => delete newLoadingExports[id]);
            return newLoadingExports;
        });
        setReadyExports(prev => ({
            ...prev,
            ...newReadyExports
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingExports]);

    return [loadingExports, readyExports, problemExports];
}

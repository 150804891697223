import {useEffect} from 'react';
import useToggle from "v4/hooks/useToggle";

export default function useKonamiCode() {
    const [konamiCodeEntered, toggleKonamiCodeEntered] = useToggle();

    const handleKonamiChange = () => {
        toggleKonamiCodeEntered();
        if (document.title.indexOf(' - no cache') === -1) {
            document.title += ' - no cache';
        } else {
            document.title = document.title.replace(' - no cache', '');
        }
        document.body.classList.toggle('no-cache');
    };

    useEffect(() => {
        const konamiCode = [
            'ArrowUp',
            'ArrowUp',
            'ArrowDown',
            'ArrowDown',
            'ArrowLeft',
            'ArrowRight',
            'ArrowLeft',
            'ArrowRight',
            'b',
            'a',
        ];

        let currentCodeIndex = 0;

        const handleKeyDown = (event) => {
            if (event.key === konamiCode[currentCodeIndex]) {
                currentCodeIndex++;

                if (currentCodeIndex === konamiCode.length) {
                    handleKonamiChange();
                    currentCodeIndex = 0;
                }
            } else {
                currentCodeIndex = 0;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return konamiCodeEntered;
};

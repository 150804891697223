import {captureException} from "@sentry/react";
import ErrorBoundary from "components/Error/errorBoundary";
import React, {Fragment, useEffect, useMemo} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import Field from "v4/components/form/Field/Field";
import Loader from "v4/components/ui/Loader/Loader";
import PreventRedirect from "v4/components/utils/PreventRedirect/PreventRedirect";
import {FORM_ENTITY_USER_EVENT} from "v4/data/userEvents";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import FormEntityLayoutFormActions
    from "v4/layouts/FormEntityLayout/components/FormEntityLayoutForm/components/FormEntityLayoutFormActions/FormEntityLayoutFormActions";
import 'v4/layouts/FormEntityLayout/components/FormEntityLayoutForm/FormEntityLayoutForm.scss';
import EntityLoop from "v4/layouts/partials/EntityLoop/EntityLoop";
import {generateUrl} from "v4/services/api.service";
import {userEventsService} from "v4/services/userEventsService";
import {randomId, sanitizeRichText} from "v4/utils";
import ProductCustomizeVoter from "../../../../voters/productCustomizeVoter";

export default function FormEntityLayoutForm({
                                                 inputsGroups,
                                                 submitRouteName,
                                                 additionalButtons,
                                                 whenSubmitted,
                                                 fetchFormParameters,
                                                 onBack,
                                                 shouldWarnOnChangePage
                                             }) {
    const {t} = usePolTranslation();
    const history = useHistory();
    const [{data, isLoading, isError, errorData, isFinished}, fetchSave] = useFetch();

    const allInputs = useMemo(() => Object.values(inputsGroups.results).map(({properties}) => properties).flat(), [inputsGroups]);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: getInitialValues(allInputs),
    });

    const {addErrorNotification} = useNotification();
    const onSubmit = async data => {
        if (methods.formState.isDirty || shouldWarnOnChangePage) {
            const {taskId,quoteId, ...restParameters} = fetchFormParameters ?? {};
            const parameters = {};
            if (taskId) data['tasks'] = [{externalValue: taskId}];
            if (quoteId) data['quotes'] = [{externalValue: quoteId}];
            if (restParameters) {
                Object.keys(fetchFormParameters)
                    .forEach(key => parameters[key] = fetchFormParameters[key]);
            }

            const {
                alteredData,
                errors = []
            } = await userEventsService([FORM_ENTITY_USER_EVENT, `beforesave:${submitRouteName}`].filter(Boolean), data);
            if (errors?.length > 0) {
                errors.forEach(error => addErrorNotification(error));
                return;
            }

            fetchSave({
                url: Object.keys(parameters).length > 0
                    ? generateUrl(submitRouteName, parameters)
                    : generateUrl(submitRouteName),
                config: {
                    method: 'POST',
                    body: createFormData(alteredData ?? data),
                }
            })
        } else {
            history.goBack();
        }
    };

    useEffect(() => {
        if (isFinished && !isError && whenSubmitted) {
            whenSubmitted(history, data, methods);
            if (data?.isProspectRefreshNeeded) {
                history.replace({
                    state: {
                        hasToRefresh: true,
                        refreshProspectView: true,
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isFinished, isError, history, whenSubmitted]);

    useEffect(() => {
        if (isError && errorData?.errors?.length > 0) {
            const existingKeys = Object.keys(methods.getValues());
            errorData.errors.forEach(({key, messages}) => {
                if (!existingKeys.includes(key)) {
                    captureException(new Error(`The key ${key} does not exist in the form`), {
                        extra: {existingKeys, errorData}
                    });
                    return;
                }
                if (messages.length > 0) {
                    methods.setError(key, {type: 'invalidForm', message: messages.join(' ')});
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, errorData]);

    const formPrefix = randomId();

    const AdditionalButtons = additionalButtons;

    return (
        <ErrorBoundary>
            {/* Demande confirmation à l'utilisateur avant de changer de page */}
            <PreventRedirect
                when={!methods.formState.isSubmitSuccessful && (shouldWarnOnChangePage || methods.formState.isDirty)}
                message={t('customer_confirm_leave_form_alert')}/>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}
                      className={`form form__scrollable form__edit--view form-v4`}>
                    {isLoading && (
                        <div className="form-v4__loader">
                            <Loader message="Enregistrement..."/>
                        </div>
                    )}
                    <FormEntityLayoutFormActions onBack={onBack}
                                                 entityId={fetchFormParameters?.id}
                                                 isSaveDisabled={methods.formState.isSubmitting}>
                        {AdditionalButtons && <AdditionalButtons/>}
                    </FormEntityLayoutFormActions>
                    <div className="input-field-group-wrapper">
                        <EntityLoop inputsGroups={inputsGroups}
                                    groupClassName="input-field-group"
                                    childrenClassName="input-field-wrapper">
                            {(input, {id: entityId}, showLabel) => (
                                <Fragment key={input.key}>
                                    <Field name={input.key} {...input} prefix={formPrefix} entityId={entityId}/>
                                    {showLabel && (
                                        <label htmlFor={formPrefix + input.key}
                                               className={getActiveClassName(methods.watch(input.key))}>
                                            {t(input.label)}
                                        </label>
                                    )}
                                </Fragment>
                            )}
                        </EntityLoop>
                    </div>
                </form>
            </FormProvider>
        </ErrorBoundary>
    )
}

export function getActiveClassName(value) {
    if (!value) return null;

    let className = null;
    if (
        String(value)?.length > 0
        || value?.begin?.length > 0
        || value?.end?.length > 0
    ) {
        className = 'active';
    }
    return className;
}

function getInitialValues(values) {
    const initialValues = {};
    values.forEach(({type, key, value, attr}) => {
        if (type === 'ChoiceType' && value?.id) {
            return initialValues[key] = value.id;
        }
        if (type === 'ChoiceType') {
            return initialValues[key] = value ?? [];
        }
        if (type === 'TextareaType' && attr?.["rich-text-editor"]) {
            return initialValues[key] = sanitizeRichText(value);
        }

        return initialValues[key] = value ?? '';
    })
    return initialValues;
}

function createFormData(data) {
    const formData = new FormData();
    const {canAccessProductAttributes} = ProductCustomizeVoter();

    for (const [key, value] of Object.entries(data)) {
        if (value?.shouldBeSubmitted === false) continue;
        if (value?.file !== undefined || value?.files !== undefined || value?.['shouldDelete'] !== undefined) {
            if (value?.file instanceof File) {
                formData.append(`${key}[customerFile][file]`, value.file);
            }
            if (value?.files?.[0] instanceof File) {
                value.files.forEach(file => formData.append(`${key}[][customerFile][file]`, file));
            }
            if (value?.shouldDelete) {
                if (Array.isArray(value.shouldDelete)) {
                    value.shouldDelete.forEach(
                        (val, index) => {
                            if (val) {
                                formData.append(`${key}[${index}][shouldDelete]`, val)
                            }
                        }
                    );
                } else {
                    formData.append(`${key}[shouldDelete]`, value.shouldDelete);
                }
            }
        } else {
            if (Array.isArray(value)) {

                if (key === "quoteLines") {
                    if (!value.length) {
                        formData.append(`quoteLines`, '');
                    }
                    value.forEach((item, index) => {
                        formData.append(`quoteLines[${index}][isOptional]`, item.isOptional ?? false);
                        formData.append(`quoteLines[${index}][name]`, item.name ?? '');
                        formData.append(`quoteLines[${index}][unitPriceExclVat]`, item.unitPriceExclVat ?? '');
                        formData.append(`quoteLines[${index}][quantity]`, item.quantity ?? '');
                        formData.append(`quoteLines[${index}][discountType]`, item.discountType ?? '');
                        formData.append(`quoteLines[${index}][discountValue]`, item.discountValue ?? '');
                        formData.append(`quoteLines[${index}][comment]`, item.comment ?? '');
                        formData.append(`quoteLines[${index}][description]`, item.description ?? '');
                        formData.append(`quoteLines[${index}][sectionName]`, item.sectionName ?? '');
                        formData.append(`quoteLines[${index}][vatRate]`, item.vatRate ?? '20.00');
                        formData.append(`quoteLines[${index}][quoteLineInfo][product]`, item.quoteLineInfo?.product?.id ?? '');

                        if (canAccessProductAttributes()) {
                            if (item.quoteLineInfo?.quoteLineInfoProductAttributes?.length > 0) {
                                item.quoteLineInfo.quoteLineInfoProductAttributes.forEach((quoteLineAttribute, indexAttr) => {
                                    formData.append(`quoteLines[${index}][quoteLineInfo][quoteLineInfoProductAttributes][${indexAttr}][productAttribute]`, quoteLineAttribute.productAttribute?.id);
                                    formData.append(`quoteLines[${index}][quoteLineInfo][quoteLineInfoProductAttributes][${indexAttr}][productAttributeLabel]`, quoteLineAttribute.productAttributeLabel);
                                    formData.append(`quoteLines[${index}][quoteLineInfo][quoteLineInfoProductAttributes][${indexAttr}][productAttributePosition]`, quoteLineAttribute.productAttributePosition);
                                    formData.append(`quoteLines[${index}][quoteLineInfo][quoteLineInfoProductAttributes][${indexAttr}][value]`, quoteLineAttribute.value ?? '');
                                });
                            } else {
                                formData.append(`quoteLines[${index}][quoteLineInfo][quoteLineInfoProductAttributes]`, '[]');
                            }
                        }
                    })
                } else {
                    value.forEach((v, index) => {
                        if (typeof v === "object" && !Array.isArray(v)) {
                            for (let prop in v) {
                                if (v.hasOwnProperty(prop)) {
                                    formData.append(`${key}[${index}][${prop}]`, v[prop]);
                                }
                            }

                            return;
                        }
                        formData.append(`${key}[]`, v)
                    })
                    if (value.length === 0) {
                        formData.append(key, '')
                    }
                }
            } else if (value?.id) {
                formData.append(key, value.id);
            } else {
                formData.append(key, value ?? '');
            }
        }
    }
    return formData;
}

import React, {useState} from 'react';
import {PolIcon} from "../../../PolIcon/policon";
import {FolderGrid} from "./folderGrid";
import {FolderItemList} from "./folderList";
import {Droppable} from 'react-beautiful-dnd';
import {onDoubleClickHandler, onSingleClickHandler} from "../../../../utils/clicHandler";
import {hasSelectedElementInChild} from "../../../../utils/folderManager";

export const Folder = (props) => {
    let {
        handleEditedFile, itemToEdit, onChangeItem, folder: {folderName, id, subFolders = []},
        hierarchyLevel, renderByCurrentDirectory = false, resetEditFile, editItemSpot, activeView, doIsNestedFolder, currentFolder
        , folderOpened, addOrRemoveFolderOpened, isMobileDisplay
    } = props;
    //State
    const [inputValue, setInputValue] = useState(folderName);
    const [isEditFolderName, setEditFolderName] = useState(false);
    const [selfFolderName, setFolderName] = useState(folderName);
    let [folderIsOpen, setfolderIsOpen] = useState(false);
    let hasFolderInChild = false;
    //Props & Variables
    let subFoldersOfCurrentDirectory = [];

    if (folderOpened && folderOpened.indexOf(id) !== -1 && !folderIsOpen) {
        setfolderIsOpen(true)
    }
    if (folderOpened && folderOpened.indexOf(id) === -1 && folderIsOpen) {
        setfolderIsOpen(false)
    }

    if (!renderByCurrentDirectory) {
        hasFolderInChild = hasSelectedElementInChild(currentFolder && currentFolder.id ? currentFolder.id : null, subFolders);
    }

    const isSelectedFolder = currentFolder && currentFolder.id === id

    if (subFolders.length) {
        subFolders.forEach(subFolder => {
            subFoldersOfCurrentDirectory.push({
                folderName: subFolder.folderName,
                id: subFolder.id,
                subFolders: subFolder.customerResources
            })
        })
    }

    let folderHierarchyLevel = hierarchyLevel;

    if (itemToEdit === id && !isEditFolderName) {
        setEditFolderName(true);
    } else {
        if (itemToEdit !== id && isEditFolderName) {
            setEditFolderName(false);
        }
    }

    if (isEditFolderName) {
        const $elt = document.querySelector('.ged-component__input-rename');
        if ($elt) {
            $elt.focus()
        }
    }

    hierarchyLevel += 1;

    return (
        <>
            {!renderByCurrentDirectory ? (
                <>
                    <Droppable key={`${id}-list`} isCombineEnabled
                               droppableId={`droppable-${id}-list`}>
                        {(provided, snapshot) => {
                            return (
                                <div
                                    ref={provided.innerRef}
                                    className={`${snapshot.isDraggingOver ? 'ged-component__folder-list-content-file-drop' : ''} ${isSelectedFolder ? 'ged-component__folder-list-content-file-selected' : ''} ged-component__folder-list-content-file ${doIsNestedFolder ? 'nestedLevel' + hierarchyLevel : ''} col-12 `}
                                    tabIndex={"-1"}
                                    onClick={(e) => onSingleClickHandler(() => {
                                        setfolderIsOpen(!folderIsOpen)
                                        addOrRemoveFolderOpened(id);
                                        if (itemToEdit !== id && isMobileDisplay) {
                                            onChangeItem({
                                                folderName: folderName,
                                                id: id,
                                                hierarchyLevel: folderHierarchyLevel
                                            }, folderHierarchyLevel, renderByCurrentDirectory, null, 'sidebar');
                                        }
                                    })}
                                    onDoubleClick={(e) => onDoubleClickHandler(() => {
                                        if (itemToEdit !== id) {
                                            onChangeItem({
                                                folderName: folderName,
                                                id: id,
                                                hierarchyLevel: folderHierarchyLevel
                                            }, folderHierarchyLevel, renderByCurrentDirectory, null, 'sidebar');
                                        }
                                    })}
                                >
                            <span className={`${folderIsOpen ? 'ged-component__folder-list-content-file--policon-reverse' : ''}`}>
                                <span>
                                    <PolIcon icon={'folder'}/>
                                    {isEditFolderName && editItemSpot === 'sidebar' ? (
                                        <>
                                            <input onBlur={() => {
                                                if (resetEditFile) {
                                                    resetEditFile()
                                                }
                                                handleEditedFile({
                                                    folderName: inputValue,
                                                    id: id
                                                })
                                                setFolderName(inputValue);
                                            }} className={'ged-component__input-rename'} onKeyPress={(e) => {
                                                if (e.charCode === 13) {
                                                    handleEditedFile({
                                                        folderName: inputValue,
                                                        id: id
                                                    })
                                                    setFolderName(inputValue);
                                                }
                                            }} onChange={(e) => {
                                                setInputValue(e.target.value)
                                            }} type={"text"} defaultValue={selfFolderName}/>
                                        </>
                                    ) : selfFolderName}
                                    </span>
                                {subFolders.length ? (
                                    <PolIcon icon={"Polygone"}/>
                                ) : null}
                            </span>
                                    <span className={'hidden'}>
                            {provided.placeholder}
                        </span>
                                </div>)
                        }}
                    </Droppable>

                    <>
                        {(subFolders.length && folderIsOpen)  || isSelectedFolder || hasFolderInChild ? (
                            <div className={`ged-component__folder-list-content-nestedFile`}>
                                {subFoldersOfCurrentDirectory.map(subFolder => {

                                    return (
                                        <Folder {...props} doIsNestedFolder={true}
                                                handleEditedFile={handleEditedFile}
                                                itemToEdit={itemToEdit}
                                                folder={subFolder} hierarchyLevel={hierarchyLevel}
                                                onChangeItem={onChangeItem}
                                                key={subFolder.id}
                                        />)
                                })}
                            </div>
                        ) : null}
                    </>
                </>
            ) : (
                //GENERATE BY CURRENTDIRECTORY
                <>
                    {activeView === 'grid' ? (
                        <FolderGrid {...props} inputValue={inputValue} setInputValue={setInputValue}
                                    isEditFolderName={isEditFolderName} selfFolderName={selfFolderName}
                                    setFolderName={setFolderName}
                        />) : <FolderItemList {...props} inputValue={inputValue} setInputValue={setInputValue}
                                              isEditFolderName={isEditFolderName} selfFolderName={selfFolderName}
                                              setFolderName={setFolderName}
                    />}
                </>
            )}
        </>
    )
}

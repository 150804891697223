import React from "react";
import {Droppable} from "react-beautiful-dnd";
import TreeNodesElement from "v4/components/ui/Tree/components/TreeNodes/components/TreeNodesElement/TreeNodesElement";

const getDroppableNodeStyle = (isDraggingOver) => {
    return isDraggingOver ? 'treeNodes--droppable' : ''
}

const TreeNodes = ({childNodes, parentId, level}) => {
    return (
        <Droppable droppableId={parentId}>

            {(provided, snapshot) => (

                <ul ref={provided.innerRef}
                    className={'treeNodes__Droppable level_' + level + ' ' + getDroppableNodeStyle(snapshot.isDraggingOver)}>

                    {childNodes.map((item, index) => (
                        <TreeNodesElement key={index} parentId={parentId} item={item} level={level} index={index}
                                          inEditMode={item.inEditMode ? item.inEditMode : false}/>
                    ))}

                    {provided.placeholder}
                </ul>

            )}

        </Droppable>
    )
}

export default TreeNodes;

import React from 'react';

export default function InputDate({
                                      fieldName,
                                      fieldValue,
                                      prefix,
                                      placeholder,
                                      error,
                                      attr,
                                      inputConstraints,
                                      withTime = false,
                                      onChange
                                  }) {
    const handleChange = ({currentTarget: {value}}) => {
        onChange(value);
    }

    return (
        <input className={`input-field${error ? ' has-error' : ''}`}
               type={withTime ? "datetime-local" : "date"}
               id={prefix + fieldName}
               value={fieldValue}
               placeholder={placeholder}
               data-is-required={!!inputConstraints?.required}
               {...attr}
               onFocus={({currentTarget}) => {
                   try {
                       currentTarget.showPicker()
                   } catch (e) {
                   }
               }}
               onChange={handleChange}/>
    )
}

import Login from "./views/Login/login";
import {ROLE_SUPER_ADMIN} from "./api/user/user";

const urlsApi = {
    prospects: {
        list: '/api/prospects',
        id: '/api/prospect/{id}',
        formBusiness: '/api/prospect/business/form',
        formIndividual: '/api/prospect/individual/form',
        post: '/api/prospects',
        search: 'api/prospects/search/form'
    },
    contacts: {
        list: '/api/contacts',
        prospectList: '/api/prospects/{id}/contacts',
        id: '/api/contact/{id}',
        form: '/api/contact/form',
        post: '/api/contacts',
        put: '/api/contact',
        unitMail: '/api/prospect/{id}/task/form-mailing/?contactIds={contactIds}',
        postUnitMail: '/api/prospect/{id}/task/form-mailing'
    },
    tasks: {
        list: '/api/tasks',
        prospectList: '/api/prospects/{id}/tasks?isClosed=false&isMailingTask=false',
        id: '/api/task/{id}',
        form: '/api/task/form',
        addFormWithSubResourceUrl: '/api/prospect/{id}/task/form', // On a besoin du prospect pour générer ce formulaire
        post: '/api/task',
        put: '/api/task',
    },
    summary_tasks: {
        tasksAll: '/api/prospects/{id}/tasks',
    },
    devis: {
        list: '/api/quotes',
        prospectList: '/api/prospects/{id}/quotes?status.isWon=false',
        id: '/api/quote/{id}',
        form: '/api/quote/form',
        addFormWithSubResourceUrl: '/api/prospect/{id}/quote/form', // On a besoin du prospect pour générer ce formulaire
        post: '/api/quote',
        put: '/api/quote',
        duplicate: '/api/quote/{id}/duplicate'
    },
    mails: {
        list: '/api/mail_types',
        id: '/api/mail_types/{id}',
        form: '/api/mail_types/form',
        post: '/api/mail_types',
        put: '/api/mail_types',
        delete: '/api/mail_types/{id}',
        duplicate: '/api/mail_types/{id}/duplicate'
    },
    historique_actions: {
        prospectList: '/api/prospects/{id}/tasks?isClosed=true&isMailingTask=false&isSynchroOutlookTask=false',
        id: '/api/task/{id}',
        form: '/api/task/form',
        addFormWithSubResourceUrl: '/api/prospect/{id}/task/form', // On a besoin du prospect pour générer ce formulaire
        post: '/api/task',
        put: '/api/task',
    },
    historique_mails: {
        prospectList: '/api/prospects/{id}/tasks?isMailingTask=true&isSynchroOutlookTask=false',
        id: '/api/task/{id}',
        form: '/api/task/form',
        addFormWithSubResourceUrl: '/api/prospect/{id}/task/form', // On a besoin du prospect pour générer ce formulaire
        post: '/api/task',
        put: '/api/task',
    },
    received_mails: {
        prospectList: '/api/prospects/{id}/tasks/?isSynchroOutlookTask=true&isClosed=true',
        id: '/api/task/{id}',
        form: '/api/task/form',
        addFormWithSubResourceUrl: '/api/prospect/{id}/task/form', // On a besoin du prospect pour générer ce formulaire
        post: '/api/task',
        put: '/api/task',
    },
    affaires: {
        prospectList: '/api/prospects/{id}/quotes?status.isWon=true',
        id: '/api/quote/{id}',
        form: '/api/quote/form',
        addFormWithSubResourceUrl: '/api/prospect/{id}/quote/form', // On a besoin du prospect pour générer ce formulaire
        post: '/api/quote',
        put: '/api/quote',
        duplicate: '/api/quote/{id}/duplicate'
    },
    last_visits: {},
    ged: {
        folderList: '/api/customer_resources?isRoot=true&onlyFolders=true',
        list: '/api/customer_resources?isRoot=true',
        listFromFolder: '/api/customer_folders/{id}',
        downloadFile: '/api/files/download/{id}',
        viewFile: '/api/files/view/{id}',
        uploadFile: '/api/customer_files',
        put: '/api/customer_files',
        putFolder: '/api/customer_folders/{id}',
        deleteFile: '/api/customer_files/{id}',
        deleteFolder: '/api/customer_folders/{id}',
        postFolder: '/api/customer_folders',
        searchWithFolder: '/api/customer_resources?customerFolderId={idDossier}&realFileName={valeur}',
        searchWithoutFolder: '/api/customer_resources?realFileName={valeur}',
    },
    admin: {
        customersList: '/api/customers?allowAll=1'
    },
    savedSearch: {
        search: '/api/saved_user_searches',
        delete: '/api/saved_user_searches/{id}'
    },
    emailing: {
        send: '/api/send_emailing'
    },
    home: {
        getData: '/api/home?stats={stats}'
    },
    user: {
        resetPassword: '/api/reset_password_models/form'
    }
};

export const getApiUrl = (name, type) => {
    return urlsApi[name][type];
};

export const baseProspect = '/clients-prospects-old';
export const baseProspectContent = 'actions';
export const baseContact = '/contacts-old';
export const baseTask = '/actions-old';
export const baseQuote = '/devis-offres-old';
export const baseMailType = '/mails/mails-types';
export const routeSuperAdminManageUser = '/administration/super_admin_user_list';

/* TODO: Refacto navigation.js */
export const routes = [
    {
        path: '/',
        component: Login,
        protected: false,
        text: 'home'
    },
    {
        path: `${baseMailType}/new/:type`,
        component: 'new',
        protected: true,
        className: 'mailType',
        text: 'mails',
        isMain: false
    },
    {
        path: `${baseMailType}/edit/:type/:itemId`,
        component: 'new',
        protected: true,
        className: 'mailType',
        text: 'mails',
        isMain: false
    },
    {
        path: '/administration',
        component: 'administration',
        protected: true,
        isAdmin: true,
        text: 'administration'
    },
    {
        path: '/administration/customer_list',
        component: 'customer',
        protected: true,
        isAdmin: true,
        text: 'administration_customer',
        group: 'acces',
        roles: [
            ROLE_SUPER_ADMIN
        ],
        subMenu: [
            {
                path: '/administration/customer_list',
            },
            {
                path: '/administration/customer_list/:id?',
            },
        ]
    },
    {
        path: '/administration/user_list',
        component: 'users',
        protected: true,
        isAdmin: true,
        text: 'administration_user',
        group: 'users',
        roles: [
            'ROLE_ADMIN',
        ],
        subMenu: [
            {
                path: '/administration/user_list',
            },
            {
                path: '/administration/user_list/:id?',
            },
        ]
    },
    {
        path: routeSuperAdminManageUser,
        component: 'users',
        protected: true,
        isAdmin: true,
        text: 'administration_user',
        roles: [
            ROLE_SUPER_ADMIN,
        ],
        subMenu: [
            {
                path: routeSuperAdminManageUser,
            },
            {
                path: routeSuperAdminManageUser + '/:id?',
            },
        ]
    },
    {
        path: '/administration/gestion_groupes',
        component: 'groups',
        protected: true,
        isAdmin: true,
        text: 'administration_groups',
        group: 'users',
        roles: [
            'ROLE_ADMIN',
        ],
        subMenu: [
            {
                path: '/administration/gestion_groupes',
            },
            {
                path: '/administration/gestion_groupes/:id?',
            },
        ]
    },
    {
        path: '/administration/sections',
        component: 'sections',
        protected: true,
        isAdmin: true,
        text: 'admin_gestion_sections',
        group: 'users',
        roles: [
            'ROLE_ADMIN',
        ],
        subMenu: [
            {
                path: '/administration/sections/:id?',
            },
        ]
    },
    {
        path: '/administration/gestion_listes',
        component: 'listing',
        protected: true,
        isAdmin: true,
        text: 'admin_gestion_listes',
        group: 'parametrage',
        roles: [
            'ROLE_ADMIN',
        ],
        subMenu: [
            {
                path: '/administration/gestion_listes',
            },
            {
                path: '/administration/gestion_listes/:id?',
            },
            {
                path: '/administration/gestion_listes_admin/:type?',
            },
        ]
    },
    {
        path: '/administration/gestion_champs',
        component: 'field',
        protected: true,
        isAdmin: true,
        text: 'admin_gestion_champs',
        group: 'parametrage',
        roles: [
            'ROLE_ADMIN',
        ],
        subMenu: [
            {
                path: '/administration/gestion_champs',
            },
            {
                path: '/administration/gestion_champs/:id?',
            },
        ]
    },
    // {
    //     path: '/administration/orders',
    //     component: 'orders',
    //     protected: true,
    //     isAdmin: true,
    //     text: 'admin_gestion_orders',
    //     group: 'parametrage',
    //     roles: [
    //         'ROLE_ADMIN',
    //     ],
    // },
    {
        path: '/administration/onglets',
        component: 'tabs',
        protected: true,
        isAdmin: true,
        text: 'tab_management',
        group: 'parametrage',
        roles: [
            ROLE_SUPER_ADMIN
        ],
        subMenu: [
            {
                path: '/administration/onglets/:id?',
            },
        ]
    },
    {
        path: '/administration/import_donnees',
        component: 'import',
        protected: true,
        isAdmin: true,
        text: 'admin_gestion_import_donnees',
        group: 'parametrage',
        roles: [
            'ROLE_ADMIN',
        ]
    },
    {
        path: '/recherche',
        component: 'listingSearch',
        protected: true,
        className: 'listingSearch',
        text: 'Recherche'
    },
];

export const menuGroupSuperAdmin = [
    {
        name: 'clients_accounts',
        path: '/administration/customer_list',
        icon: 'company',
        groupName: '',
        roles: [
            ROLE_SUPER_ADMIN,
        ]
    },
    {
        name: 'admin_gestion_utilisateurs',
        path: '/administration/super_admin_user_list',
        icon: 'client',
        groupName: '',
        roles: [
            ROLE_SUPER_ADMIN,
        ]
    },
];

export const menuGroupAdmin = [
    {
        name: 'clients_accounts',
        path: '/',
        icon: 'Polygone',
        groupName: '',
        roles: [
            ROLE_SUPER_ADMIN,
            'ROLE_ADMIN'
        ]
    },
    {
        name: 'admin_gestion_utilisateurs',
        path: '/',
        icon: 'client',
        groupName: 'users',
        roles: [
            "ROLE_ADMIN",
        ]
    },
    {
        name: 'admin_parametrage',
        path: '/',
        icon: 'tools',
        groupName: 'parametrage',
        roles: [
            "ROLE_ADMIN",
        ]
    },
];

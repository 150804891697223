import React, {useEffect, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, useHistory} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import {
    MODULE_CONFIGURATION_ADD, MODULE_CONFIGURATION_EDIT_FORM, MODULE_CONFIGURATION_FORM,
    MODULE_CONFIGURATION_ITEM
} from "v4/data/apiRoutes";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";

export default function ModuleConfigurationsForm({customerId, id, moduleId}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification();

    const [{data: moduleConfigurationFormData, isLoading: isLoadingForm, isFinished: isFinishedForm}, fetchModuleConfigurationForm] = useFetch();
    const [{data: moduleConfigurationDataSave, isError: isErrorSave, isLoading: isLoadingSave, isFinished: isFinishedSave}, fetchModuleConfigurationSave] = useFetch();

    const [moduleConfigurationForm, setModuleConfigurationForm] = useState(null);
    const [moduleConfiguration, setModuleConfiguration] = useState({
        keyConf: null,
        value: null,
    });
    const isEdit = (!!id);

    useEffect(() => {
        fetchModuleConfigurationForm({
            url: id
            ? apiService.generateUrl(MODULE_CONFIGURATION_EDIT_FORM, {id: id, customerId: customerId, moduleId: moduleId})
            : apiService.generateUrl(MODULE_CONFIGURATION_FORM, {customerId: customerId, moduleId: moduleId}),
        })
    }, [fetchModuleConfigurationForm, id, customerId, moduleId, isEdit])

    useEffect(() => {
        if (moduleConfigurationFormData && isFinishedForm) {
            const form = moduleConfigurationFormData?.schema?.['hydra:member']?.[2];
            setModuleConfigurationForm(form);

            if (form) {
                setModuleConfiguration({
                    keyConf: form?.keyConf?.value,
                    value: form?.value?.value,
                    defaultConfig: form?.defaultConfig?.value,
                    widget: form?.value?.widget,
                    module: moduleId,
                    customer: customerId
                });
            }
        }
    }, [isEdit, moduleId, customerId, setModuleConfigurationForm, moduleConfigurationFormData, isFinishedForm])

    const handleChange = (input) => {
        setModuleConfiguration({...moduleConfiguration, [input.target.name]: input.target.value})
    }

    const handleSave = (e) => {
        e.preventDefault()
        fetchModuleConfigurationSave({
            url: isEdit
            ? apiService.generateUrl(MODULE_CONFIGURATION_ITEM, {id: id})
            : apiService.generateUrl(MODULE_CONFIGURATION_ADD),
            config: {
                method: 'POST',
                body: JSON.stringify(moduleConfiguration)
            }
        })
    }

    const {push} = useHistory();
    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('module_configuration_save_error'))
        }

        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('module_configuration_save_done'))
            push(`${routesBase.adminModuleConfiguration}/${customerId}/${moduleId}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleConfigurationDataSave, isErrorSave, isFinishedSave])

    const CardHeaderTools = () => (
        <>
            <Link to={`${routesBase.adminModuleConfiguration}/${customerId}/${moduleId}`} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )

    return (
        <AdminCard title={t('manage_module_configurations')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoadingForm || isLoadingSave || !moduleConfigurationForm)
                    ? <Loader message="Chargement..."/>
                    : <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                    <div className="row row-fluid mt-4">
                        <div className={'col-12 '}>
                            <div className={'col-12 field ' + (moduleConfiguration?.keyConf ? 'field-written' : null)}>
                                <input type="text" name="keyConf" id="module_configuration_key_conf" className="field__input"
                                       defaultValue={moduleConfiguration?.keyConf} onChange={(input) => handleChange(input)}/>
                                <label className="field__label" htmlFor="module_configuration_key_conf">{t('module_configuration_key_conf')}</label>
                            </div>
                        </div>
                        <div className={'col-12 '}>
                            <div className={'col-12 field ' + (moduleConfiguration?.value ? 'field-written' : null)}>
                                {moduleConfiguration?.widget === 'textarea' ?
                                    <textarea rows={10} name="value" id="module_configuration_value" className="field__textarea"
                                           defaultValue={moduleConfiguration?.value} onChange={(input) => handleChange(input)}/>
                                    :
                                    <input type="text" name="value" id="module_configuration_value" className="field__input"
                                           defaultValue={moduleConfiguration?.value} onChange={(input) => handleChange(input)}/>
                                }
                                <label className="field__label" htmlFor="module_configuration_value">{t('module_configuration_value')}</label>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </AdminCard>
    )
}

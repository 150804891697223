import {useRouteMatch} from "react-router-dom";

/**
 * @param {Object} prop
 * @param {string|array} [prop.inUrl] - String(s) to check if they are in the current url
 * @param {string|array} [prop.inParams] - String(s) to check if they are in the current url params
 */
export default function useTestRoute({inUrl, inParams}, both = false) {
    const {path, params} = useRouteMatch();

    let testResultUrl = false;
    let testResultParams = false;

    if (inUrl?.length > 0) {
        const inUrlArray = Array.isArray(inUrl) ? inUrl : [inUrl];
        const pathArray = path.split('/');

        testResultUrl = inUrlArray.every((item) => pathArray.includes(item));
    }

    if (inParams?.length > 0) {
        const inParamsArray = Array.isArray(inParams) ? inParams : [inParams];

        testResultParams = inParamsArray.every((item) => params[item]);
    }

    if (both) {
        return testResultUrl && testResultParams;
    }

    return testResultUrl || testResultParams;
}

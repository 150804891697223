import React from 'react';
import {connect} from 'react-redux'
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {removeError} from "../../actions/datas";
import {PolIcon} from "../PolIcon/policon";
import {removeToken} from "../../actions/user";

const ErrorPopin = ({removeError, t, error, logOut}) => {
    let textError = t('erreur_chargement_donnees');
    let isError = true;
    setTimeout(function () {
        removeError()
    }, 5000);

    if (error === 401) {
        /*todo refresh_token*/
        logOut();
        textError = t('erreur_deconnexion');
    }

    if (error === 404) {
        textError = t('page_introuvable');
    }

    if (typeof error === 'string') {
        textError = t(error);
    } else {
        if(error.msg) {
            textError = error.msg;
            isError = error.errorCode !== 200
        }
    }

    return (
        <div className={`${isError ? 'error' : 'success'}`}>
            <PolIcon icon={'close'} className={`${isError ? 'error' : 'success'}__close`} onClick={() => removeError()}/>
            <p>{textError}</p>
        </div>
    )
};

const mapStateToProps = ({datas: {error}}) => ({
    error
});

const mapDispatchToProps = dispatch => {
    return {
        removeError: () => dispatch(removeError()),
        logOut: () => dispatch(removeToken()),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(ErrorPopin))

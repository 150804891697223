import DOMPurify from "dompurify";

export default function sanitizeRichText(string) {
    if (typeof string !== 'string' || string.length === 0) return '';

    const preparedContent = string
        // Unescape quotes
        .replace(/\\"/g, '"')
        // Replace literal "\n" with <br> (excel export?)
        .replace(/\\n/g, '<br>');

    // Add div because attachShadow requires an HTML Element, not a TextNode.
    return `<div>${DOMPurify.sanitize(preparedContent, {FORCE_BODY: true})}</div>`;
}

export default function useConfirm() {
    return (onConfirm, onCancel, message) =>
        () => {
            if (window.confirm(message)) {
                onConfirm();
            } else {
                onCancel();
            }
        }
}

import {fetchHandler, manageError} from './datas'
import {resetStoreDatas, resetSubResourceStore} from '../../actions/datas'
import {resetGedData} from "../../actions/ged";
import {deleteLastVisit} from "../../actions/user";

export function DeleteService(info) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');

        return fetch(info.deleteUrl ? (process.env.REACT_APP_HOST_API + info.deleteUrl) : info.absoluteDeleteUrl, {
            method: 'DELETE',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
        }).then(
            fetchHandler,
            error => {
                manageError(dispatch, true, info)
            }
        ).then(
            dispatch(resetGedData()),
            () => {
                if(info.order_entity === 'Contact'){
                    dispatch(deleteLastVisit(info.id, info.entityId))
                }
                if (!info.itemId || (!info.name || info.name !== 'prospects_update')) {
                    dispatch(resetSubResourceStore());
                } else {
                    dispatch(resetStoreDatas())
                }
                if(info.callback){
                    info.callback();
                }
            }
        );
    };
}
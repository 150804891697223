import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

/**
 *
 * @param {Object[]} rows
 * @param {string[]} keysToRender
 * @param {Function} renderCell
 * @param {Function} renderNotFoundCell
 * @return {JSX.Element}
 * @constructor
 */
export default function AdminSimpleTableBody({rows, keysToRender, renderCell, renderNotFoundCell}) {
    const {t} = usePolTranslation();

    return (
        <tbody>
        {rows.map((row, index) => (
            <tr key={index}>
                {keysToRender.map(key => (
                    <td key={key} className={key}>
                        {renderCell
                            ? renderCell(row[key], row, key)
                            : row[key] ? t(row[key]) : renderNotFoundCell?.(row, key) ?? '-'
                        }
                    </td>
                ))}
            </tr>
        ))}
        </tbody>
    )
}

import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {connect} from "react-redux";
import {routes} from "../../routes";
import TransitionRoute from './transition'
import Prospects from "../../views/Prospects/prospects";
import Contact from "../../views/Contact/contact";
import Actions from "../../views/Actions/actions";
import Devis from "../../views/Devis/devis";
import Mails from "../../views/Mail/mail";
import Administration from "../../views/Administration/administration";
import Orders from "../../views/Administration/pages/orders";
import Field from "../../views/Administration/pages/field";
import Listing from "../../views/Administration/pages/listing";
import {Error404} from "../../views/Error/404";
import Details from "../Page/Details";
import New from "../Page/New";
import UserService from "../../api/user/user";
import {setHistory} from "../../actions/content";
import {setAdmin} from "../../actions/router";
import {setToken} from "../../actions/user";
import {EnvHeader} from "../Header/_partials/envHeader";
import Home from "../../views/Home/home";
import MapsPage from "../Page/Maps";
import Ged from "../../views/Ged/Ged";
import ListingSearch from "../../views/Administration/pages/listingSearch";
import Groups from "../../views/Administration/pages/groups";
import Customer from "../../views/Administration/pages/customer";
import User from "../../views/Administration/pages/user";
import Import from "../../views/Administration/pages/import";
import SummaryTasks from "../../views/SummaryTasks/SummaryTasks";
import CustomerCodeProspectLoader from "../../views/Prospects/customerCodeProspectLoader";
import Tabs from "../../views/Administration/pages/Tabs";
import Sections from "../../views/Administration/pages/Sections";
import CustomTabs from "../../views/CustomTabs/CustomTabs";
import MailsTypesPage from "v4/pages/front/MailsTypes/MailsTypesPage";

class Router extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTransition: true
        }
    }

    render() {
        const {user, history, setHistory, onLogin, setAdmin} = this.props;
        const {isTransition} = this.state;
        const userToken = UserService.getTokens();

        const routesComponents = {
            'prospects': Prospects,
            'contact': Contact,
            'actions': Actions,
            'devis': Devis,
            'mails': Mails,
            'ged': Ged,
            'customTabs': CustomTabs,
            'administration': Administration,
            'details': Details,
            'new': New,
            'users': User,
            'customer': Customer,
            'groups': Groups,
            'orders': Orders,
            'tabs': Tabs,
            'sections': Sections,
            'import': Import,
            'field': Field,
            'listing': Listing,
            'home': Home,
            'maps': MapsPage,
            'listingSearch': ListingSearch,
            'summary_tasks': SummaryTasks,
            'CustomerCodeProspectLoader' : CustomerCodeProspectLoader,
            'mailTypes': MailsTypesPage
        };

        const lengthHistory = history.length;
        const pathname = window.location.pathname;
        if (history[lengthHistory - 1] !== pathname) {
            setHistory(pathname);
        }

        return (
            <>
                <EnvHeader/>
                <Route render={({location}) => {
                    return (
                        <TransitionRoute location={location} history={history} isTransition={isTransition}
                                         setTransition={(value) => this.setState({
                                             isTransition: value
                                         })}>
                            <Switch location={location}>
                                {
                                    routes.map((route, key) => {
                                        if (route.protected) {
                                            const RouteComponent = routesComponents[route.component];
                                            const exactRoute = (route.isMain || route.isAdmin) ? {exact: true} : {};
                                            if (!user.token) {
                                                if (userToken) {
                                                    onLogin(userToken.token, userToken.refresh_token);
                                                } else {
                                                    return <Redirect key={key} to="/"/>
                                                }
                                            }

                                            if (route.roles && route.roles.length) {
                                                if (!UserService.controlAccess(route.roles)) {
                                                    return null;
                                                }
                                            }

                                            if (route.subMenu && route.subMenu.length) {
                                                return route.subMenu.map((subMenu, subMenuKey) => {
                                                    return <Route key={subMenuKey} {...exactRoute} path={subMenu.path}
                                                                  render={(props) => {
                                                                      setAdmin(route.isAdmin);
                                                                      return <RouteComponent {...props}
                                                                                             location={location}/>
                                                                  }}/>;
                                                });
                                            }

                                            return <Route key={key} {...exactRoute} path={route.path}
                                                          render={(props) => {
                                                              setAdmin(route.isAdmin);
                                                              return <RouteComponent {...props} location={location}/>
                                                          }}/>
                                        } else {
                                            const RouteComponent = route.component;
                                            return (
                                                <Route key={key} exact path={route.path} render={() => {
                                                    return <RouteComponent/>
                                                }}/>
                                            )
                                        }
                                    })
                                }
                                <Route render={() => {
                                    return <Error404/>
                                }}/>
                            </Switch>
                        </TransitionRoute>
                    )
                }}/>
            </>
        )
    }

}

const mapStateToProps = ({user, content: {history}}) => ({
    user,
    history
});

const mapDispatchToProps = dispatch => {
    return {
        onLogin: (token, refresh_token) => dispatch(setToken(token, refresh_token)),
        setHistory: (route) => dispatch(setHistory(route)),
        setAdmin: (isAdmin) => dispatch(setAdmin(isAdmin)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Router);

import React from 'react';
import {PolIcon} from "../../PolIcon/policon";
import {Editor} from '@tinymce/tinymce-react';
import {PopupGed} from "./popupGed";
import {usePolTranslation} from "../../../v4/hooks/usePolTranslation";

/**
 * Composant TinyMce pour les inputs de type rich-text-editor
 * @param inputData
 * @param onChange
 * @param setPopup
 * @param t
 * @param height
 * @returns {*}
 * @constructor
 */
export function InputRichTextEditor({inputData, onChange, setPopup, height = 250}) {
    const {t} = usePolTranslation();

    /**
     * Méthode appelé au clique sur le bouton parcourir de l'upload d'image
     * @param callback
     * @param value
     * @param meta
     */
    const clickBrowseGed = (callback) => {
        setPopup({
            isOpen: true,
            content: (
                <PopupGed callback={callback} t={t} setPopup={setPopup}/>
            )
        })
    }

    return (
        <>
            {inputData.error ? (
                <div className={'form__error'}
                     onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                     onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                >
                    <PolIcon icon={'exclamation-circle'} className={'form__error__icon'}/>
                    <p className={'form__error__content'}>{t(inputData.error)}</p>
                </div>
            ) : null}

            <div className={'row'}>
                <div className={'col-md-12'}>
                    <label className={'field__label field__label-radio'}>{`${t(inputData.title)} ${inputData.isRequired || inputData.required ? '*' : ''}`}</label>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-md-12'}>
                    <Editor
                        tinymceScriptSrc={process.env.REACT_APP_PUBLIC_URL + 'tinymce/tinymce.min.js'}
                        initialValue={inputData.value}
                        init={{
                            image_title: true,
                            file_picker_types: 'image',
                            file_picker_callback: (callback, value, meta) => {
                                clickBrowseGed(callback, value, meta)
                            },
                            height: height,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic image backcolor forecolor |' +
                                'alignleft aligncenter alignright alignjustify |' +
                                'bullist numlist outdent indent | removeformat link charmap lists anchor insertdatetime bbcode| help',
                        }}
                        value={inputData.value}
                        onEditorChange={(content, editor) => onChange(content)}
                    />
                </div>
            </div>
            {inputData.attr && inputData.attr['data-sign'] ? (
            <span className={'btn-fl-right'} onClick={() => {
                onChange(inputData.value + inputData.attr['data-sign'])
            }}>{t('add_sign')}</span>
                ) :null }
        </>
    )
};

export const substitutionInputs = [
    {
        visible: true,
        viewOrderType: "form",
        groupe: true,
        width: 100,
        fields: [
            {
                "type": "string",
                "title": "Firstname",
                "propertyOrder": 1,
                visible: true,
                width: 25,
                input: {
                    value: '',
                    type: 'string',
                }
            },
            {
                "type": "string",
                "title": "Lastname",
                "propertyOrder": 2,
                visible: true,
                width: 25,
                input: {
                    value: '',
                    type: 'string',
                }
            },
            {
                "type": "string",
                "title": "Email",
                "propertyOrder": 3,
                width: 25,
                visible: true,
                input: {
                    value: '',
                    type: 'string',
                }
            },
            {
                "type": "TelType",
                "title": "Phone",
                "propertyOrder": 3,
                width: 25,
                visible: true,
                input: {
                    value: '',
                    type: 'string',
                }
            },
            {
                "type": "TelType",
                "title": "Phone",
                "propertyOrder": 3,
                width: 25,
                visible: true,
                input: {
                    value: '',
                    type: 'string',
                }
            },
            {
                "type": "TelType",
                "title": "Phone",
                "propertyOrder": 3,
                width: 25,
                visible: true,
                input: {
                    value: '',
                    type: 'string',
                }
            },
        ]
    },
    {
        visible: true,
        width: 100,
        viewOrderType: "form",
        groupe: true,
        fields: [
            {
                "type": "string",
                "title": "Phone",
                "propertyOrder": 4,
                width: 50,
                visible: true,
                input: {
                    value: '',
                    type: 'string',
                }
            },
            {
                "type": "string",
                "title": "Address",
                "propertyOrder": 5,
                width: 50,
                visible: true,
                input: {
                    value: '',
                    type: 'string',
                }
            },
        ]
    },
    {
        visible: true,
        viewOrderType: "form",
        groupe: true,
        width: 50,
        fields: [
            {
                "type": "string",
                "title": "Company",
                "propertyOrder": 7,
                visible: true,
                width: 50,
                input: {
                    value: '',
                    type: 'string',
                }
            },
            {
                "type": "string",
                "title": "City",
                "propertyOrder": 8,
                visible: true,
                width: 50,
                input: {
                    value: '',
                    type: 'string',
                }
            },
        ]
    }
];
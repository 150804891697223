import {captureException} from "@sentry/react";
import React from 'react';
import {withTranslation} from "react-i18next";

class GlobalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {
        captureException(error);
        this.setState({
            hasError: true
        });
    }

    render() {
        const {t} = this.props;
        const {hasError} = this.state;

        if (hasError) {
            return (
                <div className={'page page-error page-error-global'}>
                    <p className={'page__title'}>500</p>
                    <p className={'page__sub-title'}>{t("Une erreur s'est produite sur la page")}</p>
                    <p className={'page__link'}>{t("Merci de rafraichir la page ou de réessayer ultérieurement")}</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default withTranslation()(GlobalErrorBoundary);

import React, {useEffect, useState} from "react";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {generateCsv} from "../../../utils/exportManager";
import {PolIcon} from "../../PolIcon/policon";
import {connect} from "react-redux";
import {PostSearchService} from "../../../api/datas/post";
import Loading from "../../Loading/Loading";

const ExportCSV = (props) => {
    let {t, columns, displayPopupCSV, changeColumnsCSV, setDisplayPopupCSV, newColumnsForExport, postSearchService, searchContext, filters} = props;
    const [draggedItem, setDraggedItem] = useState(null);
    const [exportColumns, setExportColumns] = useState(columns);
    const [isExporting, setExporting] = useState(false);
    const [checkedAll, setCheckedAll] = React.useState(false);

    useEffect(() => {
        const newCols = columns.map((val) => {
            return {
                ...val,
                csvExport: newColumnsForExport ? newColumnsForExport[val.dataField] : val.hidden !== true
            }
        });

        setExportColumns(newCols);
    }, [displayPopupCSV, columns, newColumnsForExport]);

    const changeLocalColumnsCSV = (index, value) => {
        setCheckedAll(false);
        let items = exportColumns;
        items[index].csvExport = value;
        setExportColumns(items);
        changeColumnsCSV(index, value);
    }

    const handleClick = () => {
        let {infoSearch: {order_entity, order_type, submitUrl, name}, selectedRows} = props;
        const columnsSelected = exportColumns.filter(col => col.csvExport !== false).map(col => col.dataField);
        setExporting(true);

        let search = {};
        Object.keys(filters).forEach(key => {
            if(filters[key] && filters[key].value) {
                search = {
                    ...search,
                    [key]: filters[key].value,
                }
            }
        })

        postSearchService({
            url: submitUrl,
            submittedData: {...search, id: selectedRows},
            name: name,
            order_entity: order_entity,
            order_type: order_type,
            isExport: true,
            columnsSelected: columnsSelected,
            searchInformations: {searchText: searchContext.props.searchText},
            exportCallback: (data) => {
                generateCsv(exportColumns, data, undefined, undefined, t);
                setDisplayPopupCSVLocal(true);
                setExporting(false);
            }
        });
    };

    const onDragStart = (e, index) => {
        setDraggedItem(exportColumns[index]);
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    const onDragOver = index => {
        const draggedOverItem = exportColumns[index];

        // if the item is dragged over itself, ignore
        if (draggedItem === draggedOverItem) {
            return;
        }

        // filter out the currently dragged item
        let items = exportColumns.filter(item => item !== draggedItem);

        // add the dragged item after the dragged over item
        items.splice(index, 0, draggedItem);
        setExportColumns(items);
    };

    const onDragEnd = () => {
        setDraggedItem(null)
    };


    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const selectAllColumnsCSV = (e) => {
        exportColumns.map((item, index) => {
            changeLocalColumnsCSV(index, e.target.checked);

            return item;
        });
        setCheckedAll(e.target.checked);
    }

    /**
     * @param {boolean} value
     */
    const setDisplayPopupCSVLocal = (value) => {
        setCheckedAll(false);
        setDisplayPopupCSV(value);
    }

    return (
        <>

            <PolIcon icon="dwl-csv" className={'action__export'}
                     onClick={() => setDisplayPopupCSVLocal(true)}/>

            {displayPopupCSV &&
            <>
                <span className={'popup-export__overlay'} onClick={() => setDisplayPopupCSVLocal(true)}/>
                <div className={'popup-export'}>
                    <p className={'popup-export__title'}>{t('question_exporter_ligne')}</p>
                    {isExporting ? <Loading message={t("loading")} specificClass={"popup__loader"}/> : (
                        <>
                            <div className={'columns__select_all_item'}>
                                <div>
                                    <input type={'checkbox'} checked={checkedAll} onChange={selectAllColumnsCSV} id={`column_select_all`} />
                                    <label htmlFor={`column_select_all`}>
                                        {t('select_all')}
                                    </label>
                                </div>
                            </div>
                            <hr/>
                            <div className={'popup-export__content'}>
                                <ul className={'columns'}>
                                    {exportColumns.map((item, key) => {
                                        let checked = {
                                            checked: false
                                        };
                                        let exportable = false;
                                        if (item.dataField === 'actions') {
                                            return null;
                                        }
                                        if (item.csvExport !== false) {
                                            exportable = true;
                                            checked = {
                                                checked: true
                                            }
                                        }

                                        return (
                                            <li key={key} className={'columns__item'}>
                                                <div
                                                    draggable
                                                    onDragStart={e => onDragStart(e, key)}
                                                    onDragOver={() => onDragOver(key)}
                                                    onDragEnd={onDragEnd}
                                                >
                                                    <input type={'checkbox'} {...checked} onChange={() => {
                                                        changeLocalColumnsCSV(key, !exportable)
                                                    }} id={`column_${key}`}/>
                                                    <label htmlFor={`column_${key}`}>
                                                        {item.text}
                                                    </label>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </>
                        )}

                    <button className={'popup-export__btn'} onClick={() => handleClick()} disabled={isExporting}>
                        {t('export_csv')}
                    </button>
                </div>
            </>
            }
        </>
    );
};

const mapStateToProps = ({form}) => ({
    form
});

const mapDispatchToProps = dispatch => {
    return {
        postSearchService: (info) => dispatch(PostSearchService(info)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(ExportCSV));

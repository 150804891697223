import {PolIcon} from "components/PolIcon/policon";
import React from 'react'
import {createPortal} from "react-dom";
import 'v4/components/ui/Confirm/Confirm.scss';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

const defaultClassNames = {
    overlay: '',
    confirm: '',
    header: '',
    content: '',
    btns: '',
    btnCancel: '',
    btnConfirm: ''
}

export default function Confirm({
                                    isOpen,
                                    title,
                                    cancelAction,
                                    confirmAction,
                                    closeAction,
                                    noLabel = 'no',
                                    yesLabel = 'yes',
                                    principalColor = '#3860C4',
                                    parentSelector = '#root',
                                    isLoading,
                                    classNames = {},
                                    children
                                }) {
    const finalClassNames = {...defaultClassNames, ...classNames};
    const {t} = usePolTranslation();
    const modalRoot = document.querySelector(parentSelector);

    if (!modalRoot || !isOpen) return null;

    return (
        createPortal(
            <div className={"confirm__overlay " + finalClassNames.overlay} style={{'--principal-color': principalColor}}>
                <div className={"confirm " + finalClassNames.confirm}>
                    <div className={"confirm__header " + finalClassNames.header}>
                        <p>{t(title)}</p>
                        <PolIcon icon="close" onClick={closeAction ?? cancelAction}/>
                    </div>
                    {
                        children &&
                        <div className={"confirm__content " + finalClassNames.content}>
                            {children}
                        </div>
                    }
                    <div className={"confirm__btns " + finalClassNames.btns}>
                        <button className={"btn confirm__btns__cancel " + finalClassNames.btnCancel}
                                onClick={cancelAction ?? closeAction}>{t(noLabel)}</button>
                        <button className={"btn confirm__btns__confirm " + finalClassNames.btnConfirm}
                                onClick={confirmAction} disabled={isLoading || !confirmAction}>
                            {t(yesLabel)}
                            {isLoading && <PolIcon icon="circle-notch"/>}
                        </button>
                    </div>
                </div>
            </div>
            , modalRoot)
    )
}

import React, {useEffect, useState} from "react";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useFetch from "v4/hooks/useFetch";
import apiService from "v4/services/api.service";
import {QUOTE_PRODUCTS_FILES} from "v4/data/apiRoutes";
import useNotification from "v4/hooks/useNotification";
import {PolIcon} from "components/PolIcon/policon";
import 'v4/features/front/products/components/InputFileImportProductsDocs/InputFileImportProductsDocs.scss'

export default function InputFileImportProductsDocs({files, setFiles, quoteId}) {
    const {t} = usePolTranslation()
    const [{data, isLoading, isFinished, isError}, fetchDocuments] = useFetch()
    const [isProcessingFiles, setIsProcessingFiles] = useState(false)
    const {addErrorNotification} = useNotification()
    const [processedFiles, setProcessedFiles] = useState(0)

    const handleImportProductsFiles = (e) => {
        e.preventDefault()
        setProcessedFiles(0)
        fetchDocuments({
            url: apiService.generateUrl(QUOTE_PRODUCTS_FILES).replace('$id$', quoteId)
        })
    }

    const processFile = (file) => {
        fetch(file.source, {})
            .then(res => res.blob())
            .then(res => {
                    const loadedFile = new File(
                        [res],
                        file.name,
                        {type: file.type},
                    )

                    files.push(loadedFile)
                    setProcessedFiles(c => c + 1)
                }
            )
    }

    useEffect(() => {
        if (isLoading) {
            setIsProcessingFiles(true)
        }

        if (!isLoading) {
            setIsProcessingFiles(false)
        }

        if (data && isFinished && !isError) {
            if (data?.files?.length) {
                setIsProcessingFiles(true)

                data.files.forEach((file) => {
                    processFile(file)
                })
            }
        }

        if (isError) {
            setIsProcessingFiles(false)
            addErrorNotification(t('error_occured_while_loading_products_files'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading, isFinished, isError])

    useEffect(() => {
        if (data?.files?.length && isFinished && !isError && processedFiles >= data.files.length) {
            setIsProcessingFiles(false)
            setFiles([...files])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processedFiles, data, isFinished, isError])

    return (
        <button onClick={handleImportProductsFiles} disabled={isProcessingFiles}
                className={'btn btn-primary import-files-button'} style={{
            position: 'relative',
            top: '-10px',
            lineHeight: '1',
            paddingTop: '5px',
            paddingBottom: '5px'
        }}>
            {!isProcessingFiles &&
                (t('add_products_quote_files'))
            }

            {isProcessingFiles &&
                (<>{t('processing_products_files')} <PolIcon icon="circle-notch spin"/></>)
            }
        </button>
    )
}

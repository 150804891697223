const stateDefault = {
};

const prospects = (state = stateDefault, action) => {
    switch (action.type) {
        case 'SET_DATA_FOR_SELECT' :
            return {
                ...state,
                selectData: action.data.map((prospect) => {
                    return {
                        label: prospect.fullname ? prospect.fullname : prospect.id,
                        value: prospect.id
                    }
                }),
                prospectId : action.prospectId,
                entityId : action.entityId,
            }
        case 'RESET_AFTER_DUPLICATE' :
            return {
                resetAfterDuplicate: true
            };
        default:
            return state;
    }
};

export default prospects;

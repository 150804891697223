import React, {Fragment} from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import DashboardQuotesSummaryCell
    from "v4/pages/front/Dashboard/components/DashboardQuotesSummary/components/DashboardQuotesSummaryCell/DashboardQuotesSummaryCell";
import useDashboardQuotesSummary
    from "v4/pages/front/Dashboard/components/DashboardQuotesSummary/hooks/useDashboardQuotesSummary";

export default function DashboardQuotesSummary() {
    const {t} = usePolTranslation();
    const {data, tableInfos, getPreSearch} = useDashboardQuotesSummary();

    return (
        <div className="dashboard-quotes-summary">
            <p>{t('quotes_results')}</p>
            <table>
                <thead>
                <tr>
                    <th/>
                    <th colSpan={6}>{t('since_january')}</th>
                    <th colSpan={(tableInfos?.remaining_months?.length * 2) + 2 ?? 12}>{t('current_quotes')}</th>
                </tr>
                <tr>
                    <th/>
                    <th colSpan={2}>{t('won_label')}</th>
                    <th colSpan={2}>{t('abandoned_label')}</th>
                    <th colSpan={2}>{t('lost_label')}</th>
                    <th colSpan={2}>{t('late_label')}</th>
                    {tableInfos?.remaining_months && (
                        tableInfos.remaining_months.map(({label}) => <th colSpan={2} key={label}>{label}</th>)
                    )}
                </tr>
                </thead>
                <tbody>
                {data && data.map((row, index) => (
                    <tr key={index}>
                        <DashboardQuotesSummaryCell value={row.fullname}/>
                        <DashboardQuotesSummaryCell value={row.total_won}
                                                    isFinancial={true}
                                                    preSearch={getPreSearch('won', index)}/>
                        <DashboardQuotesSummaryCell value={row.nb_won}
                                                    preSearch={getPreSearch('won', index)}/>
                        <DashboardQuotesSummaryCell value={row.total_abandonned}
                                                    isFinancial={true}
                                                    preSearch={getPreSearch('abandoned', index)}/>
                        <DashboardQuotesSummaryCell value={row.nb_abandonned}
                                                    preSearch={getPreSearch('abandoned', index)}/>
                        <DashboardQuotesSummaryCell value={row.total_lost}
                                                    isFinancial={true}
                                                    preSearch={getPreSearch('lost', index)}/>
                        <DashboardQuotesSummaryCell value={row.nb_lost}
                                                    preSearch={getPreSearch('lost', index)}/>
                        <DashboardQuotesSummaryCell value={row.total_late}
                                                    isFinancial={true}
                                                    preSearch={getPreSearch('late', index)}/>
                        <DashboardQuotesSummaryCell value={row.nb_late}
                                                    preSearch={getPreSearch('late', index)}/>
                        {tableInfos?.remaining_months && (
                            tableInfos.remaining_months.map(({monthNumber}) => (
                                <Fragment key={monthNumber}>
                                    <DashboardQuotesSummaryCell value={row[`total_month_${monthNumber}`]}
                                                                isFinancial={true}
                                                                preSearch={getPreSearch(`total_month_${monthNumber}`, index)}/>
                                    <DashboardQuotesSummaryCell value={row[`nb_month_${monthNumber}`]}
                                                                preSearch={getPreSearch(`total_month_${monthNumber}`, index)}/>
                                </Fragment>
                            ))
                        )}
                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr className="table-stat__body__total">
                    <td>{t('total_euro')}</td>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.total_won}
                                                isFinancial={true}
                                                preSearch={getPreSearch('total_won')}/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.nb_won}
                                                preSearch={getPreSearch('total_won')}/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.total_abandonned}
                                                isFinancial={true}
                                                preSearch={getPreSearch('total_abandoned')}/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.nb_abandonned}
                                                preSearch={getPreSearch('total_abandoned')}/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.total_lost}
                                                isFinancial={true}
                                                preSearch={getPreSearch('total_lost')}/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.nb_lost}
                                                preSearch={getPreSearch('total_lost')}/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.total_late}
                                                isFinancial={true}
                                                preSearch={getPreSearch('total_late')}/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.nb_late}
                                                preSearch={getPreSearch('total_late')}/>
                    {tableInfos?.remaining_months && (
                        tableInfos.remaining_months.map(({monthNumber}) => (
                            <Fragment key={monthNumber}>
                                <DashboardQuotesSummaryCell
                                    value={tableInfos.totalByColumn[`total_month_${monthNumber}`]}
                                    isFinancial={true}
                                    preSearch={getPreSearch(`total_month_${monthNumber}`)}/>
                                <DashboardQuotesSummaryCell
                                    value={tableInfos.totalByColumn[`nb_month_${monthNumber}`]}
                                    preSearch={getPreSearch(`total_month_${monthNumber}`)}/>
                            </Fragment>
                        ))
                    )}
                </tr>
                <tr className={'table-stat__body__total'}>
                    <td>{t('total_ponderate')}</td>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.total_weighted_won}
                                                isFinancial={true}/>
                    <td/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.total_weighted_abandonned}
                                                isFinancial={true}/>
                    <td/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.total_weighted_lost}
                                                isFinancial={true}/>
                    <td/>
                    <DashboardQuotesSummaryCell value={tableInfos.totalByColumn.total_weighted_late}
                                                isFinancial={true}/>
                    <td/>
                    {tableInfos?.remaining_months && (
                        tableInfos.remaining_months.map(({monthNumber}) => (
                            <Fragment key={monthNumber}>
                                <DashboardQuotesSummaryCell key={monthNumber}
                                                            value={tableInfos.totalByColumn[`total_weighted_month_${monthNumber}`]}
                                                            isFinancial={true}/>
                                <td/>
                            </Fragment>
                        ))
                    )}
                </tr>
                </tfoot>
            </table>
        </div>
    )
}

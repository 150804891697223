import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {setExpandInfo, setHistory, setRedirectToList} from "../../actions/content";
import InfosProspect from "./_details/infosProspect";
import {baseProspect} from "../../routes";
import ErrorBoundary from "../Error/errorBoundary";
import {PolIcon} from "../PolIcon/policon";
import ReactTooltip from "react-tooltip";

class Details extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            toSetLastVisit: false,
            isPageVisit: false,
            idProspect: false,
            canChangeWindow: true
        }
    }

    componentDidMount() {
        const {location: {state = {}}, match: {params}} = this.props;

        this.setState({
            idProspect: params.id,
            toSetLastVisit: state ? !state.toSetLastVisit : null,
            isPageVisit: (state && state.isPageVisit === true),
        })
    }

    /**
     * change location
     * @param {string} id
     */
    changeLocation = (id) => {
        const {idProspect} = this.state;
        if(idProspect !== id) {
            this.setState({
                idProspect: id
            })
        }
    };

    /**
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            const {content: {redirectToList}, setRedirectToList} = this.props;
            if(redirectToList){
                setRedirectToList(false);

                this.setState({
                    redirect: <Redirect to={{
                        pathname: baseProspect,
                    }}/>
                });
            }
        }        
    }

    /**
     * set can change window
     * @param {boolean} val
     */
    setCanChangeWindow = (val) => {
        this.setState({
            canChangeWindow: val
        })
    };

    /**
     * @returns {JSX.Element}
     */
    render() {
        const {content: {expanded, history}, location: {state = {}}, setExpandInfo, t} = this.props;
        const {redirect, toSetLastVisit, isPageVisit, idProspect, canChangeWindow} = this.state;
        let classContent = expanded ? ' expanded' : '';

        return (
            <>
                {redirect}
                {idProspect ? (
                    <>
                            <div className={'page page-details main-content__body__slide'}>
                                <div className={`page-details__content ${classContent}`}>
                                    <div className={'page-details__close'}>
                                        <span className={"page-details__close__icon"} onClick={() => {
                                                const page = isPageVisit ? baseProspect : getLastHistoryList(history);
                                                this.setState({
                                                    redirect: <Redirect to={{
                                                        pathname: page,
                                                    }}/>
                                                }, () => {
                                                    this.setState({
                                                        redirect: null
                                                    })
                                                });
                                                if(canChangeWindow) {
                                                    setExpandInfo(false);
                                                }

                                        }}
                                        data-tip={t('quit')}>
                                            <PolIcon icon="quit"/>
                                        </span>
                                    </div>
                                    <ErrorBoundary className={'page-details__prospect'}>
                                        <InfosProspect idProspect={idProspect} toSetLastVisit={toSetLastVisit}
                                                       isUpdateProspect={state ? state.updateProspect : null}
                                                       isCompany={state ? state.isCompany : null}
                                                       setCanChangeWindow={this.setCanChangeWindow.bind(this)}
                                        />
                                    </ErrorBoundary>

                                    <ReactTooltip effect="solid" />
                                </div>
                            </div>
                    </>
                ) : null}
            </>
        )
    }
}

export const getLastHistoryList = (history) => {
    let page = ''
    history.forEach((item) => {
        if(item.split('/').length === 2 || (item.split('/').length === 3 && item.split('/')[1] === 'onglets')){
            page = item
        }
    })
    return page;
}

const mapStateToProps = ({content, datas}) => ({
    content,
    datas
});

const mapDispatchToProps = dispatch => {
    return {
        setHistory: (route) => dispatch(setHistory(route)),
        setExpandInfo: (expand) => dispatch(setExpandInfo(expand)),
        setRedirectToList: (redirectToList) => dispatch(setRedirectToList(redirectToList))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(Details));

import React, {useContext} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux'
import {logOut} from "../../../actions/user";
import {PolIcon} from "../../PolIcon/policon";
import {NavLink} from "react-router-dom";
import {resetStoreContent, setPopup} from "../../../actions/content";
import {resetStoreDatas} from "../../../actions/datas";
import UserService from "../../../api/user/user";
import ReactTooltip from "react-tooltip";
import ChangePassword from "./changePassword";
import {useRecoilState} from "recoil";
import {customerState} from "../../Admin/atoms/customer";
import {AdminContext} from "../../../v4/contexts/AdminContext";

const MenuUser = ({t, logOut, togglePopup, resetDatas, resetContent}) => {
    const isAdmin = UserService.controlAccess(['ROLE_ADMIN']);
    const [, setCustomer] = useRecoilState(customerState);
    const {setAsCustomer} = useContext(AdminContext)

    const setBothCustomer = (customer) => {
        setAsCustomer(customer)
        setCustomer(customer)
    }

    return (
        <>
            {isAdmin ? (
                <NavLink to={'/administration'} className={'user__actions__item'}>
                    <PolIcon icon="cog" className={'user__actions__item__picto'}/>
                    {t('administration')}
                </NavLink>
            ) : null}

            <span className={'user__actions__item'} onClick={() => {
                togglePopup(
                    {
                        isOpen: true,
                        target: 'duplicate_item',
                        className: 'popup__forgot',
                        content: (
                            <ChangePassword togglePopup={togglePopup}/>
                        )
                    }
                )}}>
                <PolIcon icon="unlock" className={'user__actions__item__picto'}/>
                {t('mot_passe')}
            </span>

            <p className={'user__actions__item'}
               onClick={() => {
                   resetDatas();
                   resetContent();
                   setBothCustomer(null)
                   logOut()
               }} data-tip={t('logout')}>
                <PolIcon icon="times-circle" className={'user__actions__item__picto'}/>
                {t('deconnexion')}
            </p>
            <ReactTooltip effect="solid" />
        </>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(logOut()),
        resetDatas: () => dispatch(resetStoreDatas()),
        resetContent: () => dispatch(resetStoreContent()),
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(MenuUser));


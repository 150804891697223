import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {QUOTE_ADD, QUOTE_FORM, QUOTE_ITEM, QUOTE_ITEM_FORM} from "v4/data/apiRoutes";
import useTestRoute from "v4/hooks/useTestRoute";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import ProspectQuotesCustomTabListing
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesCustomTab/components/ProspectQuotesCustomTabListing/ProspectQuotesCustomTabListing";
import ProspectQuotesRecalculateButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesRecalculateButton/ProspectQuotesRecalculateButton";
import {
    whenSubmitted
} from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/ProspectQuotesTab";
import ProspectQuotesCustomTabShow
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesCustomTab/components/ProspectQuotesCustomTabShow/ProspectQuotesCustomTabShow";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";


export default function ProspectQuotesCustomTab({prospectId, tab}) {
    const isShow = useTestRoute({inParams: 'showId'});
    const isEdit = useTestRoute({inUrl: 'edit'});
    const isAdd = useTestRoute({inUrl: 'add'});
    const isListing = !isShow && !isEdit && !isAdd;
    const {params: {showId = null}} = useRouteMatch();
    const [quoteData, setQuoteData] = useState(null);

    const {setQuote} = useProspectPageContext();

    const onGetData = useCallback((data) => {
        const hydraMember = data?.['hydra:member'] ?? data;
        setQuoteData(hydraMember);
        if (hydraMember) {
            const quoteFromHydra = Object.values(hydraMember.results).reduce((acc, {properties}) => {
                properties.forEach(({key, value}) => {
                    acc[key] = value;
                })
                return acc;
            }, {});
            quoteFromHydra.metadata = hydraMember.metadata;
            setQuote(quoteFromHydra);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        quoteData && onGetData(quoteData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteData]);

    const fetchFormParameters = useMemo(() => ({
        id: showId,
        prospectId,
        tabId: tab.id,
    }), [showId, prospectId, tab]);

    return (
        <>
            <ToggleComponent isShowing={isListing}>
                <ProspectQuotesCustomTabListing prospectId={prospectId} tab={tab}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isShow && !isEdit}
                             shouldRemoveFromDom={true}
                             className="prospect-tab__prospect showing">
                <ProspectQuotesCustomTabShow tab={tab}
                                             prospectId={prospectId}
                                             showId={showId}
                                             onGetData={onGetData}
                                             quoteData={quoteData}
                                             setQuoteData={setQuoteData}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isAdd || isEdit}
                             shouldRemoveFromDom={true}
                             className="prospect-tab__prospect editing">
                <FormEntityLayout title="devis_offres"
                                  formRouteName={isAdd ? QUOTE_FORM : QUOTE_ITEM_FORM}
                                  submitRouteName={isAdd ? QUOTE_ADD : QUOTE_ITEM}
                                  fetchFormParameters={fetchFormParameters}
                                  additionalButtons={() => <ProspectQuotesRecalculateButton eventForRecalculate={`beforesave:${isAdd ? QUOTE_ADD : QUOTE_ITEM}`}/>}
                                  whenSubmitted={whenSubmitted}/>
            </ToggleComponent>
        </>
    )
}

import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import './InlineLoader.scss';
import {useTranslation} from "react-i18next";

export default function InlineLoader({text = 'loading', className = ''}) {
    const {t} = useTranslation();

    return (
        <p className={`inline-loader ${className}`}>
            <PolIcon icon="circle-notch" className="spinner"/>
            <span>{t(text)}</span>
        </p>
    )
}

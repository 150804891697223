import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {createPortal} from "react-dom";
import AttributesPopupRenderFields
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuotesProductsTableAttributesPopup/components/AttributesPopupRenderFields/AttributesPopupRenderFields";
import useQuotesProductsTableAttributesPopup
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuotesProductsTableAttributesPopup/hooks/useQuotesProductsTableAttributesPopup";
import 'v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuotesProductsTableAttributesPopup/QuotesProductsTableAttributesPopup.scss';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function QuotesProductsTableAttributesPopup({quoteLineAttributesFor, closePopup, isShow}) {
    const {t} = usePolTranslation();

    const [inputs] = useQuotesProductsTableAttributesPopup(quoteLineAttributesFor);

    return (
        <>
            {
                createPortal(
                    <div className="quotes-products-table-attributes-popup">
                        <div className={`quotes-products-table-attributes-popup__content ${isShow ? 'is-show' : ''}`}>
                            <div className="quotes-products-table-attributes-popup__header">
                                <h2 className="quotes-products-table-attributes-popup__title">{t('product_attributes')}</h2>

                                {(!inputs || isShow) && <PolIcon icon="close" onClick={closePopup}/>}
                            </div>
                            <div className="quotes-products-table-attributes-popup__body">
                                <div className="quotes-products-table-attributes-popup__attributes">
                                    <AttributesPopupRenderFields inputs={inputs} isShow={isShow}/>
                                </div>
                            </div>
                            {
                                (inputs?.length > 0 && !isShow) && (
                                    <div className="quotes-products-table-attributes-popup__footer">
                                        <button onClick={closePopup}>{t('validate')}</button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    , document.body)
            }
        </>
    )
}

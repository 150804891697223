import React, {Component} from 'react';
import Header from "./_partials/header";
import SideBar from "./_partials/sidebar";
import {connect} from "react-redux";
import {AdminProvider} from "../../v4/contexts/AdminContext";
import NotificationProvider from "../../v4/contexts/NotificationContext";
import {withPolTranslation} from "../../v4/hooks/usePolTranslation";

class Admin extends Component {
    render() {
        const {children} = this.props;
        let classAdmin = 'admin';
        if (this.props.admin.isSticky) {
            classAdmin += ' is-sticky'
        }

        return (
            <AdminProvider>
                <NotificationProvider>
                    <div className={classAdmin}>
                        <Header/>
                        <SideBar/>
                        <div className={'admin__wrapper'}>
                            {children}
                        </div>
                    </div>
                </NotificationProvider>
            </AdminProvider>
        )
    }
}

const mapStateToProps = ({admin}) => ({
    admin
});

export default connect(
    mapStateToProps,
    null
)(withPolTranslation(Admin));

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from "react-router-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {QUOTE_ITEM, QUOTE_ITEM_FORM} from "v4/data/apiRoutes";
import useTestRoute from "v4/hooks/useTestRoute";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import ProspectQuotesRecalculateButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesRecalculateButton/ProspectQuotesRecalculateButton";
import ProspectWonQuotesTabListing
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/components/ProspectWonQuotesTabListing/ProspectWonQuotesTabListing";
import ProspectWonQuoteTabShow
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/components/ProspectWonQuoteTabShow/ProspectWonQuoteTabShow";
import {
    whenSubmitted
} from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/ProspectQuotesTab";

export default function ProspectWonQuotesTab({prospectId}) {
    const isShow = useTestRoute({inParams: 'showId'});
    const isEdit = useTestRoute({inUrl: 'edit'});
    const isListing = !isShow && !isEdit;
    const {params: {showId = null}} = useRouteMatch();
    const [quoteData, setQuoteData] = useState(null);

    const {setQuote} = useProspectPageContext();

    const onGetData = useCallback((data) => {
        const hydraMember = data?.['hydra:member'] ?? data;
        setQuoteData(hydraMember);
        if (hydraMember) {
            const [quote, quoteForm] = Object.values(hydraMember.results).reduce((acc, {properties}) => {
                properties.forEach(input => {
                    acc[0][input.key] = input.value;
                    acc[1][input.key] = input;
                })
                return acc;
            }, [{}, {}]);
            quote.metadata = {quoteForm, ...hydraMember.metadata};
            setQuote(quote);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        quoteData && onGetData(quoteData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteData]);

    const fetchFormParameters = useMemo(() => ({
        id: showId,
        prospectId
    }), [showId, prospectId]);

    return (
        <>
            <ToggleComponent isShowing={isListing}>
                <ProspectWonQuotesTabListing prospectId={prospectId}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isShow && !isEdit} shouldRemoveFromDom={true}
                             className="prospect-tab__prospect showing">
                <ProspectWonQuoteTabShow prospectId={prospectId}
                                         showId={showId}
                                         onGetData={onGetData}
                                         quoteData={quoteData}
                                         setQuoteData={setQuoteData}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isEdit} shouldRemoveFromDom={true} className="prospect-tab__prospect editing">
                <FormEntityLayout title="affaires"
                                  action="edit"
                                  formRouteName={QUOTE_ITEM_FORM}
                                  submitRouteName={QUOTE_ITEM}
                                  fetchFormParameters={fetchFormParameters}
                                  onGetData={onGetData}
                                  additionalButtons={() => (
                                      <ProspectQuotesRecalculateButton eventForRecalculate={`beforesave:${QUOTE_ITEM}`}/>
                                  )}
                                  whenSubmitted={whenSubmitted}/>
            </ToggleComponent>
        </>
    )
}

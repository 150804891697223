import React, {useState} from 'react'
import {connect} from 'react-redux'
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import AdminDatatables from '../../../components/Admin/_partials/datatables';
import {Redirect} from "react-router-dom";
import FormUser from "../../../components/Admin/_partials/form_user";
import * as moment from "moment";
import {routeSuperAdminManageUser} from "../../../routes";
import {arrowSort} from "../../../components/Datatables/_partials/config";
import {sortFunc} from "../../../api/datas/datas";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { setPopup} from "../../../actions/content";
import PopupInfo from "../../../components/Popup/_popup_info";
import {useRecoilValue} from "recoil";
import {customerIdSelector} from "components/Admin/atoms/customer";

const User = ({t, match, setPopup}) => {
    const [redirect, setRedirect] = useState(undefined);
    const itemId = match && match.params ? match.params.id : null;
    const [isAddForm, setAddForm] = useState(false);
    const [calendarUrl, setCalendarUrl] = useState(null);

    const initColumns = [
        {
            text: t('id'),
            dataField: 'id',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('firstname'),
            dataField: 'firstname',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('lastname'),
            dataField: 'lastname',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('customer'),
            dataField: 'customer.name',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('isActive'),
            dataField: 'isActive',
            formatter: (cellContent, row) => (
                <div className="checkbox disabled">
                    <label>
                        <input type="checkbox" checked={row.isActive} disabled/>
                    </label>
                </div>),
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('lastLogin'),
            dataField: 'lastLogin',
            formatter: (cellContent, row) => row.lastLogin ? moment(row.lastLogin).format('DD/MM/YYYY HH:mm') : '-',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        }
    ];

    const disableCustomerIdFilter = window.location.pathname.search(routeSuperAdminManageUser) !== -1;
    const customerId = useRecoilValue(customerIdSelector);

    const infoTable = {
        url: '/api/user-info',
        deleteUrl: `/api/user-info/{id}${!disableCustomerIdFilter ? `?customerId=${customerId}` : ``}`,
        loader: 'datatable-users'
    };

    return (
        <>
            {redirect}
            <div className="page page-admin content-wrapper">
                <section className={'box'}>
                    <div className={'box__header'}>
                        <p className={'box__header__text'}>{t('administration_user')}</p>
                        {calendarUrl ? (
                        <span onClick={() => {
                            setPopup({
                                isOpen: true,
                                content: (
                                    <PopupInfo
                                        message={`URL Synchronisation Gmail/Outlook : ${process.env.REACT_APP_HOST_API}/api/calendar/${calendarUrl}?v=${Math.floor(Math.random() * (80000 - 10000)) + 10000}`}
                                        onAccept={() => {
                                            setPopup({})
                                        }}
                                        onDecline={() => {
                                            setPopup({})
                                        }
                                        }
                                    />
                                )
                            });
                        }} className={'box__header__agenda'}>
                            <FontAwesomeIcon icon={faCalendar}/>
                        </span>) : null}
                    </div>
                    <div className={'box__body'}>
                        {!itemId && !isAddForm ? (
                            <>
                                <AdminDatatables
                                    info={infoTable}
                                    disableCustomerIdFilter={disableCustomerIdFilter}
                                    columns={initColumns}
                                    changeView={(item) => {
                                        setRedirect(<Redirect
                                            to={disableCustomerIdFilter ? `/administration/super_admin_user_list/${item.id}` : `/administration/user_list/${item.id}`}/>
                                        )
                                    }}/>
                                <div className={'row align-items-center justify-content-end'}>
                                    <div className={`col-12 col-md-auto form__submit`}>
                                        <button className={'btn btn-primary form__submit__btn'}
                                                onClick={() => setAddForm(true)}>
                                            {t('add_user')}
                                        </button>
                                    </div>
                                </div>

                            </>) : (
                            <FormUser calendarUrl={calendarUrl} setCalendarUrl={setCalendarUrl} itemId={itemId}
                                      disableCustomerIdFilter={disableCustomerIdFilter}/>
                        )}
                    </div>
                </section>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    state
});

const mapDispatchToProps = dispatch => {
    return {
        setPopup: (info) => dispatch(setPopup(info)),
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(User));

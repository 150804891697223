import React, {useEffect, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, Redirect} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import {TEMPLATE_ITEM, TEMPLATE_NEW} from "v4/data/apiRoutes";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";

export default function TemplatesForm({customerId, id}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()
    const [{data: templateData, isLoading, isFinished}, fetchTemplate] = useFetch()
    const [{
        data: templateDataSave,
        isError: isErrorSave,
        isLoading: isLoadingSave,
        isFinished: isFinishedSave
    }, fetchTemplateSave] = useFetch()
    const [template, setTemplate] = useState({
        name: null,
        content: null,
        customerId: customerId
    })
    const isEdit = (!!id)
    const [redirect, setRedirect] = useState(null)

    useEffect(() => {
        if (isEdit) {
            fetchTemplate({
                url: apiService.generateUrl(TEMPLATE_ITEM, {id: id}),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (templateData && isFinished) {
            setTemplate(templateData)
        }
    }, [templateData, isFinished])

    const handleChange = (input) => {
        setTemplate({...template, [input.target.name]: input.target.value})
    }

    const handleSave = (e) => {
        e.preventDefault()
        fetchTemplateSave({
            url: isEdit
            ? apiService.generateUrl(TEMPLATE_ITEM, {id: id})
            : apiService.generateUrl(TEMPLATE_NEW),
            config: {
                method: (isEdit ? 'PUT' : 'POST'),
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(template)
            }
        })
    }

    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('template_save_error'))
        }

        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('template_save_done'))
            setRedirect(<Redirect to={routesBase.adminManageTemplates}/>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateDataSave, isErrorSave, isFinishedSave])

    const CardHeaderTools = () => (
        <>
            <Link to={routesBase.adminManageTemplates} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )

    return (
        <AdminCard title={t('manage_invoices_templates')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoading || isLoadingSave) ? <Loader message="Chargement..."/> : null
            }
            <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                <div className="row row-fluid mt-4">
                    <div className={'col-12 field ' + (template?.name ? 'field-written' : null)}>
                        <input type="text" name="name" id="template_name" className="field__input"
                               defaultValue={template?.name} onChange={(input) => handleChange(input)}/>
                        <label className="field__label" htmlFor="template_name">{t('template_name')} *</label>
                    </div>
                    <div className={'col-12 field ' + (template?.content ? 'field-written' : null)}>
                    <textarea name="content" id="template_content" className="field__textarea" rows={16}
                              defaultValue={template?.content} onChange={(input) => handleChange(input)}></textarea>
                        <label className="field__label" htmlFor="template_content">{t('template_content')}</label>
                    </div>
                </div>
            </form>
            {redirect}
        </AdminCard>
    )
}

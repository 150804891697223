export default function getNewViewOrderState(currentViewOrder, result) {
    const {state} = currentViewOrder;
    const newViewOrderState = window.structuredClone(state);

    if (result.type === 'group') {
        const {source, destination} = result;
        const {index: sourceIndex} = source;
        const {index: destinationIndex} = destination;

        const [group] = newViewOrderState.splice(sourceIndex, 1);
        newViewOrderState.splice(destinationIndex, 0, group);

        return newViewOrderState;
    }

    // If result.type === 'field'
    const {source, destination, item} = result;
    const sourceIndex = source.fields.findIndex(({id}) => id === item.id); // Find the index of the field in the source group and not using directly source.index because of search in the default group (the index in DOM is not the same as the index in the view order state)
    const {id: sourceId} = source;
    const {index: destinationIndex, id: destinationId} = destination;

    const sourceGroupIndex = newViewOrderState.findIndex(({id}) => id === sourceId);
    const destinationGroupIndex = newViewOrderState.findIndex(({id}) => id === destinationId);

    if (sourceGroupIndex === destinationGroupIndex) {
        const group = newViewOrderState[sourceGroupIndex];
        const {fields} = group;
        const [field] = fields.splice(sourceIndex, 1);
        fields.splice(destinationIndex, 0, field);
    } else {
        const {fields: sourceFields} = newViewOrderState[sourceGroupIndex];
        const {fields: destinationFields} = newViewOrderState[destinationGroupIndex];
        const [field] = sourceFields.splice(sourceIndex, 1);
        destinationFields.splice(destinationIndex, 0, field);
    }

    return newViewOrderState;
}

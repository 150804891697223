import React from 'react';
import DownloadLink from "v4/components/utils/DownloadLink/DownloadLink";
import {PolIcon} from "../PolIcon/policon";

export const Document = ({doc, index, removeFile}) => {
    return (
        <>
            <div className={`col-12 filepond__container-file ${doc?.isQuotePdf ? 'quote-pdf-file' : ''}`}>
                    <span className={'filepond__container-file-icon'}>
                        <PolIcon icon={'document'}/>
                    </span>
                <span className={'filepond__container-file-title'}>
                        {doc.fileLabel ? doc.fileLabel : doc.realFileName}
                    </span>
                <span>
                        <DownloadLink href={doc.downloadLink} fileName={doc?.fileLabel} className="filepond__container-file-button">
                            <PolIcon icon={'download'}/>
                        </DownloadLink>
                    {(removeFile && doc?.deleteLink) &&
                        <span className={'filepond__container-file-button'} onClick={() => {
                            removeFile(index, doc.deleteLink)
                        }}><PolIcon icon={'bin'}/></span>
                    }
                    </span>
            </div>
        </>
    )
}

import {adminFetchHandler, fetchHandler} from "../datas/datas";
import {setCustomersList} from "../../actions/admin";

export function adminData(info) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');

        return fetch(process.env.REACT_APP_HOST_API + info.url, {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            }
        }).then(
            fetchHandler,
            error => {
                dispatch(adminFetchHandler(error, {}))
            }
        ).then(
            data => {
                if (data) {
                    dispatch(setCustomersList(data['hydra:member']))
                }
            },
            error => {
                dispatch(adminFetchHandler(error, {}))
            }
        );
    };
}

export const getForm = (url) => {
    let token = localStorage.getItem('token');

    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_HOST_API + url, {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
        }).then(
            fetchHandler,
            error => {
                reject(error)
            }
        ).then(
            data => {
                if (data && data.schema['hydra:member'] && data.schema['hydra:member'].length) {
                    resolve(data.schema['hydra:member'][2]);
                } else {
                    reject(500)
                }
            },
            error => {
                reject(error)
            }
        );
    })
}

export const getAdmin = (url, errorCallback, successCallback) => {
    fetch(`${process.env.REACT_APP_HOST_API}${url}`, {
        method: 'GET',
        headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/json',
            "X-POL-AUTH": 'Bearer ' + localStorage.getItem('token')
        },
    }).then(
        fetchHandler,
        error => {
            if (errorCallback) {
                errorCallback(error);
            }
        }
    ).then(
        data => {
            if (successCallback) {
                successCallback(data);
            }
        },
        error => {
            if (errorCallback) {
                errorCallback(error);
            }
        }
    );
}

import {setLoader} from "../../actions/content";
import {errorAjaxHandler, fetchHandler, manageError} from "../datas/datas";
import {setLastVisits} from "../../actions/user";

export function getLastVisits(info) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');
        if(!token){
            return;
        }
        dispatch(setLoader({
            [info.loader]: true
        }));

        return fetch(process.env.REACT_APP_HOST_API + info.url, {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
        }).then(
            fetchHandler,
            error => {
                dispatch(manageError(true, info))
            }
        ).then(
            data => {
                if (data) {
                    dispatch(setLastVisits(data['hydra:member']));

                    dispatch(setLoader({
                        [info.loader]: false
                    }));
                } else {
                    dispatch(manageError(500, info))
                }
            },
            error => {
                errorAjaxHandler(dispatch, info, error);
            }
        );
    };
}

export function setLastVisit(info, data) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');
        let lastVisits = user.lastVisits;
        const index = lastVisits.findIndex(item => item.name === data.name);
        if (index >= 0) {
            lastVisits.splice(index, 1);
            dispatch(setLastVisits(lastVisits));
        }

        return fetch(process.env.REACT_APP_HOST_API + info.url, {
            method: 'POST',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
            body: JSON.stringify(data),
        }).then(
            fetchHandler,
            error => {
                dispatch(manageError(true, info))
            }
        ).then(
            data => {
                if (!data) {
                    dispatch(manageError(500, info))
                }
            },
            error => {
                dispatch(manageError(error.status, info))
            }
        );
    };
}
import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import ReactTooltip from 'react-tooltip';
import Confirm from "v4/components/ui/Confirm/Confirm";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import useConfiguredActionButton
    from "v4/features/front/configuredActions/components/ConfiguredActionButton/hooks/useConfiguredActionButton";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ConfiguredActionButton({action, entityId, prospectId, popupTitle, classNamePrefix, btnClassName, isGroup}) {
    const {t} = usePolTranslation();
    const {
        key,
        icon = '',
        label = '',
        title = '',
        confirmBeforeMessage = '',
    } = action;

    const [
        isOpen,
        toggleOpen,
        handleAction,
        components,
    ] = useConfiguredActionButton(action, entityId, prospectId);

    if (isGroup) return (
        <li className={`${classNamePrefix}__group__list__line`}>
            <div onClick={toggleOpen}>
                {icon.length > 0 && <PolIcon icon={icon}/>}
                {title.length > 0 && <span>{t(title)}</span>}
            </div>
            {
                components
                    ? (
                        <PolPopup isOpen={isOpen}
                                  closePopup={toggleOpen}
                                  title={popupTitle}
                                  classNames={{overlay: 'no-group-click'}}
                                  buttonsRender={() => components.buttons}>
                            {components.children}
                        </PolPopup>
                    )
                    : (
                        <Confirm isOpen={isOpen}
                                 cancelAction={toggleOpen}
                                 confirmAction={handleAction}
                                 classNames={{overlay: 'no-group-click'}}>
                            <p>
                                {
                                    confirmBeforeMessage?.length > 0
                                        ? t(confirmBeforeMessage)
                                        : t('confirm-action')
                                }
                            </p>
                        </Confirm>
                    )
            }
        </li>
    )

    return (
        <>
            <button className={`${classNamePrefix}-btn ${btnClassName}`}
                    data-for={key}
                    data-tip={t(title)}
                    onClick={toggleOpen}>
                {icon.length > 0 && <PolIcon icon={icon}/>}
                {label.length > 0 && <span>{t(label)}</span>}
            </button>
            <ReactTooltip id={key} effect="solid"/>
            {
                components
                    ? (
                        <PolPopup isOpen={isOpen}
                                  closePopup={toggleOpen}
                                  title={popupTitle}
                                  buttonsRender={() => components.buttons}>
                            {components.children}
                        </PolPopup>
                    )
                    : (
                        <Confirm isOpen={isOpen}
                                 cancelAction={toggleOpen}
                                 confirmAction={handleAction}>
                            <p>
                                {
                                    confirmBeforeMessage?.length > 0
                                        ? t(confirmBeforeMessage)
                                        : t('confirm-action')
                                }
                            </p>
                        </Confirm>
                    )
            }
        </>
    )
}

import React, {useEffect, useState} from 'react'
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {useRecoilState} from "recoil";
import {formDataState, specificFieldsState} from "../atoms/importAdmin";
import {handleFormData} from "../../../reducers/form";

const ImportFieldsAvailable = ({fieldsAvailable, formValues, t}) => {
    let [options, setOptions] = useState({});
    const entityToImport = formValues.entityToImport
    const [, setSpecificFields] = useRecoilState(specificFieldsState)
    const [, setFormData] = useRecoilState(formDataState)

    useEffect(() => {
        if (Object.keys(options).length === 0) {
            // eslint-disable-next-line
            options = fieldsAvailable.length ? JSON.parse(fieldsAvailable) : {}
            setOptions(options)
        }

        if (options && options.specificFields && options.forms && entityToImport) {
            setSpecificFields(options.specificFields[entityToImport]);
            setFormData(handleFormData(options.forms[entityToImport].properties))
        }

    }, [fieldsAvailable, formValues]);

    return (
        <div className={'admin__field__info'}>
            <span className={'admin-info-mt-15 admin-info'}/>
            <div className={'admin__field'}>
                {Object.keys(options).length && options.items && options.items[entityToImport]
                    ? (
                        <div className={'row'}>
                            <div className={'col-sm-12 col-md-6'}>
                                {options.items[entityToImport]['contact'] ? (
                                    <p className={"admin__field__entity"}>{t('import_table_prospect')}</p>) : null}
                                {Object.keys(options.items[entityToImport]).map((key) => {
                                    const object = options.items[entityToImport];
                                    if (key === 'contact') {
                                        return ''
                                    }
                                    let text = object[key];
                                    const keyTrad = text.split(' ').pop();
                                    const index = text.split(' ').shift();
                                    const finalText = keyTrad === '(livraison)' ? `${t(index)} ${keyTrad}` : t(text);
                                    return (
                                        <React.Fragment key={key}>
                                            <p key={key}
                                               className={`${options.unique[entityToImport].indexOf(object[key]) !== -1 ? 'admin__field__unique' : ''}`}>{`${finalText} 
                ${options.required[entityToImport].indexOf(object[key]) !== -1 ? '*' : ''}`}
                                                {key === 'managedBy' ? <span
                                                    className={'admin__field__contact'}> {t('managedBy_import')}</span> : ''}
                                                {key === 'company_naf' ? <span
                                                    className={'admin__field__contact'}> {t('naf_import')}</span> : ''}
                                            </p>
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            {options.items[entityToImport]['contact'] ? (<div className={'col-sm-12 col-md-6'}>
                                <p className={"admin__field__entity"}>{t('import_table_contact')}</p>
                                <p className={"admin__field__contact"}>Contact_<strong>x</strong> {t('import_contact_field_name')}, <strong>x</strong>
                                    {t('import_contact_can_go_to')}</p>
                                {Object.keys(options.items[entityToImport]['contact']).map((key) => {
                                    const object = options.items[entityToImport];
                                    let text = object['contact'][key];
                                    const keyTrad = text.split(' ').pop();
                                    const index = text.split(' ').shift();
                                    return <p key={key}>{`${index} ${t(keyTrad)}`}</p>
                                })}
                            </div>) : null}
                        </div>) : null}
            </div>
        </div>
    )
}


export default (withPolTranslation(React.memo(ImportFieldsAvailable)));

import * as CryptoJS from 'crypto-js';
import UserService from '../api/user/user';

export const loginMercureHandler = (parsedMessage, logOut, t, setAppError) => {
    const hash = CryptoJS.SHA256(UserService.getTokens().token).toString(CryptoJS.enc.Hex);

    if (UserService.getTokens().token && hash !== parsedMessage.hash && parsedMessage.forceLogout) {
        logOut()
        setAppError(t('logout_mercure'))
    }
}

export const disableUserMercureHandler = (logOut, t, setAppError) => {
    logOut()
    setAppError(t('disable_user_mercure'))
}

export const logoutUserWithMessage = (logOut, t, setAppError) => {
    logOut()
    setAppError(t('logout_roles_change'))
}

export const importProgressHandler = (parsedMessage, setImportProgress) => {
    setImportProgress(parsedMessage)
}

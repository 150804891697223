import React, {useMemo, useEffect, useState, useCallback} from 'react';
import Table from "v4/components/ui/Table/Table";
import {Link} from "react-router-dom";
import {routesBase} from 'v4/data/appRoutes'
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import apiService from "v4/services/api.service";
import {
    PRODUCT_ATTRIBUTE_LIST,
} from "v4/data/apiRoutes";
import {PolIcon} from "components/PolIcon/policon";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import useNotification from "v4/hooks/useNotification";
import ProductAttributesListDelete
    from "v4/pages/admin/ProductAttributes/ProductAttributesDelete/ProductAttributesListDelete";
export default function ProductAttributesList({customerId}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()
    // Listing
    const [{
        data: loadedProductAttributes,
        isLoading: loadedProductAttributesLoading,
        isError: loadedProductAttributesError
    }, fetchProductAttributes] = useFetch();
    const [refetch, setRefetch] = useState(0)
    useEffect(() => {
        fetchProductAttributes({
            url: apiService.generateUrl(PRODUCT_ATTRIBUTE_LIST, {
                _sort_field: "position",
                _sort_order: "asc",
                customerId: customerId
            }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])
    const onDeleteSuccess = useCallback(() => {
        addSuccessNotification(t('delete_product_attribute_done'))
        setRefetch(c => c + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])
    const onDeleteError = useCallback(() => {
        addErrorNotification(t('delete_product_attribute_error'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const hydrateProductAttributeRows = useCallback(() => {
        if (!loadedProductAttributes?.['hydra:member']) {
            return null
        }
        const rows = JSON.parse(JSON.stringify(loadedProductAttributes['hydra:member']))
        return (
            rows.map((item, index) => {
                return {
                    label: item.label,
                    type: item.type,
                    groupCode: item.groupCode ?? '-',
                    position: item.position,
                    defaultValueField: item.defaultValueField ?? '-',
                    product_configuration: item.productConfigurations.map((configuration) => {
                        return configuration.label
                    }).join(' ; '),
                    actions: (
                        <ul className="datatable__actions">
                            <Link key={index} to={`${routesBase.adminProductAttributes}/edit/${item.id}`}>
                                <li className="datatable__actions__item">
                                    <span className="datatable__actions__item__icon">
                                        <PolIcon icon='edit'/>
                                    </span>
                                </li>
                            </Link>
                            <ProductAttributesListDelete item={item} onSuccessCallback={onDeleteSuccess}
                                                     onErrorCallBack={onDeleteError}/>
                        </ul>
                    )
                };
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedProductAttributes])
    const tableList = useMemo(() => {
        return <Table tableRows={hydrateProductAttributeRows()} isLoading={loadedProductAttributesLoading}
                      isError={loadedProductAttributesError}
                      theadData={Object.keys({label: 'label', product_attribute_type: 'type', groupCode: 'groupCode', position: 'position', default_value_field: 'default_value_field', product_configuration: 'product_configuration', actions: 'actions'})}/>
    }, [hydrateProductAttributeRows, loadedProductAttributesLoading, loadedProductAttributesError])
    const CardHeaderTools = () => (
        <Link to={`${routesBase.adminProductAttributes}/add`}
              className="btn btn-primary">{t('add_new_product_attribute')}</Link>
    )
    return (
        <AdminCard title={t('manage_product_attributes')} CardHeaderTools={CardHeaderTools}>
            {tableList}
        </AdminCard>
    )
}
import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import CountUp from "react-countup";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import DashboardQuotesListingTable
    from "v4/pages/front/Dashboard/components/DashboardQuotesListing/components/DashboardQuotesListingTable/DashboardQuotesListingTable";
import useDashboardCounters from "v4/pages/front/Dashboard/hooks/useDashboardCounters";
import useTabToggle from "v4/pages/front/Dashboard/hooks/useTabToggle";
import {financial, getVarsCounter} from "v4/utils";

const CURRENT_QUOTES_TAB_KEY = 'current_quotes';
const SIGNED_QUOTES_TAB_KEY = 'signed_quotes';

const tabs = [
    CURRENT_QUOTES_TAB_KEY,
    SIGNED_QUOTES_TAB_KEY,
];

const tabIcons = {
    [CURRENT_QUOTES_TAB_KEY]: 'file-invoice-dollar',
    [SIGNED_QUOTES_TAB_KEY]: 'file-contract',
}

export default function DashboardQuotesListing() {
    const {t} = usePolTranslation();
    const [activeTabKey, setActiveTabKey] = useTabToggle(CURRENT_QUOTES_TAB_KEY);
    const [counters, setCounter] = useDashboardCounters(tabs);

    return (
        <div className="dashboard-listing quotes">
            <div className="dashboard-listing__counters">
                {tabs.map((tabKey, nth) => (
                    <div key={tabKey}
                         className="dashboard-listing__counter"
                         style={getVarsCounter(nth, 66)}>
                        <CountUp className="dashboard-listing__counter__number"
                                 start={0}
                                 end={counters?.[tabKey]?.totalAmount ?? 0}
                                 duration={1}
                                 formattingFn={value => financial(value, false)}
                        />
                        <div className="dashboard-listing__counter__name">
                            {t(tabKey)} ({counters?.[tabKey]?.totalItems})
                        </div>
                        <PolIcon icon={tabIcons?.[tabKey] ?? 'circle-notch'} className="dashboard-listing__counter__icon"/>
                    </div>
                ))}
            </div>
            <div className="dashboard-listing__tabs">
                {tabs.map((tabKey, nth) => (
                    <div key={tabKey}
                         className={`dashboard-listing__tab ${activeTabKey === tabKey ? 'active' : ''}`}
                         onClick={setActiveTabKey(tabKey)}
                         style={getVarsCounter(nth, 66)}>
                        <span>{t(tabKey)}</span>
                    </div>
                ))}
            </div>
            <div className="dashboard-listing__listing">
                {tabs.map((tabKey, nth) => (
                    <SearchProvider key={tabKey} pageType={tabKey}>
                        <DashboardQuotesListingTable tabKey={tabKey}
                                                     activeTabKey={activeTabKey}
                                                     setCounter={setCounter}
                                                     styleVars={getVarsCounter(nth, 66)}/>
                    </SearchProvider>
                ))}
            </div>
        </div>
    )
}

export default function useSearchDispatchers(dispatch, state) {
    const setSearchbar = (search) => {
        dispatch({type: 'SET_SEARCHBAR', payload: search});
    }

    const initPagesWithTotalItems = (totalItems, forceItemsPerPage) => {
        if (totalItems !== null && !isNaN(totalItems)) {
            const maxPage = Math.ceil(totalItems / (forceItemsPerPage ?? state.itemsPerPage));
            dispatch({type: 'SET_PAGES', payload: maxPage > 0 ? maxPage : 1});
        }
    }

    const setCurrentPage = (pageToGo) => {
        if (pageToGo > 0 && pageToGo <= state.pages) {
            dispatch({type: 'SET_CURRENT_PAGE', payload: pageToGo});
        }
    }

    const setSortField = (sortField) => {
        dispatch({type: 'SET_SORT_FIELD', payload: sortField});
    }

    const setSortOrder = (sortOrder) => {
        dispatch({type: 'SET_SORT_ORDER', payload: sortOrder});
    }

    const setSort = ({sortField, sortOrder}) => {
        dispatch({type: 'SET_SORT', payload: {sortField, sortOrder}});
    }

    const setItemsPerPage = (value) => {
        dispatch({type: 'SET_ITEMS_PER_PAGE', payload: value});
    }

    const setFormReady = (bool) => {
        dispatch({type: 'SET_FORM_READY', payload: bool});
    }

    return {
        setSearchbar,
        initPagesWithTotalItems,
        setCurrentPage,
        setSortField,
        setSortOrder,
        setSort,
        setItemsPerPage,
        setFormReady,
    }
}

import React, {useRef, useState} from 'react';
import Confirm from "v4/components/ui/Confirm/Confirm";
import Loader from "v4/components/ui/Loader/Loader";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ProspectContactsMoveContactForm
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectContactsMoveContact/components/ProspectContactsMoveContactForm/ProspectContactsMoveContactForm";
import useMoveContact
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectContactsMoveContact/hooks/useMoveContact";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectContactsMoveContact/ProspectContactsMoveContact.scss';

export default function ProspectContactsMoveContact({contactIdToMove, closePopup}) {
    const {t} = usePolTranslation();

    const [inputs, isLoading] = useMoveContact(contactIdToMove);
    const formRef = useRef(null);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    const submitForm = () => {
        formRef.current.dispatchEvent(new Event('submit'));
    }

    return (
        <Confirm title={t('move_contact')}
                 isOpen={true}
                 isLoading={isSubmitLoading}
                 yesLabel={t('move')}
                 noLabel={t('cancel')}
                 cancelAction={closePopup}
                 confirmAction={!isLoading ? submitForm : undefined}
                 classNames={{
                     content: 'move-contact-popup__content',
                     btnConfirm: 'move-contact-popup__btn-confirm'
                 }}>
            <p>{t('move_contact_confirm')}</p>
            {isLoading && <Loader className="move-contact-popup__loader"/>}
            {inputs && <ProspectContactsMoveContactForm inputs={inputs}
                                                        contactId={contactIdToMove}
                                                        setIsSubmitLoading={setIsSubmitLoading}
                                                        closePopup={closePopup}
                                                        ref={formRef}/>}
        </Confirm>
    )
}

import React, {useCallback} from 'react';
import UserService from "api/user/user";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";

/**
 * @returns {Object} {t} - Alternative translation function
 */
export function usePolTranslation() {
    const {t: loco} = useTranslation();

    /* Récupération des clés alternatives de l'utilisateur */
    const alternativeTranslations = UserService.getAlternativeTranslations();

    /* À partir des pages onglets, on peut récupérer le slug de l'onglet */
    const {slug, tabName} = useParams();

    /* À partir de la page COMPTES PROSPECTS / CLIENTS, je récupère le slug de l'onglet dans l'URL */
    const location = useLocation();
    const splittedPathname = location.pathname.split('/');
    const indexSlug = splittedPathname.some(item => ["onglet", "onglets"].includes(item)) && (splittedPathname.findIndex((item) => ["onglet", "onglets"].includes(item)) + 1);

    /* Si il ne trouve pas de slug, il n'est pas dans un onglet, c'est donc la clé default qui est appliquée */
    const tabSlug = slug ?? tabName ?? splittedPathname?.[indexSlug] ?? "default";

    const t = useCallback((label, options = {}, disableAlternativeTranslation = false) => {
        let alternativeTranslation = alternativeTranslations?.[tabSlug]?.[label];

        if (!alternativeTranslation || disableAlternativeTranslation) return loco(label, options);

        /* Permet de remplacer les {{count}}, par exemple, par la valeur envoyée en option, comme loco */
        Object.entries(options).forEach(([key, value]) => {
            alternativeTranslation = alternativeTranslation.replace(`{{${key}}}`, value)
        });

        return alternativeTranslation;
    }, [alternativeTranslations, loco, tabSlug]);

    return {t};
}


/**
 * @param {function} Component - React component
 * @return {function} - React component with **t** translation function in props
 */
export function withPolTranslation(Component) {
    return React.memo(
        function PolTranslationWrapper(props) {
            const {t} = usePolTranslation();
            return <Component {...props} t={t}/>;
        }
    );
}

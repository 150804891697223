import {useCallback, useState} from 'react';

export default function useDashboardCounters(tabsKeys) {
    const [counters, setCounters] = useState(() => (
        tabsKeys.reduce((acc, tabKey) => ({
            ...acc,
            [tabKey]: {
                totalItems: 0
            }
        }), {})
    ));

    const updateCounter = useCallback((tabKey, value) => {
        setCounters(counters => ({
            ...counters,
            [tabKey]: value
        }));
    }, []);

    return [counters, updateCounter];
}

import React, {Component, Fragment} from 'react'
import {routesBase} from "v4/data/appRoutes";
import {Redirect} from 'react-router-dom'
import {formatForView} from "../../../utils/viewTypeFormatter";
import {withPolTranslation} from "../../../v4/hooks/usePolTranslation";

class TableQuote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
        };
    }

    handleClick = (isEnable, preSearchType, datas, monthIndex) => {
        if (!isEnable) return;

        const {quotesStatus = {}} = this.props;
        const {managedBy = ''} = datas?.[0] ?? {};

        const preSearch = {};
        if (managedBy !== '') {
            preSearch['managedBy'] = [managedBy];
        }
        switch (preSearchType) {
            case 'wons':
                preSearch['status'] = quotesStatus.isWon ?? [];
                preSearch['expectedSignedAt'] = {
                    "equal": ">",
                    "begin": "01-01-" + new Date().getFullYear(),
                    "end": ""
                };
                break;
            case 'abandoneds':
                preSearch['status'] = quotesStatus.isAbandoned ?? [];
                preSearch['expectedSignedAt'] = {
                    "equal": ">",
                    "begin": "01-01-" + new Date().getFullYear(),
                    "end": ""
                };
                break;
            case 'losts':
                preSearch['status'] = quotesStatus.isLost ?? [];
                preSearch['expectedSignedAt'] = {
                    "equal": ">",
                    "begin": "01-01-" + new Date().getFullYear(),
                    "end": ""
                };
                break;
            case 'lates':
                preSearch['status'] = quotesStatus.inProgress ?? [];
                preSearch['expectedSignedAt'] = {
                    "equal": "<",
                    "begin": "",
                    "end": new Date().toLocaleDateString('fr-FR').replaceAll('/', '-')
                };
                break;
            case 'months':
                preSearch['status'] = quotesStatus.inProgress ?? [];
                preSearch['expectedSignedAt'] = {
                    "equal": "><",
                    "begin": new Date(new Date().getFullYear(), monthIndex - 1, 1).toLocaleDateString('fr-FR').replaceAll('/', '-'),
                    "end": new Date(new Date().getFullYear(), monthIndex, 0).toLocaleDateString('fr-FR').replaceAll('/', '-')
                };
                break;
            default:
                break;
        }

        this.setState({
            redirect: <Redirect
                to={{
                    pathname: routesBase.baseQuote,
                    state: {preSearch: preSearch}
                }}
            />
        });
    }

    render() {
        const {t, tableQuotes} = this.props;
        const {redirect} = this.state;

        return (
            <div className={'table-stat quote'}>
                {redirect}
                <p className={'col-md-2 col-sm-4 col-6 table-stat__title'}>{t('quotes_results')}</p>
                <table className={'table-stat__table'}>
                    <thead className={'table-stat__header'}>
                    <tr>
                        <th/>
                        <th colSpan={6}>{t('since_january')}</th>
                        <th colSpan={tableQuotes && tableQuotes['remaining_months'] && tableQuotes['remaining_months'].length ?
                            (tableQuotes['remaining_months'].length * 2 + 2) : 13}>{t('current_quotes')}</th>
                    </tr>
                    <tr>
                        <th/>
                        <th colSpan={2}>{t('won_label')}</th>
                        <th colSpan={2}>{t('abandoned_label')}</th>
                        <th colSpan={2}>{t('lost_label')}</th>
                        <th colSpan={2}>{t('late_label')}</th>
                        {tableQuotes ? tableQuotes['remaining_months'].map(month => {
                                return (
                                    <th colSpan={2} key={month.label}>{t(month.label)}</th>
                                )
                            })
                            : null}
                    </tr>
                    </thead>
                    <tbody className={'table-stat__body'}>
                    <>
                        {(tableQuotes && tableQuotes['data']) ?
                            <>
                                {tableQuotes['data'].map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{item.user}</td>
                                            <td className={'table-stat__link'}
                                                onClick={() => this.handleClick(item['wons']['total_euro'] > 0, "wons", item['wons']['quotes'])}>{item['wons']['total_euro'] > 0 ? <span className="table-stat__link-money">{formatForView(item['wons']['total_euro'], 'money')}</span> : '-'}</td>
                                            <td className={'table-stat__link'}
                                                onClick={() => this.handleClick(item['wons']['quotes'].length > 0, "wons", item['wons']['quotes'])}>{item['wons']['quotes'].length > 0 ? item['wons']['quotes'].length : '-'}</td>

                                            <td className={'table-stat__link'}
                                                onClick={() => this.handleClick(item['abandoneds']['total_euro'] > 0, "abandoneds", item['abandoneds']['quotes'])}>{item['abandoneds']['total_euro'] > 0 ? <span className="table-stat__link-money">{formatForView(item['abandoneds']['total_euro'], 'money')}</span> : '-'}</td>
                                            <td className={'table-stat__link'}
                                                onClick={() => this.handleClick(item['abandoneds']['quotes'].length > 0, "abandoneds", item['abandoneds']['quotes'])}>{item['abandoneds']['quotes'].length > 0 ? item['abandoneds']['quotes'].length : '-'}</td>

                                            <td className={'table-stat__link'}
                                                onClick={() => this.handleClick(item['losts']['total_euro'] > 0, "losts", item['losts']['quotes'])}>{item['losts']['total_euro'] > 0 ? <span className="table-stat__link-money">{formatForView(item['losts']['total_euro'], 'money')}</span> : '-'}</td>
                                            <td className={'table-stat__link'}
                                                onClick={() => this.handleClick(item['losts']['quotes'].length > 0, "losts", item['losts']['quotes'])}>{item['losts']['quotes'].length > 0 ? item['losts']['quotes'].length : '-'}</td>

                                            <td className={'table-stat__link'}
                                                onClick={() => this.handleClick(item['lates']['total_euro'] > 0, "lates", item['lates']['quotes'])}>{item['lates']['total_euro'] > 0 ? <span className="table-stat__link-money">{formatForView(item['lates']['total_euro'], 'money')}</span> : '-'}</td>
                                            <td className={'table-stat__link'}
                                                onClick={() => this.handleClick(item['lates']['quotes'].length > 0, "lates", item['lates']['quotes'])}>{item['lates']['quotes'].length > 0 ? item['lates']['quotes'].length : '-'}</td>

                                            {tableQuotes['remaining_months'].map((month) => {
                                                return (
                                                    <Fragment key={`1 ${month.index}`}>
                                                        {item['months'] && item['months'][month.index]
                                                            ? (
                                                                <>
                                                                    <td className={'table-stat__link'}
                                                                        onClick={() => this.handleClick(item['months'][month.index]['total_euro'] > 0, "months", item['months'][month.index]['quotes'], month.index)}>
                                                                        {item['months'][month.index]['total_euro'] > 0
                                                                            ? (
                                                                                <span className="table-stat__link-money">
                                                                                    {formatForView(item['months'][month.index]['total_euro'], 'money')}
                                                                                </span>
                                                                            )
                                                                            : '-'
                                                                        }
                                                                    </td>
                                                                    <td className={'table-stat__link'}
                                                                        onClick={() => this.handleClick(item['months'][month.index]['quotes'].length > 0, "months", item['months'][month.index]['quotes'], month.index)}>
                                                                        {item['months'][month.index]['quotes'].length > 0
                                                                            ? item['months'][month.index]['quotes'].length
                                                                            : '-'
                                                                        }
                                                                    </td>
                                                                </>
                                                            )
                                                            : (
                                                                <>
                                                                    <td className={'table-stat__link'}>-</td>
                                                                    <td className={'table-stat__link'}>-</td>
                                                                </>
                                                            )
                                                        }
                                                    </Fragment>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </>
                            : <tr>
                                <td>{t('loading_data')}</td>
                            </tr>}

                        {tableQuotes ?
                            <>
                                <tr className={'table-stat__body__total'}>
                                    <td>{t('total_euro')}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['wons']['total_euro'] > 0, 'wons')}>{tableQuotes['totals']['wons']['total_euro'] > 0 ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals']['wons']['total_euro'], 'money')}</span> : '-'}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['wons']['total_nb'] > 0, 'wons')}>{tableQuotes['totals']['wons']['total_nb'] > 0 ? tableQuotes['totals']['wons']['total_nb'] : '-'}</td>

                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['abandoneds']['total_euro'], 'abandoneds')}>{tableQuotes['totals']['abandoneds']['total_euro'] ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals']['abandoneds']['total_euro'], 'money')}</span> : '-'}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['abandoneds']['total_nb'] > 0, 'abandoneds')}>{tableQuotes['totals']['abandoneds']['total_nb'] > 0 ? tableQuotes['totals']['abandoneds']['total_nb'] : '-'}</td>

                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['losts']['total_euro'], 'losts')}>{tableQuotes['totals']['losts']['total_euro'] ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals']['losts']['total_euro'], 'money')}</span> : '-'}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['losts']['total_nb'], 'losts')}>{tableQuotes['totals']['losts']['total_nb'] ? tableQuotes['totals']['losts']['total_nb'] : '-'}</td>

                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['lates']['total_euro'] > 0, 'lates')}>{tableQuotes['totals']['lates']['total_euro'] > 0 ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals']['lates']['total_euro'], 'money')}</span> : '-'}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['lates']['total_nb'] > 0, 'lates')}>{tableQuotes['totals']['lates']['total_nb'] > 0 ? tableQuotes['totals']['lates']['total_nb'] : '-'}</td>

                                    {tableQuotes['remaining_months'].map((month) => {
                                        return (
                                            <React.Fragment key={"2" + month.index}>
                                                <td className={'table-stat__link'}
                                                    onClick={() => this.handleClick(tableQuotes['totals'][month.index]['total_euro'] > 0, 'months' , [], month.index)}>{tableQuotes['totals'][month.index]['total_euro'] > 0 ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals'][month.index]['total_euro'], 'money')}</span> : '-'}</td>
                                                <td className={'table-stat__link'}
                                                    onClick={() => this.handleClick(tableQuotes['totals'][month.index]['total_nb'] > 0, 'months' , [], month.index)}>{tableQuotes['totals'][month.index]['total_nb'] > 0 ? tableQuotes['totals'][month.index]['total_nb'] : '-'}</td>
                                            </React.Fragment>
                                        )
                                    })}
                                </tr>

                                <tr className={'table-stat__body__total'}>
                                    <td>{t('total_ponderate')}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['wons']['total_ponderate'] > 0, 'wons')}>{tableQuotes['totals']['wons']['total_ponderate'] > 0 ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals']['wons']['total_ponderate'], 'money')}</span> : '-'}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(true, 'wons')}>{''}</td>

                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['abandoneds']['total_ponderate'] > 0, 'abandoneds')}>{tableQuotes['totals']['abandoneds']['total_ponderate'] > 0 ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals']['abandoneds']['total_ponderate'], 'money')}</span> : '-'}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(true, 'abandoneds')}>{''}</td>

                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['losts']['total_ponderate'] > 0, 'losts')}>{tableQuotes['totals']['losts']['total_ponderate'] > 0 ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals']['losts']['total_ponderate'], 'money')}</span> : '-'}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(true, 'losts')}>{''}</td>

                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(tableQuotes['totals']['lates']['total_ponderate'] > 0, 'lates')}>{tableQuotes['totals']['lates']['total_ponderate'] > 0 ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals']['lates']['total_ponderate'], 'money')}</span> : '-'}</td>
                                    <td className={'table-stat__link'}
                                        onClick={() => this.handleClick(true, 'lates')}>{''}</td>

                                    {tableQuotes['remaining_months'].map((month) => {
                                        return (
                                            <React.Fragment key={"3" + month.index}>
                                                <td className={'table-stat__link'}
                                                    onClick={() => this.handleClick(tableQuotes['totals'][month.index]['total_ponderate'] > 0, 'months' , [], month.index)}>{tableQuotes['totals'][month.index]['total_ponderate'] > 0 ? <span className="table-stat__link-money">{formatForView(tableQuotes['totals'][month.index]['total_ponderate'], 'money')}</span> : '-'}</td>
                                                <td className={'table-stat__link'}
                                                    onClick={() => this.handleClick(true, 'months' , [], month.index)}>{''}</td>
                                            </React.Fragment>
                                        )
                                    })}
                                </tr>
                            </>
                            :
                            <>
                                <tr className={'table-stat__body__total'}>
                                    <td>{t('total_euro')}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                </tr>
                                <tr className={'table-stat__body__total'}>
                                    <td>{t('total_ponderate')}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                    <td>{''}</td>
                                </tr>
                            </>
                        }

                    </>
                    </tbody>
                </table>
            </div>
        )
    }
}


export default withPolTranslation(TableQuote);

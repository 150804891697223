export function devideSavedSearch(savedSearch, shouldClearSearch = false) {
    const {searchbar = '', chainedSearch = null, ...search} = JSON.parse(savedSearch ?? '{}');
    return {
        searchbar,
        chainedSearch,
        search: shouldClearSearch ? clearUnusedFieldsOfSearch(search) : search,
    }
}

export function clearUnusedFieldsOfSearch(search) {
    const newSearch = {...search};

    for (const key in newSearch) {
        const type = typeof newSearch[key];
        const value = newSearch[key];
        if (
            value === undefined ||
            (type === 'string' && value === '') ||
            (type === 'boolean' && value === false) ||
            (type === 'object' && value === null) ||
            (type === 'object' && value.length === 0) ||
            (type === 'object' && Object.keys(value).length === 0) ||
            (type === 'object' && value.begin === '' && value.end === '') ||
            (type === 'boolean' && value === false)
        ) {
            delete newSearch[key];
        }
    }

    return newSearch;
}

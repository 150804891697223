import React, {useCallback, useState} from 'react';
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import ProspectQuotesDuplicate
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesDuplicate/ProspectQuotesDuplicate";
import ProspectQuotesTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesTabPartial/ProspectQuotesTabPartial";
import ProspectWonQuotesTabRowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/components/ProspectWonQuotesTabRowActions/ProspectWonQuotesTabRowActions";

const fetchParams = {
    'status.isWon': true,
}

export default function ProspectWonQuotesTabListing({prospectId}) {
    const [rowIdToDuplicate, setRowIdToDuplicate] = useState(null);

    const handleDuplicate = (rowId) => () => setRowIdToDuplicate(rowId);

    const closeDuplicatePopup = useCallback(() => setRowIdToDuplicate(null), [setRowIdToDuplicate]);

    return (
        <>
            <div className="prospect-tab__listing__wrapper">
                <ProspectHeadingTabPartial title="affaires"/>
                <ProspectQuotesTabPartial fetchParams={fetchParams}
                                          prospectId={prospectId}
                                          rowActions={<ProspectWonQuotesTabRowActions onDuplicate={handleDuplicate}/>}/>
            </div>

            <ToggleComponent isShowing={rowIdToDuplicate} shouldRemoveFromDom={true} className="duplicate-popup">
                <ProspectQuotesDuplicate rowIdToDuplicate={rowIdToDuplicate} closePopup={closeDuplicatePopup}/>
            </ToggleComponent>
        </>
    )
}

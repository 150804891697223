import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import useViewOrdersButtonListHover
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/hooks/useViewOrdersButtonListHover";
import 'v4/pages/admin/ViewOrders/components/ViewOrdersButtons/partials/ViewOrdersButtonListHover.scss';
import ViewOrdersButtonListHover
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/partials/ViewOrdersButtonListHover";
import getAvailableWidths from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/utils/getAvailableWidths";
import getIconForWidth from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/utils/getIconForWidth";
import ReactTooltip from "react-tooltip";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ViewOrdersAddGroupButton({group, isAfter}) {
    const {t} = usePolTranslation();
    const {addGroup} = useViewOrdersContext();

    const availableWidths = getAvailableWidths(group.width);
    const {
        isActionOpen,
        toggleIsActionOpen,
        shouldOpenTop,
        containerRef,
    } = useViewOrdersButtonListHover(availableWidths);

    const handleClick = (width) => addGroup(group.id, isAfter, width)

    return (
        <div className="vo-action__list-hover__container"
             ref={containerRef}
             onMouseLeave={() => toggleIsActionOpen(false)}>
            <button className="vo-action__button vo-action__add-group__button"
                    data-for={`add-group-tooltip${group.id}${+isAfter}`}
                    data-tip={t('add_group')}
                    onClick={toggleIsActionOpen}>
                <PolIcon icon="add-fine"/>
            </button>
            <ViewOrdersButtonListHover isActionOpen={isActionOpen}
                                       shouldOpenTop={shouldOpenTop}
                                       list={availableWidths}
                                       titleCallback={width => `${width}%`}
                                       itemClassName="square"
                                       renderItem={width => <PolIcon icon={getIconForWidth(width)}/>}
                                       onClick={handleClick}/>
            <ReactTooltip id={`add-group-tooltip${group.id}${+isAfter}`}
                          place={isAfter ? 'left' : 'right'}
                          className="text-nowrap"
                          effect="solid"/>
        </div>
    )
}

import useFetch from "v4/hooks/useFetch";
import useToggle from "v4/hooks/useToggle";
import {generateUrl} from "v4/services/api.service";
import {AUTOMATED_STATUS_ITEM, AUTOMATED_STATUS_LIST, AUTOMATED_STATUS_NEW} from "v4/data/apiRoutes";
import {useContext, useEffect, useState} from "react";
import {AdminContext} from "v4/contexts/AdminContext";

export default function useAutomatedStatus() {
    const [{data, isLoading}, fetchAutomatedStatus] = useFetch();
    const [{isError, isFinished, errorData}, fetchSaveForm] = useFetch();
    const [submitErrors, setSubmitErrors] = useState(errorData?.errors ?? null);
    const [{isFinished: isDeleted, isError: isDeleteError}, fetchDelete] = useFetch();
    const [isPopupOpen, togglePopup] = useToggle();
    const [isConfirmOpen, toggleConfirm] = useToggle();
    const [refresh, toggleRefresh] = useToggle();
    const [idToDelete, setIdToDelete] = useState(null);
    const [idToEdit, setIdToEdit] = useState(null);
    const {asCustomer} = useContext(AdminContext);

    const automatedStatus = data?.['hydra:member'];

    useEffect(() => {
        fetchAutomatedStatus({
            url: generateUrl(AUTOMATED_STATUS_LIST, {...(asCustomer && {customerId: asCustomer.id})})
        });
    }, [asCustomer, fetchAutomatedStatus, refresh]);


    const onClickAdd = () => {
        setIdToEdit(null);
        setSubmitErrors(null);
        togglePopup();
    }

    useEffect(() => {
        if (isError && errorData?.errors) {
            setSubmitErrors(errorData.errors);
        }
    }, [errorData, isError])

    const onSubmit = (data) => {
        fetchSaveForm({
            url: generateUrl(idToEdit ? AUTOMATED_STATUS_ITEM : AUTOMATED_STATUS_NEW, {
                ...(idToEdit && {id: idToEdit}),
                ...(asCustomer && {customerId: asCustomer.id}),
            }),
            config: {
                method: idToEdit ? 'PUT' : 'POST',
                body: JSON.stringify(data)
            }
        });
    }

    useEffect(() => {
        if (isFinished && !isError) {
            toggleRefresh();
            togglePopup();
        }
    }, [isFinished, isError, toggleRefresh, togglePopup]);

    const onEdit = (id) => {
        setIdToEdit(id);
        setSubmitErrors(null);
        togglePopup();
    }

    const onDelete = (id) => {
        setIdToDelete(id);
        toggleConfirm();
    }

    const onConfirmCancel = () => {
        setIdToDelete(null);
        toggleConfirm();
    }

    const handleDelete = () => {
        fetchDelete({
            url: generateUrl(AUTOMATED_STATUS_ITEM, {
                id: idToDelete,
                ...(asCustomer && {customerId: asCustomer.id}),
            }),
            config: {method: 'DELETE'}
        });
        setIdToDelete(null);
        toggleConfirm();
    }

    useEffect(() => {
        if (isDeleted && !isDeleteError) {
            toggleRefresh();
        }
    }, [isDeleted, isDeleteError, toggleRefresh]);

    const onPopupClose = () => {
        setIdToEdit(null);
        togglePopup();
    }

    return {
        isLoading,
        automatedStatus,
        isConfirmOpen,
        isPopupOpen,
        idToEdit,
        onClickAdd,
        onSubmit,
        onEdit,
        onDelete,
        handleDelete,
        onConfirmCancel,
        onPopupClose,
        submitErrors,
    }
}

import React, {useState} from 'react';
import {PolIcon} from "../../PolIcon/policon";
import Select, {components, createFilter} from "react-select";
import AsyncSelect from 'react-select/async';
import {postAdmin} from "../../../api/datas/post";
import {usePolTranslation} from "../../../v4/hooks/usePolTranslation";

const Option = (props) => {
    const {innerProps, isFocused, ...otherProps} = props;
    const {onMouseMove, onMouseOver, ...otherInnerProps} = innerProps;
    const newProps = {innerProps: {...otherInnerProps}, ...otherProps};
    return (
        <components.Option {...newProps} className="field-select__item">{props.children}
        </components.Option>
    );
};

const InputSelect = ({inputData, onChange, setSelectIsFocused, useNewCheckValue}) => {
    const {t} = usePolTranslation();

    const selectType = inputData.attr ? inputData.attr['select-type'] ?? false : false
    let [options, setOptions] = useState([]);
    let [asyncSelectValue, setAsyncSelectValue] = useState('');
    let optionsForView = [];
    const singleValue = options.find((option) => option.value === inputData.value || option.label === inputData.value)

    if (!inputData.enum && !inputData.items) {
        return null;
    }
    if (inputData.type === 'array') {
        inputData.enum = inputData.items.enum;
        inputData.enum_titles = inputData.items.enum_titles;
    }

    if (!options.length && inputData.enum && inputData.enum.length) {
        options = inputData.enum.map((option, key) => {
            return {value: option, label: t(inputData.enum_titles[key]) ?? inputData.enum_titles[key]}
        });

        setOptions([...options])
    }

    if (selectType) {
        optionsForView = groupRolesByEntity(inputData, t);
    }

    if (inputData.value
        && inputData.value[0]
        && inputData.value[0].value === undefined
        && inputData.type === 'array'
        && Array.isArray(inputData.value)
        && !(inputData.attr && inputData.attr['input-type'])
    ) {
        const valuePreSelected = Array.isArray(inputData.originalValue) && inputData.originalValue[0] !== null ? inputData.originalValue : inputData.value;
        inputData.value = [];

        options.forEach((item) => {
            if (useNewCheckValue) {
                if (valuePreSelected.some(id => id === item.value)) {
                    inputData.value.push(item);
                }
            } else {
                if (valuePreSelected[0].indexOf(item.value) !== -1) {
                    inputData.value.push(item);
                }
            }
        });

        if (!inputData.value.length) {
            inputData.value = null;
        }
    }

    if (inputData.attr && inputData.attr.type === 'hidden') {
        return (<></>)
    }

    const optimiseSelect = inputData && inputData.enum && inputData.enum.length > 500 ? {components: {Option: Option}} : {}

    const handleAsyncSelectChange = (newValue) => {
        setAsyncSelectValue(newValue);
    }

    const setAsyncSelectField = (datas) => {
        let datasToSelect = [];
        if (datas && datas.data) {
            datasToSelect = datas.data.map((data, key) => {
                let label = t("empty_name");
                if (inputData) {
                    if (inputData.attr['type_field'] === 'contact') {
                        label = data.nameWithProspectName;
                    } else if (inputData.attr['type_field'] === 'prospect') {
                        label = data.fullname ? data.fullname : t("empty_name");
                    }
                }
                return {value: data.id, label: label};
            });
            if(inputData.value) {
                if (Array.isArray(inputData.value)) {
                    datasToSelect = [...datasToSelect, ...inputData.value];
                } else {
                    datasToSelect.push(inputData.value)
                }
            }

            setOptions(datasToSelect.sort((a, b) => a.label > b.label ? 1 : -1));
        }
        return datasToSelect;
    }

    const loadOptions = (inputValue, callback) => {
        if (asyncSelectValue.length > 2) {
            let infoSubmit = [];
            if (inputData && inputData.attr && inputData.attr['type_field']) {
                if (inputData.attr['type_field'] === 'contact') {
                    infoSubmit = {
                        url: (inputData && inputData.attr && inputData.attr.url) ? inputData.attr.url : '',
                        submittedData: {
                            'contactFullName': asyncSelectValue,
                            'justLabelAndId': true,
                        },
                    };
                } else if (inputData.attr['type_field'] === 'prospect') {
                    infoSubmit = {
                        url: (inputData && inputData.attr && inputData.attr.url) ? inputData.attr.url : '',
                        submittedData: {
                            'name': asyncSelectValue,
                            'justLabelAndId': true,
                        },
                    };
                }
            }


            postAdmin(infoSubmit.url, null, (datas) => {
                callback(setAsyncSelectField(datas));
            }, infoSubmit.submittedData);
        }
    };

    const noOptionsMessage = () => {
        return (
            <p align="center">
                {t((asyncSelectValue && asyncSelectValue.length > 2) ? "aucun_resultat_trouve" : "need_more_letter")}
            </p>
        );
    }
    return (
        <>
            {inputData.error ? (
                <div className={'form__error'}
                     onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                     onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                >
                    <PolIcon icon={'exclamation-circle'} className={'form__error__icon'}/>
                    <p className={'form__error__content'}>{t(inputData.error)}</p>
                </div>
            ) : null}

            {inputData.type === 'array' && !(inputData.attr && inputData.attr['input-type']) ? (
                <>
                    {(inputData.attr && inputData.attr.type === 'autocomplete_select') ?
                        <AsyncSelect name={inputData.keyItem} className={'field__select field__input'}
                                     id={inputData.keyItem}
                                     value={inputData.value && inputData.value.length ? inputData.value : null}
                                     onChange={(value) => onChange(value)}
                                     onInputChange={handleAsyncSelectChange}
                                     options={options}
                                     noOptionsMessage={noOptionsMessage}
                                     loadOptions={loadOptions}
                                     isMulti={!(inputData && inputData.attr && inputData.attr.isSingle)}
                                     placeholder={inputData && inputData.attr && inputData.attr.placeholder ? inputData.attr.placeholder : ''}
                                     ignoreAccents={false}
                                     onFocus={() => {
                                         if (setSelectIsFocused) {
                                             setSelectIsFocused(inputData.keyItem, true)
                                         }
                                     }}
                                     onBlur={() => {
                                         if (setSelectIsFocused) {
                                             setSelectIsFocused(inputData.keyItem, false)
                                         }
                                     }}
                                     styles={{
                                         control: (base, state) => ({
                                             ...base,
                                             '&:hover': {borderColor: '#372e74'},
                                             border: '2px solid #eef0fa',
                                             boxShadow: 'none',
                                             color: '#372e74',
                                         })
                                     }}
                        />
                        :
                        <Select name={inputData.keyItem} className={'field__select field__input'}
                                id={inputData.keyItem}
                                value={inputData.value && inputData.value.length ? inputData.value : null}
                                onChange={(value) => onChange(value)}
                                options={optionsForView.length ? optionsForView : options}
                                isMulti={true}
                                placeholder={''}
                                ignoreAccents={false}
                                onFocus={() => {
                                    if (setSelectIsFocused) {
                                        setSelectIsFocused(inputData.keyItem, true)
                                    }
                                }}
                                onBlur={() => {
                                    if (setSelectIsFocused) {
                                        setSelectIsFocused(inputData.keyItem, false)
                                    }
                                }}
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        '&:hover': {borderColor: '#372e74'},
                                        border: '2px solid #eef0fa',
                                        boxShadow: 'none',
                                        color: '#372e74',
                                    })
                                }}
                        />
                    }
                    <label title={t(inputData.title)}
                           className={'field__label'}
                           htmlFor={inputData.keyItem}
                    >{`${t(inputData.title)} ${inputData.isRequired || inputData.required ? '*' : ''}`}</label>
                </>
            ) : (
                <>
                    {((inputData.widget === 'choice-expanded') || (inputData.attr && inputData.attr['input-type'])) ? (
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <label
                                    className={'field__label field__label-radio'}>{inputData.keyItem ? `${t(inputData.title)} ${inputData.isRequired || inputData.required ? '*' : ''}` : ''}</label>
                            </div>
                            {inputData.enum_titles.map((label, value) => {
                                    const inputType = inputData.attr && inputData.attr['input-type'] ? inputData.attr['input-type'] : 'radio'
                                    let checked = inputData.value && (inputData.value.indexOf(label.toString()) !== -1
                                        || (Array.isArray(inputData.value) && inputData.value.findIndex((elt) => elt.label === label.toString()) !== -1))
                                    if (typeof (checked) === 'undefined' || null === checked) {
                                        checked = false;
                                    }

                                    return (<div key={inputData.title + value}>
                                            {inputData.type === 'array' ? (
                                                <div>
                                                    <div className={'col-md-auto'}>
                                                        <input className={`field__input-${inputType}`}
                                                               id={inputData.keyItem + label} type={inputType}
                                                               value={checked}
                                                               checked={checked}
                                                               onChange={() => onChange(label)}
                                                        />
                                                        <label className={`label-${inputType}`}
                                                               htmlFor={inputData.keyItem + label}> {t(label)}</label>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className={'field__radio'}>
                                                    <div
                                                        className={`col-md-auto ${inputData.disabled ? `field__input-${inputType}__disabled` : null}`}>
                                                        <input className={`field__input-${inputType}`}
                                                               id={inputData.title + label} type={inputType}
                                                               value={inputData.enum[value]}
                                                               checked={inputData.value && inputData.value.toString() === inputData.enum[value].toString()}
                                                               onChange={e => onChange(e.target.value)}
                                                               disabled={inputData.disabled}
                                                        />
                                                        <label className={`label-${inputType}`}
                                                               htmlFor={inputData.title + label}> {t(label)}</label>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </div>
                                    )
                                }
                            )}
                        </div>

                    ) : (
                        <>
                            <Select name={inputData.keyItem} className={`field__select field__input ${inputData.isReadOnly ? 'input-read-only' : ''}`}
                                    id={inputData.keyItem}
                                    onChange={(value) => onChange(value && value.value ? value.value : value)}
                                    options={options}
                                    isClearable={true}
                                    isMulti={false}
                                    value={singleValue ? singleValue : null}
                                    placeholder={''}
                                    title={t(inputData.title)}
                                    filterOption={createFilter({ignoreAccents: false})}
                                    {...optimiseSelect}
                                    onFocus={() => {
                                        if (setSelectIsFocused) {
                                            setSelectIsFocused(inputData.keyItem, true)
                                        }
                                    }}
                                    onBlur={() => {
                                        if (setSelectIsFocused) {
                                            setSelectIsFocused(inputData.keyItem, false)
                                        }
                                    }}
                                    styles={{
                                        control: (base, state) => ({
                                            ...base,
                                            '&:hover': {borderColor: '#372e74'},
                                            border: '2px solid #eef0fa',
                                            boxShadow: 'none',
                                            color: '#372e74',
                                        }),
                                        singleValue: () => ({
                                            color: inputData.isReadOnly ? '#333' : '#7565c0'
                                        })
                                    }}
                                    isOptionDisabled={(option) => inputData.isReadOnly && option.value !== inputData.value}
                            />
                            <label title={t(inputData.title)}
                                   className={'field__label'}
                                   htmlFor={inputData.keyItem}
                            >{`${t(inputData.title)} ${inputData.isRequired || inputData.required ? '*' : ''}`}</label>
                        </>
                    )}
                </>
            )}

        </>
    )
};

const groupRolesByEntity = (inputData, t) => {
    const items = [...inputData.enum];

    const prospectOptions = inputData.enum.reduce((acc, option, key) => {
        if (option.search('PROSPECT') !== -1) {
            acc.push({value: option, label: t(inputData.enum_titles[key])})
            items.splice(items.indexOf(option), 1);
        }
        return acc;
    }, []);
    const contactOptions = inputData.enum.reduce((acc, option, key) => {
        if (option.search('CONTACT') !== -1) {
            acc.push({value: option, label: t(inputData.enum_titles[key])})
            items.splice(items.indexOf(option), 1);
        }
        return acc;
    }, []);
    const taskOptions = inputData.enum.reduce((acc, option, key) => {
        if (option.search('TASK') !== -1) {
            acc.push({value: option, label: t(inputData.enum_titles[key])})
            items.splice(items.indexOf(option), 1);
        }
        return acc;
    }, []);
    const quoteOptions = inputData.enum.reduce((acc, option, key) => {
        if (option.search('QUOTE') !== -1) {
            acc.push({value: option, label: t(inputData.enum_titles[key])})
            items.splice(items.indexOf(option), 1);
        }
        return acc;
    }, []);
    const ongletOptions = inputData.enum.reduce((acc, option, key) => {
        if (option.search('ROLE_TAB') !== -1) {
            acc.push({value: option, label: t(inputData.enum_titles[key])})
            items.splice(items.indexOf(option), 1);
        }
        return acc;
    }, []);
    const otherOptions = items.map((option) => {
        return {value: option, label: t(option)}
    });

    return [
        {
            label: 'Prospect',
            options: prospectOptions,
        },
        {
            label: 'Contact',
            options: contactOptions,
        },
        {
            label: 'Action',
            options: taskOptions,
        },
        {
            label: 'Devis',
            options: quoteOptions,
        },
        {
            label: 'Onglet',
            options: ongletOptions,
        },
        {
            label: 'Autre',
            options: otherOptions,
        },
    ];
}


export default React.memo(InputSelect);

import React, {useState} from 'react'
import {withPolTranslation} from "v4/hooks/usePolTranslation";

const ProspectType = ({onChange, t, item}) => {
    const [isClient, setIsClient] = useState(item && item.isClient);
    return (
        <>
            <input className={'field__input'} type={'checkbox'} value={isClient}
                   checked={isClient} id="is_client" onChange={(e) => {
                onChange('isClient', e.target.value)
                setIsClient(!isClient)
            }}/>
            <label htmlFor="is_client">{t('isClient')}</label>
        </>
    )
}

export default withPolTranslation(ProspectType)

import {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {STATS_MAILING} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";

export default function useMailingStatistics(prospectId) {
    const {t} = usePolTranslation();
    const [{isLoading, isError, isFinished}, fetchStats] = useFetch();
    const {replace} = useHistory();
    const {addSuccessNotification} = useNotification();

    const fetchMailingStatistics = () => {
        fetchStats({
            url: generateUrl(STATS_MAILING),
            config: {
                method: 'POST',
                body: JSON.stringify({prospectId: prospectId ?? null})
            }
        });
    }

    useEffect(() => {
        if (!isError && isFinished) {
            replace(({pathname}) => pathname, {hasToRefresh: true})
            addSuccessNotification(t('stats_updated'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished])

    return [isLoading, fetchMailingStatistics];
}

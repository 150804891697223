import React from 'react';
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import UserService from "api/user/user";
import {ENTITIES} from "v4/data/entities";

export default function ViewOrdersSelection() {
    const {t} = usePolTranslation();
    const {viewOrders, changeCurrentViewOrder, currentViewOrder} = useViewOrdersContext();

    function handleSelect(event) {
        const value = event.target.value;
        changeCurrentViewOrder(value);
    }

    const options = Object.entries(viewOrders).map(([viewOrderKey, viewOrder]) => {
        const keysToGet = ['form', 'search'];
        return {
            entityName: viewOrderKey,
            viewOrders: keysToGet.flatMap(key => {
                return Object.keys(viewOrder[key] ?? {}).map(formKey => {
                    const tabs = UserService.getFlatTabs();
                    const tab = tabs.find(tab => tab.id === formKey);
                    if (![...ENTITIES, 'business', 'individual'].includes(formKey) && !tab) return null;

                    let label = t(key);

                    if (tab) label = `${label} ${tab.name}`
                    if (formKey === 'individual') label = `${label} ${t('prospect_individual')}`
                    if ([...ENTITIES, 'business'].includes(formKey)) label = `${label} ${t(formKey)}`

                    return {
                        key: `${viewOrderKey}.${key}.${formKey}`,
                        label,
                    }
                })
            })
                .filter(Boolean)
                .sort((a, b) => {
                    // a = Formulaire, b = Recherche --> group them together
                    // a = Formulaire Pexin, b = Recherche Pexin --> group them together
                    // a = Formulaire Prolyse, b = Recherche Prolyse --> group them together
                    // So at the end, we have [Formulaire, Recherche, Formulaire Pexin, Recherche Pexin, Formulaire Prolyse, Recherche Prolyse]

                    const aParts = a.label.split(' ');
                    const bParts = b.label.split(' ');

                    if (aParts.length === 1 && bParts.length === 1) return 0;
                    if (aParts.length === 1) return -1;
                    if (bParts.length === 1) return 1;

                    return aParts[1].localeCompare(bParts[1]);
                })
        }
    });

    return (
        <div className="new-view-orders__selection">
            <h3>{t('pick_form_to_edit')}</h3>

            <select onChange={handleSelect}
                    style={{
                        width: '100%',
                        padding: '0.3rem',
                        fontSize: '1rem',
                        marginTop: '0.5rem',
                    }}>
                {!currentViewOrder && <option value="">{t('pick_element')}</option>}

                {options.map(({entityName, viewOrders}) => (
                    <optgroup key={entityName} label={t(entityName)}>
                        {viewOrders.map(({key, label}) => (
                            <option key={key} value={key}>
                                {label}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
        </div>
    )
}

import UserService from "api/user/user";
import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect, useMemo, useState} from 'react';
import CustomReactSelect from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {SAVED_SEARCH, SAVED_SEARCH_DELETE} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";
import {clearUnusedFieldsOfSearch, devideSavedSearch} from "v4/utils";

export default function SavedSearch({entity}) {
    const {t} = usePolTranslation();
    const [{data}, fetchSaveSavedSearch] = useFetch();
    const [, fetchDeleteSavedSearch] = useFetch();
    const {
        tab,
        searchbar,
        setSearchbar,
        formMethods,
    } = useSearchContext();
    const [savedSearches, setSavedSearches] = useState(UserService.getSavedSearchs()?.filter(({searchType}) => searchType === (tab ? tab.id : entity)));
    const [selectedSavedSearch, setSelectedSavedSearch] = useState('');
    const [name, setName] = useState("");
    const [isFormDirty, setIsFormDirty] = useState(false);

    useEffect(() => {
        if (searchbar !== '') {
            setIsFormDirty(true);
        } else {
            setIsFormDirty(formMethods.formState.isDirty);
        }
    }, [formMethods.formState.isDirty, searchbar]);

    const options = useMemo(() => savedSearches.map(({searchContent, name}) => ({
        value: searchContent,
        label: name
    })), [savedSearches]);

    const onSelectChange = (value) => {
        setSelectedSavedSearch(value);
        if (value && value !== '') {
            const {searchbar, chainedSearch, search} = devideSavedSearch(value, true);
            setSearchbar(searchbar);
            Object.entries({...search, ...chainedSearch}).forEach(([key, value]) => {
                if (key in formMethods.getValues()) {
                    formMethods.setValue(key, value);
                } else {
                    formMethods.register(key, {value, shouldUnregister: true});
                }
            });
        }
    }

    const onAddSavedSearch = () => {
        const nameValue = name;
        setName("");
        const searchContent = clearUnusedFieldsOfSearch(formMethods.getValues());
        if (searchbar !== '') {
            searchContent.searchbar = searchbar;
        }
        fetchSaveSavedSearch({
            url: generateUrl(SAVED_SEARCH),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    name: nameValue,
                    searchType: tab ? tab.id : entity,
                    searchContent: JSON.stringify(searchContent)
                })
            }
        });
    }

    useEffect(() => {
        if (data?.data) {
            const {id, name, searchType, searchContent} = data.data;
            setSavedSearches((prev) => [...prev, {id, name, searchType, searchContent}]);
            UserService.setSavedSearch(data.data);
        }
    }, [data]);

    const onDeleteSavedSearch = () => {
        const savedSearchId = savedSearches.find(s => s.searchContent === selectedSavedSearch)?.id;
        fetchDeleteSavedSearch({
            url: generateUrl(SAVED_SEARCH_DELETE, {id: savedSearchId}),
            config: {
                method: 'DELETE'
            }
        });
        UserService.removeSavedSearch(savedSearchId);
        setSavedSearches((prevSavedSearches) => prevSavedSearches.filter(s => s.searchContent !== selectedSavedSearch));
        setSelectedSavedSearch('');
    }

    return (
        <>
            <h2>{t('saved_searchs')}</h2>
            <div>
                <p>{t('my_saved_searchs')}</p>
                <div>
                    <CustomReactSelect name="test"
                                       value={selectedSavedSearch}
                                       options={options}
                                       onChange={onSelectChange}/>
                    <button className="savedSearch_button--first"
                            disabled={selectedSavedSearch === ''}
                            onClick={onDeleteSavedSearch}>
                        <PolIcon icon='bin'/>
                    </button>
                </div>
                <p>{t('name_a_search')}</p>
                <div>
                    <input className="savedSearch_input"
                           value={name}
                           onChange={({currentTarget: {value}}) => setName(value)}/>
                    <button className="savedSearch_button--second"
                            disabled={name === '' || !isFormDirty}
                            onClick={onAddSavedSearch}>
                        <PolIcon icon='validation'/>
                        {t('save_search')}
                    </button>
                </div>
            </div>
        </>
    )
}

import {
    atom
} from 'recoil';

export const importDataState = atom({
    key: 'import-data',
    default: [],
});

export const datatableColsState = atom({
    key: 'import-datatable-cols',
    default: [],
});

export const skipRowState = atom({
    key: 'import-datatable-skip-row',
    default: 1,
});

export const specificFieldsState = atom({
    key: 'import-specific-fields',
    default: 1,
});

export const formDataState = atom({
    key: 'import-form-data',
    default: [],
});

export const importProgression = atom({
    key: 'import-progression',
    default: {},
});

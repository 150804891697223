import React, {useEffect, useState} from 'react';
import Module from "v4/features/admin/modules/components/ErrorBoundaryModules/components/ManageModules/components/Module/Module";
import apiService from "v4/services/api.service";
import {MODULE_LIST, MODULE_MICROSOFT_GRAPH_URL} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export const MODULE_MICROSOFT_GRAPH_ID = 'module_microsoft_graph';

export default function ManageModules({customerId, setAdditionalInput}) {
    const [{data, isError, isLoading, isFinished}, hookFetch] = useFetch();
    const [{data: dataMicrosoftGraph, isError: isErrorMicrosoftGraph, isLoading: isLoadingMicrosoftGraph, isFinished: isFinishedMicrosoftGraph}, hookFetchMicrosoftGraph] = useFetch();
    const [refetch, setRefetch] = useState(false);
    const [modules, setModules] = useState(null)
    const {t} = usePolTranslation();

    useEffect(() => {
        hookFetch({
            url: apiService.generateUrl(MODULE_LIST, {customerId}),
            config: {
                method: 'GET',
            }
        })
    }, [refetch, hookFetch, customerId])

    useEffect(() => {
        if(!isError && !isLoading && isFinished && data?.['hydra:member']) {
            setModules(data['hydra:member'])
        }
    }, [data, isLoading, isError, isFinished])

    useEffect(() => {
        if (modules?.length) {
            if (modules.some(module => module.id === MODULE_MICROSOFT_GRAPH_ID && true === module.isEnabled)) {
                hookFetchMicrosoftGraph({
                    url: apiService.generateUrl(MODULE_MICROSOFT_GRAPH_URL, {id: MODULE_MICROSOFT_GRAPH_ID, customerId: customerId}),
                    config: {
                        method: 'GET',
                    }
                })
            }
        }
    }, [modules, hookFetchMicrosoftGraph, customerId])

    useEffect(() => {
        if(!isErrorMicrosoftGraph && !isLoadingMicrosoftGraph && isFinishedMicrosoftGraph && dataMicrosoftGraph) {
            setAdditionalInput(
                <div>
                    <a href={dataMicrosoftGraph.url}>{dataMicrosoftGraph.url}</a>
                    <p>TenantId : {dataMicrosoftGraph.tenantId}</p>
                </div>
            );
        }
    }, [dataMicrosoftGraph, isLoadingMicrosoftGraph, isErrorMicrosoftGraph, isFinishedMicrosoftGraph, setAdditionalInput])

    return (
        <section className="box">
            <div className={'box__header d-flex justify-content-between'}>
                <p className="box__header__text align-self-center">{t("manage_modules")}</p>
            </div>
            <div className="box__body">
                {
                    isError ?
                        <div className="module-card__error">
                            <p>Une erreur est survenue</p>
                            <button onClick={() => setRefetch(toggleRefetch => !toggleRefetch)}>Recharger le
                                module
                            </button>
                        </div>
                        :
                        (modules === null || isLoading ?
                                <p>Chargement...</p>
                                :
                                (modules &&
                                    <div className="module-card__wrapper">
                                        {
                                            modules.map(module => <Module key={module.id} moduleInfos={module}
                                                                          customerId={customerId}/>)
                                        }
                                    </div>
                                )
                        )
                }
            </div>
        </section>
    );
}

import React from 'react';
import ContentActions from "./contentActions";

export const Header = ({title, className = '', number = '', setVisibleFilters, entity, listingToMap}) => {
    return (
        <div className={'header'}>
            <div className={`header__margin ${className}`}></div>
            <div className={`header__title ${className}`}>
                {title}
            </div>
            <div className={`header__separator`}></div>
            <p className={'header__number datatable__number'}>{number}</p>
            <div className={`header__separator`}></div>
            <ContentActions
                entity={entity}
                setVisibleFilters={() => setVisibleFilters()}
                listingToMap={listingToMap}
            />
        </div>
    )
};

import UserService from "api/user/user";
import {routesBase} from "v4/data/appRoutes";
import ListingPageLayout from "v4/layouts/ListingPageLayout/ListingPageLayout";

const classNameCorrespondance = {
    quote: 'devis',
    task: 'actions',
    contact: 'contacts',
}

export function getRouteParamsForTabs() {
    const tabs = UserService.getTabs();

    return tabs.map(tab => (
        tab.subMenu ? getGroupTabs(tab) : getTab(tab)
    ));
}

function getTab(tab, basePath = routesBase.baseTab) {
    return {
        id: tab.id,
        className: classNameCorrespondance?.[tab.searchType] ?? '',
        basePath: `${basePath}/${tab.slug}`.replace('//', '/'),
        path: [
            `${basePath}/${tab.slug}`.replace('//', '/')
        ],
        component: ListingPageLayout,
        groupCode: 'listingPage',
        isTab: true,
        tab: tab,
        entity: tab.searchType,
        isProtected: true,
        menuTitle: tab.title,
        badgeId: tab?.badgeId ?? null,
        testIsGranted: () => true
    };
}

function getGroupTabs(tab) {
    const basePath = `${routesBase.baseTab}/${tab.groupLabel.replace(/\s+/g, '_')}`; // this replace is in case of space in groupLabel, s+ means one or more space and g means global (multi match)

    return {
        id: tab.groupLabel,
        className: 'tabs',
        basePath: basePath,
        subMenu: tab.subMenu.map(sub => getTab(sub, basePath)),
        isProtected: true,
        menuTitle: tab.groupLabel,
        testIsGranted: () => true
    };
}

export function getCustomTabSlugs(onlyDisplayable = false) {
    return UserService.getTabs().reduce((acc, tab) => {
        if ('subMenu' in tab) {
            tab.subMenu.forEach(subTab => {
                if (!onlyDisplayable || !subTab.isDisabledDisplaySublist) {
                    if (subTab.searchType !== 'prospect') {
                        acc.push(subTab.slug)
                    }
                }
            });
        } else if (!onlyDisplayable || !tab.isDisabledDisplaySublist) {
            if (tab.searchType !== 'prospect') {
                acc.push(tab.slug);
            }
        }

        return acc;
    }, []);
}

export function getCustomTabBySlug(slug) {
    const tabs = UserService.getTabs();
    return tabs.reduce((acc, tab) => {
        if ('subMenu' in tab) {
            const subTab = tab.subMenu.find(sub => sub.slug === slug);
            if (subTab) {
                return subTab;
            }
        } else if (tab.slug === slug) {
            return tab;
        }
        return acc;
    }, null);
}

import { combineReducers } from 'redux'
import user from './user'
import datas from './datas'
import router from './router'
import admin from './admin'
import content from './content'
import form from './form'
import datatable from './datatable'
import prospects from './prospects'
import ged from './ged'

const allReducers = combineReducers({
    user,
    datas,
    router,
    admin,
    content,
    form,
    datatable,
    prospects,
    ged
});

const rootReducer = (state, action) => {
    if (action.type === 'LOG_OUT') {
        state = undefined
    }

    return allReducers(state, action)
};

export default rootReducer
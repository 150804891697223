import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {useHistory} from "react-router-dom";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default React.memo(function FormEntityLayoutFormActions({onBack, saveLabel, saveIcon, isSaveDisabled, children}) {
    const {t} = usePolTranslation();
    const {goBack} = useHistory();

    return (
        <div className="form-entity-actions">
            <div className="form__back" onClick={onBack ?? goBack}>
                <span className="form__back__icon">
                    <PolIcon icon='return'/>
                </span>
                {t('retour')}
            </div>
            <div className="form__submit">
                <button type="submit" className="btn btn-primary form__submit__btn" disabled={isSaveDisabled}>
                    <PolIcon icon={saveIcon ?? 'validation'}/>
                    <span>
                        {t(saveLabel ?? 'enregistrer')}
                    </span>
                </button>
            </div>
            {children}
        </div>

    )
}, (prevProps, nextProps) => {
    return prevProps.isSaveDisabled === nextProps.isSaveDisabled;
})

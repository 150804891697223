import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import useViewOrdersButtonListHover
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/hooks/useViewOrdersButtonListHover";
import getIconForWidth from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/utils/getIconForWidth";
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import 'v4/pages/admin/ViewOrders/components/ViewOrdersButtons/partials/ViewOrdersButtonListHover.scss';
import ViewOrdersButtonListHover
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/partials/ViewOrdersButtonListHover";
import getAvailableWidths from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/utils/getAvailableWidths";
import ReactTooltip from "react-tooltip";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ViewOrdersWidthButton({group, field}) {
    const {t} = usePolTranslation();
    const {setWidth} = useViewOrdersContext();

    const availableWidths = getAvailableWidths(group.width, !field);
    const {
        isActionOpen,
        toggleIsActionOpen,
        shouldOpenTop,
        containerRef,
    } = useViewOrdersButtonListHover(availableWidths, );

    const handleClick = (width) => setWidth(group.id, field?.id, width);

    return (
        <div className="vo-action__list-hover__container"
             ref={containerRef}
             onMouseLeave={() => toggleIsActionOpen(false)}>
            <button className="vo-action__button vo-action__width__button"
                    data-for="width-tooltip"
                    data-tip={t('manage_width')}
                    onClick={toggleIsActionOpen}>
                <PolIcon icon={getIconForWidth(field?.width ?? group.width)}/>
            </button>
            <ViewOrdersButtonListHover isActionOpen={isActionOpen}
                                       shouldOpenTop={shouldOpenTop}
                                       list={availableWidths}
                                       currentItems={[field?.width ?? group.width]}
                                       titleCallback={item => `${item}%`}
                                       itemClassName="square"
                                       renderItem={item => <PolIcon icon={getIconForWidth(item)}/>}
                                       onClick={handleClick}/>
            <ReactTooltip id="width-tooltip" effect="solid"/>
        </div>
    )
}

import React from 'react';

import 'v4/components/ui/PolBadge/PolBadge.scss';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

/**
 *
 * @param {string} text
 * @param {'success' | 'error' | 'warning' | 'info'} status
 */
export default function PolBadge({text, status= 'info'}) {
    const {t} = usePolTranslation();

    return (
        <span className={`pol-badge pol-badge-${status}`}>{t(text)}</span>
    )
}

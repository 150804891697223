import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {useHistory} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {PRODUCT} from "v4/data/entities";
import ListingExport from "v4/features/front/export/components/ListingExport/ListingExport";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {ids} from "v4/utils";

export default function ProductsListingTools({checkedRows}) {
    const {t} = usePolTranslation();
    const {push} = useHistory();

    const handleClick = () => push(routesBase.baseQuote, {[ids(PRODUCT)]: checkedRows ?? []});
    const handleNoSelectedItems = (e) => (checkedRows?.length === 0) && e.stopPropagation();

    return (
        <>
            <ListingExport selectedItems={checkedRows} entity={PRODUCT}/>
            <li className="listing-header__action dropdown"
                title={t('options')} onClickCapture={handleNoSelectedItems}>
                <PolIcon icon="actions" className="action__picto"/>
                <ul className="listing-header__action__dropdown">
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`} onClick={handleClick}>
                        <p>{t('research_associated_quote')}</p>
                    </li>
                </ul>
            </li>
        </>
    )
}

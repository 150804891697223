import React, {useMemo} from 'react';
import PolTable from "v4/components/ui/PolTable/PolTable";
import SearchContextPagination from "v4/components/utils/SearchContextPagination/SearchContextPagination";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import DashboardListingState from "v4/pages/front/Dashboard/components/DashboardListingState/DashboardListingState";
import DashboardQuotesListingTableRowAction
    from "v4/pages/front/Dashboard/components/DashboardQuotesListing/components/DashboardQuotesListingTable/components/DashboardQuotesListingTableRowAction/DashboardQuotesListingTableRowAction";
import useDashboardTabListing from "v4/pages/front/Dashboard/hooks/useDashboardTabListing";

export default function DashboardQuotesListingTable({tabKey, activeTabKey, setCounter, styleVars}) {
    const {state, isShowing, data, onHeaderClick} = useDashboardTabListing(tabKey, activeTabKey, setCounter) ?? {};

    const [isLoading, noResults] = useMemo(() => {
        if (data) {
            return [false, data.results.length === 0];
        }

        return [true, false];
    }, [data]);

    return (
        <ToggleComponent isShowing={isShowing}>
            {(isLoading || noResults) && <DashboardListingState isLoading={isLoading} noResults={noResults}/>}
            {(!isLoading && !noResults) && (
                <PolTable className="dashboard-listing__table"
                          onHeaderClick={onHeaderClick}
                          data={data}
                          status={state}
                          rowActions={(props) => <DashboardQuotesListingTableRowAction {...props}/>}
                          styleVars={styleVars}
                          hasTFoot={false}/>
            )}
            <SearchContextPagination canChangeItemsPerPage={false} showWrapperEvenIfNoPages={true}/>
        </ToggleComponent>
    )
}

import {useEffect} from "react";
import {useHistory} from "react-router-dom";

export default function useHandleRouteStateForceFilters(formMethods) {
    const {replace, location} = useHistory();
    const {state} = location;

    useEffect(() => {
        if (state?.forceFilters) {
            const {forceFilters, ...newState} = state;
            if (forceFilters) {
                Object.entries(forceFilters).forEach(([key, value]) => {
                    formMethods.setValue(key, value);
                });
            }

            replace({state: newState});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.forceFilters]);
}

import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useFrontContext} from "v4/contexts/FrontContext";
import {LAST_VISITS} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

export default function LastVisits() {
    const {t} = useTranslation();
    const [{data}, fetchLastVisits] = useFetch();
    const {lastVisits, setLastVisits, refetchLastsVisits} = useFrontContext();

    useEffect(() => {
        fetchLastVisits({url: generateUrl(LAST_VISITS)});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchLastsVisits]);

    useEffect(() => {
        if (data?.['hydra:member']) setLastVisits(data['hydra:member']);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <ul className="row row-fluid last-visits__list">
            <li className="col-xs-12 col-sm-auto last-visits__title">
                {t('dernieres_consultations')}
            </li>
            {lastVisits.map((item, index) => (
                <li className="col-xs-12 col-sm-auto last-visits__list__item" key={item.id ?? index}>
                    <Link to={item.url}
                          className="last-visits__list__item__loader">
                        <PolIcon icon={getIcon(item.type)} className="last-visits__list__item__picto"/>
                        <span className="last-visits__list__item__name">
                            {item.name}
                        </span>
                    </Link>
                </li>
            ))}
        </ul>
    )
}

function getIcon(type) {
    switch (type.toLowerCase()) {
        case 'entreprise':
            return 'building';
        case 'particulier':
            return 'store-alt';
        case 'contact':
            return 'address-book';
        default:
            return 'user';
    }
}

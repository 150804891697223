import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_QUOTES, TAB_WON_QUOTES} from "v4/data/tabNames";

export default function QuotesListingRowActions({tab = {}, rowId, prospectId, isWon}) {
    let slug = isWon ? TAB_WON_QUOTES : TAB_QUOTES;

    if ('slug' in tab && !tab.isDisabledDisplaySublist) {
        slug = tab.slug;
    }

    return (
        <Link to={`${routesBase.baseProspect}/${prospectId}/${slug}/${rowId}`} className="table-link"/>
    )
}

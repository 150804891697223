import React, {useMemo, useEffect, useState, useCallback} from 'react';
import Table from "v4/components/ui/Table/Table";
import {Link} from "react-router-dom";
import {routesBase} from 'v4/data/appRoutes'
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import apiService from "v4/services/api.service";
import {
    OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_SHOW_LIST
} from "v4/data/apiRoutes";
import {PolIcon} from "components/PolIcon/policon";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import useNotification from "v4/hooks/useNotification";
import OutlookSubscriptionConfigurationUsersListDelete
    from "v4/pages/admin/OutlookSubscriptionConfigurations/OutlookSubscriptionConfigurationUsersList/components/OutlookSubscriptionConfigurationUsersListDelete/OutlookSubscriptionConfigurationUsersListDelete";

export default function OutlookSubscriptionConfigurationUsersList({customerId, subscriptionConfigId}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()

    // Listing
    const [{
        data: loadedOutlookSubscriptionConfigurationUsers,
        isLoading: loadedOutlookSubscriptionConfigurationUsersLoading,
        isError: loadedOutlookSubscriptionConfigurationUsersError
    }, fetchOutlookSubscriptionConfigurationUsers] = useFetch();

    const [refetch, setRefetch] = useState(0)

    useEffect(() => {
        fetchOutlookSubscriptionConfigurationUsers({
            url: apiService.generateUrl(OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_SHOW_LIST, {
                subscriptionConfigId: subscriptionConfigId,
                sortField: "name",
                sortOrder: "asc"
            }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const onDeleteSuccess = useCallback(() => {
        addSuccessNotification(t('delete_outlook_subscription_configuration_user_done'))
        setRefetch(c => c + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])

    const onDeleteError = useCallback(() => {
        addErrorNotification(t('delete_outlook_subscription_configuration_user_error'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hydrateOutlookSubscriptionConfigurationUserRows = useCallback(() => {

        if (!loadedOutlookSubscriptionConfigurationUsers?.['hydra:member']) {
            return null
        }

        const rows = JSON.parse(JSON.stringify(loadedOutlookSubscriptionConfigurationUsers['hydra:member']))

        return (
            rows.map((item, index) => {
                const today = new Date();

                return {
                    fullname: item.user?.fullname,
                    email: item.user?.email,
                    outlookFolderId: item.outlookFolderId,
                    expiredAt: today > new Date(item.expiredAt) ? 'idle' : 'active',
                    actions: (
                        <ul className="datatable__actions">
                            <Link key={index} to={`${routesBase.adminMicrosoftGraph}/${subscriptionConfigId}/edit/${item.id}`}>
                                <li className="datatable__actions__item">
                                    <span className="datatable__actions__item__icon">
                                        <PolIcon icon='edit'/>
                                    </span>
                                </li>
                            </Link>
                            <OutlookSubscriptionConfigurationUsersListDelete item={item} onSuccessCallback={onDeleteSuccess}
                                                 onErrorCallBack={onDeleteError}/>
                        </ul>
                    )
                };
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedOutlookSubscriptionConfigurationUsers])

    const tableList = useMemo(() => {
        return <Table tableRows={hydrateOutlookSubscriptionConfigurationUserRows()} isLoading={loadedOutlookSubscriptionConfigurationUsersLoading}
                   isError={loadedOutlookSubscriptionConfigurationUsersError}
                   theadData={Object.keys({fullname: 'fullname', email: 'email', outlookFolderId: 'outlookFolderId', expiredAt: 'expiredAt', actions: 'actions'})}/>
    }, [hydrateOutlookSubscriptionConfigurationUserRows, loadedOutlookSubscriptionConfigurationUsersLoading, loadedOutlookSubscriptionConfigurationUsersError])

    const CardHeaderTools = () => (
        <>
            <Link to={routesBase.adminMicrosoftGraph} className="btn btn-secondary">{t('retour')}</Link>
            <Link to={`${routesBase.adminMicrosoftGraph}/${subscriptionConfigId}/add`}
                  className="btn btn-primary">{t('add_new_outlook_subscription_configuration_user')}</Link>
        </>
    )

    return (
        <AdminCard title={t('manage_outlook_subscription_configuration_users')} CardHeaderTools={CardHeaderTools}>
            {tableList}
        </AdminCard>
    )
}

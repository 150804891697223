import {useState} from 'react';

export default function useCheckList(initialValue, hasCheckAll = false) {
    const [checkList, setCheckList] = useState(initialValue);
    const [isCheckAllChecked, setIsCheckAllChecked] = useState(false);


    /* CHECKLIST */
    const containsInChecklist = (value) => checkList.includes(value);

    const handleCheck = (value) => {
        if (containsInChecklist(value)) {
            if (hasCheckAll) {
                setIsCheckAllChecked(false);
            }
            setCheckList(prevCheckList => prevCheckList.filter((item) => item !== value));
        } else {
            setCheckList(prevCheckList => [...prevCheckList, value]);
        }
    }

    const addMultipleValues = (values) => {
        setCheckList(prevCheckList => [...new Set([...prevCheckList, ...(values ?? [])])]);
    }

    const unCheckAll = () => {
        setCheckList([]);
        if (hasCheckAll) {
            setIsCheckAllChecked(false);
        }
    }

    const specificChecklistFunctions = {
        setCheckList,
        containsInChecklist,
        addMultipleValues,
        unCheckAll,
    };
    /* *** */


    /* CHECKALL */
    const handleCheckAll = (onCheck, onUncheck) => ({currentTarget}) => {
        if (currentTarget.checked) {
            setIsCheckAllChecked(true);
            onCheck && onCheck();
        } else {
            setIsCheckAllChecked(false);
            setCheckList([]);
            onUncheck && onUncheck();
        }
    }

    const specificCheckAllFunctions = () => ({
        setIsCheckAllChecked,
    });
    /* *** */


    /* RETURNS */
    if (hasCheckAll) {
        return [checkList, isCheckAllChecked, handleCheck, handleCheckAll, specificChecklistFunctions, specificCheckAllFunctions];
    }

    return [checkList, handleCheck, specificChecklistFunctions];
    /* *** */
}

// On parcourt les inputs, si on a un input object (Formulaire imbriqué) alors on ajoute ses champs a l'input initial pour garder un fonctionnement simple dans la vue
import {searchFieldKeyInViewOrders} from "../utils/viewOrdersManager";

export const handleFormData = (inputs, parentElement = false, parentId = undefined, searchItem = undefined, allowRecursive = true) => {
    let finalObject = {};
    for (const [key, value] of Object.entries(inputs)) {
        if (value.type === 'object' && ((value.attr && value.attr.type !== 'date_compare' && value.attr && value.attr.type !== 'number_compare') || (value.attr === undefined && allowRecursive))) {
            const objectId = value.id ? {
                [`${key}_id`]: {
                    id: `${key}_id`,
                    required: true,
                    originalValue: value.id,
                    type: "hidden",
                    keyItem: 'id',
                    value: value.id,
                    parentElement: key,
                    isSpecificField: value && value.attr && value.attr['data-type'] === 'specific-fields' ? "1" : false
                }
            } : {};

            finalObject = {
                ...finalObject,
                ...handleFormData(value.properties, key, value.id, value.attr ? value.attr.dataKey : undefined),
                ...objectId
            };
        } else if (value.type !== 'array') {
            finalObject = {
                ...finalObject,
                [parentElement ? parentElement + '_' + key : key]: {
                    ...value,
                    parentElement: parentElement,
                    keyItem: key,
                    parentId: parentId,
                    searchItem,
                    originalValue: value.value
                }
            }
        } else {
            finalObject = {
                ...finalObject,
                [parentElement ? parentElement + '_' + key : key]: {
                    ...value,
                    parentElement: parentElement,
                    keyItem: key,
                    parentId: parentId,
                    searchItem,
                    originalValue: [value.value],
                    type: 'array'
                }
            }
        }
    }

    return finalObject;
};

const handleErrorData = (errorsJson, parentKey = false) => {
    let obj = {};
    const objsToSearch = errorsJson.hasOwnProperty('children') ? errorsJson.children : errorsJson;

    for (const [key, value] of Object.entries(objsToSearch)) {

        if (value && value.hasOwnProperty('children')) {
            obj = {
                ...obj,
                ...handleErrorData(value.children, key)
            }
        }
        if (value && value.hasOwnProperty('errors')) {
            const keyError = parentKey ? `${parentKey}_${key}` : key;

            obj = {
                ...obj,
                [keyError]: value.errors.pop()
            }
        }
    }

    return obj;
};

const defaultState = {
    filters: {},
    errors: {},
    redirect: false
};

const form = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_FORM':
            return {
                ...state,
                [action.name]: handleFormData(action.inputs),
                redirect: false,
                errors: {},
            };
        case 'DELETE_FORM':
            const newState = state;
            delete newState[action.name];

            return {
                ...newState,
                redirect: false,
                errors: {},
            };
        case 'SET_FORM_ERROR':
            return {
                ...state,
                errors: handleErrorData(action.errors),
                redirect: false
            };
        case 'SET_REDIRECT_FALSE':
            return {
                ...state,
                redirect: false
            };
        case 'SET_FORM_SUCCESS':
            return {
                ...state,
                redirect: true,
                errors: {}
            };
        case 'SET_FILTER_SEARCH':
            let {inputs, submittedData} = action.info
            const submittedDataToKeep = {...submittedData};
            if (submittedData) {
                // Cette méthode remplace les clés des filtres par les labels présents dans les viewOrders sauf pour les tableaux
                Object.keys(submittedData).forEach((key) => {
                    const viewOrder = searchFieldKeyInViewOrders(inputs, key);
                    if (viewOrder && inputs[viewOrder.groupKey]?.fields[viewOrder.index]?.input?.title
                        && submittedData[key]
                        && (typeof submittedData[key] === 'boolean' || submittedData[key].length || Object.keys(submittedData[key]).length)
                    ) {
                        const input = inputs[viewOrder.groupKey].fields[viewOrder.index].input;

                        let value = submittedData[key];
                        let titleValue = value;
                        if (!Array.isArray(submittedData[key])) {
                            if (inputs[viewOrder.groupKey].fields[viewOrder.index].type === 'search'
                                && (
                                    (input?.enum && input?.enum_titles)
                                    ||
                                    (input?.items?.enum && input?.items?.enum_titles)
                                )
                            ) {
                                const enumTitleList = input.items ? input.items.enum_titles : input.enum_titles;
                                const enumList = input.items ? input.items.enum : input.enum

                                if (enumList && enumTitleList) {
                                    titleValue = enumTitleList[enumList.indexOf(value)]
                                }
                            }

                            submittedData = {
                                ...submittedData,
                                [key]: {
                                    type: 'search',
                                    key: key,
                                    value: value,
                                    name: input.title,
                                    filterValue: titleValue,
                                }
                            }
                        } else {
                            let realValues = submittedData[key];
                            const enumTitleList = input.items ? input.items.enum_titles : input.enum;
                            const enumList = input.items ? input.items.enum : input.enum

                            let translatedValues = [];

                            if(input && input.attr && input.attr.type && input.attr.type === 'autocomplete_select') {
                                translatedValues = input.options.map(val => {
                                    return val.label;
                                })
                            } else {
                                translatedValues = realValues.map(val => {
                                    return enumList && enumTitleList ? enumTitleList[enumList.indexOf(val)] : val
                                });
                            }

                            delete submittedData[key];
                            submittedData = {
                                ...submittedData,
                                [key]: {
                                    type: 'search',
                                    key: key,
                                    filterValue: translatedValues,
                                    value: realValues,
                                    name: input.title,
                                }
                            }
                        }
                    }
                })
            }

            if (window.searchController) {
                window.searchController.abort();
            }

            return {
                ...state,
                filters: {
                    ...submittedData,
                },
                submittedDataMemory: submittedDataToKeep
            };
        case 'RESET_FORM':
            return defaultState;
        case 'SET_PREFILLING_OBJECT':
            return {
                ...state,
                prefillInputs: action.prefillInputs
            };
        default:
            return state;
    }
};

export default form;

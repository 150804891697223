export default function getIconForWidth(width) {
    switch (+width) {
        case 25:
            return 'width-1-4';
        case 33:
            return 'width-1-3';
        case 50:
            return 'width-half';
        case 66:
            return 'width-2-3';
        case 75:
            return 'width-3-4';
        case 100:
            return 'width-full';
        default:
            return 'circle-notch';
    }
}

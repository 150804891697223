import React from 'react';

export default () => (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1203.61 522.76">
            <title>SFI</title>
            <g id="af464a0a-4da9-4a5c-a035-5fe103f3c54d" data-name="Calque 2">
                <g id="a5380d8b-6f58-4358-a389-23a172fe7c96" data-name="Calque 1">
                    <g>
                        <path
                            d="M0,474.64c2.21-1.57,3.11-4.1,4.47-6.26,8.34-13.34,16.7-26.66,24.81-40.13,1.89-3.15,3.14-2.93,5.8-1,20.72,15.34,43,27.6,68.18,34.11,15.11,3.92,30.44,5.69,46,3.46,8.64-1.24,16.61-4.27,23.44-10,13.19-11,19.77-32.51-2.72-46.93-13.92-8.92-29.15-14.58-44.74-19.57-17.21-5.51-34.76-10-51.11-18-12.06-5.89-23.48-12.71-33.24-22.06-14.7-14.1-22.46-31.17-22.27-51.72.21-24,8.09-44.72,25.51-61.49A106.38,106.38,0,0,1,91.3,208.6C144,194.68,193.39,203,239.35,232.19c1.49.95,2.49,1.58,1.38,3.74q-11.16,21.63-22,43.43c-1.21,2.44-2,2.92-4.59,1.31-25.66-16.3-53.7-24.31-84.15-23.32-11.59.38-22.83,2.43-33.11,8.2-14.14,7.95-19.46,26.33-11.1,38.57,4.55,6.65,11.67,9.53,18.8,12.29,13.65,5.28,28.06,7.91,42.06,12,23.66,6.87,46.69,15.07,67.74,28.33,19,12,30.6,28.57,34.06,50.78,3.05,19.53,1.69,38.66-6.38,56.88-9.84,22.22-27.17,36.95-48.82,46.91-22.11,10.16-45.6,12.53-69.55,11.07-30.37-1.85-59.15-10-86.56-23.1A212.18,212.18,0,0,1,0,476.14Z"
                        />
                        <path
                            d="M1016.88,0c4.77,1.75,9.88,2.18,14.66,4,15.06,5.66,26.31,15.88,35.15,29,8.68,12.89,14.89,26.82,16.11,42.5,1.56,20.05-5.68,37.05-18.46,52.13-10,11.75-22.95,19.27-35.88,27.06-31.24,18.82-62.2,38.13-90.35,61.49C930,222.89,922,229.86,916.77,240c-1.29-4.82-1.37-9.26-1.63-13.63-1.9-31.95-2.25-63.9.56-95.82,2-23.15,5.53-46,12.83-68.21C933,48.72,939,35.91,948.11,24.73,959.28,11,974.61,5,991.3,1.51,993.58,1,996,1.08,998.13,0Z"
                        />
                        <path
                            d="M1203.61,275.94c-2.2,5.23-2.68,11-5.15,16.17-11.51,24-28.44,42.28-54.53,50.32-16.49,5.08-32.55,2.2-48.21-4.25-14.23-5.86-25.35-16-36.75-25.87-26.32-22.84-53-45.2-82.36-64.19-10.12-6.56-20.32-13.1-32.9-15.28,1.84-2,4.31-2.34,6.39-3.19A666.12,666.12,0,0,1,1027.81,203c28.84-7.81,58-13.89,88.06-13.87,16.57,0,32.84,2.16,48,9.68,13.68,6.81,22.39,18.4,29.7,31.18a71.17,71.17,0,0,1,9.48,27.77c0,.32.38.62.58.93Z"
                        />
                        <path
                            d="M404.83,388.9q0,62.42.08,124.82c0,3.46-.93,4.4-4.36,4.37q-28.49-.24-57,0c-3.61,0-4.58-.91-4.58-4.55q.17-124.63.18-249.26c0-4-1.06-5-4.92-4.85-10.24.28-20.49,0-30.73.15-2.59,0-3.67-.33-3.64-3.36.18-15.37.15-30.74,0-46.1,0-2.59.59-3.38,3.27-3.34,10.87.16,21.74,0,32.61.12,2.65,0,3.36-.76,3.35-3.36,0-11-.24-22,.5-33,3.57-53.32,41.33-86.4,81.79-93.2C453.56,72,482.52,80,508.24,99.9c8.56,6.64,8.58,6.63,3.1,16.07-6.58,11.33-13.25,22.61-19.7,34-1.65,2.91-2.67,2.88-4.95.56-8.66-8.79-18.65-15.35-31.16-17.28-25-3.86-45.19,10.43-49.59,35.35-2.12,12-.81,24.18-1.09,36.28-.07,2.8,2.08,1.93,3.49,1.93q27.74.08,55.47,0c6.63,0,13.25.08,19.87,0,2.08,0,3.12.21,3.1,2.75-.12,15.74-.14,31.49,0,47.23,0,3.06-1.56,2.73-3.48,2.73-24.74,0-49.48.06-74.22-.08-3.36,0-4.36.74-4.35,4.26.14,41.72.09,83.45.09,125.18Z"
                        />
                        <path
                            d="M914.29,267.61c-4.57-.17-8.59-1.48-12.62-2.53-20-5.19-39.82-10.9-59.27-17.83-29.63-10.56-58.57-22.66-84.92-40.16-13.62-9.05-26.15-19.36-35.57-33a60.75,60.75,0,0,1-11.14-34.61c-.19-14.14,1.42-27.8,7.44-40.77C726.44,81,740.43,69.8,758.39,63.27a98,98,0,0,1,35.9-6.16q33,.77,53.36,26.73c10.46,13.29,16.14,28.58,19.42,45,2.3,11.58,5.45,23,8.24,34.48,3.29,13.53,7,26.94,11.13,40.25,5.8,18.86,11.68,37.71,21.6,55C909.81,261.61,912.06,264.4,914.29,267.61Z"
                        />
                        <path
                            d="M958.81,255.75c6.73,7.16,12.51,14.45,18.3,21.72,21.83,27.41,41.93,56,58.36,87,9,17,16.78,34.52,21.27,53.29,3.91,16.34,6.15,33.05-.83,48.92-10.67,24.28-28,42-54.51,48.72-14.46,3.65-28.72,1.41-42.58-3.77-14.8-5.54-28.11-13.24-38.35-25.61-12-14.54-15.87-31.44-15.07-49.91.76-17.53,8.06-33,14.7-48.67,12.59-29.72,24.38-59.74,33.29-90.81,3-10.56,5.49-21.26,6.11-32.27C959.65,261.7,959.09,259,958.81,255.75Z"
                        />
                        <path
                            d="M941.34,277.91a586.42,586.42,0,0,1-36,54.57,480.43,480.43,0,0,1-41.77,50.15c-16.5,17-34.18,32.65-55.47,43.8-24,12.54-47.41,11.08-70.85-1.12-17.47-9.08-30-22.42-35.85-41.49a77.56,77.56,0,0,1-3.23-25.51c.52-15.51,3.42-30.46,11.44-44.05,9.43-16,23.65-25.52,41.12-30.82,16.41-5,33-3.85,49.73-2.2,22.12,2.19,44.3,3.47,66.53,4a415.57,415.57,0,0,0,48.65-1.14,92.38,92.38,0,0,0,13.31-2A51.83,51.83,0,0,0,941.34,277.91Z"
                        />
                        <path
                            d="M549.66,362.15q0-75.7-.07-151.4c0-3,.57-4,3.83-4q29.24.25,58.46,0c3,0,3.74.76,3.74,3.73q-.14,152,0,303.91c0,2.76-.64,3.67-3.49,3.66-19.74-.11-39.47-.14-59.21,0-3.17,0-3.27-1.35-3.27-3.8q.07-76.06,0-152.14Z"
                        />
                        <path
                            d="M583.06,74.1a41.64,41.64,0,1,1,0,83.27c-23.06.08-42.15-20.22-41.61-42.63C542,92.8,560.68,74.09,583.06,74.1Z"
                        />
                    </g>
                </g>
            </g>
        </svg>

    </>
);
import ErrorBoundary from "components/Error/errorBoundary";
import React, {useState} from "react";
import {QUOTE} from "v4/data/entities";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ListingEntityLayout from "v4/layouts/ListingEntityLayout/ListingEntityLayout";
import ListingHeaderEntityLayout from "v4/layouts/ListingHeaderEntityLayout/ListingHeaderEntityLayout";
import QuotesListingRowActions
    from "v4/pages/front/Quotes/components/QuotesListing/components/QuotesListingRowActions/QuotesListingRowActions";
import QuotesListingTools
    from "v4/pages/front/Quotes/components/QuotesListing/components/QuotesListingTools/QuotesListingTools";

export default function QuotesListing({tab}) {
    const {t} = usePolTranslation();
    const [totalItems, setTotalItems] = useState(null);

    return (
        <>
            <div className="page-list__content">
                <div className="table-container">
                    <ErrorBoundary>
                        <ListingHeaderEntityLayout totalItems={totalItems}
                                                   title={tab?.title ?? t('devis_offres')}
                                                   pageType='devis'
                                                   resultsText={
                                                       t('resultats_elements_devis', {count: totalItems})
                                                   }/>
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <ListingEntityLayout entity={QUOTE}
                                             tools={QuotesListingTools}
                                             rowActions={<QuotesListingRowActions tab={tab}/>}
                                             tableClassName="devis"
                                             setTotalItems={setTotalItems}
                                             tab={tab}/>
                    </ErrorBoundary>
                </div>
            </div>
        </>
    )
}

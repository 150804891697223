import React from 'react';
import * as moment from "moment";

export const FolderItemList = ({
                                    folder: {id, createdAt}, isEditFolderName, editItemSpot,
                                   resetEditFile, handleEditedFile, inputValue,
                                   setFolderName, setInputValue, selfFolderName
                               }) => {

    return (
        <>
            <td>
                {isEditFolderName && editItemSpot === 'container' ? (
                    <>
                        <input onBlur={() => {
                            if (resetEditFile) {
                                resetEditFile()
                            }
                            handleEditedFile({
                                folderName: inputValue,
                                id: id
                            })
                        }} className={'ged-component__input-rename'} onKeyPress={(e) => {
                            if (e.charCode === 13) {
                                handleEditedFile({
                                    folderName: inputValue,
                                    id: id
                                })
                                setFolderName(inputValue);
                            }
                        }} onChange={(e) => {
                            setInputValue(e.target.value)
                        }} type={"text"} defaultValue={selfFolderName}/>
                    </>
                ) : selfFolderName}
            </td>
            <td>
                {moment(createdAt).format('lll')}
            </td>
            <td>
            </td>
            <td>
                Dossier
            </td>
        </>
    )
}
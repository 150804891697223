import {PolIcon} from "components/PolIcon/policon";
import BgAll from "img/bg_all.svg";
import BgInLate from "img/bg_in_late.svg";
import BgOk from "img/bg_ok.svg";
import React, {useCallback, useEffect, useMemo} from 'react';
import {useHistory} from "react-router-dom";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import TimelineItem
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOpenTasksTab/components/ProspectOpenTasksTabResume/components/TimelineItem/TimelineItem";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOpenTasksTab/components/ProspectOpenTasksTabResume/ProspectOpenTasksTabResume.scss';
import {getFieldsValuesByKey} from "v4/utils";
import {generateUrl} from "v4/services/api.service";
import {PROSPECT_TASKS_RESUME} from "v4/data/apiRoutes";


export default function ProspectOpenTasksTabResume(prospectId) {
    const {t} = usePolTranslation();

    const {goBack} = useHistory();

    const [{data}, fetchTasks] = useFetch();

    useEffect(() => {
        fetchTasks({
            url: generateUrl(PROSPECT_TASKS_RESUME, prospectId)
        });
    }, [fetchTasks, prospectId]);

    const tasks = useMemo(() => data?.['hydra:member']?.results ?? [], [data]);

    const [showOnGoing, toggleShowOngoing] = useToggle(true);
    const [showLate, toggleShowLate] = useToggle(true);
    const [showClosed, toggleShowClosed] = useToggle(true);

    const filterTasksToShow = useCallback(task => {
        const status = getFieldsValuesByKey('status', task);
        if (status === 'ongoing') {
            return showOnGoing;
        }
        if (status === 'late') {
            return showLate;
        }
        if (status === 'closed') {
            return showClosed;
        }
        return false;
    }, [showOnGoing, showLate, showClosed]);

    return (
        <>
            <div className="resume-tab__header">
                <h2>{t('tasks_summary')}</h2>
                <div className="resume-tab__header__actions">
                    <div className="resume-tab__header__actions__filters">
                        <button
                            className={`summaryTask__header__button summaryTask__header__button--ongoing ${showOnGoing && 'summaryTask__header__button--active'}`}
                            onClick={toggleShowOngoing}>
                            <PolIcon icon="clock"/>
                        </button>
                        <button
                            className={`summaryTask__header__button summaryTask__header__button--late ${showLate && 'summaryTask__header__button--active'}`}
                            onClick={toggleShowLate}>
                            <PolIcon icon="clock"/>
                        </button>
                        <button
                            className={`summaryTask__header__button summaryTask__header__button--closed ${showClosed && 'summaryTask__header__button--active'}`}
                            onClick={toggleShowClosed}>
                            <PolIcon icon="clock"/>
                        </button>
                    </div>
                    <div className="prospect-tab__prospect__header__back" onClick={goBack}>
                        <PolIcon icon='return'/>
                        <span>{t('retour')}</span>
                    </div>
                </div>
            </div>
            <div className="resume-tab__body">
                <div className="resume-tab__body__scroll">
                    {tasks
                        .filter(filterTasksToShow)
                        .map((task) => (
                            <TimelineItem task={task} key={task.metadata.id}/>
                        ))
                    }
                    {!showOnGoing && !showClosed && !showLate && <div>{t('select_action_type')}</div>}
                </div>
                {
                    (!showOnGoing && !showClosed && showLate
                        && <img className="resume-tab__body__bg"
                                alt={t('tasks_summary_late')}
                                src={BgInLate}/>
                    )
                    ||
                    ((showOnGoing || showClosed) && !showLate
                        && <img className="resume-tab__body__bg summaryTask__img--finish"
                                alt={t('tasks_summary_done')}
                                src={BgOk}/>
                    )
                    ||
                    <img className="resume-tab__body__bg summaryTask__img--all"
                         alt={t('tasks_summary')}
                         src={BgAll}/>
                }
            </div>
        </>
    )
}

import {PRODUCT} from "v4/data/entities";
import ProductVoter from "v4/voters/productVoter";

export default function GenericVoter(entity, method) {
    let voterModel = null;

    switch (entity) {
        case PRODUCT:
            voterModel = ProductVoter()
            return voterModel[method] ? voterModel[method]() : true
        default:
            return true
    }
}

import React, {useMemo, useEffect, useState, useCallback} from 'react';
import Table from "v4/components/ui/Table/Table";
import {Link} from "react-router-dom";
import {routesBase} from 'v4/data/appRoutes'
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import apiService from "v4/services/api.service";
import {
    CUSTOMER_EVENT_SERVICE_LIST,
} from "v4/data/apiRoutes";
import {PolIcon} from "components/PolIcon/policon";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import useNotification from "v4/hooks/useNotification";
import CustomerEventServicesListDelete
    from "v4/pages/admin/CustomerEventServices/components/CustomerEventServicesListDelete/CustomerEventServicesListDelete";
export default function CustomerEventServicesList({customerId}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()
    // Listing
    const [{
        data: loadedCustomerEventServices,
        isLoading: loadedCustomerEventServicesLoading,
        isError: loadedCustomerEventServicesError
    }, fetchCustomerEventServices] = useFetch();
    const [refetch, setRefetch] = useState(0)
    useEffect(() => {
        fetchCustomerEventServices({
            url: apiService.generateUrl(CUSTOMER_EVENT_SERVICE_LIST, {
                sortField: "name",
                sortOrder: "asc",
                customerId: customerId
            }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])
    const onDeleteSuccess = useCallback(() => {
        addSuccessNotification(t('delete_customer_event_service_done'))
        setRefetch(c => c + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])
    const onDeleteError = useCallback(() => {
        addErrorNotification(t('delete_customer_event_service_error'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const hydrateCustomerEventServiceRows = useCallback(() => {
        if (!loadedCustomerEventServices?.['hydra:member']) {
            return null
        }
        const rows = JSON.parse(JSON.stringify(loadedCustomerEventServices['hydra:member']))
        return (
            rows.map((item, index) => {
                return {
                    eventName: item.eventName,
                    servicePath: item.servicePath,
                    provider: item.provider,
                    actions: (
                        <ul className="datatable__actions">
                            <Link key={index} to={`${routesBase.adminCustomerEventServices}/edit/${item.id}`}>
                                <li className="datatable__actions__item">
                                    <span className="datatable__actions__item__icon">
                                        <PolIcon icon='edit'/>
                                    </span>
                                </li>
                            </Link>
                            <CustomerEventServicesListDelete item={item} onSuccessCallback={onDeleteSuccess}
                                                           onErrorCallBack={onDeleteError}/>
                        </ul>
                    )
                };
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedCustomerEventServices])
    const tableList = useMemo(() => {
        return <Table tableRows={hydrateCustomerEventServiceRows()} isLoading={loadedCustomerEventServicesLoading}
                      isError={loadedCustomerEventServicesError}
                      theadData={Object.keys({eventName: 'eventName', servicePath: 'servicePath', provider: 'provider', actions: 'actions'})}/>
    }, [hydrateCustomerEventServiceRows, loadedCustomerEventServicesLoading, loadedCustomerEventServicesError])
    const CardHeaderTools = () => (
        <Link to={`${routesBase.adminCustomerEventServices}/add`}
              className="btn btn-primary">{t('add_new_customer_event_service')}</Link>
    )
    return (
        <AdminCard title={t('manage_customer_event_services')} CardHeaderTools={CardHeaderTools}>
            {tableList}
        </AdminCard>
    )
}
import ErrorBoundary from "components/Error/errorBoundary";
import {PolIcon} from "components/PolIcon/policon";
import React, {useState} from 'react';
import {DragDropContext} from "react-beautiful-dnd";
import {useFormContext} from "react-hook-form";
import ReactTooltip from "react-tooltip";
import QuotesProductsTableAttributesPopup
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuotesProductsTableAttributesPopup/QuotesProductsTableAttributesPopup";
import QuotesProductsTableSection
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuotesProductsTableSection/QuotesProductsTableSection";

import 'v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/QuotesProductsTable.scss';
import {
    useQuotesProductsDetailsContext
} from "v4/features/front/products/components/QuotesProductsDetails/contexts/QuotesProductsDetailsContext/QuotesProductsDetailsContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useQuoteTabContext} from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/contexts/QuoteTabContext.jsx";
import ProductVoter from "v4/voters/productVoter";

export default function QuotesProductsTable({
                                                addLine,
                                                togglePopup,
                                                isShow,
                                                isQuoteFetchLoading,
                                            }) {
    const {t} = usePolTranslation();
    const {canProductShowList} = ProductVoter();

    const {
        quoteLinesMethods: {fields, replace},
        sectionsNames,
        addSection,
    } = useQuotesProductsDetailsContext();

    const [quoteLineAttributesFor, setQuoteLineAttributesFor] = useState(null);
    const {toggleCheckCustomerEventService} = useQuoteTabContext();

    const editAttributesFor = (index) => {
        setQuoteLineAttributesFor({
            index: index,
            productId: fields[index].quoteLineInfo.product.id
        });
    }
    const closePopup = () => {
        setQuoteLineAttributesFor(null);
        toggleCheckCustomerEventService();
    }

    const {getValues} = useFormContext();
    const onDragEnd = (result) => {
        const {source, destination, draggableId} = result;

        if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) return;

        const realIndex = fields.findIndex(({id}) => id === draggableId);

        // using getValues instead of fields because fields are not always updated
        const sectionNameSource = getValues(`quoteLines[${realIndex}].sectionName`) ?? '';
        const sectionIndexSource = source.index;

        const sectionNameDestination = sectionsNames.find(({id}) => id === destination.droppableId).name;
        const sectionIndexDestination = destination.index;

        const fieldsBySection = sectionsNames.reduce((acc, sectionName) => {
            acc[sectionName.name] = getValues('quoteLines').filter(({sectionName: sectionNameField}) => (sectionNameField ?? '') === sectionName.name);

            return acc;
        }, {});

        if (fieldsBySection[sectionNameSource]) {
            fieldsBySection[sectionNameSource].splice(sectionIndexSource, 1);
        }
        if (fieldsBySection[sectionNameDestination]) {
            fieldsBySection[sectionNameDestination].splice(sectionIndexDestination, 0, {
                ...getValues(`quoteLines.${realIndex}`),
                sectionName: sectionNameDestination
            });
        }

        replace(Object.values(fieldsBySection).flat());
    }

    return (
        <>
            <div className={`quotes-products__table${isShow ? ' is-show' : ''}`}>
                <div className={`quotes-products__thead${fields.length === 0 ? ' empty' : ''}`}>
                    {
                        fields.length > 0
                            ? <>
                                <div className="products__thead__img"></div>
                                <div className="products__thead__optional">
                                    {t('optional')}
                                    <span data-tip={t('quotes_products_optional_help')} data-for="optional"><PolIcon
                                        icon="question"/></span>
                                    <ReactTooltip id="optional" effect="solid"/>
                                </div>
                                <div className="products__thead__desc">{t('designation')}</div>
                                {/*isShow && <div className="products__thead__attributes">{t('product_attributes')}</div>*/}
                                <div className="products__thead__price">{t('quotes_unit_price')}</div>
                                <div className="products__thead__quantity">{t('quantity')}</div>
                                <div className="products__thead__discount">{t('discount')}</div>
                                <div className="products__thead__vat">{t('vat')}</div>
                                <div className="products__thead__total">{t('excl_taxes_amount')}</div>
                            </>
                            : (
                                <div className="quotes-products__no-products">
                                    <div>
                                        {t('no_quote_line')}
                                        {isQuoteFetchLoading && <PolIcon icon={'circle-notch'}/>}
                                    </div>
                                </div>
                            )
                    }
                    {
                        !isShow && !isQuoteFetchLoading && (
                            <div className="products__thead__actions">
                                <div className="quotes-products__actions">
                                    <button type="button" onClick={addLine}>
                                        <PolIcon icon="plus"/>
                                    </button>
                                    {canProductShowList() &&
                                        <button type="button" onClick={togglePopup}>
                                            <PolIcon icon="search-plus"/>
                                        </button>
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="quotes-products__tbody">
                        {
                            sectionsNames.map(({name, id, isDefault}, sectionIndex) => (
                                <QuotesProductsTableSection key={id}
                                                            isShow={isShow}
                                                            name={name}
                                                            id={id}
                                                            sectionIndex={sectionIndex}
                                                            isDefault={isDefault}
                                                            editAttributesFor={editAttributesFor}/>
                            ))
                        }
                    </div>
                </DragDropContext>
            </div>
            {!isShow && (
                <div className="quotes-products__add-section">
                    <button type="button"
                            disabled={sectionsNames.some(({isDefault}) => isDefault)}
                            onClick={addSection}>
                        <PolIcon icon="plus"/>
                        {t('new_section')}
                    </button>
                </div>
            )}
            {quoteLineAttributesFor !== null &&
                <ErrorBoundary>
                    <QuotesProductsTableAttributesPopup quoteLineAttributesFor={quoteLineAttributesFor}
                                                        closePopup={closePopup} isShow={isShow}/>
                </ErrorBoundary>
            }
        </>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect, useRef} from 'react';
import {useFormContext} from "react-hook-form";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import {useQuoteTabContext} from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/contexts/QuoteTabContext";
import {getUserEvents, userEventsService} from "v4/services/userEventsService";

export default function ProspectQuotesRecalculateButton({eventForRecalculate}) {
    const {t} = usePolTranslation();
    const methods = useFormContext();
    const {addErrorNotification} = useNotification();
    const [isLoading, toggleIsLoading] = useToggle(false);
    const {quoteLinesMethods, checkCustomerEventService} = useQuoteTabContext();

    const userEvents = getUserEvents();
    const isEventExisting = userEvents.some(({eventName}) => eventName.split(',').includes(eventForRecalculate));
    let recalculate = null;

    const firstRender = useRef(true);

    useEffect(() => {
        if (!firstRender.current && recalculate && quoteLinesMethods && isEventExisting) {
            recalculate();
        }

        firstRender.current = false;
    }, [recalculate, checkCustomerEventService, quoteLinesMethods, isEventExisting]);

    if (!isEventExisting) return null;

    recalculate = async () => {
        toggleIsLoading();
        const {
            alteredData,
            errors = []
        } = await userEventsService([eventForRecalculate], methods.getValues());
        toggleIsLoading();
        if (errors?.length > 0) {
            errors.forEach(error => addErrorNotification(error));
            return;
        }

        Object.entries(alteredData ?? {}).forEach(([key, value]) => {
            if (key === "quoteLines") {
                quoteLinesMethods.replace(value);
            } else {
                if (JSON.stringify(methods.getValues(key)) === JSON.stringify(value)) return;

                methods.setValue(key, value ?? '', {shouldValidate: true});
            }
        })
    }

    return (
        <div className="form__recalculate">
            <button type="button" className="btn form__submit__btn" onClick={recalculate}>
                <span className="form__submit__btn__icon">
                    <PolIcon icon="calculator"/>
                </span>
                {t('recalculate')}
                {isLoading && <PolIcon icon="circle-notch" className="spinner"/>}
            </button>
        </div>
    )
}

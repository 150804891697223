import React, {Component} from 'react';
import {PolIcon} from "../../PolIcon/policon";
import ReactTooltip from "react-tooltip";
import {withPolTranslation} from "v4/hooks/usePolTranslation";

class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     *
     * @param {Boolean} isVisibleFilter
     * @param {string} className "Nom de class CSS"
     * @returns {string} className
     */
    handleClassName = (isVisibleFilter, className) => {
        return isVisibleFilter ? className : `${className}-select`
    }

    render() {
        const {onClick, index, isVisibleFilter = null} = this.props;
        let {filterValue, filterLabel, t} = this.props;
        if(filterValue.value === 'all'){
            return null;
        }
        if (typeof filterValue === 'object' && filterValue.type !== 'search') {
            let newValue = '';
            Object.keys(filterValue).forEach(key => {
                if (filterValue[key] && filterValue[key].length > 0) {
                    newValue += filterValue[key];
                    newValue += key !== 'equal' ? ' | ' : ' ';
                }
            });
            filterValue = newValue.substr(0, newValue.length - 2);
        } else if (typeof filterValue === 'object' && filterValue.type === 'search') {
            filterLabel = filterValue.name
            filterValue = Array.isArray(filterValue.value) ? filterValue.value.join(' ; ') : filterValue.filterValue ?
                filterValue.filterValue : filterValue.value
        }
        if (typeof filterValue === 'boolean') {
            filterValue = filterValue ? t('oui') : t('non')
        }

        return (
            <li className={this.handleClassName(isVisibleFilter, 'table-active-filter__item')}
                id={index}
            >
                {onClick &&
                    <PolIcon icon={'times-circle'}
                             className={'table-active-filter__item__icon'}
                             onClick={onClick}
                             data-tip={t('delete')}
                    />
                }
                <span
                    className={'table-active-filter__item__key'}>{t(filterLabel)}</span>
                <span
                    className={'table-active-filter__item__key'}> : </span>
                <span
                    className={'table-active-filter__item__value'}>{filterValue === 'empty' ? t('empty') : t(filterValue)}</span>
                <ReactTooltip effect="solid"/>
            </li>
        )
    }
}

export default withPolTranslation(Filter);

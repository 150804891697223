import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {useHistory} from "react-router-dom";
import {useFrontContext} from "v4/contexts/FrontContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function FullPageFormLayout({title, children}) {
    const {t} = usePolTranslation();
    const {goBackToListing} = useFrontContext();
    const {goBack} = useHistory();


    return (
        <div className="fullpage page page-details">
            <div className="page-details__content">
                <div className="page-details__close">
                    <span className="page-details__close__icon" onClick={goBackToListing ?? goBack}>
                        <PolIcon icon='quit'/>
                    </span>
                </div>
                <div className="page-details__prospect">
                    <div className="row row-fluid page-details__prospect__title">
                        <div className="col-auto col-fluid page-details__prospect__name">
                            <p>{t(title)}</p>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useRouteMatch} from "react-router-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {QUOTE_ADD, QUOTE_DUPLICATE_FORM, QUOTE_FORM, QUOTE_ITEM, QUOTE_ITEM_FORM} from "v4/data/apiRoutes";
import useTestRoute from "v4/hooks/useTestRoute";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import ProspectQuotesRecalculateButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesRecalculateButton/ProspectQuotesRecalculateButton";
import ProspectQuotesTabListing
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuotesTabListing/ProspectQuotesTabListing";
import ProspectQuoteTabShow
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/ProspectQuoteTabShow";


export default function ProspectQuotesTab({prospectId}) {
    const isShow = useTestRoute({inParams: 'showId'});
    const isEdit = useTestRoute({inUrl: 'edit'});
    const isAdd = useTestRoute({inUrl: 'add'});
    const isListing = !isShow && !isEdit && !isAdd;
    const {params: {showId = null}} = useRouteMatch();
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const taskId = queryParameters.get('taskId') ?? null;
    const duplicateFrom = queryParameters.get('duplicateFrom') ?? null;
    const [quoteData, setQuoteData] = useState(null);

    const {setQuote} = useProspectPageContext();

    const onGetData = useCallback((data) => {
        const hydraMember = data?.['hydra:member'] ?? data;
        setQuoteData(hydraMember);
        if (hydraMember) {
            const quoteFromHydra = Object.values(hydraMember.results).reduce((acc, {properties}) => {
                properties.forEach(({key, value}) => {
                    acc[key] = value;
                })
                return acc;
            }, {});
            quoteFromHydra.metadata = hydraMember.metadata;
            setQuote(quoteFromHydra);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        quoteData && onGetData(quoteData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteData]);

    const fetchFormParameters = useMemo(() => ({
        id: showId,
        prospectId,
        taskId,
        duplicateFrom
    }), [showId, prospectId, taskId, duplicateFrom]);

    let formRoute = isAdd ? QUOTE_FORM : QUOTE_ITEM_FORM;
    formRoute = duplicateFrom ? QUOTE_DUPLICATE_FORM : formRoute;
    let shouldWarnOnChangePage = false;
    if (duplicateFrom) {
        shouldWarnOnChangePage = true;
    }

    return (
        <>
            <ToggleComponent isShowing={isListing}>
                <ProspectQuotesTabListing prospectId={prospectId}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isShow && !isEdit}
                             shouldRemoveFromDom={true}
                             className="prospect-tab__prospect showing">
                <ProspectQuoteTabShow prospectId={prospectId}
                                      showId={showId}
                                      onGetData={onGetData}
                                      quoteData={quoteData}
                                      setQuoteData={setQuoteData}/>
            </ToggleComponent>

            <ToggleComponent isShowing={isAdd || isEdit}
                             shouldRemoveFromDom={true}
                             className="prospect-tab__prospect editing">
                <FormEntityLayout title="devis_offres"
                                  formRouteName={formRoute}
                                  submitRouteName={isAdd ? QUOTE_ADD : QUOTE_ITEM}
                                  fetchFormParameters={fetchFormParameters}
                                  onGetData={onGetData}
                                  additionalButtons={() => <ProspectQuotesRecalculateButton eventForRecalculate={`beforesave:${isAdd ? QUOTE_ADD : QUOTE_ITEM}`}/>}
                                  shouldWarnOnChangePage={shouldWarnOnChangePage}
                                  whenSubmitted={whenSubmitted}/>
            </ToggleComponent>
        </>
    )
}

export function whenSubmitted(history, data) {
    if (history.location.pathname.includes('/add') && data.id) {
        history.replace(history.location.pathname.replace('add', data.id), {
            hasToRefresh: true,
            verifyProspectSubscriptions: true
        });
        return;
    }
    history.replace(history.location.pathname.replace('/edit', '').replace('/add', ''), {
        hasToRefresh: true,
        verifyProspectSubscriptions: true
    });
}

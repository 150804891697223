import {useCallback, useEffect} from "react";
import {useHistory} from "react-router-dom";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

export default function useDeleteEntity(deleteUrl, id, toggleModal) {
    const {replace} = useHistory();
    const [{isFinished, isError}, fetchDelete] = useFetch();

    const deleteContact = useCallback(() => {
        fetchDelete({
            url: generateUrl(deleteUrl, {id}),
            config: {
                method: 'DELETE'
            }
        });
        toggleModal();
    }, [fetchDelete, id, toggleModal, deleteUrl]);

    useEffect(() => {
        if (isFinished && !isError) {
            replace(({pathname}) => pathname, {hasToRefresh: true});
        }
    }, [isFinished, isError, replace]);

    return deleteContact;
}

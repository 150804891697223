import React from 'react';
import './AdminSimpleTable.scss';

export default function AdminSimpleTable({children, className = ''}) {
    return (
        <table className={`table-admin ${className}`}>
            {children}
        </table>
    )
}

import React, {useState, useEffect} from "react";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {getJsonFromCsv} from "../../../../utils/csvParser";
import StepTwoHeader from "./_stepTwoHeader"
import StepTwoDatatable from "./_stepTwoDatatable"
import ImportInfo from "../../../../components/Admin/_partials/import_info";
import {adminFetchHandler} from "../../../../api/datas/datas";
import {useRecoilState, useRecoilValue} from "recoil";
import {customerIdSelector} from "../../../../components/Admin/atoms/customer";
import {importDataState, importProgression} from "../../../../components/Admin/atoms/importAdmin";
import {connect} from "react-redux";
import {setPopup} from "../../../../actions/content";
import {postAdmin} from "../../../../api/datas/post";
import {resetStoreDatas} from "../../../../actions/datas";
import PopupInfoConfirm from "../../../../components/Popup/_popup_info_confirm";
import PopupInfo from "../../../../components/Popup/_popup_info";

const ImportStepTwo = ({
                           t, formValues, setLoader, setStep, fieldsAvailable, setPopup,
                           adminFetchHandler, resetStoreDatas, setProgressBar
                       }) => {
    const [stepTwoValues, setStepTwoValues] = useState({});
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState({});
    const [required, setRequired] = useState([]);
    const [affectedColsLength, setAffectedColsLength] = useState(0);
    const [outputs, setOutputs] = useState([]);
    const [concatFields, setConcatFields] = useState({});
    const [validationErrors, setValidationErrors] = useState([]);
    const [isEditTabs, setEditTabs] = useState([]);
    const customerId = useRecoilValue(customerIdSelector);
    const [importableObjects, setImportableObjects] = useState(0);
    const newData = useRecoilValue(importDataState);
    const [importProgress, setImportProgress] = useRecoilState(importProgression)
    const [nbColumnsToAffect, setNbColumnsToAffect] = useState(0)

    const addData = (newData) => {
        setData([...data, ...newData])
    }

    if (nbColumnsToAffect === 0 && data.length) {
        setNbColumnsToAffect(data && data[0] ? Object.keys(data[0]).length : 0)
    }

    useEffect(() => {
        const fieldsAvailableJSON = JSON.parse(fieldsAvailable);
        if (fieldsAvailableJSON
            && fieldsAvailableJSON.items
            && formValues
            && formValues.entityToImport
            && fieldsAvailableJSON.items[formValues.entityToImport]
        ) {
            let columns = fieldsAvailableJSON.items[formValues.entityToImport];
            if (columns.contact) {
                Object.keys(columns.contact).forEach((key) => {
                    for (let i = 1; i <= 5; i++) {
                        columns = {
                            ...columns,
                            [key.replace('contact_1', 'contact_' + i)]: columns.contact[key].replace('Contact_1', 'Contact_' + i)
                        }
                    }
                })
            }
            delete columns.contact;
            setColumns({...columns})
            setRequired(fieldsAvailableJSON.required[formValues.entityToImport])
        }
        setLoader(true)
        getJsonFromCsv(formValues.csvFile, (data) => {
            addData(data)
            setLoader(false)
        })  // eslint-disable-next-line
    }, [formValues]);

    const validate = () => {
        const data = {
            ...formValues,
            data: outputs,
            isDeleteCustomerData: formValues.isDeleteCustomerData === "1",
            isSendMailAfterImport: formValues.isSendMailAfterImport === "1",
            customerId: customerId,
            concatFields: concatFields
        }
        const validateUrl = '/api/validate_data_import_model';
        setProgressBar(true)

        postAdmin(validateUrl, (error) => {
            adminFetchHandler(error, {})
            setProgressBar(false)
            setImportProgress({})
        }, (data) => {
            setValidationErrors([...data.errors])
            setEditTabs([...data.isEditTabs])
            setImportProgress({
                ...importProgress,
                isFinished: true,
                timeRemaining: 0
            })
            setTimeout(() => {
                setProgressBar(false)
                setImportProgress({})
            }, 1500)
        }, data)
    }

    const handleImport = () => {
        setPopup({

            isOpen: true,
            content: (
                <PopupInfoConfirm
                    message={t('import_data_confirm', {
                        countValid: importableObjects,
                        entityToImport: formValues.entityToImport
                    })}
                    onAccept={() => {
                        const data = {
                            ...formValues,
                            data: outputs.filter(item => item.import_status !== false),
                            isDeleteCustomerData: formValues.isDeleteCustomerData === "1",
                            isSendMailAfterImport: formValues.isSendMailAfterImport === "1",
                            customerId: customerId,
                            csvFileName: formValues.csvFile.name,
                            concatFields: concatFields
                        }
                        const importUrl = '/api/import_data_import_model';

                        setProgressBar(true)
                        postAdmin(importUrl, (error) => {
                            adminFetchHandler(error, {})
                            setProgressBar(false)
                            setImportProgress({})
                        }, (data) => {
                            if (data && !data.error) {
                                setImportProgress({
                                    ...importProgress,
                                    isFinished: true,
                                    timeRemaining: 0
                                })
                                setTimeout(() => {
                                    setProgressBar(false)
                                    setImportProgress({})
                                }, 1500)
                                setStep(1);
                                setPopup({
                                    isOpen: true,
                                    content: (
                                        <PopupInfo
                                            message={t('import_ok', {
                                                count_new: data.new,
                                                count_edit: data.edit,
                                                entityToImport: formValues.entityToImport
                                            })}
                                            onAccept={() => {
                                                resetStoreDatas()
                                                setPopup({})
                                            }}
                                        />
                                    )
                                })
                            } else {
                                setProgressBar(false)
                                adminFetchHandler("", {})
                            }
                        }, data)
                        setPopup({})
                    }}
                    onDecline={() => {
                        setPopup({})
                    }
                    }
                />
            )
        })
    }

    return (
        <>
            <div className={'row'}>
                <div className={'col-12'}>
                    <section className={'box'}>
                        <div className={'box__header'}>
                            <p className={'box__header__text'}>{t('data_matching')}</p>
                        </div>
                        <div className={'box__body'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <StepTwoHeader formValues={formValues}
                                                   setStepTwoValues={setStepTwoValues}
                                                   stepTwoValues={stepTwoValues}
                                    />
                                </div>
                            </div>

                            {Object.keys(columns).length ? (
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <StepTwoDatatable required={required}
                                                          data={data}
                                                          setData={setData}
                                                          setAffectedColsLength={setAffectedColsLength}
                                                          columns={columns}
                                                          setOutputs={setOutputs}
                                                          validationErrors={validationErrors}
                                                          formValues={formValues}
                                                          setImportableObjects={setImportableObjects}
                                                          isEditTabs={isEditTabs}
                                                          setConcatFields={setConcatFields}
                                        /></div>
                                </div>) : null}
                            <div className={'row'}>
                                <div className={'col-12 admin-info-mt-15'}>
                                    <ImportInfo dataLength={newData.length}
                                                entityToImport={formValues ? formValues.entityToImport : ''}
                                                colsLength={nbColumnsToAffect}
                                                affectedColsLength={affectedColsLength}
                                                importableObjects={importableObjects}
                                    />
                                </div>
                            </div>
                            <div className={'row align-items-center justify-content-end'}>
                                <div className={`admin__flex-row col-12 col-md-auto form__submit`}>
                    <span className={'btn btn-primary form__submit__btn'} onClick={() => {
                        setStep(1)
                    }}>
                        {t('retour')}
                    </span>
                                    <span
                                        className={`btn btn-primary form__submit__btn ${importableObjects > 0 ? 'form__submit__btn__disabled' : ''}`}
                                        onClick={() => {
                                            validate()
                                        }}>
                        {t('validate')}
                    </span>
                                    <span
                                        className={`btn btn-primary form__submit__btn ${importableObjects === 0 ? 'form__submit__btn__disabled' : ''}`}
                                        onClick={() => {
                                            handleImport()
                                        }}>
                        {t('import')}
                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setPopup: (info) => dispatch(setPopup(info)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        resetStoreDatas: () => dispatch(resetStoreDatas()),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(React.memo(ImportStepTwo)));

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ProspectTasksCustomTabActions() {
    const {t} = usePolTranslation();

    return (
        <Link to={({pathname}) => `${pathname}/add`} className="prospect-tab__action">
            <PolIcon icon="plus-circle"/>
            <span>{t('add')}</span>
        </Link>
    )
}

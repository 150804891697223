import React, {useMemo} from "react";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import QuoteIntranetPopupButtons
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/components/ProspectWonQuoteTabShow/components/ProspectWonQuoteIntranetButton/components/QuoteIntranetPopupButtons/QuoteIntranetPopupButtons";
import QuoteIntranetPopupContent
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/components/ProspectWonQuoteTabShow/components/ProspectWonQuoteIntranetButton/components/QuoteIntranetPopupContent/QuoteIntranetPopupContent";

export default function useQuoteIntranetButton() {
    const {t} = usePolTranslation();
    const {quote} = useProspectPageContext();
    const [isOpen, toggleOpen] = useToggle();
    const [canExport, toggleCanExport] = useToggle();

    const {
        hasContractNumber,
        hasWorkType,
        hasSignedAt
    } = useMemo(() => {
        return {
            hasContractNumber: quote.contractNumber?.length > 0,
            hasWorkType: quote.sf_field_335?.length > 0,
            hasSignedAt: quote.expectedSignedAt?.length > 0
        }
    }, [quote]);

    const tooltipMessage = useMemo(() => {
        let message = [];

        if (hasContractNumber) {
            message.push(<p key="hasContractNumber">{t('intranet_has_contract_number')}</p>);
            return message;
        }

        if (!hasWorkType) {
            message.push(<p key="hasWorkType">{t('intranet_has_no_work_type')}</p>);
        }

        if (!hasSignedAt) {
            message.push(<p key="hasSignedAt">{t('intranet_has_no_signed_at')}</p>);
        }

        return message?.length > 0 ? message : undefined;
    }, [hasContractNumber, hasWorkType, hasSignedAt, t]);

    const components = useMemo(() => ({
        children: <QuoteIntranetPopupContent allowExport={toggleCanExport}/>,
        buttons: <QuoteIntranetPopupButtons canExport={canExport} closePopup={toggleOpen}/>,
    }), [canExport, toggleCanExport, toggleOpen]);

    return {
        isDisabled: !!tooltipMessage,
        tooltipMessage,
        isOpen,
        toggleOpen,
        components,
    }
}

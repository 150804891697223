import React, {createContext, useContext, useState} from "react";
import useToggle from "../../../../../../hooks/useToggle";

const defaultValues = {
    quoteLinesMethods: null,
    setQuoteLinesMethods: () => {
    },
    checkCustomerEventService: false,
    toggleCheckCustomerEventService: () => {
    }
};

const QuoteTabContext = createContext(defaultValues);

export function useQuoteTabContext() {
    return useContext(QuoteTabContext);
}

export default function QuoteTabProvider({children}) {
    const [quoteLinesMethods, setQuoteLinesMethods] = useState(null);
    const [checkCustomerEventService, toggleCheckCustomerEventService] = useToggle();
    const returnValue = {
        quoteLinesMethods,
        setQuoteLinesMethods,
        checkCustomerEventService,
        toggleCheckCustomerEventService
    };

    return (
        <QuoteTabContext.Provider value={returnValue}>
            {children}
        </QuoteTabContext.Provider>
    )
}
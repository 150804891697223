import {PolIcon} from "components/PolIcon/policon";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {routesBase} from "v4/data/appRoutes";
import {TAB_CONTACTS} from "v4/data/tabNames";
import GeneratePdf from "v4/features/front/generatePdf/components/GeneratePdf/GeneratePdf";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import Confirm from "v4/components/ui/Confirm/Confirm";

export default function ActionsButtonsPdf({quoteId, quoteName, prospectId, contactIds, updateHandler, currentFile}) {
    const {t} = usePolTranslation()
    const [isPopupPDFOpen, toggleIsPopupPDFOpen] = useToggle(false)
    const [isConfirmPDFOpen, toggleIsConfirmPDFOpen] = useToggle(false)
    const [PDFMode, setPDFMode] = useState(null)

    const handleGeneratePopup = (e) => {
        e.preventDefault();
        currentFile?.length > 0 ? toggleIsConfirmPDFOpen() : toggleIsPopupPDFOpen();
    }

    const closePDFPopup = () => {
        toggleIsPopupPDFOpen(false);
        setPDFMode(null);
    }

    const handleNoConfirm = () => {
        setPDFMode('add')
        toggleIsConfirmPDFOpen()
        toggleIsPopupPDFOpen()
    }

    const handleYesConfirm = () => {
        toggleIsConfirmPDFOpen()
        toggleIsPopupPDFOpen()
    }

    return (
        <>
            <button className="prospect-tab__prospect__header__action"
                    disabled={!quoteName}
                    onClick={handleGeneratePopup}
                    data-for="generate-pdf"
                    data-tip={t('generate_pdf')}>
                <PolIcon icon="pdf"/>
            </button>
            <ReactTooltip id="generate-pdf" effect="solid"/>

            {currentFile?.length > 0
                ? (
                    <Link className="prospect-tab__prospect__header__action"
                          data-for="send-pdf-mail"
                          data-tip={t('send_by_mail')}
                          to={{
                              pathname: `${routesBase.baseProspect}/${prospectId}/${TAB_CONTACTS}/mail`,
                              state: {
                                  quoteName: quoteName,
                                  quoteId: quoteId,
                                  mailContactIds: contactIds,
                                  quotePdfFile: currentFile
                              }
                          }}>
                        <PolIcon icon="mails-send"/>
                    </Link>
                )
                : (
                    <button className="prospect-tab__prospect__header__action"
                            data-for="send-pdf-mail"
                            data-tip={t('send_by_mail')}
                            disabled>
                        <PolIcon icon="mails-send"/>
                    </button>
                )
            }

            <ReactTooltip id="send-pdf-mail" effect="solid"/>

            <Confirm title={t('confirm_pdf_already_existing')}
                     isOpen={isConfirmPDFOpen}
                     yesLabel={t('confirm_yes_overwrite')}
                     noLabel={t('confirm_no_other_version')}
                     cancelAction={handleNoConfirm}
                     confirmAction={handleYesConfirm}
                     closeAction={toggleIsConfirmPDFOpen}
            >
                <p>{t('confirm_regenerate_pdf')}</p>
            </Confirm>

            <PolPopup title={t('generate_pdf')}
                      classNames={{content: 'quotes-generate-pdf'}}
                      isOpen={isPopupPDFOpen}
                      closePopup={closePDFPopup}>
                <GeneratePdf toggleGeneratePopup={closePDFPopup}
                             PDFMode={PDFMode}
                             quoteId={quoteId}
                             updateHandler={updateHandler}/>
            </PolPopup>
        </>
    )
}

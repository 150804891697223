import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {routesBase} from "v4/data/appRoutes";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ProspectsListingActions() {
    const {t} = usePolTranslation();

    return (
        <>
            <li className="action">
                <Link to={`${routesBase.baseProspect}/add/business`} data-for="add" data-tip={t('add_company')}>
                    <PolIcon icon="add-company" className="action__picto"/>
                </Link>
            </li>
            <li className="action">
                <Link to={`${routesBase.baseProspect}/add/individual`} data-for="add" data-tip={t('add_prospect')}>
                    <PolIcon icon="add-client" className="action__picto"/>
                </Link>
            </li>
            <ReactTooltip id="add" effect="solid"/>
        </>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function DashboardListingState({isLoading, noResults}) {
    const {t} = usePolTranslation();

    return (
        <div className={`dashboard-listing__state ${noResults ? 'no-results' : ''}`}>
            {isLoading && (
                <>
                    <PolIcon icon="circle-notch" className="spinner"/>
                    <p>{t('loading')}</p>
                </>
            )}
            {!isLoading && noResults && (
                <>
                    <PolIcon icon="search" className="icon"/>
                    <p>{t('no_results')}</p>
                </>
            )}
        </div>
    )
}

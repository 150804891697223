import React, {useState} from 'react'
import Ged from "../../../views/Ged/Ged";
import {captureException} from "@sentry/react";
import {manageError} from "../../../api/datas/datas";

export const PopupGed = ({callback, t, setPopup}) => {
    const [gedSelectedFileUrl, setGedSelectedFileUrl] = useState(null);

    const handleLoadGedImage = (src, callback) => {
        return fetch(src)
            .then(r => r.blob())
            .then(blob => new Promise(
                (resolve, reject) => {
                    const reader = new FileReader()
                    reader.onloadend = () => resolve(reader.result)
                    reader.onerror = reject
                    reader.readAsDataURL(blob)
                }
            ))
            .catch((e) => {
                captureException(e, {
                    extra: {
                        src
                    }
                });
                manageError(500, {}, t('error_content_loaded'))
            });
    }

    return (
        <>
            <div className={"popup__overlay-ged"}/>
            <Ged isReadOnly={true}
                 onFileItemSimpleClick={(link) => {
                     setGedSelectedFileUrl(link)
                 }}
                 onFileItemDoubleClick={(link) => {
                     setPopup({})
                     if (link !== null) {
                         handleLoadGedImage(link).then(callback)
                     }
                 }} expansionAllowed={['png', 'bmp', 'jpg', 'jpeg', 'gif']}/>
            <div className={'row ged-component__separator'}>
                <div className={'col-6'}>
                    <button className={'btn btn-third decline'}
                            onClick={() => setPopup({})}>{t('close')}</button>
                </div>
                <div className={'col-6'}>
                    <button disabled={gedSelectedFileUrl === null} className={'btn-third-file btn btn-third accept'}
                            onClick={() => {
                                setPopup({})
                                if (gedSelectedFileUrl !== null) {
                                    handleLoadGedImage(gedSelectedFileUrl).then(callback)
                                }
                            }}>{gedSelectedFileUrl !== null ? t('pick_file') : t('select_file')}</button>
                </div>
            </div>
        </>
    )
}

import React from 'react';
import {FormProvider, useForm, useWatch} from "react-hook-form";
import {useHistory} from "react-router-dom";
import Field from "v4/components/form/Field/Field";
import {routesBase} from "v4/data/appRoutes";
import {TAB_QUOTES} from "v4/data/tabNames";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesDuplicate/ProspectQuotesDuplicate.scss';
import UserService from "api/user/user";

export default function ProspectQuotesDuplicate({rowIdToDuplicate, closePopup}) {
    const {t} = usePolTranslation();
    const {replace} = useHistory();

    const methods = useForm({
        defaultValues: {
            prospect: '',
        }
    });

    const selectedProspect = useWatch({
        control: methods.control,
        name: 'prospect',
    });

    const handleAccept = () => {
        if (selectedProspect.length > 0) {
            replace(`${routesBase.baseProspect}/${selectedProspect}/${TAB_QUOTES}/add?duplicateFrom=${rowIdToDuplicate}`);
            closePopup();
        }
    }

    return (
        <div className="duplicate-popup__content">
            <p>{t('duplicate_prospect_text')}</p>
            <FormProvider {...methods}>
                <Field name="prospect"
                       type="select"
                       attr={{type: 'autocomplete', autocomplete_entity: 'prospect'}}
                       constraints={{required: true}}
                       impossibleValues={'unknown_' + UserService.getCustomerId()}
                       placeholder={t('select')}/>
            </FormProvider>
            <div className="duplicate-popup__content__buttons">
                <button className="duplicate-popup__content__button-cancel"
                        onClick={closePopup}>
                    {t('cancel')}
                </button>
                <button className="duplicate-popup__content__button-confirm"
                        onClick={handleAccept} disabled={!selectedProspect}>
                    {t('duplicate')}
                </button>
            </div>
        </div>
    );
}

import React, {useContext, useEffect} from 'react';
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {AUTOMATED_STATUS_FORM, AUTOMATED_STATUS_FORM_EDIT} from "v4/data/apiRoutes";
import Loader from "v4/components/ui/Loader/Loader";
import {FormProvider, useForm} from "react-hook-form";
import Field from "v4/components/form/Field/Field";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {AdminContext} from "v4/contexts/AdminContext";
import {getActiveClassName} from "v4/layouts/FormEntityLayout/components/FormEntityLayoutForm/FormEntityLayoutForm";

export default function AutomatedStatusForm({id, onSubmit, submitErrors}) {
    const {t} = usePolTranslation();
    const [{data, isLoading}, fetchForm] = useFetch();
    const methods = useForm();
    const {asCustomer} = useContext(AdminContext);

    useEffect(() => {
        fetchForm({
            url: id
                ? generateUrl(AUTOMATED_STATUS_FORM_EDIT, {id: id, ...(asCustomer && {customerId: asCustomer.id})})
                : generateUrl(AUTOMATED_STATUS_FORM, {...(asCustomer && {customerId: asCustomer.id})}),
        });
    }, [asCustomer, fetchForm, id]);

    if (isLoading) return <Loader/>;

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}
                  className="automated-status__form">
                {data?.fromQuoteStatusId && (
                    <div className="input-field-wrapper">
                        {submitErrors?.find(error => error.key === 'fromQuoteStatusId')?.messages.map(error => (
                            <span key={error} className="input-field__errors">{t(error)}</span>
                        ))}
                        <Field name="fromQuoteStatusId"
                               {...data.fromQuoteStatusId}
                               specificParams={{menuPosition: 'fixed'}}/>
                        <label htmlFor="fromQuoteStatusId"
                               className={getActiveClassName(methods.watch('fromQuoteStatusId') ?? data.fromQuoteStatusId.value)}>
                            {t(data.fromQuoteStatusId.label)}
                        </label>
                    </div>
                )}
                {data?.toQuoteStatusId && (
                    <div className="input-field-wrapper">
                        {submitErrors?.find(error => error.key === 'toQuoteStatusId')?.messages.map(error => (
                            <span key={error} className="input-field__errors">{t(error)}</span>
                        ))}
                        <Field name="toQuoteStatusId"
                               {...data.toQuoteStatusId}
                               specificParams={{menuPosition: 'fixed'}}/>
                        <label htmlFor="toQuoteStatusId"
                               className={getActiveClassName(methods.watch('toQuoteStatusId') ?? data.toQuoteStatusId.value)}>
                            {t(data.toQuoteStatusId.label)}
                        </label>
                    </div>
                )}

                <div className="d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary">
                        {id ? t('edit') : t('add')}
                    </button>
                </div>
            </form>
        </FormProvider>
    )
}

import React, {useMemo, useEffect, useCallback} from 'react';
import Table from "v4/components/ui/Table/Table";
import {Link} from "react-router-dom";
import {routesBase} from 'v4/data/appRoutes'
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import apiService from "v4/services/api.service";
import {
    MODULE_CONFIGURATION_LIST,
    MODULE_ITEM,
} from "v4/data/apiRoutes";
import {PolIcon} from "components/PolIcon/policon";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";

export default function ModuleConfigurationsList({customerId, moduleId}) {
    const {t} = usePolTranslation()

    // Listing
    const [{data: loadedModule}, fetchModule] = useFetch();
    const [{
        data: loadedModuleConfigurations,
        isLoading: loadedModuleConfigurationsLoading,
        isError: loadedModuleConfigurationsError
    }, fetchModuleConfigurations] = useFetch();

    useEffect(() => {
        fetchModule({
            url: apiService.generateUrl(MODULE_ITEM, {
                customerId: customerId,
                id: moduleId
            }),
        });

        fetchModuleConfigurations({
            url: apiService.generateUrl(MODULE_CONFIGURATION_LIST, {
                customer: customerId,
                module: moduleId
            }),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hydrateModuleConfigurationRows = useCallback(() => {
        if (!loadedModuleConfigurations) {
            return null
        }

        const rows = loadedModuleConfigurations?.['hydra:member'] ?? [];

        return (
            rows.map((item, index) => {
                return {
                    keyConf: item.keyConf,
                    value: item.value,
                    actions: (
                        <ul className="datatable__actions" key={index}>
                            <Link
                                to={`${routesBase.adminModuleConfiguration}/${customerId}/${moduleId}/edit/${item.id}`}>
                                <li className="datatable__actions__item">
                                    <span className="datatable__actions__item__icon">
                                        <PolIcon icon='edit'/>
                                    </span>
                                </li>
                            </Link>
                        </ul>
                    )
                };
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedModuleConfigurations])

    const tableList = useMemo(() => {
        return <Table tableRows={hydrateModuleConfigurationRows()} isLoading={loadedModuleConfigurationsLoading}
                      isError={loadedModuleConfigurationsError}
                      theadData={['keyConf', 'value', 'actions']}/>
    }, [hydrateModuleConfigurationRows, loadedModuleConfigurationsLoading, loadedModuleConfigurationsError])

    return (
        <AdminCard title={`${t('manage_module_configurations')} : ${loadedModule?.name}`}>
            {tableList}
        </AdminCard>
    )
}

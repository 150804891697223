import React, {useState} from 'react';
import Datetime from 'react-datetime'
import PhoneInput from 'react-phone-input-2'
import {PolIcon} from "../../PolIcon/policon";
import {usePolTranslation} from "../../../v4/hooks/usePolTranslation";

require('moment/locale/fr');

function InputDefault({inputData, type = 'text', onChange}) {
    const {t} = usePolTranslation();

    let tagType = 'default';
    let autoCompleteField;
    let autoCompleteType;
    let isAutocomplete = false;
    let moreAttribute = {};
    let legends = [];
    let isCheck = !!inputData.value;
    const [cursor, setCursor] = useState(0);
    switch (type) {
        case 'string' :
            type = 'text';
            break;
        case 'boolean' :
            type = 'checkbox';
            break;
        default :
            break;
    }

    if (inputData.widget) {
        //Widget => datetime / textarea
        tagType = inputData.widget;
    }


    if (inputData.attr && inputData.attr.type) {
        switch (inputData.attr.type) {
            case 'TelType':
                tagType = inputData.attr.type;
                break;
            case 'autocomplete':
                isAutocomplete = true;
                autoCompleteField = inputData.attr['data-field']
                autoCompleteType = inputData.attr['data-autocomplete-type'];
                moreAttribute = {
                    onBlur: () => hideAutocomplete(inputData),
                    onFocus: () => showAutocomplete(inputData),
                };
                break;
            case 'autocomplete_city' :
                isAutocomplete = true;
                break;
            case 'zip_code' :
                moreAttribute = {
                    onBlur: () => hideAutocomplete(inputData),
                    onFocus: () => showAutocomplete(inputData),
                };
                break;
            case 'slider' :
                tagType = 'slider';
                inputData.value = inputData.value ? inputData.value : 0;
                for (let i = inputData.attr['min']; i <= inputData.attr['max']; i = i + inputData.attr['step']) {
                    const classOption = i === parseInt(inputData.value) ? 'active' : '';
                    legends.push(<option className={classOption} key={i} label={i}>{i}</option>)
                }

                break;
            case 'hidden':
                type = 'hidden';
                tagType = 'hidden';
                break;
            default :
                tagType = 'default'
        }
    }

    const handleKeyDown = (e) => {

        if (inputData.dataAutocomplete && inputData.dataAutocomplete.length) {
            // arrow up/down button should select next/previous list element
            if (e.keyCode === 38 && cursor > 0) {
                e.preventDefault();
                setCursor(cursor - 1)
            } else if (e.keyCode === 40 && cursor < inputData.dataAutocomplete.length - 1) {
                e.preventDefault();
                setCursor(cursor + 1)
            } else if (e.keyCode === 13 && cursor >= 0
                && ((inputData.dataAutocomplete[cursor] || inputData.dataAutocomplete[cursor]) && inputData.dataAutocomplete[cursor][autoCompleteField])) {
                e.preventDefault();
                onChange(autoCompleteType ? inputData.dataAutocomplete[cursor] : inputData.dataAutocomplete[cursor][autoCompleteField])
                hideAutocomplete(inputData);
            } else {
                showAutocomplete(inputData);
            }
        }
    }

    const timeFormat = tagType === 'datetime' ? "HH:mm" : false;
    const dateFormat = 'DD-MM-YYYY'

    return (
        <div className={`${inputData && inputData.hidden ? 'd-none' : ''}`}>
            {inputData.error ? (
                <div className={'form__error'}
                     onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                     onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                >
                    <PolIcon icon={'exclamation-circle'} className={'form__error__icon'}/>
                    <p className={'form__error__content'}>{t(inputData.error)}</p>
                </div>
            ) : null}

            {tagType === 'slider' ? (
                <>
                    <input
                        type={'range'}
                        className={`field__slider ${inputData.isReadOnly ? 'input-read-only' : ''}`}
                        min={inputData.attr['min']}
                        max={inputData.attr['max']}
                        step={inputData.attr['step']}
                        list={'ticks'}
                        onChange={(event) => onChange(event.target.value)}
                        value={inputData.value}
                        style={{
                            background: `linear-gradient(to right, #c8befe ${inputData.value}%, #c8befe ${inputData.value}%, #fff 0, #fff 100%)`
                        }}
                        readOnly={inputData.isReadOnly}
                    />

                    <datalist className={'field__slider__datalist'} id="ticks">
                        {legends}
                    </datalist>
                </>

            ) : null
            }

            {tagType === 'textarea' ? (
                <textarea
                    className={`field__textarea ${inputData.isReadOnly ? 'input-read-only' : ''}`}
                    value={inputData.value ? inputData.value : ''}
                    onChange={(event) => onChange(event.target.value)}
                    title={t(inputData.title)}
                    rows="3"
                    readOnly={inputData.isReadOnly}
                />
            ) : null}

            {tagType === 'datetime' || tagType === 'date'? (
                <Datetime className={`field__input field__input-date ${inputData.isReadOnly ? 'input-read-only' : ''}`}
                          locale="fr"
                          closeOnSelect={true}
                          dateFormat={dateFormat}
                          timeFormat={timeFormat}
                          onChange={(date) => {
                              if (typeof date.format !== 'undefined') {
                                  if(timeFormat){
                                      onChange(date.format(dateFormat + ' ' +timeFormat));
                                      return;
                                  } else {
                                      onChange(date.format(dateFormat));
                                      return;
                                  }
                              }
                              if(!date.length){
                                  onChange(null)
                                  return;
                              }
                              onChange(date)
                          }}
                          value={inputData.value ? timeFormat ? inputData.value : inputData.value.substring(0, 10) : ''}
                          inputProps={{disabled: inputData.isReadOnly}}
                />
            ) : null}

            {tagType === 'TelType' ? (
                <PhoneInput
                    containerClass={`field__input field__input-date ${inputData.isReadOnly ? 'input-read-only' : ''}`}
                    value={inputData.value ? inputData.value : ''}
                    onlyCountries={['fr']}
                    disableDropdown={true}
                    disableCountryCode={true}
                    country={'fr'}
                    placeholder={''}
                    masks={{fr: '.. .. .. .. .. .. .. .. .'}}
                    onChange={(item) => onChange(item)}
                    disabled={inputData.isReadOnly}
                />
            ) : null}

            {tagType === 'password' ? (
                <input name={inputData.keyItem} className={`field__input ${inputData.isReadOnly ? 'input-read-only' : ''}`} type={'password'}
                       id={inputData.keyItem}
                       value={inputData.value ? inputData.value : ""}
                       onChange={(event) => onChange(event.target.value)}
                       title={t(inputData.title)}
                       autoComplete={'new-password'}
                       readOnly={inputData.isReadOnly}
                />
            ) : null}

            {tagType === 'default' || tagType === 'checkbox' ? (
                <>
                    {isAutocomplete ? (
                        <div className={`field__autocomplete d-none`} id={`autocomplete_${inputData.parentElement}_${inputData.keyItem}`}>
                            <ul className={'list'}>
                                {inputData.dataAutocomplete ? (
                                    <>
                                        {inputData.dataAutocomplete.map((item, key) => (

                                            <li className={`list__item ${key === cursor ? 'list__item__selected' : null}`}
                                                key={key}
                                                onMouseDown={() => onChange(!autoCompleteType ? item[autoCompleteField] : item)}>
                                                {!autoCompleteType ?
                                                    item[autoCompleteField] :
                                                    autoCompleteType === 'zip' ? `${item.code} - ${item.city}` : `${item.city} - ${item.code}` }
                                            </li>
                                        ))}
                                    </>
                                ) : null}
                            </ul>
                        </div>
                    ) : null}

                    <input onKeyDown={handleKeyDown} name={inputData.keyItem} className={`field__input ${tagType === 'checkbox' ? 'field__input--checkbox' : ''} ${isCheck && tagType === 'checkbox' ? 'field__input--checkbox--checked' : ''} ${inputData.isReadOnly ? 'input-read-only' : ''}`} type={type}
                           id={inputData.keyItem}
                           value={inputData.value ? inputData.value : ""}
                           checked={inputData.value ? inputData.value : false}
                           onBlur={inputData.onBlur}
                           {...moreAttribute}
                           onChange={(event) => onChange(event.target.value)}
                           onClick={(event) => {
                               if(inputData.attr && 'spam-request-security' in inputData.attr && inputData.attr['spam-request-security'] === false) {
                                   onChange(event.target.value);
                                   isCheck = !isCheck;
                               }
                           }}
                           title={t(inputData.title)}
                           readOnly={inputData.isReadOnly}
                           maxLength={inputData.attr && inputData.attr.max ? inputData.attr.max : null }
                           autoComplete={'off'}
                    />
                </>
            ) : null}

            {type !== 'hidden' ? (
                <>
                    <label title={t(inputData.title)}
                           className={`field__label ${tagType === 'checkbox' ? 'field__label--checkbox' : ''} ${tagType === 'checkbox' && isCheck ? 'field__label--checkbox--checked' : ''}`}
                           htmlFor={inputData.keyItem}
                    >{`${t(inputData.title)} ${inputData.isRequired || inputData.required ? '*' : ''}`}</label>
                </>
            ) : null}
        </div>
    )
};

const hideAutocomplete = (input) => {
    const element = document.getElementById(`autocomplete_${input.parentElement}_${input.keyItem}`);
    if (element) {
        element.classList.add("d-none");
    }
};

const showAutocomplete = (input) => {
    const element = document.getElementById(`autocomplete_${input.parentElement}_${input.keyItem}`);
    if (element) {
        element.classList.remove("d-none");
    }
};

export default React.memo(InputDefault)

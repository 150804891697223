import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_CLOSED_TASKS, TAB_OLD_MAILS_TASKS, TAB_OPEN_TASKS, TAB_RECEIVED_MAILS_TASKS} from "v4/data/tabNames";
import ManageTasksActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/components/ManageTasks/components/ManageTasksActions/ManageTasksActions";

export default function ManageTasksRow({prospectId, id, name, beginAt, isMailing, isSynchroOutlook, isClosed, isReminder}) {
    const [_beginAt, setBeginAt] = useState(beginAt);

    return (
        <div className="manage-task__row">
            <div className="manage-task__row__title">
                <Link
                    to={`${routesBase.baseProspect}/${prospectId}/${getSlug(isMailing, isSynchroOutlook, isClosed)}/${id}`}>
                    {name}
                </Link>
                <p>{_beginAt}</p>
            </div>
            <ManageTasksActions
                taskId={id}
                changeDate={setBeginAt}
                canRenew={isReminder}
                defaultCanClose={!isClosed}/>
        </div>
    )
}


function getSlug (isMailing, isSynchroOutlook, isClosed) {
    if (isMailing) return TAB_OLD_MAILS_TASKS;

    if (isSynchroOutlook) return TAB_RECEIVED_MAILS_TASKS;

    return isClosed ? TAB_CLOSED_TASKS : TAB_OPEN_TASKS;
}

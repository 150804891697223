import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {Prompt, Redirect} from "react-router-dom";
import {PolIcon} from "../../PolIcon/policon";
import {adminFetchHandler} from "../../../api/datas/datas";
import {Input} from "../../Input/input";
import {PostService} from "../../../api/datas/post";
import {getForm} from "../../../api/admin/get";
import {handleFormData} from "../../../reducers/form";
import {setLoader} from "../../../actions/content";
import {useRecoilValue} from "recoil";
import {customerIdSelector} from "../atoms/customer";

const FormGroups = ({t, adminFetchHandler, form, postService, itemId, setLoader}) => {
    const [inputs, setInputs] = useState({});
    const [redirect, setRedirect] = useState(null);
    const formUrl = `/api/security_groups/form${itemId ? `/${itemId}` : ''}`
    const customerId = useRecoilValue(customerIdSelector)
    const [isEdited, setIsEdited] = useState(false);

    const handleLeaving = () => {
        setIsEdited(false);
        return t("customer_confirm_leave_form_alert");
    }

    if (form && form.errors) {
        const errors = form.errors;

        Object.keys(inputs).forEach(key => {
                const input = inputs[key]
                const errorField = Object.keys(errors).find((error) => error === input.keyItem);
                if (errorField) {
                    input.error = errors[errorField];
                }
            }
        );

        setInputs({
            ...inputs
        })

        delete form.errors;
    }

    useEffect(() => {
        if (!Object.keys(inputs).length) {
            setLoader({
                'form-group': true
            })
            getForm(formUrl).then(data => {
                setInputs(handleFormData(data));
                setLoader({
                    'form-group': false
                })
            }).catch(error => {
                adminFetchHandler(error, {})
                setLoader({
                    'form-group': false
                })
            })
        }
    }, [setLoader, adminFetchHandler, formUrl, inputs]);

    const handleChange = (key, value) => {
        setIsEdited(true);

        inputs[key].value = value
        inputs[key].originalValue = ''
        inputs[key].error = null;

        setInputs({
            ...inputs
        })

    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let dataFetch = {};

        Object.keys(inputs).forEach(key => {
            dataFetch[key] = inputs[key].value;
            if (Array.isArray(inputs[key].value)) {
                dataFetch[key] = inputs[key].value.map(item => item.value);
            }
        });

        let infoSubmit = {
            url: `/api/security_groups`,
            submittedData: dataFetch,
            loader: 'form-security-group',
            callback: () => {
                setRedirect(<Redirect to={`/administration/gestion_groupes/`}/>)
            }
        };
        setIsEdited(false);
        if (itemId) {
            dataFetch.id = itemId;
            infoSubmit = {
                ...infoSubmit,
                putUrl: `/api/security_groups`,
            }
        } else {
            dataFetch.customerId = customerId
        }

        postService(infoSubmit, t)

        return false;
    };


    return (
        <>
            {redirect}

            <form className={`form form__scrollable form__admin`} onSubmit={(event) => handleSubmit(event)}>
                <Prompt message={handleLeaving} when={isEdited}/>
                <div className={'row row-fluid'}>
                    {Object.keys(inputs).map((key, index) => {
                        const input = inputs[key]
                        let fieldClass = input && input.value ? 'field-written' : '';
                        fieldClass += input && input.error ? ' field-error' : '';

                        return (
                            <React.Fragment key={key}>
                                {input.error ? (
                                    <div className={'form__error'}
                                         onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                         onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                                    >
                                        <PolIcon icon={'exclamation-circle'}
                                                         className={'form__error__icon'}/>
                                        <p className={'form__error__content'}>{input.error}</p>
                                    </div>
                                ) : null}
                                <div className={`col-12 col-md-3 field ${fieldClass}`} key={index}>
                                    <Input t={t}
                                           inputData={input}
                                           onChange={(newValue) => handleChange(key, newValue)}
                                           typeForm={null}
                                    />
                                </div>
                            </React.Fragment>)
                    })}
                </div>

                <div className={'row align-items-center justify-content-end'}>
                    <div className={'col-12 col-md-auto form__back'} onClick={() =>
                        setRedirect(<Redirect to={`/administration/gestion_groupes/`}/>)
                    }>
                        <PolIcon icon={'chevron-circle-left'} className={'form__back__icon'}/>
                        {t('retour')}
                    </div>
                    <div className={`col-12 col-md-auto form__submit`}>
                        <button className={'btn btn-primary form__submit__btn'} onClick={() => handleSubmit}>
                            {t('enregistrer')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = ({form}) => ({
    form
});
const mapDispatchToProps = dispatch => {
    return {
        postService: (info, t) => dispatch(PostService(info, t)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setLoader: (value) => dispatch(setLoader(value)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(FormGroups));

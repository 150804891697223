import {createGlobalStyle} from 'styled-components';
import bold from './Poppins-Bold.ttf';
import medium from './Poppins-Medium.ttf';
import regular from './Poppins-Regular.ttf'

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: local('Poppins Bold'),
         url(${bold}) format('truetype');
  }
    
    @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: local('Poppins Medium'),
         url(${medium}) format('truetype');
  }
  
  @font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: local('Poppins'),
          url(${regular}) format('truetype');
  }
`;

import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "components/PolIcon/policon";

export const Error404 = () => {
    const {t} = usePolTranslation();

    return (
        <div className={'page page-error'}>
            <p className={'page__title'}>404</p>
            <p className={'page__sub-title'}>{t('page_not_found')}</p>
            <Link className={'page__link'} to={routesBase.baseProspect}>
                {t('back_to_home')}
                <PolIcon icon={'arrow-right-bis'} className={'page__link__arrow'}/>
            </Link>
        </div>
    )
};

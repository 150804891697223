import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function AdminSimpleTableHeader({
                                                   headers = [],
                                                   className = '',
                                                   trClassName = '',
                                                   thClassName = '',
                                                   thRender,
                                               }) {
    const {t} = usePolTranslation();
    const isMultiArray = Array.isArray(headers[0]);

    return (
        <thead className={className}>
        {isMultiArray ? (
            headers.map((header, index) => (
                <tr key={index} className={trClassName}>
                    {header.map((label, subIndex) => (
                        thRender
                            ? thRender(label, subIndex)
                            : <th key={subIndex} className={`${label} ${thClassName}`}>{t(label)}</th>
                    ))}
                </tr>
            ))
        ) : (
            <tr className={trClassName}>
                {headers.map((header, index) => (
                    thRender
                        ? thRender(header, index)
                        : <th key={index} className={`${header} ${thClassName}`}>{t(header)}</th>
                ))}
            </tr>
        )}
        </thead>
    )
}

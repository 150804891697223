import React from 'react';
import {useLocation} from "react-router-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {PROSPECT_SEARCH} from "v4/data/apiRoutes";
import useTestRoute from "v4/hooks/useTestRoute";
import ProspectsAdd from "v4/pages/front/Prospects/components/ProspectsAdd/ProspectsAdd";
import ProspectsListing from "v4/pages/front/Prospects/components/ProspectsListing/ProspectsListing";

export default function ProspectsPage({tab, isProspectPage}) {
    const isAddBusinessPage = useTestRoute({inUrl: ['add', 'business']});
    const isAddIndividualPage = useTestRoute({inUrl: ['add', 'individual']});
    const isListingShowing = !(isAddBusinessPage || isAddIndividualPage) && !isProspectPage;
    const isProspectsAdd = isAddBusinessPage || isAddIndividualPage;
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const taskId = queryParameters.get('taskId') ?? null;

    return (
        <>
            <SearchProvider searchRouteName={PROSPECT_SEARCH} pageType="prospects" tab={tab}>
                <ToggleComponent isShowing={isListingShowing} className="page page-list">
                    <ProspectsListing tab={tab}/>
                </ToggleComponent>
            </SearchProvider>

            {isProspectsAdd && <ProspectsAdd type={isAddBusinessPage ? 'business' : 'individual'} taskId={taskId}/>}
        </>
    );
}

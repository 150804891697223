import {getLSItem} from "v4/services/localStorage.service";
import {captureException} from "@sentry/react";

export async function userEventsService(events, data) {
    let alteredData = null;
    const userEvents = getUserEvents();
    const eventsToExecute = events.filter(event => userEvents.some(({eventName}) => eventName.split(',').includes(event)));

    // sort eventsToExecute in the same order as userEvents
    eventsToExecute.sort((a, b) => {
        const aIndex = userEvents.findIndex(({eventName}) => eventName === a);
        const bIndex = userEvents.findIndex(({eventName}) => eventName === b);

        return aIndex - bIndex;
    });

    for (const event of eventsToExecute) {
        alteredData = await alterDataWithFetch(data, getUserEventUrl(event));
    }

    return alteredData ?? {alteredData: data};
}

function getUserEventUrl(currentEventName) {
    const userEvents = getUserEvents();
    const userEvent = userEvents.find(({eventName}) => eventName.split(',').includes(currentEventName));
    if ('App\\Service\\Provider\\ApiServicePlenetudeProvider' === userEvent?.provider) {
        return `${process.env.REACT_APP_API_SERVICE_PLENETUDE_HOST}${userEvent?.servicePath}`;
    }

    throw new Error('Only plenetude service authorized');
}

async function alterDataWithFetch(data, url) {
    if (!url) return data;

    try {
        const request = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/ld+json',
                'Content-Type': 'application/json',
                'X-POL-AUTH': 'Bearer ' + getLSItem('token', 'string'),
            },
            body: JSON.stringify(data),
        });

        if (!request.ok) {
            throw new Error('Network response was not ok');
        }

        const {alteredData, errors} = await request.json();

        const restoredFiles = getFilesObject(data);

        return {
            alteredData: {
                ...alteredData,
                ...restoredFiles,
            },
            errors,
        }
    } catch (e) {
        captureException(e);
        return data;
    }
}

export function getUserEvents() {
    const {userEventServices: {'hydra:member': userEvents}} = getLSItem('user');

    return userEvents;
}

function getFilesObject(data) {
    if (!data) return {};

    return Object.entries(data).reduce((acc, [key, value]) => {
        if (value?.files?.[0] instanceof File) {
            acc[key] = value;
        }

        return acc;
    }, {});
}

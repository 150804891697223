import React, {useContext, useEffect, useState} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
import {logOut, setToken} from '../../actions/user';
import Logo from "../../img/logo";
import {AuthContext} from "../../v4/contexts/AuthContext";
import {captureException} from "@sentry/react";
import {tokenCheckerHandler} from "../../api/datas/datas";
import UserService from "../../api/user/user";

const TokenCheckerComponent = ({children, logOut, setToken}) => {
    const [loaded, setLoaded] = useState(false);
    const {setToken: setTokenAuthContext} = useContext(AuthContext);

    // @see https://stackoverflow.com/a/69058154
    const isTokenExpired = (token) => {
        const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
        return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');

        // Check if both token and refresh token are present
        if (!token || !refreshToken) {
            logOut();
            setLoaded(true);
            return;
        }

        // Check if token is expired
        try {
            if (isTokenExpired(token)) {
                logOut();
                setLoaded(true);
                return;
            }
        } catch (error) {
            captureException(error)
            logOut();
            setLoaded(true);
            return;
        }

        // Everything seems fine, we can fetch the user data using the token
        // => User is already logged-in.
        fetch(`${process.env.REACT_APP_HOST_API}/api/user`, {
            method: 'GET',
            headers: {
                'Accept': 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": `Bearer ${token}`
            },
        })
            .then(tokenCheckerHandler)
            .then(response => {
                setTokenAuthContext(token, refreshToken);
                UserService.setUser(response);
            })
            .catch(error => {
                captureException(error);
                logOut();
            })
            .finally(() => {
                setLoaded(true);
            });

        // les dépendances manquantes lancent plusieurs fois la requête
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded, logOut, setToken])

    return (
        <>
            {!loaded ? (
                <main className={'loginpage'}>
                    <div className={'login__loader'}>
                        <div className={'login__loader__logo'}>
                            <Logo/>
                        </div>
                    </div>
                </main>) : children}
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(logOut()),
        setToken: (token, refreshToken, reload) => dispatch(setToken(token, refreshToken, reload))
    };
};

export default connect(
    null,
    mapDispatchToProps
)(withTranslation()(TokenCheckerComponent));

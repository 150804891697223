import UserService, {ROLE_MODULE_PRODUCT_CUSTOMIZE} from "api/user/user";


export default function ProductCustomizeVoter() {
    const canAccessProductAttributes = () => {
        return -1 !== UserService.getRoles().indexOf(ROLE_MODULE_PRODUCT_CUSTOMIZE);
    }

    const canAccessProductConfigurations = () => {
        return canAccessProductAttributes();
    }

    return ({canAccessProductAttributes, canAccessProductConfigurations})
}

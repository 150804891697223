import {PolIcon} from "components/PolIcon/policon";
import iconMain from "img/main_marker.svg";
import iconClient from "img/marker_client.svg";
import iconProspect from "img/marker_prospect.svg";
import L from "leaflet";
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useWatch} from "react-hook-form";
import {Circle, Map, Marker, TileLayer, Tooltip} from "react-leaflet";
import {Link, useHistory} from "react-router-dom";
import Loader from "v4/components/ui/Loader/Loader";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {PROSPECT_SEARCH} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import MapFilter from "v4/pages/front/Prospect/components/ProspectMap/components/MapFilter/MapFilter";
import ProspectMapDraggable
    from "v4/pages/front/Prospect/components/ProspectMap/components/ProspectMapDraggable/ProspectMapDraggable";
import 'v4/pages/front/Prospect/components/ProspectMap/ProspectMap.scss';
import ProspectsListingMapsDraggable
    from "v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingMaps/components/ProspectsListingMapsDraggable/ProspectsListingMapsDraggable";
import {generateUrl} from "v4/services/api.service";
import {routesBase} from "../../../../../data/appRoutes";

const markerIcon = {
    iconSize: [50, 50],
    iconAnchor: [25, 50],
    popupAnchor: [0, -50],
    shadowSize: null,
    shadowAnchor: null
};

export default function ProspectMap() {
    const {t} = usePolTranslation();
    const {mapProspect} = useProspectPageContext();
    const [showHelp, toggleHelp] = useToggle(true);
    const [selectedProspect, setSelectedProspect] = useState(null);
    const [map, setMap] = useState({
        lat: 51.505,
        lng: -0.09,
        zoom: 8,
    });
    const {isBusiness, fullname} = mapProspect?.metadata ?? {};
    const {goBack} = useHistory();

    const mapPosition = useMemo(() => [map.lat, map.lng], [map]);
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);

    useEffect(() => {
        if (mapProspect) {
            setSelectedProspect({...mapProspect, isFromList: false});
            setMap({
                lat: mapProspect.metadata.lat,
                lng: mapProspect.metadata.lng,
                zoom: 12
            });
        }
    }, [mapProspect]);

    const {
        formMethods: {control},
    } = useSearchContext();

    const [{data: searchProspects, isLoading: isSearchProspectsLoading}, fetchProspects] = useFetch();

    const values = useWatch({control});
    useEffect(() => {
        if (mapProspect && values.distance) {
            fetchProspects({
                url: generateUrl(PROSPECT_SEARCH, {isGeolocationSearch: true}),
                config: {
                    method: 'POST',
                    body: JSON.stringify({
                        ...values,
                        lat: mapProspect.metadata.lat,
                        lng: mapProspect.metadata.lng,
                    }),
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, mapProspect]);

    const markers = useMemo(() => {
        if (mapProspect) {
            const prospectMarker = {
                lat: mapProspect?.metadata.lat,
                lng: mapProspect?.metadata.lng,
                prospectId: mapProspect?.metadata.id,
                isMain: true,
            };

            const searchProspectsResults = searchProspects?.['hydra:member']?.results;
            if (searchProspectsResults) {
                const searchProspectsMarkers = searchProspectsResults.map(
                    prospect => ({
                        lat: prospect.extraFields.address.lat,
                        lng: prospect.extraFields.address.lng,
                        prospectId: prospect.metadata.id,
                        isClient: prospect.metadata.isClient ?? false,
                    }));

                return [...searchProspectsMarkers, prospectMarker];
            }

            return [prospectMarker];
        }

        return [];
    }, [mapProspect, searchProspects]);

    const circle = useMemo(() => {
        if (mapProspect && values.distance) {
            return {
                lat: mapProspect.metadata.lat,
                lng: mapProspect.metadata.lng,
                distance: values.distance * 1000,
            };
        }

        return null;
    }, [values.distance, mapProspect]);

    const handleMarkerClick = (prospectId) => {
        const selectedProspect = searchProspects?.['hydra:member']?.results?.find(
            p => p.metadata.id === prospectId
        );
        if (selectedProspect) {
            setSelectedProspect({...selectedProspect, isFromList: true});
        } else {
            setSelectedProspect({...mapProspect, isFromList: false});
        }
    };

    const removeSelectedProspect = () => {
        setSelectedProspect(null);
    };

    const onlyInRadius = (marker) => {
        if (circle) {
            const distance = L.latLng(marker.lat, marker.lng).distanceTo(L.latLng(circle.lat, circle.lng));
            return distance <= circle.distance;
        }

        return true;
    }

    return (
        <div className="map">
            {
                mapProspect
                    ? (
                        <>
                            <div className="map__header">
                                <div className="map__header__name" onClick={goBack}>
                                    <PolIcon icon={isBusiness ? 'building' : 'house'}/>
                                    {fullname}
                                </div>
                                {values.distance > 0 && (
                                    <Link to={{
                                        pathname: routesBase.baseProspect,
                                        state: {
                                            forceFilters: {
                                                ...values,
                                                lat: mapProspect.metadata.lat,
                                                lng: mapProspect.metadata.lng,
                                            },
                                        }
                                    }}>
                                        {t('swtich_to_list')}
                                    </Link>
                                )}
                                <div onClick={goBack}>
                                    <PolIcon icon="sign-out"/>
                                </div>
                            </div>
                            <div className="map__wrapper">
                                <div className="map__wrapper__map" ref={mapContainerRef}>
                                    <MapFilter/>
                                    <div className={`map__wrapper__map__help${showHelp ? ' show' : ''}`}>
                                        <PolIcon icon={showHelp ? 'close' : 'question'} onClick={toggleHelp}/>
                                        <div>
                                            <p>{t('geolocations_text_1')}</p>
                                            <p>{t('geolocations_text_2')}</p>
                                        </div>
                                    </div>

                                    <ToggleComponent isShowing={selectedProspect}
                                                     shouldRemoveFromDom={true}>
                                        {selectedProspect?.isFromList
                                            ? <ProspectsListingMapsDraggable selectedProspect={selectedProspect}
                                                                             removeSelectedProspect={removeSelectedProspect}
                                                                             offsetParent={mapContainerRef.current}
                                                                             bounds=".map__wrapper__map"/>
                                            : <ProspectMapDraggable selectedProspect={selectedProspect}
                                                                    removeSelectedProspect={removeSelectedProspect}
                                                                    offsetParent={mapContainerRef.current}
                                                                    bounds=".map__wrapper__map"/>
                                        }
                                    </ToggleComponent>

                                    <Map center={mapPosition}
                                         zoom={map.zoom}
                                         animate={true}
                                         ref={mapRef}>
                                        <TileLayer
                                            attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"/>
                                        {markers.filter(onlyInRadius).map((marker, key) => {
                                            const newIcon = L.icon({
                                                ...markerIcon,
                                                className: `mapspage__marker`,
                                                iconUrl: getIcon(marker),
                                            });

                                            return <Marker key={key}
                                                           position={[marker.lat, marker.lng]}
                                                           icon={newIcon}
                                                           onClick={() => handleMarkerClick(marker.prospectId)}/>
                                        })}
                                        {(circle && !isSearchProspectsLoading) && (
                                            <Circle radius={circle.distance}
                                                    center={{
                                                        lat: circle.lat,
                                                        lng: circle.lng,
                                                    }}
                                                    className="map-circle"/>
                                        )}
                                        {(circle && isSearchProspectsLoading) && (
                                            <Circle radius={circle.distance}
                                                    center={{
                                                        lat: circle.lat,
                                                        lng: circle.lng,
                                                    }}
                                                    className="map-circle pulse">
                                                <Tooltip sticky>
                                                    <span>{t('loading')}</span>
                                                </Tooltip>
                                            </Circle>
                                        )}
                                    </Map>
                                </div>
                            </div>
                        </>
                    )
                    : <Loader/>
            }
        </div>
    )
}

function getIcon(marker) {
    if (marker.isMain) {
        return iconMain;
    }
    if (marker.isClient) {
        return iconClient;
    }

    return iconProspect;
}

import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useFormContext} from "react-hook-form";
import {PolIcon} from "components/PolIcon/policon";
import {CHOICE_TYPE, TEXT_TYPE} from "v4/data/fieldTypes";

export default function SiretPopup({siretData, closePopup}) {
    const {t} = usePolTranslation();
    const parentFormMethods = useFormContext();
    const {fields} = siretData;

    const onSubmit = ({currentTarget}) => {
        const checkedInputs = [...currentTarget.form].filter(({tagName, checked}) => tagName === 'INPUT' && checked);
        checkedInputs.forEach(({name}) => parentFormMethods.setValue(name, getSiretFieldValueFor(name, fields), {shouldValidate: true}));
        closePopup();
    }

    if (!fields?.length) return <p>{t('no_results')}</p>;

    return (
        <form className="siret-input__popup-content">
            <p className="siret-input__explanation">
                {t('siret_popup_explanation')}
            </p>

            <div className="siret-input__popup-grid">
                {fields.map(({fieldKey, fieldLabel, type, value}) => (
                    <div key={fieldKey} className="input-field-wrapper">
                        <div className="input-field-checkbox-container">
                            <input type="checkbox"
                                   id={fieldKey}
                                   name={fieldKey}
                                   defaultChecked={!(parentFormMethods.getValues(fieldKey)?.length)}/>
                        </div>
                        <label htmlFor={fieldKey}>
                            <span>{t(fieldLabel)} : <b>{getSiretFieldShowValueFor(type, value)}</b></span>
                        </label>
                    </div>
                ))}
            </div>

            <button type="button"
                    onClick={onSubmit}
                    className="btn btn-primary">
                <PolIcon className="mr-2" icon="validation"/>
                {t('enregistrer')}
            </button>
        </form>
    )
}

function getSiretFieldValueFor(name, fields) {
    const {type, value} = fields?.find(({fieldKey}) => fieldKey === name) ?? {};

    switch (type) {
        case CHOICE_TYPE:
            return value?.id ?? '';
        case TEXT_TYPE:
        default:
            return value ?? '';
    }
}

function getSiretFieldShowValueFor(type, value) {
    switch (type) {
        case CHOICE_TYPE:
            return value?.label ?? '';
        case TEXT_TYPE:
        default:
            return value ?? '';
    }
}

import Papa from 'papaparse'

export const getJsonFromCsv = (file, callback) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = function () {
        const result = reader.result;
        const jschardet = require("jschardet")
        const encoding = jschardet.detect(result)

        Papa.parse(file, {
            encoding: encoding.encoding.search('windows-') !== -1 ? 'ISO-8859-1' : 'UTF-8',
            complete: function(results) {
                callback(results.data);
            }
        });
    }

}
import React from 'react';
import {Link} from "react-router-dom";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {routesBase} from "v4/data/appRoutes";
import useProspectQuotesTabs
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesTabs/hooks/useProspectQuotesTabs";

export default function ProspectQuotesTabs({currentTab, showId}) {
    const {prospectId} = useProspectPageContext();
    const {parent = null, children = []} = useProspectQuotesTabs();

    const parentToShow = parent && {...parent, isCurrent: parent.id === showId};
    const childrenToShow = children && children?.map(child => ({...child, isCurrent: child.id === showId}));

    return (
        <div className="prospect-tab__prospect__header__tabs">
            {parentToShow && (
                <div className={`prospect-tab__prospect__header__tabs__tab${parentToShow.isCurrent ? ' active' : ''}`}>
                    {!parentToShow.isCurrent
                        ? (
                            <Link
                                to={`${routesBase.baseProspect}/${prospectId}/${currentTab}/${parentToShow.id}`}>
                                <span>{parentToShow.name}</span>
                            </Link>
                        )
                        : <span>{parentToShow.name}</span>
                    }
                </div>
            )}
            {childrenToShow?.length > 0 && childrenToShow.map((child, index) => (
                    <div key={index} className={`prospect-tab__prospect__header__tabs__tab${child.isCurrent ? ' active' : ''}`}>
                        <Link to={`${routesBase.baseProspect}/${prospectId}/${currentTab}/${child.id}`}>
                            <span>{clampParentNameForCurrentTabName(parentToShow.name, child.name)}</span>
                        </Link>
                    </div>
                )
            )}
        </div>
    )
}

function clampParentNameForCurrentTabName(parentName, currentTabName, maxParentNameLength = 20) {
    if (parentName.length > maxParentNameLength) {
        const clampedName = parentName.substring(0, maxParentNameLength);
        return currentTabName.replace(parentName, clampedName + '...');
    }

    return currentTabName;
}

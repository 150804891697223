const defaultStoreGed = []

const gedData = (state = defaultStoreGed, action) => {
    switch (action.type) {
        case 'SET_FOLDER_LIST':
            if (action.folderList) {
                return {...state, folderList: action.folderList};
            }
            break;
        case 'SET_DIRECTORY_LIST':
            if (action.directoryList) {
                let newState = {...state, directoryList: action.directoryList, getType: action.getType};
                if (action.isRoot && action.directoryList && action.directoryList.length) {
                    newState = {
                        ...newState,
                        rootDirectoryList: action.directoryList
                    }
                }

                return newState;
            }
            break;
        case 'RESET_GED_DATA':
            return {...defaultStoreGed, rootDirectoryList: state.rootDirectoryList};
        default: {
            return state;
        }
    }
}

export default gedData;
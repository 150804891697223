import React, {useContext} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useRouteMatch} from "react-router-dom";
import {AdminContext} from "v4/contexts/AdminContext";
import ProductAttributesList from "v4/pages/admin/ProductAttributes/ProductAttributesList/ProductAttributesList";
import ProductAttributesForm from "v4/pages/admin/ProductAttributes/ProductAttributesForm/ProductAttributesForm";
export default function ProductAttributesPage() {
    const {t} = usePolTranslation();
    const {path, params: {id}, isExact} = useRouteMatch();
    const isAddPage = path.split('/').pop() === 'add';
    const isEditPage = !!id && isExact;
    const isListPage = (!isAddPage && !isEditPage);
    const {asCustomer} = useContext(AdminContext);
    const getComponent = () => {
        if (isListPage) {
            return <ProductAttributesList customerId={asCustomer?.id}/>
        }
        return <ProductAttributesForm id={id} customerId={asCustomer?.id}/>
    }
    return (
        <>
            {asCustomer?.id ?
                (
                    <>
                        {getComponent()}
                    </>
                ) : (
                    <p>{t('no_customer_defined')}</p>
                )
            }
        </>
    )
}
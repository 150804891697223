import React, {useEffect, useState} from "react";
import {Draggable} from "react-beautiful-dnd";
import TreeNodes from "v4/components/ui/Tree/components/TreeNodes/TreeNodes";
import useConfirm from "v4/hooks/useConfirm";
import useTreeNodes from "v4/components/ui/Tree/hooks/useTreeNodes";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

const TreeNodesElement = ({parentId, item, level, index, inEditMode}) => {
    const confirmDelete = useConfirm();
    const {t} = usePolTranslation()
    const {treeNodes, setTreeNodes, addNodeAction, findNode, events} = useTreeNodes()
    const [editMode, setEditMode] = useState(inEditMode)
    const [itemName, setItemName] = useState(item.name)

    const getDraggingNodeStyle = (isDragging) => {
        return isDragging ? 'treeNodes--dragging' : ''
    }

    const getStyle = (style, snapshot) => {
        if (!snapshot.isDropAnimating) {
            return style;
        }
        return {
            ...style,
            // cannot be 0, but make it super tiny
            transitionDuration: `0.001s`,
        };
    }

    const onConfirmDelete = (parentId, item) => () => {
        // Make a copy of complete tree
        const newTreeNodes = [...treeNodes];
        // Search parent object in treeNodes by parentId
        const parent = findNode(newTreeNodes, parentId);
        // Remove item from parent children or from root
        if (parent) {
            parent.children = parent.children.filter(function (obj) {
                return obj.id !== item.id;
            })
            setTreeNodes(newTreeNodes);
        } else {
            setTreeNodes(newTreeNodes.filter(function (obj) {
                return obj.id !== item.id;
            }))
        }
    }

    const handleToggleEditMode = (e) => {
        const node = document.getElementById(item.id + '_node')

        document.querySelector('.admin').removeEventListener('mousedown', handleToggleEditMode)
        if (!node.contains(e.target)) {
            toggleEditMode(false)
        }
    }

    useEffect(() => {
        // Autofocus input name on edit mode
        if (editMode) {
            document.getElementById(item.id + '_name').focus()
            document.querySelector('.admin').addEventListener('mousedown', handleToggleEditMode)
        }

        if (editMode === true && events === "save") {
            toggleEditMode(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, events])

    const toggleEditMode = (mode) => {
        // When toggling to edit mode false (seems we want to save item name edited or not)
        if (!mode) {
            item.inEditMode = mode
        }

        setEditMode(mode)
    }

    const handleChange = (e) => {
        setItemName(e.target.value)
        item.name = e.target.value
    }

    return (
        <Draggable key={item.id} draggableId={item.id} index={index}>

            {(provided, snapshot) => (

                <li className={'treeNodes__Node ' + getDraggingNodeStyle(snapshot.isDragging)}>

                    <div className="treeNodes__Node__Label"
                         id={item.id + '_node'}
                         ref={provided.innerRef} {...provided.draggableProps}
                         style={getStyle(provided.draggableProps.style, snapshot)}>
                        <div className="treeNodes__Node__Handle" {...provided.dragHandleProps}><i
                            className="policon-sort"></i></div>
                        {editMode &&
                            <span className="treeNodes__Node__Label--name">
                                    <input type="text" name="name" id={item.id + '_name'} value={itemName}
                                           onChange={(e) => handleChange(e)} onKeyUp={(e) => {
                                        if (e.key === "Enter") {
                                            toggleEditMode(false)
                                        }
                                    }
                                    }/>
                                        </span>
                        }

                        {!editMode &&
                            <strong className="treeNodes__Node__Label--name"
                                    onDoubleClick={() => toggleEditMode(!editMode)}>{item.name}</strong>
                        }

                        <div className="treeNodes__Node__Tools">
                            {!editMode &&
                                <div className="action" onClick={() => addNodeAction(item)}><i
                                    className="policon-add"></i></div>
                            }
                            <div className="action" onClick={() => toggleEditMode(!editMode)}><i
                                className={'policon-' + (editMode ? 'validation' : 'edit')}></i></div>
                            <div className="action" onClick={confirmDelete(onConfirmDelete(parentId, item), () => {
                            }, t("delete_category_and_contents"))}><i
                                className="policon-delete"></i></div>
                        </div>
                    </div>

                    <TreeNodes childNodes={item.children} parentId={item.id} level={level + 1}/>

                    {provided.placeholder}
                </li>

            )}

        </Draggable>
    )
}

export default TreeNodesElement;

import React from 'react';
import * as moment from "moment";
import {humanFileSize} from "../../../../utils/unitConverter";

export const FileList = ({file, setInputValue, inputValue, handleEditedFile, itemToEdit}) => {

    const expansion = file.fileName ? file.fileName.split('.').slice(-1) : '-';

    return (
        <>
            <td>
                {itemToEdit === file.id ? (
                    <>
                        <input onBlur={e => {
                            handleEditedFile({
                                oldName: file.fileName,
                                newName: inputValue,
                                id: file.id
                            })
                        }} onKeyPress={(e) => {
                            if (e.charCode === 13) {
                                handleEditedFile({
                                    oldName: file.fileName,
                                    newName: inputValue,
                                    id: file.id
                                })
                            }
                        }} onChange={(e) => {
                            setInputValue(e.target.value)
                        }} type={"text"} defaultValue={inputValue}/>
                    </>
                ) : inputValue}
            </td>
            <td>
                {moment(file.createdAt).format('lll')}
            </td>
            <td>
                {humanFileSize(file.size)}
            </td>
            <td>
                {expansion}
            </td>
            </>
    )
}
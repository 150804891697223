import React, {useMemo} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux'
import UserService from "../../api/user/user";
import Router from "../Router/router";
import Header from "../Header/header";
import Admin from "../Admin/admin";
import Loading from "../Loading/Loading";
import PolRouter from "v4/features/front/routing/components/PolRouter/PolRouter";
import {useLocation} from "react-router-dom";
import {getRoutesParams} from "v4/data/appRoutes";
import {FrontProvider} from "v4/contexts/FrontContext";
import ExportPopup from "v4/features/front/export/components/ExportPopup/ExportPopup";
import {getLSItem} from "v4/services/localStorage.service";

const Layout = ({user, router, t, loader}) => {
    const {pathname} = useLocation();
    const userToken = UserService.getTokens();
    let isLoading;
    let loaderKeys = Object.keys(loader);

    loaderKeys = loaderKeys.filter((key) => loader[key] === true);
    isLoading = loaderKeys.length > 0;

    const getRouteV4 = useMemo(() => {
        const currentPath = pathname.replace(/\/$/, '');
        return getRoutesParams().find(({basePath}) => currentPath.startsWith(basePath));
    }, [pathname]);

    // Not logged in
    const userFromLocalStorage = getLSItem('user');
    if (!userFromLocalStorage?.id || (!user.token && !userToken) || getRouteV4?.isExternal) {
        return getRouteV4 ? <PolRouter isExternal={getRouteV4?.isExternal}/> : <Router/>;
    }

    // Logged in
    return (
        <>
            <ExportPopup/>
            {(router.isAdmin || getRouteV4?.isAdmin)
                ? (
                    <>
                        {isLoading ? <Loading message={t("loading")} specificClass={"admin-loader"}/> : null}
                        <Admin>
                            {getRouteV4 ? <PolRouter/> : <Router/>}
                        </Admin>
                    </>
                )
                : (
                    <FrontProvider
                        header={() => <Header/>}
                        body={
                            <>
                                <main className="wrap-content main-content">
                                    <div className="main-content__body">
                                        {getRouteV4 ? <PolRouter/> : <Router/>}
                                    </div>
                                </main>
                            </>
                        }/>
                )
            }
        </>

    )
};


const mapStateToProps = ({user, router, content: {loader}}) => ({
    user,
    router,
    loader
});

export default connect(
    mapStateToProps,
    null
)(withTranslation()(Layout))

import getRealFieldType from "v4/pages/admin/ViewOrders/contexts/utils/getRealFieldType";
import getValueType from "v4/pages/admin/ViewOrders/contexts/utils/getValueType";
import i18n from "i18next";

export default function populateField(field, fieldData) {
    if (!field || !fieldData) return null;

    const {
        required: isRequired,
        attr: {
            'data-type': attrDataType,
            type: attrType,
            email: isEmail,
            isExtended,
            readonly: isReadOnly,
        } = {},
        key: realKey,
        fixedKey,
    } = fieldData;

    const type = getRealFieldType(fieldData);
    const valueType = getValueType(field);

    return {
        ...field,
        realKey,
        type,
        ...(fixedKey.startsWith('deliveryAddress_') && {label: `${i18n?.t(field.label)} (${i18n?.t('delivery')})`}),
        ...(valueType && {valueType}),
        ...(isRequired && {isRequired, isRequiredNotRemovable: true}),
        ...(attrDataType === 'specific-fields' && {isSpecificField: true}),
        ...(attrType === 'hidden' && {isHidden: true}),
        ...(isEmail && {isEmail}),
        ...(isExtended && {isExtended}),
        ...(isReadOnly && {isReadOnly}),
    }
}

import ErrorBoundary from "components/Error/errorBoundary";
import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import usePropToComponent from "v4/hooks/usePropToComponent";
import 'v4/layouts/ListingHeaderEntityLayout/ListingHeaderEntityLayout.scss';

export default function ListingHeaderEntityLayout({pageType, title, totalItems, resultsText, actions}) {
    const {t} = usePolTranslation();
    const [listingActions] = usePropToComponent(actions);

    return (
        <div className="header mb-2">
            <hr className={`header__margin ${pageType}`}/>
            <div className="header__title">{t(title)}</div>
            {totalItems !== null && (
                <>
                    <hr className="header__separator"/>
                    <p className="header__number datatable__number">
                        {resultsText}
                    </p>
                    <hr className="header__separator"/>
                </>
            )}
            <div className="header__filters"></div>
            <div className="content-add">
                <ul className="content-add__list">
                    <ErrorBoundary>
                        {listingActions}
                    </ErrorBoundary>
                </ul>
            </div>
        </div>
    )
}

import React, {useEffect, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, Redirect} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import {
    CUSTOM_EVENT_TRIGGER_ADD, CUSTOM_EVENT_TRIGGER_EDIT, CUSTOM_EVENT_TRIGGER_FORM,
    CUSTOM_EVENT_TRIGGER_ITEM
} from "v4/data/apiRoutes";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";

const TYPE_SPECIFIC_FIELD = 'specific_field';
const CONDITION_CONTAINS_TASKS = 'condition_contains_tasks';

export default function CustomEventTriggersForm({customerId, id}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification();
    const [{data: customEventTriggerFormData, isLoading: isLoadingForm, isFinished: isFinishedForm}, fetchCustomEventTriggerForm] = useFetch();
    const [{data: customEventTriggerDataSave, isError: isErrorSave, isLoading: isLoadingSave, isFinished: isFinishedSave}, fetchCustomEventTriggerSave] = useFetch();
    const [customEventTriggerForm, setCustomEventTriggerForm] = useState(null);
    const [customEventTrigger, setCustomEventTrigger] = useState({
        entity: null,
        aCondition: null,
        field: null,
        type: null,
        value: null,
        description: null,
        specificFieldId: null,
        whenApply: null,
        containValues: null,
        customerId: customerId
    });
    const isEdit = (!!id);
    const [redirect, setRedirect] = useState(null);
    useEffect(() => {
        fetchCustomEventTriggerForm({
            url: id
                ? apiService.generateUrl(CUSTOM_EVENT_TRIGGER_EDIT, {id: id, customerId: customerId})
                : apiService.generateUrl(CUSTOM_EVENT_TRIGGER_FORM, {customerId: customerId}),
        })
    }, [fetchCustomEventTriggerForm, id, customerId, isEdit])
    useEffect(() => {
        if (customEventTriggerFormData && isFinishedForm) {
            const form = customEventTriggerFormData?.schema?.['hydra:member']?.[2];
            setCustomEventTriggerForm(form);
            if (form) {
                setCustomEventTrigger({
                    entity: form?.entity?.value,
                    aCondition: form?.aCondition?.value,
                    field: form?.field?.value,
                    type: form?.type?.value,
                    value: form?.value?.value,
                    description: form?.description?.value,
                    specificFieldId: form?.specificFieldId?.value,
                    whenApply: form?.whenApply?.value,
                    containValues: form?.containValues?.value,
                    customerId: form?.customerId?.value,
                });
            }
        }
    }, [isEdit, setCustomEventTriggerForm, customEventTriggerFormData, isFinishedForm])
    const handleChange = (input) => {
        setCustomEventTrigger({...customEventTrigger, [input.target.name]: input.target.value})
    }
    const handleSave = (e) => {
        e.preventDefault()

        let customEventTriggerForSave = customEventTrigger;

        if (CONDITION_CONTAINS_TASKS !== customEventTriggerForSave.aCondition) {
            customEventTriggerForSave.containValues = null;
        }
        if (TYPE_SPECIFIC_FIELD !== customEventTriggerForSave.type) {
            customEventTriggerForSave.specificFieldId = null;
        }

        fetchCustomEventTriggerSave({
            url: isEdit
                ? apiService.generateUrl(CUSTOM_EVENT_TRIGGER_ITEM, {id: id})
                : apiService.generateUrl(CUSTOM_EVENT_TRIGGER_ADD),
            config: {
                method: 'POST',
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customEventTriggerForSave)
            }
        })
    }
    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('custom_event_trigger_save_error'))
        }
        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('custom_event_trigger_save_done'))
            setRedirect(<Redirect to={routesBase.adminCustomEventTriggers}/>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customEventTriggerDataSave, isErrorSave, isFinishedSave])
    const CardHeaderTools = () => (
        <>
            <Link to={routesBase.adminCustomEventTriggers} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )
    return (
        <AdminCard title={t('manage_custom_event_triggers')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoadingForm || isLoadingSave || !customEventTriggerForm)
                    ? <Loader message="Chargement..."/>
                    : <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                        <div className="row row-fluid mt-4">
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (customEventTrigger?.entity ? 'field-written' : null)}>
                                    <select name="entity" id="custom_event_trigger_entity" className="field__input" required={true}
                                            defaultValue={customEventTriggerForm?.entity?.value}
                                            onChange={(input) => handleChange(input)}>
                                        {customEventTriggerForm?.entity?.enum?.map((entity, entityKey) => {
                                            return (
                                                <option key={entityKey} value={entity} label={entity}></option>
                                            )
                                        })}
                                    </select>
                                    <label className="field__label" htmlFor="custom_event_trigger_entity">{t('entity')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (customEventTrigger?.field ? 'field-written' : null)}>
                                    <select name="field" id="custom_event_trigger_field" className="field__input" required={true}
                                            defaultValue={customEventTriggerForm?.field?.value}
                                            onChange={(input) => handleChange(input)}>
                                        {customEventTriggerForm?.field?.enum?.map((field, fieldKey) => {
                                            return (
                                                <option key={fieldKey} value={field} label={t(customEventTriggerForm?.field?.enum_titles?.[fieldKey])}></option>
                                            )
                                        })}
                                    </select>
                                    <label className="field__label" htmlFor="custom_event_trigger_field">{t('field')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (customEventTrigger?.value ? 'field-written' : null)}>
                                    <textarea name="value" id="custom_event_trigger_value" className="field__input"
                                           defaultValue={customEventTrigger?.value} onChange={(input) => handleChange(input)}/>
                                    <label className="field__label" htmlFor="custom_event_trigger_value">{t('default_value')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (customEventTrigger?.type ? 'field-written' : null)}>
                                    <select name="type" id="custom_event_trigger_type" className="field__input" required={true}
                                            defaultValue={customEventTriggerForm?.type?.value}
                                            onChange={(input) => handleChange(input)}>
                                        {customEventTriggerForm?.type?.enum?.map((type, typeKey) => {
                                            return (
                                                <option key={typeKey} value={type} label={t(type)}></option>
                                            )
                                        })}
                                    </select>
                                    <label className="field__label" htmlFor="custom_event_trigger_type">{t('fieldType')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (customEventTrigger?.aCondition ? 'field-written' : null)}>
                                    <select name="aCondition" id="custom_event_trigger_condition" className="field__input" required={true}
                                            defaultValue={customEventTriggerForm?.aCondition?.value}
                                            onChange={(input) => handleChange(input)}>
                                        {customEventTriggerForm?.aCondition?.enum?.map((condition, conditionKey) => {
                                            return (
                                                <option key={conditionKey} value={condition} label={t(condition)}></option>
                                            )
                                        })}
                                    </select>
                                    <label className="field__label" htmlFor="custom_event_trigger_condition">{t('condition')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                {TYPE_SPECIFIC_FIELD === customEventTrigger?.type &&
                                    <div className={'col-12 field ' + (customEventTrigger?.specificFieldId ? 'field-written' : null)}>
                                        <input type="text" name="specificFieldId" id="custom_event_trigger_specific_field_id" className="field__input"
                                               defaultValue={customEventTrigger?.specificFieldId} onChange={(input) => handleChange(input)}/>
                                        <label className="field__label" htmlFor="custom_event_trigger_specific_field_id">{t('specific_field_id_targeted')}</label>
                                    </div>
                                }
                            </div>
                            <div className={'col-6 '}>
                                {CONDITION_CONTAINS_TASKS === customEventTrigger?.aCondition &&
                                    <div className={'col-12 field ' + (customEventTrigger?.containValues ? 'field-written' : null)}>
                                        <input type="text" name="containValues" id="custom_event_trigger_contain_values" className="field__input"
                                               defaultValue={customEventTrigger?.containValues} onChange={(input) => handleChange(input)}/>
                                        <label className="field__label" htmlFor="custom_event_trigger_contain_values">{t('contain_values_field')}</label>
                                    </div>
                                }
                            </div>
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (customEventTrigger?.whenApply ? 'field-written' : null)}>
                                    <select name="whenApply" id="custom_event_trigger_when_apply" className="field__input" required={true}
                                            defaultValue={customEventTriggerForm?.whenApply?.value}
                                            onChange={(input) => handleChange(input)}>
                                        {customEventTriggerForm?.whenApply?.enum?.map((whenApply, whenApplyKey) => {
                                            return (
                                                <option key={whenApplyKey} value={whenApply} label={t(whenApply)}></option>
                                            )
                                        })}
                                    </select>
                                    <label className="field__label" htmlFor="custom_event_trigger_when_apply">{t('when_apply')}</label>
                                </div>
                            </div>
                            <div className={'col-12 '}>
                                <div className={'col-12 field ' + (customEventTrigger?.description ? 'field-written' : null)}>
                                    <input type="text" name="description" id="custom_event_trigger_description" className="field__input"
                                           defaultValue={customEventTrigger?.description} onChange={(input) => handleChange(input)}/>
                                    <label className="field__label" htmlFor="custom_event_trigger_description">{t('shortDescription')}</label>
                                </div>
                            </div>
                            <div className={'col-6 '}>
                                <p>{t('configuration_tokens_available')} :</p>
                                <ul>
                                    <li>%managedBy.initials%</li>
                                    <li>%issuedAt%</li>
                                    <li>%tenantId%</li>
                                    <li>%increment-end-when-field-begin-like:padding%</li>
                                    <br />
                                    <li>
                                        %date|value|format% => {t('for_example')} :
                                        <p>%date|first day of February next year|d-m-Y%</p>
                                        <p>%date|yesterday|d-m-Y h:i%</p>
                                        <p>%date|now|d/m/Y%</p>
                                        <p>{t('warning_task_field_date_must_be')}</p>
                                        <p>Y-m-d\TH:i : {t('for_example')} => %date|now|Y-m-d\TH:i%</p>
                                    </li>
                                </ul>
                                <p>{t('custom_event_trigger_date_value_attempted')} :</p>
                            </div>
                        </div>
                    </form>
            }
            {redirect}
        </AdminCard>
    )
}
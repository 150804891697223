import React, {useContext} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useRouteMatch} from "react-router-dom";
import {AdminContext} from "v4/contexts/AdminContext";
import TemplatesList from "v4/pages/admin/Templates/components/TemplatesList/TemplatesList";
import TemplatesForm from "v4/pages/admin/Templates/components/TemplatesForm/TemplatesForm";

export default function TemplatesPage() {
    const {t} = usePolTranslation();
    const {path, params: {id}, isExact} = useRouteMatch();
    const isAddPage = path.split('/').pop() === 'add';
    const isEditPage = !!id && isExact;
    const isListPage = (!isAddPage && !isEditPage)
    const {asCustomer} = useContext(AdminContext)

    return (
        <>
            {asCustomer?.id ?
                (
                    <>
                        {isListPage ? (
                                <TemplatesList customerId={asCustomer?.id}/>
                            ) :
                            (
                                <TemplatesForm id={id} customerId={asCustomer?.id}/>
                            )
                        }
                    </>
                ) : (
                    <p>{t('no_customer_defined')}</p>
                )
            }
        </>
    )

}

import React from 'react';

export default () => (
    <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.26 176.84">
        <title>logo-pol-v3</title>
        <g className="bar bar-1" id={'test'}>
            <polygon className="cls-1"
                     points="151.17 135.91 147.69 137.88 110.5 71.67 113.98 69.7 151.17 135.91"/>
            <circle className="cls-2" cx="149.43" cy="136.89" r="12"/>
        </g>
        <g className="bar bar-2">
            <polygon className="cls-3" points="86.62 12.72 90.35 11.29 113.35 70.69 109.62 72.13 86.62 12.72"/>
            <circle className="cls-4" cx="88.48" cy="12" r="12"/>
        </g>
        <g className="bar bar-3">
            <rect className="cls-5" x="375.73" y="251.21" width="4" height="93.53"
                  transform="translate(114.04 582.73) rotate(-132.63)"/>
            <circle className="cls-5" cx="43.03" cy="134.66" r="12"/>
        </g>
        <g>
            <path className="cls-6"
                  d="M356.35,233.73q8.56,7.28,8.56,22.36t-8.79,22.07q-8.79,7-26.85,7H318.44v22.71H300.27V226.45H329Q347.79,226.45,356.35,233.73Zm-13.33,32q3.25-3.67,3.26-10.72T342,245q-4.26-3-13.22-3H318.44v27.38h12.23Q339.76,269.43,343,265.76Z"
                  transform="translate(-300.27 -194.96)"/>
            <path className="cls-6" d="M462.66,307.86V226.45h18.17v65.22h34.71v16.19Z"
                  transform="translate(-300.27 -194.96)"/>
            <path className="cls-6"
                  d="M442.6,296.62q-12.35,12.06-30.4,12.06t-30.4-12.06q-12.35-12.06-12.34-30t12.34-30q12.34-12.06,30.4-12.06t30.4,12.06q12.34,12.06,12.34,30T442.6,296.62Z"
                  transform="translate(-300.27 -194.96)"/>
            <path className="cls-7"
                  d="M412.2,271.07a15.76,15.76,0,1,0-15.76-15.76A15.75,15.75,0,0,0,412.2,271.07Zm8.23,3.4a15.47,15.47,0,0,1-16.46,0c-8.48,2.56-14.64,9.9-14.64,18.65,0,.18.05.34.05.52H435c0-.18.05-.34.05-.52C435.07,284.37,428.91,277,420.43,274.47Z"
                  transform="translate(-300.27 -194.96)"/>
        </g>
        <path className="cls-6"
              d="M303.62,360v6.94h-1.33V349.86h5a4.69,4.69,0,0,1,3.56,1.36,6.08,6.08,0,0,1,0,7.46,4.81,4.81,0,0,1-3.63,1.3Zm0-1.25h3.63a3.4,3.4,0,0,0,2.6-1,3.86,3.86,0,0,0,.92-2.77,4,4,0,0,0-.93-2.82,3.29,3.29,0,0,0-2.58-1h-3.64Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M322,355.46a5.25,5.25,0,0,0-.82-.08,2.44,2.44,0,0,0-1.81.74,4.1,4.1,0,0,0-1,2.05v8.75H317V354.24h1.27l0,1.9a3,3,0,0,1,2.9-2.13,1.65,1.65,0,0,1,.79.15Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M325.55,359.94a6.93,6.93,0,0,1,1.24-4.34,4.43,4.43,0,0,1,6.78,0,6.83,6.83,0,0,1,1.25,4.32v1.35a6.75,6.75,0,0,1-1.25,4.35,4.44,4.44,0,0,1-6.73,0,6.61,6.61,0,0,1-1.29-4.19Zm1.32,1.3a5.86,5.86,0,0,0,.88,3.44,3.06,3.06,0,0,0,4.91.05,6,6,0,0,0,.86-3.45v-1.35a5.91,5.91,0,0,0-.89-3.45,2.85,2.85,0,0,0-2.45-1.25,2.8,2.8,0,0,0-2.4,1.23,5.73,5.73,0,0,0-.91,3.38Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M346.2,363.8a1.86,1.86,0,0,0-.68-1.44,9.31,9.31,0,0,0-2.3-1.34A12.83,12.83,0,0,1,341,359.8a3.34,3.34,0,0,1-.95-1.06,2.87,2.87,0,0,1-.3-1.36,3.14,3.14,0,0,1,1.05-2.42,4,4,0,0,1,2.73-.95,4,4,0,0,1,2.89,1,3.55,3.55,0,0,1,1.07,2.69h-1.3a2.39,2.39,0,0,0-.73-1.79,3.07,3.07,0,0,0-3.75-.12,1.92,1.92,0,0,0-.67,1.52,1.64,1.64,0,0,0,.51,1.25,10.59,10.59,0,0,0,2.23,1.3,12.93,12.93,0,0,1,2.44,1.28,3.46,3.46,0,0,1,1,1.11,3,3,0,0,1,.34,1.46,3.1,3.1,0,0,1-1.09,2.5,4.25,4.25,0,0,1-2.87.93,4.44,4.44,0,0,1-3.05-1,3.37,3.37,0,0,1-1.17-2.66h1.32a2.48,2.48,0,0,0,.79,1.83,3.1,3.1,0,0,0,2.11.65,3,3,0,0,0,1.93-.59A1.9,1.9,0,0,0,346.2,363.8Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M361.26,361.18a8,8,0,0,1-1,4.45,3.41,3.41,0,0,1-3,1.53,3.63,3.63,0,0,1-3.3-1.76v6.4h-1.29V354.24h1.2l.06,1.74a3.56,3.56,0,0,1,3.29-2,3.49,3.49,0,0,1,3.07,1.45,7.86,7.86,0,0,1,1,4.44ZM360,360a6.68,6.68,0,0,0-.75-3.58,2.64,2.64,0,0,0-2.37-1.15,3,3,0,0,0-2.94,2.1V364a3.05,3.05,0,0,0,3,1.91,2.6,2.6,0,0,0,2.32-1.15,6.57,6.57,0,0,0,.77-3.54Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M370.51,367.16a4.4,4.4,0,0,1-3.51-1.47,6.28,6.28,0,0,1-1.28-4.17V360a7.06,7.06,0,0,1,1.21-4.38,4,4,0,0,1,3.36-1.58,3.81,3.81,0,0,1,3.14,1.34,6.61,6.61,0,0,1,1.11,4.13v1.22H367v.71a5.34,5.34,0,0,0,.94,3.37,3.14,3.14,0,0,0,2.63,1.18,3.78,3.78,0,0,0,3-1.37l.68.87A4.37,4.37,0,0,1,370.51,367.16Zm-.22-11.93a2.82,2.82,0,0,0-2.37,1.08,5.51,5.51,0,0,0-.9,3.17h6.22v-.34C373.18,356.53,372.19,355.23,370.29,355.23Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M383.35,365.94a2.77,2.77,0,0,0,2.09-.76,3.23,3.23,0,0,0,.78-2.18h1.26a4.37,4.37,0,0,1-1.24,3.05,4,4,0,0,1-2.89,1.11,4.12,4.12,0,0,1-3.41-1.45,6.93,6.93,0,0,1-1.18-4.3v-1.54a7.06,7.06,0,0,1,1.15-4.39,4.1,4.1,0,0,1,3.43-1.47,3.82,3.82,0,0,1,3,1.19,5,5,0,0,1,1.16,3.32h-1.26a3.79,3.79,0,0,0-.79-2.43,2.58,2.58,0,0,0-2.09-.86,2.81,2.81,0,0,0-2.48,1.13,6.23,6.23,0,0,0-.79,3.49v1.44a6.17,6.17,0,0,0,.8,3.54A2.85,2.85,0,0,0,383.35,365.94Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M394.23,351v3.23h2.17v1.17h-2.17v8.43a3,3,0,0,0,.31,1.55,1.12,1.12,0,0,0,1,.51,5.31,5.31,0,0,0,.93-.12l0,1.18a3.29,3.29,0,0,1-1.28.2q-2.28,0-2.34-3.15v-8.6h-2v-1.17h2V351Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M407.61,363.8a1.86,1.86,0,0,0-.68-1.44,9.31,9.31,0,0,0-2.3-1.34,12.83,12.83,0,0,1-2.27-1.22,3.34,3.34,0,0,1-.95-1.06,2.87,2.87,0,0,1-.3-1.36,3.14,3.14,0,0,1,1.05-2.42,4,4,0,0,1,2.73-.95,4,4,0,0,1,2.89,1,3.52,3.52,0,0,1,1.08,2.69h-1.31a2.39,2.39,0,0,0-.73-1.79,3.07,3.07,0,0,0-3.75-.12,1.92,1.92,0,0,0-.67,1.52,1.64,1.64,0,0,0,.51,1.25,10.59,10.59,0,0,0,2.23,1.3,12.93,12.93,0,0,1,2.44,1.28,3.62,3.62,0,0,1,1,1.11,3.06,3.06,0,0,1,.34,1.46,3.13,3.13,0,0,1-1.09,2.5,4.3,4.3,0,0,1-2.88.93,4.44,4.44,0,0,1-3-1,3.36,3.36,0,0,1-1.16-2.66h1.31a2.48,2.48,0,0,0,.79,1.83,3.1,3.1,0,0,0,2.11.65,3,3,0,0,0,1.93-.59A1.9,1.9,0,0,0,407.61,363.8Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M435.9,360.24a8,8,0,0,1-1.45,5.11,5.46,5.46,0,0,1-8.12,0,7.81,7.81,0,0,1-1.48-5.07v-3.72a7.93,7.93,0,0,1,1.46-5.11,5.43,5.43,0,0,1,8.13,0,8.09,8.09,0,0,1,1.46,5.15Zm-1.35-3.7a6.86,6.86,0,0,0-1.08-4.21,4.1,4.1,0,0,0-6.16,0,6.59,6.59,0,0,0-1.13,4.12v3.82a6.72,6.72,0,0,0,1.12,4.23,3.71,3.71,0,0,0,3.09,1.41,3.64,3.64,0,0,0,3.1-1.41,7,7,0,0,0,1.06-4.23Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M442.47,354.24l0,2a4.59,4.59,0,0,1,1.43-1.64,3.39,3.39,0,0,1,2-.61q3.31,0,3.4,4.41v8.5H448v-8.35a4.37,4.37,0,0,0-.6-2.51,2.11,2.11,0,0,0-1.84-.81,2.56,2.56,0,0,0-1.83.77,4.72,4.72,0,0,0-1.24,2.1v8.8h-1.3V354.24Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6" d="M467.46,365.68h7v1.24h-8.38V349.86h1.34Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M479,350.58a1.08,1.08,0,0,1,.22-.67.77.77,0,0,1,.64-.29.79.79,0,0,1,.64.29,1.15,1.15,0,0,1,0,1.35.81.81,0,0,1-.64.27.78.78,0,0,1-.64-.27A1.09,1.09,0,0,1,479,350.58Zm1.5,16.34h-1.31V354.24h1.31Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M487.34,354.24l0,2a4.59,4.59,0,0,1,1.43-1.64,3.37,3.37,0,0,1,2-.61q3.31,0,3.4,4.41v8.5h-1.29v-8.35a4.37,4.37,0,0,0-.6-2.51,2.11,2.11,0,0,0-1.84-.81,2.56,2.56,0,0,0-1.83.77,4.72,4.72,0,0,0-1.24,2.1v8.8h-1.3V354.24Z"
              transform="translate(-300.27 -194.96)"/>
        <path className="cls-6"
              d="M503.88,367.16a4.4,4.4,0,0,1-3.51-1.47,6.33,6.33,0,0,1-1.29-4.17V360a7.06,7.06,0,0,1,1.22-4.38,4,4,0,0,1,3.36-1.58,3.81,3.81,0,0,1,3.14,1.34,6.68,6.68,0,0,1,1.11,4.13v1.22h-7.54v.71a5.28,5.28,0,0,0,.94,3.37,3.14,3.14,0,0,0,2.63,1.18,3.78,3.78,0,0,0,3-1.37l.68.87A4.37,4.37,0,0,1,503.88,367.16Zm-.22-11.93a2.8,2.8,0,0,0-2.37,1.08,5.51,5.51,0,0,0-.9,3.17h6.22v-.34C506.55,356.53,505.56,355.23,503.66,355.23Z"
              transform="translate(-300.27 -194.96)"/>
    </svg>
)

import React, {useState, useEffect} from "react";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import {
    arrowSort,
    importPaginationRenderer
} from "../../../../components/Datatables/_partials/config";
import paginationFactory from "react-bootstrap-table2-paginator";
import {compareObjectsStr} from "../../../../utils/selectOptionsSort";
import {PolIcon} from "../../../../components/PolIcon/policon";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    datatableColsState, formDataState,
    importDataState,
    skipRowState,
    specificFieldsState
} from "../../../../components/Admin/atoms/importAdmin"
import ReactTooltip from "react-tooltip";

const StepTwoDatatable = ({
                              t, data, columns, required, setAffectedColsLength,
                              setOutputs, validationErrors, formValues, setImportableObjects, isEditTabs, setConcatFields
                          }) => {
    let [datatableCols, setDatatableCols] = useRecoilState(datatableColsState);
    const skipRow = useRecoilValue(skipRowState)
    const [defaultNewData, setDefaultNewData] = useState([]);
    const [oldSkipRow, setOldSkipRow] = useState(skipRow);
    const specificFields = useRecoilValue(specificFieldsState)
    const formData = useRecoilValue(formDataState)
    let [newData, setNewData] = useRecoilState(importDataState);
    const [optionsSelect, setOptionsSelect] = useState([]);
    const {SearchBar} = Search;
    const [numberItem, setNumberItem] = useState(10)

    // Pagination
    const config = {
        pageListRenderer: importPaginationRenderer,
        prePageText: '<',
        withFirstAndLast: false,
        nextPageText: '>',
        sizePerPage: parseInt(numberItem),
        onSizePerPageChange: (sizePerPage) => {
            setNumberItem(sizePerPage)
        },
    };


    const headerFormatter = (column) => {
        let value = optionsSelect
            .find(item => item.fieldKey === column.fieldKey);
        if (!value) {
            value = null;
        }

        return <>
            <Select
                onChange={(value) => {
                    setImportableObjects(0);
                    const indexColumn = datatableCols.findIndex(item => item.fieldKey === value.fieldKey)
                    let newDatatableCols = [...datatableCols];

                    if (indexColumn !== -1) {
                        newDatatableCols[indexColumn] = {
                            ...newDatatableCols[indexColumn],
                            fieldKey: null,
                            text: ''
                        }
                    }
                    const colIndex = newDatatableCols.findIndex(item => item.id === column.id)
                    newDatatableCols[colIndex] = {
                        ...newDatatableCols[colIndex],
                        fieldKey: value.fieldKey,
                        text: value.label
                    };
                    datatableCols = newDatatableCols
                    setAffectedColsLength(datatableCols.filter((item) => !!item.fieldKey).length)
                    setOutputs(outputsValidationFormatter(newData, newDatatableCols, specificFields, formData, formValues))
                    setDatatableCols([...newDatatableCols])
                }}
                options={optionsSelect}
                value={value}
                isMulti={false}
                styles={selectStyle}
                placeholder={t('pick_element')}
            />

            {
                formData[value?.fieldKey]?.attr?.isConcatenable && <div className="d-flex">
                    <input id={`memo_${value.fieldKey}`} type="checkbox" onChange={e => {
                        const checked = e.target.checked;

                        const split = value.fieldKey.split('_');
                        if (split.length !== 2) {
                            setConcatFields(currentConcatFields => {
                                return {
                                    ...currentConcatFields,
                                    [value.fieldKey]: checked
                                }
                            });

                            return;
                        }

                        setConcatFields(currentConcatFields => {
                            return {
                                ...currentConcatFields,
                                [split[0]]: {
                                    ...currentConcatFields[split[0]],
                                    [split[1]]: checked
                                }
                            }
                        });
                    }}/>
                    <label htmlFor={`memo_${value.fieldKey}`}>{t('import_concat_instead_of_replace')}</label>
                </div>
            }
        </>
    }

    const cellFormatter = (cell, row, id) => {
        if (row) {
            const item = datatableCols.find(col => col.id === id)
            const rowKey = item ? item.fieldKey : undefined

            const cellHasError = rowKey && row.import_error && row.import_error[rowKey] !== undefined;

            return (
                <>
                    {!cellHasError ? (
                        <>
                            {cell}
                        </>
                    ) : (
                        <div className={'import-error'}
                             data-for={'cell-formatter'}
                             data-tip={t(row.import_error[rowKey])}
                             onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                             onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                        >
                            <p className={'import-value-error'}>{cell}</p>
                            <PolIcon icon={'exclamation-circle'} className={'form__error__icon-import'}/>
                            <ReactTooltip effect="solid" id={'cell-formatter'} multiline={true}/>
                        </div>
                    )}
                </>
            )
        }
    }

    useEffect(() => {

            if (datatableCols.length && newData.length && skipRow !== oldSkipRow) {
                if (!defaultNewData.length) {
                    // On sauvegarde les datas originales car le splice détruit les élements via pointeur
                    setDefaultNewData([...newData])
                }
                const splicedData = !defaultNewData.length ? [...newData] : [...defaultNewData]
                if (skipRow < splicedData.length) {
                    splicedData.splice(0, skipRow)
                    setNewData(splicedData)
                    setOldSkipRow(skipRow)
                }
                return;
            }
            if (!validationErrors.length) {
                const mappedColumns = [];
                const newCols = [];
                const formattedData = [];

                if (!data[0]) {
                    return;
                }
                const columnValues = Object.values(columns);
                const columnsKeys = Object.keys(columns);
                let hasHeader = columnValues.some(r => data[0].includes(t(r)));

                if (hasHeader) {
                    const headerRow = data[0];
                    data.splice(0, skipRow)
                    headerRow.forEach((value, i) => {
                        let index = columnValues.findIndex(colValue => colValue.replace(' ', '').toUpperCase()
                            === value.replace(' ', '').toUpperCase());
                        if (index === -1) {
                            index = columnValues.findIndex(colValue => t(colValue).replace(' ', '').toUpperCase()
                                === t(value).replace(' ', '').toUpperCase());
                        }
                        if (index === -1) {
                            index = columnValues.findIndex(colValue => {
                                let label = '';
                                if (colValue.search('Contact_') !== -1) {
                                    const split = colValue.split(' ');
                                    const tradKey = split.pop();
                                    const prefix = split.shift();
                                    label = `${prefix} ${t(tradKey)}`
                                } else if (colValue.search('(livraison)') !== -1) {
                                    const split = colValue.split(' ');
                                    const tradKey = split.shift();
                                    const prefix = split.pop();
                                    label = `${t(tradKey)} ${prefix}`
                                }
                                return label.replace(' ', '').toUpperCase() === value.replace(' ', '').toUpperCase();
                            });
                        }
                        mappedColumns.push({
                            value: value,
                            index: i,
                        })
                        const dataField = columnsKeys[index];

                        newCols.push({
                            id: i,
                            text: value,
                            dataField: dataField ? dataField : i,
                            fieldKey: dataField,
                            headerFormatter: headerFormatter,
                            formatter: (cell, row) => cellFormatter(cell, row, i),
                            order: index,
                            showOrder: i
                        })
                    })
                } else {
                    Object.keys(data[0]).forEach((value, j) => {
                        mappedColumns.push({
                            value: value,
                            index: j
                        })
                        newCols.push({
                            id: j,
                            text: value,
                            dataField: j,
                            headerFormatter: headerFormatter,
                            formatter: (cell, row) => cellFormatter(cell, row, j)
                        })
                    })
                }
                // Set options
                columnValues.forEach((value) => {
                    let label = t(value);
                    // Si pas de traduction
                    if (value === label) {
                        if (value.search('Contact_') !== -1) {
                            const split = value.split(' ');
                            const tradKey = split.pop();
                            const prefix = split.shift();
                            label = `${prefix} ${t(tradKey)}`
                        } else if (value.search('(livraison)') !== -1) {
                            const split = value.split(' ');
                            const tradKey = split.shift();
                            const prefix = split.pop();
                            label = `${t(tradKey)} ${prefix}`
                        }
                    }

                    optionsSelect.push({
                        label: `${label} ${required.indexOf(value) !== -1 ? '*' : ''}`,
                        value: value,
                        fieldKey: columnsKeys[columnValues.indexOf(value)]
                    })
                })
                data.forEach((elt) => {
                    let object = {};
                    if (elt.length <= 1) {
                        return '';
                    }

                    mappedColumns.forEach((col) => {
                        let index = columnValues.findIndex(colValue => colValue.replace(' ', '').toUpperCase()
                            === col.value.replace(' ', '').toUpperCase());
                        if (index === -1) {
                            index = columnValues.findIndex(colValue => t(colValue).replace(' ', '').toUpperCase()
                                === t(col.value).replace(' ', '').toUpperCase());
                        }
                        if (index === -1) {
                            index = columnValues.findIndex(colValue => {
                                let label = '';
                                if (colValue.search('Contact_') !== -1) {
                                    const split = colValue.split(' ');
                                    const tradKey = split.pop();
                                    const prefix = split.shift();
                                    label = `${prefix} ${t(tradKey)}`
                                } else if (colValue.search('(livraison)') !== -1) {
                                    const split = colValue.split(' ');
                                    const tradKey = split.shift();
                                    const prefix = split.pop();
                                    label = `${t(tradKey)} ${prefix}`
                                }
                                return label.replace(' ', '').toUpperCase() === col.value.replace(' ', '').toUpperCase();
                            });
                        }
                        const objKey = columnsKeys[index]
                        object = {
                            ...object,
                            [objKey ? objKey : col.index]: elt[col.index]
                        }
                    })

                    let isDoublon = -1;
                    switch (formValues.entityToImport) {
                        case 'Contacts':
                            isDoublon = formattedData.findIndex(item => item['firstname'] === object['firstname']
                                && item['lastname'] === object['lastname'] && item['Prospect'] === object['Prospect'])
                            ;
                            break;
                        case 'Particuliers':
                            isDoublon = formattedData.findIndex(item => item['contact_firstname'] === object['contact_firstname']
                                && item['contact_lastname'] === object['contact_lastname'])
                            ;
                            break;
                        case 'Prospects / Clients':
                            isDoublon = formattedData.findIndex(item => item['company_name'] === object['company_name']
                                && item['company_name'] === object['company_name'])
                            ;
                            break;
                        default:
                            console.error("Le type d'entité à importer est invalide");
                            object = {
                                ...object,
                                isDoublon: true,
                                import_status: false,
                                import_error: {error: 'error_import'}
                            }
                            break;
                    }

                    if (isDoublon !== -1) {
                        object = {
                            ...object,
                            isDoublon: true,
                            import_status: false,
                            import_error: {error: 'import_doublon'}
                        }
                    }
                    formattedData.push(object)
                })

                // Add status and error cols
                newCols.unshift({
                    text: '',
                    dataField: 'isEdit',
                    formatter: (cell) => {
                        if (cell === true || cell === false) {
                            return (
                                <p>{cell ? 'MAJ' : 'NEW'}</p>
                            )
                        }
                    }

                }, {
                    text: t('status'),
                    dataField: 'import_status',
                    formatter: (cell) => {
                        if (cell !== undefined) {
                            return (
                                <span className={`${cell ? 'td-success' : ''}`}/>
                            )
                        }
                    },
                    sort: true,
                    sortCaret: (order, column) => arrowSort(order, column),
                }, {
                    text: '',
                    dataField: 'import_error',
                    formatter: (cell) => {
                        if (cell && Object.keys(cell).length) {
                            return (
                                <div className={'import-error'}
                                     data-for="cell"
                                     data-tip={Object.keys(cell).map(
                                         key => {
                                             const findIndexSpecFields = specificFields.findIndex(item => item.key === key);
                                             const label = findIndexSpecFields !== -1 && specificFields[findIndexSpecFields].label ? specificFields[findIndexSpecFields].label : key

                                             return `${findIndexSpecFields !== -1 ? label
                                                 : t(label)} : ${t(cell[key])}`
                                         }).join('<br />')}
                                     onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                     onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                                >
                                    <PolIcon icon={'exclamation-circle'} className={'form__error__icon-import'}/>
                                    <ReactTooltip effect="solid" id={'cell'} multiline={true}/>
                                </div>
                            )
                        }
                    }
                })

                newCols.sort((a, b) => a.showOrder - b.showOrder);

                newCols.length = data[0].length + 3;
                setNewData([...formattedData])
                setDatatableCols([...newCols]) // eslint-disable-next-line
                datatableCols = newCols; // eslint-disable-next-line
                newData = formattedData
                setAffectedColsLength(newCols.filter((item) => !!item.fieldKey).length)
                setOutputs(outputsValidationFormatter(formattedData, newCols, specificFields, formData, formValues))
                setOptionsSelect(optionsSelect.sort(compareObjectsStr))
            } else {
                const newArray = [...newData]
                newArray.forEach((elt, indexData) => {
                    const validateItemError = validationErrors[indexData]

                    newArray[indexData] = {
                        ...elt,
                        'import_status': newArray[indexData].isDoublon ? false : !(validateItemError && Object.keys(validateItemError).length),
                        'import_error': newArray[indexData].isDoublon ? {...validateItemError, ...newArray[indexData].import_error} : validateItemError,
                        'isEdit': isEditTabs[indexData]
                    }
                })

                setNewData([...newArray])
                setImportableObjects(newArray.filter(item => item.import_status === true).length)
                setDatatableCols([...datatableCols])
                setOutputs(outputsValidationFormatter(newArray, datatableCols, specificFields, formData, formValues))
            }
        }
        ,
        [data, columns, validationErrors, skipRow, isEditTabs]
    );

    const rowClasses = (row) => {
        return row.import_status === false ? 'tr-error' : '';
    };

    return (
        <>
            {datatableCols.length ? (
                <div className={'admin-datatable row'}>
                    <div className={'col-12'}>
                        <ToolkitProvider
                            keyField='id'
                            data={newData}
                            columns={datatableCols}
                            search
                        >
                            {props => (
                                <div className={`table-container`}>

                                    <div className={'row row-fluid justify-content-end'}>
                                        <div className={'col-12 col-md-3 col-xl-2 col-fluid'}>
                                            <div className={'datatable__search datatable__loader'}>
                                                <SearchBar {...props.searchProps} className={'datatable__search__input'}
                                                           placeholder={t('votre_recherche')}/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={'table-content table-import'}>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            classes={'datatable datatable-loader'}
                                            onDataSizeChange={() => {
                                            }}
                                            wrapperClasses="table-responsive"
                                            pagination={paginationFactory(config)}
                                            filter={filterFactory()}
                                            rowClasses={rowClasses}
                                        />
                                    </div>
                                </div>
                            )}
                        </ToolkitProvider>
                        <ReactTooltip effect="solid" multiline={true}/>
                    </div>
                </div>) : null}
        </>
    )
}

const selectStyle = {
    control: (base) => ({
        ...base,
        '&:hover': {},
        border: '2px solid #eef0fa',
        boxShadow: 'none',
        color: '#372e74',
        background: '#f3f1fc'
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused || state.isSelected ? '#ebe7ff' : '#f3f1fc',
        cursor: 'pointer',
        color: '#555299',
    }),
    container: (base, state) => {
        return ({
            ...base,
            zIndex: state.isFocused ? "999" : "1",
            width: '180px',

        })
    },
    singleValue: (provided) => ({
        ...provided,
        color: '#555299'
    }),
}

export const outputsValidationFormatter = (data, dataTableCols, specificFields, formData, formValues) => {
    const formattedData = [];

    if (data.length && dataTableCols.length) {
        data.forEach((item) => {
            let newObj = {}
            if (item.import_status === false) {
                newObj = {
                    ...newObj,
                    import_status: false
                }
            }
            dataTableCols.forEach((map) => {
                const fieldKey = map.fieldKey;

                if (fieldKey) {
                    const split = fieldKey.split('_')
                    const isSpecificFields = specificFields && specificFields.findIndex((item) => {
                        return item.key === fieldKey
                    }) !== -1;
                    const value = formData[isSpecificFields ? fieldKey : split[split.length - 1]] &&
                    formData[isSpecificFields ? fieldKey : split[split.length - 1]].type === "array" && item[map.dataField]
                        ? item[map.dataField].split(formValues && formValues.delimiterMultipleValue ? formValues.delimiterMultipleValue : ';') : item[map.dataField]

                    if ((split.length === 2 && !isSpecificFields)) {
                        const groupBy = split[0];
                        const subKey = split[1];
                        newObj = {
                            ...newObj,
                            [groupBy]: {
                                ...newObj[groupBy],
                                [subKey]: value
                            }
                        }
                    } else {
                        newObj = {
                            ...newObj,
                            [fieldKey]: value
                        }
                    }
                }
            })
            formattedData.push(newObj)
        })
    }
    return formattedData;
}

export default (withPolTranslation(StepTwoDatatable));


import React from 'react';
import {useParams} from "react-router-dom";
import {useFrontContext} from "v4/contexts/FrontContext";
import {CONTACT, PROSPECT, QUOTE, TASK} from "v4/data/entities";
import useTestRoute from "v4/hooks/useTestRoute";
import ContactsPage from "v4/pages/front/Contacts/ContactsPage";
import ProspectPage from "v4/pages/front/Prospect/ProspectPage";
import ProspectsPage from "v4/pages/front/Prospects/ProspectsPage";
import QuotesPage from "v4/pages/front/Quotes/QuotesPage";
import TasksPage from "v4/pages/front/Tasks/TasksPage";
import useHashPulse from "v4/hooks/useHashPulse";

export default function ListingPageLayout() {
    const {currentListingEntity, tab} = useFrontContext();
    const isMapPage = useTestRoute({inParams: ['prospectId'], inUrl: ['maps']}, true);
    const isTabsPage = useTestRoute({inParams: ['prospectId', 'tabName']});
    const {prospectId, tabName} = useParams();
    const isProspectPage = (isTabsPage || isMapPage);
    useHashPulse(!isProspectPage);

    return (
        <>
            {switchListingPage(currentListingEntity, tab, isProspectPage)}

            {isProspectPage && <ProspectPage prospectId={prospectId} currentTabName={tabName} isMapPage={isMapPage}/>}
        </>
    )
}

function switchListingPage(entity, tab, isProspectPage) {
    switch (entity) {
        case CONTACT:
            return <ContactsPage key={tab?.id} tab={tab} isProspectPage={isProspectPage}/>;
        case PROSPECT:
            return <ProspectsPage key={tab?.id} tab={tab} isProspectPage={isProspectPage}/>;
        case TASK:
            return <TasksPage key={tab?.id} tab={tab} isProspectPage={isProspectPage}/>;
        case QUOTE:
            return <QuotesPage key={tab?.id} tab={tab} isProspectPage={isProspectPage}/>;
        default:
            return null;
    }
}

import React, {Component} from 'react';
import UserService from "../../../api/user/user";
import {Redirect} from "react-router-dom";
import {PolIcon} from "../../PolIcon/policon";
import ReactTooltip from "react-tooltip";

export class GlobalSearch extends Component {
    constructor(props) {
        super(props);
        this.globalSearchInput = React.createRef();
        this.state = {
            search: "",
            oldSearch: "",
            redirection: false,
            doInputIsOpen: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeValue(e) {
        this.setState({search: e.target.value});
    }

    handleSubmit(e) {
        e.preventDefault();
        const {search} = this.state;

        if(!search) {
            return;
        }

        UserService.setGlobalSearch({search});
        this.setState({
            redirection: true,
            oldSearch: search
        }, () => this.setState({redirection: false}));
    }

    render() {
        const {t} = this.props;
        const {search, redirection, doInputIsOpen, oldSearch} = this.state;
        if (redirection) {
            //Affichage de la redirection
            return <Redirect to='/recherche'/>;
        }

        return (
            <form className={doInputIsOpen ? "globalSearch--extended" : ""} onSubmit={(e) => this.handleSubmit(e)}>
                <input ref={this.globalSearchInput} className={`globalSearch__input ${doInputIsOpen ? "" : 'input__close'}`} value={search} placeholder={t('default_value_search')} onChange={(e) => this.handleChangeValue(e)} onKeyPress={(e) => {
                    if (e.charCode === 13) {
                        this.handleSubmit(e)
                        setTimeout(() => {
                            this.globalSearchInput.current.focus()
                        }, 300)
                    }
                }} onBlur={() => {
                    this.setState({
                        doInputIsOpen: false
                    })
                }}/>
                <button type={'submit'} data-for="global-search" data-tip={t('make_global_search')} onClick={(e) => {
                    if (search === oldSearch) {
                        e.preventDefault()
                        this.setState({
                            search: ""
                        })
                    }

                    if (!doInputIsOpen || search === "") {
                        this.setState({
                            doInputIsOpen: true
                        }, () => {setTimeout(() => {this.globalSearchInput.current.focus()}, 300)})
                    }
                }}>
                    <PolIcon icon={'search'}/>
                </button>
                <ReactTooltip id="global-search" effect="solid"/>
            </form>
        );
    }
}

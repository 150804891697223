import React, {Component} from 'react'
import {connect} from 'react-redux'
import {NavLink} from "react-router-dom";
import {menuGroupAdmin, routes} from "../../routes";
import UserService from "../../api/user/user";
import {withPolTranslation} from "../../v4/hooks/usePolTranslation";
import {getRoutesParams} from "v4/data/appRoutes";

class Administration extends Component {
    render() {
        const {t} = this.props;
        return (
            <>
                <div className="page page-admin content-wrapper">
                    <h1 className={'admin__title'}>{t('administration')}</h1>
                    <section className={'row row-fluid'}>

                        {menuGroupAdmin.map((group, index) => {
                            const subMenuV4 = getRoutesParams().filter(route => (route.isAdmin && route.group === group.groupName));
                            const submenu = routes.filter(route => (route.isAdmin && route.group === group.groupName));
                            const groupAccessGranted = UserService.controlAccess(group.roles);

                            return (
                                groupAccessGranted && submenu.length ? (
                                    <div className={'col-12 col-md-6'} key={index}>
                                        <article className={' box'}>
                                            <header className={'box__header'}>
                                                <p className={'box__header__text'}>{t(group.name)}</p>
                                            </header>
                                            <div className={'box__body'}>
                                                <ul className={'list'}>
                                                    {
                                                        submenu.map((subMenuItem, key) => {
                                                            const menuAccessGranted = UserService.controlAccess(subMenuItem.roles);
                                                            return (
                                                                menuAccessGranted ? (
                                                                    <li className={'list__item'} key={key}>
                                                                        <NavLink to={subMenuItem.path}
                                                                                 className={'list__item__link'}>
                                                                            {t(subMenuItem.text)}
                                                                        </NavLink>
                                                                    </li>
                                                                ) : null
                                                            )
                                                        })
                                                    }
                                                    {
                                                        subMenuV4.map((subMenuItem, key) => {
                                                            const menuAccessGranted = UserService.controlAccess(subMenuItem.roles);
                                                            return (
                                                                menuAccessGranted ? (
                                                                    <li className={'list__item'} key={key}>
                                                                        <NavLink to={subMenuItem.basePath}
                                                                                 className={'list__item__link'}>
                                                                            {t(subMenuItem.menuTitle)}
                                                                        </NavLink>
                                                                    </li>
                                                                ) : null
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </article>
                                    </div>
                                ) : null
                            )
                        })}
                    </section>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({router}) => ({
    router
});

export default connect(
    mapStateToProps,
    null
)(withPolTranslation(Administration));

import React, {useState} from 'react';
import Field from "v4/components/form/Field/Field";
import {PolIcon} from "components/PolIcon/policon";
import apiService from "v4/services/api.service";
import {IMAGE} from "v4/data/apiRoutes";

import 'v4/components/form/Field/components/InputFile/components/DocumentsHolder/DocumentsHolder.scss';

export default function DocumentsHolder({multiple, filesFromServer, fieldName, shouldDeleteType, register}) {
    const [isDelete, setIsDelete] = useState(false);
    const handleChange = value => setIsDelete(value[fieldName+'[shouldDelete]']);

    return (
        <div className={`documents-holder${multiple ? ' multiple' : ''}`}>
            {!multiple && filesFromServer && (
                <div
                    className={`document${['image/jpeg', 'image/png', 'image/webp'].includes(filesFromServer?.mimeType) ? ' is-image' : ''}${isDelete ? ' is-delete' : ''}`}>
                    {
                        ['image/jpeg', 'image/png'].includes(filesFromServer?.mimeType)
                            ? <img src={apiService.generateUrl(IMAGE, {id: filesFromServer.id})}
                                   alt={filesFromServer.realFileName}/>
                            : (
                                <p>
                                    <PolIcon icon="file" className="file-icon"/>
                                    <span>{filesFromServer.realFileName}</span>
                                </p>
                            )
                    }
                    <label className="document-delete">
                        <Field name={`${fieldName}[shouldDelete]`} type={shouldDeleteType} register={register} onFieldChange={handleChange}/>
                        <PolIcon icon="trash" className="delete-icon"/>
                    </label>
                </div>
            )}
            {multiple && filesFromServer && filesFromServer.map((file, index) => (
                <div key={file.fileName} className="document">
                    <p>
                        <PolIcon icon="file" className="file-icon"/>
                        <span>{file.fileLabel ?? file.realFileName}</span>
                    </p>
                    <label className="document-delete">
                        <Field name={`${fieldName}[shouldDelete][${index}]`} type={shouldDeleteType}
                               register={register}/>
                        <PolIcon icon="trash" className="delete-icon"/>
                    </label>
                </div>)
            )}
        </div>
    )
}

import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";
import filterFactory from 'react-bootstrap-table2-filter';
import UserService from "../../../api/user/user";
import {adminFetchHandler, fetchHandler, manageError} from "../../../api/datas/datas";
import {setLoader, setPopup} from "../../../actions/content";
import {PolIcon} from "../../PolIcon/policon";
import {DeleteService} from "../../../api/datas/delete";
import {useRecoilValue} from "recoil";
import {customerIdSelector} from "../atoms/customer";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
    importPaginationRenderer
} from "../../../components/Datatables/_partials/config";
import PopupInfoConfirm from "../../Popup/_popup_info_confirm";

const {SearchBar} = Search;

export const AdminDatatables = ({columns, info, adminFetchHandler, disableCustomerIdFilter, changeView, deleteService, t, setLoader, togglePopup}) => {
    const [cols, setColumns] = useState(columns);
    const [data, setData] = useState([]);
    const [numberItem, setNumberItem] = useState(10);
    const customerId = useRecoilValue(customerIdSelector);
    const user = UserService.getUser();

    const getData = () => {
        const user = UserService.getUser();
        const token = user.token ? user.token : localStorage.getItem('token');

        setLoader({
            [info.loader]: true
        });

        let url = new URL(`${process.env.REACT_APP_HOST_API}${info.url}`);
        if (customerId && !disableCustomerIdFilter) {
            url.searchParams.append('customerId', customerId);
        } else if (disableCustomerIdFilter) {
            url.searchParams.append('allowAll', 'true');
        }
        if (info.queryParams) {
            url = info.queryParams.map((param) => {
                url.searchParams.append(param.key, param.value);

                return url;
            });
        }
        fetch(url.toString(), {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
        }).then(
            fetchHandler,
            error => {
                adminFetchHandler(error, {})
            }
        ).then(
            data => {
                if (data.data) {
                    data = data.data;
                }

                if (data && data['hydra:member']) {
                    data['hydra:member'].forEach((item, key) => {
                        if (item.params) {
                            data['hydra:member'][key].name = t(item.name)
                        }
                        if (Array.isArray(item.roles)) {
                            data['hydra:member'][key].roles = item.roles.map(item => t(item)).join(' / ')
                        }
                    })
                    setData(data['hydra:member'])
                } else {
                    manageError(500, info)
                }
                setLoader({
                    [info.loader]: false
                });
            },
            error => {
                adminFetchHandler(error, {})
                setLoader({
                    [info.loader]: false
                });
            }
        );
    };

    useEffect(() => {
        getData(); // eslint-disable-next-line
    }, [data.length, customerId]);

    if (cols && cols.length) {
        const isSetActions = cols.filter(item => item.dataField === 'actions');
        if (!isSetActions.length) {
            const actionsField = {
                text: "",
                dataField: 'actions',
                formatter: (cellContent, row) => {
                    return (
                        <ul className={'datatable__actions'}>
                            <li className={'datatable__actions__item'} data-action={'update'}>
                                    <span className={'datatable__actions__item__icon'}>
                                        <PolIcon icon="edit"/>
                                    </span>
                            </li>
                            {row && !row.params && row.id !== user.id ? (
                                <li className={'datatable__actions__item'} data-action={'delete'}>
                                    <span className={'datatable__actions__item__icon'} data-action={'delete'}>
                                        <PolIcon icon="bin"/>
                                    </span>
                                </li>) : null}
                        </ul>
                    )
                },
            };

            cols.push(actionsField);
            setColumns(cols)
        }
    }


    const clickElement = (e, row, rowIndex) => {
        const eventTarget = e.target;
        const eventDataAction = eventTarget.dataset.action;
        const eventParentDataAction = eventTarget.parentNode.dataset.action;
        const deleteInfo = eventDataAction === 'delete' || eventParentDataAction === 'delete';

        if (deleteInfo) {
            togglePopup({
                isOpen: true,
                target: 'delete_item',
                content: (
                    <PopupInfoConfirm
                        message={t('confirm_delete')}
                        onDecline={() => {
                            togglePopup({})
                        }}
                        onAccept={() => {
                            deleteService({
                                deleteUrl: info.deleteUrl.replace('{id}', row.id),
                                callback: () => {
                                    getData();
                                }
                            });
                            togglePopup({});
                        }}
                    />
                )
            });

            return false;
        }


        changeView(row);
    };

    const config = {
        pageListRenderer: importPaginationRenderer,
        prePageText: '<',
        withFirstAndLast: false,
        nextPageText: '>',
        sizePerPage: parseInt(numberItem),
        onSizePerPageChange: (sizePerPage) => {
            setNumberItem(sizePerPage)
        }
    }

    return (
        <>
                <ToolkitProvider
                    keyField='id'
                    data={data}
                    columns={cols}
                    search
                >
                    {props => (
                        <div className={`table-container`}>

                            <div className={'row row-fluid justify-content-end'}>
                                <div className={'col-12 col-md-3 col-xl-2 col-fluid'}>
                                    <div className={'datatable__search datatable__loader'}>
                                        <SearchBar {...props.searchProps} className={'datatable__search__input'}
                                                   placeholder={t('votre_recherche')}/>
                                    </div>
                                </div>
                            </div>


                            <div className={'table-content'}>
                                <BootstrapTable
                                    {...props.baseProps}
                                    classes={'datatable datatable-loader'}
                                    selectRow={{
                                        mode: 'checkbox',
                                    }}
                                    pagination={paginationFactory(config)}
                                    rowEvents={{
                                        onClick: (e, row, rowIndex) => clickElement(e, row, rowIndex)
                                    }}
                                    filter={filterFactory()}
                                    keyField="id"
                                />
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
        </>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        manageError: (code, error) => dispatch(manageError(code, error)),
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
        deleteService: (info) => dispatch(DeleteService(info)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info)),
        setLoader: (value) => dispatch(setLoader(value)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(React.memo(AdminDatatables)));

import {withPolTranslation} from "v4/hooks/usePolTranslation";
import React, {useEffect, useState} from 'react';
import {debounce} from "../../../utils/debounce";
import {useLocation} from "react-router-dom";

const PolSearch = ({onSearch, t, setSearchBarMemory, preSearch}) => {
    const location = useLocation();
    const [isLoaded, setLoaded] = useState(false);
    const [search, setSearch] = useState('');

    const handleChange = (e) => {
        const valueSearch = e.target.value;
        setSearch(`${valueSearch}`);

        debounce( () => {
            setSearchBarMemory(`${valueSearch}`);
            //onSearch(valueSearch.replace(/\s/g, ''));
            onSearch(valueSearch);
        }, 300)
    };

    useEffect(() => {
       if(!isLoaded && preSearch && preSearch.length && !location?.state?.from.match(/\/maps$/g)){
           setSearch(`${preSearch}`)
           onSearch(preSearch.replace(/\s/g, ''))
           setLoaded(true);
       } // eslint-disable-next-line 
    }, [isLoaded]);

    return (
        <div>
            <input
                className={'datatable__search__input'}
                placeholder={t('votre_recherche')}
                type="text"
                onChange={(e) => handleChange(e)}
                value={search}
            />
        </div>
    );
};
export default withPolTranslation(PolSearch);

export const setFolderList = (folderList) => {
    return {
        type: 'SET_FOLDER_LIST',
        folderList: folderList
    }
};

export const setDirectoryList = (directoryList, getType, isRoot) => {
    return {
        type: 'SET_DIRECTORY_LIST',
        directoryList,
        getType,
        isRoot
    }
}

export const resetGedData = () => {
    return {
        type: 'RESET_GED_DATA'
    }
}
import React, {createContext, useCallback, useContext, useReducer} from 'react';

const defaultContext = {
    prospectId: '',
    currentTabName: '',
    mapProspect: null,
    quote: null,
    prospect: null,
    setProspect: () => {
    },
    setMapProspect: () => {
    },
    setQuote: () => {
    }
}

const prospectPageContext = createContext(defaultContext);

export const useProspectPageContext = () => useContext(prospectPageContext);

const prospectPageReducer = (state, action) => {
    switch (action.type) {
        case 'SET_MAP_PROSPECT':
            return {
                ...state,
                mapProspect: action.payload
            }
        case 'SET_QUOTE':
            return {
                ...state,
                quote: action.payload
            }
        case 'SET_PROSPECT':
            return {
                ...state,
                prospect: action.payload
            }
        default:
            return state;
    }
}

export default function ProspectPageProvider({prospectId, currentTabName, children}) {
    const [state, dispatch] = useReducer(prospectPageReducer, defaultContext);

    const setMapProspect = useCallback((mapProspect) => dispatch({type: 'SET_MAP_PROSPECT', payload: mapProspect}), []);

    const setQuote = useCallback((quote) => dispatch({type: 'SET_QUOTE', payload: quote}), []);

    const setProspect = useCallback((prospect) => dispatch({type: 'SET_PROSPECT', payload: prospect}), []);

    const contextValues = {
        ...state,
        prospectId,
        currentTabName,
        setMapProspect,
        setQuote,
        setProspect,
    };

    return (
        <prospectPageContext.Provider value={contextValues}>
            {children}
        </prospectPageContext.Provider>
    )
}

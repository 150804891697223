import {useForm} from "react-hook-form";
import useFetch from "v4/hooks/useFetch";
import {useEffect, useMemo} from "react";
import {generateUrl} from "v4/services/api.service";
import {TASK_MOVE, TASK_MOVE_FORM} from "v4/data/apiRoutes";
import {useHistory, useRouteMatch} from "react-router-dom";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function useMoveTask(taskId, toggleOpen, setInternalState) {
    const {t} = usePolTranslation();
    const methods = useForm();
    const [{data, isError}, fetchForm] = useFetch();
    const [{data: saveData, isError: isSaveError, isLoading}, fetchSave] = useFetch();
    const {replace, location} = useHistory();
    const {params} = useRouteMatch();
    const {addErrorNotification} = useNotification();

    useEffect(() => {
        fetchForm({
            url: generateUrl(TASK_MOVE_FORM)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskId]);

    const inputs = useMemo(() => data?.['hydra:member']?.results ?? null, [data]);

    const submit = methods.handleSubmit(values => {
        fetchSave({
            url: generateUrl(TASK_MOVE, {id: taskId}),
            config: {
                method: 'POST',
                body: JSON.stringify(values)
            }
        })
    });

    useEffect(() => {
        setInternalState({
            inputs,
            isError,
            submit,
            isLoading,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputs, isError, isLoading]);

    useEffect(() => {
        if (isSaveError) {
            addErrorNotification(t('error'));
        }
        if (saveData) {
            const {prospectId} = params ?? {};
            const shouldRedirect = prospectId !== saveData.prospectId;
            replace({
                pathname: shouldRedirect ? location.pathname.replace(prospectId, saveData.prospectId) : location.pathname,
                state: {
                    hasToRefresh: true
                }
            })
            toggleOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveData, replace, isSaveError]);

    return {
        methods,
        inputs,
        isError
    }
}

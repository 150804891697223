import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_CLOSED_TASKS, TAB_OPEN_TASKS} from "v4/data/tabNames";

export default function DashboardTasksListingTableRowAction({isClosed, prospectId, rowId}) {
    const slug = isClosed ? TAB_CLOSED_TASKS : TAB_OPEN_TASKS;

    return (
        <>
            <Link to={`${routesBase.baseProspect}/${prospectId}/${slug}/${rowId}`} className="table-link"/>
        </>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import CustomReactSelect from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import useQuoteState from "v4/features/admin/adminForms/components/QuoteState/hooks/useQuoteState";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function QuoteState({rawValuesFromAdmin}) {
    const {t} = usePolTranslation();
    const [
        inputs,
        handleSubmit,
        register,
        onDelete,
        onAdd,
        onExtraChange,
        quoteStateStatusOptions,
        showReasons,
        getQuoteStateReasonsOptions
    ] = useQuoteState(rawValuesFromAdmin);

    return (
        <form className="form form__scrollable" onSubmit={handleSubmit}>
            <div className="col-12 col-md-3 field field-written">
                <h3>QuoteState</h3>
                <span className="field__border"/>
            </div>
            <div className="col-12 col-md-3 field field-written">
                <ul className="field__list">
                    {inputs.map((input, index) => {

                        return (
                            <li key={input.id} className="field__item QuoteState" data-id={input.itemId}>
                                <input type="text"
                                       className="field__input"
                                       {...register(`quoteState.${index}.value`, input)}/>
                                <div className="field__item__delete">
                                    <span onClick={onDelete(index)}>
                                        <PolIcon icon="trash"/>
                                    </span>
                                    <div className="quote-state">
                                        <CustomReactSelect name={`quoteState.${index}.extra.quoteStateStatus`}
                                                           value={input.extra.quoteStateStatus ?? []}
                                                           options={quoteStateStatusOptions}
                                                           isMulti
                                                           onChange={onExtraChange(index, 'quoteStateStatus')}/>

                                        {showReasons && (
                                            <CustomReactSelect name={`quoteState.${index}.extra.quoteStateReasons`}
                                                               value={input.extra.quoteStateReasons ?? []}
                                                               options={getQuoteStateReasonsOptions(index)}
                                                               isMulti
                                                               selectParams={{closeMenuOnSelect: true}}
                                                               onChange={onExtraChange(index, 'quoteStateReasons')}/>
                                        )}
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <p className="field__list__add" onClick={onAdd}>
                    {t('ajouter_champ')}
                </p>
            </div>
            <div className="row align-items-center justify-content-end">
                <Link to="/administration/gestion_listes" className="col-12 col-md-auto form__back">
                    <PolIcon icon="chevron-circle-left" className="form__back__icon"/>
                    {t('retour')}
                </Link>
                <div className="col-12 col-md-auto form__submit">
                    <button className="btn btn-primary form__submit__btn" onClick={handleSubmit}>
                        {t('enregistrer')}
                    </button>
                </div>
            </div>
        </form>
    )
}

import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import {routesBase} from "v4/data/appRoutes";
import {TAB_OPEN_TASKS} from "v4/data/tabNames";
import {substitutionData, substitutionColumns} from '../../datas/Content';
import {
    noData,
} from "./_partials/config";
import {DataService, getDefaultColumns} from "../../api/datas/datas";
import {setModifyInfo} from "../../actions/content";
import {formatForView} from "../../utils/viewTypeFormatter";
import {
    getViewOrdersOfEntityAndType,
} from "../../utils/viewOrdersManager";
import {Header} from "../Page/_list/header";
import {removeError, setError} from "../../actions/datas";
import {withPolTranslation} from "../../v4/hooks/usePolTranslation";

class SearchDatatables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newColumns: substitutionColumns,
            newDatas: substitutionData,
            number: {start: 'X', end: 'X', count: 'X'},
            numberItem: 10,
            noResult: false,
            redirect: null,
            isDataLoaded: false,
            hiddenRowsKey: [],
        }
    }

    /**
     * Prepare data for intepretation
     *
     * @param currentDatas
     * @param type
     */
    manageDatas = (currentDatas, type = 'datas') => {
        const {info, t, setError, removeError } = this.props;
        this.updateDefaultSorting(info.name, info.type);

        if (currentDatas.columns && currentDatas[type]) {
            this.initData(currentDatas[type], currentDatas.columns);
        } else {
            const data = currentDatas[type];
            const visibleInfos = getViewOrdersOfEntityAndType(info.order_entity, info.order_type);

            if (!visibleInfos[0] || !visibleInfos[0].fields) {
                return;
            }

            const sortVisibleInfos = visibleInfos[0].fields.sort((a, b) => a.order - b.order);
            let columns = getDefaultColumns(data, t, false, visibleInfos, setError, removeError);

            if (!sortVisibleInfos.length) {
                columns.map((item, columKey) => {
                    delete columns[columKey].hidden;
                    return '';
                });
            } else {
                columns.forEach((item) => item.order = 99);
                sortVisibleInfos.map((item, index) => {
                    columns.map((column, columKey) => {
                        if (item.key === column.dataField) {
                            columns[columKey].order = index;
                            columns[columKey].valueType = item.valueType;
                            delete columns[columKey].hidden;
                        }

                        return '';
                    });
                    return '';
                });
            }

            columns.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
            this.initData(data, columns);
        }
    };

    /**
     * update the default sorting list
     *
     * @param name
     * @param type
     */
    updateDefaultSorting(name, type) {
        if ("datas" !== type) {
            this.updateDefaultSorting(type, "datas");
            return;
        }

        let columnForDefaultSort;

        switch (name) {
            case "prospects":
                columnForDefaultSort = [{
                    dataField: 'fullname',
                    order: 'asc'
                }]
                break;
            case "contacts":
                columnForDefaultSort = [{
                    dataField: 'lastname',
                    order: 'asc'
                }]
                break;
            case "actions":
                columnForDefaultSort = [{
                    dataField: 'beginAt',
                    order: 'desc'
                }]
                break;
            case "historique":
                columnForDefaultSort = [{
                    dataField: 'beginAt',
                    order: 'desc'
                }]
                break;
            case "devis":
                columnForDefaultSort = [{
                    dataField: 'issuedAt',
                    order: 'desc'
                }]
                break;
            case "devis_offres":
                columnForDefaultSort = [{
                    dataField: 'issuedAt',
                    order: 'desc'
                }]
                break;
            case "affaires":
                columnForDefaultSort = [{
                    dataField: 'expectedSignedAt',
                    order: 'desc'
                }]
                break;
            default:
                columnForDefaultSort = [{
                    dataField: 'fullname',
                    order: 'asc'
                }]
                break;
        }
        this.setState({columnForDefaultSort: columnForDefaultSort});
    }

    /**
     * Build table
     * @param datas
     * @param columns
     */
    initData = (datas = [], columns = []) => {
        const localNumberItem = localStorage.getItem('itemPerPage');
        let {numberItem} = this.state;
        if (localNumberItem) {
            numberItem = localNumberItem;
        }

        if (columns.length) {
            columns.unshift({
                text: 'fullname',
                dataField: 'fullname',
                sort: true
            });

            columns.map((item, key) => {
                item.text = this.props.t(item.text);
                return '';
            });

        } else {
            columns.push({
                text: '',
                dataField: 'noResult',
            });
        }

        datas.forEach((item, index) => {
            columns.filter((col) => !col.hidden).forEach((col) => {
                if (col.dataField && col.valueType) {
                    const keys = col.dataField.split('.');
                    let value = keys.length ? undefined : datas[index][col.dataField];
                    let lastArray = [];

                    keys.forEach((key) => {
                        if (typeof value === 'object') {
                            lastArray = value
                            value = value[key]
                            return;
                        }
                        value = datas[index][key]
                        lastArray = datas[index][key]
                    })

                    if (keys.length > 1) {
                        lastArray[keys[keys.length - 1]] = formatForView(value, col.valueType)
                    } else {
                        datas[index][col.dataField] = formatForView(value, col.valueType);
                    }
                }
            })
        })

        const end = numberItem > datas.length ? datas.length : numberItem;

        const managedByColumn = columns.find((col) => col.dataField === 'managedBy');
        if (managedByColumn) {
            managedByColumn.dataField = 'managedByRealName';
        }

        const contactColumn = columns.find((col) => col.dataField === 'contactId');
        if (contactColumn) {
            contactColumn.dataField = 'contactIdRealName';
        }

        this.setState({
            newColumns: columns,
            newDatas: datas,
            number: {start: '1', end: end, count: datas.length},
            isDataLoaded: true
        });
    };

    componentDidMount() {
        const {datasSearch, info: {type}} = this.props;
        this.manageDatas({datas: datasSearch}, type);
    }

    /**
     * Click on table row
     *
     * @param e
     * @param row
     * @param rowIndex
     * @returns {boolean}
     */
    clickElement = (e, row, rowIndex) => {
        const {setModifyInfo, info} = this.props;

        setModifyInfo(false);

        let id = (row['@type'] !== "Prospect" && row.prospect) ? row.prospect.id : row.id;
        if (row.prospectId) {
            id = row.prospectId;
        }
        if (row['prospect.id']) {
            id = row['prospect.id'];
        }

        let url = `${routesBase.baseProspect}/${id}/${TAB_OPEN_TASKS}`;

        if (info.name !== 'prospects') {
            url = `${routesBase.baseProspect}/${id}${info.route}/${row.id}`;
        }

        this.setState({
            redirect: null,
        }, () => {
            this.setState({
                redirect: <Redirect to={{
                    pathname: url,
                }}/>
            })
        });
    };

    render() {
        const {title, loader, t, className = '', info: {name}} = this.props;
        const {columnForDefaultSort} = this.state;
        let {newColumns, newDatas, number, noResult, redirect, isDataLoaded, hiddenRowsKey} = this.state;
        let classContainer = noResult ? 'no-datas' : '';
        let translateResult;

        switch (name) {
            case "prospects":
                number.count > 1 ? (translateResult = 'resultats_elements_prospects') : (translateResult = 'resultats_elements_prospect')
                break;
            case "contacts":
                number.count > 1 ? (translateResult = 'resultats_elements_contacts') : (translateResult = 'resultats_elements_contact')
                break;
            case "actions":
                number.count > 1 ? (translateResult = 'resultats_elements_actions') : (translateResult = 'resultats_elements_action')
                break;
            case "devis":
                translateResult = 'resultats_elements_devis'
                break;
            case "mailType":
                number.count > 1 ? (translateResult = 'resultats_elements_mails') : (translateResult = 'resultats_elements_mail')
                break;
            default :
                number.count > 1 ? (translateResult = 'resultats_elements') : (translateResult = 'resultats_element')
        }

        return (
            <>
                {redirect}
                <Header title={t(title)}
                        className={className}
                        number={t(translateResult, number)}
                        loading={loader.table}
                />

                {isDataLoaded &&
                <ToolkitProvider
                    keyField='id'
                    data={newDatas}
                    columns={newColumns}
                >
                    {props => (
                        <div className={`table-container ${classContainer}`}>

                            <div className={'table-content-search  table-content__margin'}>
                                <BootstrapTable
                                    {...props.baseProps}
                                    classes={`datatable ${className} datatable-loader`}
                                    noDataIndication={() => noData(t)}
                                    rowEvents={{
                                        onClick: (e, row, rowIndex) => this.clickElement(e, row, rowIndex)
                                    }}
                                    hiddenRows={hiddenRowsKey}
                                    filter={filterFactory()}
                                    ref={n => this.node = n}
                                    defaultSorted={columnForDefaultSort}
                                    keyField="id"
                                />
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
                }
            </>
        )
    };
}

const mapStateToProps = ({datas, datatable, content: {loader}, error, form, prospects}) => ({
    datas,
    loader,
    error,
    form,
    prospects,
    datatable
});

const mapDispatchToProps = dispatch => {
    return {
        getDatas: (info) => dispatch(DataService(info)),
        setModifyInfo: (modifyInfo) => dispatch(setModifyInfo(modifyInfo)),
        setError: error => dispatch(setError(error)),
        removeError: () => dispatch(removeError()),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(SearchDatatables))

import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "../../PolIcon/policon";
import React from "react";
import {isCompany} from "../../../api/datas/datas";
import {Link} from "react-router-dom";
import {baseProspect, baseProspectContent} from "../../../routes";

const fullname = ['fullname'];
const fields = ['type', 'contact.email', 'managedByRealName'];
const address = ['mainAddress.address', 'mainAddress.postalCode', 'mainAddress.city'];
const tel = ['contact.phone'];

export const Informations = ({prospect, setDisplay}) => {
    const {t} = usePolTranslation();
    let picto = isCompany(prospect) ? 'building' : 'store-alt';

    const displayFullname = fullname.map(field => {
        return field.split('.').reduce((o, i) => o[i], prospect);
    });

    const displayData = fields.map(field => {
        return field.split('.').reduce((o, i) => o[i], prospect);
    });

    const displayAddress = address.map(field => {
        return field.split('.').reduce((o, i) => o[i], prospect);
    });

    const displayTel = tel.map(field => {
        return field.split('.').reduce((o, i) => o[i], prospect);
    });

    return (
        <>
            <div className={'info__header'}>
                <PolIcon icon={picto} className={'info__header__icon'}/>
                <p className={'info__header__name'}>{displayFullname}</p>

                <div className={'close'} onClick={() => setDisplay()}>
                    <PolIcon icon={'close'} className={'close__icon'}/>
                </div>
            </div>
            <div className={'info__content'}>
                {displayData.map((item, key) => {
                    if (!item) {
                        return null
                    }

                    return (
                        <p className={'data'} key={key}>
                            <span className={'data__label'}>{t(fields[key].replace('.', '_'))}:</span>
                            <span className={'data__value'}>{item}</span>
                        </p>
                    )
                })}
            </div>
            <div className={'info__address'}>
                <p className={'info__address__line'}>{displayAddress[0]}</p>
                <p className={'info__address__line'}>{displayAddress[1]} {displayAddress[2]}</p>
            </div>
            <div className={'info__footer'}>
                <PolIcon icon={'phone'} className={'info__footer__icon'}/>
                <p className={'info__footer__number'}>{displayTel}</p>
                <Link to={`${baseProspect}/${prospect.id}/${baseProspectContent}`} className={'link'} target={'_blank'}>
                    <PolIcon icon={'arrow-right'} className={'link__icon'}/>
                </Link>
            </div>
        </>
    )
};

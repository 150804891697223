import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import DashboardTasksSummaryCell
    from "v4/pages/front/Dashboard/components/DashboardTasksSummary/components/DashboardTasksSummaryCell/DashboardTasksSummaryCell";
import useDashboardTasksSummary
    from "v4/pages/front/Dashboard/components/DashboardTasksSummary/hooks/useDashboardTasksSummary";

export default function DashboardTasksSummary() {
    const {t} = usePolTranslation();
    const {data, tableInfos, getPreSearch} = useDashboardTasksSummary();

    return (
        <div className="dashboard-tasks-summary">
            <p>{t('tasks_results')}</p>
            <table>
                <thead>
                <tr>
                    <th/>
                    <th>{t('completed_tasks')}</th>
                    <th>{t('tasks_late')}</th>
                    <th colSpan={3}>{t('tasks_to_do')}</th>
                </tr>
                <tr>
                    <th/>
                    <th>{t('since_january')}</th>
                    <th/>
                    {tableInfos?.remaining_months && (
                        tableInfos.remaining_months.map(month => (
                            <th key={month}>{month}</th>
                        ))
                    )}
                </tr>
                </thead>
                <tbody>
                {data && data.map((row, dataIndex) => (
                    <tr key={dataIndex}>
                        <DashboardTasksSummaryCell value={row.fullname}/>
                        <DashboardTasksSummaryCell value={row.closed}
                                                   preSearch={getPreSearch('since_january', dataIndex)}/>
                        <DashboardTasksSummaryCell value={row.late}
                                                   preSearch={getPreSearch('late', dataIndex)}/>
                        {tableInfos?.remaining_months && (
                            tableInfos.remaining_months.map((month, index) => (
                                <DashboardTasksSummaryCell key={month}
                                                           value={row[`m_plus_${index}`]}
                                                           preSearch={getPreSearch(`m_plus_${index}`, dataIndex)}/>
                            ))
                        )}
                    </tr>
                ))}
                </tbody>
                <tfoot>
                <tr className="table-stat__body__total">
                    <td>{t('total_euro')}</td>
                    <DashboardTasksSummaryCell value={tableInfos.totalByColumn.total_closed}
                                               preSearch={getPreSearch('total_since_january')}/>
                    <DashboardTasksSummaryCell value={tableInfos.totalByColumn.total_late}
                                               preSearch={getPreSearch('total_late')}/>
                    {tableInfos?.remaining_months && (
                        tableInfos.remaining_months.map((month, index) => (
                            <DashboardTasksSummaryCell key={month}
                                                       value={tableInfos.totalByColumn[`total_m_plus_${index}`]}
                                                       preSearch={getPreSearch(`total_m_plus_${index}`)}/>
                        ))
                    )}
                </tr>
                </tfoot>
            </table>
        </div>
    );
}

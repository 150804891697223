import {useEffect, useMemo, useState} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";
import {PARAMS_REQUESTS, PARAMS_SUBMIT} from "v4/data/apiRoutes";

export default function useQuoteState(rawValuesFromAdmin) {
    const {t} = usePolTranslation();
    const [{isFinished, isError}, fetchSaveQuoteState] = useFetch();

    const [quoteStateReasonsOptions, setQuoteStateReasonsOptions] = useState(null);
    const customerId = useMemo(() => rawValuesFromAdmin[0]?.customerId, [rawValuesFromAdmin]);

    useEffect(() => {
        fetch(generateUrl(PARAMS_REQUESTS, {customerId}))
            .then(response => response.json())
            .then(({data}) => {
                const quoteReason = data?.['hydra:member']?.find(item => item.name === 'QuoteReason')?.params;
                if (quoteReason) {
                    setQuoteStateReasonsOptions(quoteReason.map(item => ({value: item.id, label: item.name})));
                }
            });
    }, [customerId]);

    const quoteStateStatusOptions = useMemo(() => ([
        {value: "isAbandoned", label: t('is_abandoned')},
        {value: "inProgress", label: t('in_progress')},
        {value: "isWon", label: t('is_won')},
        {value: "isLost", label: t('is_lost')},
        {value: "isDiffered", label: t('is_differed')},
        {value: "isNotCountForStats", label: t('is_not_count_for_stats')},
        {value: "isUnSelectable", label: t('is_un_selectable')},
    ]), [t]);

    const inputs = useMemo(() => {
        return rawValuesFromAdmin.map(item => {
            return {
                itemId: item.id,
                value: item.name,
                extra: {
                    quoteStateStatus: getQuoteStateStatus(item),
                    quoteStateReasons: item.reasons?.map(reason => reason.id) ?? []
                }
            }
        });
    }, [rawValuesFromAdmin]);

    const methods = useForm({
        defaultValues: {
            quoteState: inputs
        }
    });
    const {fields, append, update, remove} = useFieldArray({
        control: methods.control,
        name: "quoteState"
    });

    const handleSubmit = (data) => {
        fetchSaveQuoteState({
            url: generateUrl(PARAMS_SUBMIT, {customerId}),
            config: {
                method: "PUT",
                body: JSON.stringify({
                    "id": "QuoteState",
                    "name": "QuoteState",
                    "code": "QuoteState",
                    "isActive": true,
                    "customerId": customerId,
                    "params": data.quoteState.map((item, index) => ({
                        "id": item.itemId,
                        "name": item.value,
                        "order": index + 1,
                        "@type": "QuoteState",
                        "isWon": item.extra.quoteStateStatus.includes('isWon'),
                        "isLost": item.extra.quoteStateStatus.includes('isLost'),
                        "isAbandoned": item.extra.quoteStateStatus.includes('isAbandoned'),
                        "isDiffered": item.extra.quoteStateStatus.includes('isDiffered'),
                        "isNotCountForStats": item.extra.quoteStateStatus.includes('isNotCountForStats'),
                        "isUnSelectable": item.extra.quoteStateStatus.includes('isUnSelectable'),
                        "inProgress": item.extra.quoteStateStatus.includes('inProgress'),
                        "customerId": customerId,
                        "reasons": item.extra.quoteStateReasons.map(reason => ({
                            "id": reason,
                            "name": quoteStateReasonsOptions.find(option => option.value === reason).label,
                            "@type": "QuoteReason",
                            "customerId": customerId,
                        }))
                    }))
                })
            }
        })
    }

    const {replace} = useHistory();
    const {addErrorNotification} = useNotification();
    useEffect(() => {
        if (isFinished && !isError) {
            replace('/administration/gestion_listes_admin');
        } else if (isFinished && isError) {
            addErrorNotification(t('fetch_post_error'));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished, isError]);

    const onDelete = (index) => () => remove(index);

    const onAdd = () => append({value: '', extra: {quoteStateStatus: [], quoteStateReasons: []}});

    const onExtraChange = (index, name) => (value) => {
        update(index, {...fields[index], extra: {...fields[index].extra, [name]: value}});
    }

    const getQuoteStateReasonsOptions = (index) => {
        // each option in quoteStateReasonsOptions can be selected only once

        const selectedOptions = methods.getValues(`quoteState`).map(item => item.extra.quoteStateReasons).flat();
        const options = quoteStateReasonsOptions.filter(item => !selectedOptions.includes(item.value));
        // Add the current value to the options
        const currentValue = methods.watch(`quoteState.${index}.extra.quoteStateReasons`);
        if (currentValue.length) {
            options.push(...currentValue.map(item => quoteStateReasonsOptions.find(option => option.value === item)));
        }

        return options ?? [];
    }

    return [fields, methods.handleSubmit(handleSubmit), methods.register, onDelete, onAdd, onExtraChange, quoteStateStatusOptions, !!quoteStateReasonsOptions, getQuoteStateReasonsOptions];
}

function getQuoteStateStatus(item) {
    return Object.keys(item).reduce((acc, curr) => {
        if ((curr.startsWith('is') || curr.startsWith('in')) && item[curr]) {
            acc.push(curr);
        }

        return acc;
    }, []);
}

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from "react-router-dom";
import PolTable from "v4/components/ui/PolTable/PolTable";
import SearchContextPagination from "v4/components/utils/SearchContextPagination/SearchContextPagination";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {PROSPECT_CONTACTS_SEARCH} from "v4/data/apiRoutes";
import {CONTACT} from "v4/data/entities";
import useCheckList from "v4/hooks/useCheckList";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import useToggle from "v4/hooks/useToggle";
import ProspectContactsMoveContact
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectContactsMoveContact/ProspectContactsMoveContact";
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import ProspectContactsTabActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsTab/components/ProspectContactsTabActions/ProspectContactsTabActions";
import ProspectContactsTabRowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsTab/components/ProspectContactsTabRowActions/ProspectContactsTabRowActions";
import {generateUrl} from "v4/services/api.service";
import {debounce} from "v4/utils";
import {useWatch} from "react-hook-form";

export default function ProspectContactsTabListing({prospectId}) {
    const {t} = usePolTranslation();
    const {
        initPagesWithTotalItems,
        currentPage,
        pages,
        itemsPerPage,
        sortOrder,
        sortField,
        setSortField,
        setSortOrder,
        formMethods
    } = useSearchContext();

    const [{data, isLoading}, fetchItems] = useFetch();
    const [
        checkedRows,
        handleCheck,
        {
            containsInChecklist,
            unCheckAll,
        }] = useCheckList([]);

    const checkFunctions = {
        checkedRows,
        containsInChecklist,
        handleCheck,
    };

    const [refetch, toggleRefetch] = useToggle();

    const {location: {state}, replace} = useHistory();
    useEffect(() => {
        if (state?.hasToRefresh) {
            const {hasToRefresh, ...newState} = state;
            replace({state: newState})
            toggleRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.hasToRefresh]);


    const contacts = useMemo(() => data?.['hydra:member'] || [], [data]);
    const status = useMemo(() => {
        return {
            isLoading: isLoading,
        }
    }, [isLoading]);

    const values = useWatch({control: formMethods?.control});
    useEffect(() => {
        const isValuesEmpty = Object.values(values).every(value => !value || value === '');
        debounce(() => fetchItems({
            url: generateUrl(PROSPECT_CONTACTS_SEARCH, {
                prospectId,
                page: currentPage,
                sortField,
                sortOrder,
                itemsPerPage,
            }),
            config: {
                method: 'POST',
                body: JSON.stringify(values)
            },
        }),isValuesEmpty ? 0 : 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, prospectId, sortOrder, sortField, currentPage, itemsPerPage, refetch]);

    useEffect(() => {
        if (data && !pages) {
            initPagesWithTotalItems(data?.['hydra:totalItems']);
        }

        return () => initPagesWithTotalItems(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pages]);

    const onHeaderClick = useCallback(({currentTarget}) => {
        const {key, sort} = currentTarget.dataset;
        let currentSortOrder = sortOrder;
        if (sortField !== key) {
            setSortField(key);
            currentSortOrder = 'asc';
            [...currentTarget.closest('tr').querySelectorAll('[data-sort]')].forEach(child => {
                if (child !== currentTarget) {
                    delete child.dataset.sort;
                    child.classList.remove('active');
                }
            })
        }
        if (!sort) {
            setSortOrder(currentSortOrder);
            currentTarget.dataset.sort = currentSortOrder;
            currentTarget.classList.add('active');
        } else {
            const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
            setSortOrder(newSortOrder);
            currentTarget.dataset.sort = newSortOrder;
            currentTarget.classList.add('active');
        }
    }, [sortField, sortOrder, setSortField, setSortOrder]);


    const hasEmail = ({key, value}) => key === 'email' && value;
    const selectedContactsWithMail = useMemo(() => contacts.results?.filter(
        ({fields, metadata: {id}}) => checkedRows.includes(id) && fields.find(hasEmail)
    ), [contacts, checkedRows]);

    useEffect(() => {
        if (state?.unselect) {
            unCheckAll();
            const {unselect, ...newState} = state;
            replace({state: newState})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [replace, state]);

    const [contactIdToMove, setContactIdToMove] = useState(null);
    const onMoveContactClick = (contactId) => () => setContactIdToMove(contactId);
    const onMoveContactClose = () => setContactIdToMove(null);


    return (
        <>
            <div className="prospect-tab__listing__wrapper">
                <ProspectHeadingTabPartial title="contacts" actions={(props) => <ProspectContactsTabActions
                    selectedContactsWithMail={selectedContactsWithMail} {...props}/>}/>
                <div className="prospect-tab__listing">
                    <p className="prospect-tab__listing__results">
                        &zwj;
                        {data
                            && t('result_header_datatable_homepage', {
                                start: data?.['hydra:totalItems'] > 0 ? (currentPage - 1) * itemsPerPage + 1 : 0,
                                end: (currentPage - 1) * itemsPerPage + contacts?.results.length,
                                count: data?.['hydra:totalItems'],
                                many: data?.['hydra:totalItems'] > 1 ? 's' : '',
                                all: data?.['hydra:totalItems'] > 1 ? 's' : '',
                            })
                        }
                    </p>
                    <PolTable data={contacts}
                              status={status}
                              onHeaderClick={onHeaderClick}
                              rowActions={<ProspectContactsTabRowActions onMoveContactClick={onMoveContactClick}/>}
                              checkFunctions={checkFunctions}
                              toggleRefetch={toggleRefetch}
                              entity={CONTACT}/>
                    <SearchContextPagination/>
                </div>
            </div>

            <ToggleComponent isShowing={contactIdToMove} shouldRemoveFromDom={true} className="move-contact-popup">
                <ProspectContactsMoveContact contactIdToMove={contactIdToMove} closePopup={onMoveContactClose}/>
            </ToggleComponent>
        </>
    )
}

/* Gestion des modules */
export const MODULE_ITEM = 'module_item';
export const MODULE_LIST = "module_list";
export const MODULE_UPDATE = "module_update";
export const MODULE_MICROSOFT_GRAPH_URL = "module_microsoft_graph_url";
export const MODULE_MICROSOFT_GRAPH_POST_URL = "module_microsoft_graph_post_url";
export const MODULE_CONFIGURATION_ITEM = "module_configuration_item";
export const MODULE_CONFIGURATION_LIST = "module_configuration_list";
export const MODULE_CONFIGURATION_ADD = "module_configuration_add";
export const MODULE_CONFIGURATION_FORM = "module_configuration_form";
export const MODULE_CONFIGURATION_EDIT_FORM = "module_configuration_edit_form";

/* Gestion des onglets */
export const TABS = "tab_list";
export const TAB_ITEM = "tab_item";
export const TAB_FORM = "tab_form";
export const TAB_FORM_EDIT = "tab_form_edit";

/* Gestion des sections */
export const SECTIONS = "section_list";
export const SECTION_ITEM = "section_item";
export const SECTION_FORM = "section_form";
export const SECTION_FORM_EDIT = "section_form_edit";

/* Gestion des articles */
export const PRODUCT_SEARCH = "product_search";
export const PRODUCT_FORM = "product_form";
export const PRODUCT_LIST = "product_list";
export const PRODUCT_ITEM_FORM = "product_item_form";
export const PRODUCT_ITEM = "product_item";
export const PRODUCT_ADD = "product_add";

/* Gestion des attributs de produits */
export const PRODUCT_ATTRIBUTE_FORM = 'product_attribute_form';
export const PRODUCT_ATTRIBUTE_LIST = 'product_attribute_list';
export const PRODUCT_ATTRIBUTE_EDIT = 'product_attribute_edit';
export const PRODUCT_ATTRIBUTE_ITEM = 'product_attribute_item';
export const PRODUCT_ATTRIBUTE_ADD = 'product_attribute_add';

/* Gestion des configurations de produits */
export const PRODUCT_CONFIGURATION_FORM = 'product_configuration_form';
export const PRODUCT_CONFIGURATION_LIST = 'product_configuration_list';
export const PRODUCT_CONFIGURATION_EDIT = 'product_configuration_edit';
export const PRODUCT_CONFIGURATION_ITEM = 'product_configuration_item';
export const PRODUCT_CONFIGURATION_ADD = 'product_configuration_add';

/* Gestion des custom actions */
export const CUSTOM_ACTIONS_SHOW_LIST = "custom_action_list";
export const CUSTOM_ACTION_ITEM = "custom_action_item";
export const CUSTOM_ACTION_ADD = "custom_custom_action_item_add";
export const CUSTOM_ACTION_FORM = "custom_action_form";
export const CUSTOM_ACTION_EDIT_FORM = "custom_action_form_edit";

/* Gestion des prospects */
export const PROSPECT_SEARCH = "prospect_search";
export const PROSPECT_ITEM_CUSTOMER_CODE = "prospect_item_customer_code";
export const PROSPECT_ITEM = "prospect_item";
export const PROSPECT_ITEM_FORM = "prospect_item_form";
export const PROSPECT_INDIVIDUAL_FORM = "prospect_individual_form";
export const PROSPECT_BUSINESS_FORM = "prospect_business_form";
export const PROSPECT_INDIVIDUAL_ADD = "prospect_individual_add";
export const PROSPECT_BUSINESS_ADD = "prospect_business_add";
export const PROSPECT_DELETE = "prospect_delete";
/* Gestion des onglets de prospect */
export const PROSPECT_CONTACTS = "prospect_contacts"
export const PROSPECT_CONTACTS_SEARCH = "prospect_contacts_search"
export const PROSPECT_CONTACTS_SEARCH_FORM = "prospect_contacts_search_form"
export const PROSPECT_TASKS = "prospect_tasks"
export const PROSPECT_TASKS_RESUME = "prospect_tasks_resume"
export const PROSPECT_QUOTES = "prospect_quotes"
export const PROSPECT_NAMES = "prospect_names"

/* Gestion des contacts */
export const CONTACT_ITEM_FORM = "contact_item_form";
export const CONTACT_FORM = "contact_form";
export const CONTACT_ITEM = "contact_item";
export const CONTACT_ADD = "contact_add";
export const CONTACT_DELETE = "contact_delete";
export const CONTACT_SEARCH = "contact_search";
export const CONTACT_MOVE = "contact_move";

/* Gestion des actions */
export const TASK_ITEM_FORM = "task_item_form";
export const TASK_FORM = "task_form";
export const TASK_ITEM = "task_item";
export const TASK_ADD = "task_add";
export const TASK_SEARCH = "task_search";
export const TASK_DELETE = "task_delete";
export const TASK_RENEW = "task_renew";
export const TASK_CLOSE = "task_close";
export const TASK_MOVE_FORM = "task_move_form";
export const TASK_MOVE = "task_move";

/* VATs */
export const VAT_LIST = "vat_list";

/* Gestion des devis */
export const QUOTE_ITEM = "quote_item";
export const QUOTE_ITEM_FORM = "quote_item_form";
export const QUOTE_DUPLICATE_FORM = "quote_duplicate_form";
export const QUOTE_FORM = "quote_form";
export const QUOTE_ADD = "quote_add";
export const QUOTE_GENERATE = "quote_generate";
export const QUOTE_PRODUCTS_FILES = "quote_products_files";
export const QUOTE_DELETE = "quote_delete";
export const QUOTE_SEARCH = "quote_search";
export const QUOTE_REGENERATE = "quote_regenerate";
export const QUOTE_SPLIT = "quote_split";
export const QUOTE_TASKS = "quote_tasks";
export const QUOTE_REASON_FORM = "quote_reason_form";
export const QUOTE_REASON = "quote_reason";
export const QUOTE_FILIATION = "quote_filiation";
export const QUOTE_EXPECTED_SIGNED_AT_FORM = "quote_expected_signed_at_form";
export const QUOTE_EXPECTED_SIGNED_AT = "quote_expected_signed_at";

/* Gestion des categories */
export const CATEGORY_LIST = "category_list"
export const CATEGORY_SAVE = "category_save"

/* Gestion des templates */
export const TEMPLATE_LIST = "template_list"
export const TEMPLATE_ITEM = "template_item"
export const TEMPLATE_NEW = "template_new"

/* Gestion des listes */
export const PARAMS_REQUESTS = "get_params_requests"
export const PARAMS_SUBMIT = "params_submit"

/* Gestion des fichiers */
export const FILES_VIEW = "files_view"

/* Gestion des images */
export const IMAGE = "image"

/* Gestion des mailings */
export const MAILING_SEND = "mailing_send"
export const UNIT_MAIL = "unit_mail"
export const MAIL_TYPE_SEARCH = "mail_type_search"
export const MAIL_TYPE_LIST = "mail_type_list"
export const MAIL_TYPE_ITEM = "mail_type_item"
export const MAIL_TYPE_FORM = "mail_type_form"
export const MAIL_TYPE_ADD = "mail_type_add"
export const MAIL_TYPE_COPY = "mail_type_copy"
export const MAIL_TYPE_ITEM_FORM = "mail_type_item_form"

/* Gestion des view orders */
export const VIEW_ORDERS_FIELDS = "view_orders_fields"
export const SAVE_VIEW_ORDER = "save_view_order"

/* Gestion des campagnes */
export const CAMPAIGN_GENERATE = "campaign_generate"
export const CAMPAIGN_HAS_MAIL = "campaign_has_mail"

/* Gestion des recherches sauvegardées */
export const SAVED_SEARCH = "saved_search"
export const SAVED_SEARCH_DELETE = "saved_search_delete"

/* Gestion des requêtes sauvegardées */
export const SAVED_REQUEST_SHOW_LIST = 'saved_request_show_list';
export const SAVED_REQUEST_ITEM = "saved_request_item";
export const SAVED_REQUEST = "saved_request_add";
export const SAVED_REQUEST_FORM = "saved_request_form";
export const SAVED_REQUEST_EDIT_FORM = "saved_request_edit_form";

/* Gestion de Liveo */
export const LIVEO_CALLS = "liveo_calls"

/* Gestion des dernières consultations */
export const LAST_VISITS = "last_visits"
export const LAST_VISIT = "last_visit"

/* Gestion des statistiques des mailings */
export const STATS_MAILING = "stats_mailing"

/* Gestion module microsoft graph */
export const OUTLOOK_SUBSCRIPTION_CONFIGURATION_SHOW_LIST = 'outlook_subscription_configuration_show_list';
export const OUTLOOK_SUBSCRIPTION_CONFIGURATION_ITEM = "outlook_subscription_configuration_item"
export const OUTLOOK_SUBSCRIPTION_CONFIGURATION_ADD = "outlook_subscription_configuration_add"
export const OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_SHOW_LIST = 'outlook_subscription_configuration_user_show_list';
export const OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_ITEM = "outlook_subscription_configuration_user_item"
export const OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_EDIT = "outlook_subscription_configuration_user_edit"
export const OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_ADD = "outlook_subscription_configuration_user_add"

/* Gestion des customer event services */
export const CUSTOMER_EVENT_SERVICE_FORM = 'customer_event_service_form';
export const CUSTOMER_EVENT_SERVICE_LIST = 'customer_event_service_list';
export const CUSTOMER_EVENT_SERVICE_EDIT = 'customer_event_service_edit';
export const CUSTOMER_EVENT_SERVICE_ITEM = 'customer_event_service_item';
export const CUSTOMER_EVENT_SERVICE_ADD = 'customer_event_service_add';

/* Gestion des custom event triggers */
export const CUSTOM_EVENT_TRIGGER_FORM = 'custom_event_trigger_form';
export const CUSTOM_EVENT_TRIGGER_LIST = 'custom_event_trigger_list';
export const CUSTOM_EVENT_TRIGGER_EDIT = 'custom_event_trigger_edit';
export const CUSTOM_EVENT_TRIGGER_ITEM = 'custom_event_trigger_item';
export const CUSTOM_EVENT_TRIGGER_ADD = 'custom_event_trigger_add';

/* Gestion listings */
export const LISTING_FORM = 'listing_form';
export const LISTING_LIST = 'listing_list';
export const LISTING_EDIT = 'listing_edit';
export const LISTING_ITEM = 'listing_item';
export const LISTING_ADD = 'listing_add';

/* Partial form */
export const PARTIAL_FORM = "partial_form";

/* Gestion utilisateur */
export const USER_SHOW_LIST = 'user_show_list';

/* Maintenance */
export const MAINTENANCE = 'maintenance';

/* Stats */
export const STATS_QUOTE_CA = 'stats_quote_ca';
export const STATS_QUOTE_SUMMARY = 'stats_quote_summary';
export const STATS_TASK_SUMMARY = 'stats_task_summary';

/* Dashboard */
export const DASHBOARD_TASK_TODAY = 'dashboard_task_today';
export const DASHBOARD_TASK_IN_PROGRESS = 'dashboard_task_in_progress';
export const DASHBOARD_QUOTE_IN_PROGRESS = 'dashboard_quote_in_progress';
export const DASHBOARD_QUOTE_SIGNED = 'dashboard_quote_signed';

/* Intranet */
export const INTRANET_EXPORT = 'intranet_export';

/* Exports */
export const EXPORT_ENTITY = 'export_entity';
export const EXPORT_LIST = 'exports_list';
export const EXPORT_DOWNLOAD = 'exports_download';
export const EXPORT_PRECONFIGURED = 'export_preconfigured';
export const EXPORT_PRECONFIGURED_START = 'export_preconfigured_start';

/* Changement de status automatisé */
export const AUTOMATED_STATUS_LIST = 'automated_status_list';
export const AUTOMATED_STATUS_FORM = 'automated_status_form';
export const AUTOMATED_STATUS_FORM_EDIT = 'automated_status_edit';
export const AUTOMATED_STATUS_ITEM = 'automated_status_item';
export const AUTOMATED_STATUS_NEW = 'automated_status_new';

/* SIRET */
export const SIRET ='siret';

/* DEBUG BAR */
export const DEBUG_BAR = 'debug_bar';

export default {
    /* CAMPAIGN */
    [CAMPAIGN_GENERATE]: "/campaign",
    [CAMPAIGN_HAS_MAIL]: "/campaign/check_contact",

    /* CATEGORIES */
    [CATEGORY_LIST]: "/categories",
    [CATEGORY_SAVE]: "/categories/service/save",

    /* FILES */
    [FILES_VIEW]: "/files/view/$id$",

    /* IMAGES */
    [IMAGE]: "/logo/$id$",

    /* CUSTOM ACTIONS */
    [CUSTOM_ACTIONS_SHOW_LIST]: "/V4/custom_actions",
    [CUSTOM_ACTION_ITEM]: "/V4/custom_actions/$id$",
    [CUSTOM_ACTION_ADD]: "/V4/custom_actions",
    [CUSTOM_ACTION_FORM]: "/V4/custom_actions/form",
    [CUSTOM_ACTION_EDIT_FORM]: "/V4/custom_actions/$id$/form",

    /* LAST VISITS */
    [LAST_VISITS]: "/last_visits",
    [LAST_VISIT]: "/last_visit",

    /* LIVEO */
    [LIVEO_CALLS]: "/liveo_calls",

    /* MAILING */
    [MAIL_TYPE_SEARCH]: "/v4/mail_types/search/form",
    [MAIL_TYPE_LIST]: "/v4/mail_types",
    [MAIL_TYPE_ITEM]: "/v4/mail_types/$id$",
    [MAIL_TYPE_FORM]: "/v4/mail_types/form",
    [MAIL_TYPE_ADD]: "/v4/mail_types",
    [MAIL_TYPE_COPY]: "/v4/mail_types/$id$/copy",
    [MAIL_TYPE_ITEM_FORM]: "/v4/mail_types/$id$/form",
    [MAILING_SEND]: "/send_emailing",
    [UNIT_MAIL]: "/prospect/$id$/task/form-mailing",

    /* MODULES */
    [MODULE_ITEM]: "/modules/$id$",
    [MODULE_LIST]: "/modules",
    [MODULE_UPDATE]: "/customers/$id$/update_module_access",
    [MODULE_MICROSOFT_GRAPH_URL]: "/modules/$id$/configuration",
    [MODULE_MICROSOFT_GRAPH_POST_URL]: "/modules/$id$/customer_configuration",

    /* MODULE_CONFIGURATION */
    [MODULE_CONFIGURATION_ITEM]: "/module_configurations/$id$",
    [MODULE_CONFIGURATION_LIST]: "/module_configurations",
    [MODULE_CONFIGURATION_ADD]: "/module_configurations",
    [MODULE_CONFIGURATION_FORM]: "/module_configurations/form",
    [MODULE_CONFIGURATION_EDIT_FORM]: "/module_configurations/$id$/form",

    /* PARAMS */
    [PARAMS_REQUESTS]: "/get_params_requests",
    [PARAMS_SUBMIT]: "/params",

    /* PRODUCTS */
    [PRODUCT_SEARCH]: "/products/search/form",
    [PRODUCT_FORM]: "/products/form",
    [PRODUCT_LIST]: "/products",
    [PRODUCT_ITEM_FORM]: "/products/$id$/form",
    [PRODUCT_ITEM]: "/products/$id$",
    [PRODUCT_ADD]: "/products",

    /* PRODUCT ATTRIBUTES */
    [PRODUCT_ATTRIBUTE_FORM]: "/V4/product_attributes/form",
    [PRODUCT_ATTRIBUTE_LIST]: "/V4/product_attributes",
    [PRODUCT_ATTRIBUTE_EDIT]: "/V4/product_attributes/$id$/form",
    [PRODUCT_ATTRIBUTE_ITEM]: "/V4/product_attributes/$id$",
    [PRODUCT_ATTRIBUTE_ADD]: "/V4/product_attributes",

    /* PRODUCT CONFIGURATIONS */
    [PRODUCT_CONFIGURATION_FORM]: "/V4/product_configurations/form",
    [PRODUCT_CONFIGURATION_LIST]: "/V4/product_configurations",
    [PRODUCT_CONFIGURATION_EDIT]: "/V4/product_configurations/$id$/form",
    [PRODUCT_CONFIGURATION_ITEM]: "/V4/product_configurations/$id$",
    [PRODUCT_CONFIGURATION_ADD]: "/V4/product_configurations",

    /* PROSPECTS */
    [PROSPECT_SEARCH]: "/V4/prospects/search/form",
    [PROSPECT_INDIVIDUAL_FORM]: "/V4/prospects/individual/form",
    [PROSPECT_ITEM_FORM]: "/V4/prospects/$id$/form",
    [PROSPECT_BUSINESS_FORM]: "/V4/prospects/business/form",
    [PROSPECT_ITEM]: "/V4/prospects/$id$",
    [PROSPECT_ITEM_CUSTOMER_CODE]: "/V4/prospects/customer-code/$customerCode$",
    [PROSPECT_INDIVIDUAL_ADD]: "/V4/prospects/individual",
    [PROSPECT_BUSINESS_ADD]: "/V4/prospects/business",
    [PROSPECT_DELETE]: "/V4/prospects/$id$",
    // Onglets de prospect
    [PROSPECT_CONTACTS]: "/V4/prospects/$prospectId$/contacts",
    [PROSPECT_CONTACTS_SEARCH]: "/V4/prospects/$prospectId$/contacts/search",
    [PROSPECT_CONTACTS_SEARCH_FORM]: "/V4/prospects/contacts/search/form",
    [PROSPECT_TASKS]: "/V4/prospects/$prospectId$/tasks",
    [PROSPECT_TASKS_RESUME]: "/V4/prospects/$prospectId$/tasks_resume",
    [PROSPECT_QUOTES]: "/V4/prospects/$prospectId$/quotes",
    [PROSPECT_NAMES]: "/V4/prospects/get_names",

    /* CONTACTS */
    [CONTACT_ITEM_FORM]: "/V4/contacts/$id$/form",
    [CONTACT_ADD]: "/V4/contacts",
    [CONTACT_ITEM]: "/V4/contacts/$id$",
    [CONTACT_FORM]: "/V4/contacts/form",
    [CONTACT_DELETE]: "/V4/contacts/$id$",
    [CONTACT_SEARCH]: "/V4/contacts/search/form",
    [CONTACT_MOVE]: "/V4/contacts/$id$/form_move_contact",

    /* TASKS */
    [TASK_ITEM_FORM]: "/V4/tasks/$id$/form",
    [TASK_ADD]: "/V4/tasks",
    [TASK_ITEM]: "/V4/tasks/$id$",
    [TASK_FORM]: "/V4/tasks/form",
    [TASK_DELETE]: "/V4/tasks/$id$",
    [TASK_SEARCH]: "/V4/tasks/search/form",
    [TASK_RENEW]: "/v4/tasks/renew/$id$",
    [TASK_CLOSE]: "/v4/tasks/close/$id$",
    [TASK_MOVE_FORM]: "/v4/move_task/form",
    [TASK_MOVE]: "/v4/move_task/$id$",

    /* QUOTES */
    [QUOTE_ITEM_FORM]: "/V4/quotes/$id$/form",
    [QUOTE_ADD]: "/V4/quotes",
    [QUOTE_ITEM]: "/V4/quotes/$id$",
    [QUOTE_FORM]: "/V4/quotes/form",
    [QUOTE_DUPLICATE_FORM]: "/V4/quotes/$duplicateFrom$/form/duplicate",
    [QUOTE_GENERATE]: "/quote/$id$/generatepdf",
    [QUOTE_PRODUCTS_FILES]: "/quote/$id$/productsfiles",
    [QUOTE_DELETE]: "/V4/quotes/$id$",
    [QUOTE_SEARCH]: "/V4/quotes/search/form",
    [QUOTE_REGENERATE]: '/V4/quotes/$id$/regenerate',
    [QUOTE_SPLIT]: '/quotes/$id$/split-children',
    [QUOTE_TASKS]: '/v4/quotes/$id$/related-tasks',
    [QUOTE_REASON_FORM]: '/V4/quotes/$id$/reason/form',
    [QUOTE_REASON]: '/V4/quotes/$id$/reason',
    [QUOTE_FILIATION]: '/v4/quote/$id$/filiation',
    [QUOTE_EXPECTED_SIGNED_AT_FORM]: '/V4/quotes/expected-signed-at/form',
    [QUOTE_EXPECTED_SIGNED_AT]: '/V4/quotes/$id$/expected-signed-at',

    /* SAVED SEARCH */
    [SAVED_SEARCH]: "/saved_user_searches",
    [SAVED_SEARCH_DELETE]: "/saved_user_searches/$id$",

    /* STATS MAILING */
    [STATS_MAILING]: "/task_mailing_stats",

    /* TABS */
    [TABS]: "/V4/tabs",
    [TAB_ITEM]: "/V4/tabs/$id$",
    [TAB_FORM]: "/V4/tabs/form",
    [TAB_FORM_EDIT]: "/V4/tabs/$id$/form",

    /* SECTIONS */
    [SECTIONS]: "/V4/sections",
    [SECTION_ITEM]: "/V4/sections/$id$",
    [SECTION_FORM]: "/V4/sections/form",
    [SECTION_FORM_EDIT]: "/V4/sections/$id$/form",

    /* TEMPLATES */
    [TEMPLATE_LIST]: "/templates",
    [TEMPLATE_ITEM]: "/templates/$id$",
    [TEMPLATE_NEW]: "/templates",

    /* VAT */
    [VAT_LIST]: "/vats",

    /* VIEW ORDERS */
    [VIEW_ORDERS_FIELDS]: "/view_orders/$entity$/fields",
    [SAVE_VIEW_ORDER]: "/view_orders_info",


        /* MICROSOFT GRAPH */
    [OUTLOOK_SUBSCRIPTION_CONFIGURATION_SHOW_LIST]: "/V4/outlook_subscription_configurations",
    [OUTLOOK_SUBSCRIPTION_CONFIGURATION_ITEM]: "/V4/outlook_subscription_configurations/$id$",
    [OUTLOOK_SUBSCRIPTION_CONFIGURATION_ADD]: "/V4/outlook_subscription_configurations",
    [OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_SHOW_LIST]: "/V4/outlook_subscription_configurations/$subscriptionConfigId$/outlook_subscription_configuration_users",
    [OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_ITEM]: "/V4/outlook_subscription_configuration_users/$id$",
    [OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_EDIT]: "/V4/outlook_subscription_configurations/$subscriptionConfigId$/outlook_subscription_configuration_users/$id$",
    [OUTLOOK_SUBSCRIPTION_CONFIGURATION_USER_ADD]: "/V4/outlook_subscription_configurations/$subscriptionConfigId$/outlook_subscription_configuration_users",

    /* SAVED REQUEST */
    [SAVED_REQUEST_SHOW_LIST]: "/V4/saved_requests",
    [SAVED_REQUEST_ITEM]: "/V4/saved_requests/$id$",
    [SAVED_REQUEST]: "/V4/saved_requests",
    [SAVED_REQUEST_FORM]: "/V4/saved_requests/form",
    [SAVED_REQUEST_EDIT_FORM]: "/V4/saved_requests/$id$/form",

    /* CUSTOMER EVENT SERVICES */
    [CUSTOMER_EVENT_SERVICE_FORM]: "/V4/customer_event_services/form",
    [CUSTOMER_EVENT_SERVICE_LIST]: "/V4/customer_event_services",
    [CUSTOMER_EVENT_SERVICE_EDIT]: "/V4/customer_event_services/$id$/form",
    [CUSTOMER_EVENT_SERVICE_ITEM]: "/V4/customer_event_services/$id$",
    [CUSTOMER_EVENT_SERVICE_ADD]: "/V4/customer_event_services",

    /* CUSTOM EVENT TRIGGERS */
    [CUSTOM_EVENT_TRIGGER_FORM]: "/V4/custom_event_triggers/form",
    [CUSTOM_EVENT_TRIGGER_LIST]: "/V4/custom_event_triggers",
    [CUSTOM_EVENT_TRIGGER_EDIT]: "/V4/custom_event_triggers/$id$/form",
    [CUSTOM_EVENT_TRIGGER_ITEM]: "/V4/custom_event_triggers/$id$",
    [CUSTOM_EVENT_TRIGGER_ADD]: "/V4/custom_event_triggers",

    /* LISTING */
    [LISTING_FORM]: "/v4/listings/form",
    [LISTING_LIST]: "/v4/listings",
    [LISTING_EDIT]: "/v4/listings/$id$/form",
    [LISTING_ITEM]: "/v4/listings/$id$",
    [LISTING_ADD]: "/v4/listings",

    [USER_SHOW_LIST]: "/V4/user_infos",

    /* PARTIAL FORM */
    [PARTIAL_FORM]: "/v4/partial-form/$formType$?formOptions[productId]=$productId$",

    /* Maintenance */
    [MAINTENANCE]: "/monitoring",

    /* Stats */
    [STATS_QUOTE_CA]: "/v4/stats/quote/ca",
    [STATS_QUOTE_SUMMARY]: "/v4/stats/quote/summary",
    [STATS_TASK_SUMMARY]: "/v4/stats/task/summary",

    /* Dashboard */
    [DASHBOARD_TASK_TODAY]: "/v4/dashboard/task/today",
    [DASHBOARD_TASK_IN_PROGRESS]: "/v4/dashboard/task/in-progress",
    [DASHBOARD_QUOTE_IN_PROGRESS]: "/v4/dashboard/quote/in-progress",
    [DASHBOARD_QUOTE_SIGNED]: "/v4/dashboard/quote/signed",

    /* Intranet */
    [INTRANET_EXPORT]: "/specific/export_intranet/$id$",

    /* Exports */
    [EXPORT_ENTITY]: "/v4/export/$entity$",
    [EXPORT_LIST]: "/v4/export/list",
    [EXPORT_DOWNLOAD]: "/v4/export/download/$entity$/$id$",
    [EXPORT_PRECONFIGURED]: "/v4/preconfigured-export",
    [EXPORT_PRECONFIGURED_START]: "/v4/preconfigured-export/$id$/export",

    /* AUTOMATED STATUS */
    [AUTOMATED_STATUS_LIST]: "/v4/automatic_quote_status_update_configuration/listing",
    [AUTOMATED_STATUS_NEW]: "/v4/automatic_quote_status_update_configuration",
    [AUTOMATED_STATUS_FORM]: "/v4/automatic_quote_status_update_configuration/form",
    [AUTOMATED_STATUS_FORM_EDIT]: "/v4/automatic_quote_status_update_configuration/form/$id$",
    [AUTOMATED_STATUS_ITEM]: "/v4/automatic_quote_status_update_configuration/$id$",

    /* SIRET */
    [SIRET]: "/v4/company/lookup-by-siret/$siret$",

    /* DEBUG BAR */
    [DEBUG_BAR]: "/dev/profiler/$token$",
};

import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect, useMemo, useState} from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Loader from "v4/components/ui/Loader/Loader";
import {VIEW_ORDERS_FIELDS} from "v4/data/apiRoutes";
import "v4/features/front/listingViewOrdersManagement/components/ViewOrdersList/ViewOrdersList.scss";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";
import {useLocation} from "react-router-dom";
import {getCustomTabBySlug} from "v4/services/tab.service";

export default function ViewOrdersList({
                                           onSubmit,
                                           onCancel,
                                           entity,
                                           isBlocked = false,
                                           submitLabel = 'validate',
                                           isCheckBoxMode = false
                                       }) {
    const {t} = usePolTranslation();
    const [{data, isLoading: isViewOrdersLoading}, fetchViewOrders] = useFetch();
    const [search, setSearch] = useState('');
    const [columns, setColumns] = useState([]);

    const location = useLocation();
    /* Si il ne trouve pas de slug, il n'est pas dans un onglet, c'est donc la clé default qui est appliquée */
    const tabSlug = useMemo(() => {
        const splitedPathname = location.pathname.split('/');
        const indexSlug = splitedPathname.some(item => item === "onglets") && (splitedPathname.findIndex((item) => item === "onglets") + 1);

        return splitedPathname?.[indexSlug];
    }, [location]);

    useEffect(() => {
        if (!isViewOrdersLoading) {
            fetchViewOrders({
                url: generateUrl(VIEW_ORDERS_FIELDS, {entity: entity, tabId: getCustomTabBySlug(tabSlug)?.id ?? null}),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data?.['hydra:member']) setColumns(data['hydra:member'][0]);
    }, [data]);

    const handleSearch = ({target: {value}}) => setSearch(value);

    const isItemInSearch = (item) => {
        if (!search) return true;
        return t(item.label).toLowerCase().includes(search.toLowerCase());
    }

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const copiedItems = [...columns];
        const {destination, source} = result;
        const [movedItem] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, movedItem);
        setColumns(copiedItems);
    }

    const toggleDisplayedColumns = (item) => () => {
        const copiedItems = [...columns];
        const itemIndex = copiedItems.findIndex(({key}) => key === item.key);
        copiedItems[itemIndex].metadata.isDisplayed = !copiedItems[itemIndex].metadata.isDisplayed;
        setColumns(copiedItems);
    };

    const toggleSelectAll = ({currentTarget: {checked}}) => {
        const copiedItems = [...columns];
        copiedItems.forEach(item => {
            item.metadata.isDisplayed = checked;
        });
        setColumns(copiedItems);
    }

    const handleValidate = () => {
        const displayedColumns = columns.filter(({metadata}) => metadata.isDisplayed).map(({key}) => key);
        onSubmit(displayedColumns, tabSlug);
    }

    return (
        <>
            {isCheckBoxMode && (!isViewOrdersLoading && columns) && (
                <div className="view-orders-list__select-all">
                    <input id="select-all" type="checkbox" onClick={toggleSelectAll}/>
                    <label htmlFor="select-all">{t('select_all')}</label>
                </div>
            )}
            <input type="search" className="view-orders-list__search" placeholder={t('search')} value={search} onChange={handleSearch}/>
            <div className="view-orders-list">
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="view-orders-droppable">
                        {(provided) => (
                            <ul ref={provided.innerRef}
                                className="view-orders-list__scrollable" {...provided.droppableProps}>
                                <li className={`view-orders-list__loading ${isViewOrdersLoading ? '' : 'fade-out'}`}>
                                    <Loader message="Chargement..."/>
                                </li>
                                {(!isViewOrdersLoading && columns) &&
                                    columns?.map((item, index) => {
                                        if (!isItemInSearch(item)) return null;

                                        return (
                                            <Draggable draggableId={item.key} key={item.key} index={index}>
                                                {(provided) => (
                                                    <li ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={`view-orders-list__item${item.metadata.isDisplayed ? ' active' : ''}`}
                                                        onClick={toggleDisplayedColumns(item)}>
                                                        <div className="view-orders-list__item__drag">
                                                            {index + 1} - {t(item.label)}
                                                            <span className="view-orders-list__item__check"></span>
                                                        </div>
                                                    </li>
                                                )}
                                            </Draggable>
                                        )
                                    })
                                }
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <div className="view-orders-list__btns">
                {(!isViewOrdersLoading && columns) &&
                    <>
                        <button className="view-orders-list__btn-cancel"
                                disabled={isBlocked}
                                onClick={onCancel}>{t('close')}</button>
                        <button className="view-orders-list__btn-validate"
                                disabled={isBlocked || columns.filter(({metadata}) => metadata.isDisplayed).length === 0}
                                onClick={handleValidate}>
                            {t(submitLabel)}
                            {isBlocked && <PolIcon icon="circle-notch"/>}
                        </button>
                    </>
                }
            </div>
        </>
    )
};

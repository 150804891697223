import React, {Component} from 'react'
import {connect} from 'react-redux'
import List from "../../components/Page/List"
import {getApiUrl} from "../../routes";
import {GROUPS} from "../Administration/pages/orders";

class Devis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                name: 'devis',
                id: 'table',
                type: 'datas',
                url: getApiUrl('devis', 'list'),
                loader: 'table-devis',
                route: 'devis_offres',
                routeBusiness: 'affaires',
                order_type: 'list',
                order_entity: 'Quote',
                subType: props.subType ?? GROUPS['quote'],
                prospectIds: props.location && props.location.state ? props.location.state.prospectIds : [],
                contactIds: props.location && props.location.state ? props.location.state.contactIds : [],
                productIds: props.location && props.location.state ? props.location.state.selectedArticles : [],
                preSearch: props.location && props.location.state && props.location.state.preSearch ? props.location.state.preSearch : null,
                additionalSearch: props.customTabSearch
            }
        }
    }

    render() {
        const {info} = this.state;
        const {title, customTabSearch} = this.props;

        return (
            <List
                info={info}
                className={'devis'}
                title={title ?? 'liste_devis_offres'}
                customTabSearch={customTabSearch}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    state
});

export default connect(
    mapStateToProps,
    null
)(React.memo(Devis));

import UserService from "api/user/user";
import moment from "moment";
import {useCallback, useEffect, useMemo} from 'react';
import {STATS_QUOTE_SUMMARY} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

export default function useDashboardQuotesSummary() {
    const [{data}, fetchQuotesSummary] = useFetch();

    const results = useMemo(() => {
        if (!data) return null;
        // put the "- Utilisateur désactivé" at the end of the list
        const resultsCopy = window.structuredClone(data.results);
        const disabledUser = resultsCopy.find(result => result.fullname === '- Utilisateur désactivé');
        if (disabledUser) {
            resultsCopy.splice(resultsCopy.indexOf(disabledUser), 1);
            resultsCopy.push(disabledUser);
        }

        return resultsCopy;
    }, [data]);

    useEffect(() => {
        fetchQuotesSummary({
            url: generateUrl(STATS_QUOTE_SUMMARY),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const totalMonthKeys = Object.keys(results?.[0] ?? {}).filter(key => key.startsWith('total_month_'));
    const totalNbMonthKeys = Object.keys(results?.[0] ?? {}).filter(key => key.startsWith('nb_month_'));
    const totalWeightedMonthKeys = Object.keys(results?.[0] ?? {}).filter(key => key.startsWith('total_weighted_month_'));

    const [
        total_won,
        nb_won,
        total_abandonned,
        nb_abandonned,
        total_lost,
        nb_lost,
        total_late,
        nb_late,
        total_weighted_abandonned,
        total_weighted_late,
        total_weighted_lost,
        total_weighted_won,
        ...remainingMonths
    ] = results?.reduce((acc, row) => {
        const {
            total_won,
            nb_won,
            total_abandonned,
            nb_abandonned,
            total_lost,
            nb_lost,
            total_late,
            nb_late,
            total_weighted_abandonned,
            total_weighted_late,
            total_weighted_lost,
            total_weighted_won,
        } = row;

        return [
            parseFloat(acc[0]) + parseFloat((total_won ?? '0')),
            parseFloat(acc[1]) + parseFloat((nb_won ?? '0')),
            parseFloat(acc[2]) + parseFloat((total_abandonned ?? '0')),
            parseFloat(acc[3]) + parseFloat((nb_abandonned ?? '0')),
            parseFloat(acc[4]) + parseFloat((total_lost ?? '0')),
            parseFloat(acc[5]) + parseFloat((nb_lost ?? '0')),
            parseFloat(acc[6]) + parseFloat((total_late ?? '0')),
            parseFloat(acc[7]) + parseFloat((nb_late ?? '0')),
            parseFloat(acc[8]) + parseFloat((total_weighted_abandonned ?? '0')),
            parseFloat(acc[9]) + parseFloat((total_weighted_late ?? '0')),
            parseFloat(acc[10]) + parseFloat((total_weighted_lost ?? '0')),
            parseFloat(acc[11]) + parseFloat((total_weighted_won ?? '0')),
            ...totalMonthKeys.map((key, index) => {
                return parseFloat(acc[12 + index]) + parseFloat((row[key] ?? '0'));
            }),
            ...totalNbMonthKeys.map((key, index) => {
                return parseFloat(acc[12 + totalMonthKeys.length + index]) + parseFloat((row[key] ?? '0'));
            }),
            ...totalWeightedMonthKeys.map((key, index) => {
                return parseFloat(acc[12 + totalMonthKeys.length + totalNbMonthKeys.length + index]) + parseFloat((row[key] ?? '0'));
            }),
        ];
    }, Array(totalMonthKeys.length + totalNbMonthKeys.length + totalWeightedMonthKeys.length + 12).fill(0)) ?? [];

    const tableInfos = {
        remaining_months: Object.keys(results?.[0] ?? {})
            .filter(key => key.startsWith('nb_month_'))
            .map(key => {
                const date = new Date();
                const monthNumber = key.replace('nb_month_', '');
                date.setMonth(monthNumber - 1, 1);
                const monthString = new Intl.DateTimeFormat(UserService.getLocale(), {month: 'long'}).format(date);

                return {
                    monthNumber: monthNumber,
                    label: monthString.slice(0, 1).toUpperCase() + monthString.slice(1)
                };
            }),
        totalByColumn: {
            total_won,
            nb_won,
            total_abandonned,
            nb_abandonned,
            total_lost,
            nb_lost,
            total_late,
            nb_late,
            total_weighted_abandonned,
            total_weighted_late,
            total_weighted_lost,
            total_weighted_won,
            ...remainingMonths.reduce((acc, value, index) => {
                const key = index < totalMonthKeys.length ? totalMonthKeys[index] : index < totalMonthKeys.length + totalNbMonthKeys.length ? totalNbMonthKeys[index - totalMonthKeys.length] : totalWeightedMonthKeys[index - totalMonthKeys.length - totalNbMonthKeys.length];

                return {
                    ...acc,
                    [key]: value
                };
            }, {}),
        }
    };

    const getPreSearch = useCallback((preSearchType, index) => {
        const {metadata} = data ?? {};
        const preSearch = getQuotePreSearch(preSearchType, metadata?.quoteStates);

        if (results?.[index]?.managedBy) {
            preSearch['managedBy'] = results[index].managedBy.split(',') ?? [];
        } else if (preSearchType.startsWith('total_')) {
            // all managedBy
            preSearch['managedBy'] = results?.map(({managedBy}) => managedBy.split(',')).flat();
        }

        return preSearch;
    }, [data, results]);

    return {data: results, tableInfos, getPreSearch};
}

function getQuotePreSearch(preSearchType, quotesStatus = {}) {
    const preSearch = {};

    if (preSearchType.includes('won')) {
        preSearch['status'] = quotesStatus.isWon ?? [];
        preSearch['expectedSignedAt'] = {
            equal: ">",
            begin: moment().startOf('year').format('DD-MM-YYYY'),
            end: ""
        };
    } else if (preSearchType.includes('abandoned')) {
        preSearch['status'] = quotesStatus.isAbandoned ?? [];
        preSearch['issuedAt'] = {
            equal: ">",
            begin: moment().startOf('year').format('DD-MM-YYYY'),
            end: ""
        };
    } else if (preSearchType.includes('lost')) {
        preSearch['status'] = quotesStatus.isLost ?? [];
        preSearch['issuedAt'] = {
            equal: ">",
            begin: moment().startOf('year').format('DD-MM-YYYY'),
            end: ""
        };
    } else if (preSearchType.includes('late')) {
        preSearch['status'] = quotesStatus.inProgress ?? [];
        preSearch['expectedSignedAt'] = {
            equal: "<",
            begin: "",
            end: moment().format('DD-MM-YYYY')
        };
    } else if (preSearchType.includes('month')) {
        const monthNumber = preSearchType.split('_').pop();
        preSearch['status'] = quotesStatus.inProgress ?? [];
        preSearch['expectedSignedAt'] = {
            equal: "><",
            begin: moment().month(monthNumber - 1).startOf('month').format('DD-MM-YYYY'),
            end: moment().month(monthNumber - 1).endOf('month').format('DD-MM-YYYY')
        };
    }

    return preSearch;
}

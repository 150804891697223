import React from "react";

export default function SearchContextPaginationItem({label, isActive, isDisabled, onClick, page}) {

    return (
        <li className={`listing-pagination__item${isDisabled ? ' disabled' : ''}${isActive ? ' active' : ''}`}>
            <button onClick={() => !isDisabled && onClick(page)}>{label}</button>
        </li>
    )
}

import React, {useState, useEffect} from 'react';
import Select from "react-select";
import {selectStyle} from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {connect} from 'react-redux';
import {PolIcon} from "../PolIcon/policon";
import {DeleteService} from "../../api/datas/delete";
import {getApiUrl} from "../../routes";
import UserService from "../../api/user/user";
import {PostSearchService, PostService} from "../../api/datas/post";
import {setPopup} from "../../actions/content";
import PopupInfoConfirm from "../Popup/_popup_info_confirm";

const SavedSearch = (props) => {
    // UseState
    let [searchValue, setSearchValue] = useState('');
    const [userSavedSearch, setUserSavedSearch] = useState(UserService.getSavedSearchs());
    const [selectValue, setSelectValue] = useState(null);
    const [savedSearchName, setSavedSearchName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Props & Variable
    const {t, setSavedSearch, info: {name}, deleteService, searchContent, postService, resetSavedSearch, setPopup} = props
    let savedSearchs = [];
    let searchType;

    //Functions

    switch (name) {
        case 'contacts' :
            searchType = 'contact'
            break;
        case 'prospects' :
            searchType = 'prospect'
            break;
        case 'actions' :
            searchType = 'task'
            break;
        case 'devis' :
            searchType = 'quote'
            break;
        default:
            searchType = 'none'
    }

    /**
     * Récupération des recherches savegardées par l'utilisateur
     */
    if (userSavedSearch) {
        userSavedSearch.forEach(search => {
            if (search.searchType === searchType) {
                savedSearchs.push({
                    value: search.searchContent,
                    label: search.name,
                    id: search.id
                })
            }
        })
    }

    useEffect(() => {
        if (Object.keys(searchContent).length) {
            Object.keys(searchContent).forEach((key) => {
                if (searchContent[key] === "") {
                    delete searchContent[key];
                }
            })
        }
    }, [searchContent])

    return (
        <div className={'savedSearch'}>
            <h2>{t('filters_button_text')}</h2>
            <h2>{`${t('saved_searchs')} :`}</h2>
            <div>
                <p>{t('my_saved_searchs')}</p>
                <div>
                    <Select options={savedSearchs}
                            placeholder={t('select_saved_search')}
                            isMulti={false}
                            value={selectValue}
                            onChange={(value) => {
                                resetSavedSearch()
                                setSelectValue(value)
                                setSearchValue({
                                    searchContent: JSON.parse(value.value),
                                    searchID: value.id,
                                    searchLabel: value.label
                                })
                                setSavedSearch(JSON.parse(value.value))
                            }}
                            styles={selectStyle}
                    />
                    <button className={'savedSearch_button--first'} disabled={!searchValue} onClick={() => {
                        setPopup({
                            isOpen: true,
                            content: (
                                <PopupInfoConfirm
                                    message={t('saved_search_delete').replace('{searchName}', searchValue.searchLabel)}
                                    onAccept={() => {
                                        resetSavedSearch()
                                        deleteService({
                                            deleteUrl: getApiUrl('savedSearch', 'delete').replace('{id}', searchValue.searchID)
                                        })
                                        setUserSavedSearch(UserService.removeSavedSearch(searchValue.searchID));
                                        setSelectValue(null)
                                        setPopup({})
                                    }}
                                    onDecline={() => {
                                        setPopup({})
                                        }
                                    }
                                />
                            )
                        });
                    }}>
                        <PolIcon icon={'bin'}/>
                    </button>
                </div>
                <p>{t('name_a_search')}</p>
                <div>
                    <input className={'savedSearch_input'} value={savedSearchName} type={'text'} onChange={(e) => {
                        setSavedSearchName(e.currentTarget.value)
                    }}/>
                    <button className={'savedSearch_button--second'} disabled={!savedSearchName || (Object.keys(searchContent).length < 1)} onClick={() => {
                        const submittedData = {
                            searchType: searchType,
                            searchContent: JSON.stringify(searchContent),
                            name: savedSearchName
                        }
                        setIsLoading(true)
                        postService({
                            url: getApiUrl('savedSearch', 'search'),
                            submittedData: submittedData,
                            type: 'uploadSavedSearch',
                            callback: () => {
                                setUserSavedSearch(UserService.getSavedSearchs())
                                setIsLoading(false)
                                setSavedSearchName('');
                            }
                        })
                    }}>
                        <PolIcon icon={isLoading ? 'circle-notch' : 'validation'}/>
                        {t('save_search')}
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        deleteService: (info) => dispatch(DeleteService(info)),
        postService: (info, t) => dispatch(PostService(info, t)),
        postSearchService: (info) => dispatch(PostSearchService(info)),
        setPopup: (info) => dispatch(setPopup(info))
    }
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(React.memo(SavedSearch)));

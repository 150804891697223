import React, {Fragment} from 'react';
import {useWatch} from "react-hook-form";

import 'v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsPricesInfos/QuotesProductsPricesInfos.scss';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {financial} from "v4/utils";

export default function QuotesProductsPricesInfos({isShow, vatChoices}) {
    const {t} = usePolTranslation();
    const quoteLines = useWatch({name: 'quoteLines', defaultValue: null});
    const pricesInfos = quoteLines && quoteLines.filter(quoteLine => !quoteLine.isOptional).reduce((prev, {
        unitPriceExclVat,
        quantity,
        discountValue,
        discountType,
        vatRate: vat
    }) => {
        if (isNaN(parseFloat(unitPriceExclVat)) || isNaN(parseFloat(quantity))) {
            return prev;
        }

        /* Remises */
        let lineDiscount = 0;
        if (String(discountValue).length > 0) {
            if (discountType === 'amount') {
                lineDiscount = parseFloat(discountValue);
            }
            if (discountType === 'percentage') {
                lineDiscount = parseFloat((parseFloat(unitPriceExclVat)  * (parseFloat(discountValue) / 100)).toFixed(2)) * parseFloat(quantity);
            }
        }
        prev.total_discount += lineDiscount;

        /* Total HT */
        const totalHT = parseFloat((parseFloat(unitPriceExclVat) * parseFloat(quantity)).toFixed(2));
        prev.total_HT += totalHT;

        /* TVAs */
        const vatInPercentage = vatChoices.find(({value}) => value === vat)?.label;

        if(vatInPercentage) {
            prev.vat = {
                ...prev.vat,
                [vatInPercentage]: (prev.vat?.[vatInPercentage] ?? 0) + parseFloat(((totalHT - lineDiscount) * (parseFloat(vatInPercentage) / 100)).toFixed(2))
            };
        }

        return prev;
    }, {total_HT: 0, total_discount: 0, vat: {}});

    return (
        <div className={`quotes-products__prices-infos__grid${isShow ? ' is-show' : ''}`}>
            <div className="quotes-products__prices-infos">
                <p>{t('total_without_discount')}</p>
                <p>{financial(pricesInfos?.total_HT)}</p>
                <p className="small">{t('discount').toLowerCase()}</p>
                <p className="alt-text">
                    {pricesInfos?.total_discount > 0 && '-'}
                    {pricesInfos?.total_discount < 0 && '+'}
                    {financial(Math.abs(pricesInfos?.total_discount))}
                </p>
                <p>{t('total_with_discount')}</p>
                <p>{financial(pricesInfos?.total_HT - pricesInfos?.total_discount)}</p>
                {
                    pricesInfos?.vat && Object.entries(pricesInfos.vat).map(([vat, value]) => {
                            if (vat !== 'undefined') {
                                return (
                                    <Fragment key={vat}>
                                        <p className="small">{`${t('vat_amount')} ${vat}`}</p>
                                        <p className="alt-text">{financial(value)}</p>
                                    </Fragment>
                                )
                            }
                            return null;
                        }
                    )
                }
                <hr/>
                <p>{t('total_with_taxes')}</p>
                <p>
                    {
                        financial(
                            (parseFloat(pricesInfos?.total_HT) - parseFloat(pricesInfos?.total_discount))
                            + (pricesInfos?.vat ? Object.values(pricesInfos.vat).reduce((prev, v) => parseFloat(prev) + parseFloat(v), 0) : 0)
                        )
                    }
                </p>
            </div>
        </div>
    )
}

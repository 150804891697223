import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {QUOTE_ITEM_FORM} from "v4/data/apiRoutes";
import {TAB_QUOTES} from "v4/data/tabNames";
import ActionsButtonsPdf from "v4/features/front/generatePdf/components/ActionsButtonsPdf/ActionsButtonsPdf";
import ShowEntityLayout from "v4/layouts/ShowEntityLayout/ShowEntityLayout";
import ProspectQuotesSplitButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesSplitButton/ProspectQuotesSplitButton";
import ProspectQuotesTabs
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesTabs/ProspectQuotesTabs";
import QuoteTasksButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/QuoteTasksButton";
import {filterFilesForMail} from "v4/utils";
import ProspectTabBackButton from "v4/components/utils/ProspectTabBackButton/ProspectTabBackButton";
import ProspectTabEditButton from "v4/components/utils/ProspectTabEditButton/ProspectTabEditButton";
import ProspectTabReOfferButton from "v4/components/utils/ProspectTabReOfferButton/ProspectTabReOfferButton";
import ProspectTabPdfversionningButton
    from "v4/components/utils/ProspectTabPdfversionningButton/ProspectTabPdfversionningButton";
import ProspectTabShowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTabShowActions/ProspectTabShowActions";

export default function ProspectQuoteTabShow({prospectId, showId, onGetData, quoteData, setQuoteData}) {
    const {replace} = useHistory();
    const {quote, setQuote} = useProspectPageContext();

    useEffect(() => {
        return () => setQuote(null);
    }, [setQuote]);

    const whenDeleteFile = () => {
        // Removing the current from quote_invoices metadata to hide the download button
        const quoteInvoices = JSON.parse(quoteData.metadata.quote_invoices);
        quoteInvoices.current = null;
        quoteData.metadata.quote_invoices = JSON.stringify(quoteInvoices);
        onGetData && onGetData(quoteData);
        replace({state: {hasToRefresh: true}})
    }

    return (
        <>
            <div className="prospect-tab__prospect__header">
                {quote?.metadata?.isSplit && <ProspectQuotesTabs currentTab={TAB_QUOTES} showId={showId}/>}

                <ProspectTabBackButton/>
                <ProspectTabEditButton showId={showId}/>
                {!quote?.metadata?.isSplit && <ProspectQuotesSplitButton/>}
                <QuoteTasksButton quote={quote}/>
                <ProspectTabReOfferButton showId={showId}/>
                <ProspectTabPdfversionningButton quote={quote}/>
                <ActionsButtonsPdf prospectId={prospectId}
                                   quoteId={showId}
                                   quoteName={quote?.name}
                                   contactIds={quote?.contactId}
                                   currentFile={filterFilesForMail(quote?.customerFiles)}
                                   updateHandler={onPDFGenerate(setQuoteData, replace)}/>
                {quote?.metadata?.showActions && (
                    <ProspectTabShowActions showId={showId}
                                            prospectId={prospectId}
                                            popupTitle={quote?.name}
                                            showActions={quote.metadata.showActions}/>
                )}
            </div>
            <ShowEntityLayout entityId={showId}
                              onGetData={onGetData}
                              overrideData={quoteData}
                              whenDeleteFile={whenDeleteFile}
                              entityRouteName={QUOTE_ITEM_FORM}/>
        </>
    )
}

// This function is exported because it can be reused in other 'Show' components but no extracted
// in another file because the other components can implement their own logic if needed. It's like a shortcut
export function onPDFGenerate(setQuoteData, replace) {
    return (file) => {
        setQuoteData((quoteData) => {
            const _data = window.structuredClone(quoteData);
            const groupIdOfCustomerFiles = Object.keys(_data.results ?? {}).find((groupId) => _data.results[groupId].properties.some(({key}) => key === 'customerFiles'));

            // add the file in the quote_invoices metadata to show the download button
            if (file.quotePdf) {
                const metadata = _data.metadata ?? {};
                const quoteInvoices = JSON.parse(metadata.quote_invoices ?? '{}');
                if (quoteInvoices.current) {
                    quoteInvoices.archives = quoteInvoices.archives ?? [];
                    quoteInvoices.current.versionNumber = quoteInvoices.archives.length + 1;
                    quoteInvoices.archives.push(quoteInvoices.current);
                }
                quoteInvoices.current = file;
                quoteInvoices.archives = quoteInvoices.archives ?? [];
                // remove previous file from quoteInvoices.archives
                if (file.previousFileId) {
                    const previousFile = quoteInvoices.archives.find(({id}) => id === file.previousFileId);
                    if (previousFile) {
                        quoteInvoices.archives.splice(quoteInvoices.archives.indexOf(previousFile), 1);
                    }
                }

                metadata.quote_invoices = JSON.stringify(quoteInvoices);
                _data.metadata = metadata;
            }

            if (!groupIdOfCustomerFiles) return _data;
            const customerFiles = _data.results[groupIdOfCustomerFiles].properties.find(({key}) => key === 'customerFiles');
            if (!Array.isArray(customerFiles.value)) customerFiles.value = [];

            // remove previous file from customerFiles
            if (file.previousFileId) {
                const previousFile = customerFiles.value.find(({id}) => id === file.previousFileId);
                if (previousFile) {
                    customerFiles.value.splice(customerFiles.value.indexOf(previousFile), 1);
                }
            }

            // add new file to the top of the list
            customerFiles.value.unshift(file);

            return _data;
        })
        replace({state: {hasToRefresh: true}});
    }
}

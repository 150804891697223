import {useState} from 'react';

export default function useTabToggle(firstActiveTabKey) {
    const [activeTabKey, setActiveTab] = useState(firstActiveTabKey);

    const toggleTab = (tabKey) => () => {
        setActiveTab(tabKey);
    }

    return [activeTabKey, toggleTab];
}

import React, {Component} from 'react'
import {routesBase} from "v4/data/appRoutes";
import {arrowSort, noData} from "../../../components/Datatables/_partials/config";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import {updateDefaultSorting, getDefaultColumns, initData, sortFunc} from "../../../api/datas/datas";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {getViewOrdersOfEntityAndType} from "../../../utils/viewOrdersManager";
import {Redirect} from 'react-router-dom'
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withPolTranslation} from "../../../v4/hooks/usePolTranslation";

class TableHome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tabActive: '',
            tabsDefault: true,
            newColumns: [],
            columnForDefaultSort: '',
            redirect: null,
            isLoading: true,
            currPage: 1
        }
    }

    componentDidMount() {
        this.setState({isLoading: true}, this.getColumns());
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.datas !== this.props.datas) {
            this.setState({isLoading: true}, this.getColumns());
            this.setState({isLoading: false});
        }
        if(prevState.tabActive !== this.state.tabActive){
            const {info} = this.props
            this.setState({columnForDefaultSort: [...updateDefaultSorting(info.name, info.type)]});
        }
    }

    changePage = (currPage, sizePerPage) => {
        this.setState({currPage: currPage});
    }

    /**
     * get columns of table
     */
    getColumns = () => {
        const {info, datas, t} = this.props;
        this.setState({columnForDefaultSort: updateDefaultSorting(info.name, info.type), tabActive: info.tabActive});
        let data = null;
        if(datas[info.tabActive]) {
            data = datas[info.tabActive].data;
            if(data.length === 0) {
                info.tabs.map(tab => {
                    if(datas[tab] && datas[tab].data && datas[tab].data.length > 0) {
                        data = datas[tab].data;
                        return '';
                    }
                    return '';
                });
            }
        }

        const visibleInfos = getViewOrdersOfEntityAndType(info.order_entity, info.order_type);

        if (!visibleInfos || !visibleInfos[0] || !visibleInfos[0].fields) {
            this.initData(data, []);
            return;
        }

        const sortVisibleInfos = visibleInfos[0].fields.sort((a, b) => a.order - b.order);

        let columns = getDefaultColumns(data, t, true, visibleInfos);

        columns.forEach((item) => item.order = 99);
        sortVisibleInfos.map((item, index) => {
            columns.map((column, columKey) => {
                if (item.key === column.dataField) {
                    columns[columKey].order = index;
                    columns[columKey].valueType = item.valueType;
                    delete columns[columKey].hidden;
                }
                return '';
            });
            return '';
        });

        columns.push({
            text: 'isLate',
            dataField: 'isLate',
            sort: true,
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            order: -1,
            formatter: (value, row, key) => {
                return (
                    value ? <FontAwesomeIcon className={"datatable__header-homepage__icon datatable__header-homepage__icon-late"} icon={faClock}/> :
                        <FontAwesomeIcon className={'datatable__header-homepage__icon datatable__header-homepage__icon-not-late'} icon={faClock}/>
                )
            },
        })

        columns.push({
            text: t('prospect_fullname'),
            dataField: info.order_entity === 'Quote' ? 'prospectFullName' : 'prospect.fullname',
            sort: true,
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            order: -1,
        })

        columns.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
        const keys = Object.keys(datas);
        keys.forEach((item) => {
            if(datas[item] && datas[item].data){
                this.initData(datas[item].data, columns);
            }
        })

        this.initData(data, columns);
    }

    /**
     * Build table
     * @param datas
     * @param columns
     */
    initData = (datas = [], columns = []) => {
        const {info, t} = this.props;
        const numberItem = 5;

        const dataSetted = initData(datas, columns, info, numberItem, this.setNumber, this.setVisibleList, t, this.changePage);

        this.dataSizeChange({
            dataSize: dataSetted.datas.length
        });

        this.setState({
            newColumns: dataSetted.columns.filter(item => item.hidden !== true),
            newDatas: dataSetted.datas,
            pagination: {
                pagination: paginationFactory(dataSetted.config)
            },
            isDataLoaded: true,
        });
    };

    /**
     * Handle change size
     * @param dataSize
     */
    dataSizeChange = ({dataSize}) => {
        this.setState({noResult: dataSize === 0 });
    };

    /**
     * Click on table row
     *
     * @param e
     * @param row
     * @returns {boolean}
     */
    clickElement = (e, row) => {
        const {info} = this.props;

        const eventTarget = e.target;
        const eventDataAction = eventTarget.dataset.action;
        const eventParentDataAction = eventTarget.parentNode.dataset.action;

        const updateInfo = eventDataAction === 'update' || eventParentDataAction === 'update';

        const id = row['prospect.id'] ? row['prospect.id'] : row.prospect.id;

        this.setState({
            redirect: null,
            classVisibleFilters: 'hidden-search'
        }, () => {
            this.setState({
                redirect: <Redirect to={{
                    pathname: `${routesBase.baseProspect}/${id}/${info.route.replaceAll('_', '-')}/${row.id}`,
                    state: {
                        updateInfo: updateInfo,
                    }
                }}/>
            });
        });
    };

    render() {
        const {tabActive, tabsDefault, newColumns, columnForDefaultSort, redirect, pagination, isLoading, currPage} = this.state;
        const {info, datas, t} = this.props;

        return (
            <>
                {redirect}
                <div className={'datatable__header-homepage row'}>
                    <div className={`col-6 datatable__header-homepage-tabs ${info.classNameTabs} ${tabsDefault ? 'active' : ''}`}>
                        {info.tabs && info.tabs.map((tab, key) => (
                            <button key={tab + key}
                                    type="button"
                                    className={`col-6 ${tab} ${tabActive === tab ? 'active' : ''}`}
                                    onClick={() => {
                                        this.setState({
                                            tabActive: tab,
                                            tabsDefault: false,
                                        });
                                        this.initData(datas[tab]?.data, newColumns);
                                    }}>
                                {t(tab)}
                            </button>
                        ))}
                    </div>
                    {datas?.[tabActive] && (
                        <p className={'col-6'}>
                            {datas[tabActive]?.data?.length > 0 && (
                                t('result_header_datatable_homepage', {
                                    start: datas[tabActive].number ? ((currPage - 1) * 5) + parseInt(datas[tabActive].number.start) : 0,
                                    end: datas[tabActive].number ?
                                        ((currPage - 1) * 5) + parseInt(datas[tabActive].number.end) < datas[tabActive].number.count ?
                                            ((currPage - 1) * 5) + parseInt(datas[tabActive].number.end) : datas[tabActive].number.count
                                        : 0,
                                    count: datas[tabActive].data ? datas[tabActive].data.length : 0,
                                    many: (datas[tabActive].number && datas[tabActive].number.count > 1) ? 's' : '',
                                    all: (datas[tabActive].number && datas[tabActive].number.count > 1) ? 's' : '',
                                })
                            )}
                        </p>
                    )}
                </div>

                {(datas?.[tabActive] && newColumns) &&
                <ToolkitProvider
                    keyField='id'
                    data={datas[tabActive].data}
                    columns={(datas[tabActive].data && datas[tabActive].data.length > 0) ? newColumns : [{
                        text: '',
                        dataField: 'noResult',
                    }]}
                >
                    {props => (
                        <div
                            className={`table-container ${datas[tabActive].data.length === 0 ? 'no-datas' : ''} ${info.classNameTabs}`}>
                            <div className={'table-content  table-content__margin'}>
                                <BootstrapTable
                                    {...props.baseProps}
                                    classes={`datatable datatable-loader`}
                                    noDataIndication={() => noData(t, isLoading)}
                                    onDataSizeChange={this.dataSizeChange}
                                    rowEvents={{onClick: this.clickElement}}
                                    keyField="id"
                                    ref={n => this.node = n}
                                    filter={filterFactory()}
                                    selectRow={ { mode: 'checkbox', clickToSelect: true } }
                                    pagination={pagination.pagination}
                                    defaultSorted={[columnForDefaultSort[0]]}
                                />
                            </div>
                        </div>
                    )}
                </ToolkitProvider>
                }
            </>
        )
    }
}

export default withPolTranslation(TableHome);

import moment from "moment";
import React, {useMemo} from 'react';
import {Controller, useFormContext, useWatch} from "react-hook-form";
import AutocompleteSelect from "v4/components/form/Field/components/AutocompleteSelect/AutocompleteSelect";
import CustomReactSelect from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import DisplayFields from "v4/components/form/Field/components/DisplayFields/DisplayFields";
import InputCheckboxes from "v4/components/form/Field/components/InputCheckboxes/InputCheckboxes";
import InputCompare from "v4/components/form/Field/components/InputCompare/InputCompare";
import InputDate from "v4/components/form/Field/components/InputDatetime/InputDate";
import InputEmail from "v4/components/form/Field/components/InputEmail/InputEmail";
import InputFile from "v4/components/form/Field/components/InputFile/InputFile";
import InputNumber from "v4/components/form/Field/components/InputNumber/InputNumber";
import InputPostalCode from "v4/components/form/Field/components/InputPostalCode/InputPostalCode";
import InputRadio from "v4/components/form/Field/components/InputRadio/InputRadio";
import InputRange from "v4/components/form/Field/components/InputRange/InputRange";
import InputRichText from "v4/components/form/Field/components/InputRichText/InputRichText";
import InputSimpleCheckbox from "v4/components/form/Field/components/InputSimpleCheckbox/InputSimpleCheckbox";
import InputTel from "v4/components/form/Field/components/InputTel/InputTel";
import InputText from "v4/components/form/Field/components/InputText/InputText";
import QuoteLines from "v4/components/form/Field/components/QuoteLines/QuotesLines";
import 'v4/components/form/Field/Field.scss';
import useConditionalField from "v4/components/form/Field/hooks/useConditionalField";
import useDependsOnField from "v4/components/form/Field/hooks/useDependsOnField";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {getFormattedValue} from "v4/utils";
import InputSiret from "v4/components/form/Field/components/InputSiret/InputSiret";
import {
    CHECKBOX_TYPE, CHOICE_TYPE, COLLECTION_TYPE, CUSTOMER_FILE_TYPE, DATE_COMPARE_TYPE,
    DATE_TIME_TYPE,
    DATE_TYPE,
    EMAIL_TYPE, FILE_TYPE,
    HIDDEN_TYPE, NUMBER_COMPARE_TYPE,
    NUMBER_TYPE,
    TEL_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE
} from "v4/data/fieldTypes";

export default React.forwardRef(function Field({
                                                   type,
                                                   name,
                                                   label,
                                                   value: defaultValue,
                                                   choices,
                                                   multiple,
                                                   expanded,
                                                   properties,
                                                   constraints,
                                                   attr,
                                                   placeholder = '',
                                                   onFieldChange,
                                                   specificParams = {},
                                                   prefix = '',
                                                   entry_type,
                                                   entityId,
                                                   impossibleValues = [],
                                               }, ref) {
    const {t} = usePolTranslation();
    const {register, control, getFieldState, formState} = useFormContext();
    const {error} = getFieldState(name, formState);

    // Fix readOnly attribute
    if ('readonly' in (attr ?? {})) {
        attr.readOnly = attr.readonly;
        delete attr.readonly;
    }

    const {
        'data-condition-field': conditionField,
        'data-condition-value': conditionFieldValues,
        'data-postal-code-target': postalCodeTarget,
        'data-wysiwyg-mode': richTextEditorMode,
        'display-fields': displayFields,
        dependsOnField,
        type: attrType,
        ...cleanedAttr
    } = attr ?? {};
    const hideField = useConditionalField(conditionField, conditionFieldValues);
    const {
        dependsOnFieldChoices,
        autocompleteAdditionnalSearchFilter
    } = useDependsOnField(name, dependsOnField, choices);

    const constraintsName = Object.values(constraints ?? {}).map(({propertyPath}) => propertyPath).filter(Boolean);
    const values = useWatch({control, disabled: (!constraintsName || constraintsName.length === 0)}) ?? [];
    const inputConstraints = useMemo(() => getConstraints(constraints, type, values, t), [constraints, type, values, t]);

    if (hideField) return null;
    if (attrType === 'hidden') {
        type = 'hidden';
    }

    switch (type) {
        case 'text':
        case TEXT_TYPE:
            if (postalCodeTarget) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputPostalCode fieldName={fieldName}
                                                            fieldValue={fieldValue}
                                                            inputConstraints={inputConstraints}
                                                            placeholder={placeholder}
                                                            prefix={prefix}
                                                            attr={cleanedAttr}
                                                            error={error}
                                                            postalCodeTarget={postalCodeTarget}
                                                            onChange={(value) => {
                                                                onChange(value)
                                                                onFieldChange && onFieldChange(value)
                                                            }}/>
                                }}/>
                </>
            }

            if (attrType === 'siret') {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputSiret fieldName={fieldName}
                                                       fieldValue={fieldValue}
                                                       inputConstraints={inputConstraints}
                                                       placeholder={placeholder}
                                                       prefix={prefix}
                                                       attr={cleanedAttr}
                                                       error={error}
                                                       onChange={(value) => {
                                                           onChange(value)
                                                           onFieldChange && onFieldChange(value)
                                                       }}/>
                                }}/>
                </>
            }

            return <>
                {error && <p className="input-field__errors">{error.message}</p>}
                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputText fieldName={fieldName}
                                                  fieldValue={fieldValue}
                                                  inputConstraints={inputConstraints}
                                                  placeholder={placeholder}
                                                  prefix={prefix}
                                                  attr={cleanedAttr}
                                                  error={error}
                                                  onChange={(value) => {
                                                      onChange(value)
                                                      onFieldChange && onFieldChange(value)
                                                  }}/>
                            }}/>
            </>
        case 'number':
        case NUMBER_TYPE:
            if (attrType === 'range' || attrType === 'slider') {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputRange fieldValue={fieldValue}
                                                       fieldName={fieldName}
                                                       attributes={cleanedAttr}
                                                       htmlConstraints={inputConstraints}
                                                       onChange={(value) => {
                                                           onChange(value)
                                                           onFieldChange && onFieldChange(value)
                                                       }}/>
                                }}/>
                </>
            }

            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputNumber fieldName={fieldName}
                                                    fieldValue={fieldValue}
                                                    attr={cleanedAttr}
                                                    inputConstraints={inputConstraints}
                                                    placeholder={placeholder}
                                                    prefix={prefix}
                                                    error={error}
                                                    onChange={(value) => {
                                                        onChange(value)
                                                        onFieldChange && onFieldChange(value)
                                                    }}/>
                            }}/>
            </>
        case TEL_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputTel fieldName={fieldName}
                                                 inputConstraints={inputConstraints}
                                                 placeholder={placeholder}
                                                 prefix={prefix}
                                                 attr={cleanedAttr}
                                                 error={error}
                                                 fieldValue={fieldValue}
                                                 onChange={(value) => {
                                                     onChange(value)
                                                     onFieldChange && onFieldChange(value)
                                                 }}/>
                            }}/>
            </>
        case 'email':
        case EMAIL_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputEmail fieldName={fieldName}
                                                   fieldValue={fieldValue}
                                                   inputConstraints={inputConstraints}
                                                   placeholder={placeholder}
                                                   prefix={prefix}
                                                   attr={cleanedAttr}
                                                   error={error}
                                                   onChange={(value) => {
                                                       onChange(value)
                                                       onFieldChange && onFieldChange(value)
                                                   }}/>
                            }}/>
            </>
        case 'hidden':
        case HIDDEN_TYPE:
            return <>
                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {name: fieldName, value: fieldValue}}) => {
                                return <input className={`input-field${error ? ' has-error' : ''}`}
                                              type="hidden"
                                              id={prefix + fieldName}
                                              defaultValue={fieldValue}
                                              {...cleanedAttr}/>
                            }}/>
            </>
        case 'datetime':
        case DATE_TIME_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputDate fieldName={fieldName}
                                                  fieldValue={fieldValue}
                                                  withTime={true}
                                                  inputConstraints={inputConstraints}
                                                  placeholder={placeholder}
                                                  prefix={prefix}
                                                  attr={cleanedAttr}
                                                  error={error}
                                                  onChange={(value) => {
                                                      onChange(value)
                                                      onFieldChange && onFieldChange(value)
                                                  }}/>
                            }}/>
            </>
        case 'date':
        case DATE_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputDate fieldName={fieldName}
                                                  fieldValue={fieldValue}
                                                  inputConstraints={inputConstraints}
                                                  placeholder={placeholder}
                                                  prefix={prefix}
                                                  attr={cleanedAttr}
                                                  error={error}
                                                  onChange={(value) => {
                                                      onChange(value)
                                                      onFieldChange && onFieldChange(value)
                                                  }}/>
                            }}/>
            </>
        case 'textarea':
        case TEXTAREA_TYPE:
            if (cleanedAttr?.['rich-text-editor']) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputRichText fieldValue={fieldValue}
                                                          fieldName={fieldName}
                                                          inputConstraints={inputConstraints}
                                                          isRestricted={richTextEditorMode === 'restricted'}
                                                          {...cleanedAttr}
                                                          ref={ref}
                                                          overrideTinyMceProps={specificParams}
                                                          onChange={(value) => {
                                                              onChange(value)
                                                              onFieldChange && onFieldChange(value)
                                                          }}/>
                                }}/>
                </>
            }

            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <textarea className={`input-field${error ? ' has-error' : ''}`}
                                                 id={prefix + fieldName} value={fieldValue} placeholder={placeholder}
                                                 {...cleanedAttr} data-is-required={!!inputConstraints?.required}
                                                 onChange={(e) => {
                                                     onChange(e)
                                                     onFieldChange && onFieldChange(e.currentTarget.value)
                                                 }}/>
                            }}/>
            </>
        case 'checkbox':
        case CHECKBOX_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? false}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputSimpleCheckbox fieldValue={fieldValue}
                                                            fieldName={fieldName}
                                                            inputConstraints={inputConstraints}
                                                            prefix={prefix}
                                                            attr={cleanedAttr}
                                                            error={error}
                                                            onChange={(booleanValue) => {
                                                                onChange(booleanValue)
                                                                onFieldChange && onFieldChange(booleanValue)
                                                            }}/>
                            }}/>
            </>
        case CUSTOMER_FILE_TYPE:
        case FILE_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputFile fieldValue={fieldValue}
                                                  fieldName={fieldName} multiple={false}
                                                  properties={properties} register={register}
                                                  htmlConstraints={inputConstraints}
                                                  ref={ref}
                                                  onChange={(value) => {
                                                      onChange(value)
                                                      onFieldChange && onFieldChange(value)
                                                  }}/>
                            }}/>
            </>
        case COLLECTION_TYPE:
            if (entry_type === CUSTOMER_FILE_TYPE) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputFile fieldValue={fieldValue} ref={ref}
                                                      fieldName={fieldName} multiple={true}
                                                      properties={properties} register={register}
                                                      htmlConstraints={inputConstraints}
                                                      onChange={(value) => {
                                                          onChange(value)
                                                          onFieldChange && onFieldChange(value)
                                                      }}/>
                                }}/>
                </>
            } else if (entry_type === 'QuoteLineType') {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? []}
                                render={({field: {onChange}}) => {
                                    return <QuoteLines properties={properties}
                                                       onChange={(value) => {
                                                           onChange(value)
                                                           onFieldChange && onFieldChange(value)
                                                       }}/>
                                }}/>
                </>
            }

            return <p className="input-field__error">L'input de type {type ?? '"erreur"'} n'est pas géré</p>;

        case 'select':
        case CHOICE_TYPE:
            if (displayFields) {
                return <DisplayFields label={label} choices={choices} attr={cleanedAttr}/>
            }

            const {'is-expanded': isExpanded} = cleanedAttr ?? {};
            if ((isExpanded || expanded) && !multiple) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                    return <InputRadio fieldName={fieldName} fieldValue={fieldValue}
                                                       choices={choices} prefix={prefix}
                                                       htmlConstraints={inputConstraints}
                                                       onChange={(value) => {
                                                           onChange(value)
                                                           onFieldChange && onFieldChange(value)
                                                       }}/>
                                }}/>
                </>
            }
            if ((isExpanded || expanded) && multiple) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? []}
                                render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                    return <InputCheckboxes fieldName={fieldName} fieldValue={fieldValue}
                                                            choices={choices} prefix={prefix}
                                                            htmlConstraints={inputConstraints}
                                                            onChange={(value) => {
                                                                onChange(value)
                                                                onFieldChange && onFieldChange(value)
                                                            }}/>
                                }}/>
                </>
            }

            if (attrType === 'autocomplete') {
                const {'autocomplete_entity': entity} = cleanedAttr;

                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints}
                                defaultValue={defaultValue ?? (multiple ? [] : "")}
                                render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                    return <AutocompleteSelect name={fieldName}
                                                               value={fieldValue}
                                                               options={dependsOnFieldChoices}
                                                               entity={entity}
                                                               autocompleteAdditionnalSearchFilter={autocompleteAdditionnalSearchFilter}
                                                               isMulti={multiple}
                                                               selectParams={specificParams}
                                                               placeholder={placeholder}
                                                               htmlConstraints={inputConstraints}
                                                               impossibleValues={impossibleValues}
                                                               onChange={(value) => {
                                                                   onChange(value)
                                                                   onFieldChange && onFieldChange(value)
                                                               }}/>
                                }}/>
                </>
            }
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints}
                            defaultValue={defaultValue ?? (multiple ? [] : "")}
                            render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                return <CustomReactSelect name={fieldName} value={fieldValue}
                                                          options={dependsOnFieldChoices} isMulti={multiple}
                                                          selectParams={specificParams} placeholder={placeholder}
                                                          htmlConstraints={inputConstraints}
                                                          onChange={(value) => {
                                                              onChange(value)
                                                              onFieldChange && onFieldChange(value)
                                                          }}/>
                            }}/>
            </>
        case NUMBER_COMPARE_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints}
                            defaultValue={defaultValue ?? {equal: '=', begin: "", end: ""}}
                            render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                return <InputCompare type="number" fieldValue={fieldValue}
                                                     htmlConstraints={inputConstraints}
                                                     onChange={(value) => {
                                                         onChange(value)
                                                         onFieldChange && onFieldChange(value)
                                                     }}/>
                            }}/>
            </>
        case DATE_COMPARE_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints}
                            defaultValue={defaultValue ?? {equal: '=', begin: "", end: ""}}
                            render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                return <InputCompare type="date" fieldValue={fieldValue}
                                                     htmlConstraints={inputConstraints}
                                                     onChange={(value) => {
                                                         onChange(value)
                                                         onFieldChange && onFieldChange(value)
                                                     }}/>
                            }}/>
            </>
        default:
            return <p className="input-field__error">L'input de type {type ?? '"erreur"'} n'est pas géré</p>;
    }
})

function getConstraints(constraints, type, values, t) {
    const inputConstraints = {}
    if (constraints?.required) {
        inputConstraints.required = t('validation_required')
    }
    if (constraints?.Length) {
        if (constraints.Length?.max !== undefined) {
            inputConstraints.maxLength = {
                value: constraints.Length.max,
                message: t('validation_maxLength', {max: constraints.Length.max}),
            };
        }
        if (constraints.Length?.min !== undefined) {
            inputConstraints.minLength = {
                value: constraints.Length.min,
                message: t('validation_minLength', {min: constraints.Length.min}),
            };
        }
    }
    if (constraints?.Date) {
        type = DATE_TYPE;
    }
    if (constraints?.DateTime) {
        type = DATE_TIME_TYPE;
    }
    if (constraints?.Email) {
        type = 'email';
        inputConstraints.validate = {
            ...inputConstraints.validate,
            email: (fieldValue) => {
                if (fieldValue === '') {
                    return true;
                }
                return /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(fieldValue) || t('validation_email');
            }
        }
    }
    if (constraints?.NotEqualTo) {
        inputConstraints.validate = {
            ...inputConstraints.validate,
            notEqualTo: (fieldValue) => {
                if (!inputConstraints?.required && fieldValue === '') {
                    return true;
                }
                return fieldValue.match(new RegExp(`^${constraints.NotEqualTo?.value}$`, 'g')) === null
                    || t('validation_notEqualTo', {pattern: constraints.NotEqualTo?.value});
            }
        }
    }
    if (constraints?.GreaterThan) {
        const constraintValue = constraints?.GreaterThan?.propertyPath
            ? values[constraints?.GreaterThan?.propertyPath]
            : getFormattedValue(type, constraints.GreaterThan?.value, {toEndOfDay: true})
        ;
        inputConstraints.validate = {
            ...inputConstraints.validate,
            greaterThan: (fieldValue) => {
                if (!inputConstraints?.required && fieldValue === '') {
                    return true;
                }
                if ([DATE_TYPE, DATE_TIME_TYPE].includes(type)) {
                    return moment(fieldValue) > moment(constraintValue) || t('validation_greaterThan', {number: getFormattedValue(type, constraintValue, {keepTime: true})});
                }
                return fieldValue > constraintValue || t('validation_greaterThan', {number: constraints.GreaterThan?.value})
            },
        }
    }
    if (constraints?.GreaterThanOrEqual) {
        const constraintValue = constraints.GreaterThanOrEqual.propertyPath
            ? values[constraints.GreaterThanOrEqual.propertyPath]
            : getFormattedValue(type, constraints.GreaterThanOrEqual?.value, {toEndOfDay: true})
        ;
        inputConstraints.validate = {
            ...inputConstraints.validate,
            GreaterThanOrEqual: (fieldValue) => {
                if (!inputConstraints?.required && fieldValue === '') {
                    return true;
                }

                if ([DATE_TYPE, DATE_TIME_TYPE].includes(type)) {
                    return moment(fieldValue) >= moment(constraintValue) || t('validation_greaterThanOrEqual', {number: getFormattedValue(type, constraintValue, {keepTime: true})});
                }
                return fieldValue >= constraintValue || t('validation_greaterThanOrEqual', {number: constraints.GreaterThanOrEqual?.value})
            },
        }
    }
    if (constraints?.LessThan) {
        const constraintValue = constraints?.LessThan?.propertyPath
            ? values[constraints?.LessThan?.propertyPath]
            : getFormattedValue(type, constraints.LessThan?.value, {toEndOfDay: true})
        ;
        inputConstraints.validate = {
            ...inputConstraints.validate,
            lessThan: (fieldValue) => {
                if (!inputConstraints?.required && fieldValue === '') {
                    return true;
                }
                if ([DATE_TYPE, DATE_TIME_TYPE].includes(type)) {
                    return moment(fieldValue) < moment(constraintValue) || t('validation_lessThan', {number: getFormattedValue(type, constraintValue, {keepTime: true})});
                }
                return fieldValue < constraintValue || t('validation_lessThan', {number: constraintValue})
            },
        }
    }
    if (constraints?.LessThanOrEqual) {
        const constraintValue = constraints?.LessThanOrEqual?.propertyPath
            ? values[constraints?.LessThanOrEqual?.propertyPath]
            : getFormattedValue(type, constraints.LessThanOrEqual?.value, {toEndOfDay: true})
        ;
        inputConstraints.validate = {
            ...inputConstraints.validate,
            lessThanOrEqual: (fieldValue) => {
                if (!inputConstraints?.required && fieldValue === '') {
                    return true;
                }
                if ([DATE_TYPE, DATE_TIME_TYPE].includes(type)) {
                    return moment(fieldValue) < moment(constraintValue) || t('validation_lessThanOrEqual', {number: getFormattedValue(type, constraintValue, {keepTime: true})});
                }
                return fieldValue <= constraintValue || t('validation_lessThanOrEqual', {number: constraintValue})
            },
        }
    }
    if (constraints?.Positive) {
        if (constraints?.GreaterThan?.value < 0 || constraints?.GreaterThanOrEqual?.value < 0) {
            inputConstraints.min = {
                value: 0,
                message: t('validation_greaterThanOrEqual', {number: 0}),
            };
        }
    }
    if (constraints?.File) {
        inputConstraints.validate = {
            ...inputConstraints.validate,
            file: (fieldValue) => {
                if (!inputConstraints?.required && (fieldValue === '' || fieldValue?.file === '')) {
                    return true;
                }
                return fieldValue.file !== '' || t('validation_file');
            },
        }
    }
    if (constraints?.Regex) {
        inputConstraints.pattern = {
            value: new RegExp(constraints.Regex?.pattern),
            message: t('validation_regex'),
        };
    }
    if (constraints?.NotBlank || constraints?.NotNull) {
        inputConstraints.required = t('validation_required');
    }

    return inputConstraints;
}

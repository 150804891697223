import React, {Component} from 'react';
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import Form from "../Form/form";
import {baseMailType, baseProspect, getApiUrl} from "../../routes";
import {Redirect} from "react-router-dom";
import {setPopup} from "../../actions/content";
import {DeleteService} from "../../api/datas/delete";
import {resetStoreDatas} from "../../actions/datas";
import PopupInfoConfirm from "../Popup/_popup_info_confirm";
import {PolIcon} from "../PolIcon/policon";

class New extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            loader: true,
            info: {}
        }
    }

    componentDidMount() {
        const {match: {params: {type = 'business', itemId = undefined}}} = this.props;

        if (infoType[type]) {
            let infoForm = infoType[type];
            if (itemId) {
                infoForm = {
                    ...infoForm,
                    formParam: itemId,
                    itemId: itemId,
                    putUrl: infoForm.submitUrl,
                    title: infoForm.title.replace('add', 'edit'),
                    setDataInfo: {
                        ...infoForm.setDataInfo,
                        id: itemId
                    }
                }
            }

            this.setState({
                info: infoForm,
                loader: false
            })
        } else {
            this.setState({
                redirect: <Redirect to={baseProspect}/>
            })
        }
    }

    handleDelete() {
        const {setPopup, deleteService, t, resetDatas} = this.props;
        const {match: {params: {type = 'business', itemId = undefined}}} = this.props;
        if (itemId && infoType[type] && infoType[type].deleteUrl) {
            setPopup({
                isOpen: true,
                content: (
                    <PopupInfoConfirm
                        message={t('confirm_delete')}
                        onAccept={() => {
                            deleteService({deleteUrl: infoType[type].deleteUrl.replace('{id}', itemId)})
                            setPopup({});
                            this.setState({
                                redirect: <Redirect to={infoType[type].backUrl}/>
                            })
                            resetDatas();
                        }}
                        onDecline={() => {
                            setPopup({})
                        }}
                    />
                )
            })
        }
    }

    render() {
        const {info, info:{group}, redirect, loader} = this.state;
        const {t} = this.props;

        return (
            <>
                {redirect}

                {!loader ? (
                    <>
                        <div className="fullpage page page-details">
                            <div className={'page-details__content'}>
                                <div className="page-details__close"><span className={"page-details__close__icon"} onClick={() => {
                                    this.setState({
                                        redirect: <Redirect to={{
                                            pathname: group === 'individual' || group === 'business' ? baseProspect : baseMailType,
                                        }}/>
                                    }, () => {
                                        this.setState({
                                            redirect: null
                                        })
                                    });
                                }}
                                                                           data-tip={t('quit')}>
                                            <PolIcon icon="quit"/>
                                        </span></div>
                                <div className={'page-details__prospect'}>
                                    <div className="row row-fluid page-details__prospect__title">
                                        <div className="col-auto col-fluid page-details__prospect__name">
                                            <PolIcon icon={group === 'business' ? 'company' : group === 'individual' ? 'client' : 'mail-historical'}/>
                                            <p
                                            title={t(info.title)}>{t(info.title)}</p>
                                        </div>
                                    </div>
                                    <div className="page-details__edit">

                                    </div>
                                    <Form isCurrentEditionByView={true} info={info}/>
                                </div>
                            </div>
                        </div>

                    </>
                ) : null}
                </>
        )
    }
}

const infoType = {
    individual: {
        name: 'propsect_individual',
        formUrl: getApiUrl('prospects', 'formIndividual'),
        submitUrl: getApiUrl('prospects', 'post'),
        title: 'ajouter_prospect_particulier',
        backUrl: baseProspect,
        group: 'individual',
        setDataInfo: {
            name: 'prospects',
            type: 'data'
        },
    },
    business: {
        formUrl: getApiUrl('prospects', 'formBusiness'),
        submitUrl: getApiUrl('prospects', 'post'),
        name: 'propsect_business',
        title: 'ajouter_prospect_entreprise',
        backUrl: baseProspect,
        group: 'business',
        setDataInfo: {
            name: 'prospects',
            type: 'data'
        },
    },
    "mail-type": {
        formUrl: getApiUrl('mails', 'form'),
        submitUrl: getApiUrl('mails', 'post'),
        deleteUrl: getApiUrl('mails', 'delete'),
        name: 'mail_type',
        title: 'add_mailing_type',
        backUrl: baseMailType,
        order_type: 'form',
        order_entity: 'MailType',
        setDataInfo: {
            name: 'mailType',
            type: 'data'
        },
    }
};

const mapStateToProps = ({form}) => ({
    form
});

const mapDispatchToProps = dispatch => {
    return {
        setPopup: (info) => dispatch(setPopup(info)),
        deleteService: (info) => dispatch(DeleteService(info)),
        resetDatas: () => dispatch(resetStoreDatas()),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(New));

import {setError} from "../actions/datas";
import {setAddInfo} from "../actions/content";

export const handleUnitMailResponse = (response, dispatch, t) => {
    const mailSuccess = [];
    const mailErrors = [];

    Object.keys(response).forEach((key) => {
        if(parseInt(response[key]) > 0){
            mailSuccess.push(key);
            return '';
        }
        mailErrors.push(key);
    })

    if(mailErrors.length){
        dispatch(setError({msg: t('mail_unit_send_error', {emails: mailErrors.join(';')})}))
    } else {
        dispatch(setError({msg: t('mail_unit_send_ok', {emails: mailSuccess.join(';')}), errorCode : 200}))
    }

    dispatch(setAddInfo(null));
}

import React from 'react';

export default function InputTel({
                                     fieldName,
                                     fieldValue,
                                     prefix,
                                     placeholder,
                                     error,
                                     attr,
                                     inputConstraints,
                                     onChange
                                 }) {
    const handleChange = ({currentTarget: {value}}) => {
        const formattedValue = telFormat(value).trim();
        if (formattedValue === fieldValue) return;
        onChange(formattedValue);
    }

    return (
        <input className={`input-field${error ? ' has-error' : ''}`}
               type="tel"
               id={prefix + fieldName}
               value={fieldValue}
               placeholder={placeholder}
               data-is-required={!!inputConstraints?.required}
               {...attr}
               onChange={handleChange}/>
    )
}

function telFormat(value) {
    const isStartWithPlus = value.startsWith('+');
    let v = value.replace(/\D/g, '');
    if (isStartWithPlus) {
        // first part : +337
        const firstPart = v.substring(0, 3);
        // second part : 12 34 56 78
        const secondPart = v.substring(3).replace(/(\d{2})/g, '$1 ');
        v = firstPart + ' ' + secondPart;
    } else {
        v = v.replace(/(\d{2})/g, '$1 ');
    }

    v = isStartWithPlus ? '+' + v : v;
    // limit phone number to 15 digits + all spaces
    const limit = 15 + Math.floor(15 / 2);

    return v.substring(0, limit);
}

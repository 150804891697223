import React from 'react';
import Logo from "../../img/logo";

export const Loading = ({message, containerClass = null, specificClass = null}) => {
    return (
        <div className={`is-loading ${containerClass}`}>
            <span className={`loader ${specificClass}`}>
                <Logo/>
                <p className={'loader-message'}>{message}</p>
            </span>
        </div>
    )
}

export default Loading;

export default function useAttributesPopupRenderFields(inputs) {
    if (!inputs) return [[], []];

    return inputs.reduce((acc, input) => {
        if (input.label.split('|')[1]?.toLowerCase() !== 'hidden') {
            if (input.groupCode) {
                if (acc[1].some((item) => item.groupCode === input.groupCode)) {
                    acc[1].find((item) => item.groupCode === input.groupCode).inputs.push(input);
                } else {
                    acc[1].push({
                        groupCode: input.groupCode,
                        inputs: [input],
                    });
                }
            } else {
                acc[0].push(input);
            }
        }
        return acc;
    }, [[], []]);
}

import {captureException} from "@sentry/react";
import {useContext, useEffect, useState} from 'react';
import {MercureContext} from "v4/contexts/MercureContext";
import {setLSItem} from "v4/services/localStorage.service";

const eventSources = {};

export default function useMercure({topic, localStorageSaveKey, itemKey}) {
    const {hubUrl, prefix, withCredentials} = useContext(MercureContext);
    const [eventData, setEventData] = useState(null);

    useEffect(() => {
        const eventSourceUrl = `${hubUrl}?topic=${prefix}${encodeURIComponent(topic)}`;
        const eventSource = getEventSource(eventSourceUrl, withCredentials);
        const onMessage = e => {
            try {
                const data = JSON.parse(e.data);
                if (localStorageSaveKey) {
                    setLSItem(localStorageSaveKey, data)
                }
                setEventData(data);
            } catch (error) {
                captureException(e);
                setEventData(e.data);
            }
        }
        eventSource.addEventListener('message', onMessage);

        return () => {
            eventSource.removeEventListener('message', onMessage);
            eventSource.close();
            delete eventSources[eventSourceUrl]
        }
    }, [hubUrl, prefix, topic, withCredentials, localStorageSaveKey]);

    return itemKey ? eventData?.[itemKey] : eventData;
}

function getEventSource(url, withCredentials) {
    if (!eventSources[url]) {
        eventSources[url] = new EventSource(url, {withCredentials});
    }

    return eventSources[url];
}

import {useEffect} from 'react';

export default function useHandleFormReady(formMethods, formInputs, isFormReady, setFormReady) {
    const {flatInputs, state: {isFinished} = {}} = formInputs ?? {};

    useEffect(() => {
        if (!isFormReady && isFinished && flatInputs.every(({key}) => Object.keys(formMethods.getValues()).includes(key))) {
            setFormReady(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formMethods.getValues, flatInputs]);
}

import {useEffect, useState} from "react";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {QUOTE_FILIATION} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

let cacheFiliation = {
    ids: [],
    data: {},
};

export default function useProspectQuotesTabs() {
    const [{data}, fetchFiliation] = useFetch();
    const {quote} = useProspectPageContext();

    const [filiation, setFiliation] = useState(() => {
        if (cacheFiliation.ids.includes(quote.metadata.id)) return cacheFiliation;

        return {ids: [], data: {}}
    });

    useEffect(() => {
        if (quote?.metadata?.id && !filiation.ids.includes(quote.metadata.id)) {
            fetchFiliation({url: generateUrl(QUOTE_FILIATION, {id: quote.metadata.id}),})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data) {
            setFiliation({
                ids: [data.parent.id, ...data.children.map(({id}) => id)],
                data,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // adding cache to avoid re-fetching data when switching between tabs
    useEffect(() => {
        if (filiation.ids.length > 0) {
            return () => cacheFiliation = filiation;
        }
    }, [filiation]);

    return filiation.data;
}

import {useMemo} from "react";
import {
    DASHBOARD_QUOTE_IN_PROGRESS,
    DASHBOARD_QUOTE_SIGNED,
    DASHBOARD_TASK_IN_PROGRESS,
    DASHBOARD_TASK_TODAY,
} from "v4/data/apiRoutes";
import useDashboardListing from "v4/pages/front/Dashboard/hooks/useDashboardListing";
import {randomId} from "v4/utils";

const allowedTabs = {
    'daily-tasks': {
        id: randomId(),
        fetchUrl: DASHBOARD_TASK_TODAY,
        hasState: true,
    },
    'opened-tasks': {
        id: randomId(),
        fetchUrl: DASHBOARD_TASK_IN_PROGRESS,
        hasState: true,
    },
    'current_quotes': {
        id: randomId(),
        fetchUrl: DASHBOARD_QUOTE_IN_PROGRESS,
        hasState: true,
    },
    'signed_quotes': {
        id: randomId(),
        fetchUrl: DASHBOARD_QUOTE_SIGNED,
    }
}

export default function useDashboardTabListing(tabKey, activeTabKey, setCounter) {
    const tab = allowedTabs[tabKey];
    const {
        state,
        data,
        totalItems,
        totalAmount,
        onHeaderClick
    } = useDashboardListing(tab?.fetchUrl);

    return useMemo(() => {
        if (tab && data) {
            setCounter(tabKey, {totalItems, totalAmount});
            return {
                key: tabKey,
                isShowing: activeTabKey === tabKey,
                state,
                data: addStateToData(data, tab),
                onHeaderClick
            }
        }

        return {
            key: tabKey,
            isShowing: activeTabKey === tabKey,
            state,
            data,
            onHeaderClick
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, activeTabKey, state]);

}

function addStateToData(data, tab) {
    const {hasState = false} = tab;
    const dataCopy = window.structuredClone(data);

    if (hasState) {
        const stateHeader = {
            key: "isLate",
            label: "isLate",
            metadata: {
                type: "icon",
                sortable: false,
            }
        }
        const stateField = {
            key: "isLate",
            metadata: {
                icon: "clock-full",
                iconColor: "#e74c3c",
            },
        }
        dataCopy.headers.unshift(stateHeader);
        dataCopy.results.forEach((result) => {
            const stateFieldCopy = window.structuredClone(stateField);
            if ('isLate' in result.metadata && !result.metadata.isLate) {
                stateFieldCopy.metadata.iconColor = "#47ad3b";
            }
            result.fields.unshift(stateFieldCopy);
        });
    }

    return dataCopy;
}

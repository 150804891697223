import React from 'react'
import {createContext, useState} from "react"
import UserService from "api/user/user";

const defaultCustomer = {
    asCustomer: null,
    setAsCustomer: () => {},
}

export const AdminContext = createContext(defaultCustomer)

export function AdminProvider({children}) {
    const customerId = UserService.getCustomerId()
    const [asCustomer, setAsCustomer] = useState(customerId)

    return (
        <AdminContext.Provider value={{asCustomer, setAsCustomer}}>
            {children}
        </AdminContext.Provider>
    )
}

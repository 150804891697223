import {useEffect, useMemo} from 'react';
import {useFormContext} from "react-hook-form";
import useFetch from "v4/hooks/useFetch";
import {generateRelativeUrl, generateUrlFromRelativeUrl} from "v4/services/api.service";
import {debounce} from "v4/utils";

export default function useAutocomplete(autoCompleteUrl, dataField, fieldValue, length = 3, autocompleteValues, target, targetField) {
    const [{data}, fetchAutocomplete] = useFetch();

    useEffect(() => {
        if (autoCompleteUrl && fieldValue?.length >= length) {
            const generateUrlFunction = autoCompleteUrl.startsWith('http') ? generateRelativeUrl : generateUrlFromRelativeUrl;
            debounce(() => fetchAutocomplete({
                url: generateUrlFunction(autoCompleteUrl, {[dataField]: fieldValue}),
                config: {
                    headers: {}
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoCompleteUrl, dataField, fieldValue, length]);

    const {setValue} = useFormContext();
    useEffect(() => {
        if (autoCompleteUrl && fieldValue?.length >= length && target && targetField) {
            const dataValue = data?.[autocompleteValues ?? 'hydra:member'];
            const targetData = dataValue?.filter(item => String(item[dataField]) === String(fieldValue));
            if (targetData?.length === 1) {
                setValue(target, targetData[0][targetField]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, fieldValue]);

    return useMemo(() => {
        const values = data?.[autocompleteValues ?? 'hydra:member'];
        if (values) {
            return values.map(item => item[dataField]);
        }
        return [];
    }, [data, dataField, autocompleteValues]);
}

import {errorAjaxHandler, fetchHandler, manageError} from './datas';
import {setLoader} from '../../actions/content';
import {setFilterSearch, setFormError, setFormSuccess} from '../../actions/form';
import {resetSubResourceStore, setData, setDataId, setError} from '../../actions/datas';
import {resetGedData} from '../../actions/ged';
import {handleUnitMailResponse} from '../../utils/unitMail';
import UserService from '../user/user';
import {getViewOrdersOfEntityAndType} from '../../utils/viewOrdersManager';
import {batch} from 'react-redux';

function handleRequestBody(submittedData, isPutRequest) {
    const dataToStringify = {
        ...submittedData
    };
    delete dataToStringify.customerFiles;
    const dataToPost = JSON.stringify(dataToStringify);

    if (submittedData?.customerFiles?.length > 0) {
        const form = new FormData();

        if (typeof submittedData.customerFiles === 'object') {
            Object.keys(submittedData.customerFiles).forEach((key) => {
                if(submittedData.customerFiles[key] instanceof Blob) {
                    form.append(`customerFiles[${key}]`, submittedData.customerFiles[key], submittedData.customerFiles[key]?.name);
                }else{
                    form.append(`customerFiles[${key}]`, submittedData.customerFiles[key]);
                }
            })
        } else {
            form.append('customerFiles[]', submittedData.customerFiles);
        }
        form.append('request', dataToPost);
        if (isPutRequest) {
            // Symfony n'accepte pas le transfert de fichier en PUT, il faut utiliser un champs _method et envoyer la requête fetch en POST
            form.append('_method', 'PUT');
        }

        return form;
    }

    return dataToPost;
}

export function PostService(info, t) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');
        const url = info.putUrl ? info.putUrl : info.url;
        const {doIsSubmitAndCreate} = info;
        const headerJsonParams = {
            'Content-Type': 'application/json',
        };
        let commonHeader = {
            Accept: 'application/ld+json',
            "X-POL-AUTH": 'Bearer ' + token
        };
        const body = handleRequestBody(info.submittedData, info.putUrl ? true : false);

        if (typeof body === 'string') {
            commonHeader = {
                ...commonHeader,
                ...headerJsonParams
            }
        }

        dispatch(setLoader({
            [info.loader]: true
        }));

        return fetch(process.env.REACT_APP_HOST_API + url, {
            method: !info.putUrl || (info.submittedData.customerFiles && info.submittedData.customerFiles.length) ? 'POST' : 'PUT',
            headers: commonHeader,
            body: body
        }).then(
            fetchHandler,
            error => {
                dispatch(manageError(true, info))
            }
        ).then(
            data => {
                let blockCallback = false;
                if (info && info.setDataInfo && data && data.code === 200) {
                    if (data.data && data.data.unitMail) {
                        handleUnitMailResponse(data.data.unitMail, dispatch, t)
                        dispatch(setFormSuccess());
                        dispatch(setLoader({
                            [info.loader]: false
                        }));
                        return;
                    }
                    if (!info.itemId || (!info.name || info.name !== 'prospects_update')) {
                        dispatch(resetSubResourceStore());
                    } else {
                        dispatch(setData(info.setDataInfo.name, info.setDataInfo.id, {
                            [info.setDataInfo.type]: data.data
                        }));
                    }

                    //Dispatch du nouvel ID de l'entreprise venant d'être créée
                    if (data.data && data.data.id && !info.prospectId) {
                        dispatch(setDataId(data.data.id, doIsSubmitAndCreate));
                    }

                    dispatch(setFormSuccess());
                    dispatch(setLoader({
                        [info.loader]: false
                    }));
                } else {
                    if (data) {
                        if (!info.type || "send_emailing" === info.type) {
                            dispatch(setFormError(data && data.data ? data.data : {}));
                            if (data && data.code === 500) {
                                dispatch(manageError(500, info, data.errorMessage))
                                if (info.type) {
                                    dispatch(setFormSuccess());
                                }
                                blockCallback = true;
                            }
                        }
                        if (info.type === 'uploadFile' && !data.error && !(data.code && data.code === 500)) {
                            dispatch(setError({msg: t('ged_success'), errorCode: 200}))
                        }
                        if (info.refreshGed) {
                            dispatch(resetGedData())
                        }
                        if (info.type === 'uploadSavedSearch' && data.data) {
                            const savedSearch = data.data;
                            UserService.setSavedSearch({
                                id: savedSearch.id,
                                searchType: savedSearch.searchType,
                                userId: savedSearch.userId,
                                customerId: savedSearch.customerId,
                                searchContent: savedSearch.searchContent,
                                name: savedSearch.name
                            })
                        }
                    }
                }
                if (info.callback && !blockCallback) {
                    let id = data.id;
                    if (!id) {
                        id = data[0] ? data[0].id : null;
                    }
                    info.callback(id);
                }
                dispatch(setLoader({
                    [info.loader]: false
                }));
            },
            error => {
                dispatch(setFormError({children: {empty: {errors: ["Erreur"]}}}));
                dispatch(manageError(error.status, info))
            }
        );
    };
}

/*Initialisation des variables utilisées ci-dessous par le AbortController()*/
window.searchController = null;

export function PostSearchService(info) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');
        const url = info.putUrl ? info.putUrl : info.url;
        dispatch(setLoader({
            [info.loader]: true
        }));
        /*Si une requête est en cours alors qu'une nouvelle vient d'être soumise*/
        if (window.searchController) {
            /*Supression de la requete en cours*/
            window.searchController.abort();
        }

        if ("AbortController" in window) {
            /*Définition d'une nouvelle instance de la class AbortController*/
            window.searchController = new AbortController();
        }

        if(info.columnsSelected) {
            info.submittedData['_selectedFields'] = info.columnsSelected.join(';')
        } else if(info.order_type && info.order_entity && !info.columnsSelected) {
            let selectFieldsArray = getViewOrdersOfEntityAndType(info.order_entity, info.order_type, info.subType);
            selectFieldsArray = selectFieldsArray[0] && selectFieldsArray[0].fields ? selectFieldsArray[0].fields.map(item => item.key) : [];
            info.submittedData['_selectedFields'] = selectFieldsArray.join(';')
        }

        const finalUrl = new URL(process.env.REACT_APP_HOST_API + url)
        if(info.searchInformations) {
            Object
                .keys(info.searchInformations)
                .filter(key => info.searchInformations[key] !== undefined)
                .filter(key => info.searchInformations[key] !== null)
                .forEach(key => {
                    finalUrl.searchParams.append(key, info.searchInformations[key])
                })
            ;
        }

        if(info.isAllIds) {
            finalUrl.searchParams.append('isAllIds', 'true');
        }

        if(info.isExport) {
            finalUrl.searchParams.append('isExport', 'true');
        }

        return fetch(finalUrl.toString(), {
            method: info.itemId ? 'PUT' : 'POST',
            /*Mise en place du lien entre la requête et le AbortController()*/
            signal: window.searchController.signal,
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
            body: JSON.stringify(info.submittedData)
        }).then(
            fetchHandler,
            error => {
                /*Si l'erreur est pas dû à l'abort du fetch*/
                if (error.name !== 'AbortError') {
                    /*Faire disparaître le loader et apparaître une erreur lors de la requete.*/
                    dispatch(manageError(true, info))
                }
            }
        ).then(
            data => {
                if (data) {
                    if (!info.isExport) {
                        if (!data.code || data.code !== 500) {
                            const datas = data.data ? data.data : data;
                            if(info.isAllIds) {
                                let datasHydrated = datas['hydra:member'] ?? datas;
                                let ids = datasHydrated.map(col => col.id);
                                info.setSelectedRows(ids);
                                info.setIsAllIds(false);
                            } else {
                                batch(() => {
                                    dispatch(setFilterSearch(info));
                                    dispatch(setData(info.name, 'tableSearch', {
                                        datas: datas['hydra:member'] ?? datas,
                                        length: data['hydra:totalItems'] ? data['hydra:totalItems'] : data['hydra:member'].length
                                    }));
                                });
                            }
                        } else {
                            dispatch(manageError(true, info));
                        }
                    } else {
                        info.exportCallback(data['hydra:member']);
                    }

                    dispatch(setLoader({
                        [info.loaderDatatable]: false,
                        [info.loader]: false
                    }));
                }
            },
            error => {
                /*Si l'erreur est pas dû à l'abort du fetch*/
                if (error.name !== 'AbortError') {
                    /*Faire disparaître le loader et apparaître une erreur lors de la requete.*/
                    errorAjaxHandler(dispatch, info, error);
                }
                dispatch(setLoader({
                    [info.loader]: false
                }));
            }
        );
    };
}

export const postAdmin = (url, errorCallback, successCallback, data, method = 'POST') => {
    fetch(`${process.env.REACT_APP_HOST_API}${url}`, {
        method: method,
        headers: {
            Accept: 'application/ld+json',
            'Content-Type': 'application/json',
            "X-POL-AUTH": 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(data),
    }).then(
        fetchHandler,
        error => {
            if (errorCallback) {
                errorCallback(error);
            }
        }
    ).then(
        data => {
            if (successCallback) {
                successCallback(data);
            }
        },
        error => {
            if (errorCallback) {
                errorCallback(error);
            }
        }
    );
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import ReactTooltip from "react-tooltip";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useQuoteIntranetButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/components/ProspectWonQuoteTabShow/components/ProspectWonQuoteIntranetButton/hooks/useQuoteIntranetButton";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/components/ProspectWonQuoteTabShow/components/ProspectWonQuoteIntranetButton/ProspectWonQuoteIntranetButton.scss';

export default function ProspectWonQuoteIntranetButton() {
    const {t} = usePolTranslation();
    const {
        isDisabled,
        tooltipMessage,
        isOpen,
        toggleOpen,
        components
    } = useQuoteIntranetButton();

    return (
        <>
            <span data-for="intranet" data-tip>
                <button type="button"
                        className="prospect-tab__prospect__header__action"
                        disabled={isDisabled}
                        onClick={toggleOpen}>
                    <PolIcon icon='file-export-solid'/>
                    <span>{t('intranet')}</span>
                </button>
            </span>
            <ReactTooltip id="intranet" effect="solid" disable={!tooltipMessage}>
                {tooltipMessage}
            </ReactTooltip>
            <PolPopup isOpen={isOpen}
                      closePopup={toggleOpen}
                      title={t('export_intranet_popup_title')}
                      sticked={{btns: true}}
                      buttonsRender={() => components.buttons}>
                {components.children}
            </PolPopup>
        </>
    )
}

import React from 'react';
import MicrosoftGraphResponse from "v4/pages/front/MicrosoftGraph/components/MicrosoftGraphResponse";

export default function MicrosoftGraphPage() {
    const fetchData = Object.fromEntries(new URLSearchParams(window.location.search));

    return (
        <MicrosoftGraphResponse fetchData={fetchData}/>
    );
}

import React, {useState} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux'
import LastVisits from "v4/features/front/lastVisits/components/LastVisits/LastVisits";
import UserService from "../../api/user/user";
import Navigation from "./_partials/navigation";
import User from "./_partials/user";
import {setPopup} from "../../actions/content";
import Logo from '../../img/logo';
import Sfi from '../../img/sfi-white';
import {PolIcon} from "../PolIcon/policon";

const Header = ({user, router, popup, t, togglePopup}) => {

    const [menuState, setMenuState] = useState(false);
    const [lastVisitsState, setLastVisitsState] = useState(false);
    const userToken = UserService.getTokens();
    let classLastVisits = '';

    if ((!user.token && !userToken) || router.isAdmin) {
        return null;
    }

    if (popup.target === 'last-visits' && popup.isOpen) {
        classLastVisits = ' last-visits-open';
    }

    return (
        <header className={`main-header wrap-content bg-env-${process.env.REACT_APP_ENV}`}>
            <div className={'row row-fluid justify-content-between'}>
                <div className={`col-2 d-md-none align-self-center main-header__picto ${popup.isOpen && popup.target === 'menu' ? 'close-icon__menu' : ''}`}>
                    {/*A optimiser lorsque l'icone 'bars' sera créée pour Icomoon*/}
                    {!popup.isOpen || !lastVisitsState ?
                        <PolIcon icon={'bars'} onClick={() => {
                            togglePopup({
                                isOpen: true,
                                target: 'menu'
                            });
                            setLastVisitsState(true)
                        }}/>
                        :
                        <PolIcon icon={'quit'} onClick={() => {
                            togglePopup({
                                isOpen: false,
                                target: 'menu'
                            });
                            setLastVisitsState(false)
                        }}/>
                    }
                </div>
                <div className={'col-8 col-md-auto user'}>
                    <User/>
                </div>
                <div className={'col-12 col-md-5 col-lg-4 col-xl-3 d-none d-md-block logo'}>
                    <div className={'row justify-content-end align-items-center'}>
                        <div className={'col-7 d-md-none d-xl-block'}>
                            <p className={'logo__slogan'}>{t('pol_slogan')}</p>
                            <div className={'row align-items-center justify-content-end logo__powered'}>
                                <div className={'col-auto col-fluid logo__powered__text'}>
                                    {t('developed_by')}
                                </div>
                                <div className={'col-auto logo__powered__img'}>
                                    <Sfi/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-auto loader'}>
                            <Logo/>
                        </div>
                    </div>
                </div>
                <div className={`col-2 d-md-none align-self-center main-header__picto ${popup.isOpen  && popup.target === 'last-visits' ? 'close-icon__lasts-visits' : ''}`}>
                {/*A optimiser lorsque l'icone 'tags' sera créée pour Icomoon*/}
                {!popup.isOpen || !menuState ?
                        <PolIcon icon={'tags'} onClick={() => {
                            togglePopup({
                                isOpen: true,
                                target: 'last-visits'
                            });
                            setMenuState(true)
                        }}/>
                    :
                        <PolIcon icon={'quit'} onClick={() => {
                            togglePopup({
                                isOpen: false,
                                target: 'last-visits'
                            });
                            setMenuState(false)
                        }}/>
                }
                </div>

            </div>

            <Navigation/>

            <div className={'last-visits' + classLastVisits}>
                <LastVisits/>
            </div>
        </header>
    )
};


const mapStateToProps = ({user, router, content: {popup}}) => ({
    user,
    router,
    popup
});


const mapDispatchToProps = dispatch => {
    return {
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Header))

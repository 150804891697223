import useAccessVoter from "v4/hooks/useAccessVoter";

export default function QuoteLinesVoter() {
    const {isGranted} = useAccessVoter();

    const canAccessQuoteLines = () => {
        return isGranted('quote_lines')
    }

    return ({canAccessQuoteLines})
}

import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_CONTACTS} from "v4/data/tabNames";

export default function ContactsListingRowActions({tab = {}, rowId, prospectId}) {
    let slug = TAB_CONTACTS;

    if ('slug' in tab && !tab.isDisabledDisplaySublist) {
        slug = tab.slug;
    }

    return (
        <Link to={`${routesBase.baseProspect}/${prospectId}/${slug}/${rowId}`} className="table-link"/>
    )
}

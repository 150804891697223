import React, {useContext} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useRouteMatch} from "react-router-dom";
import {AdminContext} from "v4/contexts/AdminContext";
import SavedRequestsList from "v4/pages/admin/SavedRequests/SavedRequestsList/SavedRequestsList";
import SavedRequestsForm from "v4/pages/admin/SavedRequests/SavedRequestsForm/SavedRequestsForm";

export default function SavedRequestsPage() {
    const {t} = usePolTranslation();
    const {path, params: {id}, isExact} = useRouteMatch();
    const isAddPage = path.split('/').pop() === 'add';
    const isEditPage = !!id && isExact;
    const isListPage = (!isAddPage && !isEditPage);
    const {asCustomer} = useContext(AdminContext);

    const getComponent = () => {
        if (isListPage) {
            return <SavedRequestsList customerId={asCustomer?.id}/>
        }

        return <SavedRequestsForm id={id} customerId={asCustomer?.id}/>
    }

    return (
        <>
            {asCustomer?.id ?
                (
                    <>
                        {getComponent()}
                    </>
                ) : (
                    <p>{t('no_customer_defined')}</p>
                )
            }
        </>
    )

}

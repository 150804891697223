import UserService from "api/user/user";
import moment from "moment";
import {useCallback, useEffect, useMemo} from 'react';
import {STATS_TASK_SUMMARY} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";

export default function useDashboardTasksSummary() {
    let [{data}, fetchQuotesSummary] = useFetch();

    const results = useMemo(() => {
        if (!data) return null;
        // put the "- Utilisateur désactivé" at the end of the list
        const dataCopy = window.structuredClone(data);
        const disabledUser = dataCopy.find(result => result.fullname === '- Utilisateur désactivé');
        if (disabledUser) {
            dataCopy.splice(dataCopy.indexOf(disabledUser), 1);
            dataCopy.push(disabledUser);
        }

        return dataCopy;
    }, [data]);

    useEffect(() => {
        fetchQuotesSummary({
            url: generateUrl(STATS_TASK_SUMMARY),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const remainingMonthsKeys = Object.keys(results?.[0] ?? {}).filter(key => key.includes('m_plus_'));

    const [
        total_closed,
        total_late,
        ...remainingMonths
    ] = results?.reduce((acc, row) => {
        const {
            closed,
            late,
            ...monthPlus
        } = row;

        return [
            parseFloat(acc[0]) + parseFloat((closed ?? '0')),
            parseFloat(acc[1]) + parseFloat((late ?? '0')),
            ...remainingMonthsKeys.map((key, index) => parseFloat(acc[index + 2]) + parseFloat((monthPlus[key] ?? '0')))
        ];
    }, [0, 0, ...Array.from({length: remainingMonthsKeys.length}).map(() => 0)]) ?? [];

    const tableInfos = {
        remaining_months: Array.from({length: 3})
            .map((_, index) => {
                const date = new Date();
                date.setMonth(date.getMonth() + index, 1);
                const monthString = date.toLocaleString(UserService.getLocale(), {month: 'long'});

                return monthString.charAt(0).toUpperCase() + monthString.slice(1);
            }),
        totalByColumn: {
            total_closed,
            total_late,
            ...remainingMonths.reduce((acc, value, index) => ({
                ...acc,
                [`total_m_plus_${index}`]: value
            }), {})
        }
    };

    const getPreSearch = useCallback((preSearchType, index = null) => {
        const preSearch = getTaskPreSearch(preSearchType);

        if (results?.[index]?.managedBy) {
            preSearch['managedBy'] = results[index].managedBy.split(',') ?? [];
        } else if (preSearchType.startsWith('total_')) {
            // all managedBy
            preSearch['managedBy'] = results?.map(({managedBy}) => managedBy.split(',')).flat();
        }

        return preSearch;
    }, [results]);

    return {data: results, tableInfos, getPreSearch};
}

function getTaskPreSearch(preSearchType) {
    const preSearch = {};

    if (preSearchType.includes('since_january')) {
        preSearch['closed'] = 'yes';
        preSearch['beginAt'] = {
            equal: ">",
            begin: moment().startOf('year').format('DD-MM-YYYY'),
            end: ""
        };
    } else if (preSearchType.includes('late')) {
        preSearch['closed'] = "no";
        preSearch['beginAt'] = {
            equal: "<",
            begin: "",
            end: moment().format('DD-MM-YYYY')
        };
    } else if (preSearchType.includes('m_plus_')) {

        const monthNumber = parseFloat(preSearchType.replace('total_', '').replace('m_plus_', ''));
        preSearch['closed'] = "no";
        preSearch['beginAt'] = {
            equal: "><",
            begin: moment().add(monthNumber, 'month').startOf('month').format('DD-MM-YYYY'),
            end: moment().add(monthNumber, 'month').endOf('month').format('DD-MM-YYYY')
        };
    }

    return preSearch;
}

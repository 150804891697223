import React, {Component} from 'react'
import {connect} from "react-redux";
import {Prompt, Redirect} from "react-router-dom";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {adminFetchHandler} from "../../../api/datas/datas";
import {PostService} from "../../../api/datas/post";
import {PolIcon} from "../../PolIcon/policon";
import ProspectType from "./prospectType";
import QuoteState from "./quoteState";
import TaskName from './taskName';
import TaskType from './taskType';


class FormListingAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: [],
            loader: true,
            redirect: null,
            isSubmitted: false,
            submitUrl: '/api/params',
            moreActions: [],
            customerId: '',
            isEdited: false
        }
    }

    handleLeaving = () => {
        const {t} = this.props;

        this.setState({
            isEdited: false
        })
        return t("customer_confirm_leave_form_alert");
    }

    handleChange = (key, group = false, value, oldValue) => {
        let {inputs} = this.state;

        if (value !== oldValue) {
            if (group) {
                inputs[group].value[key].value = value;
            } else {
                inputs[key].value = value;
            }
        }

        this.setState({
            inputs: inputs,
            isEdited: true
        });
    };

    handleMoreInfoChange = (fieldKey, key, group = false, forceValue) => {
        let {inputs} = this.state;

        inputs[group].value[key][fieldKey] = forceValue !== undefined ? forceValue : !inputs[group].value[key][fieldKey];

        this.setState({
            inputs: inputs,
            isEdited: true
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const {data, postService, t, type} = this.props;
        const {inputs, customerId} = this.state;
        const params = [];
        let cancel = false;

        inputs[1].value.forEach((item, key) => {
            if (item && item.value.length) {
                params.push({
                    name: item.value,
                    id: item?.id ?? null,
                    order: key + 1,
                    '@type': type,
                    isMailing: item.isMailing,
                    isTimestamped: item.isTimestamped,
                    isSynchroOutlook: item.isSynchroOutlook,
                    isReminder: item.isReminder,
                    isQuoteReminder: item.isQuoteReminder,
                    isWon: item.isWon,
                    isLost: item.isLost,
                    isAbandoned: item.isAbandoned,
                    isUnSelectable: item.isUnSelectable,
                    isDiffered: item.isDiffered,
                    inProgress: item.inProgress,
                    isClient: item.isClient,
                    customerId: customerId
                })
            } else {
                alert(t('one_element_empty'));
                cancel = true;
            }
        });

        if (cancel) {
            return;
        }

        this.setState({
            isEdited: false
        })

        let dataFetch = {
            id: type,
            name: inputs[0].value,
            code: data.code ? data.code : inputs[0].value.replace(' ', '-'),
            isActive: data.isActive ? data.isActive : true,
            customerId: customerId,
            params: params
        };

        postService({
            putUrl: '/api/params',
            submittedData: dataFetch,
            type: 'form-listing-admin',
            callback: () => {
                this.setState({
                    redirect: <Redirect to={`/administration/gestion_listes/`}/>
                });
            }
        }, t)

        return false;
    };

    componentDidMount() {
        const {initInputs, data, type} = this.props;
        let {moreActions} = this.state;
        let customerId = ''
        initInputs.forEach(input => {
            if (input.type !== 'array') {
                input.value = type;
                input.attr = {
                    readonly: true
                }
            } else if (data) {
                input.value = [];
                data.sort((a, b) => a.order - b.order).forEach(item => {
                    input.value.push({
                        value: item.value ? item.value : item.name,
                        id: item.id,
                    });
                    customerId = item.customerId
                });
            }
        });

        this.setState({
            inputs: initInputs,
            customerId: this.props.customerId ?? customerId ?? '',
        }, () => {
            if (!data || 0 === data.length) {
                this.setState({
                    moreActions: moreActions,
                })

                return;
            }

            if (type === 'TaskType') {
                data.forEach((item, keyData) => {
                    if (item.isTimestamped) {
                        this.handleMoreInfoChange('isTimestamped', keyData, 1)
                    }
                    if (item.isMailing) {
                        this.handleMoreInfoChange('isMailing', keyData, 1)
                    }
                    if (item.isSynchroOutlook) {
                        this.handleMoreInfoChange('isSynchroOutlook', keyData, 1)
                    }
                    if (item.isReminder) {
                        this.handleMoreInfoChange('isReminder', keyData, 1)
                    }
                    moreActions.push(<TaskType item={item}
                                               onChange={(fieldKey) => this.handleMoreInfoChange(fieldKey, keyData, 1)}/>)
                })
            }

            if (type === 'TaskName') {
                data.forEach((item, keyData) => {
                    if (item.isQuoteReminder) {
                        this.handleMoreInfoChange('isQuoteReminder', keyData, 1)
                    }
                    moreActions.push(<TaskName item={item}
                                               onChange={(fieldKey) => this.handleMoreInfoChange(fieldKey, keyData, 1)}/>)
                })
            }

            if (type === 'ProspectType') {
                data.forEach((item, keyData) => {
                    if (item.isClient) {
                        this.handleMoreInfoChange('isClient', keyData, 1)
                    }
                    moreActions.push(<ProspectType item={item}
                                               onChange={(fieldKey) => this.handleMoreInfoChange(fieldKey, keyData, 1)}/>)
                })
            }

            if (type === 'QuoteState') {
                data.forEach((item, keyData) => {
                    if (item.isWon) {
                        this.handleMoreInfoChange('isWon', keyData, 1)
                    }
                    if (item.isLost) {
                        this.handleMoreInfoChange('isLost', keyData, 1)
                    }
                    if (item.isAbandoned) {
                        this.handleMoreInfoChange('isAbandoned', keyData, 1)
                    }
                    if (item.isUnSelectable) {
                        this.handleMoreInfoChange('isUnSelectable', keyData, 1)
                    }
                    if (item.inProgress) {
                        this.handleMoreInfoChange('inProgress', keyData, 1)
                    }
                    if (item.isDiffered) {
                        this.handleMoreInfoChange('isDiffered', keyData, 1)
                    }
                    moreActions.push(<QuoteState item={item}
                                                 onChange={(fieldKey, value) => this.handleMoreInfoChange(fieldKey, keyData, 1, value)}/>)
                })
            }

            this.setState({
                moreActions: moreActions,
            })
        })
    };

    updateActions(type) {
        const {inputs} = this.state;
        const newActions = [];
        inputs[1].value.forEach((input, key) => {
            const props = {
                update: true,
                item: input,
                onChange: (fieldKey, value) => this.handleMoreInfoChange(fieldKey, key, 1, value)
            }

            if (type === 'TaskType') {
                newActions.push(<TaskType {...props}/>)
            }
            if (type === 'TaskName') {
                newActions.push(<TaskName {...props}/>)
            }
            if (type === 'QuoteState') {
                newActions.push(<QuoteState {...props}/>)
            }
        })

        this.setState({
            moreActions: newActions,
        })
    }

    render() {
        const {t, type} = this.props;
        let {inputs, redirect, moreActions, customerId, isEdited} = this.state;

        return (
            <>
                {redirect}

                <form className={`form form__scrollable`} onSubmit={this.handleSubmit}>
                    <Prompt message={this.handleLeaving} when={isEdited}/>

                    {inputs.map((input, key) => {
                        let fieldClass = input && input.value ? 'field-written' : '';
                        return (
                            <div className={`col-12 col-md-3 field ${fieldClass}`} key={key}>
                                {input.type !== 'array' ? (
                                    <>
                                        <input readOnly name={input.id} className={'field__input'} type={input.type}
                                               id={input.id}
                                               value={input.value ? input.value : ""}
                                        />
                                        <label className={'field__label'} htmlFor={input.id}>{t(input.label)}</label>
                                        <span className={'field__border'}> </span>
                                    </>
                                ) : (
                                    <>
                                        <ul className={'field__list'}>
                                            {input.value.map((item, keyItem) => {

                                                return (
                                                    <li key={keyItem} className={'field__item ' + type}>
                                                        <input name={item.id} className={'field__input'} type={'text'}
                                                               id={item.id}
                                                               value={item.value ? item.value : ""}
                                                               onChange={(event) => this.handleChange(keyItem, key, event.target.value, item.value)}/>
                                                        <div className={'field__item__delete'}>
                                                               <span onClick={() => {
                                                                   inputs[key].value.splice(keyItem, 1);
                                                                   this.setState({
                                                                       inputs,
                                                                   }, () => {
                                                                       this.updateActions(type)
                                                                   });
                                                               }}>
                                                            <PolIcon icon={'trash'}/>
                                                        </span>
                                                            {moreActions[keyItem]}
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <p className={'field__list__add'} onClick={() => {
                                            inputs[key].value.push({
                                                value: '',
                                                customerId: customerId
                                            });
                                            this.setState({
                                                inputs
                                            }, () => {
                                                this.updateActions(type)
                                            });
                                        }}>
                                            {t('ajouter_champ')}
                                        </p>
                                    </>
                                )}
                            </div>
                        )
                    })}

                    <div className={'row align-items-center justify-content-end'}>
                        <div className={'col-12 col-md-auto form__back'} onClick={() => this.setState({
                            redirect: <Redirect to={`/administration/gestion_listes/`}/>
                        })}>
                            <PolIcon icon={'chevron-circle-left'} className={'form__back__icon'}/>
                            {t('retour')}
                        </div>
                        <div className={`col-12 col-md-auto form__submit`}>
                            <button className={'btn btn-primary form__submit__btn'} onClick={() => this.handleSubmit}>
                                {t('enregistrer')}
                            </button>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
    return {
        postService: (info, t) => dispatch(PostService(info, t)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(FormListingAdmin));

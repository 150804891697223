import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ViewOrdersButtonListHover({
                                                      isActionOpen,
                                                      shouldOpenTop,
                                                      list,
                                                      currentItems,
                                                      disabledItems,
                                                      itemClassName = '',
                                                      titleCallback,
                                                      renderItem,
                                                      onClick
                                                  }) {
    const {t} = usePolTranslation();

    if (!isActionOpen) return null;
    currentItems = currentItems?.map(toString) ?? [];
    disabledItems = disabledItems?.map(toString) ?? [];

    return (
        <ul className="vo-action__list-hover"
            style={{...shouldOpenTop && {top: 'unset', bottom: '100%'}}}>
            {list.map((item, index) => {
                const isActive = currentItems.includes(toString(item));
                const isDisabled = disabledItems.includes(toString(item));
                const className = `
                    vo-action__list-hover__item
                    ${itemClassName}
                    ${isActive ? 'active' : ''}
                    ${isDisabled ? 'disabled' : ''}
                 `.trim();

                return (
                    <li key={index}
                        className={className}
                        {...(titleCallback && {title: titleCallback(item)})}
                        onClick={!isDisabled ? () => onClick(item) : undefined}>
                        {renderItem ? renderItem(item, isActive) : t(item)}
                    </li>
                )
            })}
        </ul>
    )
}

function toString(item) {
    return typeof item === 'object' ? JSON.stringify(item) : item.toString();
}

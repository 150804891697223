import React, {useEffect, useMemo} from 'react';
import {Controller, FormProvider, useFieldArray, useForm} from "react-hook-form";
import CustomReactSelect from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import Field from "v4/components/form/Field/Field";
import Loader from "v4/components/ui/Loader/Loader";
import Table from "v4/components/ui/Table/Table";
import {IMAGE, PRODUCT_SEARCH} from "v4/data/apiRoutes";
import {
    useQuotesProductsDetailsContext
} from "v4/features/front/products/components/QuotesProductsDetails/contexts/QuotesProductsDetailsContext/QuotesProductsDetailsContext";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";
import {getLSItem} from "v4/services/localStorage.service";
import {financial} from "v4/utils";

export default function QuotesProductsAdvancedSearch({togglePopup, handleAddProducts}) {
    const {t} = usePolTranslation();
    const [{data, isFinished}, fetchForm] = useFetch();
    const [{data: searchResults, isFinished: isSearchFinished, isLoading: isSearchLoading}, fetchSearch] = useFetch();
    const {addSuccessNotification} = useNotification();

    /* useForm pour le formulaire de recherche */
    const fieldsToRender = [
        {name: 'name', type: 'string'},
        {name: 'reference', type: 'string'},
        {name: 'categories', type: 'array'},
    ];
    const methods = useForm({
        defaultValues: fieldsToRender.reduce((prev, {name, type}) => {
            if (type === 'array') {
                prev[name] = [];

                return prev;
            }

            prev[name] = '';

            return prev;
        }, {})
    });

    /* useForm pour les lignes d'articles */
    const {control, watch, getValues, setValue} = useForm({mode: 'onChange'});
    const {fields, replace} = useFieldArray({control, name: 'products'});

    useEffect(() => {
        fetchForm({url: generateUrl(PRODUCT_SEARCH)})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inputsGroups = useMemo(() => {
        if (isFinished) {
            return data?.['hydra:member'];
        }
    }, [isFinished, data]);

    const handleSearch = () => {
        const values = methods.getValues();
        fetchSearch({
            url: generateUrl(PRODUCT_SEARCH, {disableTransformation: 1, itemsPerPage: 54}), //Patrice m'a dit que c'était bon
            config: {
                method: 'POST',
                body: JSON.stringify(values)
            }
        });
    }

    useEffect(() => {
        const keyDownEnterCallback = (e) => {
            if (e.key === 'Enter') {
                handleSearch();
            }
        }

        window.addEventListener('keydown', keyDownEnterCallback);

        return () => {
            window.removeEventListener('keydown', keyDownEnterCallback);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const defaultQuoteSections = getLSItem('user')?.defaultQuoteSections?.['hydra:member'] ?? [];
    const {sectionsNames} = useQuotesProductsDetailsContext();
    useEffect(() => {
        if (isSearchFinished && searchResults?.['hydra:member']) {
            replace(searchResults['hydra:member'].map(product => {
                const quoteSectionIndex = defaultQuoteSections.findIndex(section => section.id === product?.quoteSection);
                const existingSection = sectionsNames.find(({name}) => name.split('|')[1] === defaultQuoteSections[quoteSectionIndex]?.label);

                return {
                    productInfo: product,
                    quantity: 0,
                    price: product?.sellingPriceExcludingVat ?? 0,
                    sectionName: existingSection?.name ?? quoteSectionIndex !== -1 ? `${quoteSectionIndex}|${defaultQuoteSections[quoteSectionIndex].label}|${defaultQuoteSections[quoteSectionIndex].id}` : '',
                };
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearchFinished]);

    const createTableRows = () => {
        return fields.map(({productInfo}, index) => {
            return {
                image: productInfo?.imageId
                    ? <img src={generateUrl(IMAGE, {id: productInfo.imageId})}
                           alt={productInfo?.name}/>
                    : null,
                designation:
                    <div className="quotes-products__popup__body__table__desc">
                        <p>{productInfo?.name}</p>
                        <p>{t('ref')}: {productInfo?.reference}</p>
                        <p>{t('category')}: {productInfo?.category?.name ?? t('undefined')}</p>
                    </div>,
                quotes_unit_price:
                    <div className="quotes-products__popup__body__table__price">
                        <Controller name={`products.${index}.price`}
                                    control={control}
                                    render={({field: {onChange, name}}) => (
                                        <input className="input-field"
                                               type="number"
                                               name={name}
                                               value={getValues(`products.${index}.price`) ?? ''}
                                               onChange={onChange}/>
                                    )}/>
                        <span>€</span>
                    </div>,
                quantity:
                    <div className="quotes-products__popup__body__table__quantity">
                        <Controller name={`products.${index}.quantity`}
                                    control={control}
                                    render={({field: {onChange, name}}) => (
                                        <input className="input-field"
                                               type="number"
                                               min="0"
                                               name={name}
                                               value={getValues(`products.${index}.quantity`) ?? ''}
                                               onChange={onChange}/>
                                    )}/>
                    </div>,
                sectionName:
                    <div className="quotes-products__popup__body__table__section">
                        <Controller name={`products.${index}.sectionName`}
                                    control={control}
                                    render={({field: {onChange, name}}) => (
                                        <CustomReactSelect name={name}
                                                           value={getValues(`products.${index}.sectionName`)}
                                                           options={sectionChoices}
                                                           onChange={onChange}
                                                           placeholder={t('section_name')}/>
                                    )}/>
                    </div>,
                totalExcludingVat:
                    <p>
                        {financial(watch(`products.${index}.price`) * watch(`products.${index}.quantity`))}
                    </p>,
            }
        });
    }

    const getTotal = () => {
        return watch('products')?.filter(row => row.quantity > 0)?.reduce((prev, product) => {
            return prev + product.price * product.quantity;
        }, 0);
    }

    const handleAdd = () => {
        const products = getValues('products');
        const productsToAdd = products.filter(product => product.quantity > 0);

        handleAddProducts(productsToAdd);
        replace(products.map((field) => ({
            ...field,
            quantity: 0,
            price: field.productInfo.sellingPriceExcludingVat,
        })));

        if (productsToAdd.length > 0) {
            addSuccessNotification(t('products_added'));
        }
    }

    const handleAddAndClose = () => {
        handleAdd();
        togglePopup();
    }

    const handleTotalQuantities = ({currentTarget: {value}}) => {
        const newValue = isNaN(parseInt(value)) ? 0 : parseInt(value);
        fields.forEach((field, index) => {
            setValue(`products.${index}.quantity`, newValue);
        });
    }

    const sectionChoices = sectionsNames.filter(({isDefault}) => !isDefault).reduce((acc, {name}) => {
        const label = name.split('|')[1];
        acc.push({
            label: label !== '' ? label : t('empty_name'),
            value: name
        });

        return acc;
    }, []);
    if (defaultQuoteSections.length > 0) {
        defaultQuoteSections.forEach(({id, label}) => {
            if (!sectionChoices.some(({value}) => value.split('|')[1] === label)) {
                sectionChoices.push({
                    label: label,
                    value: `${sectionChoices.length}|${label}|${id}`
                });
            }
        });
    }

    const handleTotalSections = (value) => {
        fields.forEach((field, index) => {
            setValue(`products.${index}.sectionName`, value);
        });
    }

    return <>
        <FormProvider {...methods}>
            <div className="quotes-products__popup__body__add-form advanced">
                {!isFinished && <div className="loader"><Loader message={t('loading')}/></div>}
                {inputsGroups?.results && (
                    Object.values(inputsGroups.results).map(({properties}) =>
                        properties
                            .filter(({key}) => fieldsToRender.some(({name}) => name === key))
                            .map(input => <Field name={input.key} {...input} placeholder={t(input.key)}/>))
                )}
            </div>
            <div className="quotes-products__popup__body__buttons button-search-container">
                {
                    inputsGroups && (
                        <button type="button" className="quotes-products__popup__body__button-return"
                                onClick={handleSearch}>
                            {t('search_product')}
                        </button>
                    )
                }
            </div>
        </FormProvider>
        <div className="quotes-products__popup__body__search-count-results">
            {
                searchResults?.['hydra:totalItems'] > 1
                    ? <p>{t('search_results', {count: searchResults?.['hydra:totalItems']})}</p>
                    : searchResults?.['hydra:totalItems'] === 1
                        ? <p>{t('search_result')}</p>
                        : <p>{t('no_results')}</p>
            }
        </div>
        <div className="quotes-products__popup__body__table-loader-container">
            <div className="quotes-products__popup__body__table-container">
                <Table
                    theadData={['', 'designation', 'quotes_unit_price', 'quantity', 'section_name', 'excl_taxes_amount']}
                    tableRows={createTableRows()} isLoading={isSearchLoading}
                    className="quotes-products__popup__body__table"/>
            </div>
        </div>
        {(searchResults?.['hydra:member'] && isSearchFinished) && (
            <div className="quotes-products__popup__body__sub-table">
                <div className="quotes-products__popup__body__total-quantity">
                    <label htmlFor="total-quantities">{t('quantity_all_lines')}</label>
                    <input type="number" id="total-quantities" className="input-field" min="0"
                           onChange={handleTotalQuantities}/>
                </div>
                <div className="quotes-products__popup__body__total-sections">
                    <label htmlFor="total-sections">{t('section_all_lines')}</label>
                    <Controller name="total-sections"
                                control={control} render={({field: {onChange, name, value}}) => (
                        <CustomReactSelect name={name}
                                           value={value}
                                           id="total-sections"
                                           options={sectionChoices}
                                           onChange={(val) => {
                                               onChange(val);
                                               handleTotalSections(val);
                                           }}/>
                    )}/>
                </div>
                <div className="quotes-products__popup__body__total">
                    <p>{t('totalExcludingVat')}</p>
                    <p>{financial(getTotal())}</p>
                </div>
            </div>
        )}
        <div className="quotes-products__popup__body__buttons">
            <button type="button" className="quotes-products__popup__body__button-return"
                    onClick={togglePopup}>
                {t('retour')}
            </button>
            <button type="button" className="quotes-products__popup__body__button-validate"
                    onClick={handleAdd}>
                {t(searchResults?.['hydra:totalItems'] > 1 ? 'add_free_products' : 'add_free_product')}
            </button>
            <button type="button" className="quotes-products__popup__body__button-validate"
                    onClick={handleAddAndClose}>
                {t('add_and_close')}
            </button>
        </div>
    </>
}

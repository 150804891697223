import React, {Component} from 'react'
import UserService from "../../../api/user/user";
import {fetchHandler, manageError} from "../../../api/datas/datas";
import {getApiUrl} from "../../../routes";
import ErrorBoundary from "../../../components/Error/errorBoundary";
import SearchDatatables from "../../../components/Datatables/searchDatatables";
import Loading from "components/Loading/Loading";
import {routesBase} from "v4/data/appRoutes";

export default class ListingSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datas: [],
            columnsP: [],
            isMounted: false,
            infoP: {
            name: 'prospects',
                id: 'search',
                type: 'datas',
                url: getApiUrl('prospects', 'list'),
                urlId: getApiUrl('prospects', 'id'),
                loader: 'table-prospects',
                order_type: 'list',
                order_entity: 'Prospect',
                redirect: null,
            },
            infoC: {
                name: 'contacts',
                id: 'search',
                type: 'datas',
                url: getApiUrl('contacts', 'list'),
                urlId: getApiUrl('contacts', 'id'),
                loader: 'table-contact',
                route: routesBase.baseContact,
                order_type: 'list',
                order_entity: 'Contact',
            },
            infoT: {
                name: 'actions',
                id: 'search',
                type: 'datas',
                url: getApiUrl('tasks', 'list'),
                urlId: getApiUrl('tasks', 'id'),
                loader: 'table-actions',
                route: routesBase.baseTask,
                order_type: 'list',
                order_entity: 'Task',
            },
            infoQ: {
                name: 'devis',
                id: 'search',
                type: 'datas',
                url: getApiUrl('devis', 'list'),
                loader: 'table-devis',
                route: routesBase.baseQuote,
                order_type: 'list',
                order_entity: 'Quote',
            },
        }
    }

    componentDidMount() {
        const search = UserService.getGlobalSearch();

        this.getData(search);
    };

    getData = (search) => {
        const user = UserService.getUser();
        const token = user.token ? user.token : localStorage.getItem('token');

        fetch(process.env.REACT_APP_HOST_API + '/api/global/search/form', {
            method: 'POST',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
            body: JSON.stringify(search),
        }).then(
            fetchHandler,
            error => {
                manageError(true, null)
            }
        ).then(
            data => {
                if (data) {
                    this.setState({
                        datas: data.data,
                        isMounted: true,
                    });
                } else {
                    manageError(500, null)
                }
            },
            error => {
                manageError(error.status, null);
            }
        );
    };

    render() {
        const {datas, infoP, infoC, infoT, infoQ, isMounted} = this.state;

        return (
            <>
                {!isMounted
                    ? <Loading specificClass={"form-loader"}/>
                    : <div className={'page page-list main-content__body__slide'}>
                        <div className={'page-list__content'}>
                            <ErrorBoundary>
                                <SearchDatatables title={'Prospect'} className={'prospects'} info={infoP} isSearch={true}
                                        datasSearch={datas['prospects'] ? datas['prospects'] : []}/>
                                <SearchDatatables title={'Contact'} className={'contacts'} info={infoC} isSearch={true}
                                            datasSearch={datas['contacts'] ? datas['contacts'] : []}/>
                                <SearchDatatables title={'Actions'} className={'actions'} info={infoT} isSearch={true}
                                            datasSearch={datas['tasks'] ? datas['tasks'] : []}/>
                                <SearchDatatables title={'Devis'} className={'devis'} info={infoQ} isSearch={true}
                                            datasSearch={datas['quotes'] ? datas['quotes'] : []}/>
                            </ErrorBoundary>
                        </div>
                    </div>
                }
            </>
        )
    }
}

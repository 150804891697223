import {captureException, ErrorBoundary} from "@sentry/react";
import React, {useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import Loader from "v4/components/ui/Loader/Loader";
import PreventRedirect from "v4/components/utils/PreventRedirect/PreventRedirect";
import {MAIL_TYPE_ITEM, QUOTE_ITEM, UNIT_MAIL} from "v4/data/apiRoutes";
import {routesBase} from "v4/data/appRoutes";
import {TAB_CONTACTS} from "v4/data/tabNames";
import ImportProductsDocsButtons
    from "v4/features/front/generatePdf/components/ImportProductsDocsButtons/ImportProductsDocsButtons";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import FormEntityLayoutFormActions
    from "v4/layouts/FormEntityLayout/components/FormEntityLayoutForm/components/FormEntityLayoutFormActions/FormEntityLayoutFormActions";
import MailField
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsTab/components/ProspectContactsTabMail/components/ProspectContactsTabMailForm/components/MailField/MailField";
import {generateUrl} from "v4/services/api.service";

export default function ProspectContactsTabMailForm({inputGroups}) {
    const {t} = usePolTranslation();
    const [quoteId, setQuoteId] = useState(null);
    const [{data: submitData, isLoading}, fetchSave] = useFetch();
    const [{data}, fetchMailType] = useFetch();
    const history = useHistory();

    const tinyMceRef = useRef(null);
    const filePondRef = useRef(null);

    const methods = useForm({
        mode: 'onChange',
        defaultValues: getInitialValues(inputGroups),
    });

    const onSubmit = data => {
        if (methods.formState.isDirty) {
            fetchSave({
                url: generateUrl(UNIT_MAIL, {id: inputGroups.prospectId.value}),
                config: {
                    method: 'POST',
                    body: createFormData(data),
                }
            })
        } else {
            history.goBack();
        }
    };

    const {addSuccessNotification} = useNotification();

    useEffect(() => {
        if (submitData?.code === 200) {
            history.push(`${routesBase.baseProspect}/${inputGroups.prospectId.value}/${TAB_CONTACTS}`, {unselect: true});
            addSuccessNotification(t('Vos mails ont bien été envoyés')); /* TODO: traduction */
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitData]);

    const onMailTypeChange = (value) => {
        const params = {};
        if (typeof value === 'object') {
            params.id = value.mailType?.value?.value ?? value.mailType?.value ?? value.mailType;
        } else {
            params.id = value;
        }
        fetchMailType({
            url: generateUrl(MAIL_TYPE_ITEM, params),
        });
    }

    useEffect(() => {
        if (data) {
            methods.setValue('object', data.object);
            methods.setValue('content', data.content);
        }
    }, [data, methods]);

    useEffect(() => {
        const {state, search} = history.location;

        let {quoteId} = state ?? {};
        const {quoteName, quotePdfFile, ...restState} = state ?? {};
        const searchParams = new URLSearchParams(search);

        if (!quoteId) {
            quoteId = searchParams.get('quoteId');
        }

        if (quoteId) {
            methods.setValue('quoteId', quoteId);
            setQuoteId(quoteId);
        }
        quoteName && methods.setValue('object', quoteName);

        const setFilesInFilePond = (files) => {
            if (!files) return;

            Promise.all(files.map(({downloadLink}) => fetch(downloadLink)))
                .then(responses => Promise.all(responses.map(res => res.blob())))
                .then(blobs => filePondRef.current.addFiles(
                    blobs.map((blob, index) =>
                        new File([blob], files[index]?.fileLabel ?? files[index]?.fileName ?? `${t('quote')}.pdf`, {type: blob.type})
                    )
                ))
                .catch(err => captureException(err, {extra: {message: 'À l\'initialisation du mail avec les fichiers du devis'}}));
        }

        if (quoteId && quotePdfFile && filePondRef.current) {
            setFilesInFilePond(quotePdfFile);
            history.replace({state: restState});
        }

        if (quoteId && !quotePdfFile && filePondRef.current) {
            getQuote(quoteId).then(quoteData => {
                setFilesInFilePond(quoteData?.customerFiles?.['hydra:member'] ?? []);
                if (methods.getValues('object') === '') {
                    methods.setValue('object', quoteData?.name ?? '');
                }

                const contactIds = methods.getValues('contactIds');
                if (Array.isArray(contactIds) && contactIds.length === 0) {
                    methods.setValue('contactIds', [quoteData?.contactId] ?? []);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filePondRef, methods]);

    const onClickDisplay = ({currentTarget}) => {
        const editor = tinyMceRef.current.editor;
        // add currentTarget.innerHTML to tinymce editor at the current position
        editor.selection.setContent(currentTarget.innerHTML);
        // focus on tinymce editor
        editor.focus();
    }

    const addSignature = (editor) => {
        editor = editor?.selection ? editor : tinyMceRef.current.editor;
        // add signature at the end of the editor
        editor.selection.select(editor.getBody(), true);
        editor.selection.collapse(false);
        editor.selection.setContent(inputGroups.content?.attr?.['data-sign'] ?? '');
        // focus on tinymce editor
        editor.focus();
    }

    useEffect(() => {
        if (inputGroups.mailType?.value) {
            onMailTypeChange(inputGroups.mailType?.value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [hasFilesErrors, setHasFilesErrors] = useState(false);
    useEffect(() => {
        const subscription = methods.watch(({customerFiles}) => {
            const {files, file} = customerFiles ?? {};
            if (files) {
                setHasFilesErrors(files.some(file => file?.error));
            } else {
                setHasFilesErrors(!!file?.error);
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.watch]);

    return (
        <ErrorBoundary>
            <PreventRedirect when={methods.formState.isDirty && !methods.formState.isSubmitSuccessful}
                             message={t('customer_confirm_leave_form_alert')}/>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}
                      className={`form form__scrollable form__edit--view form-v4`}>
                    {(Object.keys(inputGroups).length === 0 || isLoading) && (
                        <div className="form-v4__loader">
                            <Loader message={t('loading')}/>
                        </div>
                    )}
                    <FormEntityLayoutFormActions onBack={history.goBack}
                                                 saveLabel="send_mail"
                                                 saveIcon="mails-send"
                                                 isSaveDisabled={hasFilesErrors || methods.formState.isSubmitting}/>
                    <div className="input-field-group-wrapper">
                        {
                            Object.keys(inputGroups).length > 0 && (
                                <div className="row row-fluid">
                                    <div className="col-md-12">
                                        <MailField input={inputGroups.object}
                                                   name="object"/>
                                    </div>
                                    <div className="col-md-6">
                                        <MailField input={inputGroups.contactIds}
                                                   name="contactIds"/>
                                    </div>
                                    <div className="col-md-6">
                                        <MailField input={inputGroups.mailType}
                                                   name="mailType"
                                                   onFieldChange={onMailTypeChange}/>
                                    </div>
                                    <div className="col-md-12">
                                        <MailField input={inputGroups.tagsToReplace}
                                                   name="tagsToReplace"
                                                   onFieldChange={onClickDisplay}/>
                                    </div>
                                    <div className="col-md-12">
                                        <MailField input={inputGroups.content}
                                                   name="content"
                                                   specificParams={{onInit: addSignature}}
                                                   ref={tinyMceRef}/>
                                        {inputGroups.content?.attr?.['data-sign'] && (
                                            <span className="btn-fl-right" onClick={addSignature}>
                                                {t('add_sign')}
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        {(quoteId && filePondRef.current) && <ImportProductsDocsButtons quoteId={quoteId}
                                                                                                        filePondInstance={filePondRef.current}/>}
                                        <MailField input={inputGroups.customerFiles}
                                                   ref={filePondRef}
                                                   name="customerFiles"/>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </form>
            </FormProvider>
        </ErrorBoundary>
    )
}

function getInitialValues(inputs) {
    return Object.entries(inputs).reduce((acc, [key, values]) => {
        const {type, value} = values;
        if (type === 'array') {
            acc[key] = value?.join(',') // array to string
                .replace('undefined', '') // replace undefined because of no v4 routes
                .split(',') // string to array
                .filter(Boolean); // remove empty values
            return acc;
        }
        acc[key] = value ?? '';
        return acc;
    }, {});
}

function createFormData(data) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if (value?.file !== undefined || value?.files !== undefined || value?.['shouldDelete'] !== undefined) {
            if (value?.file instanceof File) {
                formData.append(`${key}[customerFile][file]`, value.file);
            }
            if (value?.files?.[0] instanceof File) {
                value.files.forEach(file => formData.append(`${key}[][customerFile][file]`, file));
            }
            if (value?.shouldDelete) {
                if (Array.isArray(value.shouldDelete)) {
                    value.shouldDelete.forEach(
                        (val, index) => {
                            if (val) {
                                formData.append(`${key}[${index}][shouldDelete]`, val)
                            }
                        }
                    );
                } else {
                    formData.append(`${key}[shouldDelete]`, value.shouldDelete);
                }
            }
        } else {
            if (Array.isArray(value)) {
                value.forEach(v => formData.append(`${key}[]`, v));
            } else if (value?.id) {
                formData.append(key, value.id);
            } else if (value) {
                formData.append(key, value);
            }
        }
    }
    return formData;
}

async function getQuote(quoteId) {
    const quote = await fetch(generateUrl(QUOTE_ITEM, {id: quoteId}));
    const quoteData = await quote.json();

    return quoteData ?? {};
}

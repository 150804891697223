import React, {useMemo} from 'react';
import {
    PROSPECT_BUSINESS_ADD,
    PROSPECT_BUSINESS_FORM,
    PROSPECT_INDIVIDUAL_ADD,
    PROSPECT_INDIVIDUAL_FORM
} from "v4/data/apiRoutes";
import {routesBase} from "v4/data/appRoutes";
import {TAB_CONTACTS} from "v4/data/tabNames";
import FormEntityLayout from "v4/layouts/FormEntityLayout/FormEntityLayout";
import FullPageFormLayout from "v4/layouts/FullPageFormLayout/FullPageFormLayout";
import ProspectsAddAdditionalButtons
    from "v4/pages/front/Prospects/components/ProspectsAdd/components/ProspectsAddAdditionalButtons/ProspectsAddAdditionalButtons";

export default function ProspectsAdd({type, taskId}) {
    const isBusiness = type === 'business';

    const fetchFormParameters = useMemo(() => ({
        taskId
    }), [taskId]);

    return <FullPageFormLayout title={isBusiness ? "ajouter_prospect_entreprise" : "add_prospect"}>
        <FormEntityLayout action="add"
                          formRouteName={isBusiness ? PROSPECT_BUSINESS_FORM : PROSPECT_INDIVIDUAL_FORM}
                          submitRouteName={isBusiness ? PROSPECT_BUSINESS_ADD : PROSPECT_INDIVIDUAL_ADD}
                          fetchFormParameters={fetchFormParameters}
                          additionalButtons={
                              isBusiness
                                  ? () => <ProspectsAddAdditionalButtons/>
                                  : null
                          }
                          whenSubmitted={whenSubmitted}/>
    </FullPageFormLayout>
}

function whenSubmitted(history, data, methods) {
    const {redirectToContact} = methods?.getValues() ?? {};
    const {id: prospectId} = data ?? {};
    if (redirectToContact?.value && prospectId) {
        history.push(`${routesBase.baseProspect}/${prospectId}/${TAB_CONTACTS}/add`, {hasToRefresh: true});

        return;
    }

    history.replace(`/${history.location.pathname.split('/')[1]}`, {hasToRefresh: true});
}

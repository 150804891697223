import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect, useRef} from 'react';
import CustomerFileButton
    from "v4/components/ui/PolTable/components/PolTableTd/components/PolTableTdCustomerFiles/components/CustomerFileButton/CustomerFileButton";
import 'v4/components/ui/PolTable/components/PolTableTd/components/PolTableTdCustomerFiles/PolTableTdCustomerFiles.scss';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";


export default function PolTableTdCustomerFiles({files}) {
    const {t} = usePolTranslation();
    const {files: filesArray} = JSON.parse(files);
    const dropdownRef = useRef(null);

    const [isOpen, toggleOpen] = useToggle();
    const handleClick = e => e.stopPropagation();

    const handleMouseLeave = e => {
        if (e.target.classList.contains('open')) {
            toggleOpen(false);
        }
    }

    useEffect(() => {
        let dropdownElement = dropdownRef.current;

        if (dropdownElement) {
            dropdownElement.addEventListener("mouseleave", handleMouseLeave);

            return () => {
                dropdownElement.removeEventListener("mouseleave", handleMouseLeave);
            };
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownRef.current]);

    if (filesArray?.length > 1) {
        return (
            <div className={`customer-files__dropdown ${isOpen ? 'open' : ''}`} onClick={toggleOpen} ref={dropdownRef}>
                <span>{t('product_files')} <PolIcon icon="arrow-right"/></span>
                <ul>
                    {filesArray.map((file, index) => (
                        <li key={index} onClick={handleClick}>
                            <CustomerFileButton file={file}/>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    return filesArray?.[0] ? <CustomerFileButton file={filesArray[0]}/> : null;
}

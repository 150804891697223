import React, {Component} from 'react';
import {connect} from "react-redux";
import {getLengthClassname} from "../../api/datas/datas";
import {Input} from "../Input/input";
import {PolIcon} from "../PolIcon/policon";
import {setPopup} from "../../actions/content";
import {DeleteService} from "../../api/datas/delete";
import ReactTooltip from "react-tooltip";
import QuotesProductsDetails from "../../v4/features/front/products/components/QuotesProductsDetails/QuotesProductsDetails";
import ErrorBoundary from "../Error/errorBoundary";
import DropdownOrders, {CONDITION_VALUE, TASK_TYPE} from "./dropdownOrders";
import {withPolTranslation} from "../../v4/hooks/usePolTranslation";

class ViewForm extends Component {
    getFieldClass = (inputData) => {
        if (inputData.isOpen) {
            return 'field-written';
        }

        if (inputData && ((inputData.type !== 'array' && inputData.value)
            || (inputData.type === 'array' && Array.isArray(inputData.value) && inputData.value.length > 0))) {
            return 'field-written';
        }

        return '';
    }

    /**
     * Renvoie le type de l'action actuellement modifiée
     * @returns {array|null}
     */
    getTaskTypeInputs = () => {
        const {inputs} = this.props;

        let taskTypeInputs = null;
        const inputsFiltered = inputs.map(input => {
            if (input.fields) {
                return input.fields.filter(obj => {
                    return (obj.key === TASK_TYPE || obj.keyItem === TASK_TYPE)
                })
            }
            return null;
        })

        if(inputsFiltered && inputsFiltered.length) {
            inputsFiltered.forEach(type => {
                if (type[0] && type[0].input) {
                    taskTypeInputs = type[0].input;
                }
            });

            return taskTypeInputs;
        }

        return null;
    }

    /**
     * Renvoie true si les conditions d'affichage de l'input sont respectées (fonctionne uniquement pour les taskTypes)
     * @param {Object} input
     * @returns {boolean}
     */
    controlConditionOfInput = (input) => {
        if(input[CONDITION_VALUE]) {
            const taskTypeInputs = this.getTaskTypeInputs();

            if (null !== taskTypeInputs) {
                return input[CONDITION_VALUE] === taskTypeInputs.value;
            }
        }

        return true;
    }

    render() {
        const {
            t,
            inputs,
            handleChange,
            setQuoteLinesValues,
            handleFilesSizeExceeded,
            adminOrder,
            onDragStart,
            onDragOver,
            onDragEnd,
            modifyLength,
            hideElement,
            showElement,
            changeTitle,
            typeForm,
            setPopup,
            deleteService,
            updateInput,
            disableViewOrder,
            setPopupIsOpen,
            setSelectIsFocused,
            setValueType,
            extendedSearchForm,
            doIsSearchForm,
            itemId,
            viewSelected = null
        } = this.props;

        const icons = {
            percent: 'percent',
            money: 'euro-sign',
            phone: 'phone',
            website: 'globe',
            email: 'envelope',
            html: 'envelope-open-text',
            files: 'file-bis',
            texte: ''
        }

        if (!inputs || !inputs.length) {
            return null;
        }
        const isSearch = inputs[0].type === 'search' || typeForm === 'search'

        return (
            <>
                <div className={'row form__scrollable__container'}>
                    <ReactTooltip effect="solid"/>
                    {inputs.map((group, key) => {
                        const {width, label, isHidden = false} = group;
                        let classNameLength = getLengthClassname(width);
                        let {fields} = group;
                        let groupProperties = {};
                        let specificClass = isHidden ? 'd-none' : '';
                        let visible = group.visible;
                        const notToShow = ['taskIds', 'quoteIds', 'prospectIds', 'isReverse'];

                        if (group.id === 'unmapped' && fields.filter((item) => notToShow.indexOf(item.key) === -1).length === 0) {
                            visible = false
                        }

                        if (adminOrder) {
                            groupProperties = {
                                draggable: true,
                                onDragOver: () => onDragOver(key),
                                onDragStart: (e) => onDragStart(e, key),
                                onDragEnd: (e) => onDragEnd(e, 'group'),
                            }
                        } else if (!fields || !fields.length || !fields.filter(item => item.input).length || (!adminOrder && !visible && !disableViewOrder)) {
                            return null;
                        }

                        if (doIsSearchForm) {
                            classNameLength = extendedSearchForm ? classNameLength : 'col-md-12'
                        }

                        return (
                            <div key={key} className={`${classNameLength} ${specificClass} group`}>
                                <div>
                                    <fieldset className={`group__content`} {...groupProperties}>

                                        {adminOrder && visible ? (
                                            <div className={'actions actions-group'}>
                                                <div className={'actions__item'}
                                                     onClick={() => modifyLength('minus', key)}
                                                     data-tip={t('set_minus')}
                                                >
                                                    <PolIcon icon={'minus'}
                                                             className={'actions__icon'}/>
                                                </div>
                                                <div className={'actions__item'}
                                                     onClick={() => modifyLength('more', key)}
                                                     data-tip={t('set_more')}
                                                >
                                                    <PolIcon
                                                        icon={'plus'}
                                                        className={'actions__icon'}/>
                                                </div>
                                                {group.id !== 'unmapped' ? (
                                                    <div className={'actions__item'}
                                                         onClick={() => hideElement(key, null)}
                                                         data-tip={t('hide_show_element')}
                                                    >
                                                        <PolIcon
                                                            icon={'eye-slash'}
                                                            className={'actions__icon'}/>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}

                                        <div className={'row row-fluid'}>
                                            <div className={'col-12 group__title'}>
                                                {(label && visible) || adminOrder ? (
                                                    <>
                                                        {adminOrder ? (
                                                            <input value={label ? t(label) : ''}
                                                                   onChange={(e) => changeTitle(e, key)}
                                                                   className={'group__title__input'}/>
                                                        ) : (
                                                            <span className={'group__title__text'}>
                                                                {label ? label !== 'unmapped_fields' ? t(label) : '' : t('no_title')}
                                                        </span>
                                                        )}
                                                    </>
                                                ) : null}
                                            </div>
                                            <div className={`group__container ${adminOrder ? 'group__row' : ''} row`}>
                                                {fields.map((input, groupKey) => {
                                                    let inputData = input.input;
                                                    if (inputData) {
                                                        inputData = {
                                                            ...inputData,
                                                            isRequired: input.isRequired,
                                                            isReadOnly: input.isReadOnly
                                                        }
                                                    }

                                                    if (!inputData) {
                                                        return null;
                                                    }

                                                    let fieldClass = this.getFieldClass(inputData);
                                                    fieldClass += inputData && inputData.type === 'hidden' ? ' d-none' : '';
                                                    fieldClass += inputData && inputData.error ? ' field-error' : '';

                                                    let itemProperties = {};
                                                    if (adminOrder) {
                                                        itemProperties = {
                                                            onDragOver: () => onDragOver(key, groupKey),
                                                            onDragStart: (e) => onDragStart(e, key, groupKey),
                                                            onDragEnd: (e) => onDragEnd(e, 'item'),
                                                            draggable: true
                                                        }
                                                    }
                                                    const classLength = doIsSearchForm && !extendedSearchForm ? 'col-md-12' : getLengthClassname(input.width);
                                                    fieldClass += ` ${classLength} `
                                                    let checkboxClass = inputData.widget === "checkbox" || (inputData.attr && inputData.attr['input-type'] && inputData.attr['input-type'] === 'checkbox') ? 'field__checkbox' : '';
                                                    checkboxClass += inputData.attr && inputData.attr['input-type'] && inputData.attr['input-type'] === 'checkbox' ? ' field__checkbox--multiple' : '';
                                                    checkboxClass += inputData.widget === "choice-expanded" ? 'field__radio' : '';

                                                    // Don't ask.
                                                    const isRequired = input?.isRequired || input?.input?.isRequired || true === input?.required || true === input?.input.required;
                                                    const isUnique   = input?.isUnique || input?.input?.isUnique;
                                                    const isSpecific = input?.key?.startsWith('sf_') || input?.key?.startsWith('sf.' || input?.key?.startsWith('specific')) || input?.input?.attr?.['data-type'] === 'specific-fields';

                                                    return <React.Fragment key={groupKey}>
                                                        <div
                                                            className={`${fieldClass} ${inputData.keyItem === 'potential' && doIsSearchForm ? 'field__potential' : ''} ${checkboxClass} group__item field`}
                                                            onFocus={(event) => {
                                                                event.currentTarget.classList.add('field-focused')
                                                            }}
                                                            onBlur={(event) => {
                                                                event.currentTarget.classList.remove('field-focused')
                                                            }}
                                                        >
                                                            {adminOrder
                                                                ? (
                                                                    <div className={'group__item__content '}>
                                                                        <div
                                                                            className={`group__item__drag ${isRequired ? 'group__item__required' : ''}${isUnique ? ' group__item__unique' : ''}`} {...itemProperties}>
                                                                            {isSpecific? (
                                                                                <PolIcon
                                                                                    icon={'engrenage'}
                                                                                    className={'actions__type'}
                                                                                    data-tip={t('specific_field')}
                                                                                />) : null}
                                                                            {input.valueType && input.valueType !== 'texte' ? (
                                                                                <PolIcon
                                                                                    icon={icons[input.valueType]}
                                                                                    className={'actions__type'}/>) : null}
                                                                            {input.isReadOnly ? (
                                                                                <PolIcon
                                                                                    icon={'eye'}
                                                                                    className={'actions__type'}
                                                                                    data-type={t('readOnly')}
                                                                                />) : null}
                                                                            {isRequired ? '* ' : null}
                                                                            {input
                                                                            && input.input
                                                                            && input.input.attr
                                                                            && input.input.attr['data-type']
                                                                            && input.input.attr['data-type'] === 'specific-fields'
                                                                                ?
                                                                                <i>{input.input.title}</i> : input.key.search('deliveryAddress') !== -1 ? t(inputData.title) + ' (' + t('delivery') + ')' : t(inputData.title)}

                                                                            <div className={'actions'}>
                                                                                {adminOrder ? (
                                                                                    <DropdownOrders input={input}
                                                                                                    setValueType={(type, callback, value = null) => {
                                                                                                        setValueType(key, groupKey, type, value)
                                                                                                        if (null !== callback) {
                                                                                                            callback();
                                                                                                        }
                                                                                                    }}
                                                                                                    viewSelected={viewSelected}
                                                                                                    taskTypeInputs={this.getTaskTypeInputs()}
                                                                                    />) : null}
                                                                                <div className={'actions__item'}
                                                                                     onClick={() => modifyLength('minus', key, groupKey)}
                                                                                     data-tip={t('set_minus')}
                                                                                >
                                                                                    <PolIcon icon={'minus'}
                                                                                             className={'actions__icon'}/>
                                                                                </div>
                                                                                <div className={'actions__item'}
                                                                                     onClick={() => modifyLength('more', key, groupKey)}
                                                                                     data-tip={t('set_more')}
                                                                                >
                                                                                    <PolIcon
                                                                                        icon={'plus'}
                                                                                        className={'actions__icon'}/>
                                                                                </div>
                                                                                {group.id !== 'unmapped' && group.id !== 'empty' && input.key !== 'quoteLines' && (!isSearch || (isSearch && (input.input?.items?.enum || input.input?.enum))) ? (
                                                                                    <>
                                                                                        <div
                                                                                            data-tip={t('pick_field_format')}
                                                                                            className={'actions__item'}
                                                                                            onClick={() => {
                                                                                                const inputElt = document.getElementById(`${inputData.parentElement ? inputData.parentElement : 'root'}_${inputData.keyItem}`)
                                                                                                inputElt.classList.toggle('admin-orders__hidden')
                                                                                            }}>
                                                                                            <PolIcon
                                                                                                icon={'cogs'}
                                                                                                className={'actions__icon'}/>

                                                                                        </div>
                                                                                    </>
                                                                                ) : null}
                                                                                {group.id !== 'unmapped' || !inputData.required ? (
                                                                                    <>
                                                                                        {group.visible ? (
                                                                                            <div
                                                                                                className={'actions__item'}
                                                                                                onClick={() => hideElement(key, groupKey)}
                                                                                                data-tip={t('hide_show_element')}
                                                                                            >
                                                                                                <PolIcon
                                                                                                    icon={'eye-slash'}
                                                                                                    className={'actions__icon'}/>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div
                                                                                                className={'actions__item'}
                                                                                                onClick={() => showElement(key, groupKey)}
                                                                                                data-tip={t('hide_show_element')}
                                                                                            >
                                                                                                <PolIcon
                                                                                                    icon={'eye'}
                                                                                                    className={'actions__icon'}/>
                                                                                            </div>
                                                                                        )}

                                                                                    </>
                                                                                ) : null}
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                )
                                                                : (input.key === 'quoteLines'
                                                                    ? (
                                                                        <ErrorBoundary>
                                                                            <QuotesProductsDetails isShow={false}
                                                                                                   itemId={itemId}
                                                                                                   setQuoteLinesValues={setQuoteLinesValues}/>
                                                                        </ErrorBoundary>
                                                                    )
                                                                    : (this.controlConditionOfInput(input)
                                                                        && (
                                                                            <Input inputData={inputData}
                                                                                   inputType={input.valueType}
                                                                                   handleFilesSizeExceeded={handleFilesSizeExceeded}
                                                                                   onChange={(value) => handleChange(key, groupKey, value, inputData.value)}
                                                                                   setSelectIsFocused={(keyItem, value) => setSelectIsFocused(keyItem, value)}
                                                                                   setPopupIsOpen={setPopupIsOpen}
                                                                                   typeForm={typeForm}
                                                                                   setPopup={setPopup}
                                                                                   deleteService={deleteService}
                                                                                   updateInput={(attributes) => updateInput(key, groupKey, attributes)}
                                                                            />
                                                                        )
                                                                    ))
                                                            }
                                                        </div>
                                                    </React.Fragment>;
                                                })}
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPopup: (info) => dispatch(setPopup(info)),
        deleteService: (info) => dispatch(DeleteService(info))
    }
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(React.memo(ViewForm)));

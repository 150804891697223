import UserService from "api/user/user";
import React from 'react';
import 'v4/features/front/routing/components/NavigationMenuItems/components/BadgeMercure/BadgeMercure.scss';
import useMercure from "v4/hooks/useMercure";
import {BADGES, getBadge} from "v4/services/badges.service";

export default function BadgeMercure({badgeId}) {
    const customerId = UserService.getCustomerId();
    const nbNotifs = useMercure({
        topic: `${customerId}_resynchronize-badges`,
        localStorageSaveKey: BADGES,
        itemKey: badgeId
    }) ?? getBadge(badgeId) ?? 0;

    return (
        <span className="notifs">{nbNotifs}</span>
    )
}

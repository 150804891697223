import React from 'react';
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {TASK_SEARCH} from "v4/data/apiRoutes";
import TasksListing from "v4/pages/front/Tasks/components/TasksListing/TasksListing";

export default function TasksPage({tab, isProspectPage}) {

    return (
        <SearchProvider searchRouteName={TASK_SEARCH} pageType="tasks" tab={tab}>
            <ToggleComponent isShowing={!isProspectPage} className="page page-list main-content__body__slide">
                <TasksListing tab={tab}/>
            </ToggleComponent>
        </SearchProvider>
    );
}

import UserService from "api/user/user";
import {useEffect, useMemo} from 'react';
import {STATS_QUOTE_CA} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";

export default function useDashboardQuotesGraph() {
    const {t} = usePolTranslation();
    const months = useMemo(() => Array.from({length: 12}, (_, i) => {
        const date = new Date();
        date.setMonth(i, 1);
        const monthString = new Intl.DateTimeFormat(UserService.getLocale(), {month: 'long'}).format(date);

        return monthString.slice(0, 1).toUpperCase() + monthString.slice(1);
    }), []);
    const [{data}, fetchQuoteGraph] = useFetch();

    useEffect(() => {
        fetchQuoteGraph({
            url: generateUrl(STATS_QUOTE_CA),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const quotesData = useMemo(() => {
        const [signed_quotes, in_progress_quotes, in_progress_quotes_weighted] = months.reduce((acc, month, index) => {
            const monthData = data?.find(({mois}) => String(mois) === String(index + 1));

            acc[0].push(monthData?.signed ?? 0);
            acc[1].push(monthData?.previsional ?? 0);
            acc[2].push(monthData?.weighted ?? 0);

            return acc;
        }, [[], [], []]);

        return {
            signed_quotes,
            in_progress_quotes,
            in_progress_quotes_weighted,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return {
        labels: months,
        datasets: [
            {
                label: t('signed_sales'),
                backgroundColor: 'rgb(55, 46, 116)',
                borderColor: 'rgb(55, 46, 116)',
                data: quotesData.signed_quotes,
            },
            {
                label: t('sales_previsions'),
                backgroundColor: 'rgb(200, 190, 254)',
                borderColor: 'rgb(200, 190, 254)',
                data: quotesData.in_progress_quotes,
            },
            {
                label: t('weighted_sales_previsions'),
                backgroundColor: 'rgb(237, 116, 147)',
                borderColor: 'rgb(237, 116, 147)',
                data: quotesData.in_progress_quotes_weighted,
            }
        ]
    };
}

export const hasSelectedElementInChild = (folderId, folders) => {
    let hasFound = false;
    if (!Array.isArray(folders)) {
        return false;
    }
    if (!folderId) {
        return false;
    }
    if (folders) {
        folders.forEach((folder) => {
            if (folder.id === folderId) {
                hasFound = true;
            }
            if (folder.customerResources && folder.customerResources.length) {
                if (hasSelectedElementInChild(folderId, folder.customerResources)) {
                    hasFound = true;
                }
            }
        })
    }
    return hasFound;
}

export const getTree = (folderList, folderId, currentPath = []) => {
    let path = []
    let hasFound = false;
    if (folderList) {
        folderList.forEach((folder) => {
            if (!folder.folderName) {
                return path;
            }
            if (hasFound) {
                return path;
            }
            if (folder.id === folderId) {
                path = [folder]
                hasFound = true;
            } else {
                if (folder.customerResources) {
                    const search = getTree(folder.customerResources, folderId, currentPath);
                    // ok trouvé
                    if (search.length !== currentPath.length) {
                        path = [...[folder], ...search]
                        hasFound = true;
                    }

                }
            }
        })
    }

    return hasFound ? [...currentPath, ...path] : currentPath
}
import {useEffect, useRef} from "react";
import useFetch from "v4/hooks/useFetch";
import {downloadBlob} from "v4/utils";

export default function useDownloadLink(fileName) {
    const _fileName = useRef(fileName);
    const [{data}, fetchLink] = useFetch('blob');

    const handleClick = (e) => {
        e.preventDefault();
        const {href} = e?.currentTarget ?? e?.target ?? {};

        if (!href) return;

        fetchLink({url: href})
    }

    const handleDoubleClick = (href, forceFileName) => {
        if (!href) return;
        if (forceFileName) _fileName.current = forceFileName;

        fetchLink({url: href})
    }

    useEffect(() => {
        _fileName.current = fileName
    }, [fileName]);

    useEffect(() => {
        if (data) downloadBlob(data, _fileName.current ?? "");
    }, [data]);

    return {
        handleClick,
        handleDoubleClick
    };
}

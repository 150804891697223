import React, {useCallback, useEffect} from 'react'
import {PolIcon} from "components/PolIcon/policon";
import useConfirm from "v4/hooks/useConfirm";
import useFetch from "v4/hooks/useFetch";
import apiService from "v4/services/api.service";
import {TEMPLATE_ITEM} from "v4/data/apiRoutes";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function TemplatesListDelete({item, onSuccessCallback = {}, onErrorCallBack = {}}) {
    const {t} = usePolTranslation()
    const confirmDelete = useConfirm()
    const [{isLoading, isError, isFinished}, deleteHookFetch] = useFetch();

    const onConfirmDelete = useCallback((item) => {
        deleteHookFetch({
            url: apiService.generateUrl(TEMPLATE_ITEM, {id: item.id}),
            config: {
                method: 'DELETE',
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isFinished && !isLoading && !isError) {
            onSuccessCallback()
        }

        if (!isLoading && isFinished && isError) {
            onErrorCallBack()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished, isError])

    return (
        <li className="datatable__actions__item"
            onClick={confirmDelete(() => onConfirmDelete(item), () => {
            }, t("delete_category_and_contents"))}>
                            <span className="datatable__actions__item__icon">
                                <PolIcon icon='trash'/>
                            </span>
        </li>
    )
}

import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ReactTooltip from "react-tooltip";

const NON_SORTABLE_TYPES = ['image', 'rowActions', 'files'];

export default function PolTableTh({dataKey, label, type, onHeaderClick, sortType}) {
    const {t} = usePolTranslation();

    const isSortable = !NON_SORTABLE_TYPES.includes(type) && sortType !== 'disabled';
    const isSortGroup = sortType === 'regroup';

    if (isSortable) {
        return (
            <th className={onHeaderClick ? 'sortable' : ''}
                data-type={type}>
                <span data-key={dataKey}
                      data-for='sortTooltip'
                      data-tip={isSortGroup ? t('sort_group', {label: t(label)}) : undefined}
                      onClick={onHeaderClick}>
                    {t(label)}
                </span>
                <ReactTooltip id="sortTooltip" effect="solid"/>
            </th>
        );
    }

    return (
        <th data-type={type}>
            <span data-key={dataKey}>
                {t(label)}
            </span>
        </th>
    )
}

import {Editor} from "@tinymce/tinymce-react";
import React, {useRef, useState} from 'react';
import {createPortal} from "react-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import useToggle from "v4/hooks/useToggle";
import GedPopup from "v4/pages/front/Ged/components/GedPopup/GedPopup";
import {getLSItem} from "v4/services/localStorage.service";

const TOOLBAR_FULL = 'undo redo | formatselect | removeformat bold italic underline strikethrough image backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link charmap anchor insertdatetime bbcode| help';

const TOOLBAR_RESTRICTED = 'undo redo | formatselect | removeformat bold italic underline strikethrough backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link';

export default React.forwardRef(function InputRichText({
                                                           fieldName,
                                                           fieldValue,
                                                           inputConstraints,
                                                           isRestricted = false,
                                                           onChange,
                                                           height = 250,
                                                           overrideTinyMceInitConfig = {},
                                                           overrideTinyMceProps = {},
                                                       }, ref) {
    const [isShowing, toggleShowing] = useToggle(false);
    const [popupGed, setPopupGed] = useState(null);
    const initialValue = useRef(fieldValue);

    const clickBrowseGed = (callback) => {
        toggleShowing();
        setPopupGed(<GedPopup callback={callback} toggle={toggleShowing}/>);
    }

    const language = getLSItem('i18nextLng', 'string').includes('fr') ? 'fr' : 'en';
    const {fullname} = getLSItem('user');

    return (
        <div className="input-field-rich-text-editor" data-is-required={!!inputConstraints?.required}>
            <Editor
                tinymceScriptSrc={process.env.REACT_APP_PUBLIC_URL + 'tinymce/tinymce.min.js'}
                textareaName={fieldName}
                initialValue={initialValue.current}
                init={{
                    image_title: true,
                    file_picker_types: 'image',
                    file_picker_callback: clickBrowseGed,
                    height: height,
                    menubar: false,
                    plugins: 'advlist,autolink,lists,link,image,charmap,print,preview,anchor,searchreplace,visualblocks,code,fullscreen,insertdatetime,media,table,paste,code,help,wordcount',
                    toolbar: isRestricted ? TOOLBAR_RESTRICTED : TOOLBAR_FULL,
                    content_style: 'z-index: 1',
                    language: language === 'fr' ? 'fr_FR' : undefined, // à voir si l'on rend la langue plus dynamique
                    language_url: language === 'fr' ? process.env.REACT_APP_PUBLIC_URL + 'tinymce_lang/' + language + '.js' : undefined,
                    insertdatetime_formats: language === 'fr' ? ['%d/%m/%Y', '%H:%M', `%d/%m/%Y ${fullname}`, `%d/%m/%Y %H:%M ${fullname}`, fullname] : ['%m/%d/%Y', '%H:%M', `%m/%d/%Y ${fullname}`, `%m/%d/%Y %H:%M ${fullname}`, fullname],
                    ...overrideTinyMceInitConfig,
                }}
                value={fieldValue}
                onEditorChange={onChange}
                ref={(editor) => {
                    if (ref) ref.current = editor;

                    if (editor) editor.elementRef.current.TinyMCE = editor;
                }}
                {...overrideTinyMceProps}
            />

            {popupGed && createPortal(
                <ToggleComponent isShowing={isShowing} shouldRemoveFromDom={true}>
                    {popupGed}
                </ToggleComponent>,
                document.querySelector('body'))}
        </div>
    )
})

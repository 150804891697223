import React, {useEffect, useState} from 'react'
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {routesBase} from "v4/data/appRoutes"
import {Link, Redirect} from "react-router-dom"
import useFetch from "v4/hooks/useFetch"
import apiService from "v4/services/api.service"
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import Loader from "v4/components/ui/Loader/Loader";
import useNotification from "v4/hooks/useNotification";
import {
    PRODUCT_CONFIGURATION_ADD,
    PRODUCT_CONFIGURATION_EDIT,
    PRODUCT_CONFIGURATION_FORM,
    PRODUCT_CONFIGURATION_ITEM
} from "v4/data/apiRoutes";
import Select from "react-select";

export default function ProductConfigurationsForm({customerId, id}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification();
    const [{data: productConfigurationFormData, isLoading: isLoadingForm, isFinished: isFinishedForm}, fetchProductConfigurationForm] = useFetch();
    const [{data: productConfigurationDataSave, isError: isErrorSave, isLoading: isLoadingSave, isFinished: isFinishedSave}, fetchProductConfigurationSave] = useFetch();
    const [productConfigurationForm, setProductConfigurationForm] = useState(null);
    const [productAttributeOptions, setProductAttributeOptions] = useState(null);
    const [productConfiguration, setProductConfiguration] = useState({
        label: null,
        productAttributes: [],
        customerId: customerId
    });
    const isEdit = (!!id);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        fetchProductConfigurationForm({
            url: id
                ? apiService.generateUrl(PRODUCT_CONFIGURATION_EDIT, {id: id, customerId: customerId})
                : apiService.generateUrl(PRODUCT_CONFIGURATION_FORM, {customerId: customerId}),
        })
    }, [fetchProductConfigurationForm, id, customerId, isEdit])

    useEffect(() => {
        if (productConfigurationFormData && isFinishedForm) {
            const form = productConfigurationFormData?.schema?.['hydra:member']?.[2];
            setProductConfigurationForm(form);
            if (form) {
                setProductAttributeOptions(
                    form?.productAttributes?.items?.enum.reduce((acc, option, key) => {
                        acc.push({value: option, label: form?.productAttributes?.items?.enum_titles[key]});

                        return acc;
                    }, [])
                );

                setProductConfiguration({
                    label: form?.label?.value,
                    productAttributes: form?.productAttributes?.value?.map((attribute) => {return attribute.id}),
                    customerId: form?.customerId?.value,
                });
            }
        }
    }, [isEdit, setProductConfigurationForm, productConfigurationFormData, isFinishedForm])

    const handleChange = (input) => {
        setProductConfiguration({...productConfiguration, [input.target.name]: input.target.value});
    }

    const handleChangeProductAttributes = (value) => {
        const newValue = value?.map(conf => conf.value);
        setProductConfiguration({...productConfiguration, productAttributes: newValue});
    }

    const handleSave = (e) => {
        e.preventDefault()
        fetchProductConfigurationSave({
            url: isEdit
                ? apiService.generateUrl(PRODUCT_CONFIGURATION_ITEM, {id: id})
                : apiService.generateUrl(PRODUCT_CONFIGURATION_ADD),
            config: {
                method: 'POST',
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(productConfiguration)
            }
        })
    }

    useEffect(() => {
        if (isErrorSave && isFinishedSave) {
            addErrorNotification(t('product_configuration_save_error'));
        }
        if (!isErrorSave && isFinishedSave) {
            addSuccessNotification(t('product_configuration_save_done'));
            setRedirect(<Redirect to={routesBase.adminProductConfigurations}/>)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productConfigurationDataSave, isErrorSave, isFinishedSave])

    const CardHeaderTools = () => (
        <>
            <Link to={routesBase.adminProductConfigurations} className="btn btn-secondary">{t('retour')}</Link>
            <button type="button" onClick={handleSave} className="btn btn-primary">{t('sauvegarder')}</button>
        </>
    )
    return (
        <AdminCard title={t('manage_product_configurations')} CardHeaderTools={CardHeaderTools}>
            {
                (isLoadingForm || isLoadingSave || !productConfigurationForm)
                    ? <Loader message="Chargement..."/>
                    : <form className="form form__scrollable form__admin" onSubmit={handleSave}>
                        <div className="row row-fluid mt-4">
                            <div className={'col-6 '}>
                                <div className={'col-12 field ' + (productConfiguration?.label ? 'field-written' : null)}>
                                    <input type="text" name="label" id="product_configuration_label" className="field__input"
                                           defaultValue={productConfiguration?.label} onChange={(input) => handleChange(input)}/>
                                    <label className="field__label" htmlFor="product_configuration_label">{t('label')}</label>
                                </div>
                            </div>
                            <div className={'col-12 '}>
                                <div className={'col-12 field ' + (productConfiguration?.productAttributes ? 'field-written' : null)}>
                                    <Select name='productAttributes' className={'field__select field__input'}
                                            id='productAttributes'
                                            defaultValue={productAttributeOptions.filter(
                                                attribute => productConfiguration?.productAttributes?.includes(attribute.value)
                                            )}
                                            value={productConfiguration?.productAttributes?.value}
                                            onChange={(input) => handleChangeProductAttributes(input)}
                                            options={productAttributeOptions}
                                            isMulti={true}
                                            placeholder={''}
                                            ignoreAccents={false}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    '&:hover': {borderColor: '#372e74'},
                                                    border: '2px solid #eef0fa',
                                                    boxShadow: 'none',
                                                    color: '#372e74',
                                                })
                                            }}
                                    />
                                    <label title={t('product_attributes')} className='field__label' htmlFor='productAttributes'>
                                        {t('product_attributes')}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
            }
            {redirect}
        </AdminCard>
    )
}
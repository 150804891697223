import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {MAIL_TYPE_COPY, MAIL_TYPE_ITEM} from "v4/data/apiRoutes";
import {MAIL_TYPE} from "v4/data/entities";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";
import GenericVoter from "v4/voters/genericVoter";

export default function MailsTypesListingRowActions({rowId}) {
    const {t} = usePolTranslation();
    const {replace} = useHistory();
    const [{isFinished}, fetchDelete] = useFetch();
    const [{isFinished: isFinishedCopy}, fetchCopy] = useFetch();

    const handleDelete = () => {
        const isConfirmed = window.confirm(t('confirm_delete'));
        if (isConfirmed) {
            fetchDelete({
                url: generateUrl(MAIL_TYPE_ITEM, {id: rowId}),
                config: {
                    method: 'DELETE'
                }
            });
        }
    }

    const handleCopy = () => {
        const isConfirmed = window.confirm(t('confirm_duplicate_mail'));
        if (isConfirmed) {
            fetchCopy({
                url: generateUrl(MAIL_TYPE_COPY, {id: rowId}),
                config: {
                    method: 'POST'
                }
            });
        }
    }

    useEffect(() => {
        if (isFinished || isFinishedCopy) {
            replace({
                state: {hasToRefresh: true}
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished, isFinishedCopy]);


    return (
        <>
            <Link to={({pathname}) => `${pathname}/edit/${rowId}`} className="table-link"/>
            <ul className="table-actions">
                {GenericVoter(MAIL_TYPE, 'canEdit') &&
                    <li data-for="row-action" data-tip={t('edit')}>
                        <Link to={({pathname}) => `${pathname}/edit/${rowId}`}>
                            <PolIcon icon='edit'/>
                        </Link>
                    </li>
                }

                {GenericVoter(MAIL_TYPE, 'canCopy') &&
                    <li onClick={handleCopy} data-for="row-action" data-tip={t('duplicate_mail_type')}>
                        <PolIcon icon='copy'/>
                    </li>
                }

                {GenericVoter(MAIL_TYPE, 'canDelete') &&
                    <li onClick={handleDelete} data-for="row-action" data-tip={t('delete')}>
                        <PolIcon icon='trash'/>
                    </li>
                }
            </ul>
            <ReactTooltip id="row-action" effect="solid"/>
        </>
    )
}

import React from 'react';
import 'v4/features/front/export/components/ListingExport/components/PreconfiguredExport/PreconfiguredExport.scss';
import usePreconfiguredExport
    from "v4/features/front/export/components/ListingExport/components/PreconfiguredExport/hooks/usePreconfiguredExport";
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import FieldWrapper from "v4/components/form/FieldWrapper/FieldWrapper";
import {FormProvider} from "react-hook-form";
import {CHOICE_TYPE} from "v4/data/fieldTypes";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "components/PolIcon/policon";

export default function PreconfiguredExport({entity, selectedItems, togglePopup}) {
    const {t} = usePolTranslation();
    const {methods, isLoading, preconfiguredFieldName, preconfiguredChoices, onSubmit, isSubmitLoading, hasPreconfiguredFieldValue} = usePreconfiguredExport({
        entity,
        selectedItems,
        togglePopup,
    });

    if (preconfiguredChoices?.length === 0) return null;

    return (
        <div className={`preselected-export ${hasPreconfiguredFieldValue ? 'selected-export' : ''}`}>
            {isLoading && <InlineLoader/>}
            {preconfiguredChoices && (
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <FieldWrapper name={preconfiguredFieldName}
                                      field={{
                                          type: CHOICE_TYPE,
                                          choices: preconfiguredChoices,
                                          label: 'preconfigured_export',
                                      }}/>

                        <div className="preselected-export__btns">
                            <button type="submit"
                                    disabled={!hasPreconfiguredFieldValue || isSubmitLoading}
                                    className="preselected-export__btn">
                                {t('export')}
                                {isSubmitLoading && <PolIcon icon="circle-notch"/>}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            )}
        </div>
    )
}

export default function getValueType({isPhone, isMonetary, isPercentage, isRichText}) {
    if (isPhone) return 'TelType';

    if (isMonetary) return 'money';

    if (isPercentage) return 'percent';

    if (isRichText) return 'html';

    return null;
}

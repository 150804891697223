import React from 'react';
import ViewOrdersViewer from "v4/pages/admin/ViewOrders/components/ViewOrdersViewer/ViewOrdersViewer";
import ViewOrdersSelection from "v4/pages/admin/ViewOrders/components/ViewOrdersSelection/ViewOrdersSelection";
import ViewOrdersAvailableFields
    from "v4/pages/admin/ViewOrders/components/ViewOrdersAvailableFields/ViewOrdersAvailableFields";

export default function ViewOrdersContent() {
    return (
        <>
            <div className="new-view-orders__content">
                <ViewOrdersViewer/>
            </div>
            <aside className="new-view-orders__aside">
                <ViewOrdersSelection/>
                <ViewOrdersAvailableFields/>
            </aside>
        </>
    )
}

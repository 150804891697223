import React from 'react';
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import ProspectTasksTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTasksTabPartial/ProspectTasksTabPartial";
import ProspectClosedTasksTabActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectClosedTasksTab/components/ProspectClosedTasksTabActions/ProspectClosedTasksTabActions";
import ProspectClosedTasksTabRowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectClosedTasksTab/components/ProspectClosedTasksTabRowActions/ProspectClosedTasksTabRowActions";


const fetchParams = {
    isClosed: true,
    isSynchroOutlookTask: false,
}

export default function ProspectClosedTasksTabListing({prospectId}) {
    return (
        <div className="prospect-tab__listing__wrapper">
            <ProspectHeadingTabPartial title="historique_actions_terminees" actions={(props) => <ProspectClosedTasksTabActions {...props}/>}/>
            <ProspectTasksTabPartial fetchParams={fetchParams}
                                     prospectId={prospectId}
                                     rowActions={ProspectClosedTasksTabRowActions}/>
        </div>
    )
}

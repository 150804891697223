const admin = (state = {isSticky: false}, action) => {
    switch (action.type) {
        case 'SET_STICKY_MENU':
            return {...state, isSticky: action.isSticky};
        case 'SET_CUSTOMERS_LIST':
            return {...state, customersList: action.customersList}
        case 'UPDATE_CUSTOMER_LIST':
            return {
                reloadCustomerList: true
            }
        default:
            return state;
    }
};

export default admin;
import React, {createContext, useState} from 'react'

const defaultTreeNodes = {
    treeNodes: null,
    setTreeNodes: () => {
    }
}

export const TreeNodesContext = createContext(defaultTreeNodes)

export default function TreeNodesProvider({children, nodes, nodesClone: originalNodes, saveAction, events}) {
    const [treeNodes, setTreeNodes] = useState(nodes)

    return (
        <TreeNodesContext.Provider value={{treeNodes, setTreeNodes, originalNodes, saveAction, events}}>
            {children}
        </TreeNodesContext.Provider>
    )
}
import moment from "moment";
import {useEffect, useState} from 'react';
import {TASK_CLOSE, TASK_RENEW} from "v4/data/apiRoutes";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import {generateUrl} from "v4/services/api.service";
import getDatetimeFormat from "v4/utils/getDatetimeFormat";

export default function useManageTasksActions(taskId, changeDate, defaultCanClose) {
    const {t} = usePolTranslation();

    const [{data: dataRenew, isLoading: isLoadingRenew}, fetchSaveRenew] = useFetch();
    const [{data: dataClose, isLoading: isLoadingClose}, fetchSaveClose] = useFetch();

    const [canClose, setCanClose] = useState(defaultCanClose);

    const [isRenew, toggleRenew] = useToggle();
    const [isClose, toggleClose] = useToggle();

    const [renewDate, setRenewDate] = useState(moment().add(1, 'month').format('YYYY-MM-DDTHH:mm'));

    const handleRenewDateChange = ({target: {value}}) => setRenewDate(value);

    const handleRenewValidate = () => {
        fetchSaveRenew({
            url: generateUrl(TASK_RENEW, {id: taskId}),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    beginAt: renewDate,
                })
            }
        });
    }

    const handleCloseValidate = () => {
        fetchSaveClose({
            url: generateUrl(TASK_CLOSE, {id: taskId}),
            config: {
                method: 'POST',
            }
        });
    }

    const {addSuccessNotification} = useNotification();
    useEffect(() => {
        if (dataRenew && "isClosed" in dataRenew) {
            setCanClose(!dataRenew.isClosed);
            changeDate(moment(dataRenew.beginAt).format(getDatetimeFormat(true)));
            toggleRenew();
            addSuccessNotification(t('task_renew_success'));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataRenew]);

    useEffect(() => {
        if (dataClose && "isClosed" in dataClose) {
            setCanClose(!dataClose.isClosed);
            toggleClose();
            addSuccessNotification(t('task_close_success'));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataClose]);

    return {
        state: {
            isLoading: isLoadingRenew || isLoadingClose,
        },
        canClose,
        isRenew,
        toggleRenew,
        isClose,
        toggleClose,
        renewDate,
        handleRenewDateChange,
        handleRenewValidate,
        handleCloseValidate,
    }
}

import React, {useCallback, useContext, useEffect, useState} from 'react'
import Tree from "v4/components/ui/Tree/Tree";
import useFetch from "v4/hooks/useFetch";
import apiService from "v4/services/api.service";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useNotification from "v4/hooks/useNotification";
import {AdminContext} from "v4/contexts/AdminContext";
import Loader from "v4/components/ui/Loader/Loader";
import TreeNodesProvider from "v4/components/ui/Tree/contexts/TreeNodesContext";
import {CATEGORY_LIST, CATEGORY_SAVE} from "v4/data/apiRoutes";

export default function CategoriesPage() {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification()
    const {asCustomer} = useContext(AdminContext)

    const [{data, isError, isLoading, isFinished}, hookFetch] = useFetch()
    const [refetch, setRefetch] = useState(false)

    const [originalNodes, setOriginalNodes] = useState(null)
    const [nodes, setNodes] = useState(null)

    const [events, setEvents] = useState(null)
    const [nodesChanges, setNodesChanges] = useState(null)
    const [{
        data: dataSave,
        isError: isErrorSave,
        isLoading: isLoadingSave,
        isFinished: isFinishedSave
    }, hookFetchSave] = useFetch()

    useEffect(() => {
        if (asCustomer?.id) {
            hookFetch({
                url: apiService.generateUrl(CATEGORY_LIST, {
                    customerId: asCustomer.id,
                    sortField: "order",
                    sortOrder: "asc",
                    "exists[parent]": "false"}),
            })
            setRefetch(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch, asCustomer])

    useEffect(() => {
        if (data && !isError && isFinished) {
            setNodes(data?.['hydra:member'])
            setOriginalNodes(JSON.parse(JSON.stringify(data?.['hydra:member'])))
        }

        if (isError) {
            addErrorNotification(t('admin_error_loading_categories'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError, isFinished])

    useEffect(() => {
        if (nodesChanges && asCustomer?.id) {
            hookFetchSave({
                url: apiService.generateUrl(CATEGORY_SAVE),
                config: {
                    method: 'POST',
                    headers: {
                        Accept: 'application/ld+json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({tree: nodesChanges, customerId: asCustomer.id})
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodesChanges]);

    // Set TreeNodes data tree changes
    useEffect(() => {
        if (dataSave && !isErrorSave && isFinishedSave) {
            setOriginalNodes(JSON.parse(JSON.stringify(nodesChanges)))
            addSuccessNotification(t('changes_saved'))
        }

        if (isErrorSave) {
            addErrorNotification(t('changes_save_error'))
        }

        setEvents(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSave, isErrorSave, isFinishedSave])

    const saveAction = useCallback((newTreeNodes) => {
        setEvents('save')
        setNodesChanges(newTreeNodes)
    }, [])

    return (
        <>
            {asCustomer?.id ?
                (
                    <>
                        {(isLoading || isLoadingSave) ? <Loader message="Chargement..."/> : null}

                        {data && !isError && nodes &&
                            <TreeNodesProvider nodes={nodes} nodesClone={originalNodes} saveAction={saveAction}
                                               events={events}>
                                <Tree parentId={'root'} level={0}/>
                            </TreeNodesProvider>

                        }
                    </>
                ) : (
                    <p>{t('no_customer_defined')}</p>
                )
            }
        </>
    )
}

import React, {useEffect} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import Field from "v4/components/form/Field/Field";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import {CONTACT_MOVE} from "v4/data/apiRoutes";
import {routesBase} from "v4/data/appRoutes";
import {TAB_CONTACTS} from "v4/data/tabNames";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";
import {randomId} from "v4/utils";

export default React.forwardRef(function ProspectContactsMoveContactForm({inputs, contactId, setIsSubmitLoading, closePopup}, ref) {
    const {t} = usePolTranslation();
    const {prospectId} = useProspectPageContext();

    const methods = useForm({
        defaultValues: inputs?.reduce((acc, input) => {
            acc[input.key] = input.value;
            return acc;
        }, {})
    });

    const [{data, isError}, fetchSubmitForm] = useFetch();
    const handleSubmit = (data) => {
        fetchSubmitForm({
            url: generateUrl(CONTACT_MOVE, {id: contactId}),
            config: {
                method: 'POST',
                body: JSON.stringify(data),
            }
        })
        setIsSubmitLoading(true);
    }

    const {push} = useHistory();
    const {addSuccessNotification, addErrorNotification} = useNotification();
    useEffect(() => {
        if (!isError && data?.prospectId) {
            addSuccessNotification(t('move_contact_success'));
            push(`${routesBase.baseProspect}/${data.prospectId}/${TAB_CONTACTS}/edit/${contactId}`);
            closePopup();
        }

        if (isError) {
            addErrorNotification(t('contact_form_edit'));
            closePopup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError, push, closePopup])

    return (
        <FormProvider {...methods}>
            <form className="move-contact-popup__grid"
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  ref={ref}>
                {inputs?.map((input) => {
                    const prefix = randomId();

                    return (
                        <div key={input.key}
                             className="move-contact-popup__field-wrapper"
                             data-type={input.type}>
                            <Field name={input.key}
                                   prefix={prefix}
                                   impossibleValues={[prospectId]}
                                   specificParams={{menuPosition: 'fixed'}}
                                   {...input}/>
                            <label htmlFor={prefix + input.key}>
                                {t(input.label)}
                            </label>
                        </div>
                    )
                })}
            </form>
        </FormProvider>
    )
});

import React from 'react';

export const BreadScrumb = ({folderHistory, onChangeItem, onRootReturn, t}) => {

    return (
        <div className={'ged-component__breadscrumb'}>
            <div className={'ged-component__breadscrumb-content'}>
                <span onClick={() => {onRootReturn();}}>
                    {t('documents')}
                </span>

                {folderHistory.length ? (
                    folderHistory.map(folder => {
                        return (
                            <div key={folder.id}>
                                <div className={"ged-component__breadscrumb-content-separator"}>
                                    <div></div>
                                </div>
                                <span onClick={() => {
                                    onChangeItem({
                                        folderName: folder.folderName,
                                        id: folder.id,
                                        hierarchyLevel: folder.hierarchyLevel
                                    }, folder.hierarchyLevel)
                                }}>
                                    {folder.folderName}
                                </span>
                            </div>
                        )
                    })
                ) : null}
            </div>
        </div>
    )
}
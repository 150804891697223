import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";

export default function MailsTypesListingActions() {
    return (
        <li className="action">
            <Link to={({pathname}) => `${pathname}/add`}>
                <PolIcon icon="plus" className="action__picto"/>
            </Link>
        </li>
    )
}

import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import Layout from "../../components/Layout/layout";
import {BrowserRouter as RouterDom} from 'react-router-dom';
import Popup from "../../components/Popup/popup";
import ErrorPopin from "../../components/Error/errorPopin";
import {setPopup} from "../../actions/content";
import {connect} from "react-redux";
import GlobalErrorBoundary from "../Error/index";
import {GlobalFonts} from '../../fonts/globalFonts'
import {RecoilRoot} from 'recoil';
import MercureComponent from '../../components/Mercure/mercure';
import TokenCheckerComponent from "../../components/TokenChecker/tokenChecker";
import * as Sentry from "@sentry/react";
import {MAINTENANCE_HEADER, TRANSACTION_ID_HEADER} from "v4/contexts/AuthContext";
import * as externalApiRoutes from "v4/data/externalApiRoutes";
import MercureProvider from "v4/contexts/MercureContext";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,

    // When in prod, when no error happens, we don't care about replays
    // But in dev, we want to capture all replays
    replaysSessionSampleRate: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'prod' ? 0 : 1,

    // However, on error it will force a replay to be saved and sent to Sentry
    replaysOnErrorSampleRate: 1.0,

    // All errors MUST be sent to Sentry
    tracesSampleRate: 1.0,

    integrations: [
        Sentry.browserTracingIntegration({
            tracePropagationTargets: [
                "localhost",
                `/^${process.env.REACT_APP_PUBLIC_URL}/`,
                `/^${process.env.REACT_APP_HOST_API}/`,
                `/^${process.env.REACT_APP_API_SERVICE_PLENETUDE_HOST}/`
            ]
        }),
        Sentry.replayIntegration({
            // Do not keep track of users across page loads (SPA)
            stickySession: false,

            // Allow request/response saving from & to middleware app
            networkDetailAllowUrls: [
                window.location.origin,
                process.env.REACT_APP_HOST_API,
                ...Object.values(externalApiRoutes ?? {})
            ],
            networkRequestHeaders: [
                TRANSACTION_ID_HEADER
            ],
            networkResponseHeaders: [
                MAINTENANCE_HEADER
            ],
            networkCaptureBodies: true,

            maxReplayDuration: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'prod' ? 60 * 1000 : 15 * 60 * 1000, // 1m in prod, otherwise 15m

            // Privacy
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
    ],
});

class App extends Component {

    render() {
        let classLoader = '',
            classGeneral = '';
        const {loader, className = '', error} = this.props;
        const loaderKeys = Object.keys(loader);

        loaderKeys.map((item) => {
            if (loader[item]) {
                if (classLoader === '') {
                    classLoader = 'is-loading'
                }
                classLoader += ` loading-${item}`
            }
            return '';
        });

        classGeneral += className;

        return (
            <RecoilRoot>
                <GlobalErrorBoundary>
                    <div className={`${classLoader} ${classGeneral}`}>
                        <GlobalFonts/>
                        <RouterDom>
                            <TokenCheckerComponent>
                                <MercureProvider hubUrl={process.env.REACT_APP_MERCURE_HUB_URL}
                                                 prefix={process.env.REACT_APP_MERCURE_ENV_PREFIX}>
                                    <MercureComponent>
                                        <Layout/>
                                        <Popup/>
                                        {error ? <ErrorPopin/> : null}
                                    </MercureComponent>
                                </MercureProvider>
                            </TokenCheckerComponent>
                        </RouterDom>
                    </div>
                </GlobalErrorBoundary>
            </RecoilRoot>
        )
    }
}

const mapStateToProps = ({content: {loader, className}, datas: {error}}) => ({
    loader,
    className,
    error
});

const mapDispatchToProps = dispatch => {
    return {
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));

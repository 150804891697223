import React, {useMemo} from 'react'
import CustomReactSelect from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function QuoteState({onChange, item}) {
    const {t} = usePolTranslation();

    const quoteStateOptions = useMemo(() => ([
        {value: "isAbandoned", label: t('is_abandoned')},
        {value: "inProgress", label: t('in_progress')},
        {value: "isWon", label: t('is_won')},
        {value: "isLost", label: t('is_lost')},
        {value: "isUnSelectable", label: t('is_un_selectable')},
        {value: "isDiffered", label: t('is_differed')},
    ]), [t]);

    const quoteStateValues = useMemo(() => {
        return quoteStateOptions.reduce((acc, option) => {
            if (item && item[option.value]) {
                acc.push(option.value);
            }

            return acc;
        }, []);
    }, [quoteStateOptions, item]);

    const onQuoteStateChange = (data) => {
        quoteStateOptions.forEach((option) => {
            if (data && data.some((item) => item.value === option.value)) {
                onChange(option.value, true);
            } else {
                onChange(option.value, false);
            }
        });
    }

    const reasonsOptions = useMemo(() => ([
        {value: "isAbandoned", label: t('is_abandoned')},
        {value: "inProgress", label: t('in_progress')},
        {value: "isWon", label: t('is_won')},
        {value: "isLost", label: t('is_lost')},
        {value: "isUnSelectable", label: t('is_un_selectable')},
    ]), [t]);

    const reasonsChange = (data) => {
        quoteStateOptions.forEach((option) => {
            if (data && data.some((item) => item.value === option.value)) {
                onChange(option.value, true);
            } else {
                onChange(option.value, false);
            }
        });
    }

    return (
        <div className={'quote-state'}>
            <CustomReactSelect name="quoteState"
                               value={quoteStateValues}
                               options={quoteStateOptions}
                               isMulti
                               onChange={onQuoteStateChange}/>

            <CustomReactSelect name="reasons"
                               value={item.reasons ?? []}
                               options={reasonsOptions}
                               isMulti
                               onChange={reasonsChange}/>
        </div>
    )
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {QUOTE} from "v4/data/entities";
import ListingExport from "v4/features/front/export/components/ListingExport/ListingExport";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {ids} from "v4/utils";

export default function QuotesListingTools({checkedRows}) {
    const {t} = usePolTranslation();
    const handleNoSelectedItems = (e) => (checkedRows?.length === 0) && e.stopPropagation();

    return (
        <>
            <ListingExport selectedItems={checkedRows} entity={QUOTE}/>
            <li className="listing-header__action dropdown"
                title={t('options')} onClickCapture={handleNoSelectedItems}>
                <PolIcon icon="actions" className="action__picto"/>
                <ul className="listing-header__action__dropdown">
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}>
                        <Link to={{
                            pathname: routesBase.baseContact,
                            state: {[ids(QUOTE)]: checkedRows}
                        }}>
                            <p>{t('research_associated_contacts')}</p>
                        </Link>
                    </li>
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}>
                        <Link to={{
                            pathname: routesBase.baseProspect,
                            state: {[ids(QUOTE)]: checkedRows}
                        }}>
                            <p>{t('research_associated_prospects')}</p>
                        </Link>
                    </li>
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}>
                        <Link to={{
                            pathname: routesBase.baseContact,
                            state: {[ids(QUOTE)]: checkedRows, isReverse: true}
                        }}>
                            <p>{t('reverse_research_associated_contacts')}</p>
                        </Link>
                    </li>
                    <li className={`listing-header__action__item${checkedRows?.length === 0 ? ' listing-header__action__item__disabled' : ''}`}>
                        <Link to={{
                            pathname: routesBase.baseProspect,
                            state: {[ids(QUOTE)]: checkedRows, isReverse: true}
                        }}>
                            <p>{t('reverse_research_associated_prospects')}</p>
                        </Link>
                    </li>
                </ul>
            </li>
        </>
    )
}

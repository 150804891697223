import React, {useMemo, useEffect, useState, useCallback} from 'react';
import Table from "v4/components/ui/Table/Table";
import {Link} from "react-router-dom";
import {routesBase} from 'v4/data/appRoutes'
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import apiService from "v4/services/api.service";
import {
    CUSTOM_EVENT_TRIGGER_LIST,
} from "v4/data/apiRoutes";
import {PolIcon} from "components/PolIcon/policon";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import useNotification from "v4/hooks/useNotification";
import CustomEventTriggersListDelete
    from "v4/pages/admin/CustomEventTriggers/components/CustomEventTriggersListDelete/CustomEventTriggersListDelete";
export default function CustomEventTriggersList({customerId}) {
    const {t} = usePolTranslation()
    const {addSuccessNotification, addErrorNotification} = useNotification()
    // Listing
    const [{
        data: loadedCustomEventTriggers,
        isLoading: loadedCustomEventTriggersLoading,
        isError: loadedCustomEventTriggersError
    }, fetchCustomEventTriggers] = useFetch();
    const [refetch, setRefetch] = useState(0)
    useEffect(() => {
        fetchCustomEventTriggers({
            url: apiService.generateUrl(CUSTOM_EVENT_TRIGGER_LIST, {
                sortField: "name",
                sortOrder: "asc",
                customerId: customerId
            }),
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])
    const onDeleteSuccess = useCallback(() => {
        addSuccessNotification(t('delete_custom_event_trigger_done'))
        setRefetch(c => c + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch])
    const onDeleteError = useCallback(() => {
        addErrorNotification(t('delete_custom_event_trigger_error'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const hydrateCustomEventTriggerRows = useCallback(() => {
        if (!loadedCustomEventTriggers?.['hydra:member']) {
            return null
        }
        const rows = JSON.parse(JSON.stringify(loadedCustomEventTriggers['hydra:member']))
        return (
            rows.map((item, index) => {
                return {
                    description: item.description,
                    entity: item.entity,
                    field: item.field,
                    value: item.value,
                    actions: (
                        <ul className="datatable__actions">
                            <Link key={index} to={`${routesBase.adminCustomEventTriggers}/edit/${item.id}`}>
                                <li className="datatable__actions__item">
                                    <span className="datatable__actions__item__icon">
                                        <PolIcon icon='edit'/>
                                    </span>
                                </li>
                            </Link>
                            <CustomEventTriggersListDelete item={item} onSuccessCallback={onDeleteSuccess}
                                                     onErrorCallBack={onDeleteError}/>
                        </ul>
                    )
                };
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedCustomEventTriggers])
    const tableList = useMemo(() => {
        return <Table tableRows={hydrateCustomEventTriggerRows()} isLoading={loadedCustomEventTriggersLoading}
                      isError={loadedCustomEventTriggersError}
                      theadData={Object.keys({description: 'description', entity: 'entity', field: 'field', value: 'value', actions: 'actions'})}/>
    }, [hydrateCustomEventTriggerRows, loadedCustomEventTriggersLoading, loadedCustomEventTriggersError])
    const CardHeaderTools = () => (
        <Link to={`${routesBase.adminCustomEventTriggers}/add`}
              className="btn btn-primary">{t('add_new_custom_event_trigger')}</Link>
    )
    return (
        <AdminCard title={t('manage_custom_event_triggers')} CardHeaderTools={CardHeaderTools}>
            {tableList}
        </AdminCard>
    )
}
import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_OPEN_TASKS} from "v4/data/tabNames";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function QuoteTasksNewButton({quoteId, prospectId, closePopup}) {
    const {t} = usePolTranslation();
    return (
        <Link to={{
            pathname: `${routesBase.baseProspect}/${prospectId}/${TAB_OPEN_TASKS}/add`,
            state: {relaunchId: quoteId}
        }}>
            <div onClick={closePopup}
                 className="prospect-tab__prospect__header__action task">
                <PolIcon icon='plus'/>
                <span>{t('new_task')}</span>
            </div>
        </Link>
    )
}

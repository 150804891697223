import {captureException} from "@sentry/react";
import ROUTES from "v4/data/apiRoutes";

export const MIDDLEWARE_URL = process.env.REACT_APP_HOST_API;
export const API_URL = MIDDLEWARE_URL + "/api";

/**
 *
 * @param {string} name
 * @param {Object} [parameters]
 * @param {Boolean} appendApiUrl - if true, will append the api url part to the route (/api)
 * @return {string}
 */
export function generateUrl(name, parameters = {}, appendApiUrl = true) {
    if (!ROUTES?.[name]) {
        const err = new Error(`Api ${name} not found`);
        captureException(err);
        throw err;
    }

    if (Object.keys(parameters).length > 0) {
        const url = (appendApiUrl ? API_URL : "") + ROUTES[name];
        return mixParamsToUrl(url, parameters);
    }

    return (appendApiUrl ? API_URL : "") + ROUTES[name];
}

/**
 *
 * @param {string} url
 * @param {Object} [parameters]
 * @return {string}
 */
export function generateUrlFromRelativeUrl(url, parameters = null) {
    const newUrl = url.replace(/^\//, "");
    let returnedUrl;
    if (newUrl.match(/^api/)) {
        returnedUrl = MIDDLEWARE_URL + '/' + newUrl;
    } else {
        returnedUrl = API_URL + '/' + newUrl;
    }

    if (typeof parameters === "object") {
        return mixParamsToUrl(returnedUrl, parameters);
    }

    return returnedUrl;
}

/**
 *
 * @param {string} url
 * @param {Object} [parameters]
 * @return {string}
 */
export function generateRelativeUrl(url, parameters = {}, ) {
    return mixParamsToUrl(url, parameters);
}

/**
 * @param {string} url
 * @param {object} parameters
 * @return {string} mixedUrl
 */
function mixParamsToUrl(url, parameters) {
    if (!parameters) return url;
    const parametersKeys = Object.keys(parameters);
    const urlPlaceholders = parametersKeys.filter(key => url.includes(`$${key}$`));
    const getParameters = Object.keys(parameters).filter(key => !urlPlaceholders.includes(key));
    if (urlPlaceholders.length > 0) {
        urlPlaceholders.forEach(key => {
            url = url.replace(`$${key}$`, parameters[key]);
        });
    }
    url += "?";
    getParameters.forEach(key => {
        url += key + '=' + parameters[key] + '&';
    });

    return url.slice(0, -1);
}

export default {
    generateUrl,
    generateUrlFromRelativeUrl,
    generateRelativeUrl,
}

import numberOnly from "v4/utils/numberOnly";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {SIRET} from "v4/data/apiRoutes";
import useToggle from "v4/hooks/useToggle";

let previousFieldValue = null;
export default function useInputSiret({fieldValue, onChange}) {
    const [{data, isLoading, isError}, fetchSiret] = useFetch();
    const [isPopupOpen, toggleIsPopupOpen] = useToggle();

    const handleChange = (e) => {
        onChange(numberOnly(e.currentTarget.value) ?? '');
    }

    const isValueValid = fieldValue.length === 9 || fieldValue.length === 14;

    const handleSiret = () => {
        previousFieldValue !== fieldValue && fetchSiret({url: generateUrl(SIRET, {siret: fieldValue})}); // Using the previousFieldValue to prevent fetching again to keep current data if the SIRET number is the same
        // previousFieldValue = fieldValue;
        toggleIsPopupOpen();
    }

    const siretCompanyName = data?.fields?.find(({key}) => key === 'company_name')?.value;

    return {
        handleChange,
        isValueValid,
        handleSiret,
        isLoading,
        isError,
        isPopupOpen,
        toggleIsPopupOpen,
        siretData: data,
        siretCompanyName,
    }
}

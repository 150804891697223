import React, {Component} from 'react'
import {connect} from 'react-redux'
import {setAddInfo, setExpandInfo} from "../../actions/content";
import {Redirect} from "react-router-dom";
import List from "../../components/Page/List";
import {baseProspect, getApiUrl} from "../../routes";

class Prospects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {
                name: 'prospects',
                id: 'table',
                type: 'datas',
                url: getApiUrl('prospects', 'list'),
                urlId: getApiUrl('prospects', 'id'),
                loader: 'table-prospects',
                order_type: 'list',
                order_entity: 'Prospect',
                taskIds: props.location && props.location.state ? props.location.state.taskIds : [],
                quoteIds: props.location && props.location.state ? props.location.state.quoteIds : [],
                isReverse: props.location && props.location.state ? props.location.state.isReverse : false,
                // This is used to functionally set {key: value} pairs in the search form
                additionalSearch: props.location && props.location.state ? props.location.state.additionalSearch : props.customTabSearch,
                redirect: null,
            }
        }
    }

    componentDidMount() {
        const {setExpandInfo} = this.props;
        setExpandInfo(null);
    }

    componentDidUpdate(prevProps) {
        //Si des changements dans les props ont eu lieues
        if (prevProps !== this.props && this.props.datas) {
            const {setAddInfo, datas: {newProspectId = undefined, isSubmitAndCreate = false}} = this.props;
            //Si un ID d'un nouveau prospect existe
            if (newProspectId) {
                //Redirection vers la page contact de l'entreprise créée
                this.setState({
                    redirect: <Redirect to={{
                        pathname: `${baseProspect}/${newProspectId}/contacts`
                    }}/>
                }, () => {
                    if (isSubmitAndCreate) {
                        //Modification de l'état du composant contentDetails, afin de rediriger vers le formulaire d'ajout de contact.
                        setAddInfo(true);
                    }
                    //Suppression de l'ID du nouveau prospect des props
                    delete this.props.datas.newProspectId;
                })
            }
        }
    }

    render() {
        const {info, redirect} = this.state;
        const {title, customTabSearch} = this.props;

        return (
            <>
                {redirect}
                <List
                    info={info}
                    className={'prospects'}
                    title={title ?? 'liste_comptes_prospects_clients'}
                    customTabSearch={customTabSearch}
                />
            </>
        )
    }
}

const mapStateToProps = ({datas, user}) => ({
    datas,
    user
});

const mapDispatchToProps = dispatch => {
    return {
        setExpandInfo: (expandinfo) => dispatch(setExpandInfo(expandinfo)),
        setAddInfo: (addInfo) => dispatch(setAddInfo(addInfo))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(Prospects));

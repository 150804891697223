import React from 'react';
import ConfiguredActionsGroup
    from "v4/features/front/configuredActions/components/ConfiguredActionsGroup/ConfiguredActionsGroup";
import ConfiguredActionButton
    from "v4/features/front/configuredActions/components/ConfiguredActionButton/ConfiguredActionButton";
import useConfiguredActions, {
    DEFAULT_ACTIONS_GROUP_CODE
} from "v4/features/front/configuredActions/hooks/useConfiguredActions";

export default function ConfiguredActions({actions, entityId, popupTitle, prospectId, classNamePrefix = 'actions', btnClassName = ''}) {
    const {
        [DEFAULT_ACTIONS_GROUP_CODE]: defaultActions,
        ...actionsByGroupCode
    } = useConfiguredActions(actions);

    return (
        <>
            {actionsByGroupCode && Object.entries(actionsByGroupCode).map(([groupCode, actionsGroup], index) => (
                <ConfiguredActionsGroup key={index}
                                        actions={actionsGroup}
                                        classNamePrefix={classNamePrefix}
                                        btnClassName={btnClassName}
                                        renderAction={(action, index) =>
                                            actionSwitcher(action, entityId, popupTitle, prospectId, index, classNamePrefix, btnClassName, true)
                                        }
                />
            ))}
            {defaultActions?.map((action, index) => actionSwitcher(action, entityId, popupTitle, prospectId, index, classNamePrefix, btnClassName))}
        </>
    )
}

function actionSwitcher(action, entityId, popupTitle, prospectId, index, classNamePrefix, btnClassName, isGroup) {
    if (!action.type) return null;

    switch (action.type) {
        case 'custom-actions':
            return <ConfiguredActionButton key={`${action.type} ${index}`}
                                           action={action}
                                           entityId={entityId}
                                           prospectId={prospectId}
                                           popupTitle={popupTitle}
                                           classNamePrefix={classNamePrefix}
                                           btnClassName={btnClassName}
                                           isGroup={isGroup}/>;
        default:
            return null;
    }
}

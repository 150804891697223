import React from 'react';

export const substitutionColumns = [
    {
        text: '⠀', // espace insécable U+2800
        attrs: () => ({'data-title': 'Nom de la société'}),
        dataField: 'lastname',
        sort: true,
        formatter: lineFormater,
        headerFormatter: headerFormater,
    }
];

export const substitutionData = [
    {
        id: 1,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 2,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 3,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 4,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 5,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 6,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 7,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 8,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 9,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 10,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    },
    {
        id: 11,
        lastname: 'Lorem ipsum dolor',
        name: 'Lorem ipsum dolor sit amet, consectetur',
        firstname: 'Sed ut ',
        postal_code: '42000',
        city: 'Et harum quidem',
        phone: '00 00 00 00 00',
        manage_by: 'Nam libero tempore'
    }
];

export const substitutionDataView = [
    {
        visible: true,
        fields: [
            {displayLabel: 'company_naf', value: 'test', visible: true, width: 25},
            {displayLabel: 'company_nafLabel', value: "Programmation", visible: true, width: 25},
            {displayLabel: 'company_name', value: "Colas", visible: true, width: 25},
            {displayLabel: 'company_origin', value: "Google", visible: true, width: 25},
            {displayLabel: 'company_siret', value: "359 030 756", visible: true, width: 25},
            {displayLabel: 'company_tvaNumber', value: "FR 30 779 8", visible: true, width: 25},
            {displayLabel: 'company_type', value: "agence", visible: true, width: 25},
            {displayLabel: 'contact_phone', value: "0103477880", visible: true, width: 25},
            {displayLabel: 'customer_name', value: 'Plo', visible: true, width: 25},
            {displayLabel: 'civility', value: 'MR', visible: true, width: 25},
            {displayLabel: 'ref', value: '081054', visible: true, width: 25},
        ]
    }
];

function headerFormater(column, colIndex) {
    return (
        <span className={'datatable__loader'}>{column.text}</span>
    );
}

function lineFormater(cell, row) {
    return (
        <span className={'datatable__loader'}>{cell}</span>
    );
}

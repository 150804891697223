import React, {useEffect, useState} from "react";
import {generateUrl} from "v4/services/api.service";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useFetch from "v4/hooks/useFetch";
import {TASK_ITEM} from "v4/data/apiRoutes";
import * as moment from "moment";
import {HorizontalBar} from "react-chartjs-2";
import useToggle from "v4/hooks/useToggle";

import "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectOldMailTasksTab/components/ProspectOldMailTasksTabStats/ProspectOldMailTasksTabStats.scss";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import statsMailingSVG from "img/svg-stat-mailing.svg";
import useNotification from "v4/hooks/useNotification";

export default function ProspectOldMailTasksTabStats({entityId}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification()
    const [refresh, toggleRefresh] = useToggle();
    const [date, setDate] = useState(null);
    const [hour, setHour] = useState(null);
    const [nbClic, setNbClic] = useState(0);
    const [nbReading, setNbReading] = useState(0);
    const [{data: task, isLoading, isError}, fetchTask] = useFetch();
    const [hasDetailledStats, setHasDetailledStats] = useState(false);
    const [dataset, setDataset] = useState({
        labels: [],
        datasets: [
            {
                type: 'horizontalBar',
                label: t('taskMailingStats_nbClic'),
                fill: false,
                backgroundColor: 'rgba(161,209,245,0.6)',
                borderColor: 'rgba(161,209,245,1)',
                data: []
            }
        ]
    });

    useEffect(() => {
        fetchTask({
            url: generateUrl(TASK_ITEM, {id: entityId}),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, refresh]);

    useEffect(() => {
        if (task && task?.taskType && task?.taskType?.mailing && !isLoading && !isError) {
            const stats = task && task.taskMailingStats && task.taskMailingStats.stats
            && task.taskMailingStats.stats.length > 5
                ? JSON.parse(task.taskMailingStats.stats) : false;

            setDate(moment(stats.date ? stats.date : task.createdAt).format('DD/MM/YYYY'));
            setHour(moment(stats.date ? stats.date : task.createdAt).format('HH:mm'));
            setNbReading(task && task.taskMailingStats && task.taskMailingStats.nbReading ? task.taskMailingStats.nbReading
                : task && task.taskMailingStats ? 0 : null);
            setNbClic(task && task.taskMailingStats && task.taskMailingStats.nbClic ? task.taskMailingStats.nbClic : 0);
            const newLabels = [];
            const newData = [];
            const urlsJson = stats && stats.urls ? stats.urls : {};
            const urls = Object.keys(urlsJson);
            const newDataSet = [...dataset.datasets];
            urls.forEach(url => {
                newLabels.push(url);
                newData.push(urlsJson[url].count);
            });
            newDataSet[0].data = newData;
            setDataset(
                {
                    ...dataset,
                    labels: newLabels,
                    datasets: newDataSet
                }
            );
            setHasDetailledStats(newData.length > 0);

            addSuccessNotification(t('stats_updated'));
        }

        if(isError && !isLoading) {
            addErrorNotification(t('stats_parsing_error'));
        }
        // eslint-disable-next-line
    }, [task, isLoading, isError])

    const canRefreshStats = (task) => {
        return typeof task?.taskMailingStats?.mailingJournalId !== 'undefined'
            && task?.contacts['hydra:member']?.length > 0
            ;
    }

    return <>
        {task && task.taskType && task.taskType.mailing &&
            <div className="row">
                <div className={`task-stats col-md-6`}>
                    <div className={'task-stats__header'}>
                        <p className={'task-stats__header__label'}>
                            {t('stats_task')} <span className={'task-stats__header__label--time'}>

                    {date && hour &&
                        t('stats_timestamp', {
                            date: date,
                            time: hour
                        })
                    }
                    </span>
                        </p>
                        {canRefreshStats(task) ? (
                            <button className={'btn btn-primary task-stats__refresh'} onClick={toggleRefresh}
                                    disabled={isLoading}>
                                <FontAwesomeIcon icon={faSync} className={isLoading ? 'spin' : ''}/>
                                {t('refresh')}
                            </button>) : null}
                    </div>

                    {task && task.taskMailingStats && !isLoading ? (
                        <div className="show-group">
                            <div className="show-group__fields">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="show-field text-rich-editor">
                                            <span className="show-field__label">{t('nb_readings')} :</span> <span
                                            className="show-field__value">{nbReading}</span>
                                        </p>
                                    </div>
                                    <div className="col-md-12">
                                        <p className="show-field text-rich-editor">
                                            <span className="show-field__label">{t('nb_clic')} :</span> <span
                                            className="show-field__value">{nbClic}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={'task-stats__header my-4'}>
                                <p className={'task-stats__header__label'}>{t('stats_details_task')} </p>
                            </div>

                            {hasDetailledStats ? (
                                <HorizontalBar
                                    data={
                                        dataset
                                    }
                                    width={100}
                                    height={50}
                                    options={{
                                        scales: {
                                            yAxes: [
                                                {
                                                    ticks: {
                                                        mirror: true,
                                                        fontColor: "#372E74",
                                                        beginAtZero: true
                                                    }
                                                }
                                            ],
                                            xAxes: [
                                                {
                                                    ticks: {
                                                        stepSize: 1,
                                                        beginAtZero: true
                                                    }
                                                }
                                            ]
                                        }
                                    }}
                                />
                            ) : t('aucun_resultat_trouve')}
                        </div>
                    ) : isLoading ?  t('loading') : t('no_mailing')
                    }
                </div>

                <div className={`task-stats--img col-md-6`}>
                    <img className={'data__stats-img'} src={statsMailingSVG} alt={t('stats_details_task')}/>
                </div>

            </div>
        }
    </>
}
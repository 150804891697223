import React, {createContext, useCallback, useState} from "react";
import Notice from "v4/components/ui/Notice/Notice";
import {createPortal} from "react-dom";

const defaultNotifications = {
    notifications: null,
    setNotifications: () => {
    },
    addNotification: ({type = 'success', message, show = true, delay = null}) => {
    },
    addSuccessNotification: (message) => {
    },
    addErrorNotification: (message) => {
    }
}

export const NotificationContext = createContext(defaultNotifications)

export default function NotificationProvider({children}) {
    const [notifications, setNotifications] = useState([])

    const addNotification = useCallback((notification) => {
        setNotifications(notifications => [...notifications, {
            type: 'success',
            message: null,
            show: true,
            delay: null, ...notification
        }])
    }, [])

    const addSuccessNotification = useCallback((message) => {
        setNotifications(notifications => [...notifications, {
            type: 'success',
            message: message,
            show: true
        }])
    }, [])

    const addErrorNotification = useCallback((message) => {
        setNotifications(notifications => [...notifications, {
            type: 'error',
            message: message,
            show: true
        }])
    }, [])

    return (
        <NotificationContext.Provider
            value={{notifications, setNotifications, addNotification, addSuccessNotification, addErrorNotification}}>
            {children}

            {createPortal(
                <div className="admin__notifications__wrapper notifications__wrapper">
                    {notifications.map((notification, index) => {
                        return <Notice key={index} {...notification}/>
                    })}
                </div>, document.body)}
        </NotificationContext.Provider>
    )
}

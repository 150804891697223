import {captureException} from "@sentry/react";
import React from 'react';
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "../PolIcon/policon";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {
        captureException(error)
        this.setState({
            hasError: true
        });
    }

    render() {
        const {t, className = ''} = this.props;
        const {hasError} = this.state;

        if (hasError) {
            return (
                <div className={`${className} page-error-component`}>
                    <div className={'page-error-component__content'}>
                        <PolIcon icon={'exclamation-circle'} className={'error-icon'}/>
                        <p className={'error-text'}>{t("error_content_loaded")}</p>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default withPolTranslation(ErrorBoundary);

import React, {useEffect} from 'react';
import Field from "v4/components/form/Field/Field";
import {PRODUCT} from "v4/data/entities";
import {
    useQuotesProductsDetailsContext
} from "v4/features/front/products/components/QuotesProductsDetails/contexts/QuotesProductsDetailsContext/QuotesProductsDetailsContext";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

const searchInput = {
    attr: {
        type: 'autocomplete',
        'autocomplete_entity': PRODUCT
    },
    constraints: {
        NotBlank: {
            message: 'validation_required',
        },
        readOnly: false,
        required: true,
    },
}

const quantityInput = {
    constraints: {
        GreaterThan: {
            message: 'This value should be greater than {{ compared_value }}.',
            value: 0,
        },
        readOnly: false,
        required: true,
    },
    type: 'NumberType'
}

export default function QuotesProductsStandardSearch({togglePopup, handleAddProduct}) {
    const {t} = usePolTranslation();
    const {sectionsNames} = useQuotesProductsDetailsContext();

    const sectionChoices = sectionsNames.filter(({isDefault}) => !isDefault).reduce((acc, {name}) => {
        const label = name.split('|')[1];
        acc.push({label: label !== '' ? label : t('empty_name'), value: name});

        return acc;
    }, []);

    useEffect(() => {
        const keyDownEnterCallback = (e) => {
            if (e.key === 'Enter') {
                handleAddProduct();
            }
        }

        window.addEventListener('keydown', keyDownEnterCallback);

        return () => {
            window.removeEventListener('keydown', keyDownEnterCallback);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        <div className="quotes-products__popup__body__add-form">
            <div className='input-field-wrapper'>
                <Field type="select" name="search" {...searchInput} placeholder={t('search')}/>
            </div>
            <div className='input-field-wrapper'>
                <Field name="quantity" {...quantityInput} placeholder={t('product_stock_qty')}/>
            </div>
            {
                Object.keys(sectionChoices).length > 0 && (
                    <div className='input-field-wrapper'>
                        <Field type='select' name="sectionName" placeholder={t('without_section')}
                               choices={sectionChoices}/>
                    </div>
                )}
        </div>
        <div className="quotes-products__popup__body__buttons">
            <button className="quotes-products__popup__body__button-return"
                    onClick={togglePopup}>{t('retour')}</button>
            <button className="quotes-products__popup__body__button-validate"
                    onClick={handleAddProduct}>{t('add_free_product')}</button>
        </div>
    </>
}

import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import Subscription
    from "v4/pages/front/Prospect/components/ProspectView/components/ProspectViewSubscriptions/components/Subscription/Subscription";

export default function ProspectViewSubscriptions({prospectSubscriptions, isExpanded}) {
    const {t} = usePolTranslation();
    const [isSubscriptionsExpanded, toggleSubscriptionsExpanded] = useToggle();

    if (!prospectSubscriptions || !Array.isArray(prospectSubscriptions)) return null;

    return (
        <div
            className={`prospect__render__subscriptions show-group ${(isSubscriptionsExpanded || isExpanded) ? 'expanded' : ''}`}>
            <h3>{t('subscriptions')}</h3>
            <div className="show-group__fields">
                {prospectSubscriptions.map((subscription) => (
                    <Subscription key={subscription.id} subscription={subscription}/>
                ))}
                {!isExpanded && (
                    isSubscriptionsExpanded
                        ? (
                            <button className="prospect__render__subscriptions__btn less" onClick={toggleSubscriptionsExpanded}>
                                <span>{t('see_less')}</span>
                                <PolIcon icon="chevron-down"/>
                            </button>
                        ) : (
                            <button className="prospect__render__subscriptions__btn more" onClick={toggleSubscriptionsExpanded}>
                                <span>{t('see_all')}</span>
                                <PolIcon icon="chevron-down"/>
                            </button>
                        )
                )}
            </div>
        </div>
    )
}

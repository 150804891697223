import React, {useEffect} from 'react'
import {useRecoilValue} from "recoil";
import {customerIdSelector} from "../../../components/Admin/atoms/customer";
import {adminFetchHandler, fetchHandler} from "../../../api/datas/datas";
import {connect} from "react-redux";
import UserService from "../../../api/user/user";

const OrderLocalStorage = ({update}) => {
    const customerId = useRecoilValue(customerIdSelector);

    useEffect(() => {
        if(customerId){
            fetch(`${process.env.REACT_APP_HOST_API}/api/view_orders_formatted?customerId=${customerId}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/ld+json',
                    'Content-Type': 'application/json',
                    "X-POL-AUTH": 'Bearer ' + localStorage.getItem('token')
                }
            }).then(
                fetchHandler,
                error => {
                    adminFetchHandler(error, {})
                }
            ).then(
                data => {
                    if (data) {
                        let user = UserService.getUser()
                        user.orders['hydra:member'][0] = data.viewOrders
                        UserService.setUser(user)
                        update(customerId);
                    }
                },
                error => {
                    adminFetchHandler(error, {})
                }
            );
        }
        // eslint-disable-next-line
    }, [customerId]);


    return (
        <></>
    )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
    return {
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderLocalStorage);

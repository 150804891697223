import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import InputRichText from "v4/components/form/Field/components/InputRichText/InputRichText";
import useQuoteLineDescription
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/components/QuoteLineDescription/hooks/useQuoteLineDescription";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export const MAX_LENGTH_DESCRIPTION = 65535; // C'est Kévin qui a marqué cette valeur dans la spec

export default function QuoteLineDescriptionEditor({name, togglePopup}) {
    const {t} = usePolTranslation();
    const {
        description,
        handleUpdate,
        checkSizeLimit,
        handleSave,
        isErrorMaxLength,
    } = useQuoteLineDescription(name, togglePopup);

    const tinyMceConfig = {
        onBeforeAddUndo: checkSizeLimit,
    }

    return (
        <div className={`quote-line-description-editor ${isErrorMaxLength ? 'has-error' : ''}`}>
            <InputRichText fieldName="description"
                           fieldValue={description}
                           isRestricted={true}
                           onChange={handleUpdate}
                           overrideTinyMceProps={tinyMceConfig}/>
            {isErrorMaxLength && <p>{t('error_max_length_description', {max: MAX_LENGTH_DESCRIPTION})}</p>}
            <button type="button"
                    className="mt-3 ml-auto btn btn-primary d-flex align-items-center justify-content-center"
                    onClick={handleSave}
                    disabled={isErrorMaxLength}>
                <PolIcon className="mr-2" icon="validation"/>
                <span>{t('enregistrer')}</span>
            </button>
        </div>
    )
}

import useFetch from "v4/hooks/useFetch";
import {useEffect, useMemo} from "react";
import {generateUrl} from "v4/services/api.service";
import {EXPORT_PRECONFIGURED, EXPORT_PRECONFIGURED_START} from "v4/data/apiRoutes";
import {useForm} from "react-hook-form";
import {useFrontContext} from "v4/contexts/FrontContext";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

const preconfiguredFieldName = 'preselectedExport';

export default function usePreconfiguredExport({entity, selectedItems, togglePopup}) {
    const {t} = usePolTranslation();
    const {addSuccessNotification} = useNotification();
    const {tab} = useFrontContext();
    const [{data, isLoading}, preconfiguredExportFetch] = useFetch();
    const [{data: preconfiguredExportSubmitData, isLoading: isSubmitLoading}, startPreconfiguredExport] = useFetch();
    const methods = useForm();
    const preconfiguredData = useMemo(() => {
        const _preconfiguredData = data?.['hydra:member'];
        if (!_preconfiguredData) return null;

        return Array.from(_preconfiguredData, ({id, label}) => {
            return ({label, value: id})
        })
    }, [data])

    useEffect(() => {
        preconfiguredExportFetch({
            url: generateUrl(EXPORT_PRECONFIGURED, {entity, ...(tab?.id && {tabId: tab.id})})
        })
    }, [entity, preconfiguredExportFetch, tab]);

    const onSubmit = (formData) => {
        const {[preconfiguredFieldName]: id} = formData;
        if (!id) return;

        startPreconfiguredExport({
            url: generateUrl(EXPORT_PRECONFIGURED_START, {id}),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    ids: selectedItems,
                })
            }
        });
    }

    useEffect(() => {
        if (!preconfiguredExportSubmitData?.message) return;

        addSuccessNotification(t(preconfiguredExportSubmitData.message));
        togglePopup && togglePopup(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preconfiguredExportSubmitData]);

    const {[preconfiguredFieldName]: preconfiguredFieldValue} = methods.watch({name: preconfiguredFieldName});
    const hasPreconfiguredFieldValue = !!preconfiguredFieldValue;

    return {
        methods,
        isLoading,
        preconfiguredChoices: preconfiguredData,
        preconfiguredFieldName,
        onSubmit,
        isSubmitLoading,
        hasPreconfiguredFieldValue,
    }
}

import * as moment from 'moment';
import React from "react";
import {textFilter} from 'react-bootstrap-table2-filter';
import {setLoader} from "../../actions/content";
import {setAppError, setData, setError} from "../../actions/datas";
import {setFormError} from "../../actions/form";
import {setToken} from "../../actions/user";
import {arrowSort, pageListRenderer} from "../../components/Datatables/_partials/config";
import {PolIcon} from "../../components/PolIcon/policon";
import {handleLiveoCall} from "../../components/View";
import {getViewOrdersOfEntityAndType, searchFieldKeyInViewOrders} from "../../utils/viewOrdersManager";
import {formatForView} from "../../utils/viewTypeFormatter";
import UserService from "../user/user";

export async function fetchHandler(res) {
    if (res.status >= 400 && res.status < 600) {
        return Promise.reject(res);
    } else if (res.status === 204) {
        //todo à enlever => gestion des routes vides
        return Promise.reject(res);
    }
    return res.json();
}

export async function tokenCheckerHandler(res) {
    if (res.status >= 400 && res.status < 600) {
        if (res.status === 401) {
            const json = await res.json()
            if (!(json.token && json.refresh_token)) return Promise.reject(res);

            return Promise.resolve(json);
        }
        return Promise.reject(res);
    } else if (res.status === 204) {
        //todo à enlever => gestion des routes vides
        return Promise.reject(res);
    }
    return res.json();
}

export function manageError(error, info = {}, errorMessage = undefined) {
    return function (dispatch, getState) {
        if (!errorMessage) {
            dispatch(setError(error));
            dispatch(setLoader({
                [info.loader]: false
            }));
        } else {
            dispatch(setAppError(errorMessage))
            dispatch(setFormError({}));
            if (info.submittedData) {
                dispatch(setLoader({
                    [info.loader]: false
                }));
            }
        }
    }
}

export function DataService(info, disableCache = false) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');

        dispatch(setLoader({
            [info.loader]: true
        }));
        const url = new URL(`${process.env.REACT_APP_HOST_API}${info.url}`);

        if (info.order_type && info.order_entity) {
            let selectFieldsArray = getViewOrdersOfEntityAndType(info.order_entity, info.order_type);
            if (selectFieldsArray) {
                selectFieldsArray = selectFieldsArray[0] && selectFieldsArray[0].fields ? selectFieldsArray[0].fields.map(item => item.key) : [];
                url.searchParams.append('_selectedFields', selectFieldsArray.join(';'))
                if (disableCache) {
                    url.searchParams.append('disableCache', disableCache && info.id === 'table')
                }
            }
        }

        if (info.page) {
            url.searchParams.append('page', info.page)
        }

        if (info.sortField) {
            url.searchParams.append('sortField', info.sortField)
        }

        if (info.sortOrder) {
            url.searchParams.append('sortOrder', info.sortOrder)
        }

        if (info.itemsPerPage || null !== localStorage.getItem('itemPerPage')) {
            const finalPage = info.itemsPerPage ? info.itemsPerPage : localStorage.getItem('itemPerPage')
            url.searchParams.append('itemsPerPage', finalPage)
        }

        return fetch(url.toString(), {
            method: info.method ? info.method : 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            },
        }).then(
            fetchHandler,
            error => {
                dispatch(manageError(true, info))
            }
        ).then(
            data => {
                if (data) {
                    data = data.data ? data.data : data;
                    if (data.code === 500 && data.errorMessage) {
                        dispatch(manageError(500, info, data.errorMessage))
                    } else {
                        if (data['hydra:member']) {
                            dispatch(setData(info.name, info.id, {
                                [info.type]: data['hydra:member'],
                                length: data['hydra:totalItems'] ? data['hydra:totalItems'] : data['hydra:member'].length
                            }));
                        } else {
                            dispatch(setData(info.name, info.id, {
                                [info.type]: data
                            }));
                        }

                        dispatch(setLoader({
                            [info.loader]: false
                        }));
                    }
                } else {
                    dispatch(manageError(500, info))
                }
            },
            error => {
                errorAjaxHandler(dispatch, info, error);
            }
        );
    };
}

/**
 * update the default sorting list
 *
 * @param name
 * @param type
 */
export const updateDefaultSorting = (name, type) => {
    if ("datas" !== type) {
        return updateDefaultSorting(type, "datas");
    }
    let columnForDefaultSort;
    switch (name) {
        case "prospects":
            columnForDefaultSort = [{
                dataField: 'fullname',
                order: 'asc'
            }]
            break;
        case "contacts":
            columnForDefaultSort = [{
                dataField: 'lastname',
                order: 'asc'
            }]
            break;
        case "actions":
            columnForDefaultSort = [{
                dataField: 'beginAt',
                order: 'asc'
            }]
            break;
        case "historique_mails":
            columnForDefaultSort = [{
                dataField: 'beginAt',
                order: 'asc'
            }]
            break;
        case "historique":
            columnForDefaultSort = [{
                dataField: 'beginAt',
                order: 'desc'
            }]
            break;
        case "devis":
            columnForDefaultSort = [{
                dataField: 'issuedAt',
                order: 'asc'
            }]
            break;
        case "devis_offres":
            columnForDefaultSort = [{
                dataField: 'issuedAt',
                order: 'asc'
            }]
            break;
        case "affaires":
            columnForDefaultSort = [{
                dataField: 'expectedSignedAt',
                order: 'asc'
            }]
            break;
        default:
            columnForDefaultSort = [{
                dataField: 'fullname',
                order: 'asc'
            }]
            break;
    }

    return columnForDefaultSort;
}

/**
 * Prepare columns
 *
 * @param {array} datas
 * @param t
 * @param {boolean} isFooterNeeded
 * @param {array} visibleInfos
 * @param {function} setError
 * @param {function} removeError
 * @param {function} setSortMemory
 * @param {array} viewOrdersForm
 * @param {string} subType
 * @returns {Array}
 */
export const getDefaultColumns = (datas = [], t, isFooterNeeded = false, visibleInfos = [],
                                  setError, removeError, setSortMemory, viewOrdersForm = [], subType) => {
    let columns = [];

    const map = new Map()
    visibleInfos.forEach(viewOrder => {
        return viewOrder?.fields?.forEach(viewOrderInfo => {

            const key = viewOrderInfo.viewKey ?? viewOrderInfo.key;

            if (map.has(key)) {
                return '';
            }

            map.set(key, true)

            const newColumn = addColumn(
                key,
                t(viewOrderInfo.label),
                key,
                datas.length ? datas[0][key] : '',
                false,
                isFooterNeeded,
                visibleInfos,
                setError,
                t,
                removeError,
                setSortMemory
            );
            if (newColumn) {
                columns.push(newColumn);
            }
        })
    })

    if (viewOrdersForm) {
        viewOrdersForm.forEach(viewOrder => {

            return viewOrder?.fields?.forEach(viewOrderInfo => {

                let key = viewOrderInfo.viewKey ? viewOrderInfo.viewKey : viewOrderInfo.key;
                if (key && (!viewOrderInfo.isSpecificField)) {
                    key = key.split('_').join('.');
                    key = key.replace('unmappedAddress', 'mainAddress');
                } else {
                    key = 'specificFields.' + key
                }

                const keys = Array.from(map.keys());

                if (keys.find(mapKey => mapKey.search(key) !== -1) || key === 'customerFiles') {
                    return '';
                }

                map.set(key, true)
                const newColumn = addColumn(
                    key,
                    t(viewOrderInfo.label),
                    key,
                    datas.length ? datas[0][key] : '',
                    false,
                    isFooterNeeded,
                    visibleInfos,
                    setError,
                    t,
                    removeError,
                    setSortMemory
                );
                if (newColumn) {
                    columns.push(newColumn);
                }
            })
        })
    }

    return columns;
};

window.sortCache = new Map();

export const sortFunc = (a, b, order, dataField, rowA, rowB) => {
    if (!Array.isArray(a) && typeof a === 'object' && a !== null) {
        return sortFunc(a.value, typeof b === 'object' && b !== null ? b.value : '', order, dataField);
    }
    if (rowA && rowA.id && window.sortCache.has(a + b + order + dataField + rowA.id)) {
        return window.sortCache.get(a + b + order + dataField + rowA.id);
    }

    const isDate = typeof a === 'string' && a.split('-').length === 3;

    if (a && b && !isNaN(parseFloat(a)) && !isNaN(parseFloat(b)) && !isDate) {
        if (typeof a !== "number") {
            a = parseFloat(a.split(/[.%\s]/g).join('').trim())
            b = parseFloat(b.split(/[.%\s]/g).join('').trim())
        }

        const result = order === 'asc' ? a - b : b - a;
        if (rowA && rowA.id) {
            window.sortCache.set(a + b + order + dataField + rowA.id, result)
        }
        return result;
    }

    const sorter = (valueA, valueB) => {
        let a = valueA ?? '';
        let b = valueB ?? '';
        if (Array.isArray(a)) {
            if (valueA.some(v => v.name)) {
                a = valueA.map(v => v.name).join(', ');
            } else {
                a = valueA.join(', ');
            }
        }
        if (Array.isArray(b)) {
            if (valueB.some(v => v.name)) {
                b = valueB.map(v => v.name).join(', ');
            } else {
                b = valueB.join(', ');
            }
        }

        if (typeof a === 'boolean' && typeof b === 'boolean') {
            return a === b ? 0 : a ? 1 : -1;
        }

        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b, 'fr', {ignorePunctuation: true, sensitivity: 'base', usage: 'sort'});
        }

        return 0;
    }

    const result = order === 'asc' ? sorter(a, b) : sorter(b, a);
    if (rowA && rowA.id) {
        window.sortCache.set(a + b + order + dataField + rowA.id, result)
    }
    return result;
}

/**
 * Create column with all params
 *
 * @param key
 * @param name
 * @param field
 * @param element
 * @param isSpecificFields
 * @param isFooterNeeded
 * @param visibleInfos
 * @param setError
 * @param t
 * @param removeError
 * @returns {{dataField: *, sortCaret: (function(*=, *=): *), hidden: boolean, text: *, sort: boolean, attrs: (function(): {"data-title": string})}|boolean}
 */
export const addColumn = (key, name, field, element = '', isSpecificFields, isFooterNeeded = false,
                          visibleInfos = [], setError, t, removeError, setSortMemory) => {
    const mainField = ['fullname', 'firstname', 'lastname', 'name'];
    let type = null;
    let search = searchFieldKeyInViewOrders(visibleInfos, key);
    if (!search && key === 'phone') {
        search = searchFieldKeyInViewOrders(visibleInfos, 'contact.phone');
    }
    if (search && visibleInfos[search.groupKey]?.fields?.[search.index]) {
        type = visibleInfos[search.groupKey].fields[search.index].valueType
    }

    if (!key.match(/[@]/i) && key !== 'id' && key !== 'prospectId' && key !== 'specificFields' && key !== 'prospect.id' && (Array.isArray(element) || typeof element !== 'object' || element === null)) {
        let column = {
            text: key.search('deliveryAddress') !== -1 ? name + ' (' + t('delivery') + ')' : name,
            attrs: () => ({
                'data-title': `${name}:`,
            }),
            dataField: field,
            sort: true,
            hidden: false,
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            formatter: (value, row, key) => dataFormatter(value, false, key, type, row, setError, t, removeError),
            csvFormatter: (cell) => dataFormatter(cell),
            filterValue: (cell, row) => {
                return typeof cell === 'string' ? cell.replace(/\s/g, '') : cell && cell.value ? cell.value.replace(/\s/g, '') : cell
            },
            onSort: (field, order) => {
                if (setSortMemory) {
                    setSortMemory(field, order)
                }
            }
        };

        if (!isFooterNeeded) {
            column = {
                ...column,
                footer: (columnData, column) => {
                    if (column.valueType === 'money') {
                        let sum = 0.0;
                        columnData.forEach(acc => {
                            if (!isNaN(parseFloat(acc))) {
                                sum += parseFloat(('' + acc).replace(/\s/g, '').replace(',', '.'))
                            }
                        })
                        if (sum < 1) {
                            return '';
                        }
                        return <span className={'align-right'}>{parseFloat(sum).toLocaleString(
                            undefined,
                            {minimumFractionDigits: 2}
                        ) + ' €'}</span>
                    }
                }
            }
        }

        if (mainField.indexOf(name) >= 0) {
            column = {
                ...column,
                classes: 'main-field',
                filter: textFilter({
                    className: 'datatable__filter',
                })
            }
        }

        return column;
    } else if (isSpecificFields) {
        let column = {
            text: element.fieldName,
            attrs: () => ({
                'data-title': `${element.fieldName}:`,
            }),
            dataField: field,
            sort: true,
            hidden: true,
            sortCaret: (order, column) => arrowSort(order, column),
            formatter: (value, row, key) => dataFormatter(value, false, key, type, row, setError, t, removeError),
            csvFormatter: (cell) => dataFormatter(cell),
            sortFunc: sortFunc,
            filterValue: (cell, row) => cell && cell.value ? cell.value : cell,
            onSort: (field, order) => {
                if (setSortMemory) {
                    setSortMemory(field, order)
                }
            }
        };

        if (mainField.indexOf(name) >= 0) {
            column = {
                ...column,
                classes: 'main-field',
                filter: textFilter({
                    className: 'datatable__filter',
                })
            }
        }
        return column;
    }
    return false;
};

export const initData = (datas, columns, info, numberItem, setNumber, setVisibleList, t, changePage = null, page = 1, totalItems = null) => {
    const config = {
        pageListRenderer,
        totalSize: totalItems ? totalItems : datas.length,
        page,
        sizePerPage: parseInt(numberItem),
        onPageChange: changePage,
        onSizePerPageChange: (sizePerPage) => {
            const end = sizePerPage > datas.length ? datas.length : sizePerPage;
            setNumber({start: '1', end: end, count: totalItems ? totalItems : datas.length});
            localStorage.setItem('itemPerPage', sizePerPage);
        },
    };

    if (columns.length) {
        columns.map((item, key) => {
            item.text = t(item.text);
            return '';
        });

        const issetActions = columns.filter(item => {
            return item.dataField === 'actions';
        });

        if (!issetActions.length) {
            let roleTitle;
            let editHover = 'edit';

            switch (info.type) {
                case 'actions':
                    roleTitle = 'TASK';
                    editHover = 'edit_task'
                    break;
                case 'historique':
                    editHover = 'edit_task'
                    break;
                case 'contacts':
                    roleTitle = 'CONTACT';
                    editHover = 'edit_contact'
                    break;
                case 'devis_offres':
                    roleTitle = 'QUOTE';
                    editHover = 'edit_action_quote'
                    break;
                case 'affaires':
                    editHover = 'edit_win_quote'
                    break;
                default:
                    roleTitle = null;
            }

            columns.push({
                text: '',
                headerFormatter: () => (<PolIcon icon={'list'} className={'toggle-columns'}/>),
                dataField: 'actions',
                attrs: () => ({'data-title': 'Actions'}),
                formatter: (cellContent, row) => {
                    return (
                        <>
                            {info.id !== 'table' || info.order_entity === 'MailType' ? (
                                <ul className={'datatable__actions'}>
                                    {UserService.controlAccess([`${roleTitle}_CUD`]) && true !== row.isLocked ? (
                                        <li className={'datatable__actions__item'} data-tip={t(editHover)}
                                            data-delay-show='1000' data-action={'update'}>
                                        <span className={'datatable__actions__item__icon'} data-action={'update'}>
                                            <PolIcon icon="edit"/>
                                        </span>
                                        </li>
                                    ) : (<></>)}
                                    {info && info.duplicate && UserService.controlAccess([`${roleTitle}_CUD`]) ?
                                        (
                                            <li className={'datatable__actions__item'}
                                                data-tip={t('duplicate_action_quote')} data-delay-show='1000'
                                                data-action={'duplicate'}>
                                                <PolIcon icon="copy" data-action={'duplicate'}
                                                         className={'datatable__actions__item__icon'}/>
                                            </li>
                                        )
                                        : (<></>)}
                                    {info && info.type === 'devis_offres' && UserService.controlAccess([`${roleTitle}_CUD`]) ?
                                        (
                                            <li className={'datatable__actions__item'}
                                                data-tip={t('recovery_action_quote')} data-delay-show='1000'
                                                data-action={'re-offer'}>
                                                <span className={'datatable__actions__item__icon'}
                                                      data-action={'re-offer'}>
                                                    <PolIcon icon={'file-export-solid'}/>
                                                </span>
                                            </li>
                                        )
                                        : (<></>)}
                                    {UserService.controlAccess([`${roleTitle}_CUD`]) ? (
                                        <li className={'datatable__actions__item'} data-tip={t('delete_action_quote')}
                                            data-delay-show='1000' data-action={'delete'}>
                                            <span className={'datatable__actions__item__icon'} data-action={'delete'}>
                                                <PolIcon icon="bin"/>
                                            </span>
                                        </li>
                                    ) : (<></>)}
                                </ul>) : (<></>)}
                        </>
                    )
                },
                headerEvents: {
                    onClick: (e, column, columnIndex) => {
                        setVisibleList(true);
                    }
                },
                csvExport: false
            });
        }
    } else {
        columns.push({
            text: '',
            dataField: 'noResult',
        });
    }

    datas.forEach((item, index) => {
        if (item && item.specificFields) {
            let newObject = {};
            Object.keys(item.specificFields).forEach(key => {
                const value = item.specificFields[key].value;
                newObject = {
                    ...newObject,
                    [key]: {
                        ...item.specificFields[key],
                        value: formatForView(value)
                    }
                }
            })
            item.specificFields = newObject
        }
        columns.filter((col) => !col.hidden).forEach((col) => {
            if (col.dataField && col.valueType) {
                const keys = col.dataField.split('.');
                let value = keys.length ? undefined : datas[index][col.dataField];
                let lastArray = [];

                keys.forEach((key) => {
                    if (typeof value === 'object' && value) {
                        lastArray = value
                        value = value[key]
                        return;
                    }
                    value = datas[index][key]
                    lastArray = datas[index][key]
                })

                if (keys.length > 1 && lastArray && (typeof lastArray === 'object' || Array.isArray(lastArray))) {
                    lastArray[keys[keys.length - 1]] = formatForView(value, col.valueType)
                } else {
                    datas[index][col.dataField] = formatForView(value, col.valueType);
                }
            }

            if (datas[index]?.['specificFields']?.[col.dataField]) {
                let value = datas[index]['specificFields'][col.dataField]['value']
                datas[index][col.dataField] = formatForView(value, col.valueType);
            }
        })
    });

    return {datas: datas, columns: columns, config: config}
}

export const adminFetchHandler = (error, info) => {
    return function (dispatch, getState) {
        errorAjaxHandler(dispatch, info, error)
    }
}

export const errorAjaxHandler = (dispatch, info, error) => {
    if (error.json) {
        const json = error.json();
        if (error.status === 403) {
            json.then(json => {
                dispatch(manageError(403, info, json["hydra:description"]))
            })
            return;
        }

        json.then((json) => {
            if (json.code === 500 && json.errorMessage) {
                dispatch(manageError(500, info, json.errorMessage))
            } else if (json.code === 401 && json.errorMessage) {
                dispatch(manageError(401, info))
            } else if (401 === error.status) {
                if (json.token && json.refresh_token) {
                    dispatch(setToken(json.token, json.refresh_token, true));
                } else {
                    dispatch(manageError(401, info))
                }
            } else {
                dispatch(manageError(error.status, info))
            }
        }).catch((jsonError) => {
            if (error.status === 401) {
                dispatch(manageError(401, info))
            } else if (error.status === 404) {
                dispatch(manageError(404, info));
            } else {
                dispatch(manageError(500, info))
            }
        })
    } else {
        dispatch(manageError(500, info, error))
    }
};

export const dateFormatter = (cell, isTable, key) => {
    const newHours = ['createdAt', 'updatedAt', 'beginAt', 'endAt'];

    if (cell) {
        const dateAsString = moment(cell.toString()).format(newHours.indexOf(key) !== -1 ? 'DD/MM/YYYY à HH:mm' : 'DD/MM/YYYY')

        return dateAsString !== '01/01/1970' ? dateAsString : '';
    }
    return cell;
};

export const booleanFormatter = (cell, isTable) => {
    if (!isTable) {
        return cell ? 'Oui' : 'Non'
    }

    return (
        <div className="checkbox disabled">
            <label>
                <input type="checkbox" checked={cell} disabled/>
            </label>
        </div>
    )
};

export const dataFormatter = (value, isTable = false, key, type, row, setError, t, removeError) => {
    if (type === 'TelType' && value && value.length && UserService.isLiveo()) {
        const name = row.fullname ? row.fullname : row.lastname ? row.firstname + ' ' + row.lastname : row.name;

        return (<>
            <PolIcon icon={'phone'}/>
            <span
                className={'data__info__phone'}
                onClick={() => handleLiveoCall(value, name, setError, t, removeError)}
            >
                {' ' + formatForView(value, 'phone')}
            </span>
        </>)
    }
    if (/<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|img|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|div|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i.test(value)) {
        return (<div dangerouslySetInnerHTML={{__html: value}}/>)
    }
    if (type === 'money') {
        return <span className={'align-right'}>{value}</span>
    }
    if (typeof value === 'boolean') {
        return booleanFormatter(value, isTable)
    } else if (value && !isNaN(Date.parse(value)) && moment(value.toString(), moment.ISO_8601).isValid()) {
        return dateFormatter(value, isTable, key);
    } else if (Array.isArray(value) && value.some(item => item.name)) {
        return value.map(item => item.name).join(', ');
    } if (value && typeof value === 'object') {
        return value.value;
    }

    return value;
};

export const isCompany = (data) => {
    return !!(data.company)
};

const recursiveKeys = (value, newValues, valuesKey, values) => {
    Object.keys(value).map(subValueKey => {
        if (Array.isArray(value[subValueKey]) && value[subValueKey].length > 100) {
            return ''
        }
        if (subValueKey === 'hydra:member') {
            newValues = {
                ...newValues,
                [valuesKey]: values[valuesKey + 'Formatted']
            };
        } else {
            let subValue = value[subValueKey];
            if (subValueKey.match(/[@]/i) ||
                subValueKey === 'id' ||
                !subValue
            ) {
                return null;
            }
            if (typeof subValue === 'object') {
                const recursiveValues = recursiveKeys(subValue, newValues, `${valuesKey}_${subValueKey}`, values);
                if (typeof recursiveValues === 'object') {
                    newValues = {
                        ...newValues,
                        ...recursiveValues
                    }
                }
            } else {
                newValues = {
                    ...newValues,
                    [valuesKey + '_' + subValueKey]: dataFormatter(value[subValueKey], false, subValueKey)
                };
            }
            return '';
        }
        return ''
    });

    return newValues;
}

export const createDatas = (values) => {
    let newValues = {};

    if (!values) {
        return false;
    }
    Object.keys(values).map(valuesKey => {
        let value = values[valuesKey];
        if (valuesKey.match(/[@]/i) || valuesKey === 'id' || (!value && value !== false)) {
            return null;
        }

        if (value && typeof value === 'object') {
            if (Array.isArray(value)) {
                newValues = {
                    ...newValues,
                    [valuesKey]: value
                }
            }
            if (!value["hydra:member"]) {
                newValues = {
                    ...newValues,
                    ...recursiveKeys(value, newValues, valuesKey, values)
                }
            } else {
                newValues = {
                    ...newValues,
                    [valuesKey]: value["hydra:member"]
                }
            }
        } else {
            newValues = {
                ...newValues,
                [valuesKey]: dataFormatter(values[valuesKey], false, valuesKey)
            }
        }

        return '';
    });

    return newValues;
};

export const manageDetailsView = (processedData, type, sortColumns = [], values) => {
    let issetField = [];
    let filterSubDatas = [];
    sortColumns.forEach(item => {
        const key = type === 'view' && item.viewKey ? 'viewKey' : 'key';
        if (item[key]) {
            let value = processedData[item[key]] || processedData[item[key].split('.').join('_')]
            if (!value && processedData.specificFields && processedData.specificFields.length) {
                const specificField = processedData.specificFields.filter((element => element.fieldId === item[key] || element.fieldId === item['key']));
                value = specificField.length ? specificField[0].value : ''
                item.label = specificField.length ? specificField[0].fieldName : item.label
            }

            if (!item.valueType && type === 'form') {
                item.valueType = guessValueType(item, values);
            }

            filterSubDatas.push({
                ...item,
                value: value
            })
        }
    });

    return {
        issetField,
        filterSubDatas
    };
};

const guessValueType = (item, values) => {
    if (item && item.key.length && values[item.key]) {
        const element = values[item.key];
        if (element.attr && element.attr.type === 'TelType') {
            return 'TelType';
        }
        if (element.attr && element.attr.type === 'file') {
            return 'files';
        }
        if (element?.attr?.['rich-text-editor']) {
            return 'html';
        }
    }
    return null
}

export function getSpecifiClassname(key) {
    switch (key) {
        case('potential'):
            return 'field-potential';
        case "Commentaire":
        case "Détail de l'offre":
        case "Mémo":
        case "Compte Rendu":
        case "Description":
        case "Conditions":
            return 'data__info--large';
        default:
            return '';
    }
}

export function getLengthClassname(key) {
    switch (parseInt(key)) {
        case (25):
            return 'col-md-3';
        case (33):
            return 'col-md-4';
        case (50):
            return 'col-md-6';
        case (75):
            return 'col-md-9';
        case (100):
            return 'col-md-12';
        default:
            return '';
    }
}

export function dataFilter(entity, type, values, admin = false, group = null) {
    //Va chercher les orders
    const infosOrders = getViewOrdersOfEntityAndType(entity, type === 'view' ? 'form' : type, group);
    const isSearch = (type === 'search');
    if (!infosOrders) {
        return null;
    }

    const groupSort = infosOrders.sort((a, b) => a.order - b.order);
    const filterDatas = [];
    let processedData = createDatas(values);

    let issetField = [];

    if (!values) {
        return;
    }

    type = type === 'search' ? 'form' : type;
    //Crée les données pour interpretation
    if (type === 'form') {
        Object.keys(values).forEach(key => {
            values[key].visible = false;
        });
    }
    groupSort.forEach((group) => {
        const {fields, width} = group;
        if (!fields) {
            return;
        }
        const sortColumns = fields.sort((a, b) => a.order - b.order);

        if (type === 'view') {
            //On remplace le champs mainAddress par unmapped
            let data = {};
            Object.keys(processedData).forEach(key => {
                data = {
                    ...data,
                    [key.replace('mainAddress', 'unmappedAddress')]: processedData[key]
                }
            });

            processedData = data;
        }

        const manageData = manageDetailsView(processedData, type, sortColumns, values);
        const filterSubDatas = manageData['filterSubDatas'];
        issetField = issetField.concat(manageData['issetField']);

        if (type === 'form') {
            Object.keys(values).forEach(key => {
                const input = values[key];
                const inputTitle = key;
                const issetOrder = filterSubDatas.findIndex(dataItem => dataItem.key === inputTitle);

                if (issetOrder >= 0) {
                    input.visible = true;

                    filterSubDatas[issetOrder] = {
                        ...filterSubDatas[issetOrder],
                        input: {
                            ...input
                        }
                    }
                }

                values[key] = input;
            });
        }

        filterDatas.push({
            ...group,
            width: parseInt(width),
            fields: filterSubDatas,
            visible: true
        });
    });

    if (type === 'view') {
        const unmappedData = [];
        Object.keys(processedData).forEach((key, index) => {
            if (issetField.indexOf(key) < 0) {
                unmappedData.push({
                    id: key,
                    type: "unmapped",
                    width: 25,
                    label: key,
                    order: index,
                    key: key,
                    value: processedData[key]
                })
            }
        });

        if (unmappedData.length) {
            filterDatas.push({
                id: 'unmapped',
                width: 100,
                order: (groupSort.length + 1),
                displayLabel: 'unmapped_fields',
                fields: unmappedData,
                visible: true
            });
        }
    } else if (type === 'form') {
        //Others fields which aren't in orders
        const unvisibleKey = Object.keys(values).filter(key => !values[key].visible);
        const unmappedData = [];
        const unvisibleData = [];

        unvisibleKey.forEach((item, key) => {
            const fieldKey = (values[item].parentElement) ? `${values[item].parentElement}_${values[item].keyItem}` : values[item].keyItem;
            const defautlWidth = isSearch ? 50 : 25;
            const dataItem = {
                label: values[item].keyItem,
                width: defautlWidth,
                key: fieldKey,
                groupe: false,
                order: fieldKey,
                fields: [],
                input: {
                    ...values[item]
                },
                isAdded: true
            };

            if (values[item] && values[item].required) {
                unmappedData.push(dataItem);
            } else {
                unvisibleData.push(dataItem);
            }
        });

        if (unmappedData.length) {
            filterDatas.push({
                id: 'unmapped',
                width: 100,
                order: (groupSort.length + 1),
                label: 'unmapped_fields',
                fields: unmappedData,
                visible: true
            });
        }

        filterDatas.push({
            id: 'empty',
            width: 100,
            order: (groupSort.length + 1),
            label: 'unvisible_fields',
            fields: unvisibleData,
            visible: false
        })
    }

    return admin ? {
        inputs: filterDatas,
        orders: groupSort
    } : filterDatas;
}

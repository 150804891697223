let clicHandlerTimer = 0;
let clicHandlerDelay = 200;
let clicHandlerPrevent = false;

/**
 * Execute on single clic
 * @param callback
 */
export const onSingleClickHandler = (callback) => {
    clicHandlerTimer = setTimeout(() => {
        if (!clicHandlerPrevent) {
            callback();
        }
    }, clicHandlerDelay);
};

/**
 * Execute on double clic
 * @param callback
 */
export const onDoubleClickHandler = (callback) => {
    clearTimeout(clicHandlerTimer);
    clicHandlerPrevent = true;
    callback();
    setTimeout(() => {
        clicHandlerPrevent = false;
    }, clicHandlerDelay);
};

export const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;
import React, {useState} from 'react'
import {connect} from 'react-redux'
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import AdminDatatables from '../../../components/Admin/_partials/datatables';
import {Redirect} from "react-router-dom";
import * as moment from "moment";
import FormCustomer from "../../../components/Admin/_partials/form_customer";
import {arrowSort} from "../../../components/Datatables/_partials/config";
import {sortFunc} from "../../../api/datas/datas";
import GedSizeButton from '../../../components/Admin/_partials/gedSizeButton';
import GedCleanup from '../../../components/Admin/_partials/gedCleanup';
import ErrorBoundaryModules from "../../../v4/features/admin/modules/components/ErrorBoundaryModules/ErrorBoundaryModules";

const Customer = ({t, match}) => {
    const [redirect, setRedirect] = useState(undefined);
    const itemId = match && match.params ? match.params.id : null;
    const [isAddForm, setAddForm] = useState(false);
    const [additionalInput, setAdditionalInput] = useState(<></>);

    const initColumns = [
        {
            text: t('logo'),
            dataField: 'logoUrl',
            formatter: (cellContent, row) => (
                <>
                    {'logoUrl' in row ? (
                        <img className={'list__item__logo'} src={row.logoUrl} alt={row.name}/>) : null}
                </>
            ),
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('name'),
            dataField: 'name',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('buyingAt'),
            dataField: 'buyingAt',
            formatter: (cellContent, row) => (
                moment(row.buyingAt).format('DD/MM/YYYY')),
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('licenseNumber'),
            dataField: 'licenseNumber',
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('expiredAt'),
            dataField: 'expiredAt',
            formatter: (cellContent, row) => (
                moment(row.expiredAt).format('DD/MM/YYYY')),
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        },
        {
            text: t('isActive'),
            dataField: 'isActive',
            formatter: (cellContent, row) => (
                <div className="checkbox disabled">
                    <label>
                        <input type="checkbox" checked={row.isActive} disabled/>
                    </label>
                </div>),
            sortCaret: (order, column) => arrowSort(order, column),
            sortFunc: sortFunc,
            sort: true
        }
    ];

    const infoTable = {
        url: '/api/customers',
        deleteUrl: '/api/customers/{id}',
        loader: 'datatable-customers'
    };

    return (
        <>
            {redirect}
            <div className="page page-admin content-wrapper">
                <section className={'box'}>
                    <div className={'box__header d-flex justify-content-between'}>
                        <p className={'box__header__text align-self-center'}>{t('administration_customer')}</p>
                        <div className="d-flex justify-content-end col-5">
                            {
                                itemId && <>
                                    <GedCleanup customerId={itemId}/>
                                    <GedSizeButton customerId={itemId}/>
                                </>
                            }
                        </div>
                    </div>
                    <div className={'box__body'}>
                        {!itemId && !isAddForm ? (
                            <>
                                <AdminDatatables info={infoTable}
                                                 disableCustomerIdFilter={true}
                                                 columns={initColumns}
                                                 changeView={(item) => {
                                                     setRedirect(<Redirect
                                                         to={`/administration/customer_list/${item.id}`}/>
                                                     )
                                                 }}/>
                                <div className={'row align-items-center justify-content-end'}>
                                    <div className={`col-12 col-md-auto form__submit`}>
                                        <button className={'btn btn-primary form__submit__btn'}
                                                onClick={() => setAddForm(true)}>
                                            {t('add_customer')}
                                        </button>
                                    </div>
                                </div>

                            </>) : (
                            <FormCustomer itemId={itemId} additionalInput={additionalInput}/>
                        )}
                    </div>
                </section>
                {!itemId && !isAddForm ?
                    null
                    :
                    <ErrorBoundaryModules customerId={itemId} setAdditionalInput={setAdditionalInput} />
                }
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    state
});

export default connect(
    mapStateToProps,
    null
)(withPolTranslation(Customer));

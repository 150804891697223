import {PolIcon} from "components/PolIcon/policon";
import React, {useCallback} from 'react';
import {useHistory} from "react-router-dom";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useIntranetExport
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectWonQuotesTab/components/ProspectWonQuoteTabShow/components/ProspectWonQuoteIntranetButton/hooks/useIntranetExport";

export default function QuoteIntranetPopupButtons({canExport, closePopup}) {
    const {t} = usePolTranslation();
    const {quote} = useProspectPageContext();
    const {addSuccessNotification, addErrorNotification} = useNotification();
    const {replace} = useHistory();
    const finishedCallback = useCallback((data) => {
        if (data?.code === 200) {
            addSuccessNotification(t('intranet_export_success'));
            replace({state: {showHasToRefresh: true, dontRefreshProspectView: true}})
            closePopup();
        }
        if (data?.code === 500) {
            addErrorNotification(
                <>
                    <p>{t('error_export_intranet')}</p>
                    {data?.error_msg && <p>{data.error_msg}</p>}
                </>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closePopup]);
    const [isLoading, handleExport] = useIntranetExport(quote.metadata.id, false, finishedCallback);

    return (
        <div className="intranet-export__btns">
            <button className="intranet-export__btns__cancel"
                    onClick={closePopup}>
                {t('cancel')}
            </button>
            <button className="intranet-export__btns__export"
                    disabled={!canExport}
                    onClick={handleExport}>
                <span>{t('export')}</span>
                {isLoading && <PolIcon icon="circle-notch" className="spinner"/>}
            </button>
        </div>
    )
}

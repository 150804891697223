import React, {Component} from 'react';
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import {getLSItem} from "v4/services/localStorage.service";
import {Header} from '../Page/_list/header';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import {substitutionData, substitutionColumns} from '../../datas/Content';
import {
    CustomToggleList, ActiveFilters, noData, DatabaseSearchContent, reOfferTask,
} from "./_partials/config";
import {DataService, updateDefaultSorting, getDefaultColumns, initData} from "../../api/datas/datas";
import {
    baseContact,
    baseMailType,
    baseProspect,
    baseProspectContent,
    baseQuote,
    baseTask, getApiUrl
} from "../../routes";
import UserService from "../../api/user/user";
import {resetLoader, setAddInfo, setLoader, setModifyInfo, setPopup} from "../../actions/content";
import {DeleteService} from "../../api/datas/delete";
import {postForm} from "../../api/form/form";
import {dispatchSelectedEntitiesIds, resetChainedSearch,} from "../../actions/datatable";
import {getProspectsSelectData} from "../../api/prospects/prospects";
import {refreshBigList, removeError, setData, setError} from "../../actions/datas";
import {PolIcon} from "../PolIcon/policon";
import Loading from "../Loading/Loading";
import {
    getViewOrdersOfEntityAndType,
    persistViewOrdersInfo, updateDatatableOrders
} from "../../utils/viewOrdersManager";
import {PostService} from "../../api/datas/post";
import ReactTooltip from "react-tooltip";
import {setFilterSearch, setPreFillingObject} from "../../actions/form";
import PopupInfoConfirm from "../Popup/_popup_info_confirm";
import PopupInfo from "../Popup/_popup_info";
import moment from "moment";
import RefreshStatsButton from "../Page/_details/refreshStatsButton";
import PolSearch from "./_partials/PolSearch";
import SelectProspect from "./_partials/selectProspects";
import ExportCSV from "./_partials/exportCSV";
import {debounce} from '../../utils/debounce';
import CampaignVoter from "v4/voters/campaignVoter";
import CampaignPopup from "../../v4/features/front/campaign/components/CampaignPopup/CampaignPopup";
import {createPortal} from "react-dom";
import ErrorBoundary from "../Error/errorBoundary";
import {withPolTranslation} from "../../v4/hooks/usePolTranslation";

const {SearchBar} = Search;

class Datatables extends Component {
    constructor(props) {
        super(props);
        this.searchContent = React.createRef();
        this.state = {
            newColumns: substitutionColumns,
            newDatas: [],
            number: {start: 'X', end: 'X', count: 'X'},
            pagination: {},
            numberItem: 10,
            noResult: false,
            defaultRoute: baseProspectContent,
            redirect: null,
            isDataLoaded: false,
            classVisibleFilters: '',
            visibleList: false,
            displayPopupCSV: false,
            filters: {},
            hiddenRowsKey: [],
            infoSearch: {
                formUrl: `/search/form`,
                submitUrl: `/search/form`,
                name: this.props.info.name,
                order_entity: this.props.info.order_entity,
                subType: this.props.info.subType,
                order_type: 'list',
                loader: 'datatable_search',
                submitLoader: 'form-search',
                type: 'search',
                loadForm: false,
                removeFilter: null,
                prospectIds: props.info && props.info.prospectIds ? props.info.prospectIds : [],
                contactIds: props.info && props.info.contactIds ? props.info.contactIds : [],
                productIds: props?.info?.productIds ?? [],
                taskIds: props.info && props.info.taskIds ? props.info.taskIds : [],
                quoteIds: props.info && props.info.quoteIds ? props.info.quoteIds : [],
                isReverse: props.info && props.info.isReverse ? props.info.isReverse : false,
                // This is used to functionally set {key: value} pairs in the search form
                additionalSearch: props.info && props.info.additionalSearch ? props.info.additionalSearch : {},
                page: 1,
                pageLength: null,
                sortField: props.info && props.info.sortField ? props.info.sortField: '',
                sortOrder: props.info && props.info.sortOrder ? props.info.sortOrder: 'asc',
                itemsPerPage: (props.info && props.info.itemsPerPage ? props.info.itemsPerPage: localStorage.getItem('itemPerPage')) || 10,
                searchQuery: null,
                isForceSubmitForm: true
            },
            selectedRows: [],
            exportParams: {
                fileName: 'export-pol.csv',
                separator: ';',
                blobType: "text/csv;charset=utf-8",
                onlyExportSelection: false,
                exportAll: true,
                columnForDefaultSort: [{
                    dataField: 'fullname',
                    order: 'asc'
                }]
            },
            resetAfterDuplicate: false,
            isDuplicateSelectLoaded: false,
            selectData: undefined,
            isDuplicateInProgress: undefined,
            popupIsOpen: false,
            tabsDefault: true,
            page: 1,
            extendedSearchForm: false,
            isAllIds: false,
            isBaseAllIds: false,
            showCampaignPopup: false,
        }

        this.setDisplayPopupCSV = this.setDisplayPopupCSV.bind(this);
        this.setNumber = this.setNumber.bind(this);
        this.setVisibleList = this.setVisibleList.bind(this);
        this.setShowCampaignPopup = this.setShowCampaignPopup.bind(this);
    }

    /**
     * Prepare data for intepretation
     *
     * @param {Object} currentDatas
     * @param {string} type
     * @param field
     * @param order
     * @param viewOrderType (form|list)
     */
    manageDatas = (currentDatas, type = 'datas', field, order, viewOrderType = 'form') => {
        const {info, t, setError, removeError} = this.props;
        let datatableHasMoneyField = false;
        let sort = [{
            dataField: field,
            order: order
        }];

        if (!field && !order) {
            sort = updateDefaultSorting(info.name, info.type);
        }

        if (!currentDatas) {
            return;
        }
        if (Array.isArray(sort)) {
            this.setState({columnForDefaultSort: [...sort]});
        }

        if (currentDatas.columns && currentDatas[type]) {
            this.initData(currentDatas[type], currentDatas.columns);
        } else {
            const data = currentDatas[type];
            const visibleInfos = getViewOrdersOfEntityAndType(info.order_entity, info.order_type, info.subType);

            if (!visibleInfos || !visibleInfos[0] || !visibleInfos[0].fields) {
                this.initData(data, []);
                return;
            }

            const index = visibleInfos.findIndex(obj => obj.type === info.subType);
            const sortVisibleInfos = visibleInfos[-1 === index ? 0 : index].fields.sort((a, b) => a.order - b.order);
            let columns = getDefaultColumns(data, t, false, visibleInfos, setError, removeError, this.setSortMemory.bind(this),
                getViewOrdersOfEntityAndType(info.order_entity, 'form', info.subType), info.subType);
            if (!sortVisibleInfos.length) {
                columns.map((item, columKey) => {
                    delete columns[columKey].hidden;
                    return '';
                });
            } else {
                columns.forEach((item) => item.order = 99);
                sortVisibleInfos.map((item, index) => {
                    columns.map((column, columKey) => {
                        if (item.key === column.dataField) {
                            columns[columKey].order = index;
                            columns[columKey].valueType = item.valueType;
                            delete columns[columKey].hidden;
                            if (item.valueType === "money") {
                                datatableHasMoneyField = true;
                            }
                        }

                        return '';
                    });
                    return '';
                });
            }

            if (!datatableHasMoneyField) {
                columns.forEach(column => {
                    delete column.footer;
                })
            }

            columns.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

            this.initData(data, columns, currentDatas?.length || data?.length || null);
        }
    };

    setNumber = (value) => {
        this.setState({
            number: value,
        });
    }

    setVisibleList = () => {
        this.setState({
            visibleList: !this.state.visibleList
        })
    }

    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder, data, searchText }) => {
        const {datas, info} = this.props;
        if(datas && info && info.name && datas[info.name] && datas[info.name].search) {
            searchText = datas[info.name].search;
        }
        if (['pagination', 'search', 'sort'].indexOf(type) === -1) {
            return;
        }

        // When searching for data the first time, the sortField is empty in the state as it is
        // given by the datatable once all columns and data are loaded.
        // However, we want to avoid multiple requests from being sent.
        // In that case, we set the state, but prevent the search form submission.
        // We expect the API to manage sorting correctly by default
        if (sortField !== '' && this.state.infoSearch.sortField === '') {
            this.setState(s =>{
                return {
                    ...s,
                    infoSearch: {
                        ...s.infoSearch,
                        sortField,
                    }
                }
            })

            return;
        }

        const delay = 'search' === type ? 1000 : 0;

        const that = this;
        debounce(() => {
            /**
             * @TODO: Fix this. Or throw it in a fire
             *
             * This is terrible... Too Bad!
             * Basically:
             * - the "listings" need to call the form search (id === "table")
             * - the "show sub-listing" need to call the subresource listing (id === an entity's id, probably)
             *
             * Because I don't know why, I don't want to know why, I shouldn't
             * have to wonder why, but for whatever reason the database WON'T update despite
             * correct forms submitting themselves, fetching new data, calling the "setData" thingy.
             *
             * To re-enable form submission on sub-resources, the condition that adds the "DatabaseSearchContent"
             * should return true. The only problem are the id === 'table' conditions everywhere.
             *
             * TL;DR: https://www.youtube.com/watch?v=k238XpMMn38
             */
            if (that.props.info.id === 'table') {
                this.setState(s => {
                    return {
                        ...s,
                        infoSearch: {
                            ...s.infoSearch,
                            page: 'search' === type ? 1 : page,
                            itemsPerPage: sizePerPage,
                            searchText: searchText,
                            sortField: sortField,
                            sortOrder: sortOrder
                        },
                        isForceSubmitForm: true
                    };
                });
            } else {
                this.props.getDatas({
                    ...that.props.info,
                    page: 'search' === type ? 1 : page,
                    itemsPerPage: sizePerPage,
                    searchText: searchText,
                    sortField: sortField,
                    sortOrder: sortOrder
                }, true)
            }
        }, delay)
    }

    setPage = value => {
        //passage en remote, le change page request de
        const {info: {name, type}, datas} = this.props
        this.setState({
            page: value
        })

        if (type === 'datas' && datas[name]) {
            datas[name].page = value
        }
    }

    setSortMemory = (field, order) => {
        const {info: {name, type}, datas} = this.props

        if (type === 'datas' && datas[name]) {
            datas[name].order = order
            datas[name].field = field
        }
    }

    setSearchBarMemory = (value) => {
        const {info: {name, type}, datas} = this.props

        if (type === 'datas' && datas[name]) {
            datas[name].search = value
        }
    }

    /**
     * Build table
     * @param datas
     * @param columns
     */
    initData = (datas = [], columns = [], length = null) => {
        const {info: {id}, t} = this.props
        const localNumberItem = id !== 'home' ? localStorage.getItem('itemPerPage') : 10;
        const {info} = this.props;
        let {numberItem, page} = this.state;
        if (localNumberItem) {
            numberItem = localNumberItem;
        }

        length = length ? length : datas.length;

        const dataSetted = initData(datas, columns, info, numberItem, this.setNumber, this.setVisibleList, t, this.setPage.bind(this), page, length);

        this.dataSizeChange({
            dataSize: dataSetted.datas.length
        });

        const end = numberItem > dataSetted.datas.length ? dataSetted.datas.length : numberItem;
        this.setState({
            newColumns: dataSetted.columns,
            newDatas: dataSetted.datas,
            number: {start: '1', end: end, count: length},
            pagination: {
                pagination: paginationFactory(dataSetted.config)
            },
            isDataLoaded: true,
        });
    };

    /**
     * Handle change size
     * @param dataSize
     */
    dataSizeChange = ({dataSize}) => {
        if (dataSize === 0) {
            this.setState({
                noResult: true
            })
        } else {
            this.setState({
                noResult: false
            })
        }
    };

    componentDidMount() {
        const {
            datas,
            info,
            form,
            datasSearch,
            info: {name, order_entity, type, id = 'table'},
            isSearch,
            setLoader,
            resetLoader
        } = this.props;
        const {infoSearch, filters} = this.state;

        document.addEventListener('mousedown', this.handleClickOutside, false);
        if (id === 'table' && datas && datas[info.name] && datas[info.name]['tables-dash']) {
            const keys = Object.keys(datas[info.name]['tables-dash']);
            const data = {datas: keys.map(key => datas[info.name]['tables-dash'][key])}
            this.manageDatas(data, type);
            resetLoader();
            this.setState({
                infoSearch: {
                    ...infoSearch,
                    formUrl: info.url + infoSearch.formUrl,
                    submitUrl: info.url + infoSearch.submitUrl,
                    order_entity: order_entity,
                    loadForm: true,
                    loaderDatatable: info.loader
                }
            });
            return;
        }

        if (id === 'table') {
            resetLoader();
            this.setState({
                infoSearch: {
                    ...infoSearch,
                    formUrl: info.url + infoSearch.formUrl,
                    submitUrl: info.url + infoSearch.submitUrl,
                    order_entity: order_entity,
                    loadForm: true,
                    loaderDatatable: info.loader
                }
            });
        }

        if (info.preSearch && id === 'table') {
            this.manageDatas(info.preSearch, type);
            return;
        }

        if (isSearch && id === 'table') {
            this.manageDatas({datas: datasSearch}, type);
            return;
        }

        if (id === 'table'
            && filters
            && Object.keys(filters).length === 0
            && datas
            && datas[name]
            && ((
                    datas?.[name]?.['tableSearch']?.datas?.length
                    && Object.keys(datas[name].filters).length
                    && form.filters
                    && Object.keys(form.filters).length
                )
                || datas?.[name]?.['table']?.datas?.length)
        ) {
            if (datas[name]) {
                this.setState({
                    filters: datas[name].filters,
                    page: datas[name].page
                }, () => {
                    this.manageDatas(datas[name]['tableSearch']
                    && datas[name]['tableSearch'].datas ? datas[name]['tableSearch']
                        : datas[name]['table'], type, datas[name].field, datas[name].order);
                })
            }
            return;

        }

        if (!form.redirect && (datas[name] && datas[name][id] && datas[name][id][type])) {
            this.setState({
                page: datas[name].page
            }, () => {
                this.manageDatas(datas[name][id], type);
            })
        } else {
            if (UserService.submitFormSearchOnLoading(info.order_entity) && id === 'table') {
                setLoader({
                    [info.loader]: true,
                });
            }
        }

        // Force search on datatable mount
        this.handleTableChange('sort', {
            page: this.state.infoSearch.page,
            sizePerPage: this.state.infoSearch.itemsPerPage,
            filters: {},
            searchText: this.state.infoSearch.searchText,
            sortField: this.state.infoSearch.sortField,
            sortOrder: this.state.infoSearch.sortOrder
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            const {
                datas, loader, togglePopup, info, setListStateInfo, setLoader,
                info: {name, type, id, reloadList}, getDatas, form: {filters},
                prospects: {
                    selectData = undefined, prospectId = undefined, entityId = undefined,
                    resetAfterDuplicate = false
                }, t, postForm
            } = this.props;
            const {infoSearch} = this.state;
            const oldResetAfterDuplicate = prevProps.prospects ? prevProps.prospects.resetAfterDuplicate ? prevProps.prospects.resetAfterDuplicate : false : false;
            const prevLoader = prevProps.loader;
            const listInfos = datas;
            if (filters) {
                this.setState({
                    filters
                });
            }

            if ((reloadList === true) || oldResetAfterDuplicate !== resetAfterDuplicate) {
                //On reconstruit le tableau avec les nouvelles données
                this.setState({
                    isDataLoaded: false,
                    newDatas: substitutionData,
                    newColumns: substitutionColumns
                });
                getDatas(this.props.info);
                if (setListStateInfo) {
                    setListStateInfo({
                        ...this.props.info,
                        reloadList: false
                    })
                }
            }

            if (selectData
                && prevProps.prospects.selectData !== selectData
                && selectData.length
            ) {
                this.setState({
                    isDuplicateSelectLoaded: true,
                    selectData,
                }, () => {
                    let selectValue = selectData.find((item) => item.value === prospectId)

                    togglePopup({
                        isOpen: true,
                        target: 'duplicate_item',
                        className: 'popup__duplicate',
                        content: (
                            <>
                                {this.state.isDuplicateSelectLoaded ? (
                                    <PopupInfoConfirm
                                        message={t('duplicate_prospect_text')}
                                        onDecline={() => {
                                            togglePopup({})
                                        }}
                                        onAccept={() => {
                                            postForm({
                                                method: 'PUT',
                                                loader: info.loader,
                                                url: info.duplicate.replace('{id}', entityId),
                                                data: {
                                                    prospectId: selectValue ? selectValue.value : null
                                                },
                                                callback: (newQuoteId) => {
                                                    if (!newQuoteId) {
                                                        return;
                                                    }
                                                    if (selectValue && selectValue.value) {
                                                        this.setState({
                                                            isDuplicateInProgress: false,
                                                            redirect: <Redirect to={{
                                                                pathname: `${baseProspect}/${selectValue.value}/devis_offres/${newQuoteId}`,
                                                                state: {
                                                                    updateInfo: true
                                                                }
                                                            }}/>,
                                                        })
                                                    }
                                                }
                                            })
                                            this.setState({
                                                isDuplicateInProgress: true,
                                            })
                                            togglePopup({});
                                        }}
                                    >
                                        <SelectProspect
                                            onChange={(value) => {
                                                selectValue = value
                                            }}
                                            t={t}
                                            placeholder={selectValue ? selectValue.label : ''}
                                        />
                                    </PopupInfoConfirm>
                                ) : <Loading message={t("loading")} specificClass={"form-loader"}/>}

                            </>
                        )
                    });
                })


                return;
            }

            if (listInfos && ((listInfos.disableCache) || (listInfos[name] && listInfos[name].reload))) {
                this.setState({
                    isDataLoaded: false,
                    newColumns: substitutionColumns,
                    newDatas: substitutionData,
                });
                setLoader({
                    [info.loader]: true,
                });

                // Si on update sur une liste sans recherche en cours
                if (Object.keys(filters).length !== 0) {
                    this.setState({
                        isForceSubmitForm: true
                    })
                }

                delete listInfos.disableCache;
                if (listInfos[name]) {
                    delete listInfos[name].reload;
                }
            }
            if (loader[info.loader] || loader[infoSearch.submitLoader] || (prevLoader && prevLoader[info.loader] && listInfos.error)) {
                if ((listInfos[name] && listInfos[name][id] && listInfos[name][id][type]) || (listInfos[name] && listInfos[name]['tableSearch'])) {
                    if (listInfos[name]['tableSearch'] && id === 'table') {
                        this.manageDatas(listInfos[name]['tableSearch'], type, null, null, 'list');
                        if (filters && Object.keys(filters).length > 0) {
                            listInfos[name].filters = filters;
                        }
                    } else {
                        this.manageDatas(listInfos[name][id], type);
                    }
                }
            }

            if (this.props.datatable && Object.keys(this.props.datatable).length) {
                const {prospectIds, contactIds, quoteIds, taskIds, productIds} = this.props.datatable;
                this.setState({prospectIds, contactIds, quoteIds, taskIds, productIds})
            }
            this.setState({
                infoSearch: {
                    ...infoSearch,
                    removeFilter: null,
                }
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    /**
     * Handle click outside search bar
     * @param e
     */
    handleClickOutside = (e) => {
        const {popupIsOpen, isDataLoaded} = this.state;
        if (isDataLoaded && !popupIsOpen && (!this.searchContent || !this.searchContent.contains(e.target))) {
            this.setState({
                classVisibleFilters: ''
            })
        }
    };

    /**
     * Click on table row
     *
     * @param e
     * @param row
     * @param rowIndex
     * @returns {boolean}
     */
    clickElement = (e, row, rowIndex) => {
        const {
            t,
            info,
            setModifyInfo,
            togglePopup,
            deleteDatas,
            getProspectsSelectData,
            postForm,
            setPopup,
            setAddInfo,
            datas,
            info: {order_entity, id}
        } = this.props;
        let {defaultRoute, hiddenRowsKey} = this.state;

        const eventTarget = e.target;
        const eventDataAction = eventTarget.dataset.action;
        const eventParentDataAction = eventTarget.parentNode.dataset.action;

        if (eventTarget.classList.contains('data__info__phone')) {
            return false;
        }

        const updateInfo = eventDataAction === 'update' || eventParentDataAction === 'update';
        const deleteInfo = eventDataAction === 'delete' || eventParentDataAction === 'delete';
        const duplicateInfo = eventDataAction === 'duplicate' || eventParentDataAction === 'duplicate';
        const reOffer = eventDataAction === 're-offer' || eventParentDataAction === 're-offer';

        setModifyInfo(false);
        setAddInfo(false)

        if (deleteInfo) {
            let contactId = null;
            if (datas
                && datas.prospects
                && datas.prospects[id]
                && datas.prospects[id].data
                && datas.prospects[id].data.contact
                && order_entity === 'Contact'
            ) {
                contactId = datas.prospects[id].data.contact.id
            }

            if (row.id === contactId) {
                togglePopup({
                    isOpen: true,
                    target: 'delete_item',
                    content: (
                        <PopupInfo
                            message={t('denied_delete')}
                            onAccept={() => {
                                togglePopup({})
                            }}
                        />
                    )
                });
            } else {
                togglePopup({
                    isOpen: true,
                    target: 'delete_item',
                    content: (
                        <PopupInfoConfirm
                            message={t('Contact' === order_entity ? 'popup_validation_suppression_contact' : 'popup_validation_suppression')}
                            onAccept={() => {
                                deleteDatas({
                                    ...info,
                                    deleteUrl: info.urlId ? info.urlId.replace('{id}', row.id) : info.detailUrl.replace('{id}', row.id),
                                    entityId: row.id
                                });

                                hiddenRowsKey.push(row.id);
                                this.setState({
                                    hiddenRows: hiddenRowsKey
                                });

                                togglePopup({});
                            }}
                            onDecline={() => {
                                togglePopup({})
                            }}
                        />
                    )
                });
            }

            return false;
        }

        if (duplicateInfo
        ) {
            if (this.props.info.order_entity !== 'MailType') {
                const prospectId = row.prospectId ? row.prospectId : row['prospect.id'];
                getProspectsSelectData(info, prospectId, row.id);
            } else {
                setPopup({
                    isOpen: true,
                    content: (
                        <PopupInfoConfirm
                            message={t('confirm_duplicate_mail')}
                            onAccept={() => {
                                postForm({
                                    method: 'PUT',
                                    loader: info.loader,
                                    url: info.duplicate.replace('{id}', row.id),
                                    callback: (newMailTypeId) => {
                                        if (!newMailTypeId) {
                                            return;
                                        }

                                        this.setState({
                                            isDuplicateInProgress: false,
                                            redirect: <Redirect to={{
                                                pathname: `${baseMailType}/edit/mail-type/${newMailTypeId}`,
                                                state: {
                                                    updateInfo: true
                                                }
                                            }}/>,
                                        })
                                    }
                                })
                                this.setState({
                                    isDuplicateInProgress: true,
                                })
                                setPopup({})
                            }}
                            onDecline={() => {
                                setPopup({})
                            }
                            }
                        />
                    )
                });

            }
            return false;
        }

        if (reOffer) {
            reOfferTask(row, id, this.redirectToReOffer.bind(this));
            return false;
        }

        this.setState({
            redirect: null,
            classVisibleFilters: 'hidden-search'
        }, () => {
            if (info.id === 'table') {
                let id = (row['@type'] !== "Prospect" && row.prospect) ? row.prospect.id : row.id;
                if (row.prospectId) {
                    id = row.prospectId;
                }

                if (row['prospect.id']) {
                    id = row['prospect.id'];
                }

                if (info.name === 'prospects') {

                    this.setState({
                        redirect: <Redirect to={{
                            pathname: `${baseProspect}/${id}/${defaultRoute}`,
                            state: {
                                toSetLastVisit: UserService.isLastVisit(info.name),
                                updateProspect: updateInfo,
                                isCompany: !!row.company
                            }
                        }}/>
                    })
                } else {
                    if (info.name === 'mailType') {
                        this.setState({
                            redirect: <Redirect to={{
                                pathname: `${baseMailType}/edit/mail-type/${row.id}`,
                                state: {
                                    updateInfo: updateInfo,
                                }
                            }}/>
                        })
                    } else if (info.name === 'actions') {
                        let route = UserService.getTabSlugFor(info.subType);
                        route = route ? route : row.isClosed && true === row.isClosed ? info.routeHistorique : info.route;

                        this.setState({
                            redirect: <Redirect to={{
                                pathname: `${baseProspect}/${id}/${route}/${row.id}`,
                                state: {
                                    toSetLastVisit: UserService.isLastVisit(info.name),
                                    updateInfo: updateInfo,
                                }
                            }}/>
                        })
                    } else {
                        let route = UserService.getTabSlugFor(info.subType);
                        route = route ? route : row.status && row.status.isWon ? info.routeBusiness : info.route;

                        this.setState({
                            redirect: <Redirect to={{
                                pathname: `${baseProspect}/${id}/${route}/${row.id}`,
                                state: {
                                    toSetLastVisit: UserService.isLastVisit(info.name),
                                    updateInfo: updateInfo,
                                }
                            }}/>
                        })
                    }
                }
            } else {
                this.setState({
                    redirect: <Redirect to={{
                        pathname: `${window.location.pathname}/${row.id}`,
                        state: {
                            updateInfo: updateInfo,
                        }
                    }}/>
                })
            }
        });
    };

    redirectToReOffer(row, prospectId, field) {
        const {setAddInfo, setPreFillingObject} = this.props;

        field = field ? row.quoteNumber ? field + ' ' + row.quoteNumber : field : field;

        setPreFillingObject({
            name: {
                value: field !== null ? field : '',
                isNotRealId: false
            },
            beginAt: {value: moment().format('DD-MM-YYYY HH:mm'), isNotRealId: true},
            contacts: [row.contactIdRealName],
            managedBy: {value: row.managedByRealName, isNotRealId: true},
            isFormPrefilled: true,
        });

        this.setState({
            redirect: <Redirect to={{
                pathname: `${baseProspect}/${prospectId}/actions`
            }}/>
        }, () => setAddInfo(true));
    }

    /**
     * Drag column
     * @param e
     * @param index
     */
    onDragStart = (e, index) => {
        this.draggedItem = this.state.newColumns[index];
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
        e.dataTransfer.setData("text/html", e.parentNode);
    };

    /**
     * Drag over other column
     * @param index
     */
    onDragOver = index => {
        const draggedOverItem = this.state.newColumns[index];
        if (this.draggedItem === draggedOverItem) {
            return;
        }

        let items = this.state.newColumns.filter(item => item !== this.draggedItem);
        items.splice(index, 0, this.draggedItem);

        this.setState({
            newColumns: items
        });
    };

    /**
     * set new orders
     * @param columnsToggles
     */
    setOrders = (columnsToggles) => {
        const {info, postForm} = this.props;
        let {newColumns, newDatas} = this.state;
        columnsToggles.columns = newColumns.map((item, index) => {
            return {
                ...item,
                order: index
            }
        });

        const newViewOrders = updateDatatableOrders(info, columnsToggles, newColumns, newDatas);
        debounce(() => {
            persistViewOrdersInfo(info.order_entity, 'list', newViewOrders, postForm, null, () => {
                this.setState({
                    newColumns: newColumns,
                    newDatas: newDatas,
                    newColumnsForExport: columnsToggles.toggles
                })
            }, info.subType);
        }, 500)
    };

    removeSearch = (key, secondKey = false) => {
        const {infoSearch, filters} = this.state;
        let valueDeleted = undefined;

        if (secondKey === false) {
            delete filters[key];
        } else {
            if (Array.isArray(filters[key])) {
                valueDeleted = filters[key].splice(secondKey, 1);
            } else {
                filters[key].filterValue.splice(secondKey, 1);
                valueDeleted = filters[key].value.splice(secondKey, 1);
            }
        }

        this.setState({
            infoSearch: {
                ...infoSearch,
                removeFilter: key,
                oldValueFilter: secondKey,
                valueDeleted
            },
            filters
        })
    };

    resetChainedSearchFromState = () => {
        const {infoSearch, filters} = this.state;
        const {resetChainedSearch} = this.props;

        resetChainedSearch();
        this.setState({
            infoSearch: {
                ...infoSearch,
                prospectIds: [],
                contactIds: [],
                taskIds: [],
                quoteIds: [],
                productIds: [],
            },
            filters: {
                ...filters,
                prospectIds: [],
                contactIds: [],
                taskIds: [],
                quoteIds: [],
                productIds: [],
            }
        })
    }

    resetMapFilter = () => {
        const {infoSearch, filters} = this.state;
        delete infoSearch.additionalSearch.lng;
        delete infoSearch.additionalSearch.lat;
        delete infoSearch.additionalSearch.distance;
        delete filters.lng;
        delete filters.lat;
        delete filters.distance;
        this.setState({
            infoSearch: {
                ...infoSearch,
                removeFilter: 'prospectIds'
            },
            filters
        })
    }


    changeColumnsCSV = (index, exportable) => {
        const {newColumns} = this.state;
        newColumns[index].csvExport = exportable;

        this.setState({
            newColumns: newColumns
        })
    };

    exportColumnsWithOrder = (columns, onExport) => {
        const {newColumns} = this.state;
        this.setState({
            newColumns: columns
        }, () => {
            onExport();
            this.setState({
                newColumns: newColumns
            });
        });
    };

    selectElement = (row, isSelect) => {
        const {dispatchSelectedEntitiesIds, info: {order_entity}} = this.props;
        let {selectedRows, exportParams} = this.state;

        if (isSelect) {
            selectedRows = [...selectedRows, row.id]
        } else {
            selectedRows = selectedRows.filter(x => x !== row.id)
        }

        this.setState({
            selectedRows: selectedRows,
            exportParams: {
                ...exportParams,
                onlyExportSelection: !!selectedRows.length
            },
        }, () => {
            dispatchSelectedEntitiesIds(selectedRows, order_entity);
        });

        return true;
    };

    handleOnSelectAll = () => {
        let {selectedRows, exportParams, isAllIds, isBaseAllIds} = this.state;
        if (selectedRows.length) {
            selectedRows = [];
            isAllIds = false;
            isBaseAllIds = false;
        } else {
            isAllIds = true;
            isBaseAllIds = true;
        }

        this.setState({
            selectedRows: selectedRows,
            exportParams: {
                ...exportParams,
                onlyExportSelection: !!selectedRows.length
            },
            isAllIds: isAllIds,
            isBaseAllIds: isBaseAllIds
        });
    };

    listingToMap = () => {
        const {setDataListMap, setPreFillingObject, setPopup, t} = this.props;
        const {newDatas, filters} = this.state;

        if (newDatas.length) {
            setPreFillingObject(filters)
            setDataListMap('maps', 'tableSearch', {datas: newDatas})
            this.setState({
                redirect: <Redirect to={{
                    pathname: `${baseProspect}/all/maps`,
                }}/>
            })
        } else {
            setPopup({
                isOpen: true,
                content: (
                    /* Il faudrait sûrement traduire le message */
                    <PopupInfo message={t('aucun_resultat_trouve')} onAccept={() => {
                        setPopup({})
                    }}/>
                )
            });
        }
    }

    setDisplayPopupCSV(fromPopup) {
        const {displayPopupCSV} = this.state;
        if (fromPopup) {
            this.setState({displayPopupCSV: false})
        } else if (!displayPopupCSV) {
            this.setState({displayPopupCSV: true})
        }
    }

    setPopupIsOpen(value) {
        this.setState({popupIsOpen: value});
    }

    setExtendedSearchForm = () => {
        this.setState({extendedSearchForm: !this.state.extendedSearchForm})
    }

    setShowCampaignPopup = (value) => {
        this.setState({showCampaignPopup: value})
    }

    toggleShowCampaignPopup = () => this.setShowCampaignPopup(!this.state.showCampaignPopup);

    render() {
        const {dispatchSelectedEntitiesIds, datas, title, loader, t, className = '',
            info: {id = 'table', name, classNameTabs, type, order_entity}, postService, customTabSearch} = this.props;
        const {
            prospectIds,
            contactIds,
            taskIds,
            quoteIds,
            displayPopupCSV,
            isDuplicateInProgress,
            columnForDefaultSort,
            extendedSearchForm,
            page,
            numberItem,
            isBaseAllIds,
            showCampaignPopup,
        } = this.state;
        let {
            newColumns,
            newDatas,
            exportParams,
            selectedRows,
            number,
            pagination,
            noResult,
            redirect,
            isDataLoaded,
            visibleList,
            classVisibleFilters,
            filters,
            hiddenRowsKey,
            newColumnsForExport
        } = this.state;
        let classContainer = noResult ? 'no-datas' : '';
        const classVisible = visibleList ? 'table-column-active' : '';
        const user = getLSItem('user');
        const isMailingEnabled = !!(user && user.email && user.email.length)
        let translateResult;

        switch (name) {
            case "prospects":
                number.count > 1 ? (translateResult = 'resultats_elements_prospects') : (translateResult = 'resultats_elements_prospect')
                break;
            case "contacts":
                number.count > 1 ? (translateResult = 'resultats_elements_contacts') : (translateResult = 'resultats_elements_contact')
                break;
            case "actions":
                number.count > 1 ? (translateResult = 'resultats_elements_actions') : (translateResult = 'resultats_elements_action')
                break;
            case "devis":
                translateResult = 'resultats_elements_devis'
                break;
            case "mailType":
                number.count > 1 ? (translateResult = 'resultats_elements_mails') : (translateResult = 'resultats_elements_mail')
                break;
            default :
                number.count > 1 ? (translateResult = 'resultats_elements') : (translateResult = 'resultats_element')
        }

        const loadingComponent = isDuplicateInProgress ?
            <Loading message={t("loading")} specificClass={"form-loader"}/> : null

        const {canCreateCampaign} = CampaignVoter();

        return (
            <>
                {redirect}

                {id === 'table' && this.state.infoSearch && this.state.infoSearch.loadForm ? (
                    <div className={`content-search ${classVisibleFilters} ${extendedSearchForm ? 'extended' : ''}`}
                         ref={node => {
                             this.searchContent = node
                         }}
                    >
                        <DatabaseSearchContent
                            itemType={className}
                            setVisibleFilters={() => this.setState({
                                classVisibleFilters: ''
                            })}
                            info={this.state.infoSearch}
                            activeFilters={this.state.filters}
                            removeSearch={this.removeSearch}
                            setPopupIsOpen={this.setPopupIsOpen.bind(this)}
                            setExtendedMenu={this.setExtendedSearchForm}
                            isForceSubmitForm={this.state.isForceSubmitForm}
                            isAllIds={this.state.isAllIds}
                            setIsAllIds={(val) => {
                                this.setState({
                                    isAllIds: val
                                })
                            }}
                            reInitAllIds={() => {
                                this.setState({
                                    selectedRows: [],
                                    isAllIds: false,
                                    isBaseAllIds: false
                                });
                            }}
                            setSelectedRows={(val) => {
                                this.setState({
                                    selectedRows: val
                                }, () => {
                                    dispatchSelectedEntitiesIds(val, order_entity);
                                })
                            }}
                            setForceSubmit={(val) => {
                                this.setState({
                                    isForceSubmitForm: val
                                })
                            }}
                            customTabSearch={customTabSearch}
                        />
                    </div>
                ) : null}
                {loadingComponent}
                {id === 'table' ? (
                    <>
                        <Header title={title}
                                className={className}
                                number={t(translateResult, number)}
                                loading={loader.table}
                                entity={name}
                                setVisibleFilters={() => this.setState({
                                    classVisibleFilters: 'active-search'
                                })}
                                listingToMap={this.listingToMap}
                        />
                    </>
                ) : id === 'search' ? (
                    <>
                        <Header title={t(title)}
                                className={className}
                                number={t(translateResult, number)}
                                loading={loader.table}
                        />
                    </>
                ) : null}

                {!isDataLoaded ? (
                    <div className={`table-container`}>
                        <div className={`content-search`}
                             ref={node => {
                                 this.searchContent = node
                             }}
                        >
                        </div>

                        {id === 'table' ? (
                            <div className={'row row-fluid justify-content-end'}>
                                <div className={'col-12 col-md-3 col-xl-2 col-fluid'}>
                                    <div className={'datatable__search datatable__loader'}>
                                        <SearchBar
                                            onSearch={() => null}
                                            className={'datatable__search__input'}
                                            placeholder={t('votre_recherche')}/>
                                    </div>
                                </div>
                                <div className={'col-2 col-md-auto col-fluid'}>
                                    <li className={'datatable__search-filter btn__secondary'} onClick={() => {
                                        this.setState({
                                            classVisibleFilters: 'active-search'
                                        })
                                    }}>
                                        <PolIcon icon="filters" className={'action__picto'}/>
                                        <p>{t('filters_button_text')}</p>
                                    </li>
                                </div>
                            </div>
                        ) : null}
                        <div className={'table-content'}>
                            <Loading message={t("loader_message_search")} specificClass={"table-loader"}/>

                            <BootstrapTable
                                remote
                                classes={`datatable ${className} datatable-loader`}
                                keyField='id'
                                data={newDatas}
                                columns={newColumns}
                                noDataIndication={() => noData(t)}
                                onTableChange={ this.handleTableChange }
                            />
                        </div>
                    </div>
                ) : (
                    <ToolkitProvider
                        keyField='id'
                        data={newDatas}
                        columns={newColumns}
                        columnToggle
                        search
                        exportCSV={exportParams}
                    >
                        {props => (
                            <div className={`table-container ${classContainer} ${classNameTabs}`}>

                                {id === 'table' ? (
                                        <div className={'row row-fluid justify-content-end align-items-center '}>
                                            <div className={'col-12 col-md-8 col-fluid align-self-center'}>
                                                <div className={'content-action'}>
                                                    <ul className={'content-action__list'}>
                                                        {name === 'prospects' ? (
                                                            <>
                                                                <li className={'action btn__secondary'}
                                                                    onClick={this.listingToMap.bind(this)}
                                                                    data-tip={t('map_display')}>
                                                                    <PolIcon icon="map" className={'action__picto'}/>
                                                                </li>
                                                            </>
                                                        ) : null}
                                                        <li className={'action btn__secondary'}
                                                            onClick={() => this.setDisplayPopupCSV(false)}
                                                            data-tip={t('export_data')}>
                                                            <ExportCSV {...props.csvProps} t={t} columns={newColumns}
                                                                       changeColumnsCSV={(index, exportable) => this.changeColumnsCSV(index, exportable)}
                                                                       displayPopupCSV={displayPopupCSV}
                                                                       setDisplayPopupCSV={this.setDisplayPopupCSV}
                                                                       newColumnsForExport={newColumnsForExport}
                                                                       infoSearch={this.state.infoSearch}
                                                                       selectedRows={this.state.selectedRows}
                                                                       filters={this.state.filters}
                                                                       searchContext={this.node ? this.node.searchContext : {}}
                                                            />
                                                        </li>
                                                        {name !== 'mailType' && <li className={'action__dropdown btn__secondary'}>
                                                            <PolIcon icon="actions"
                                                                     className={'action__picto action__dropdown__Btn'}/>
                                                            <ul className={'action__dropdown__content'}>
                                                                {(name === 'contacts' || name === 'prospects') ? (
                                                                    <>
                                                                        <li className={`action__dropdown__item ${!isMailingEnabled ? 'action__dropdown__item__disabled' : ''}`}
                                                                            onClick={() => {
                                                                                postService({
                                                                                    url: getApiUrl('emailing', 'send'),
                                                                                    submittedData: {
                                                                                        type: name,
                                                                                        value: selectedRows
                                                                                    },
                                                                                    type: 'send_emailing',
                                                                                    callback: () => {}
                                                                                });
                                                                            }}>
                                                                            <p>{t('send_emailing')}</p>
                                                                        </li>
                                                                        {
                                                                            canCreateCampaign() && (
                                                                                <>
                                                                                    <li className={`action__dropdown__item${selectedRows.length > 0 ? '' : ' action__dropdown__item__disabled'}`}
                                                                                        onClick={this.toggleShowCampaignPopup}>
                                                                                        <p>{t("send_campaign")}</p>
                                                                                    </li>
                                                                                    <ErrorBoundary>
                                                                                        {showCampaignPopup && (
                                                                                            createPortal(
                                                                                                <CampaignPopup selectedIds={selectedRows} type={name}
                                                                                                               toggleShow={this.toggleShowCampaignPopup}/>
                                                                                                , document.querySelector('body'))
                                                                                        )}
                                                                                    </ErrorBoundary>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                    ) : null}
                                                                {name !== 'contacts' ? (
                                                                    <li className={'action__dropdown__item'}
                                                                        onClick={() => {
                                                                            this.resetChainedSearchFromState();
                                                                            this.setState({
                                                                                redirect: <Redirect to={{
                                                                                    pathname: baseContact,
                                                                                    state: {prospectIds, taskIds, quoteIds}
                                                                                }}/>
                                                                            })
                                                                        }}>
                                                                        <p>{t('research_associated_contacts')}</p>
                                                                    </li>) : null}
                                                                {name !== 'actions' && name !== 'devis' ? (
                                                                    <li className={'action__dropdown__item'}
                                                                        onClick={() => {
                                                                            this.resetChainedSearchFromState();
                                                                            this.setState({
                                                                                redirect: <Redirect to={{
                                                                                    pathname: baseTask,
                                                                                    state: {prospectIds, contactIds}
                                                                                }}/>
                                                                            })
                                                                        }}>
                                                                        <p>{t('research_associated_actions')}</p>
                                                                    </li>) : null}
                                                                {name === 'devis' || name === 'actions' ? (
                                                                    <>
                                                                        <li className={'action__dropdown__item'}
                                                                            onClick={() => {
                                                                                this.resetChainedSearchFromState();
                                                                                this.setState({
                                                                                    redirect: <Redirect to={{
                                                                                        pathname: baseProspect,
                                                                                        state: {quoteIds, taskIds}
                                                                                    }}/>
                                                                                })
                                                                            }}>
                                                                            <p>{t('research_associated_prospects')}</p>
                                                                        </li>
                                                                        <li className={'action__dropdown__item'}
                                                                            onClick={() => {
                                                                                this.resetChainedSearchFromState();
                                                                                this.setState({
                                                                                    redirect: <Redirect to={{
                                                                                        pathname: baseProspect,
                                                                                        state: {
                                                                                            quoteIds,
                                                                                            taskIds,
                                                                                            isReverse: true
                                                                                        }
                                                                                    }}/>
                                                                                })
                                                                            }}>
                                                                            <p>{t('reverse_research_associated_prospects')}</p>
                                                                        </li>
                                                                        <li className={'action__dropdown__item'}
                                                                            onClick={() => {
                                                                                this.resetChainedSearchFromState();
                                                                                this.setState({
                                                                                    redirect: <Redirect to={{
                                                                                        pathname: baseContact,
                                                                                        state: {
                                                                                            quoteIds,
                                                                                            taskIds,
                                                                                            isReverse: true
                                                                                        }
                                                                                    }}/>
                                                                                })
                                                                            }}>
                                                                            <p>{t('reverse_research_associated_contacts')}</p>
                                                                        </li>
                                                                    </>
                                                                ) : null}
                                                                {name === 'contacts' || name === 'prospects' ? (
                                                                    <li className={'action__dropdown__item'}
                                                                        onClick={() => {
                                                                            this.resetChainedSearchFromState();
                                                                            this.setState({
                                                                                redirect: <Redirect to={{
                                                                                    pathname: baseQuote,
                                                                                    state: {prospectIds, contactIds}
                                                                                }}/>
                                                                            })
                                                                        }}>
                                                                        <p>{t('research_associated_quote')}</p>
                                                                    </li>) : null}
                                                                {name === 'actions' ? (
                                                                    <RefreshStatsButton isNotBtn={true}/>
                                                                ) : null}
                                                            </ul>
                                                        </li>}
                                                    </ul>
                                                    <ReactTooltip effect="solid"/>
                                                </div>
                                                <ActiveFilters
                                                    filters={filters}
                                                    removeSearch={(key, secondKey = false) => this.removeSearch(key, secondKey)}
                                                    resetChainedSearch={this.resetChainedSearchFromState}
                                                    resetMapFilter={this.resetMapFilter}
                                                    customTabSearch={customTabSearch}
                                                />
                                            </div>

                                            {/* Barre de recherche */}
                                            <div className={'col-10 col-md datatable__search-container'}>
                                                <div className={'datatable__search datatable__loader'}>
                                                    <PolSearch {...props.searchProps}
                                                               setSearchBarMemory={this.setSearchBarMemory.bind(this)}
                                                               preSearch={type === 'datas' && datas[name] ?
                                                                   datas[name].search : null}
                                                    />
                                                </div>
                                            </div>

                                            <div className={'col-2 col-md-auto col-fluid'}>
                                                <li className={'datatable__search-filter btn__secondary'} onClick={() => {
                                                    this.setState({
                                                        classVisibleFilters: 'active-search'
                                                    })
                                                }}>
                                                    <PolIcon icon="filters" className={'action__picto'}/>
                                                    <p>{t('filters_button_text')}</p>
                                                </li>
                                            </div>
                                        </div>
                                    ) :
                                    <p className={'result_header_sub_datatable'}>{t('result_header_datatable_homepage', {
                                        start: number && number.count > 0 && page ? ((page - 1) * numberItem) + parseInt(number.start) : 0,
                                        end: number ?
                                            ((page - 1) * numberItem) + parseInt(number.end) < number.count ?
                                                ((page - 1) * numberItem) + parseInt(number.end) : number.count
                                            : 0,
                                        count: number ? number.count : 0,
                                        many: number.count > 1 ? 's' : '',
                                        all: number && number.count > 1 ? 's' : '',
                                    })}</p>
                                }

                                <div className={'table-content  table-content__margin'}>
                                    <div className={`table-column ${classVisible}`}>
                                        <CustomToggleList {...props.columnToggleProps}
                                                          onClick={() => this.setState({
                                                              visibleList: false
                                                          })}
                                                          onDragOver={(idx) => this.onDragOver(idx)}
                                                          onDragStart={(e, idx) => this.onDragStart(e, idx)}
                                                          setOrders={() => this.setOrders(props.columnToggleProps)}/>
                                    </div>


                                    <Loading message={t("loader_message_search")} specificClass={"table-loader"}/>

                                    <BootstrapTable
                                        {...props.baseProps}
                                        remote
                                        classes={`datatable ${className} datatable-loader`}
                                        selectRow={{
                                            mode: 'checkbox',
                                            selected: selectedRows,
                                            selectionHeaderRenderer: () => {
                                                let checked = {};
                                                if (isBaseAllIds) {
                                                    checked = {
                                                        checked: 'checked'
                                                    }
                                                }

                                                return (
                                                    'table' === id ?
                                                        <div onMouseUp={(e) => this.handleOnSelectAll()}>
                                                            <input type={'checkbox'} {...checked} onChange={() => {
                                                            }}/>
                                                        </div>
                                                    : null
                                                )
                                            },
                                            onSelect: (row, isSelect, rowIndex, e) => this.selectElement(row, isSelect)
                                        }}
                                        {...pagination}
                                        noDataIndication={() => noData(t)}
                                        onDataSizeChange={this.dataSizeChange}
                                        rowEvents={{
                                            onClick: (e, row, rowIndex) => this.clickElement(e, row, rowIndex)
                                        }}
                                        hiddenRows={hiddenRowsKey}
                                        filter={filterFactory()}
                                        ref={n => this.node = n}
                                        defaultSorted={columnForDefaultSort}
                                        keyField="id"
                                        onTableChange={ this.handleTableChange }
                                    />
                                </div>
                            </div>
                        )}
                    </ToolkitProvider>
                )}
            </>
        )
    };
}

const mapStateToProps = ({datas, datatable, content: {loader}, error, form, prospects}) => ({
    datas,
    loader,
    error,
    form,
    prospects,
    datatable
});

const mapDispatchToProps = dispatch => {
    return {
        getDatas: (info, disableCache) => dispatch(DataService(info, disableCache)),
        setPopup: (info) => dispatch(setPopup(info)),
        deleteDatas: (info) => dispatch(DeleteService(info)),
        setModifyInfo: (modifyInfo) => dispatch(setModifyInfo(modifyInfo)),
        togglePopup: (popupInfo) => dispatch(setPopup(popupInfo)),
        postForm: (info) => dispatch(postForm(info)),
        postService: (info) => dispatch(PostService(info)),
        dispatchSelectedEntitiesIds: (entityIds, entity) => dispatch(dispatchSelectedEntitiesIds(entityIds, entity)),
        resetChainedSearch: () => dispatch(resetChainedSearch()),
        getProspectsSelectData: (info, prospectId, entityId) => dispatch(getProspectsSelectData(info, prospectId, entityId)),
        setDataListMap: (title, dataType, datas) => dispatch(setData(title, dataType, datas)),
        setAddInfo: (addInfo) => dispatch(setAddInfo(addInfo)),
        setPreFillingObject: (data) => dispatch(setPreFillingObject(data)),
        setError: error => dispatch(setError(error)),
        removeError: () => dispatch(removeError()),
        setLoader: (value) => dispatch(setLoader(value)),
        resetLoader: () => dispatch(resetLoader()),
        setFilterSearch: (data) => dispatch(setFilterSearch(data)),
        refreshBigList: (name) => dispatch(refreshBigList(name)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(React.memo(Datatables)))

import {fetchHandler, manageError} from "../datas/datas";
import {setFolderList, setDirectoryList} from "../../actions/ged";
import {setLoader} from "../../actions/content";

let controllerGed;

export function gedData(info) {
    return function (dispatch, getState) {
        const user = getState().user;
        let token = user.token ? user.token : localStorage.getItem('token');

        if (controllerGed && info.useAbortController) {
            controllerGed.abort();
        }

        controllerGed = new AbortController();

        dispatch(setLoader({
            [info.loader]: true
        }));

        return fetch(process.env.REACT_APP_HOST_API + info.url, {
            method: info.method,
            signal: controllerGed.signal,
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            }
        }).then(
            fetchHandler,
            error => {
                if (error.name !== 'AbortError') {
                    dispatch(manageError(true, info))
                }
            }
        ).then(
            data => {
                if (data) {
                    switch (info.type) {
                        case 'folderList':
                            dispatch(setFolderList(data['hydra:member']))
                            break;
                        case 'list':
                            dispatch(setDirectoryList(data.customerResources ? data.customerResources['hydra:member'] : data['hydra:member'], info.getType, info.isRoot))
                            break;
                        default:
                    }
                    if (info.callback) {
                        info.callback();
                    }
                    dispatch(setLoader({
                        [info.loader]: false
                    }));
                }
            },
            error => {
                dispatch(manageError(error.status, info))
            }
        );
    };
}

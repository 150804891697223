import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_QUOTES} from "v4/data/tabNames";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {PROSPECT_ITEM_CUSTOMER_CODE} from "v4/data/apiRoutes";
import {Error404} from "../Error/404";
import Loader from "../../v4/components/ui/Loader/Loader";

export default function CustomerCodeProspectLoader() {
    const [{data, isFinished, errorData}, fetchProspects] = useFetch();
    const {customerCode} = useParams()
    const {replace} = useHistory();

    useEffect(() => {
        fetchProspects({
            url: generateUrl(PROSPECT_ITEM_CUSTOMER_CODE, {customerCode})
        });
    }, [customerCode, fetchProspects]);

    useEffect(() => {
        if (isFinished && data) {
            const prospectId = data?.data?.id;
            replace(prospectId
                ? `${routesBase.baseProspect}/${prospectId}/${TAB_QUOTES}`
                : routesBase.baseProspect
            );
        }
    }, [isFinished, data, replace]);

    if (!isFinished) {
        return <Loader className={"admin-loader"} />;
    }

    if (errorData?.message === 'prospect_item_not_found_msg') {
        return <Error404/>;
    }

    return null;
}

export const DEFAULT_ACTIONS_GROUP_CODE = 'defaultActions';

export default function useConfiguredActions(actions) {
    const actionsByGroupCode = actions.reduce((acc, action) => {
        const group = action.groupCode ?? DEFAULT_ACTIONS_GROUP_CODE;
        acc[group] = [...(acc[group] ?? []), action];
        return acc;
    }, {});

    Object.entries(actionsByGroupCode).forEach(([groupCode, actions]) => {
        if (groupCode === DEFAULT_ACTIONS_GROUP_CODE || actions.length !== 1) return;

        actionsByGroupCode[DEFAULT_ACTIONS_GROUP_CODE] = [...(actionsByGroupCode[DEFAULT_ACTIONS_GROUP_CODE] ?? []), ...actions];
        delete actionsByGroupCode[groupCode];
    });

    return actionsByGroupCode;
}

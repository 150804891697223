import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {NavLink, Prompt} from "react-router-dom";
import ProductVoter from "v4/voters/productVoter";
import {setLoader} from "../../../actions/content";
import {setError} from "../../../actions/datas";
import {dataFilter} from "../../../api/datas/datas";
import {postForm} from "../../../api/form/form";
import UserService from "../../../api/user/user";
import {
    CONDITION_FIELD,
    CONDITION_VALUE,
    SHOW_CONDITION_FIELD,
    TYPE_PARENT_FIELD
} from "../../../components/Form/dropdownOrders";
import Form from '../../../components/Form/form'
import {PolIcon} from "../../../components/PolIcon/policon";
import {persistViewOrdersInfo} from "../../../utils/viewOrdersManager";
import {withPolTranslation} from "../../../v4/hooks/usePolTranslation";
import OrderLocalStorage from "./ordersLocalStorage";

const {canProductShowList, canAdd: canAddProduct, canEdit: canEditProduct} = ProductVoter()

const FORM = 'form';
const SEARCH = 'search';

const PROSPECT = 'prospect';
const GROUP_PROSPECT_INDIVIDUAL = 'individual';
const GROUP_PROSPECT_BUSINESS = 'business';
const FORM_BUSINESS = 'formBusiness';
const FORM_INDIVIDUAL = 'formIndividual';
const PROSPECT_URLS = {
    [FORM]: {
        [FORM_BUSINESS]: '/api/prospect/business/form',
        [FORM_INDIVIDUAL]: '/api/prospect/individual/form',
    },
    [SEARCH]: '/api/prospects/search/form',
};

const CONTACT = 'contact';
const CONTACT_URLS = {
    [FORM]: '/api/contact/form',
    [SEARCH]: '/api/contacts/search/form',
};

const TASK = 'task';
const TASK_URLS = {
    [FORM]: '/api/task/form',
    [SEARCH]: '/api/tasks/search/form',
};

const QUOTE = 'quote';
const QUOTE_URLS = {
    [FORM]: '/api/quote/form',
    [SEARCH]: '/api/quotes/search/form',
};

const URLS = {
    [PROSPECT]: PROSPECT_URLS,
    [CONTACT]: CONTACT_URLS,
    [TASK]: TASK_URLS,
    [QUOTE]: QUOTE_URLS,
}

const ENTITIES = {
    [PROSPECT]: 'Prospect',
    [CONTACT]: 'Contact',
    [TASK]: 'Task',
    [QUOTE]: 'Quote',
}

export const GROUPS = {
    [GROUP_PROSPECT_INDIVIDUAL]: GROUP_PROSPECT_INDIVIDUAL,
    [GROUP_PROSPECT_BUSINESS]: GROUP_PROSPECT_BUSINESS,
    [CONTACT]: CONTACT,
    [TASK]: TASK,
    [QUOTE]: QUOTE,
}

const defaultInfoSelect = [
    {
        id: PROSPECT,
        // 'Prospect au lieu de 'prospect' à cause de la traduction qui ne gère pas les objets (voir pol-fr-FR.json)
        name: 'Prospect',
        testIsGranted: () => true,
        list: [
            {
                id: FORM_BUSINESS,
                text: 'prospect_business',
                url: URLS[PROSPECT][FORM][FORM_BUSINESS],
                name: 'admin_orders_business',
                type: FORM,
                group: GROUPS[GROUP_PROSPECT_BUSINESS],
                entity: ENTITIES[PROSPECT],
            },
            {
                id: FORM_INDIVIDUAL,
                text: 'prospect_individual',
                url: URLS[PROSPECT][FORM][FORM_INDIVIDUAL],
                name: 'admin_orders_form_individual',
                type: FORM,
                group: GROUPS[GROUP_PROSPECT_INDIVIDUAL],
                entity: ENTITIES[PROSPECT],
            },
            {
                id: 'formSearch',
                text: 'search',
                url: URLS[PROSPECT][SEARCH],
                name: 'admin_orders_form_search',
                type: SEARCH,
                entity: ENTITIES[PROSPECT],
            },
        ]
    },
    {
        id: CONTACT,
        name: 'contact',
        testIsGranted: () => true,
        list: [
            {
                id: 'formContact',
                text: FORM,
                url: URLS[CONTACT][FORM],
                name: 'admin_orders_form_contact',
                type: FORM,
                entity: ENTITIES[CONTACT],
                group: GROUPS[CONTACT]
            },
            {
                id: 'formSearch',
                text: SEARCH,
                url: URLS[CONTACT][SEARCH],
                name: 'admin_orders_form_search',
                type: SEARCH,
                entity: ENTITIES[CONTACT],
            },
        ]
    },
    {
        id: TASK,
        name: 'task',
        testIsGranted: () => true,
        list: [
            {
                id: 'formTask',
                text: FORM,
                url: URLS[TASK][FORM],
                name: 'admin_orders_form_task',
                type: FORM,
                entity: ENTITIES[TASK],
                group: GROUPS[TASK]
            },
            {
                id: 'formSearch',
                text: SEARCH,
                url: URLS[TASK][SEARCH],
                name: 'admin_orders_form_search',
                type: SEARCH,
                entity: ENTITIES[TASK],
            },
        ]
    },
    {
        id: QUOTE,
        name: 'quote',
        testIsGranted: () => true,
        list: [
            {
                id: 'formTask',
                text: FORM,
                url: URLS[QUOTE][FORM],
                name: 'admin_orders_form_task',
                type: FORM,
                entity: ENTITIES[QUOTE],
                group: GROUPS[QUOTE]
            },
            {
                id: 'formSearch',
                text: SEARCH,
                url: URLS[QUOTE][SEARCH],
                name: 'admin_orders_form_search',
                type: SEARCH,
                entity: ENTITIES[QUOTE],
            },
        ]
    },
    {
        id: 'product',
        name: 'product',
        testIsGranted: () => (canAddProduct() || canEditProduct() || canProductShowList()),
        list: [
            {
                id: 'formTask',
                text: 'form',
                url: '/api/products/form?isLegacy=true',
                name: 'admin_orders_form_product',
                type: 'form',
                entity: 'Product',
            },
            {
                id: 'formSearch',
                text: 'search',
                url: '/api/products/search/form?isLegacy=true',
                name: 'admin_orders_form_product_search',
                type: 'search',
                entity: 'Product',
            },
        ]
    }
];

let infoSelect = defaultInfoSelect;

class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            parentEntity: null,
            orders: [],
            inputs: [],
            requestNumber: 0,
            viewSelected: null,
            info: {
                loadForm: false
            },
            infoPost: {
                url: '/api/view_orders',
                loader: 'post_admin_orders',
                method: 'PUT'
            },
            isEdited: false
        }
    }

    componentDidMount() {
        this.addTabToOrderList();
    }

    /**
     * Add all customs tabs form and formSearch to the orderList
     */
    addTabToOrderList = () => {
        const tabs = UserService.getTabs().map(tab => {
            if ('subMenu' in tab) {
                return tab.subMenu;
            }
            return tab;
        }).flat();

        tabs.map((tab) => {


            if (-1 !== infoSelect.findIndex(info => info.id === tab.id)) {
                return tab;
            }

            if (PROSPECT === tab.searchType) {
                infoSelect = [
                    ...infoSelect,
                    {
                        id: PROSPECT,
                        // 'Prospect au lieu de 'prospect' à cause de la traduction qui ne gère pas les objets (voir pol-fr-FR.json)
                        name: 'Prospect',
                        testIsGranted: () => true,
                        list: [
                            {
                                id: FORM_BUSINESS,
                                text: 'prospect_business',
                                url: URLS[PROSPECT][FORM][FORM_BUSINESS],
                                name: 'admin_orders_business',
                                type: FORM,
                                group: tab.id + '_business',
                                entity: 'Prospect',
                            },
                            {
                                id: FORM_INDIVIDUAL,
                                text: 'prospect_individual',
                                url: URLS[PROSPECT][FORM][FORM_INDIVIDUAL],
                                name: 'admin_orders_form_individual',
                                type: FORM,
                                group: tab.id + '_individual',
                                entity: ENTITIES[PROSPECT],
                            },
                            {
                                id: 'formSearch',
                                text: 'search',
                                url: URLS[PROSPECT][SEARCH],
                                name: 'admin_orders_form_search',
                                type: SEARCH,
                                entity: ENTITIES[PROSPECT],
                            },
                        ]
                    }
                ];

                return tab;
            }

            infoSelect = [
                ...infoSelect,
                {
                    id: tab.id,
                    name: tab.name,
                    testIsGranted: () => true,
                    list: [
                        {
                            id: 'form' + tab.searchType,
                            text: 'form',
                            url: URLS[tab.searchType][FORM],
                            name: 'admin_orders_form_task',
                            type: 'form',
                            entity: ENTITIES[tab.searchType],
                            group: tab.id,
                        },
                        {
                            id: 'formSearch' + tab.searchType,
                            text: 'search',
                            url: URLS[tab.searchType][SEARCH],
                            name: 'admin_orders_form_search',
                            type: 'search',
                            entity: ENTITIES[tab.searchType],
                            group: tab.id,
                        },
                    ]
                }
            ];

            return tab;
        });
    }

    update = (customerId) => {
        const {data, info: {entity, group, type, url}} = this.state;
        if (url && customerId) {
            const formatData = dataFilter(entity, type, data, true, group);

            this.setState({
                loader: false,
                inputs: formatData ? formatData.inputs : [],
                orders: formatData ? formatData.orders : [],
                parentEntity: formatData ? formatData.parentEntity : null,
                customerId: customerId,
                info: {
                    ...this.state.info,
                    formUrl: `${url}?customerId=${customerId}`,
                    loadForm: true
                }
            })
        }
    }

    getData = (data) => {
        const {info: {entity, group, type}} = this.state;
        const formatData = dataFilter(entity, type, data, true, group);
        if (formatData) {
            this.setState({
                data: data,
                loader: false,
                inputs: formatData.inputs,
                orders: formatData.orders,
                parentEntity: formatData.parentEntity,
            });
        }
    };

    onDragStart = (e, groupKey, itemKey = null) => {
        e.stopPropagation();
        let {inputs} = this.state;

        if (groupKey >= 0 && itemKey !== null) {
            const group = inputs[groupKey];
            this.draggedItem = group.fields[itemKey];
        } else if (groupKey >= 0) {
            this.draggedItem = inputs[groupKey];
        }

        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
        e.dataTransfer.setData("text/html", e.parentNode);
    };

    onDragOver = (groupKey, itemKey = null) => {
        let {inputs} = this.state;
        let group = inputs[groupKey];
        if (this.draggedItem
            && this.draggedItem.input
            && this.draggedItem.input.attr
            && this.draggedItem.input.attr['data-type'] === 'specific-fields') {
            this.draggedItem.isSpecificField = "1"
            this.draggedItem.label = this.draggedItem.input.title
        }
        if (!this.draggedItem) {
            return;
        }
        if (group && group.id === 'empty' && this.draggedItem &&
            (this.draggedItem.isUnique
                || this.draggedItem.isRequired
                || this.draggedItem.isReadOnly
                || this.draggedItem.isExtended
                || this.draggedItem.isEmail
                || (this.draggedItem.input && this.draggedItem.input.required))) {
            return;
        }
        if (this.draggedItem === group || (this.draggedItem && group && this.draggedItem.id === group.id)) {
            return;
        }
        //group
        if (this.draggedItem.isGroup) {
            let items = inputs.filter(item => item.id !== this.draggedItem.id);
            items.splice(groupKey, 0, this.draggedItem);
            inputs = items;
        } else { //item
            this.draggedItem.width = 100;
            if (itemKey) {
                const draggedOverItem = group.fields[itemKey];
                if (this.draggedItem === draggedOverItem) {
                    return;
                }

                if (this.draggedItem
                    && this.draggedItem.input
                    && this.draggedItem.input.attr
                    && this.draggedItem.input.attr['data-type'] === 'specific-fields') {
                    this.draggedItem.isSpecificField = "1"
                    this.draggedItem.label = this.draggedItem.input.title
                }
                let items = group.fields.filter(item => item.key !== this.draggedItem.key);

                items.splice(itemKey, 0, this.draggedItem);
                inputs[groupKey].fields = items;
            } else if (group.fields.length === 0 || !group.fields.filter(item => item.key === this.draggedItem.key).length) {
                inputs[groupKey].fields.push(this.draggedItem);
            } else {
                return;
            }

            inputs.forEach((otherGroups, key) => {
                if (groupKey !== key) {
                    inputs[key].fields = otherGroups.fields.filter(item => item !== this.draggedItem);
                }
            });
        }

        this.setState({
            inputs: inputs,
            isEdited: true
        });
    };

    onDragEnd = (e, type) => {
        if (this.draggedItem) {
            this.draggedItem = undefined;
        }
    };

    addGroup = () => {
        let {inputs, info} = this.state;
        const newGroup = {
            id: null,
            isNew: true,
            width: 100,
            order: 0,
            isGroup: true,
            label: ``,
            fields: [],
            visible: true,
            type: info.group
        };

        if (info.type === 'search' && !newGroup.type) {
            newGroup.type = info.type;
        }

        inputs.unshift(newGroup);

        this.setState({
            inputs: inputs,
            isEdited: true
        });
    };

    modifyLength = (type = 'more', groupKey, itemKey = null) => {
        let {inputs} = this.state;
        const group = inputs[groupKey];
        const groupLength = parseInt(group.width);
        const stepWidth = 25;

        if (itemKey !== null) {
            let item = group.fields[itemKey];
            const length = parseInt(item.width);
            if (type === 'more' && length < 100) {
                item.width = length + stepWidth;
            } else if (type === 'minus' && length > stepWidth) {
                item.width = length - stepWidth;
            }
            group.fields[itemKey] = item;
        } else {
            if (type === 'more' && groupLength < 100) {
                group.width = groupLength + stepWidth;
            } else if (type === 'minus' && groupLength > stepWidth) {
                group.width = groupLength - stepWidth;
            }
        }

        inputs[groupKey] = group;

        this.setState({
            inputs: inputs,
            isEdited: true
        });
    };

    hideElement = (groupKey, itemKey = null, formType) => {
        let {inputs} = this.state;
        let {t, setError} = this.props;
        const group = inputs[groupKey];
        let items = group.fields;

        const isSearch = formType === 'search'

        if (itemKey !== null) {
            const {input} = items[itemKey];
            if (!isSearch && (items[itemKey].isRequired || true === input.required || items[itemKey].isUnique)) {
                setError({msg: t('vieworder_required'), errorCode: 500})
                return;
            } else {
                inputs[inputs.length - 1].fields.push(items[itemKey]);
            }

            items.splice(itemKey, 1);
            inputs[groupKey] = group;
        } else {
            let groupIndex = inputs.findIndex(group => group.id === 'unmapped');
            if (groupIndex === -1) {
                groupIndex = inputs.findIndex(group => group.id === 'empty');
            }
            if (groupIndex !== -1) {
                const hasRequiredFields = items.filter((item) => item.isRequired || (item.input && true === item.input.required) || item.isUnique).length > 0;
                if (isSearch || !hasRequiredFields) {
                    items.forEach(item => {
                        inputs[groupIndex].fields.push(item);
                    });
                    inputs.splice(groupKey, 1);
                } else {
                    setError({msg: t('vieworder_required'), errorCode: 500})
                    return;
                }
            }
        }

        this.setState({
            inputs: inputs,
            isEdited: true
        });
    };

    /**
     * Modifie les caractéristiques d'un viewOrderInfo autres que le type de base (numeric, string...)
     * @param {array} groupKey
     * @param {string|null} itemKey
     * @param {string} context
     * @param {string|null} value
     */
    setNotValueTypeField = (groupKey, itemKey = null, context, value = null) => {
        let {inputs} = this.state;
        const group = inputs[groupKey];
        let items = group.fields;

        if (itemKey !== null && items[itemKey]) {
            switch (context) {
                case 'required':
                    inputs[groupKey].fields[itemKey].isRequired = !inputs[groupKey].fields[itemKey].isRequired;
                    inputs[groupKey].fields[itemKey][CONDITION_VALUE] = null;
                    inputs[groupKey].fields[itemKey][CONDITION_FIELD] = null;
                    break;
                case 'unique':
                    inputs[groupKey].fields[itemKey].isUnique = !inputs[groupKey].fields[itemKey].isUnique;
                    break;
                case 'readOnly':
                    inputs[groupKey].fields[itemKey].isReadOnly = !inputs[groupKey].fields[itemKey].isReadOnly;
                    break;
                case TYPE_PARENT_FIELD:
                    inputs[groupKey].fields[itemKey].isParentField = !inputs[groupKey].fields[itemKey].isParentField;
                    break;
                case 'isExtended': {
                    const currentExtended = inputs[groupKey].fields[itemKey].isExtended;
                    inputs.forEach((input) => {
                        input.fields.forEach((field) => {
                            field.isExtended = false;
                        })
                    });
                    inputs[groupKey].fields[itemKey].isExtended = !currentExtended;
                    break;
                }
                case 'email':
                    inputs[groupKey].fields[itemKey].isEmail = !inputs[groupKey].fields[itemKey].isEmail;
                    break;
                case SHOW_CONDITION_FIELD:
                    inputs[groupKey].fields[itemKey][SHOW_CONDITION_FIELD] = !inputs[groupKey].fields[itemKey][SHOW_CONDITION_FIELD];
                    break;
                case CONDITION_FIELD:
                    inputs[groupKey].fields[itemKey][CONDITION_FIELD] = value;
                    break;
                case CONDITION_VALUE:
                    inputs[groupKey].fields[itemKey][CONDITION_VALUE] = value;
                    break;
                default:
                    break;
            }
        }

        this.setState({
            inputs: inputs,
            isEdited: true
        });
    };

    setValueType = (groupKey, itemKey = null, valueType, value) => {
        let {inputs} = this.state;
        const group = inputs[groupKey];
        let items = group.fields;

        if (itemKey !== null && items[itemKey]
            && ['required', 'unique', 'readOnly', 'isExtended', TYPE_PARENT_FIELD, SHOW_CONDITION_FIELD, CONDITION_FIELD, CONDITION_VALUE].indexOf(valueType) === -1) {
            if (inputs[groupKey].fields[itemKey].valueType !== valueType) {
                inputs[groupKey].fields[itemKey].valueType = valueType;
            } else {
                inputs[groupKey].fields[itemKey].valueType = null;
            }
        }

        if (['required', 'unique', 'readOnly', 'email', 'isExtended', TYPE_PARENT_FIELD, SHOW_CONDITION_FIELD, CONDITION_FIELD, CONDITION_VALUE].indexOf(valueType) !== -1) {
            this.setNotValueTypeField(groupKey, itemKey, valueType, value);
        }

        this.setState({
            inputs: inputs,
            isEdited: true
        });
    };

    showElement = (groupKey, itemKey) => {
        let {inputs} = this.state;
        const group = inputs[groupKey];
        let items = group.fields;

        const groupIndex = inputs.findIndex(group => group.id === 'unmapped');
        inputs[groupIndex].fields.push(items[itemKey]);

        items.splice(itemKey, 1);
        inputs[groupKey] = group;

        this.setState({
            inputs: inputs,
            isEdited: true
        });
    };

    saveOrders = () => {
        const {postForm, setError, t} = this.props;
        const {inputs, info} = this.state;
        setLoader({
            'form-orders': true
        })
        // Il faut deep-copy les inputs car les arrays sont des pointeurs et le splice modifie le state dans ce cas
        let deepCopyInputs = window.structuredClone(inputs);

        const indexEmpty = deepCopyInputs.findIndex((item) => item.id === 'empty');
        const indexUnmapped = deepCopyInputs.findIndex((item) => item.id === 'unmapped');

        if (indexEmpty !== -1) {
            deepCopyInputs.splice(indexEmpty, 1);
        }
        if (indexUnmapped !== -1) {
            deepCopyInputs.splice(indexUnmapped, 1);
        }

        // On met à jour l'order
        deepCopyInputs = deepCopyInputs.map((item, key) => {
            item.order = key;
            item.fields = item.fields.map((subItem, subKey) => {
                subItem.order = subKey;
                return subItem
            })
            return item;
        })

        persistViewOrdersInfo(info.entity, info.type, deepCopyInputs, postForm, info.group, (isError) => {
            setLoader({
                'form-orders': false
            })
            if (!isError) {
                setError({msg: t('save_orders_ok'), errorCode: 200})
            }
            this.setState({
                isEdited: false
            })
        }, info.group ?? info.type)

    };

    changeTitle = (e, key) => {
        const value = e.target.value;
        let {inputs} = this.state;
        inputs[key].label = value;

        let requestNumber = this.state.requestNumber;
        requestNumber++;
        this.setState({
            requestNumber: requestNumber,
            isEdited: true
        });

        setTimeout(() => {
            if (requestNumber === this.state.requestNumber) {
                this.setState({
                    inputs: inputs,
                    isEdited: true
                })
            }
        }, 500)
    };

    changeView = (value) => {
        const {customerId} = this.state;
        const ids = value.split('_');
        const group = infoSelect.find(group => (group.id === ids[0]));
        if (!group) {
            this.setState({
                info: {
                    loadForm: false,
                    url: null
                },
                data: [],
                inputs: [],
                viewSelected: ''
            })
            return null;
        }
        let item = group.list.find(item => item.id === ids[1]);

        if (item.type === 'form' || item.type === 'search') {
            item = {
                ...item,
                formUrl: item.url
            }
        }

        if (customerId) {
            item = {
                ...item,
                loadForm: false
            }
        }

        this.setState({
            info: item,
            viewSelected: value,
            loader: true
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState !== this.state
            && prevState.info !== this.state.info
            && prevState.info.url !== this.state.info.url
            && this.state.info.url
            && this.state.customerId
        ) {
            this.setState({
                info: {
                    ...this.state.info,
                    formUrl: `${this.state.info.url}?customerId=${this.state.customerId}`,
                    loadForm: true
                }
            })
        }
    }

    handleLeaving = () => {
        const {t} = this.props;

        this.setState({
            isEdited: false
        })

        return t("customer_confirm_leave_form_alert");
    }

    render() {
        const {t} = this.props;
        const {viewSelected, info, inputs, isEdited} = this.state;

        return (
            <>
                <OrderLocalStorage update={this.update.bind(this)}/>
                <Prompt message={this.handleLeaving} when={isEdited}/>

                <div className="page page-admin content-wrapper">
                    <section className={'box'}>
                        <div className={'box__header'}>
                            <p className={'box__header__text'}>{t('admin_gestion_orders')}</p>
                        </div>
                        <div className={'box__body admin-orders'}>
                            <div className={'row row-fluid align-items-center justify-content-between'}>
                                <div className={'col-auto admin-orders__add-group'} onClick={() => this.addGroup()}>
                                    <PolIcon icon={'plus-circle'} className={'admin-orders__add-group__icon'}/>
                                    <span className={'admin-orders__add-group__text'}>{t('add_group')}</span>
                                </div>
                                <div className={'admin-orders__container'}>
                                    <p className={'admin-orders__label'}>{t('pick_form_to_edit')}</p>
                                    <div className={'col-auto col-fluid admin-orders__choose'}>
                                        <select className={'admin-orders__choose__select'}
                                                value={viewSelected ?? ''}
                                                onChange={(event) => this.changeView(event.target.value)}>
                                            {infoSelect.map((group, groupKey) => {
                                                return (
                                                    <Fragment key={groupKey}>
                                                        {groupKey === 0 ? (
                                                            <option value={''}></option>
                                                        ) : null}

                                                        {group?.testIsGranted?.() &&
                                                            <optgroup label={t(group.name)}>
                                                                {group.list.map((item, key) => {
                                                                    return (
                                                                        <option key={key}
                                                                                value={`${group.id}_${item.id}`}>{`${t(item.text)} - ${t(item.entity)}`}</option>
                                                                    )
                                                                })}
                                                            </optgroup>
                                                        }
                                                    </Fragment>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className={'admin-orders__content'}>
                                <Form admin={true}
                                      getData={data => this.getData(data)}
                                      inputs={inputs}
                                      info={info}
                                      handleChange={() => this.handleChange}
                                      adminOrder={true}
                                      onDragStart={(e, key, itemKey) => this.onDragStart(e, key, itemKey)}
                                      onDragOver={(groupKey, itemKey) => this.onDragOver(groupKey, itemKey)}
                                      onDragEnd={(e) => this.onDragEnd(e, 'group')}
                                      modifyLength={(type, groupKey, itemKey) => this.modifyLength(type, groupKey, itemKey)}
                                      hideElement={(groupKey, itemKey) => this.hideElement(groupKey, itemKey, info.type)}
                                      showElement={(groupKey, itemKey) => this.showElement(groupKey, itemKey)}
                                      changeTitle={(groupKey, text) => this.changeTitle(groupKey, text)}
                                      setRequiredOrUnique={this.setNotValueTypeField.bind(this)}
                                      setValueType={this.setValueType.bind(this)}
                                      extendedSearchForm={true}
                                      viewSelected={viewSelected}
                                      typeForm={info.type}
                                />
                            </div>
                        </div>
                        <div className={'row align-items-center justify-content-end save-orders'}>
                            <NavLink to={'/administration'}>
                                <div className={'col-12 col-md-auto form__back'}>
                                    <PolIcon icon={'chevron-circle-left'} className={'form__back__icon'}/>
                                    {t('retour')}
                                </div>
                            </NavLink>
                            <div className={`col-12 col-md-auto form__submit`}>
                                <button className={'btn btn-primary form__submit__btn'}
                                        onClick={() => this.saveOrders()}>
                                    {t('enregistrer')}
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        )
    }
}

const mapStateToProps = ({form}) => ({
    form
});

const mapDispatchToProps = dispatch => {
    return {
        postForm: (info) => dispatch(postForm(info)),
        setError: (info) => dispatch(setError(info))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(Orders));

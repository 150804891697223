import {useEffect} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
import {useRecoilState} from "recoil";
import {setAppError} from '../../actions/datas';
import {logOut} from '../../actions/user';
import {
    disableUserMercureHandler,
    importProgressHandler,
    loginMercureHandler,
    logoutUserWithMessage
} from "../../utils/mercureHandler";
import {importProgression} from "../Admin/atoms/importAdmin";
import {getLSItem} from "../../v4/services/localStorage.service";

let currentEventSource = null;

const MercureComponent = ({children, user, logOut, t, setAppError}) => {
    const [, setImportProgress] = useRecoilState(importProgression)

    useEffect(() => {
        const {id} = getLSItem('user');
        if (id && !currentEventSource) {
            setEventSource(id, logOut, setImportProgress, setAppError, t);

        }

        return () => {
            if (currentEventSource instanceof EventSource) {
                currentEventSource.close();
            }
            currentEventSource = null;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.user, logOut, setAppError, t, setImportProgress]);


    return (
        children
    );
};


const mapStateToProps = ({user}) => ({
    user
});

const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(logOut()),
        setAppError: (errorMessage) => dispatch(setAppError(errorMessage))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(MercureComponent));

function setEventSource(userId, logOut, setImportProgress, setAppError, t) {
    currentEventSource = new EventSource(`${process.env.REACT_APP_MERCURE_HUB_URL}?topic=${process.env.REACT_APP_MERCURE_ENV_PREFIX}${encodeURIComponent(userId)}`);

    currentEventSource.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data);
        if (parsedMessage && parsedMessage.type) {
            switch (parsedMessage.type) {
                case 'login':
                    loginMercureHandler(parsedMessage, logOut, t, setAppError)
                    break;
                case 'disable':
                    disableUserMercureHandler(logOut, t, setAppError)
                    break;
                case 'rolesChanged':
                    logoutUserWithMessage(logOut, t, setAppError)
                    break;
                case 'import-progress' :
                    importProgressHandler(parsedMessage, setImportProgress);
                    break;
                default:
                    break;
            }

        }
    };

    currentEventSource.onerror = () => setTimeout(() => {
        currentEventSource && currentEventSource.close();
        currentEventSource = null;
    }, 15000);
}

import React from 'react';
import {useParams} from "react-router-dom";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {SearchProvider} from "v4/contexts/SearchContext/SearchContext";
import {PRODUCT_SEARCH} from "v4/data/apiRoutes";
import useTestRoute from "v4/hooks/useTestRoute";
import ProductsAdd from "v4/pages/front/Products/components/ProductsAdd/ProductsAdd";
import ProductsEdit from "v4/pages/front/Products/components/ProductsEdit/ProductsEdit";
import ProductsListing from "v4/pages/front/Products/components/ProductsListing/ProductsListing";
import ProductVoter from "v4/voters/productVoter";
import useHashPulse from "v4/hooks/useHashPulse";

export default function ProductsPage() {
    const isAddPage = useTestRoute({inUrl: ['add']});
    const isEditPage = useTestRoute({inParams: ['articleId'], inUrl: ['edit']});
    const isListingShowing = !(isAddPage || isEditPage);
    const {articleId} = useParams();
    const {canAdd, canEdit, canProductShowList} = ProductVoter();
    useHashPulse(isListingShowing);

    return (
        <>
            {canProductShowList() &&
                <SearchProvider searchRouteName={PRODUCT_SEARCH} pageType="articles">
                    <ToggleComponent isShowing={isListingShowing} className="page page-list main-content__body__slide">
                        <ProductsListing/>
                    </ToggleComponent>
                </SearchProvider>
            }

            {canAdd() && isAddPage && <ProductsAdd/>}
            {canEdit() && isEditPage && <ProductsEdit articleId={articleId}/>}
        </>
    );
}

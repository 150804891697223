import {captureException} from "@sentry/react";
import {useEffect, useState} from 'react';
import {debounce} from "v4/utils";
import {POSTAL_CODE_SERVICE} from "v4/data/externalApiRoutes";
import {useFormContext} from "react-hook-form";
import numberOnly from "v4/utils/numberOnly";
import useToggle from "v4/hooks/useToggle";

export default function useInputPostalCode(fieldName, fieldValue, postalCodeTarget, onChange) {
    const {setValue, getFieldState} = useFormContext();
    const [dataList, setDataList] = useState([]);
    const [canAutoComplete, toggleAutoComplete] = useToggle(false);

    const {isDirty} = getFieldState(fieldName);
    useEffect(() => {
        if (isDirty) toggleAutoComplete(true);
    }, [isDirty, toggleAutoComplete]);

    useEffect(() => {
        if (fieldValue?.length >= 2) {
            debounce(() => {
                if (!canAutoComplete) return;

                getAutocomplete(fieldValue).then(data => {
                    setDataList(data);
                })
            });
        }
    }, [canAutoComplete, fieldName, fieldValue, getFieldState]);

    const handleSelect = ({currentTarget}) => {
        const {code, city} = currentTarget.dataset;
        setValue(fieldName, code, {shouldValidate: true, shouldDirty: true});

        if (postalCodeTarget) {
            setValue(postalCodeTarget, city, {shouldValidate: true, shouldDirty: true});
        }
    }

    const handleChange = (e) => {
        onChange(numberOnly(e.currentTarget.value) ?? '');
    }



    return {
        autocompleteCitiesList: dataList,
        handleSelect,
        handleChange
    };
}

async function getAutocomplete(postalCode) {
    try {
        const url = new URL(POSTAL_CODE_SERVICE);
        url.searchParams.set('code', postalCode);
        const response = await fetch(url.toString());
        const {cities} = await response.json();

        return cities ?? [];
    } catch (e) {
        captureException(e, {
            message: 'Error while fetching autocomplete for postalCode',
            extra: {
                postalCode
            }
        })
    }
}

import React, {useCallback, useEffect, useMemo} from 'react';
import {useWatch} from "react-hook-form";
import {useHistory} from "react-router-dom";
import PolTable from "v4/components/ui/PolTable/PolTable";
import SearchContextPagination from "v4/components/utils/SearchContextPagination/SearchContextPagination";
import SearchContextSearchbar from "v4/components/utils/SearchContextSearchbar/SearchContextSearchbar";
import ToggleComponent from "v4/components/utils/ToggleComponent/ToggleComponent";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import useCheckList from "v4/hooks/useCheckList";
import useFetch from "v4/hooks/useFetch";
import usePrevious from "v4/hooks/usePrevious";
import usePropToComponent from "v4/hooks/usePropToComponent";
import useToggle from "v4/hooks/useToggle";
import ListingEntityFilters from "v4/layouts/ListingEntityLayout/components/ListingEntityFilters/ListingEntityFilters";
import "v4/layouts/ListingEntityLayout/ListingEntityLayout.scss";
import {generateUrl} from "v4/services/api.service";
import {debounce} from "v4/utils";
import {useFrontContext} from "v4/contexts/FrontContext";

export default function ListingEntityLayout({
                                                entity,
                                                setTotalItems,
                                                tools,
                                                rowActions,
                                                alternateDisplay,
                                                alternateDisplayOptions,
                                                tableClassName,
                                                tab,
                                            }) {
    const {saveClickedId} = useFrontContext();
    const [, listingToolsWith] = usePropToComponent(tools);
    const {
        searchRouteName,
        isFormReady,
        formMethods: {control, getValues},
        searchbar,
        initPagesWithTotalItems,
        currentPage,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        itemsPerPage
    } = useSearchContext();

    const [{data, isLoading, isFinished}, fetchItems] = useFetch();
    const [{
        data: allItemsIds,
        isLoading: isAllItemsIdsLoading,
    }, fetchAllItems] = useFetch();

    const hydraData = useMemo(() => data?.['hydra:member'] || [], [data]);
    const hydraSums = useMemo(() => data?.['hydra:sums'] || [], [data]);

    const [shouldRefetch, toggleRefetch] = useToggle();

    const {location, replace} = useHistory();
    useEffect(() => {
        if (location.state?.hasToRefresh) {
            const {hasToRefresh, ...newState} = location.state;
            replace({state: newState});
            toggleRefetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.hasToRefresh]);

    const [
        checkedRows,
        isCheckAllChecked,
        handleCheck,
        handleCheckAll,
        {
            containsInChecklist,
            addMultipleValues,
            unCheckAll,
        }
    ] = useCheckList([], true);

    const values = useWatch({control});
    const previousValues = usePrevious(values);
    useEffect(() => {
        if (isFormReady) {
            const isSearchChanged = JSON.stringify(values) !== JSON.stringify(previousValues);

            debounce(() => {
                const parameters = {
                    page: currentPage,
                    sortField,
                    sortOrder,
                    itemsPerPage,
                }
                if (searchbar !== '') {
                    parameters.searchText = searchbar;
                }
                if (tab) {
                    parameters.tab = tab.id;
                }

                const bodyData = getValues();

                if (alternateDisplayOptions?.isMapsPage && checkedRows.length) {
                    parameters.itemsPerPage = checkedRows.length;
                    parameters.page = 1;
                    bodyData.id = checkedRows;
                }

                fetchItems({
                    url: generateUrl(searchRouteName, parameters),
                    config: {
                        method: 'POST',
                        body: JSON.stringify(bodyData),
                    }
                });
            }, isSearchChanged ? 500 : 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFormReady, values, shouldRefetch, sortField, sortOrder, itemsPerPage, currentPage, searchbar, alternateDisplayOptions?.isMapsPage]);

    useEffect(() => {
        if (data) {
            initPagesWithTotalItems(data?.['hydra:totalItems']);
            setTotalItems(data?.['hydra:totalItems']);
        }

        return () => initPagesWithTotalItems(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, itemsPerPage]);

    useEffect(() => {
        !(alternateDisplayOptions?.isMapsPage && checkedRows.length) && unCheckAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRefetch, values, searchbar, alternateDisplayOptions?.isMapsPage]);

    const onCheckAll = useCallback(() => {
        const parameters = {
            page: currentPage,
            sortField,
            sortOrder,
            itemsPerPage,
            isAllIds: true
        }
        if (searchbar !== '') {
            parameters.searchText = searchbar;
        }
        fetchAllItems({
            url: generateUrl(searchRouteName, parameters),
            config: {
                method: 'POST',
                body: JSON.stringify(getValues()),
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, currentPage, fetchAllItems, searchRouteName, sortField, sortOrder, itemsPerPage, searchbar]);

    useEffect(() => {
        if (allItemsIds?.['hydra:member']) {
            addMultipleValues(allItemsIds?.['hydra:member'].map(item => item.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allItemsIds]);

    const checkFunctions = useMemo(() => ({
        checkedRows,
        containsInChecklist,
        handleCheck,
        handleCheckAll: handleCheckAll(onCheckAll),
        isCheckAllChecked,
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [checkedRows, isCheckAllChecked]);

    const onHeaderClick = useCallback(({currentTarget}) => {
        const {key, sort} = currentTarget.dataset;
        let currentSortOrder = sortOrder;
        if (sortField !== key) {
            setSortField(key);
            currentSortOrder = 'asc';
            [...currentTarget.closest('tr').querySelectorAll('[data-sort]')].forEach(child => {
                if (child !== currentTarget) {
                    delete child.dataset.sort;
                    child.classList.remove('active');
                }
            })
        }
        if (!sort) {
            setSortOrder(currentSortOrder);
            currentTarget.dataset.sort = currentSortOrder;
            currentTarget.classList.add('active');
        } else {
            const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
            setSortOrder(newSortOrder);
            currentTarget.dataset.sort = newSortOrder;
            currentTarget.classList.add('active');
        }
    }, [sortField, sortOrder, setSortField, setSortOrder]);

    return (
        <>
            <div className="listing-header">
                <ul className="listing-header__actions">
                    {listingToolsWith({checkedRows})}
                </ul>
                <div className="header__extended-filters"/>
                <div className="listing-header__filters">
                    <SearchContextSearchbar/>
                    <ListingEntityFilters tab={tab} entity={entity}/>
                </div>
            </div>
            <div id="extended-filters-listing"></div>
            <ToggleComponent isShowing={!alternateDisplay}>
                <PolTable data={hydraData}
                          tfootSums={hydraSums}
                          onHeaderClick={onHeaderClick}
                          className={tableClassName}
                          checkFunctions={checkFunctions}
                          rowActions={rowActions}
                          status={{
                              isLoading: isLoading || isAllItemsIdsLoading,
                              isFinished,
                          }}
                          entity={entity}
                          toggleRefetch={toggleRefetch}
                          saveClickedId={saveClickedId}/>
            </ToggleComponent>
            <ToggleComponent isShowing={!!alternateDisplay} shouldRemoveFromDom={true}>
                {alternateDisplay?.({entitiesList: hydraData, isLoading})}
            </ToggleComponent>
            {!(alternateDisplayOptions?.isMapsPage && checkedRows.length) && <SearchContextPagination/>}
        </>
    )
}

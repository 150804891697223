import React from 'react';
import ManageModules
    from "v4/features/admin/modules/components/ErrorBoundaryModules/components/ManageModules/ManageModules";
import ErrorBoundary from "components/Error/errorBoundary";
import NoCustomerIdModules
    from "v4/features/admin/modules/components/ErrorBoundaryModules/components/NoCustomerIdModules/NoCustomerIdModules";

export default function ErrorBoundaryModules({customerId, setAdditionalInput}) {
    if (!customerId) return <NoCustomerIdModules/>

    return (
        <ErrorBoundary>
            <ManageModules customerId={customerId} setAdditionalInput={setAdditionalInput}/>
        </ErrorBoundary>
    )
}

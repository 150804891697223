import React from 'react';
import {baseProspect, baseMailType} from "../../../routes";
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "../../PolIcon/policon.js"
import ReactTooltip from "react-tooltip";
import UserService from "../../../api/user/user";

class ContentActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: null
        };
        this.setRedirect = this.setRedirect.bind(this);
    }

    setRedirect(component) {
        this.setState({redirect: component})
    }

    render() {
        const {entity, t} = this.props;
        const {redirect} = this.state;

        return (
            <>
                {redirect}
                <div className={'content-add'}>
                    <ul className={'content-add__list'}>
                        {entity === 'prospects' ? (
                            <>
                                <li className={`action ${!UserService.controlAccess(['PROSPECT_CUD']) ? 'action--disable' : null} btn__primary`}
                                    onClick={() => {
                                        if (UserService.controlAccess(['PROSPECT_CUD'])) {
                                            this.setRedirect(<Redirect to={`${baseProspect}/new/business`}/>)
                                        }
                                    }}
                                    data-tip={!UserService.controlAccess(['PROSPECT_CUD']) ? t('rights_access_deny') : t('add_company')}
                                    data-delay-show='1000'>
                                    <PolIcon icon="add-company" className={'action__picto'}/>
                                </li>
                                <li className={`action ${!UserService.controlAccess(['PROSPECT_CUD']) ? 'action--disable' : null} btn__primary`}
                                    onClick={() => {
                                        if (UserService.controlAccess(['PROSPECT_CUD'])) {
                                            this.setRedirect(<Redirect to={`${baseProspect}/new/individual`}/>)
                                        }
                                    }}
                                    data-tip={!UserService.controlAccess(['PROSPECT_CUD']) ? t('rights_access_deny') : t('add_prospect')}
                                    data-delay-show='1000'>
                                    <PolIcon icon="add-client" className={'action__picto'}/>
                                </li>
                            </>
                        ) : null}
                        {entity === 'mailType' ? (
                            <>
                                <li className={'action'}
                                    onClick={() => this.setRedirect(<Redirect to={`${baseMailType}/new/mail-type`}/>)}>
                                    <PolIcon icon={"plus"} className={'action__picto'}/>
                                </li>
                            </>
                        ) : null}
                    </ul>
                    <ReactTooltip effect="solid" />
                </div>
            </>
        )
    }
}

const mapStateToProps = ({datatable}) => ({
    datatable
});


export default connect(
    mapStateToProps
)(withPolTranslation(ContentActions));

import React from 'react';

export const PolIcon = ({icon = '', onClick, onDoubleClick, className, color, ...props}) => {
    const style = color ? {color} : {};

    return (
        <i className={`policon policon-${icon} ${className ?? ''}`}
           onClick={onClick}
           onDoubleClick={onDoubleClick}
           data-tip={props['data-tip']}
           data-for={props['data-for']}
           style={style}
        />
    )
};

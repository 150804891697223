import React from 'react';
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import ProspectTasksTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTasksTabPartial/ProspectTasksTabPartial";
import ProspectReceivedMailsTasksTabRowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectReceivedMailsTasksTab/components/ProspectReceivedMailsTasksTabRowActions/ProspectReceivedMailsTasksTabRowActions";


const fetchParams = {
    isSynchroOutlookTask: true
};

export default function ProspectReceivedMailsTasksTabListing({prospectId}) {
    return (
        <div className="prospect-tab__listing__wrapper">
            <ProspectHeadingTabPartial title="received_mails"/>
            <ProspectTasksTabPartial fetchParams={fetchParams}
                                     prospectId={prospectId}
                                     rowActions={ProspectReceivedMailsTasksTabRowActions}/>
        </div>
    )
}

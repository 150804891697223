import ErrorBoundary from "components/Error/errorBoundary";
import {PolIcon} from "components/PolIcon/policon";
import React, {useCallback, useEffect} from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import AsideFilters
    from "v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/AsideFilters";

export default function ListingEntityFilters({entity, tab}) {
    const {t} = usePolTranslation()
    const [isFiltersShowing, toggleFiltersShowing] = useToggle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onEscapePress = useCallback(({key}) => (key === "Escape") && toggleFiltersShowing(), []);

    useEffect(() => {
        if (isFiltersShowing) {
            document.addEventListener('keydown', onEscapePress);
        } else {
            document.removeEventListener('keydown', onEscapePress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFiltersShowing]);

    return (
        <>
            <button className="listing-header__action" onClick={toggleFiltersShowing}>
                <PolIcon icon="filters" className="action__picto"/>
                <p>{t('filters_button_text')}</p>
            </button>

            <ErrorBoundary>
                <AsideFilters entity={entity} isFiltersShowing={isFiltersShowing} toggleFiltersShowing={toggleFiltersShowing}/>
            </ErrorBoundary>
        </>
    )
}

import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';
import {PolIcon} from "../../PolIcon/policon";
import MenuUser from "./menuUser";
import UserService from "../../../api/user/user";
import {logOut} from "../../../actions/user";
import {postAdmin} from "../../../api/datas/post";
import ReactTooltip from 'react-tooltip';
import sfi from '../../../img/logoSFI.png'

class User extends Component {
    constructor(props) {
        super(props);
        this.userMenu = React.createRef();
        this.state = {
            expandUser: false,
            user: {},
            spfCheck: false
        };
    }

    componentDidMount() {
        const {logOut} = this.props;
        document.addEventListener('mousedown', this.handleClick, false);

        let localUser = UserService.getUser();
        if (localUser) {
            postAdmin('/api/spf_checks', () => {
                this.setState({
                    spfCheck: false
                })
            }, (data) => {
                if (data) {
                    this.setState({
                        spfCheck: data.spfResult
                    })
                }
            })

        } else {
            logOut();
        }

        this.setState({
            user: localUser
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false)
    }

    handleClick = (e) => {
        const menu = this.userMenu.current;
        if ((e.target === menu || e.target.closest('.user__actions') || e.target.closest('.user__text'))) {
            return;
        }

        this.setState({
            expandUser: false
        })
    };

    render() {
        const {t} = this.props;
        const {expandUser, user, spfCheck} = this.state;
        let classUserArrow = '';
        if (expandUser) {
            classUserArrow = 'active-menu'
        }

        return (
            <>
                <div className={'row align-items-end user__content'}>
                    <div className={'col-auto'}>
                        <img src={user.customer && user.customer.logoUrl ? user.customer.logoUrl : sfi} alt={'Logo'}/>
                    </div>
                    <div className={'col d-none d-md-block'} onClick={() => {
                        this.setState({expandUser: !expandUser})
                    }}>
                        <p className={'user__title'}>{t('bienvenue')}</p>
                        <p className={'user__text'}>
                            {!spfCheck ? (
                                <PolIcon data-tip={t('spf_check_fail')} icon={'exclamation-circle'}
                                         data-for='tooltip-user'
                                         className={'user__warning'}/>) : null}
                            {user.firstname} {user.lastname}
                            <PolIcon icon={'chevron-down'} className={'user__menu ' + classUserArrow}/>
                        </p>

                        {expandUser ?
                            <div className={'user__actions'} ref={this.userMenu}>
                                <MenuUser/>
                            </div> : null
                        }
                    </div>
                    <ReactTooltip effect="solid" id={'tooltip-user'}/>
                </div>

            </>
        );
    }
}

const mapStateToProps = ({user}) => ({
    user
});

const mapDispatchToProps = dispatch => {
    return {
        logOut: () => dispatch(logOut()),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(User));


import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import ProspectTabEditButton from "v4/components/utils/ProspectTabEditButton/ProspectTabEditButton";
import ProspectTabBackButton from "v4/components/utils/ProspectTabBackButton/ProspectTabBackButton";
import ProspectQuotesSplitButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesSplitButton/ProspectQuotesSplitButton";
import ProspectTabReOfferButton from "v4/components/utils/ProspectTabReOfferButton/ProspectTabReOfferButton";
import ProspectTabPdfversionningButton
    from "v4/components/utils/ProspectTabPdfversionningButton/ProspectTabPdfversionningButton";
import ActionsButtonsPdf from "v4/features/front/generatePdf/components/ActionsButtonsPdf/ActionsButtonsPdf";
import {
    onPDFGenerate
} from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/ProspectQuoteTabShow";
import ShowEntityLayout from "v4/layouts/ShowEntityLayout/ShowEntityLayout";
import {QUOTE_ITEM_FORM} from "v4/data/apiRoutes";
import ProspectQuotesTabs
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectQuotesTabs/ProspectQuotesTabs";
import QuoteTasksButton
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/QuoteTasksButton";
import ProspectTabShowActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTabShowActions/ProspectTabShowActions";


export default function ProspectQuotesCustomTabShow({prospectId, showId, tab, onGetData, quoteData, setQuoteData}) {
    const {replace} = useHistory();
    const {currentTabName, quote, setQuote} = useProspectPageContext();

    useEffect(() => {
        return () => setQuote(null);
    }, [setQuote]);

    const whenDeleteFile = () => {
        // Removing the current from quote_invoices metadata to hide the download button
        const quoteInvoices = JSON.parse(quoteData.metadata.quote_invoices);
        quoteInvoices.current = null;
        quoteData.metadata.quote_invoices = JSON.stringify(quoteInvoices);
        onGetData && onGetData(quoteData);
        replace({state: {hasToRefresh: true}})
    }

    return (
        <>
            <div className="prospect-tab__prospect__header">
                {quote?.metadata?.isSplit && <ProspectQuotesTabs currentTab={currentTabName} showId={showId}/>}

                <ProspectTabBackButton/>
                <ProspectTabEditButton showId={showId}/>
                {!quote?.metadata?.isSplit && <ProspectQuotesSplitButton/>}
                <QuoteTasksButton quote={quote}/>
                <ProspectTabReOfferButton showId={showId}/>
                <ProspectTabPdfversionningButton quote={quote}/>
                <ActionsButtonsPdf prospectId={prospectId}
                                   quoteId={showId}
                                   quoteName={quote?.name}
                                   contactIds={quote?.contactId}
                                   currentFile={quote?.customerFiles}
                                   updateHandler={onPDFGenerate(setQuoteData, replace)}/>
                {quote?.metadata?.showActions && (
                    <ProspectTabShowActions showId={showId}
                                            prospectId={prospectId}
                                            popupTitle={quote?.name}
                                            showActions={quote.metadata.showActions}/>
                )}
            </div>
            <ShowEntityLayout entityId={showId}
                              onGetData={onGetData}
                              whenDeleteFile={whenDeleteFile}
                              entityRouteName={QUOTE_ITEM_FORM}
                              overrideData={quoteData}
                              fetchFormParameters={{tabId: tab.id}}/>
        </>
    )
}

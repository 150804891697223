import {ROLE_ADMIN, ROLE_MODULE_PRODUCT_CUSTOMIZE, ROLE_SUPER_ADMIN} from "api/user/user";
import {CONTACT, MAIL_TYPE, PRODUCT, PROSPECT, QUOTE, TASK} from "v4/data/entities";
import ListingPageLayout from "v4/layouts/ListingPageLayout/ListingPageLayout";
import CustomActionsPage from "v4/pages/admin/CustomActions/CustomActionsPage";
import CustomerEventServicesPage from "v4/pages/admin/CustomerEventServices/CustomerEventServicesPage";
import CustomEventTriggersPage from "v4/pages/admin/CustomEventTriggers/CustomEventTriggersPage";
import OutlookSubscriptionConfigurationsPage
    from "v4/pages/admin/OutlookSubscriptionConfigurations/OutlookSubscriptionConfigurationsPage";
import ProductAttributesPage from "v4/pages/admin/ProductAttributes/ProductAttributesPage";
import ProductConfigurationsPage from "v4/pages/admin/ProductConfigurations/ProductConfigurationsPage";
import SavedRequestsPage from "v4/pages/admin/SavedRequests/SavedRequestsPage";
import TemplatesPage from "v4/pages/admin/Templates/TemplatesPage";
import DashboardPage from "v4/pages/front/Dashboard/DashboardPage";
import HomePage from "v4/pages/front/Home/HomePage";
import MailsTypesPage from "v4/pages/front/MailsTypes/MailsTypesPage";
import MicrosoftGraphPage from "v4/pages/front/MicrosoftGraph/MicrosoftGraphPage";
import NewsletterPage from "v4/pages/front/Newsletter/NewsletterPage";
import ProductsPage from "v4/pages/front/Products/ProductsPage";
import {getRouteParamsForTabs} from "v4/services/tab.service";
import CustomerEventServiceVoter from "v4/voters/customerEventServiceVoter";
import CustomEventTriggerVoter from "v4/voters/customEventTriggerVoter";
import MicrosoftGraphVoter from "v4/voters/micrososftGraphVoter";
import ProductCustomizeVoter from "v4/voters/productCustomizeVoter";
import ProductVoter from "v4/voters/productVoter";
import QuoteLinesVoter from "v4/voters/quoteLinesVoter";
import SavedRequestVoter from "v4/voters/savedRequestVoter";
import Ged from "views/Ged/Ged";
import CustomerCodeProspectLoader from "views/Prospects/customerCodeProspectLoader";
import ModuleConfigurationVoter from "v4/voters/ModuleConfigurationVoter";
import ModuleConfigurationsPage from "v4/pages/admin/ModuleConfigurations/ModuleConfigurationsPage";
import AutomatedStatusPage from "v4/pages/admin/AutomatedStatus/AutomatedStatusPage";
import ViewOrdersPage from "v4/pages/admin/ViewOrders/ViewOrdersPage";

const {canProductShowList} = ProductVoter();
const {canAccessQuoteLines} = QuoteLinesVoter();
const {canAccessMicrosoftGraph} = MicrosoftGraphVoter();
const {canAccessSavedRequest} = SavedRequestVoter();
const {canAccessCustomerEventService} = CustomerEventServiceVoter();
const {canAccessProductAttributes, canAccessProductConfigurations} = ProductCustomizeVoter();
const {canAccessCustomEventTrigger} = CustomEventTriggerVoter();
const {canAccessModuleConfiguration} = ModuleConfigurationVoter();

/* Routes V4 : base paths */
export const routesBase = {
    login: '/',
    baseHome: '/home',
    baseDashboard: '/tableau-de-bord',
    baseOldDashboard: '/old-dashboard',
    baseProspect: '/clients-prospects',
    baseContact: '/contacts',
    baseTask: '/actions',
    baseQuote: '/devis-offres',
    baseArticle: '/articles',
    baseMails: '/mails',
    baseNewsletter: '/mails/newsletters',
    baseMailsTypes: '/mails/mails-types',
    baseGed: '/gestion-de-documents',
    baseTab: '/onglets',
    baseMicrosoftGraph: '/microsoft-graph',
    adminManageCategories: '/administration/categories',
    adminManageTemplates: '/administration/templates',
    adminListings: '/administration/listings',
    adminCustomActions: '/administration/actions',
    adminProductAttributes: '/administration/product_attributes',
    adminProductConfigurations: '/administration/product_configurations',
    adminCustomerEventServices: '/administration/event_services',
    adminCustomEventTriggers: '/administration/event_triggers',
    adminMicrosoftGraph: '/administration/microsoft-graph',
    adminSavedRequest: '/administration/saved-request',
    adminModuleConfiguration: '/administration/module-configuration',
    adminAutomatedStatus: '/administration/automated-status',
    adminNewViewOrders: '/administration/new-view-orders',
};

/* Define entries groups for Admin sidebar */
export const menuGroupAdminV4 = [
    {
        name: 'manage_templates',
        path: '/',
        icon: 'document',
        groupName: 'manage_templates',
        roles: [
            "ROLE_ADMIN",
        ],
        testIsGranted: canAccessQuoteLines
    },
    {
        name: 'microsoft_graph_api',
        path: '/',
        icon: 'document',
        groupName: 'microsoft_graph_api',
        roles: [
            ROLE_SUPER_ADMIN,
        ],
        testIsGranted: canAccessMicrosoftGraph
    },
    {
        name: 'productConfiguration',
        path: '/',
        icon: 'document',
        groupName: 'productConfiguration',
        roles: [
            ROLE_ADMIN,
        ],
        testIsGranted: canAccessProductConfigurations
    },
]

/* Routes for sidebars and menus */
export function getRoutesParams() {
    return [
        {
            id: 'home',
            className: 'home',
            basePath: routesBase.baseHome,
            path: [
                routesBase.baseHome,
            ],
            component: HomePage,
            isProtected: true,
            testIsGranted: () => true,
        },
        {
            id: 'dashboard',
            className: 'dashboard',
            basePath: routesBase.baseDashboard,
            path: [
                routesBase.baseDashboard,
            ],
            component: DashboardPage,
            isProtected: true,
            menuTitle: 'dashboard',
            testIsGranted: () => true,
        },
        {
            id: 'customer-code',
            basePath: `${routesBase.baseProspect}/customer-code/:customerCode`,
            path: [
                `${routesBase.baseProspect}/customer-code/:customerCode`,
            ],
            component: CustomerCodeProspectLoader,
            isProtected: true,
            testIsGranted: () => true,
        },
        {
            id: 'prospects',
            className: 'prospects',
            basePath: routesBase.baseProspect,
            path: [
                routesBase.baseProspect,
                `${routesBase.baseProspect}/add/business`,
                `${routesBase.baseProspect}/add/individual`,
                `${routesBase.baseProspect}/maps`,
                `${routesBase.baseProspect}/:prospectId/maps`,
                `${routesBase.baseProspect}/:prospectId/:tabName/add`,
                `${routesBase.baseProspect}/:prospectId/:tabName/resume`,
                `${routesBase.baseProspect}/:prospectId/:tabName/mail`,
                `${routesBase.baseProspect}/:prospectId/:tabName`,
                `${routesBase.baseProspect}/:prospectId/:tabName/edit/:showId`,
                `${routesBase.baseProspect}/:prospectId/:tabName/:showId`,
            ],
            component: ListingPageLayout,
            groupCode: 'listingPage',
            entity: PROSPECT,
            isProtected: true,
            menuTitle: 'comptes_prospects_clients',
            testIsGranted: () => true,
        },
        {
            id: 'contacts',
            className: 'contacts',
            basePath: routesBase.baseContact,
            path: [
                routesBase.baseContact,
            ],
            component: ListingPageLayout,
            groupCode: 'listingPage',
            entity: CONTACT,
            isProtected: true,
            menuTitle: 'contacts',
            testIsGranted: () => true,
        },
        {
            id: 'tasks',
            className: 'actions',
            basePath: routesBase.baseTask,
            path: [
                routesBase.baseTask,
            ],
            component: ListingPageLayout,
            groupCode: 'listingPage',
            entity: TASK,
            isProtected: true,
            menuTitle: 'actions',
            testIsGranted: () => true,
        },
        {
            id: 'quotes',
            className: 'devis',
            basePath: routesBase.baseQuote,
            path: [
                routesBase.baseQuote,
            ],
            component: ListingPageLayout,
            groupCode: 'listingPage',
            entity: QUOTE,
            isProtected: true,
            menuTitle: 'devis_offres',
            testIsGranted: () => true,
        },
        {
            id: 'microsoft_graph_redirect',
            className: 'microsoft-graph',
            basePath: routesBase.baseMicrosoftGraph,
            path: [
                routesBase.baseMicrosoftGraph,
            ],
            component: MicrosoftGraphPage,
            isProtected: false,
            isExternal: true,
            testIsGranted: () => true,
        },
        {
            id: 'mails',
            className: 'mails',
            basePath: routesBase.baseMails,
            subMenu: [
                {
                    id: 'mails-types',
                    className: 'mails',
                    basePath: routesBase.baseMailsTypes,
                    path: [
                        routesBase.baseMailsTypes,
                        `${routesBase.baseMailsTypes}/edit/:mailTypeId`,
                        `${routesBase.baseMailsTypes}/add`
                    ],
                    component: MailsTypesPage,
                    entity: MAIL_TYPE,
                    isProtected: true,
                    menuTitle: 'gestion_mails_type',
                    testIsGranted: () => true
                },
                {
                    id: 'newsletter-add',
                    className: 'mails',
                    basePath: `${routesBase.baseNewsletter}/add`,
                    path: [
                        `${routesBase.baseNewsletter}/add`
                    ],
                    component: NewsletterPage,
                    isProtected: true,
                    menuTitle: 'creer_newsletter',
                    testIsGranted: () => true
                },
                {
                    id: 'newsletter-manager',
                    className: 'mails',
                    basePath: `${routesBase.baseNewsletter}/gestions`,
                    path: [
                        `${routesBase.baseNewsletter}/gestions`
                    ],
                    component: NewsletterPage,
                    isProtected: true,
                    menuTitle: 'gestion_newsletters',
                    testIsGranted: () => true
                },
                {
                    id: 'newsletter-journal',
                    className: 'mails',
                    basePath: `${routesBase.baseNewsletter}/journal`,
                    path: [
                        `${routesBase.baseNewsletter}/journal`
                    ],
                    component: NewsletterPage,
                    isProtected: true,
                    menuTitle: 'journal_newsletters',
                    testIsGranted: () => true
                },
                {
                    id: 'newsletter-stats',
                    className: 'mails',
                    basePath: `${routesBase.baseNewsletter}/statistiques`,
                    path: [
                        `${routesBase.baseNewsletter}/statistiques`
                    ],
                    component: NewsletterPage,
                    isProtected: true,
                    menuTitle: 'statistiques_newsletters',
                    testIsGranted: () => true
                },
                {
                    id: 'emailing',
                    className: 'mails',
                    basePath: `${routesBase.baseNewsletter}/emailing`,
                    path: [
                        `${routesBase.baseNewsletter}/emailing`
                    ],
                    component: NewsletterPage,
                    isProtected: true,
                    testIsGranted: () => true
                },
                {
                    id: 'contact-import',
                    className: 'mails',
                    basePath: `${routesBase.baseNewsletter}/import-destinataires`,
                    path: [
                        `${routesBase.baseNewsletter}/import-destinataires`
                    ],
                    component: NewsletterPage,
                    isProtected: true,
                    menuTitle: 'import_destinataires',
                    testIsGranted: () => true
                },
            ],
            isProtected: true,
            menuTitle: 'mails',
            testIsGranted: () => true,
        },
        {
            id: 'ged',
            className: 'ged',
            basePath: routesBase.baseGed,
            path: [
                routesBase.baseGed
            ],
            component: Ged,
            isProtected: true,
            menuTitle: 'ged',
            testIsGranted: () => true
        },
        {
            id: 'articles',
            className: 'articles',
            basePath: routesBase.baseArticle,
            path: [
                routesBase.baseArticle,
                `${routesBase.baseArticle}/edit/:articleId`,
                `${routesBase.baseArticle}/add`
            ],
            component: ProductsPage,
            entity: PRODUCT,
            isProtected: true,
            menuTitle: 'articles',
            testIsGranted: canProductShowList,
        },

        /* ADMINISTRATION */
        {
            id: 'new-view-orders',
            className: 'new-view-orders',
            basePath: routesBase.adminNewViewOrders,
            path: [routesBase.adminNewViewOrders],
            component: ViewOrdersPage,
            isProtected: true,
            menuTitle: 'admin_gestion_orders',
            group: 'parametrage',
            isAdmin: true,
            testIsGranted: () => true,
            roles: [ROLE_ADMIN],
        },
        {
            id: 'invoice_templates',
            className: 'templates--invoices',
            basePath: routesBase.adminManageTemplates,
            path: [
                routesBase.adminManageTemplates,
                `${routesBase.adminManageTemplates}/edit/:id`,
                `${routesBase.adminManageTemplates}/add`
            ],
            component: TemplatesPage,
            isProtected: true,
            menuTitle: 'manage_invoices_templates',
            isAdmin: true,
            group: 'manage_templates',
            testIsGranted: canAccessQuoteLines
        },
        /*{ A REMETTRE LORS DU PASSAGE V4 sans oublier l'absence des listes params
            id: 'listing',
            className: 'listing',
            basePath: routesBase.adminListings,
            path: [
                routesBase.adminListings,
                `${routesBase.adminListings}/edit/:id`,
                `${routesBase.adminListings}/add`,
            ],
            component: ListingsPage,
            isProtected: true,
            menuTitle: 'admin_gestion_listes_v4',
            isAdmin: true,
            group: 'parametrage',
            testIsGranted: canAccessListing
        },*/
        {
            id: 'manage_actions',
            className: 'manage-actions',
            basePath: routesBase.adminCustomActions,
            path: [
                routesBase.adminCustomActions,
                `${routesBase.adminCustomActions}/edit/:id`,
                `${routesBase.adminCustomActions}/add`,
            ],
            component: CustomActionsPage,
            isProtected: true,
            menuTitle: 'manage_actions',
            isAdmin: true,
            group: 'parametrage',
            testIsGranted: () => true
        },
        {
            id: 'manage_customer_event_services',
            className: 'manage-customer-event-services',
            basePath: routesBase.adminCustomerEventServices,
            path: [
                routesBase.adminCustomerEventServices,
                `${routesBase.adminCustomerEventServices}/edit/:id`,
                `${routesBase.adminCustomerEventServices}/add`,
            ],
            component: CustomerEventServicesPage,
            isProtected: true,
            menuTitle: 'manage_customer_event_services',
            isAdmin: true,
            group: 'parametrage',
            testIsGranted: () => canAccessCustomerEventService
        },
        {
            id: 'manage_product_attributes',
            className: 'manage-product-attributes',
            basePath: routesBase.adminProductAttributes,
            path: [
                routesBase.adminProductAttributes,
                `${routesBase.adminProductAttributes}/edit/:id`,
                `${routesBase.adminProductAttributes}/add`,
            ],
            roles: [ROLE_MODULE_PRODUCT_CUSTOMIZE],
            component: ProductAttributesPage,
            isProtected: true,
            menuTitle: 'manage_product_attributes',
            isAdmin: true,
            group: 'productConfiguration',
            testIsGranted: () => canAccessProductAttributes()
        },
        {
            id: 'manage_product_configurations',
            className: 'manage-product-configurations',
            basePath: routesBase.adminProductConfigurations,
            path: [
                routesBase.adminProductConfigurations,
                `${routesBase.adminProductConfigurations}/edit/:id`,
                `${routesBase.adminProductConfigurations}/add`,
            ],
            roles: [ROLE_MODULE_PRODUCT_CUSTOMIZE],
            component: ProductConfigurationsPage,
            isProtected: true,
            menuTitle: 'manage_product_configurations',
            isAdmin: true,
            group: 'productConfiguration',
            testIsGranted: () => canAccessProductConfigurations()
        },
        {
            id: 'manage_custom_event_triggers',
            className: 'manage-custom-event-triggers',
            basePath: routesBase.adminCustomEventTriggers,
            path: [
                routesBase.adminCustomEventTriggers,
                `${routesBase.adminCustomEventTriggers}/edit/:id`,
                `${routesBase.adminCustomEventTriggers}/add`,
            ],
            component: CustomEventTriggersPage,
            isProtected: true,
            menuTitle: 'manage_custom_event_triggers',
            isAdmin: true,
            group: 'parametrage',
            testIsGranted: () => canAccessCustomEventTrigger
        },
        {
            id: 'microsoft_graph',
            className: 'microsoft-graph',
            basePath: routesBase.adminMicrosoftGraph,
            path: [
                routesBase.adminMicrosoftGraph,
                `${routesBase.adminMicrosoftGraph}/edit/:id`,
                `${routesBase.adminMicrosoftGraph}/add`,
                `${routesBase.adminMicrosoftGraph}/:subscriptionConfigId`,
                `${routesBase.adminMicrosoftGraph}/:subscriptionConfigId/edit/:id`,
                `${routesBase.adminMicrosoftGraph}/:subscriptionConfigId/add`
            ],
            component: OutlookSubscriptionConfigurationsPage,
            isProtected: true,
            menuTitle: 'subscription_configuration',
            isAdmin: true,
            group: 'microsoft_graph_api',
            testIsGranted: canAccessMicrosoftGraph
        },
        {
            id: 'saved_request',
            className: 'saved-request',
            basePath: routesBase.adminSavedRequest,
            path: [
                routesBase.adminSavedRequest,
                `${routesBase.adminSavedRequest}/edit/:id`,
                `${routesBase.adminSavedRequest}/add`,
            ],
            component: SavedRequestsPage,
            isProtected: true,
            menuTitle: 'saved_request',
            isAdmin: true,
            group: 'parametrage',
            testIsGranted: canAccessSavedRequest
        },
        {
            id: 'module_configuration',
            className: 'module-configuration',
            basePath: routesBase.adminModuleConfiguration,
            path: [
                `${routesBase.adminModuleConfiguration}/:customerId/:moduleId`,
                `${routesBase.adminModuleConfiguration}/:customerId/:moduleId/edit/:id`,
                `${routesBase.adminModuleConfiguration}/:customerId/:moduleId/add`,
            ],
            component: ModuleConfigurationsPage,
            isProtected: true,
            menuTitle: 'module_configuration',
            isAdmin: true,
            testIsGranted: canAccessModuleConfiguration
        },
        {
            id: 'automated_status',
            className: 'automated-status',
            basePath: routesBase.adminAutomatedStatus,
            path: [
                `${routesBase.adminAutomatedStatus}`,
            ],
            component: AutomatedStatusPage,
            isProtected: true,
            menuTitle: 'automated_status',
            isAdmin: true,
            group: 'parametrage',
            testIsGranted: () => true
        },

        // Custom tabs
        ...getRouteParamsForTabs(),
    ];
}

export function getFlatRoutes() {
    return getRoutesParams().reduce((acc, route) => {
        if (route.subMenu?.length > 0) {
            route.subMenu.forEach(subRoute => {
                acc.push(subRoute);
            });
        } else {
            acc.push(route);
        }

        return acc;
    }, []);
}

export function getRouteById(id) {
    return getFlatRoutes().find(route => route.id === id);
}

export function getRouteByBasePath(basePath, startsWith = false) {
    return getFlatRoutes().find(route => {
        if (startsWith) {
            return basePath.startsWith(route.basePath);
        }

        return basePath === route.basePath;
    });
}

export function getRouteByEntity(entity) {
    return getFlatRoutes().find(route => route.entity === entity);
}

export function getRoutesByFilters(filters = {}) {
    return getFlatRoutes().filter(route => Object.keys(filters).every(key => route[key] === filters[key]));
}

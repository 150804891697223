import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ManageTasksRow
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/components/ManageTasks/components/ManageTasksRow/ManageTasksRow";
import useManageTasks
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectQuotesTab/components/ProspectQuoteTabShow/components/QuoteTasksButton/components/ManageTasks/hooks/useManageTasks";

export default function ManageTasks({quote}) {
    const {t} = usePolTranslation();
    const [isLoading, taskLines] = useManageTasks(quote);

    if (isLoading) return <p>{t('loading')}</p>

    if (!taskLines.length) return <p>{t('no_tasks')}</p>

    return taskLines.map(taskLine => <ManageTasksRow key={taskLine.id} {...taskLine}/>)
}

export const formatForView = (value, valueType) => {
    if (!value && value !== 0) {
        return '';
    }
    if (typeof value === 'object' && value !== null) {
        value = value.value;
    }
    switch (valueType) {
        case 'percent' :
            return parseFloat(value) + ' %';
        case 'money' :
            if (typeof value === 'string') {
                value = parseFloat(('' + value).replace(/\s/g, '').replace(',', '.'));
            }
            return parseFloat(value).toLocaleString(
                undefined,
                {minimumFractionDigits: 2}
            ) + ' €';
        case 'stat-money' :
            if (typeof value === 'string') {
                value = parseFloat(('' + value).replace(/\s/g, '').replace(' ', '.'));
            }
            return parseFloat(value).toFixed(0).replace(/(.)(?=(\d{3})+$)/g, '$1 ') + ' €';
        case 'phone' :
            return value;
        default:
            const valueToTest = value.split(';');

            if (valueToTest.length > 1) {
                return valueToTest.join(' / ')
            }
            return value
    }
}
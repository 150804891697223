import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {getFormattedValue} from "v4/utils";

export default function ExportPopupLine({line, problemExports, exportDownload}) {
    const {t} = usePolTranslation();
    const {id, exported, totalItems, entity, requestedAt, finished, status} = line;
    const isIndeterminate = status === 'indeterminate';
    const isFinished = !isIndeterminate && finished;
    const isStuck = problemExports?.stucked.includes(id) ?? false;
    const isError = problemExports?.error.includes(id) ?? false;

    const getMessage = () => {
        if (isFinished) return t('download');
        if (isStuck) return t('stuck');
        if (isError) return t('export_data_error');
        return t('status_inProgress');
    }

    const getClassNames = () => {
        const classNames = ['export-popup__line'];
        if (isFinished) classNames.push('downloadable');
        if (isStuck) classNames.push('stuck');
        if (isError) classNames.push('timeout');
        return classNames.join(' ');
    }

    return (
        <li key={id} /* key is for optimisation */
            data-id={id}
            className={getClassNames()}
            onClick={isFinished ? exportDownload?.(id) : undefined}>
            <div className="export-popup__line__content">
                <p className="export-popup__line__text">
                    {requestedAt && <span className="export-popup__line__date">{getFormattedValue('datetime', requestedAt, {keepTime: true})} </span>}
                    {!!entity && <span className="export-popup__line__entity">{t(entity)} : </span>}
                    {getMessage()}
                </p>
                {!isIndeterminate && (
                    <p className="export-popup__line__percent">
                        {isFinished
                            ? <PolIcon icon="download"/>
                            : `${((exported / totalItems) * 100).toFixed(2)}%`
                        }
                    </p>
                )}
            </div>
            <progress className="export-popup__line__progress"
                      {...(exported && !isIndeterminate) && {value: exported}}
                      {...totalItems && {max: totalItems}}
                      {...(isFinished && !isIndeterminate) && {value: 1, max: 1}}/>
        </li>
    )
}

import React from 'react';

export default () => (
    <>

        <svg id="_x2014_ÎÓÈ_x5F_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" viewBox="0 0 1040 740">

            <rect x="0" y="0" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 1039.9962 740)" className="st0"
                  width="1040" height="740"/>
            <path className="st1" d="M140.7,609h710.7c117.3-68.2,96.2-207.9,63.6-231.4c-44.5-32.1-114.2,88.8-157.7,66.7
	c-124.3-63.1,134.1-240.8,30-282c-67.8-26.8-114.8,178.2-197.4,106.6c-43-37.2-3.9-91.5-12.2-135.5c-13-69.8-125.6-34-224.5,140.7
	c-41.7,73.6-98.8-63.9-145.3-80.1c-43.2-15-173.7,52.8-38.8,205.4C212.1,448.1,0.3,468.6,140.7,609z"/>
            <path className="st1" d="M320.6,124c-0.5-4.5-1.8-7.6-4.9-9.3c-9.2-5-32.4,3.1-42.1,15.7c-13.4,17.5-1.2,44.4,11.9,46.1
	C300.7,178.5,323.2,147.2,320.6,124z"/>
            <g>
	<path className="st2" d="M538,625H324.8H538z"/>
                <rect x="324.8" y="624" className="st3" width="213.1" height="2"/>
</g>
            <g>
	<path className="st2" d="M377.3,640.2H243.7H377.3z"/>
                <rect x="243.7" y="639.2" className="st3" width="133.6" height="2"/>
</g>
            <g>
	<path className="st2" d="M609.7,640.2H433.4H609.7z"/>
                <rect x="433.4" y="639.2" className="st3" width="176.3" height="2"/>
</g>
            <g>
	<path className="st2" d="M264.8,625.2h-63.8H264.8z"/>
                <rect x="200.9" y="624.2" className="st3" width="63.8" height="2"/>
</g>
            <g>
	<path className="st2" d="M700.8,625H571.5H700.8z"/>
                <rect x="571.5" y="624" className="st3" width="129.2" height="2"/>
</g>
            <rect x="70.8" y="608" className="st3" width="898.4" height="2"/>
            <rect x="433.4" y="608" className="st4" width="72" height="2"/>
            <rect x="517.1" y="608" className="st4" width="9.2" height="2"/>
            <rect x="839.3" y="608" className="st4" width="9.2" height="2"/>
            <path className="st0" d="M541.3,344.2c0,4.8-3.1,8.8-7.5,10l-27.5,8.4v5.6h-24v-5.6l-27.6-8.4h0c-4.3-1.3-7.5-5.3-7.5-10
	c0-4.6,2.9-8.4,7-9.9v-81.2c-4.1-1.4-7-5.3-7-9.9c0-5.8,4.7-10.5,10.5-10.5h73c5.8,0,10.5,4.7,10.5,10.5c0,4.6-2.9,8.4-7,9.9v81.2
	C538.3,335.7,541.3,339.6,541.3,344.2z"/>
            <path className="st5" d="M455.3,265.9h-2v-12.1c-4.2-1.8-7-6-7-10.6c0-6.3,5.2-11.5,11.5-11.5h13.5v2h-13.5c-5.2,0-9.5,4.3-9.5,9.5
	c0,4,2.5,7.6,6.3,8.9l0.7,0.2V265.9z"/>
            <path className="st3" d="M541.3,344.2c0,4.8-3.1,8.8-7.5,10l-27.5,8.4v5.6h-24v-5.6l-27.6-8.4h0c-4.3-1.3-7.5-5.3-7.5-10
	c0-4.6,2.9-8.4,7-9.9v-59.4h80v59.4C538.3,335.7,541.3,339.6,541.3,344.2z"/>
            <rect x="447.3" y="368.2" className="st6" width="94" height="240"/>
            <rect x="444.3" y="411.2" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 932.4517 -56.1011)"
                  className="st4" width="100" height="54"/>
            <circle className="st7" cx="481.3" cy="409.4" r="7"/>
            <circle className="st7" cx="507.3" cy="409.4" r="7"/>
            <rect x="480.3" y="409.4" className="st0" width="2" height="17"/>
            <rect x="506.3" y="409.4" className="st0" width="2" height="17"/>
            <circle className="st0 bubbles" cx="494.3" cy="319.3" r="2"/>
            <circle className="st0 bubbles" cx="494.3" cy="333.4" r="2"/>
            <circle className="st0 bubbles" cx="494.3" cy="350.5" r="2"/>
            <circle className="st0 bubbles" cx="515.3" cy="309.1" r="2"/>
            <circle className="st0 bubbles" cx="515.3" cy="319.3" r="2"/>
            <circle className="st0 bubbles" cx="515.3" cy="333.4" r="2"/>
            <circle className="st0 bubbles" cx="473.3" cy="290" r="2"/>
            <circle className="st0 bubbles" cx="473.3" cy="299.1" r="2"/>
            <circle className="st0 bubbles" cx="473.3" cy="319.3" r="2"/>
            <rect x="467.3" y="486.2" className="st0" width="54" height="2"/>
            <path className="st5" d="M375.5,370.8c0.2,4.8-0.5,9.5-2,13.8l-67.5,3.1c-1.9-4.1-3-8.7-3.3-13.5c-0.9-20.1,14.6-37.2,34.8-38.1
	C357.5,335.1,374.6,350.7,375.5,370.8z"/>
            <ellipse transform="matrix(0.9802 -0.1978 0.1978 0.9802 -66.9861 74.4504)" className="st5" cx="339.1"
                     cy="372.5" rx="10" ry="10"/>
            <g>
	<path className="st8" d="M473.7,276.8c0.6,4.4-2.5,8.4-6.9,9c-4.4,0.6-8.4-2.5-9-6.9c-0.6-4.4,2.5-8.4,6.9-9
		C469.1,269.3,473.1,272.4,473.7,276.8z"/>
                <path className="st8" d="M458.1,280.8c-0.4-3.3-3.5-5.6-6.7-5.2c-3.3,0.4-5.6,3.5-5.2,6.7c0.4,3.3,3.5,5.6,6.7,5.2
		S458.5,284.1,458.1,280.8z"/>
                <polygon className="st8" points="462.5,270.5 449.7,276.1 452.9,287.6 466.8,285.7 	"/>
</g>
            <circle className="st8" cx="393.5" cy="334.7" r="13.6"/>
            <polygon className="st8" points="345.3,288.2 382.2,342.4 406.1,329.4 380.2,269.1 "/>
            <g>
	<polygon className="st8" points="448.5,276.8 456.6,285.7 403.6,343.9 385.4,323.8 440.5,282.8 445.3,279.2 	"/>
                <polygon className="st8" points="448.5,276.8 456.6,285.7 453,289.6 445.3,279.2 	"/>
</g>
            <ellipse transform="matrix(0.1858 -0.9826 0.9826 0.1858 22.4488 581.0377)" className="st5" cx="361.8"
                     cy="277" rx="20" ry="20"/>
            <polygon className="st5" points="345.3,288.2 376,333.2 401.7,319.1 380.2,269.1 "/>
            <g>
	<polygon className="st4" points="374.9,386.1 370.7,470.8 347.6,470.8 347.6,386.1 	"/>
</g>
            <g>
	<g>
		<path className="st4" d="M330.5,387.4c-0.7,7.5-7.3,13-14.8,12.3c-7.5-0.7-13-7.3-12.3-14.8c0.7-7.5,7.3-13,14.8-12.3
			C325.7,373.2,331.2,379.8,330.5,387.4z"/>
	</g>
</g>
            <g>
	<g>
		<circle className="st4" cx="361.2" cy="386.1" r="13.6"/>
	</g>
</g>
            <path className="st5" d="M375.9,271l-0.4,99.8l-72.8,3.3l-9.4-99.3c-0.8-8,5.3-14.9,13.3-15.3l54.7-2.5C369.2,256.6,375.9,263,375.9,271
	z"/>
            <g>
	<path className="st4"
          d="M375.6,372.5l-0.7,13.6l0,0.7c-0.4,7.3-6.4,13-13.6,13H317c-7.3,0-13.3-5.7-13.6-13l0-0.7l-0.7-13.6H375.6z"/>
</g>
            <g>
	<g>
		<polygon className="st4" points="330.5,387.4 322.8,471.7 299.7,469.6 303.4,384.9 		"/>
	</g>
</g>
            <path className="st4" d="M311.1,598.5c-0.4,4.6-4.5,8.1-9.2,7.6c-4.6-0.4-8.1-4.5-7.6-9.2c0.4-4.6,4.5-8.1,9.2-7.6
	C308.1,589.8,311.5,593.9,311.1,598.5z"/>
            <ellipse transform="matrix(0.1602 -0.9871 0.9871 0.1602 -291.4626 853.7774)" className="st9" cx="356"
                     cy="598.2" rx="8.4" ry="8.4"/>
            <g>
	<g>

			<ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -227.744 391.8667)" className="st4" cx="359.2"
                     cy="470.8" rx="11.6" ry="11.6"/>
	</g>
</g>
            <g>
	<g>
		<path className="st4" d="M322.8,471.7c-0.6,6.4-6.2,11-12.6,10.5c-6.4-0.6-11-6.2-10.5-12.6c0.6-6.4,6.2-11,12.6-10.5
			C318.7,459.7,323.4,465.4,322.8,471.7z"/>
	</g>
</g>
            <g>
	<path className="st4" d="M356,598.2V470.8V598.2z"/>
                <polygon className="st4" points="347.6,598.2 347.6,470.8 370.7,470.8 364.5,598.2 	"/>
</g>
            <g>
	<path className="st4" d="M302.7,597.8L314.4,471L302.7,597.8z"/>
                <polygon className="st4" points="294.3,597 299.7,469.6 322.8,471.7 311.1,598.5 	"/>
</g>
            <path className="st7"
                  d="M373,608.2h-25.4v-10h16.9l9.7,5.3c0.8,0.4,1.3,1.3,1.3,2.2l0,0C375.5,607.1,374.3,608.2,373,608.2z"/>
            <path className="st7"
                  d="M319.6,608.2h-25.4v-10h16.9l9.7,5.3c0.8,0.4,1.3,1.3,1.3,2.2l0,0C322.2,607.1,321,608.2,319.6,608.2z"/>
            <path className="st4" d="M358.4,208.3l-3.6,0.2l-1.3-24.5l3.6-0.2c6.8-0.3,12.5,4.9,12.9,11.6l0,0C370.4,202.2,365.2,207.9,358.4,208.3z
	"/>
            <path className="st4" d="M329.8,233l-9.1,0.5c-5,0.3-9.3-3.6-9.5-8.6l-0.5-9.9l18.2-0.9L329.8,233z"/>
            <rect x="322.2" y="231.1" transform="matrix(0.9987 -5.139592e-02 5.139592e-02 0.9987 -12.1306 17.5252)"
                  className="st8" width="25" height="27.1"/>
            <path className="st4" d="M355.9,207.8l-9.9,0.5l-1.6-31.5l8.8-0.5c6-0.3,11.1,4.3,11.5,10.3l0.6,10.8
	C365.6,202.9,361.4,207.5,355.9,207.8z"/>
            <path className="st8" d="M354.3,241l-32.2,1.7l-1.9-36.3c-0.6-11,7.9-20.4,19-21h0c10.7-0.5,19.9,7.4,20.9,18l2.8,27.7
	C363.4,236.1,359.5,240.7,354.3,241z"/>
            <path className="st10" d="M361.8,220.5l-1.7-17.2L361.8,220.5z"/>
            <path className="st4"
                  d="M327.2,182.5l2.7-0.1l1.8,34.2l-20.9,1.1l-0.8-16C309.4,191.6,317.1,183,327.2,182.5z"/>
            <path className="st8" d="M320.9,220.5l5.4-0.3l-0.3-5.4c-0.2-3-2.7-5.3-5.7-5.2l0,0c-3,0.2-5.3,2.7-5.2,5.7l0,0
	C315.4,218.3,317.9,220.7,320.9,220.5z"/>
            <path className="st4" d="M362.7,197.7l-20.7,1.1c-8.4,0.4-15.6-6.1-16.1-14.5c-0.2-4,2.9-7.4,6.9-7.6c4-0.2,7.4,2.9,7.6,6.9
	c0,0.4,0.4,0.7,0.8,0.7l20.7-1.1L362.7,197.7z"/>
            <path className="st4" d="M354.6,190.8l-10.3,0.5c-4,0.2-7.4-2.9-7.6-6.9c-0.2-4,2.9-7.4,6.9-7.6l10.3-0.5c4-0.2,7.4,2.9,7.6,6.9
	C361.7,187.2,358.6,190.6,354.6,190.8z"/>
            <path className="st8" d="M347.9,257.5c0.4,6.9-4.9,12.8-11.8,13.1s-12.8-4.9-13.1-11.8s4.9-12.8,11.8-13.1
	C341.6,245.3,347.5,250.6,347.9,257.5z"/>
            <path className="st4" d="M363.6,238.3c0.4,4.1-2.7,7.8-6.9,8l-14.4,0.7c-8,0.4-14.9-5.8-15.3-13.8l-0.9-17l5.4-0.3l0,0
	c0.2,3.3,3,5.9,6.4,5.7l23.8-1.2L363.6,238.3z"/>
            <polygon className="st8" points="322.3,293.1 277.5,340.9 256,324.4 290.8,268.8 "/>
            <polygon className="st5" points="322.3,293.1 285.1,332.8 261.9,314.9 290.8,268.8 "/>
            <ellipse transform="matrix(0.424 -0.9056 0.9056 0.424 -75.8083 439.6659)" className="st5" cx="307.8"
                     cy="279.4" rx="20" ry="20"/>
            <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -156.1072 286.2985)" className="st8" cx="267.5"
                     cy="331.6" rx="13.6" ry="13.6"/>
            <g>
	<polygon className="st8" points="266.9,318 345.5,314.3 347.2,326.2 343.3,327.1 337.5,328.6 270.8,344.8 	"/>
                <polygon className="st8" points="347.2,326.2 345.5,314.3 340.2,314.6 343.3,327.1 	"/>
</g>
            <polygon className="st4" points="374.4,334.9 358.4,334.9 356,304.9 376.8,304.9 "/>
            <g>
	<ellipse transform="matrix(0.2106 -0.9776 0.9776 0.2106 -30.3786 605.7589)" className="st8" cx="359.9" cy="321.7"
             rx="8" ry="8"/>
                <ellipse transform="matrix(0.978 -0.2087 0.2087 0.978 -59.2496 79.2436)" className="st8" cx="345.8"
                         cy="320.3" rx="6" ry="6"/>
                <polygon className="st8" points="358,329.5 344.4,326.2 345.5,314.3 359.5,313.7 	"/>
</g>
            <path className="st0"
                  d="M359,230.4c0,1.7-1.4,3-3,3c-1.7,0-3-1.4-3-3c0-1.7,1.4-3,3-3C357.7,227.4,359,228.8,359,230.4z"/>
            <path className="st5" d="M507.3,368.2h-2v-6.3l28.2-8.6c4-1.2,6.8-4.9,6.8-9.1c0-4-2.5-7.6-6.3-8.9l-0.7-0.2v-60.1h2v58.7
	c4.2,1.8,7,6,7,10.6c0,5.1-3.3,9.5-8.2,11l-26.8,8.2V368.2z"/>
            <path className="st5" d="M535.3,268.9h-2v-16.5l0.7-0.2c3.8-1.3,6.3-4.9,6.3-8.9c0-5.2-4.3-9.5-9.5-9.5h-25.5v-2h25.5
	c6.3,0,11.5,5.2,11.5,11.5c0,4.6-2.8,8.8-7,10.6V268.9z"/>
            <rect x="493.7" y="231.7" className="st5" width="5.8" height="2"/>
            <g>
	<g>
		<path className="st3" d="M758.2,280.7c-2.9,13.6-7.9,26.7-11.8,40.1c-4,13.8-8.1,27.8-8.1,42.1c0.1,18.7,7.5,37.2,20.3,50.8
			c5,5.3,10.9,10,14.8,16.2c3.9,6.2,5.3,14.7,1,20.6c-6.2,8.5-19.6,6.7-28.7,12c-11.5,6.7-13.1,23.8-6.5,35.3s36.3,44.6,78.8,36.5
			c19-3.7,44.8-21.8,44.1-41.1c-0.8-20.2-20-36.5-19.4-56.6c0.4-13.2,9.3-24.4,17-35.1c7.7-10.7,14.8-23.8,11.3-36.6
			c-5.2-18.7-29.3-24.5-41-40.1c-7.5-10-9.2-23.2-9.7-35.7c-0.5-12.5-0.1-25.3-4.3-37.2C805.8,223.2,769.9,226.7,758.2,280.7z"/>
	</g>
                <rect x="801.9" y="352.7" className="st4" width="2" height="217.2"/>
                <path className="st4"
                      d="M803.8,415.5l-2-0.3c3.2-19.7,25.6-31.9,26.5-32.4l0.9,1.8C829.1,384.7,806.9,396.8,803.8,415.5z"/>
                <path className="st4"
                      d="M801.9,501.1c-2.2-13.3-18-21.9-18.2-22l0.9-1.8c0.7,0.4,16.9,9.2,19.2,23.5L801.9,501.1z"/>
                <path className="st6"
                      d="M813.9,608h-22.5c-13.7,0-24.7-11.1-24.7-24.7v-20.7h72v20.7C838.7,596.9,827.6,608,813.9,608z"/>
                <path className="st4"
                      d="M779.5,606c-8.5-4.5-13.8-13.2-13.8-22.8v-7.6h2v7.6c0,8.9,4.9,16.9,12.7,21L779.5,606z"/>
                <rect x="766.7" y="569" className="st0" width="72" height="2"/>
                <rect x="792.8" y="561.5" className="st4" width="45.9" height="2"/>
                <g>
		<path className="st1" d="M803.6,609h46.1H803.6z"/>
                    <rect x="803.6" y="608" className="st4" width="46.1" height="2"/>
	</g>
</g>
            <polygon className="st4" points="610.4,348.5 594.4,348.5 592,318.5 612.8,318.5 "/>
            <path className="st8" d="M589.9,400.8c-1.7-2.1-1.4-5.3,0.7-7c2.1-1.7,5.3-1.4,7,0.7c1.7,2.1,1.4,5.3-0.7,7
	C594.7,403.3,591.6,402.9,589.9,400.8z"/>
            <path className="st8" d="M580.7,397.1L580.7,397.1c-0.7,0.3-1.4,0.6-2,1.1c-3,2.4-3.4,6.9-1,9.8c2.4,3,6.9,3.4,9.8,1l9.3-7.6l-4.9-8.6
	L580.7,397.1z"/>
            <path className="st8" d="M624.1,346.5c4.3,3,10.2,2,13.2-2.3c3-4.3,2-10.2-2.3-13.2s-10.2-2-13.2,2.3
	C618.8,337.6,619.9,343.5,624.1,346.5z"/>
            <polygon className="st6" points="626.2,283.2 620.2,337.7 638.7,341 651.8,287.7 "/>
            <polygon className="st8" points="621.2,334.4 589.3,395.3 597.8,400.5 637.3,344.1 "/>
            <ellipse transform="matrix(0.8373 -0.5467 0.5467 0.8373 -51.6032 395.7239)" className="st6" cx="639.1"
                     cy="284.6" rx="13" ry="13"/>
            <polygon className="st6" points="651.8,287.7 643.3,322.4 622.3,318.7 626.2,283.2 "/>
            <path className="st4" d="M652.8,188.3l4.9,0.3l-4.9,73.4l-14.2-1c-12.3-0.8-21.1-12.1-19.1-24.2L624,211
	C626.3,197.2,638.8,187.3,652.8,188.3z"/>
            <ellipse transform="matrix(0.7494 -0.6621 0.6621 0.7494 -75.1253 518.8578)" className="st6" cx="647.8"
                     cy="358.7" rx="23.3" ry="23.3"/>
            <path className="st6" d="M626.2,283.2c-0.8,7.1,4.4,13.5,11.6,14.3s13.5-4.4,14.3-11.6c0.8-7.1-4.4-13.5-11.6-14.3
	C633.4,270.9,627,276.1,626.2,283.2z"/>
            <circle className="st7" cx="665.5" cy="598.7" r="6.5"/>
            <path className="st7" d="M642.2,598.1c0.3,3.6-2.3,6.7-5.9,7c-3.6,0.3-6.7-2.3-7-5.9c-0.3-3.6,2.3-6.7,5.9-7
	C638.7,591.9,641.9,594.5,642.2,598.1z"/>
            <path className="st6" d="M626.2,284.3l-1.5,72.8l46.4,3.1l24.3-74.9c1.6-5-1.9-10.1-7.1-10.5l-48.2-3.2
	C632.6,271.1,626.3,276.9,626.2,284.3z"/>
            <ellipse transform="matrix(0.7424 -0.67 0.67 0.7424 -45.7078 500.3469)" className="st6" cx="627.7"
                     cy="309.6" rx="13" ry="13"/>
            <path className="st11" d="M624.6,358.7L624.6,358.7h20.6h25.9l2.5,1.9c8.4,6.6,13.4,16.7,13.4,27.3v10c0,7.7-6.3,14-14,14h-40.4
	c-7.7,0-14-6.3-14-14l0-13.7C618.6,375.4,620.6,366.6,624.6,358.7z"/>
            <rect x="619.7" y="284.1" transform="matrix(0.4158 -0.9094 0.9094 0.4158 99.8417 749.6335)" className="st6"
                  width="27.5" height="26"/>
            <path className="st8" d="M616.4,334.8c-0.1,2.8,2,5.1,4.8,5.2c2.8,0.1,5.1-2,5.2-4.8c0.1-2.8-2-5.1-4.8-5.2
	C618.9,329.9,616.6,332.1,616.4,334.8z"/>
            <path className="st6" d="M688.5,300.7c-7.1,0.9-13.6-4.2-14.5-11.4c-0.9-7.1,4.2-13.6,11.4-14.5c7.1-0.9,13.6,4.2,14.5,11.4
	S695.7,299.9,688.5,300.7z"/>
            <path className="st8" d="M609,343.5c0.8,0,1.6-0.1,2.3-0.3l0,0l11.5-3.4l-1.2-9.8l-12-0.6c-3.9-0.2-7.1,2.8-7.3,6.7
	C602.2,340,605.2,343.3,609,343.5z"/>
            <polygon className="st6" points="700,288 699.4,342.8 680.6,343.8 674.1,289.4 "/>
            <polygon className="st6" points="674.1,289.4 678.3,324.9 699.6,323.7 700,288 "/>
            <polygon className="st8" points="688.3,352 620.6,340 621.7,330.1 690.4,333.3 "/>
            <ellipse transform="matrix(0.8648 -0.5021 0.5021 0.8648 -114.2935 371.4261)" className="st11" cx="632.6"
                     cy="398" rx="14" ry="14"/>
            <circle className="st11" cx="673" cy="398" r="14"/>
            <rect x="646.6" y="245.7" transform="matrix(-0.9977 -6.707712e-02 6.707712e-02 -0.9977 1296.2803 561.9843)"
                  className="st8" width="22" height="27.1"/>
            <path className="st7" d="M659,599.2h13v9h-18.2c-1.5,0-2.8-1.2-2.8-2.8l0,0c0-1,0.5-1.9,1.4-2.4L659,599.2z"/>
            <circle className="st7" cx="670.3" cy="470.3" r="11.3"/>
            <polygon className="st11" points="681.6,470.3 672,598.7 659,598.7 659,470.3 "/>
            <g>
	<polygon className="st11" points="659,398 659,470.3 681.6,471.2 686.9,399 	"/>
</g>
            <path className="st11" d="M630.7,468.2c0.5,6.2-4.1,11.7-10.3,12.3c-6.2,0.5-11.7-4.1-12.3-10.3c-0.5-6.2,4.1-11.7,10.3-12.3
	C624.7,457.4,630.2,462,630.7,468.2z"/>
            <polygon className="st11" points="630.7,468.2 642.1,598.1 629.2,599.2 608.2,470.2 "/>
            <g>
	<polygon className="st11" points="646.3,401 630.5,471.7 608.3,467.6 618.7,395.9 	"/>
</g>
            <path className="st8" d="M635.7,202.7l37.5,2.5l-3.3,48.4l-23.3-1.6c-9.3-0.6-16.2-9-15-18.3L635.7,202.7z"/>
            <path className="st8" d="M671.3,232.9l-4.7-0.3l0.3-4.7c0.2-2.6,2.4-4.6,5-4.4l0,0c2.6,0.2,4.6,2.4,4.4,5v0
	C676.2,231.1,673.9,233.1,671.3,232.9z"/>
            <path className="st4" d="M686.2,247.4l-1.7-32.8c-0.7-13.8-11.7-25-25.5-25.9l-3.4-0.2l-1.3,19.1c-0.3,5.2,0.7,10.3,3.2,14.9l5.5,10.5
	c5,9.5,4.1,21.1-2.3,29.7l0,0l9.4,0.6C679.1,263.8,686.6,256.4,686.2,247.4z"/>
            <path className="st4"
                  d="M664,195l-13.9,14.8c-5,5.3-12.1,8.1-19.3,7.6l0,0l-0.9,13.2l1.4-20.6c0.6-9.5,8.8-16.6,18.3-16L664,195z"/>
            <path className="st8" d="M645.7,272c-0.4,6.1,4.2,11.3,10.2,11.7c6.1,0.4,11.3-4.2,11.7-10.2c0.4-6.1-4.2-11.3-10.2-11.7
	C651.3,261.4,646.1,265.9,645.7,272z"/>
            <path className="st7"
                  d="M629.2,599.2h13v9H624c-1.5,0-2.8-1.2-2.8-2.8l0,0c0-1,0.5-1.9,1.4-2.4L629.2,599.2z"/>
            <ellipse transform="matrix(0.6997 -0.7145 0.7145 0.6997 -37.6446 595.9027)" className="st8" cx="690"
                     cy="342.7" rx="9.4" ry="9.4"/>
            <path className="st0" d="M649.4,238.5c-0.2,3-2.8,5.3-5.8,5.2c-3-0.2-5.3-2.8-5.2-5.8L649.4,238.5z"/>
            <rect x="294.3" y="608" className="st4" width="95.1" height="2"/>
            <rect x="616.3" y="608" className="st4" width="31.5" height="2"/>
            <rect x="662.7" y="608" className="st4" width="47.4" height="2"/>
            <rect x="676.6" y="309" transform="matrix(0.9928 -0.1199 0.1199 0.9928 -33.4158 83.5783)" className="st0"
                  width="2" height="20.7"/>
            <rect x="300.2" y="307.6" transform="matrix(0.684 -0.7294 0.7294 0.684 -127.8131 322.0508)" className="st2"
                  width="15.2" height="2"/>
            <g>
	<path className="st4" d="M209.6,556.8c-8.1,0-14.6-6.6-14.6-14.6c0-8.1,6.6-14.6,14.6-14.6c8.1,0,14.6,6.6,14.6,14.6
		C224.2,550.2,217.6,556.8,209.6,556.8z M209.6,529.6c-6.9,0-12.6,5.7-12.6,12.6c0,6.9,5.7,12.6,12.6,12.6c6.9,0,12.6-5.7,12.6-12.6
		C222.2,535.2,216.5,529.6,209.6,529.6z"/>
                <rect x="161.6" y="542.2" className="st3" width="96" height="66"/>
                <rect x="247.6" y="542.2" className="st4" width="10" height="66"/>
                <rect x="161.6" y="574.2" className="st4" width="86" height="2"/>
                <rect x="197.6" y="574.2" className="st0" width="14" height="9"/>
</g>
            <path className="st12" d="M530,239.9h-22.4c-1.8,0-3.3,1.5-3.3,3.3l0,0c0,1.8,1.5,3.3,3.3,3.3H530c1.8,0,3.3-1.5,3.3-3.3l0,0
	C533.3,241.3,531.8,239.9,530,239.9z"/>
            <path className="st12" d="M496.6,239.9L496.6,239.9c-1.8,0-3.3,1.5-3.3,3.3l0,0c0,1.8,1.5,3.3,3.3,3.3l0,0c1.8,0,3.3-1.5,3.3-3.3l0,0
	C499.9,241.3,498.4,239.9,496.6,239.9z"/>
            <rect x="449.3" y="368.2" className="st0" width="2" height="132.7"/>
            <rect x="449.3" y="505.5" className="st0" width="2" height="5.7"/>
</svg>


    </>
)
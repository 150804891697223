import React from 'react';
import useMaintenanceChecker from "v4/features/front/maintenance/MaintenanceChecker/hooks/useMaintenanceChecker";
import MaintenancePage from "v4/pages/front/Maintenance/MaintenancePage";
import Logo from "../../../../../img/logo";

export default function MaintenanceChecker({children}) {
    const [isMaintenance, blockRender] = useMaintenanceChecker();

    if (blockRender) {
        return <main className={'loginpage'}>
            <div className={'login__loader'}>
                <div className={'login__loader__logo'}>
                    <Logo/>
                </div>
            </div>
        </main>;
    }

    return isMaintenance ? <MaintenancePage/> : children
}

import React from 'react';
import {usePolTranslation} from "../../../v4/hooks/usePolTranslation";

export default function InputDisplayInfo({inputData}) {
    const {t} = usePolTranslation();

    return (
        <div className={'input__display-info'}>
            <span className={'field__label field__label-radio'}>{t(inputData.title)}</span>
            {inputData.enum_titles.map((tag, index) => <span key={index}
                                                          className={'input__display-info__item'}>{tag}</span>)}
        </div>
    )
};

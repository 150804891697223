import {baseProspect} from "../routes";

const stateDefault = {
    expanded: null,
    popup: {},
    loader: {},
    history: [
        baseProspect
    ],
    refresh_list: false,
    transitionDep: false,
    modifyInfo: false
};

const content = (state = stateDefault, action) => {
    switch (action.type) {
        case 'SET_LOADER' :
            return {
                ...state,
                loader: {
                    ...state.loader,
                    ...action.loader
                }
            };
        case 'RESET_LOADER' :
            return {
                ...state,
                loader: {}
            };
        case 'SET_HISTORY':
            return {
                ...state,
                history: [
                    ...state['history'],
                    action.route
                ]
            };
        case 'SET_TRANSITION_DEP':
            return {...state, transitionDep: action.route};
        case 'SET_EXPAND_INFO':
            return {...state, expanded: action.expanded};
        case 'SET_POPUP':
            return {...state, popup: action.popupInfo};
        case 'SET_MODIFY_INFO':
            return {...state, modifyInfo: action.modifyInfo};
        case 'SET_ADD_INFO':
            return {...state, addInfo: action.addInfo};
        case 'SET_GENERAL_CLASS':
            return {...state, className: action.className};
        case 'SET_REDIRECT_LIST':
            return {...state, redirectToList: action.redirectToList}
        case 'RESET_STORE':
            return stateDefault;
        default:
            return state;
    }
};

export default content;

import useFetch from "v4/hooks/useFetch";
import apiService from "v4/services/api.service";
import {QUOTE_GENERATE} from "v4/data/apiRoutes";
import {useEffect, useState} from "react";
import UserService from "api/user/user";

export default function useTemplatePdf(updateHandler) {
    const customerId = UserService.getCustomerId()
    const [{
        data: dataGenerate,
        isLoading: isLoadingGenerate,
        isFinished: isFinishedGenerate,
        isError: isErrorGenerate
    }, fetchGenerate] = useFetch()
    const [isDownloadReady, setIsDownloadReady] = useState({})

    const generate = ({quoteId, template, showPrices, mode}) => {
        if (quoteId && template) {
            fetchGenerate({
                url: apiService.generateUrl(QUOTE_GENERATE).replace('$id$', quoteId),
                config: {
                    method: 'POST',
                    body: JSON.stringify({templateId: template, showPrices, customerId, mode})
                }
            })
        }
    }

    useEffect(() => {
        if (
            dataGenerate &&
            isFinishedGenerate &&
            !isErrorGenerate &&
            dataGenerate.filename &&
            dataGenerate.id &&
            dataGenerate.downloadLink
        ) {
            const file = {
                fileLabel: dataGenerate.fileLabel ?? dataGenerate.filename,
                ...dataGenerate,
                quotePdf: true,
            }

            updateHandler(file)
            setIsDownloadReady(file)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGenerate, isFinishedGenerate, isErrorGenerate])

    return ({
        generate,
        isLoadingGenerate,
        isFinishedGenerate,
        isDownloadReady,
        setIsDownloadReady,
    })
}

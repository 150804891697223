import React, {useState} from 'react';
import {SORT_BY_DATE, SORT_BY_NAME, SORT_BY_SIZE} from "../../../../views/Ged/Ged";
import {PolIcon} from "../../../PolIcon/policon";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {connect} from "react-redux";

const Sort = ({activeSort, setActiveSort, t, className, specificClassName = ''}) => {
    const [selectState, setSelectState] = useState(false);
    const [iconState, setIconState] = useState(false);

    return (
        <div className={`${className} ${specificClassName ? specificClassName : ''}`}>
            <div onClick={() => {
                setSelectState(!iconState)
                setIconState(!selectState);
            }}>
                <span>{`${t('ged_sort_by')} : ${t(activeSort)}`}</span>
                <PolIcon icon={'chevron-down'} className={`ged__nav-menu__select-icon ${iconState ? '' : 'close'}`}/>
                <ul className={`${className}-content ${selectState ? '' : 'hidden'}`}>
                    <li onClick={() => setActiveSort(SORT_BY_NAME)}
                        className={activeSort === SORT_BY_NAME ? 'selected' : ''}>{t('name')}</li>
                    <li onClick={() => setActiveSort(SORT_BY_DATE)}
                        className={activeSort === SORT_BY_DATE ? 'selected' : ''}>{t('date')}</li>
                    <li onClick={() => setActiveSort(SORT_BY_SIZE)}
                        className={activeSort === SORT_BY_SIZE ? 'selected' : ''}>{t('size')}</li>
                </ul>
            </div>
        </div>
    )
}

export default connect()(withPolTranslation(Sort));

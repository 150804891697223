import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link, useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectContactsTab/components/ProspectContactsTabActions/ProspectContactsTabActions.scss';
import ProspectTabSearchListing
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectTabSearchListing/ProspectTabSearchListing";
import {CONTACT} from "v4/data/entities";

export default function ProspectContactsTabActions({selectedContactsWithMail}) {
    const {t} = usePolTranslation();
    const {push, location: {pathname}} = useHistory();

    const onMailingClick = () => {
        if (selectedContactsWithMail.length === 0) return;
        push(`${pathname}/mail`, {selectedContacts: selectedContactsWithMail});
    }

    return (
        <>
            <ProspectTabSearchListing entity={CONTACT}/>
            <button className={`prospect-tab__action ${selectedContactsWithMail?.length > 0 ? '' : 'disabled'}`}
                    data-for="mailing"
                    data-tip={selectedContactsWithMail?.length > 0 ? '' : t('error_mailing_picking')}
                    onClick={onMailingClick}>
                <PolIcon icon="envelope"/>
            </button>
            <ReactTooltip id="mailing" place="bottom" effect="solid" className='mailing-tooltip'/>
            <Link to={({pathname}) => `${pathname}/add`} className="prospect-tab__action">
                <PolIcon icon="plus-circle"/>
                <span>{t('add_contacts')}</span>
            </Link>
        </>
    )
}

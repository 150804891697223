export default

function getViewOrdersUrl(entity, type, subType) {
    const urlStart = `${process.env.REACT_APP_HOST_API}/api`;

    switch (entity.toLowerCase()) {
        case 'prospect':
            switch (subType) {
                case 'business':
                    switch (type) {
                        case 'form':
                            return `${urlStart}/prospect/business/form`;
                        default:
                            return `${urlStart}/prospect/business/search/form`;
                    }
                case 'individual':
                    switch (type) {
                        case 'form':
                            return `${urlStart}/prospect/individual/form`;
                        default:
                            return `${urlStart}/prospect/individual/search/form`;
                    }
                default:
                    return `${urlStart}/prospects/search/form`;
            }
        case 'contact':
            switch (type) {
                case 'form':
                    return `${urlStart}/contact/form`;
                default:
                    return `${urlStart}/contacts/search/form`;
            }
        case 'task':
            switch (type) {
                case 'form':
                    return `${urlStart}/task/form`;
                default:
                    return `${urlStart}/tasks/search/form`;
            }
        case 'quote':
            switch (type) {
                case 'form':
                    return `${urlStart}/quote/form`;
                default:
                    return `${urlStart}/quotes/search/form`;
            }
        case 'product':
            switch (type) {
                case 'form':
                    return `${urlStart}/products/form?isLegacy=true`;
                default:
                    return `${urlStart}/products/search/form?isLegacy=true`;
            }
        default:
            return '';
    }
}

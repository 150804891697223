import React from 'react';
import {PolIcon} from "../../../../components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

/**
 * Sections listing
 * @param {string[]} headers
 * @param {Object[]} items
 * @param {string} items[].id
 * @param edit
 * @param remove
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export default function SectionsListing({headers, items, edit, remove}) {
    const {t} = usePolTranslation();

    return (
        <div className="table table-bordered datatable datatable-loader">
            <table className={`datatable prospect`}>
                <thead>
                <tr>
                    {
                        headers.map(label => <th key={label}>{t(label)}</th>)
                    }
                    <th className="listing-table-thead__orders-btn">
                        <p>{t('actions')}</p>
                    </th>
                </tr>
                </thead>

                <tbody>
                {
                    items && items.map((item) => (
                        <tr key={item.id}>
                            {
                                headers.map(key => <td key={key}>{item[key]}</td>)
                            }
                            <td>
                                <ul className="datatable__actions">
                                    <li className="datatable__actions__item" onClick={() => edit(item.id)}>
                                        <span className="datatable__actions__item__icon">
                                            <PolIcon icon="edit"/>
                                        </span>
                                    </li>
                                    <li className="datatable__actions__item" onClick={() => remove(item.id)}>
                                        <span className="datatable__actions__item__icon">
                                            <PolIcon icon="trash"/>
                                        </span>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <div className={'row align-items-center justify-content-end'}>
                <div className={`col-12 col-md-auto form__submit`}>
                    <button className={'btn btn-primary form__submit__btn'} onClick={() => edit(0)}>
                        {t('add_section')}
                    </button>
                </div>
            </div>
        </div>
    )
}

import toCamelCase from "v4/utils/toCamelCase";
import getCleanViewOrdersFields from "v4/pages/admin/ViewOrders/contexts/utils/getCleanViewOrdersFields";

export default function getCleanViewOrdersGroups(entity, viewOrders, isSearch = false) {
    if (!viewOrders) return [];

    const viewOrdersByType = viewOrders.reduce((acc, {type, ...viewOrder}) => {
        if (!type || (isSearch && (['contact', 'mail_type', 'prospect', 'quote', 'task', 'unit_mail', 'product', 'search'].includes(type)))) {
            type = toCamelCase(entity);
        } else {
            type = toCamelCase(type);
        }

        if (!acc[type]) acc[type] = [];

        acc[type].push(viewOrder);

        return acc;
    }, {});

    return Object.entries(viewOrdersByType).reduce((acc, [type, groups]) => {
        acc[type] = groups.map(({id, label, width, fields, isRequired, isDefault}, index) => ({
            id,
            label,
            width,
            index,
            fields: getCleanViewOrdersFields(fields),
            ...(isRequired && {isRequired}),
            ...(isDefault && {isDefault}),
        }))

        return acc;
    }, {})
}

import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {useFrontContext} from "v4/contexts/FrontContext";

export default function useHashPulse(isListingPage) {
    const {clearClickedId} = useFrontContext();
    const {location: {pathname, hash}} = useHistory();

    useEffect(() => {
        if (isListingPage && hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                const {top, bottom} = element.getBoundingClientRect();
                if (top < 0 || bottom > window.innerHeight) {
                    element.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
                pulseElement(element);
            }
            window.history.replaceState({}, document.title, pathname);
            clearClickedId();
        }
    }, [isListingPage, pathname, hash, clearClickedId]);
}

function pulseElement(element) {
    // Adding timeout to add smooth effect
    setTimeout(() => {
        element.classList.add('pulse-bg');

        // Removing pulse effect after 1.5s (time of animation)
        setTimeout(() => {
            element.classList.remove('pulse-bg');
        }, 1500);
    }, 500)
}

export const PROSPECT = 'prospect';
export const CONTACT = 'contact';
export const TASK = 'task';
export const QUOTE = 'quote';
export const PRODUCT = 'product';
export const MAIL_TYPE = 'mail-type';

export const ENTITIES = [
    PROSPECT,
    CONTACT,
    TASK,
    QUOTE,
    PRODUCT,
    MAIL_TYPE,
]

import React, {Component} from "react";
import Form from "../../Form/form";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "../../PolIcon/policon";

const latId = 'geoloc_lat';
const lngId = 'geoloc_lng';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInstructionsDisplay: false,
            distance: 0,
            data: {},
            group: geolocGroup,
            inputs: geolocInputs,
            distanceIsChanged: false
        };
    }

    componentDidMount() {
        const {lat, lng, isDistanceDisplay} = this.props;
        const {group, inputs} = this.state;

        const indexLat = inputs.findIndex(item => item.id === latId);
        const indexLng = inputs.findIndex(item => item.id === lngId);
        const indexDistance = inputs.findIndex((item) => item.fieldKey === 'distance');

        if (isDistanceDisplay) {
            inputs[indexLat].input.value = lat;
            inputs[indexLng].input.value = lng;
            inputs[indexDistance].input.value = 1;
        }

        this.setState({
            data: {
                ...group,
                fields: inputs
            }
        });
    }

    updateDistance = (e) => {
        e.preventDefault();

        let {distance, data, distanceIsChanged} = this.state;
        const {setLoading, setSearchDistance} = this.props;

        let input = document.getElementById('distance');
        let lastValue = input.value;
        input.value = distance;
        let event = new Event('change', {bubbles: true});

        event.simulated = true;

        let tracker = input._valueTracker;
        if (tracker) {
            tracker.setValue(lastValue);
        }
        input.dispatchEvent(event);

        data.fields[data.fields.findIndex((item) => item.fieldKey === 'distance')].input.value = distance;

        this.setState({data: data, distanceIsChanged: !distanceIsChanged});

        setSearchDistance(distance);
        setLoading(true);
        return false;
    };

    render() {
        const {t, info, isLoading, isDistanceDisplay} = this.props;
        const {isInstructionsDisplay, data, distance, distanceIsChanged} = this.state;
        const classDesc = isInstructionsDisplay ? 'open' : 'close';
        const iconDesc = isInstructionsDisplay ? 'times-circle' : 'search';

        return (
            <>
                {!isLoading && isDistanceDisplay &&
                <div className={'distance'}>
                    <form className={'distance__title'} onSubmit={(e) => this.updateDistance(e)}>
                        {t('recherche_distance')}
                        <input id={'distance'} type={'number'} className={'distance__input'}
                               value={distance}
                               onChange={(e) => this.setState({
                                   distance: e.target.value >= 0 ? e.target.value : 0
                               })}/>
                        Km
                        <button className={'distance__btn'} type={'submit'}>Ok</button>
                    </form>
                </div>
                }
                <div className={`mapspage__popin search ${classDesc}`}>
                    <div className={''}>
                        <div className={'title'}>
                            <span className={'popin-toggle'}> {t('recherche_criteres')} :</span>
                            <div className={'close'} onClick={() => this.setState({
                                isInstructionsDisplay: !isInstructionsDisplay
                            })}>
                                <PolIcon icon={iconDesc} className={'close__icon'}/>
                            </div>
                        </div>

                        <div className={'popin-toggle'}>
                            <Form info={info}
                                  type={'form_search'}
                                  supGroup={data}
                                  distanceIsChanged={distanceIsChanged}
                                  extendedSearchForm={true}
                                  isMapSearch={!isDistanceDisplay}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

let geolocGroup = {
    id: "geoloc",
    viewOrderType: "search",
    groupe: true,
    width: 100,
    order: "0",
    viewOrder: "order_sfi_prospects",
    visible: true,
    isHidden: true,
    fields: []
};

let geolocInputs = [
    {
        id: latId,
        viewOrderType: "search",
        groupe: false,
        displayWidth: "25",
        displayLabel: "Latitude",
        ordre: "1",
        fieldKey: "lat",
        value: '',
        input: {
            type: "text",
            title: "Latitude",
            propertyOrder: 1,
            value: null,
            required: [],
            parentElement: false,
            keyItem: "lat",
            originalValue: null,
            visible: true,
        }
    },
    {
        id: lngId,
        viewOrderType: "search",
        groupe: false,
        displayWidth: "25",
        displayLabel: "Longitude",
        ordre: "2",
        fieldKey: "lng",
        value: '',
        input: {
            type: "text",
            title: "Longitude",
            propertyOrder: 1,
            value: null,
            required: [],
            parentElement: false,
            keyItem: "lng",
            originalValue: null,
            visible: true,
        }
    },
    {
        id: "geoloc_distance",
        viewOrderType: "search",
        groupe: false,
        displayWidth: "50",
        displayLabel: "Distance",
        ordre: "3",
        fieldKey: "distance",
        value: 1,
        input: {
            type: "text",
            title: "Distance",
            propertyOrder: 1,
            value: null,
            required: [],
            parentElement: false,
            keyItem: "distance",
            originalValue: null,
            visible: true,
        }
    }
];

export default withPolTranslation(Search);

import React from 'react';
import 'v4/components/ui/PolTable/components/PolTableTd/components/PolTableTdRowActions/PolTableTdRowActions.scss'
import ConfiguredActions from "v4/features/front/configuredActions/components/ConfiguredActions/ConfiguredActions";

export default function PolTableTdRowActions({rowActions, rowMetadata}) {
    const {id, name, prospectId} = rowMetadata;

    return (
        <div className="row-actions">
            <ConfiguredActions actions={rowActions}
                               classNamePrefix="row-actions"
                               entityId={id}
                               popupTitle={name}
                               prospectId={prospectId}/>
        </div>
    )
}

import React, {useEffect, useState} from "react";
import DocumentsFile from "v4/components/form/ShowField/components/DocumentFile/DocumentFile";
import 'v4/components/form/ShowField/components/DocumentsList/DocumentsList.scss';

export default function DocumentsList({filesFromServer, whenDeleteFile}) {
    const [files, setFiles] = useState(filesFromServer);

    useEffect(() => {
        if (JSON.stringify(filesFromServer) === JSON.stringify(files)) return;

        setFiles(filesFromServer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesFromServer])

    const handleRemoveFiles = (file) => {
        files.forEach(({id}, index) => {
            if (id === file.id) {
                files.splice(index, 1);
                setFiles([...files]);
            }
        })
        whenDeleteFile && whenDeleteFile(files, file);
    }

    return (
        <div className={`documents-list multiple'`}>
            {files && files.map((file, index) => (
                <DocumentsFile key={file.fileName ?? index} file={file} handleRemoveFiles={handleRemoveFiles}/>
            ))}
        </div>
    )
}

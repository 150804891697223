import React, {useContext, useEffect, useState} from 'react';
import {usePolTranslation, withPolTranslation} from "v4/hooks/usePolTranslation";
import UserService from "api/user/user";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {
    TAB_FORM,
    TAB_FORM_EDIT,
    TAB_ITEM,
    TABS
} from "v4/data/apiRoutes";
import TabsListing from "views/Administration/pages/_partial/TabsListing";
import {Redirect} from "react-router-dom";
import TabsForm from "views/Administration/pages/_partial/TabsForm";
import PopupInfoConfirm from "components/Popup/_popup_info_confirm";
import {setPopup} from "actions/content";
import {connect} from "react-redux";
import Loading from "components/Loading/Loading";
import {AdminContext} from "v4/contexts/AdminContext";

/**
 * Tabs component with TabsListing and TabsForm
 * @param match
 * @param setPopup
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
function Tabs({match, setPopup}) {
    const [{data, isError}, hookFetchGet] = useFetch();
    const {asCustomer} = useContext(AdminContext)

    const [redirect, setRedirect] = useState(null);
    const [{isLoading}, fetchHookDelete] = useFetch();
    const {t} = usePolTranslation();

    const tabId = match && match.params ? match.params.id : null;
    const COL_ID = "ID";
    const COL_NAME = "Nom";
    const COL_TYPE = "A partir de";
    const COL_SEARCH = "Recherche";
    const COL_GROUP_LABEL = "Libellé du groupe";
    const COL_IS_DISABLED_IN_SUBLIST = "Masquer en sous-liste";
    const COL_POSITION = "Position";
    const COL_ROLE = "Role";
    const items = {
        head: [COL_ID, COL_NAME, COL_TYPE, COL_SEARCH, COL_GROUP_LABEL, COL_IS_DISABLED_IN_SUBLIST, COL_POSITION, COL_ROLE],
        body: []
    };
    let tabs = !isError && (data?.['hydra:member'] ?? data);

    useEffect(() => {
        if (asCustomer?.id) {
            hookFetchGet({
                url: generateUrl(TABS, {customerId: asCustomer.id}),
            })
        }
    }, [asCustomer, hookFetchGet]);

    useEffect(() => {
        if (null !== tabs && tabs.length) {
            UserService.setTabs(tabs); // I take advantage of the fetch response to force the update of the tabs in local storage
        }
    }, [tabs]);

    if (null !== tabs && tabs.length) {
        tabs.map((tab, key) => {
            let item = {};
            item.key = key;
            item.id = tab.id;
            item[COL_ID] = tab.id;
            item[COL_NAME] = tab.name;
            item[COL_TYPE] = t(tab.searchType) ?? tab.searchType;
            item[COL_SEARCH] = tab.title;
            item[COL_GROUP_LABEL] = tab.groupLabel;
            item[COL_IS_DISABLED_IN_SUBLIST] = t(true === tab.isDisabledDisplaySublist ? 'yes' : 'no');
            item[COL_POSITION] = tab.position;
            item[COL_ROLE] = tab.role;
            items.body.push(item);

            return tab;
        })
    }

    /**
     * Go to form for tab
     * @param {string} id
     */
    const editTab = (id) => {
        setRedirect(
            <Redirect to={`/administration/onglets/${id}`}/>
        )
    }

    /**
     * remove tab
     * @param {string} id
     */
    const removeTab = (id) => {
        setPopup({
            isOpen: true,
            target: 'delete_item',
            content: (
                <PopupInfoConfirm
                    message={t('popup_validation_remove_tab')}
                    onAccept={() => {
                        fetchHookDelete({
                            url: generateUrl(TAB_ITEM,{id}),
                            config: {
                                method: 'DELETE'
                            }
                        });
                        UserService.removeTab(id);

                        setPopup({});
                    }}
                    onDecline={() => {
                        setPopup({})
                    }}
                />
            )
        });
    }

    return (
        <>
            {redirect}

            {null === tabs || isLoading ?
                <Loading message={t("loading")} specificClass={"form-loader"}/>
                :
                <>
                    {tabId ?
                        <TabsForm
                            path={
                                '0' === tabId
                                    ? generateUrl(TAB_FORM, {customerId: asCustomer?.id})
                                    : generateUrl(TAB_FORM_EDIT, {id: tabId, customerId: asCustomer?.id})
                            }
                            goBack={() => editTab('')}
                            pathSubmit={'0' === tabId ? generateUrl(TABS) : generateUrl(TAB_ITEM, {id: tabId})}
                            submitMethod='POST'
                            tabId={tabId}
                            customerId={asCustomer?.id}
                        />
                        :
                        <TabsListing headers={items.head} items={items.body} edit={editTab} remove={removeTab}/>
                    }
                </>
            }
        </>
    )
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => {
    return {
        setPopup: (popupInfo) => dispatch(setPopup(popupInfo)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(Tabs));

import UserService from "api/user/user";
import React, {useMemo} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {getFlatRoutes, routesBase} from "v4/data/appRoutes";
import {Error404} from "views/Error/404";

export default function PolRouter({isExternal}) {
    const groupedRoutes = useMemo(() => {
        const routes = getFlatRoutes().reduce((acc, route) => {
            // group routes by groupCode
            if (route.groupCode) {
                const index = acc.findIndex(item => item.groupCode === route.groupCode);
                if (index === -1) {
                    acc.push({
                        groupCode: route.groupCode,
                        path: new Set(route.path),
                        component: route.component,
                    });
                } else {
                    route.path.forEach(path => acc[index].path.add(path));
                }
            } else {
                acc.push(route);
            }

            return acc;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return routes.map(route => {
            if (route.groupCode) {
                return {
                    ...route,
                    path: Array.from(route.path),
                }
            }

            return route;
        })
    }, []);

    // Redirect to login page if user is not logged in
    if (!isExternal && !UserService.getUser()) return <Redirect to={routesBase.login}/>

    return (
        <Switch>
            {groupedRoutes.map(route => {
                return (
                    route.subMenu?.length > 0
                        ? (
                            route.subMenu.map(subMenu => {
                                return (
                                    <Route key={subMenu.id}
                                           exact
                                           path={subMenu.path}
                                           component={subMenu.component}/>
                                )
                            })
                        )
                        : <Route key={route.groupCode ?? route.id}
                                 exact
                                 path={route.path}
                                 component={route.component}/>
                )
            })}
            <Route component={Error404}/>
        </Switch>
    )
}

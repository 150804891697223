import {useWatch} from "react-hook-form";

export default function useConditionalField(conditionField, conditionFieldValues) {
    const conditionFieldValue = useWatch({
        name: conditionField,
        disabled: !conditionField
    });

    return conditionField && conditionFieldValue && ![conditionFieldValues].flat().includes(conditionFieldValue)
}

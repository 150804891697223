import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import ReactTooltip from "react-tooltip";

export default function ProspectTabEditButton({showId}) {
    const {t} = usePolTranslation();
    const {currentTabName, prospectId} = useProspectPageContext();

    return (
        <>
            <Link to={`${routesBase.baseProspect}/${prospectId}/${currentTabName}/edit/${showId}`}
                  className="prospect-tab__prospect__header__action"
                  data-for="link-edit"
                  data-tip={t('edit')}>
                <PolIcon icon='edit'/>
            </Link>
            <ReactTooltip id="link-edit" effect="solid"/>
        </>
    )
}

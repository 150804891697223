import {captureException} from "@sentry/react";
import React, {useEffect, useLayoutEffect} from 'react';
import {createPortal} from "react-dom";
import {FormProvider, useFieldArray, useForm, useFormContext} from "react-hook-form";
import {PRODUCT_SEARCH} from "v4/data/apiRoutes";
import QuotesProductsPopup
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsPopup/QuotesProductsPopup";
import QuotesProductsPricesInfos
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsPricesInfos/QuotesProductsPricesInfos";
import QuotesProductsTable
    from "v4/features/front/products/components/QuotesProductsDetails/components/QuotesProductsTable/QuotesProductsTable";
import QuotesProductsDetailsProvider
    from "v4/features/front/products/components/QuotesProductsDetails/contexts/QuotesProductsDetailsContext/QuotesProductsDetailsContext";

import 'v4/features/front/products/components/QuotesProductsDetails/QuotesProductsDetails.scss';
import useToggle from "v4/hooks/useToggle";
import {useQuoteTabContext} from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/contexts/QuoteTabContext";
import {generateUrl} from "v4/services/api.service";

export default function QuotesProductsDetails({isShow = false, defaultValues, properties}) {
    const [showPopup, togglePopup] = useToggle();

    const addProductForm = useForm({
        mode: 'onChange',
        defaultValues: {
            search: '',
            quantity: 1,
        }
    });

    const quoteLinesForm = useFormContext() ?? useForm({
        mode: 'onChange',
        defaultValues: {
            quoteLines: defaultValues ?? [],
        }
    });
    const {setQuoteLinesMethods, quoteLinesMethods: searchQuoteLinesMethods} = useQuoteTabContext();
    const quoteLinesMethods = useFieldArray({control: quoteLinesForm.control, name: 'quoteLines'});

    useEffect(() => {
        if (searchQuoteLinesMethods === null) {
            setQuoteLinesMethods(quoteLinesMethods);
        }

        return () => setQuoteLinesMethods(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addLine = async (values, isAdvancedValue = false) => {
        if (isAdvancedValue) {
            const defaultValues = {
                isOptional: values?.isOptional ?? false,
                name: values?.name ?? '',
                comment: values?.comment ?? '',
                description: values?.description ?? '',
                unitPriceExclVat: values?.unitPriceExclVat ?? 0,
                quantity: values?.quantity ?? 1,
                discountValue: values?.discountValue ?? '',
                discountType: values?.discountType ?? 'amount',
                sectionName: values?.sectionName ?? '',
                vatRate: values?.vatRate ?? properties.vatRate?.choices?.find(({label}) => label === values?.quoteLineInfo?.product?.vat?.value)?.value ?? '20.00',
                quoteLineInfo: {
                    ...values?.quoteLineInfo,
                },
            };

            return quoteLinesMethods.append(defaultValues);
        }

        const product = values.search && await getProduct(values.search);

        quoteLinesMethods.append({
            isOptional: values?.isOptional ?? false,
            name: product?.name ?? '',
            comment: product?.shortDescription ?? '',
            description: product?.description ?? '',
            unitPriceExclVat: product?.sellingPriceExcludingVat ?? 0,
            quantity: values?.quantity ?? 1,
            discountValue: '',
            discountType: 'amount',
            sectionName: values?.sectionName ?? '',
            vatRate: values?.vatRate ?? properties.vatRate?.choices?.find(({label}) => label === product?.vat?.value)?.value ?? '20.00',
            quoteLineInfo: {
                product,
            },
        });
    }

    useLayoutEffect(() => {
        document.body.style.overflow = showPopup ? 'hidden' : '';
    }, [showPopup]);

    return (
        <QuotesProductsDetailsProvider quoteLinesMethods={quoteLinesMethods} properties={properties}>
            <FormProvider {...quoteLinesForm}>
                <div className="quotes-products__wrapper">
                    <QuotesProductsTable addLine={addLine}
                                         togglePopup={togglePopup}
                                         isQuoteFetchLoading={false}
                                         isShow={isShow}/>
                    {quoteLinesMethods.fields.length > 0 &&
                        <QuotesProductsPricesInfos isShow={isShow}
                                                   vatChoices={properties.vatRate?.choices ?? []}/>
                    }
                </div>
            </FormProvider>
            <FormProvider {...addProductForm}>
                {createPortal(
                    <div className={`quotes-products__popup__background ${showPopup ? 'active' : ''}`}>
                        <QuotesProductsPopup togglePopup={togglePopup} addLine={addLine}/>
                    </div>,
                    document.body
                )}
            </FormProvider>

        </QuotesProductsDetailsProvider>
    )
}

async function getProduct(id) {
    try {
        const fetchProduct = await fetch(generateUrl(PRODUCT_SEARCH, {disableTransformation: 1}), {
            method: 'POST',
            body: JSON.stringify({
                id: [id]
            })
        })
        const result = await fetchProduct.json();

        return result['hydra:member']?.[0];
    } catch (e) {
        captureException(e, {
            extra: {
                id,
                message: 'Error while fetching product when adding a line in quote',
            }
        })
    }
}

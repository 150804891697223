import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import CustomReactSelect from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useAdminSavedRequest
    from "v4/layouts/ListingEntityLayout/components/ListingEntityFilters/components/AsideFilters/components/AsideFiltersForm/components/AdminSavedSearch/hooks/useAdminSavedRequest";

export default function AdminSavedRequest({entity}) {
    const {t} = usePolTranslation();
    const {
        options,
        onSelectChange,
        onAddSavedRequest,
        onDeleteSavedRequest,
        selectedSavedRequest,
        isFormDirty,
        name,
        handleNameChange,
    } = useAdminSavedRequest(entity);

    return (
        <div className="admin-saved-request">
            <h2>{t('saved_requests')}</h2>
            <div className="admin-saved-request__inputs">
                <p>{t('my_saved_requests')}</p>
                <div>
                    <CustomReactSelect value={selectedSavedRequest}
                                       options={options}
                                       onChange={onSelectChange}/>
                    <button disabled={selectedSavedRequest === ''}
                            onClick={onDeleteSavedRequest}>
                        <PolIcon icon='bin'/>
                    </button>
                </div>
                <p>{t('name_a_request')}</p>
                <div>
                    <input value={name}
                           onChange={handleNameChange}/>
                    <button disabled={name === '' || !isFormDirty}
                            onClick={onAddSavedRequest}>
                        <PolIcon icon='validation'/>
                        {t('save_search')}
                    </button>
                </div>
            </div>
        </div>
    )
}

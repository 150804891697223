import React, {useState} from 'react'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {connect} from "react-redux";
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {Prompt, Redirect} from "react-router-dom";
import {getLSItem, setLSItem} from "v4/services/localStorage.service";
import {PolIcon} from "../../PolIcon/policon";
import {adminFetchHandler} from "../../../api/datas/datas";
import {PostService} from "../../../api/datas/post";
import {useRecoilValue} from "recoil";
import {customerIdSelector} from "../atoms/customer";
import {randomId} from "v4/utils";

const FormListing = ({data, postService, t, initInputs, form}) => {
    let [inputs, setInputs] = useState([]);
    const [redirect, setRedirect] = useState(null);
    const [preFilled, setPreFilled] = useState(false);
    const customerId = useRecoilValue(customerIdSelector);
    const [isEdited, setIsEdited] = useState(false);

    const handleLeaving = () => {
        setIsEdited(false);
        return t("customer_confirm_leave_form_alert");
    }

    const handleChange = (key, group = false, value, oldValue) => {
        setIsEdited(true);

        if (value !== oldValue) {
            if (group) {
                inputs[group].value[key].value = value;
            } else {
                inputs[key].value = value;
            }
        }

        if (inputs[key]) {
            inputs[key].error = null;
        }

        setInputs([...inputs])
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let listingItems = [];
        let cancel = false;
        inputs[1].value.forEach((item, index) => {
            if (item && item.value.length) {
                listingItems.push({
                    id: item.id,
                    name: item.value,
                    position: index + 1
                })
            } else {
                alert(t('one_element_empty'));
                cancel = true;
            }
        });

        if (cancel) {
            return;
        }
        setIsEdited(false);
        let dataFetch = {
            "name": inputs[0].value,
            "code": data.code ? data.code : inputs[0].value.replace(' ', '-'),
            "isActive": data.isActive ? data.isActive : true,
            "listingItems": listingItems,
        };

        let infoSubmit = {
            url: `/api/listing`,
            submittedData: dataFetch,
            loader: 'form-listing',
            callback: () => {
                setRedirect(<Redirect to={`/administration/gestion_listes/`}/>)
            }
        };

        if (data && data.id) {
            dataFetch.id = data.id;
            dataFetch.customerId = data.customerId;
            infoSubmit = {
                ...infoSubmit,
                putUrl: `/api/listing`,
            }
        } else {
            dataFetch.customerId = customerId
        }

        postService(infoSubmit, t)

        const code = data.code ?? '';
        if (code === 'QuoteSection') {
            const user = getLSItem('user');
            const sections = listingItems.map(({id, name}) => ({id: id, label: name}));
            user.defaultQuoteSections['hydra:member'] = sections;
            user.defaultQuoteSections['hydra:totalItems'] = sections.length;
            setLSItem('user', user);
        }

        return false;
    };

    if (!inputs.length && initInputs.length) {
        initInputs.forEach(input => {
            input.value = input.type === 'array' ? [] : '';
        });
        inputs = initInputs
        setInputs(initInputs)
    }

    if (data && data.id && !preFilled) {

        inputs.forEach(input => {
            if (input.type !== 'array') {
                input.value = data[input.id];
            } else if (data[input.id]['hydra:member']) {
                data[input.id]['hydra:member'].forEach(item => {
                    input.value.push({
                        id: item.id,
                        value: item.name
                    });
                });
            }
        });
        setPreFilled(true);
        setInputs([...inputs])

    }


    if (form && form.errors) {
        const errors = form.errors;

        inputs.forEach(input => {
                const errorField = Object.keys(errors).find((error) => error === input.id);
                if (errorField) {
                    input.error = errors[errorField];
                }
            }
        );

        setInputs([...inputs])
        delete form.errors;
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);

        result.splice(endIndex, 0, removed);
        return result;
    }

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const [name, listingItems] = inputs;

        const items = reorder(
            listingItems.value,
            result.source.index,
            result.destination.index
        );

        setInputs([name, {...listingItems, value: items}])
    }

    return (
        <>
            {redirect}

            <form className={`form form__scrollable`} onSubmit={(event) => handleSubmit(event)}>
                <Prompt message={handleLeaving} when={isEdited}/>
                {inputs.map((input, key) => {
                    let fieldClass = input && input.value ? 'field-written' : input && input.error ? 'field-error' : '';
                    return (
                        <div className={`col-12 col-md-3 field ${fieldClass}`} key={key}>
                            {input.type !== 'array' ? (
                                <>
                                    {input.error ? (
                                        <div className={'form__error'}
                                             onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                             onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}
                                        >
                                            <PolIcon icon={'exclamation-circle'}
                                                             className={'form__error__icon'}/>
                                            <p className={'form__error__content'}>{input.error}</p>
                                        </div>
                                    ) : null}
                                    <input name={input.id} className={'field__input'} type={input.type}
                                           id={input.id}
                                           value={input.value ? input.value : ""}
                                           onChange={(event) => handleChange(key, false, event.target.value, input.value)}/>

                                    <label className={'field__label field__label__admin'}
                                           htmlFor={input.id}>{t(input.label)}</label>
                                    <span className={'field__border'}> </span>
                                </>
                            ) : (
                                <div key={key}>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="form-listing-admin">
                                                {(provided, snapshot) => (
                                                    <ul className={`field__list ${fieldClass}`}
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}>
                                                        {input.error ? (
                                                            <div className={'form__error'}
                                                                 onMouseOver={(event) => event.currentTarget.classList.add('error-hover')}
                                                                 onMouseLeave={(event) => event.currentTarget.classList.remove('error-hover')}>
                                                                <PolIcon icon={'exclamation-circle'}
                                                                         className={'form__error__icon'}/>
                                                                <p className={'form__error__content'}>{input.error}</p>
                                                            </div>
                                                        ) : null}
                                                        {input.value.map((item, keyItem) => {
                                                            return (
                                                                <Draggable key={item.id ?? item.newId} draggableId={item.id ?? item.newId}
                                                                           index={keyItem}>
                                                                    {(provided, snapshot) => (
                                                                        <li className={'field__item'}
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}>
                                                                            <input name={item.id}
                                                                                   className={'field__input'}
                                                                                   type={'text'}
                                                                                   id={item.id}
                                                                                   value={item.value ? item.value : ""}
                                                                                   onChange={(event) => handleChange(keyItem, key, event.target.value, item.value)}/>
                                                                            <span className={'field__item__delete'}
                                                                                  onClick={() => {
                                                                                      inputs[key].value.splice(keyItem, 1);
                                                                                      setInputs([...inputs])
                                                                                  }}>
                                                                                    <PolIcon icon={'trash'}/>
                                                                                </span>
                                                                            <span
                                                                                className="field__item__position">{keyItem + 1}</span>
                                                                        </li>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        })}
                                                        {provided.placeholder}
                                                    </ul>
                                                )}
                                            </Droppable>
                                    </DragDropContext>
                                    <p className={'field__list__add'} onClick={() => {
                                        inputs[key].value.push({
                                            newId: randomId(),
                                            value: ''
                                        });
                                        setInputs([...inputs])
                                    }}>
                                        {t('ajouter_champ')}
                                    </p>
                                </div>
                            )}
                        </div>
                    )
                })}

                <div className={'row align-items-center justify-content-end'}>
                    <div className={'col-12 col-md-auto form__back'}
                         onClick={() => setRedirect(<Redirect to={`/administration/gestion_listes/`}/>)}>
                        <PolIcon icon={'chevron-circle-left'} className={'form__back__icon'}/>
                        {t('retour')}
                    </div>
                    <div className={`col-12 col-md-auto form__submit`}>
                        <button className={'btn btn-primary form__submit__btn'} onClick={() => handleSubmit}>
                            {t('enregistrer')}
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = ({form}) => ({
    form
});
const mapDispatchToProps = dispatch => {
    return {
        postService: (info, t) => dispatch(PostService(info, t)),
        adminFetchHandler: (error, info) => dispatch(adminFetchHandler(error, info))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPolTranslation(React.memo(FormListing)));

import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import ReactTooltip from "react-tooltip";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import Confirm from "v4/components/ui/Confirm/Confirm";

export default function ViewOrdersRemoveButton({group, field}) {
    const {t} = usePolTranslation();
    const {removeElement} = useViewOrdersContext();
    const [isConfirmOpen, toggleIsConfirmOpen] = useToggle();

    const remove = () => removeElement(group.id, field?.id);

    return (
        <>
            <button className="vo-action__button vo-action__hide__button"
                    data-for="hide-tooltip"
                    data-tip={t('remove_item')}
                    onClick={(field || group.fields.length === 0) ? remove : toggleIsConfirmOpen}>
                <PolIcon icon="eye-slash"/>
            </button>
            <ReactTooltip id="hide-tooltip" effect="solid"/>
            {(!field && group.fields.length > 0) && (
                <Confirm isOpen={isConfirmOpen}
                         title={t('remove_item')}
                         cancelAction={toggleIsConfirmOpen}
                         confirmAction={remove}>
                    <p>{t('confirm_remove_group')}</p>
                </Confirm>
            )}
        </>
    )
}

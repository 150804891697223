import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import useViewOrdersButtonListHover
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/hooks/useViewOrdersButtonListHover";
import ViewOrdersButtonListHover
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/partials/ViewOrdersButtonListHover";
import './ViewOrdersConfigButton.scss';
import ViewOrdersConfigButtonListItem
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/ViewOrdersConfigButton/components/ViewOrdersConfigButtonListItem/ViewOrdersConfigButtonListItem";
import {
    useViewOrdersContext,
    VIEW_ORDERS_CONDITIONAL_FIELD,
    VIEW_ORDERS_CONFIGS,
    VIEW_ORDERS_IS_REQUIRED
} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import ReactTooltip from "react-tooltip";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ViewOrdersConfigButton({group, field}) {
    const {t} = usePolTranslation();
    const {setFieldConfig, currentViewOrder} = useViewOrdersContext();

    const allowedConfigsFilter = ({allowedTypes, allowedEntities, allowedVOTypes, removeIfFieldIsRequired}) => {
        let isAllowed = true; // By default, all the configs are allowed

        if (allowedTypes && !allowedTypes.includes(field.type)) isAllowed = false; // Check if the field type is allowed

        if (allowedEntities && !allowedEntities.includes(currentViewOrder.entity)) isAllowed = false; // Check if the entity is allowed

        if (allowedVOTypes && !allowedVOTypes.includes(currentViewOrder.viewOrderType)) isAllowed = false; // Check if the view order type is allowed (search, form, ...)

        if (field.isRequired && removeIfFieldIsRequired) isAllowed = false; // Check if the field is required

        return isAllowed;
    }

    const allowedConfigs = VIEW_ORDERS_CONFIGS.filter(allowedConfigsFilter);
    const checkedConfigs = allowedConfigs.filter(config => field[config.type]);
    const disabledConfigs = allowedConfigs.filter(config => field.isRequiredNotRemovable && config.type === VIEW_ORDERS_IS_REQUIRED);

    const {
        isActionOpen,
        toggleIsActionOpen,
        shouldOpenTop,
        containerRef,
    } = useViewOrdersButtonListHover(allowedConfigs);
    const handleClick = (config) => {
        if (field.isRequiredNotRemovable && config.value === VIEW_ORDERS_IS_REQUIRED) return;
        const value = !field[config.type];

        const finalConfig = {...config};
        if (config.type === VIEW_ORDERS_CONDITIONAL_FIELD
            || (
                config.type === VIEW_ORDERS_IS_REQUIRED
                && allowedConfigs.find(({type}) => type === VIEW_ORDERS_CONDITIONAL_FIELD)?.removeIfFieldIsRequired
                && value === true // If the field has conditional field config and the field is required, we remove the value of the conditional field config
            )
        ) {
            finalConfig.conditionField = null;
            finalConfig.conditionValue = null;
        }

        if (config.type !== VIEW_ORDERS_CONDITIONAL_FIELD) {
            finalConfig.value = value;
        }

        setFieldConfig(group.id, field.id, finalConfig);
    }

    return (
        <div className="vo-action__list-hover__container vo-action__config__button__container"
             ref={containerRef}
             onMouseLeave={() => toggleIsActionOpen(false)}>
            <button className="vo-action__button vo-action__config__button"
                    data-for="config-tooltip"
                    data-tip={t('pick_field_format')}
                    onClick={toggleIsActionOpen}>
                <PolIcon icon="cog"/>
            </button>
            <ViewOrdersButtonListHover isActionOpen={isActionOpen}
                                       shouldOpenTop={shouldOpenTop}
                                       list={allowedConfigs}
                                       currentItems={checkedConfigs}
                                       disabledItems={disabledConfigs}
                                       renderItem={(config, isActive) => (
                                           <ViewOrdersConfigButtonListItem
                                               group={group}
                                               field={field}
                                               config={config}
                                               isActive={isActive}/>
                                       )}
                                       onClick={handleClick}/>
            <ReactTooltip id="config-tooltip" effect="solid"/>
        </div>
    )
}

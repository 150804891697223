import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect, useState} from "react";
import {QUOTE_PRODUCTS_FILES} from "v4/data/apiRoutes";
import 'v4/features/front/generatePdf/components/ImportProductsDocsButtons/ImportProductsDocsButtons.scss';
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";

export default function ImportProductsDocsButtons({quoteId, filePondInstance}) {
    const {t} = usePolTranslation()
    const [{data, isLoading, isFinished, isError}, fetchDocuments] = useFetch()
    const [isProcessingFiles, setIsProcessingFiles] = useState(false)
    const {addErrorNotification} = useNotification()
    const [processedFiles, setProcessedFiles] = useState(0)

    const handleImportProductsFiles = () => {
        setProcessedFiles(0)
        fetchDocuments({
            url: generateUrl(QUOTE_PRODUCTS_FILES, {id: quoteId}),
        })
    }

    const processFile = (file) => {
        fetch(file.source)
            .then(res => res.blob())
            .then(res => {
                    const loadedFile = new File(
                        [res],
                        file.name,
                        {type: file.type},
                    )

                    filePondInstance.addFile(loadedFile)
                    setProcessedFiles(c => c + 1)
                }
            )
    }

    useEffect(() => {
        if (isLoading) {
            setIsProcessingFiles(true)
        }

        if (!isLoading) {
            setIsProcessingFiles(false)
        }

        if (data && isFinished && !isError) {
            if (data?.files?.length) {
                setIsProcessingFiles(true)
                data.files.forEach(processFile)
            }
        }

        if (isError) {
            setIsProcessingFiles(false)
            addErrorNotification(t('error_occured_while_loading_products_files'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isLoading, isFinished, isError])

    useEffect(() => {
        if (data?.files?.length && isFinished && !isError && processedFiles >= data.files.length) {
            setIsProcessingFiles(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processedFiles, data, isFinished, isError])

    return (
        <button type="button"
                onClick={handleImportProductsFiles}
                disabled={isProcessingFiles}
                className="btn btn-primary import-files-button">
            {!isProcessingFiles &&
                (t('add_products_quote_files'))
            }
            {isProcessingFiles &&
                (<>{t('processing_products_files')} <PolIcon icon="circle-notch spin"/></>)
            }
        </button>
    )
}

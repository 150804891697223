import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {ENTITIES} from "v4/data/entities";
import {ids} from "v4/utils";

export default function useHandleChainedSearch() {
    const {replace, location} = useHistory();
    const [resetStateWith, setResetStateWith] = useState(null);

    const [chainedSearch] = useState(() => {
        const chainedSearchedEntities = Object.keys(location.state ?? {}).filter(key => ids(ENTITIES).includes(key));
        if (chainedSearchedEntities.length) {
            let state = {...location.state};
            const _chainedSearch = {};

            chainedSearchedEntities.forEach(entityId => {
                const {[entityId]: chainedSearchEntity, ...newState} = state;
                _chainedSearch[entityId] = chainedSearchEntity;
                state = newState;
            });

            if (state.isReverse) {
                const {isReverse, ...newState} = state;
                _chainedSearch.isReverse = isReverse;
                state = newState;
            }

            setResetStateWith(state);

            return _chainedSearch;
        }
    });

    useEffect(() => {
        if (resetStateWith) replace({state: resetStateWith});
    }, [resetStateWith, replace]);


    return chainedSearch;
}

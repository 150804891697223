import React, {Fragment} from 'react';
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

const legendsWithIcons = [
    {icon: 'eye-cancel', text: 'readOnly'},
    {icon: 'unique', text: 'unique'},
    {icon: 'mail', text: 'email'},
    {icon: 'phone-alt', text: 'phone'},
    {icon: 'euro', text: 'money'},
    {icon: 'web', text: 'website'},
    {icon: 'paragraph', text: 'html'},
    {icon: 'percent', text: 'percent'},
    {icon: 'list-check', text: 'select_field'},
    {icon: 'p-square', text: 'isParentField'},
    {icon: 'tab', text: 'extendedField'},
];

export default function ViewOrdersLegend() {
    const {t} = usePolTranslation();

    return (
        <div className="new-view-orders__content__legends">
            <PolIcon icon="question" className="new-view-orders__content__legends__icon"/>
            <div className="new-view-orders__content__legends__content">
                <h3>{t('legend')} :</h3>
                <dl>
                    <dt>
                        <b>{t('classic_name')}</b>
                    </dt>
                    <dd>
                        {t('pol_field_required')}
                    </dd>

                    <dt>
                        {t('classic_name')}*
                    </dt>
                    <dd>
                        {t('required')}
                    </dd>

                    <dt className="new-view-orders__field__label__specific">
                        {t('classic_name')}
                    </dt>
                    <dd>
                        {t('specific_field')}
                    </dd>

                    {legendsWithIcons.map(({icon, text}) => (
                        <Fragment key={`${icon}_${text}`}>
                            <dt>
                                <PolIcon icon={icon}/>
                            </dt>
                            <dd>
                                {t(text)}
                            </dd>
                        </Fragment>
                    ))}
                </dl>
            </div>
        </div>
    )
}

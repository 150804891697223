import {useCallback, useEffect, useMemo} from "react";
import useFetch from "v4/hooks/useFetch";
import {useSearchContext} from "v4/contexts/SearchContext/SearchContext";
import {generateUrl} from "v4/services/api.service";

export default function useDashboardListing(fetchUrl) {
    const {
        currentPage,
        initPagesWithTotalItems,
        sortOrder,
        sortField,
        setSortField,
        setSortOrder
    } = useSearchContext();
    const [{data, isLoading}, fetchListing] = useFetch();

    const onHeaderClick = useCallback(({currentTarget}) => {
        const {key, sort} = currentTarget.dataset;
        let currentSortOrder = sortOrder;
        if (sortField !== key) {
            setSortField(key);
            currentSortOrder = 'asc';
            [...currentTarget.closest('tr').querySelectorAll('[data-sort]')].forEach(child => {
                if (child !== currentTarget) {
                    delete child.dataset.sort;
                    child.classList.remove('active');
                }
            })
        }
        if (!sort) {
            setSortOrder(currentSortOrder);
            currentTarget.dataset.sort = currentSortOrder;
            currentTarget.classList.add('active');
        } else {
            const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
            setSortOrder(newSortOrder);
            currentTarget.dataset.sort = newSortOrder;
            currentTarget.classList.add('active');
        }
    }, [sortField, sortOrder, setSortField, setSortOrder]);

    useEffect(() => {
        fetchListing({
            url: generateUrl(fetchUrl, {
                page: currentPage,
                itemsPerPage: 10,
                sortField: sortField,
                sortOrder: sortOrder,
            })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortField, sortOrder, currentPage, fetchUrl, fetchListing]);

    useEffect(() => {
        if (data) {
            initPagesWithTotalItems(data['hydra:totalItems'], 10);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const state = useMemo(() => ({
        isLoading,
    }), [isLoading]);

    return {
        state,
        data: data?.['hydra:member'],
        totalItems: data?.['hydra:totalItems'],
        totalAmount: data?.['hydra:totalAmount'] ?? 0,
        onHeaderClick
    };
}

import React from 'react';
import Logo from "img/logo";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useMicrosoftGraphResponse from "v4/pages/front/MicrosoftGraph/components/hooks/useMicrosoftGraphResponse";
import 'v4/pages/front/MicrosoftGraph/components/MicrosoftGraphResponse.scss';
import {Link} from "react-router-dom";

export default function MicrosoftGraphResponse({fetchData}) {
    const {t} = usePolTranslation();
    const [message, isLoading] = useMicrosoftGraphResponse(fetchData);

    return (
        <div className="microsoft-graph-response">
            <Logo/>
            <p>{message}</p>
            {!isLoading && <Link to={'/'} className="btn btn-primary">{t('home')}</Link>}
        </div>
    );
}

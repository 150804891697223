export const dispatchSelectedEntitiesIds = (entityIds, entity) => {
    return {
        type: 'DISPATCH_SELECTED_ENTITIES_IDS',
        entityIds,
        entity
    }
};

export const resetChainedSearch = () => {
    return {
        type: 'RESET_CHAINED_SEARCH'
    }
};
import {captureException} from "@sentry/react";
import {useEffect, useReducer, useState} from "react";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import fetchReducer from "v4/reducers/fetchReducer";

const errorMessage = (discr, message = '') => {
    discr = discr.toString();

    switch (discr) {
        case 'POST':
            return 'fetch_post_error';
        case 'GET':
            return 'fetch_get_error';
        case '403':
            return 'rights_access_deny';
        case '404':
            return 'error_custom_action_not_found';
        case '410':
            return message;
        default:
            return message ?? 'fetch_error';
    }
};

const defaultConfig = {
    method: 'GET',
    headers: {
        Accept: 'application/ld+json',
        'Content-Type': 'application/json'
    },
    body: null,
};

/**
 * @typedef {Object} FetchState
 * @property {boolean} isLoading
 * @property {boolean} isFinished
 * @property {boolean} isError
 * @property {Object} data
 * @property {Object} error
 * @property {Object} errorData
 */

/**
 * @typedef {Object} FetchFunctionProp
 * @property {string} url
 * @property {{
 *     [method]: string,
 *     [headers]: Object,
 *     [body]: Object
 *     [signal]: AbortSignal
 *     }} [config]
 */

/**
 * @param {("json"|"blob")} type
 * @returns {[FetchState, function(FetchFunctionProp)]} [state, fetch]
 */
export default function useFetch(type = 'json') {
    if (!['json', 'blob'].includes(type)) {
        const err = new Error('Type must be json or blob');
        captureException(err);
        throw err;
    }

    const {addErrorNotification} = useNotification();
    const {t} = usePolTranslation();

    const [fetchInfos, setFetchInfos] = useState({
        url: null,
        config: {}
    });

    const [state, dispatch] = useReducer(fetchReducer, {
        data: null,
        isLoading: false,
        isError: false,
        isFinished: false,
        token: null,
        errorData: null,
    });

    const abortController = new AbortController();

    const polFetch = async ({url, config = {}}) => {
        const finalConfig = {...defaultConfig, ...config, signal: abortController.signal};

        // Si les données sont envoyées via FormData, on retire le Content-Type pour
        // laisser le navigateur gérer le multipart/form-data
        if (finalConfig.body instanceof FormData) {
            const {'Content-Type': _, ...headers} = finalConfig.headers;
            finalConfig.headers = headers;
        }

        dispatch({type: 'FETCH_INIT'});

        try {
            const rawResponse = await fetch(url, finalConfig);

            if (rawResponse.status >= 200 && rawResponse.status < 300) {
                if (finalConfig.method === 'DELETE') return dispatch({type: "FETCH_SUCCESS"});

                const data = await rawResponse[type]();
                return dispatch({type: "FETCH_SUCCESS", payload: data});
            }

            if (rawResponse.status === 400) {
                const json = await rawResponse.json();
                if (json?.message) addErrorNotification(t(json.message));
                return dispatch({type: "FETCH_FAILURE", payload: json});
            }

            if (rawResponse.status === 403) {
                addErrorNotification(t(errorMessage('403')));
                return dispatch({type: "FETCH_FAILURE"});
            }

            if ([404, 410, 422].includes(rawResponse.status)) {
                const json = await rawResponse.json();
                if (json?.message) {
                    addErrorNotification(t(errorMessage(rawResponse.status, json['message'])));
                    return dispatch({type: "FETCH_FAILURE", payload: json});
                }
            }

            addErrorNotification(t(errorMessage(finalConfig.method)));
            return dispatch({type: "FETCH_FAILURE"});
        } catch (e) {
            if (e.name === 'AbortError') return;

            addErrorNotification(t(errorMessage(finalConfig.method)));
            return dispatch({type: "FETCH_FAILURE"});
        }
    }

    useEffect(() => {
        if (fetchInfos.url) {
            polFetch(fetchInfos);
        }

        return () => abortController.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchInfos]);

    return [state, setFetchInfos];
}

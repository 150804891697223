import React, {useState} from 'react';
import {PolIcon} from "components/PolIcon/policon";
import useSetDOMInfos from "v4/pages/admin/ViewOrders/hooks/useSetDOMInfos";
import {useViewOrdersContext} from "v4/pages/admin/ViewOrders/contexts/ViewOrdersContext";
import ViewOrdersWidthButton
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/ViewOrdersWidthButton/ViewOrdersWidthButton";
import ViewOrdersRemoveButton
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/ViewOrdersRemoveButton/ViewOrdersRemoveButton";
import ViewOrdersAddGroupButton
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/ViewOrdersAddGroupButton/ViewOrdersAddGroupButton";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ReactTooltip from "react-tooltip";

export default function ViewOrdersGroup({group, className = "", children}) {
    const {t} = usePolTranslation();
    const groupRef = useSetDOMInfos(group);
    const {setGroupLabel} = useViewOrdersContext();
    const [label, setLabel] = useState(group.label ?? '');
    const handleLabelChange = (event) => {
        setLabel(event.target.value);
    }
    const handleBlur = (event) => {
        const newLabel = event.target.value;
        if (newLabel === group.label) return;

        setGroupLabel(group.id, newLabel);
    }
    const selectAll = (event) => {
        event.target.select();
    }
    const blurOnEnter = (event) => {
        if (event.key !== 'Enter') return

        event.target.blur();
    }

    return (
        <div className={`new-view-orders__group ${getBootstrapClassesForGroup(group.width)} ${className}`}
             ref={groupRef}>
            <div className="new-view-orders__group__header">
                {!group.isRequired && (
                    <PolIcon icon="draggable" className="new-view-orders__group__icon"/>
                )}
                <div className="new-view-orders__group__label">
                    <span>{label?.length ? label : t('security_group_name')}&zwj;</span>
                    <input type="text"
                           value={label}
                           autoComplete="off"
                           placeholder={t('security_group_name')}
                           onKeyUp={blurOnEnter}
                           onFocus={selectAll}
                           onChange={handleLabelChange}
                           onBlur={handleBlur}/>
                </div>
                <div className="new-view-orders__group__actions">
                    {!group.isRequired && (
                        <>
                            <ViewOrdersWidthButton group={group}/>
                            <ViewOrdersRemoveButton group={group}/>
                        </>
                    )}
                    {group.isRequired && (
                        <>
                            <PolIcon icon="eye-slash"
                                     className="new-view-orders__group__icon-not-registered"
                                     data-tip={t('not_registered_group')}
                                     data-for={`required-group-${group.id}`}/>
                            <ReactTooltip id={`required-group-${group.id}`} place="top" effect="solid"/>
                        </>
                    )}
                </div>
            </div>

            <div className="new-view-orders__group__left-actions">
                <ViewOrdersAddGroupButton group={group}/>
            </div>

            {children}

            {!group.isRequired && (
                <div className="new-view-orders__group__right-actions">
                    <ViewOrdersAddGroupButton group={group} isAfter/>
                </div>
            )}
        </div>
    );
};

function getBootstrapClassesForGroup(groupWidth) {
    switch (+groupWidth) {
        case 25:
            return 'vo-col-3';
        case 33:
            return 'vo-col-4';
        case 50:
            return 'vo-col-6';
        case 66:
            return 'vo-col-8';
        case 75:
            return 'vo-col-9';
        case 100:
            return 'vo-col-12';
        default:
            return 'vo-col-12';
    }
}
